import * as clone from 'clone';
import * as dayjs from 'dayjs';
import { ShippingOption } from 'Shared/classes/shipping-option';

export class DeliveryDate {
  public date: dayjs.Dayjs;
  public shippingOptions: ShippingOption[] = [];
  public isDeliverable: boolean;
  public hasStock: boolean;

  constructor(date?: dayjs.Dayjs) {
    if (date) {
      this.date = date;
    }
  }

  /**
   * Set the date
   * @param date DayJs compatible object
   */
  setDate(date: any): void {
    this.date = dayjs(date);
  }
}
