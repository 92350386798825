
<ng-container *ngIf="!new_banner">
  <div class="occasion-banner bwRhythm bwRhythmTop text-center" [ngClass]="{'occasion-banner--rebrand': occasionrebrand}">
    <div class="container-fluid" *ngIf="!occasionrebrand">
      <div class="container">
        <div class="occasion-banner__content">
          <div class="occasion-banner__image"><img bw-contentful-image="//images.ctfassets.net/ucgi79tscdcj/4VP51hVUX7ztItrokROPhJ/75cd06c31fddd7a830d8f78e6471e7b6/occasions-guest.jpg" width="590"></div>
          <div class="occasion-banner__text">
            <h2 class="h2 serif">A little forgetful?</h2>
            <p class="mb-4">Save all your special occasions into Your Account and we'll give you a little nudge nearer the time.</p>
            <bw-occasion-launch class="mb-3" buttonStyle="modal-primary" size="large" [noIcon]="true" (success)="onSuccess()" (cancel)="onCancel()" origin="homepageBanner"></bw-occasion-launch>
            <div class="text__offer position-relative" *ngIf="offerDetail &amp;&amp; occasionsCount !== undefined">
              <bw-occasion-offer-message class="position-relative d-block u-font-small occasion-banner__offer-message" [requiredCount]="offerDetail?.requiredCount" [remaining]="offerDetail?.requiredCount - occasionsCount" offerCode="add3credit5"><img class="offer__arrow" src="/assets/brand-icons/arrow-top-left-from-bottom-right.svg">
                <div>your next order</div>
              </bw-occasion-offer-message>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BRAND REFRESH-->
    <!-- Desktop-->
    <div class="occasion-banner__rebrand--desktop hidden-xs" *ngIf="occasionrebrand">
      <div class="occasion-banner__rebrand-container offset-md-1">
        <div class="row">
          <div class="col-md-6">
            <div class="occasion-banner__rebrand-left">
              <div class="bw-ui--section-block-text">
                <div class="heading bw-font-headline-large"><span>Remember what’s important</span></div>
                <div class="content bw-font-body-regular"><span>Set reminders for birthdays, anniversaries or times they might need some extra support.</span></div>
              </div>
              <bw-occasion-launch class="mb-3" [occasionrebrand]="occasionrebrand" buttonStyle="modal-tertiary" size="large" [noIcon]="true" (success)="onSuccess()" (cancel)="onCancel()" origin="homepageBanner" [isBrandRefresh]="true"></bw-occasion-launch>
              <div class="text__offer" *ngIf="offerDetail &amp;&amp; occasionsCount !== undefined">
                <bw-occasion-offer-message class="d-block bw-font-body-small" [requiredCount]="offerDetail?.requiredCount" [remaining]="offerDetail?.requiredCount - occasionsCount" offerCode="add3credit5" origin="homepageBanner" [isBrandRefresh]="true">
                  <div>your next order</div>
                </bw-occasion-offer-message>
              </div>
            </div>
          </div>
          <div class="occasion-banner__rebrand-right col-md-6"><img bw-contentful-image="//images.ctfassets.net/ucgi79tscdcj/1npisf6fYyXvs5WAkyX5sR/018be84a1ad981e8611fe3c0fac1ad5c/B_W_Occasions.jpg" width="416"></div>
        </div>
      </div>
    </div>
    <!-- Mobile-->
    <div class="occasion-banner__rebrand--mobile hidden-sm hidden-md hidden-lg hidden-xl" *ngIf="occasionrebrand">
      <div class="occasion-banner__rebrand-container row">
        <div class="occasion-banner__rebrand-right col-md-12"><img bw-contentful-image="//images.ctfassets.net/ucgi79tscdcj/6FEGY1l3uDJBH3dfev098d/95360bca44bb25c6a3f231121d4920f1/B_W_How_it_works_images_304x400pxOccasions_copy_3.jpg" width="327">
          <div class="bw-ui--section-block-text">
            <div class="heading bw-font-headline-large"><span>Remember what’s important</span></div>
            <div class="content bw-font-body-regular"><span>Set reminders for birthdays, anniversaries or times they might need some extra support.</span></div>
            <div class="cta">
              <bw-occasion-launch class="mb-3" [occasionrebrand]="occasionrebrand" buttonStyle="modal-tertiary" size="large" [noIcon]="true" (success)="onSuccess()" (cancel)="onCancel()" origin="homepageBanner" [isBrandRefresh]="true"></bw-occasion-launch>
              <div class="text__offer" *ngIf="offerDetail &amp;&amp; occasionsCount !== undefined">
                <bw-occasion-offer-message class="d-block bw-font-body-small" [requiredCount]="offerDetail?.requiredCount" [remaining]="offerDetail?.requiredCount - occasionsCount" offerCode="add3credit5" origin="homepageBanner" [isBrandRefresh]="true">
                  <div>your next order</div>
                </bw-occasion-offer-message>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="new_banner">
  <bw-occasion-launch [displayButton]="false" (success)="onSuccess()" (cancel)="onCancel()" origin="homepageBanner"></bw-occasion-launch>
  <ui-banner [background_colour]="new_banner_background" [cta_text]="new_banner_cta_text" [banner_title]="new_banner_title" [banner_surtitle]="new_banner_surtitle" [banner_subtitle]="new_banner_subtitle" (cta_clicked)="occasionLaunch.launch()"></ui-banner>
</ng-container>