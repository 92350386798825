import { Directive, OnDestroy, OnInit } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { ViewportDetectionService } from 'Shared/services/viewport-detection.service';
@Directive({
  selector: '[bwStickyScrollMonitor]'
})
export class StickyScrollMonitorDirective implements OnInit, OnDestroy {
  windowHeightCache = this.windowRefService.nativeWindow.innerHeight;
  // Timer to throttle scroll event logic from running
  scrollTimer;

  // Stored scroll point to determine scroll direction
  lastScroll: number = 0;

  // Class name to toggle
  stickyScrollHide = 'sticky-scroll-hide';

  // Class name when scrolled up
  stickyScrollUp = 'sticky-scroll-up';

  // Desktop size detect
  desktopSize: boolean = this.windowRefService.nativeWindow.matchMedia(`only screen and (min-width: 767px)`).matches;
  //
  significantScrollLimit: number = this.desktopSize ? 24 : 150;
  // The difference between a previous scroll point that we consider significant
  // Minimum scroll offset
  minimumScrollOffset: number = this.desktopSize ? 120 : this.windowHeightCache * 0.35;

  // Change Delay
  changeDelayTimout: number = this.desktopSize ? 50 : 100;

  // iOS/Android Viewport Chrome
  mobileViewportSizes: { expanded: number; contracted: number } = {
    expanded: this.windowHeightCache,
    contracted: this.windowHeightCache
  };

  device: string;

  constructor(private windowRefService: WindowRefService, private viewportService: ViewportDetectionService) {
    this.device = this.viewportService.viewportSizeIs$.getValue()?.mobile ? 'mobile' : 'desktop';
  }

  reportScrollProgress(scrollY: number = 0, forceShow: boolean = false, forceHide: boolean = false): void {
    const docBody = this.windowRefService.nativeWindow.document.body;

    // Refuse to hide (show) elements when still towards the top
    // or if force hide is true
    if (scrollY <= this.minimumScrollOffset || forceShow) {
      docBody.classList.remove(this.stickyScrollUp);
      docBody.classList.remove(this.stickyScrollHide);
      return;
    }

    // Hide elements
    if (forceHide) {
      docBody.classList.remove(this.stickyScrollUp);
      docBody.classList.add(this.stickyScrollHide);
      return;
    }

    if (scrollY > this.lastScroll) {
      // scrolling down
      docBody.classList.remove(this.stickyScrollUp);
      docBody.classList.add(this.stickyScrollHide);
    } else if (scrollY < this.lastScroll) {
      // scrolling up
      docBody.classList.remove(this.stickyScrollHide);
      docBody.classList.add(this.stickyScrollUp);
    }

    this.lastScroll = scrollY;
  }

  scrollAmountSignificant(newScrollPoint: number): boolean {
    const difference = this.lastScroll > newScrollPoint ? this.lastScroll - newScrollPoint : newScrollPoint - this.lastScroll;

    return newScrollPoint <= this.minimumScrollOffset || difference >= this.significantScrollLimit;
  }

  handleEvent(event): void {
    if (event.type === 'scroll') {
      // Scroll event
      if (this.scrollTimer) {
        return;
      }
      this.scrollTimer = setTimeout(() => {
        const scrollY = this.windowRefService.nativeWindow.scrollY;

        if (!this.scrollAmountSignificant(scrollY)) {
          this.scrollTimer = null;
          return;
        }
        this.reportScrollProgress(scrollY);
        this.scrollTimer = null;
      }, this.changeDelayTimout);
    }

    if (event.type === 'resize' && this.device === 'mobile') {
      // don't use cached height here as its expected to have changed
      const viewportHeight = this.windowRefService.nativeWindow.innerHeight;

      // Safari on iOS and Chrome on Android both hide their browser chrome based on scroll events
      // By storing their 'contracted' state, which is when browser chrome is showing
      // and their 'expanded' state, which is after they are hidden
      // we can hide/show our sticky elements at the same time
      this.mobileViewportSizes = {
        expanded: viewportHeight > this.mobileViewportSizes.expanded ? viewportHeight : this.mobileViewportSizes.expanded,
        contracted: this.mobileViewportSizes.contracted === undefined ? viewportHeight : this.mobileViewportSizes.contracted
      };

      if (viewportHeight === this.mobileViewportSizes.expanded) {
        // hide our elements
        this.reportScrollProgress(this.windowRefService.nativeWindow.scrollY, false, true);
        return;
      }

      if (viewportHeight === this.mobileViewportSizes.contracted) {
        // show our elements
        this.reportScrollProgress(this.windowRefService.nativeWindow.scrollY, true, false);
        return;
      }
    }
  }

  ngOnDestroy(): void {
    const window = this.windowRefService.nativeWindow;
    if (window.document && window.document.removeEventListener) {
      window.document.removeEventListener('scroll', this);
    }

    if (window.removeEventListener) {
      window.removeEventListener('resize', this);
    }
  }

  ngOnInit(): void {
    this.windowRefService.nativeWindow.document.addEventListener('scroll', this, {
      passive: true
    });
    this.windowRefService.nativeWindow.addEventListener('resize', this);
  }
}
