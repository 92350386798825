import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BwForm, BwFormControl, BwFormDefintion, RadioOption } from 'Shared/classes/bw-form';
import { Email, EmailService } from 'Shared/services/email.service';
import { ToastrMessage, ToastrService } from 'Base/app/toastr/services/toastr.service';
import { FeaturesService } from 'Shared/services/features.service';
import { EmailPreference } from 'Shared/classes/email';
import { Error } from 'Shared/classes/error';
import { t } from 'Shared/utils/translations';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { UserService } from 'Shared/services/user.service';

type EmailPreferenceControl = {
  preference: BwFormControl<EmailPreference>;
};

export interface EmailPreferenceItem {
  id: string;
  value: EmailPreference;
  label: string;
  description: string;
}

export const emailPreferences = [
  {
    id: 'marketing-all',
    value: EmailPreference.OptedInAllEmails,
    label: t('js.component.user-details.opt-in.all'),
    description: t('js.components.bwAccountEmail.messages.all')
  },
  {
    id: 'marketing-weekly',
    value: EmailPreference.OptedInMoreOccasional,
    label: t('js.component.user-details.opt-in.weekly'),
    description: t('js.components.bwAccountEmail.messages.weekly')
  },
  {
    id: 'marketing-seasonal',
    value: EmailPreference.OptedInOccasional,
    label: t('js.component.user-details.opt-in.seasonal'),
    description: t('js.components.bwAccountEmail.messages.seasonal')
  },
  {
    id: 'marketing-none',
    value: EmailPreference.OptedOut,
    label: t('js.component.user-details.opt-in.none'),
    description: t('js.components.bwAccountEmail.messages.unsubscribe')
  }
];

/* bw:view-encapsulation */
@Component({
  selector: 'bw-email-preferences',
  templateUrl: './email-preferences.component.html'
})
export class EmailPreferencesComponent extends BwForm<EmailPreferenceControl> implements OnInit, OnDestroy {
  @Input() email: Email;
  @Input() showPreferencesInFrench: boolean = false;
  @Output() emailChanged = new EventEmitter<Email>();

  loading: boolean;
  success: boolean = false;
  showMinimal: boolean;

  minimalOptions: RadioOption[] = [
    {
      id: 'OptedInAwaitingOptIn',
      value: EmailPreference.OptedInAwaitingOptIn,
      label: t('js.components.bwAccountEmail.labels.all'),
      description: t('js.components.bwAccountEmail.messages.all')
    },
    {
      id: 'optedOut',
      value: EmailPreference.OptedOut,
      label: t('js.components.bwAccountEmail.labels.unsubscribe'),
      description: t('js.components.bwAccountEmail.messages.unsubscribe')
    }
  ];

  availablePreferences: number[];
  defaultOptions: RadioOption[] = [];

  constructor(
    private emailService: EmailService,
    private toastr: ToastrService,
    private featuresService: FeaturesService,
    private optimizelyService: OptimizelyService,
    private analyticsService: AnalyticsService,
    private userService: UserService
  ) {
    super();

    this.availablePreferences = this.featuresService.getFeature('GDPR').availableEmailPreferences;
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * On Init
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.defaultOptions = this.availablePreferences.map((id): { id: string; value: EmailPreference; label: string; description: string } =>
      emailPreferences.find((e): boolean => e.value === id)
    );

    this.showMinimal =
      this.email.preference === 4 || // If it's waiting for user response
      (this.email.preference === 0 && this.featuresService.getFeature('GDPR').doubleOptIn); // Or it's unsubscribed, but double opt in

    this.setObject(this.email.preference);
  }

  /**
   * Build form
   */
  buildForm(): BwFormDefintion<EmailPreferenceControl> {
    return {
      preference: new FormControl(null, [Validators.required])
    };
  }

  /**
   * BwForm required implementation
   */
  getObject(): EmailPreference {
    return this.get('preference').value;
  }

  /**
   * BwForm required implementation
   */
  setObject(value: EmailPreference): void {
    this.get('preference').setValue(value);
  }

  /**
   * Submit
   */
  submit(): Promise<void> {
    const email = this.email.clone();
    email.preference = this.getObject();

    email.surveyPreference = undefined;
    const copy = (
      (
        (document.querySelector('bw-email-preferences bw-form-radio-input .is-selected .radio-input__label-copy') as HTMLElement) ||
        (document.querySelector('bw-email-preferences input:checked ~ label') as HTMLElement) ||
        {}
      ).innerText || ''
    ).trim();

    email.consent = {
      copy,
      method: 'Ticked Checkbox',
      location: 'Web - Email Preferences - Email Preferences'
    };

    this.loading = true;
    return this.emailService
      .update(email)
      .then((updatedEmail: Email): void => {
        this.success = true;
        this.trackHeapEvent({
          oldPreference: this.email.preference,
          newPreference: email.preference,
          detail: copy,
          location: document.location.pathname
        });
        this.emailChanged.emit(updatedEmail);
      })
      .catch((error: Error): ToastrMessage => this.toastr.error(error.message, error.title))
      .then((): void => {
        this.loading = false;
      });
  }

  /**
   * Track heap events
   * @param {string} eventName
   * @param {unknown[]} args
   */
  private trackHeapEvent(data: object): void {
    this.optimizelyService.trackEvent('unsubscribe');

    const user = this.userService.getUser();
    data = { user, ...data };
    this.analyticsService.trackInHeap('updateEmailPreferences', data);
  }
}
