import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { User } from 'Shared/classes/user';
import { Purchase } from 'Shared/classes/purchase';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';

@Injectable({
  providedIn: 'root'
})
export class BranchService {
  private serviceInitialized: boolean = false;
  debug: boolean = false;

  constructor(private windowRef: WindowRefService, private domUtils: DomUtilsService) {
    this.debug = this.windowRef.nativeWindow.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Main branch call
   * @param fnName
   * @param args
   */
  branch(fnName: string, ...args): void {
    if (this.serviceInitialized && this.windowRef.nativeWindow['branch']) {
      try {
        this.windowRef.nativeWindow['branch'][fnName](...args);
        this.log(fnName, ...args);
      } catch (e) {}
    }
  }

  /**
   * Init Script
   */
  init(): Promise<any> {
    return this.domUtils.loadScript('https://cdn.branch.io/branch-latest.min.js', 'branch').then(() => {
      this.serviceInitialized = true;
    });
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<branch>', ...args);
    }
  }

  /**
   * Identify
   * @param user
   */
  identify(user: User): void {
    try {
      if (user && user.slug) {
        this.branch('setIdentity', user.slug);
        return;
      }
      this.branch('logout');
    } catch (e) {}
  }

  /**
   * Track a purchase
   */
  trackPurchase(purchase: Purchase): void {
    try {
      const data = {
        transaction_id: purchase.id,
        currency: purchase.currency.toUpperCase(),
        revenue: (purchase.price.price / 100).toFixed(2),
        products: purchase.orders.map(order => ({
          sku: order.id,
          name: order.product.name,
          price: (order.price.price / 100).toFixed(2),
          variant: order.getTrackedDurationName(),
          quantity: 1 // Aways one
        }))
      };
      const metadata = {};
      this.branch('trackCommerceEvent', 'purchase', data, metadata);
    } catch (e) {}
  }

  /**
   * Log an event through branch
   * @param {String} key
   * @param {Object} data
   * @param {Object} contentItems
   */
  logEvent(key: string, data: any, contentItems: any): void {
    try {
      const k = key.toUpperCase();
      this.branch('logEvent', k, data, contentItems);
      this.log('purchase', k, data, contentItems);
    } catch (e) {}
  }

  /**
   * Is iphone or android
   * @returns {Boolean}
   */
  isiPhone(): boolean {
    // UA sniffing is recommended by branch...
    const userAgentLowerCase = this.windowRef.nativeWindow.navigator.userAgent.toLowerCase();
    return /(iphone|ipod)/i.test(userAgentLowerCase);
  }

  /**
   * Show Banner
   * @returns {Promise}
   */
  showBanner(): void {
    try {
      if (this.isiPhone()) {
        this.log('showing banner (potentially)');
        this.branch(
          'banner',
          {
            icon: '/brand-icons/favicons/apple-touch-icon-120x120.png',
            // TODO: BELOW TRANSLATIONS
            //            title: this.bwTranslation.translate('js.branchio.labels.title'),
            //            openAppButtonText: this.bwTranslation.translate('js.branchio.buttons.open'),
            //            downloadAppButtonText: this.bwTranslation.translate('js.branchio.buttons.download'),
            showAndroid: false,
            showDesktop: false,
            showiOS: true,
            showiPad: false,
            rating: 4.81,
            reviewCount: 15035 // TODO: Cool if we can pull in live review data
          },
          {}
        );
      }
    } catch (e) {
      console.warn(e.toString());
    }
  }
}
