import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, Validators, FormControl, ValidatorFn } from '@angular/forms';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { WindowRefService } from 'Shared/services/window.service';
import { User } from 'Shared/classes/user';
import { UserService } from 'Shared/services/user.service';
import { OrderService } from 'Shared/services/order.service';
import * as dayjs from 'dayjs';
import { BwForm, BwFormControl, BwFormDefintion } from 'Shared/classes/bw-form';
import { TicketService } from 'Shared/services/ticket.service';
import { FileService } from 'Shared/services/file.service';
import { Order } from 'Shared/classes/order';
import { DropDownOption } from 'Shared/components/form-dropdown/form-dropdown.component';
import { Delivery } from 'Shared/classes/delivery';
import { Error } from 'Shared/classes/error';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { FormName, FieldName } from 'Shared/services/third-parties/gtag-ga4.service';

type MyFormControls = {
  fullName: BwFormControl<string>;
  reason: BwFormControl<string>;
  resolution: BwFormControl<string>;
  problem: BwFormControl<string>;
  issue: BwFormControl<string>;
  stem: BwFormControl<string>;
  question: BwFormControl<string>;
  description: BwFormControl<string>;
  deliveryAddress: BwFormControl<string>;
  postCode: BwFormControl<string>;
  order: BwFormControl<string>;
  email: BwFormControl<string>;
  fileNames: BwFormControl<any>;
};

type MyFormControlNames = keyof MyFormControls;

class ContentfulDataModel {
  index: number;
  title: string;
}
/* bw:view-encapsulation */
@Component({
  selector: 'bw-contact-us',
  templateUrl: './contact-us.component.html'
})
export class ContactUsComponent extends BwForm<MyFormControls> implements OnInit {
  loading: boolean;
  success: boolean;
  formSubmitted: boolean;
  reasonHelper: any;
  reasons: ContentfulDataModel[] = [];
  resolutions: ContentfulDataModel[] = [];
  problems: ContentfulDataModel[] = [];
  issues: ContentfulDataModel[] = [];
  stems: ContentfulDataModel[] = [];

  contactUsForm: FormGroup = new FormGroup({});

  fileUpload: any;
  user: User;
  orderPrefilled: boolean = false;
  files: any[];
  s3Location: string = 'bw-contact-uploads';

  characterLimit: number;
  textCounter: number;
  resolutionsDDL: DropDownOption[];
  problemsDLL: DropDownOption[];
  issuesDDL: DropDownOption[];
  stemsDDL: DropDownOption[];
  reasonsDLL: DropDownOption[];

  // The defaultReason is repeated, because ....
  @Input() defaultReason: string; // ... camelCase for when called from codebase and or modals
  @Input() defaultreason: string; // ... lowercase this is called from contentful.
  @Input() suggestedResolutions: string; // From contentful
  @Input() suggestedProblems: string;
  @Input() suggestedIssues: string;
  @Input() suggestedStems: string;
  @Input() defaultUser: User;
  @Input() defaultOrder: Order;
  @Input() defaultDelivery?: Delivery;

  @Input() formtype: string = 'general'; // Could be 'general' ,'delivery' or 'quality'

  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  constructor(
    private toastr: ToastrService,
    private ticketService: TicketService,
    private fileService: FileService,
    private windowRefService: WindowRefService,
    private userService: UserService,
    private orderService: OrderService,
    private analyticsService: AnalyticsService
  ) {
    super();

    this.formSubmitted = false;
    this.loading = false;
    this.characterLimit = 1000;
    this.textCounter = 0;
  }

  onMessageTextChange(): void {
    this.textCounter = (this.get('question').value || '').length;
  }

  onFilesChanged(files: any[]): void {
    this.files = files;
  }

  onFocus(fieldName: FieldName): void {
    this.analyticsService.trackEnterField('contact us', fieldName);
  }

  updateUserFields(): Promise<any> {
    this.orderPrefilled = false;

    const userDetails = {
      email: '',
      name: ''
    };

    if (this.user && this.user.isLoggedIn()) {
      userDetails.email = this.user.email.address || '';
      userDetails.name = this.user.fullName || '';
    }

    this.get('email').setValue(userDetails.email);
    this.get('fullName').setValue(userDetails.name);

    if (!this.user || !this.user.isLoggedIn()) {
      this.get('order').setValue('');
      this.get('postCode').setValue('');
      this.orderPrefilled = false;
      return Promise.resolve();
    }

    if (this.defaultDelivery) {
      this.get('order').setValue(this.defaultDelivery.orderId.toString());
      this.get('postCode').setValue(this.defaultDelivery.address.postcode);
      return Promise.resolve();
    }

    return this.orderService.getAll().then((orders) => {
      const order = orders
        .filter((o) => o.createdAt.isAfter(dayjs().subtract(14, 'day')))
        .sort((a, b) => b.createdAt.unix() - a.createdAt.unix())[0];

      this.get('order').setValue(`${order?.id}` || '');
      this.get('postCode').setValue(order && order.address ? order.address.postcode || '' : '');

      if (order && order.id) {
        this.orderPrefilled = true;
      }
    });
  }

  /**
   * Applies value to form control
   * @param value
   * @param FormControlName
   */
  onSelection(value: ContentfulDataModel, controlName: MyFormControlNames): void {
    if (controlName) {
      this.get(controlName).setValue(value.title);
    }
  }

  selectReason(reason: ContentfulDataModel): void {
    if (!reason || !reason.title) {
      return;
    }

    this.get('reason').setValue(reason.title);
  }

  /**
   * Get the reasons from attributes
   * @param {Object} attributes
   * @returns {Array}
   */
  getFromAttributes(key: string): any[] {
    const attrReasons: any[] = [];

    for (let i = 0; i < 20; i = i + 1) {
      // if reason exists in scope
      if (this[`${key}_${i}`]) {
        attrReasons.push({
          index: i,
          title: this[`${key}_${i}`],
          content: this[`${key}_${i}_content`] || ''
        });
      }
    }

    return attrReasons.sort((a, b) => a.index - b.index);
  }

  /**
   * Gathers data from the uploaded file
   * @param {Object} data
   */
  fileUploaded(data): void {
    if (!data.target.files.length) {
      return;
    }
    this.fileUpload = data.target.files;
  }

  /**
   * On File Input Touched
   */
  onFileInputTouched(): void {
    this.get('fileNames').markAsTouched();
  }

  /**
   * Submit the form
   * @param {Form} form
   * @returns {Promise}
   */
  submitForm(): Promise<any> {
    super.markAsSubmitted();
    this.formSubmitted = true;
    if (!this.valid) {
      return Promise.resolve('form invalid');
    }
    this.loading = true;
    this.analyticsService.trackFormSubmit('contact us');

    this.trackChurnFlowContactForm();

    let p: Promise<any> = Promise.resolve();

    // Upload all the files, if one fails, stop
    if (this.files) {
      const promiseArr = this.files.map((file) => this.fileService.uploadFile(this.s3Location, file));
      p = p.then(() => Promise.all(promiseArr));
    }

    return p
      .then((fileNames) => {
        const payload = this.value;
        if (!payload.reason) {
          if (this.defaultReason) {
            payload.reason = this.defaultReason;
          }
        }
        payload.fileNames = fileNames && fileNames.length ? fileNames : undefined;
        return this.ticketService.createTicket(payload);
      })
      .then(() => {
        this.success = true;
        this.loading = false;
        const elem = this.windowRefService.nativeWindow.document.querySelector('bw-contact-us');
        this.windowRefService.scrollElementIntoView(elem, 0, 'top');
      })
      .catch((err: Error) => {
        this.toastr.error(err.message, err.title);
        this.loading = false;
      });
  }

  handleContentfulAttribute(value?: string, attribute?: string): any[] {
    if (value) {
      return value.split(',').map((title: string, index: number) => ({ index, title: title.trim() }));
    }

    return this.getFromAttributes(attribute);
  }

  /**
   * Ng on init
   */
  ngOnInit(): void {
    // Set the default reason / resolution
    this.reasons = this.getFromAttributes('reason');
    this.resolutions = this.handleContentfulAttribute(this.suggestedResolutions, 'resolution');
    this.problems = this.handleContentfulAttribute(this.suggestedProblems, 'problem');
    this.issues = this.handleContentfulAttribute(this.suggestedIssues, 'issue');
    this.stems = this.handleContentfulAttribute(this.suggestedStems, 'stem');

    // Build form
    super.ngOnInit();

    // Set values in form controls
    if (this.defaultUser) {
      this.get('email').setValue(this.defaultUser.email.address);
      this.get('fullName').setValue(this.defaultUser.fullName);
    } else {
      this.userService.user$.subscribe((user) => {
        if (this.user !== user) {
          this.user = user;
          this.updateUserFields();
        }
      });
    }
    if (this.defaultDelivery) {
      this.get('order').setValue(this.defaultDelivery.orderId.toString());
      this.get('postCode').setValue(this.defaultDelivery.address.postcode);
    } else if (this.defaultOrder) {
      this.get('order').setValue(this.defaultOrder.id.toString());
      this.get('postCode').setValue(this.defaultOrder.address.postcode);
    }

    this.resolutionsDDL = this.mapResolution();

    this.problemsDLL = this.mapProblem();
    this.issuesDDL = this.mapIssues();
    this.stemsDDL = this.mapStems();
    this.reasonsDLL = this.mapReasons();

    const defaultReason = this.defaultReason || this.defaultreason;
    if (defaultReason) {
      this.get('reason').setValue(defaultReason);
    }
  }

  /**
   * Contentful data checks to pass correct validations
   * @param values
   * @returns
   */
  customValidatorChecker(values: any[]): ValidatorFn[] {
    if (!values?.length) {
      return [];
    }

    return [Validators.required];
  }

  /**
   * Build Form - BwForm required implementation
   */
  buildForm(): BwFormDefintion<MyFormControls> {
    // Set the correct validation based on the different form types
    if (this.formtype === 'general') {
      return {
        reason: new FormControl(null, {
          validators: this.customValidatorChecker(this.resolutions)
        }),
        resolution: new FormControl(null),
        problem: new FormControl(null),
        issue: new FormControl(null),
        stem: new FormControl(null),
        question: new FormControl('', { validators: [Validators.required] }),
        description: new FormControl(''),
        deliveryAddress: new FormControl(''),
        postCode: new FormControl('', {}),
        order: new FormControl('', {}),
        fullName: new FormControl('', { validators: [Validators.required] }),
        email: new FormControl('', {
          validators: [Validators.required, Validators.email]
        }),
        fileNames: new FormControl('')
      };
    }
    if (this.formtype === 'delivery') {
      return {
        reason: new FormControl(this.defaultReason || ''),
        resolution: new FormControl(null, {
          validators: this.customValidatorChecker(this.resolutions)
        }),
        issue: new FormControl(null),
        problem: new FormControl(null),
        stem: new FormControl(null),
        question: new FormControl('', { validators: [Validators.required] }),
        description: new FormControl(''),
        deliveryAddress: new FormControl('', { validators: [Validators.required] }),
        postCode: new FormControl('', {}),
        order: new FormControl('', {}),
        fullName: new FormControl('', { validators: [Validators.required] }),
        email: new FormControl('', {
          validators: [Validators.required, Validators.email]
        }),
        fileNames: new FormControl('')
      };
    }

    if (this.formtype === 'quality') {
      return {
        reason: new FormControl(this.defaultReason || ''),
        resolution: new FormControl(null, {
          validators: this.customValidatorChecker(this.resolutions)
        }),
        problem: new FormControl(null),
        issue: new FormControl(null),
        stem: new FormControl(null),
        question: new FormControl(''),
        description: new FormControl('', { validators: [Validators.required] }),
        deliveryAddress: new FormControl('', { validators: [Validators.required] }),
        postCode: new FormControl('', {}),
        order: new FormControl('', {}),
        fullName: new FormControl('', { validators: [Validators.required] }),
        email: new FormControl('', {
          validators: [Validators.required, Validators.email]
        }),
        fileNames: new FormControl('')
      };
    }
    if (this.formtype === 'email_general' || this.formtype === 'churn_flow') {
      return {
        reason: new FormControl(this.defaultReason || ''),
        resolution: new FormControl(null),
        problem: new FormControl(null, { validators: this.customValidatorChecker(this.problems) }),
        issue: new FormControl(null),
        stem: new FormControl(null),
        question: new FormControl(''),
        description: new FormControl('', { validators: [Validators.required] }),
        deliveryAddress: new FormControl(''),
        postCode: new FormControl('', {}),
        order: new FormControl('', {}),
        fullName: new FormControl('', { validators: [Validators.required] }),
        email: new FormControl('', {
          validators: [Validators.required, Validators.email]
        }),
        fileNames: new FormControl('')
      };
    }
  }

  /**
   * BwForm required implementation
   */
  getObject(): any {}

  /**
   * BwForm required implementation
   */
  setObject(): any {}

  displayResolution(value: any) {
    return value?.title || value;
  }

  /**
   *
   */
  mapResolution(): DropDownOption[] {
    return (this.resolutions || []).map((r) => ({
      value: r.title,
      label: r.title,
      prefixIcon: null
    }));
  }

  displayProblem(value: any) {
    return value?.title || value;
  }

  /**
   *
   */
  mapProblem(): DropDownOption[] {
    return (this.problems || []).map((p) => ({
      value: p.title,
      label: p.title,
      prefixIcon: null
    }));
  }

  /**
   * Display issue on DDL
   * @param value
   */
  displayIssues(value: any) {
    return value?.title || value;
  }
  /**
   * Map issues for DDL
   */
  mapIssues(): DropDownOption[] {
    return (this.issues || []).map((r) => ({
      value: r.title,
      label: r.title,
      prefixIcon: null
    }));
  }

  /**
   * Display issue on DDL
   * @param value
   */
  displayReason(value: any) {
    return value?.title || value;
  }
  /**
   * Map issues for DDL
   */
  mapReasons(): DropDownOption[] {
    return (this.reasons || []).map((r) => ({
      value: r.title,
      label: r.title,
      prefixIcon: null
    }));
  }

  /**
   * Display stem on DDL
   * @param value
   */
  displayStems(value: any) {
    return value?.title || value;
  }
  /**
   * Map Stems for DDL
   */
  mapStems(): DropDownOption[] {
    return (this.stems || []).map((r) => ({
      value: r.title,
      label: r.title,
      prefixIcon: null
    }));
  }

  /**
   * Track Churn Flow Contact Form from Subs Benefits Modal
   */
  private trackChurnFlowContactForm() {
    if (this.formtype === 'churn_flow') {
      this.analyticsService.trackInHeap('submit_contact_form_churn_flow', {
        order: this.defaultOrder,
        activeSubsDeliveries: this.defaultOrder?.activeDeliveriesCount ?? 0,
        skippedSubsDeliveries: this.defaultOrder?.skippedDeliveriesCount ?? 0,
        modalType: 'churnFlowContactForm'
      });
    }
  }
}
