import * as dayjs from 'dayjs';
import * as clone from 'clone';
import { CurrencyCode } from 'Shared/classes/price';

export class GiftVoucherRedemption {
  amountUsed: number;
  completed: boolean;
  orderId: number;

  constructor(amount: number, completed: boolean, orderId: number) {
    this.amountUsed = amount;
    this.completed = completed;
    this.orderId = orderId;
  }
}

export class GiftVoucher {
  code: string;
  id?: number;
  type?: string;
  initialValue?: number;
  balance?: number;
  expiryDate?: dayjs.Dayjs;
  currency?: CurrencyCode;
  redemptions?: GiftVoucherRedemption[];
  amountUsed: number;
  active?: boolean;
  isLoyalty?: boolean;

  constructor(
    code: string,
    redemptions?: GiftVoucherRedemption[],
    id?: number,
    type?: string,
    initialValue?: number,
    balance?: number,
    currency?: CurrencyCode,
    expiryDate?: dayjs.Dayjs,
    isLoyalty?: boolean
  ) {
    this.id = id;
    this.type = type;
    this.initialValue = initialValue;
    this.balance = balance;
    this.code = code;
    this.currency = currency;
    this.expiryDate = expiryDate;
    this.redemptions = redemptions;
    this.amountUsed = this.totalAmountUsed(redemptions);
    this.isLoyalty = isLoyalty ?? false;
  }

  /**
   * Clone the gift voucher
   */
  clone(): GiftVoucher {
    return clone(this);
  }

  /**
   * Returns the remaining balance
   */
  getRemainingBalance(): number {
    if (!this.balance) {
      return 0;
    }

    if (!this.amountUsed) {
      return this.balance;
    }

    return this.balance - this.amountUsed;
  }

  /**
   * Returns the total amount used
   */
  totalAmountUsed(redemptions: GiftVoucherRedemption[] = []): number {
    let totalAmount = 0;
    redemptions.forEach((order) => {
      totalAmount += order.amountUsed;
    });
    return totalAmount;
  }
}
