import { Component, OnChanges, Input } from '@angular/core';
import { ConfigService } from 'Shared/services/config.service';
import { DiscountService, Discount } from 'Checkout/services/discount.service';
import { environment } from 'Environments/environment';
import { Country } from 'Shared/classes/country';
import { CountryService } from 'Shared/services/country.service';
import { Price } from 'Shared/classes/price';
import { PricePipe } from 'Shared/pipes/price.pipe';

@Component({
  selector: 'bw-occasion-offer-message',
  templateUrl: './occasion-offer-message.component.html',
  styleUrls: ['./occasion-offer-message.component.scss']
})
export class OccasionOfferMessageComponent implements OnChanges {
  @Input('requiredCount')
  requiredCount: number;

  @Input('remaining')
  remaining: number;

  @Input('offerCode')
  offerCode: string;

  @Input() origin: string = '';
  @Input() isBrandRefresh: boolean = false;

  offerReward: string;

  constructor(
    private config: ConfigService,
    private discountService: DiscountService,
    private countryService: CountryService,
    private pricePipe: PricePipe
  ) {}

  ngOnChanges(): void {
    const offerDetails = this.config.getConfig().occasionsOffers[this.offerCode];
    if (!offerDetails) {
      return;
    }

    if (offerDetails && offerDetails.currency !== this.countryService.forShipping.currencyCode) {
      return;
    }

    const c = new Country();
    c.codes = [environment.country];
    this.discountService.clearCache();
    this.discountService
      .check(new Discount(offerDetails['code']), c)
      .then(r => {
        this.offerReward = this.pricePipe.transform(
          new Price(this.countryService.forShipping.currencyCode, 1, offerDetails['amount'])
        );
      })
      .catch(() => {
        this.offerReward = null;
      }); // Code was invalid for some reason
  }
}
