import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { LocationService } from 'Shared/services/location.service';
import { ContentService } from 'Shared/services/content.service';
import { StateService } from 'Shared/services/state.service';

/**
 * ------------------------
 * To get a "fragment" of a content, spot, use it like this:
 *
 * bw-content-spot(
 *   contentId="fragmented-example"
 *   fragment="#helloworld"
 * )
 *
 * ------------------------
 */

/* bw:view-encapsulation */
@Component({
  selector: 'bw-content-spot',
  templateUrl: './content-spot.component.html'
})
export class ContentSpotComponent implements OnInit {
  @Input()
  contentId: string;
  @Input()
  fragment: string;
  @Input()
  useUrl: boolean = false;
  @Input()
  urlOverride: string;
  @Input()
  queryStringKey: string;
  @Input()
  defaultContentId: string;
  @Input()
  useFallback: boolean = true;

  @Output()
  contentSpotDoesNotExist: EventEmitter<boolean> = new EventEmitter();
  @Output()
  contentSpotLoaded: EventEmitter<boolean> = new EventEmitter();

  content: string;

  constructor(
    private locationService: LocationService,
    private contentService: ContentService,
    private stateService: StateService
  ) {}

  /**
   * Get the content spot file name
   * @returns {String}
   */
  getContentSpotFileName(): string {
    // Via a parameter eg ?contentSpot=hello-world
    // The key is whatever is defined as queryStringKey so ?{queryStringKey}={contentParam}
    const currentParams = this.stateService.getCurrent().params;

    if (this.queryStringKey && this.queryStringKey.length) {
      const contentSpotQueryParam = currentParams[this.queryStringKey];
      if (contentSpotQueryParam && contentSpotQueryParam.length) {
        return `content-spot/${contentSpotQueryParam}`;
      }
    }

    let path = this.locationService.path().toLowerCase();
    path = path[0] === '/' ? path.slice(1) : path; // Remove the first '/'
    path = path.substr(-1) === '/' ? path.slice(0, -1) : path; // if last character is / remove it

    if (this.contentId && this.useUrl) {
      return `content-spot/${this.contentId}/${path}`;
    }
    // Or via the attributes eg <content-spot content-id="hello-world">
    if (this.contentId) {
      return `content-spot/${this.contentId}`;
    }
    // Or an url-override
    if (this.urlOverride) {
      return this.urlOverride;
    }
    // Or if we want to default to using the path, eg /send-flowers/tagonly/christmas
    if (this.useUrl) {
      return path;
    }
    return '';
  }

  /**
   * Get the content for a path
   * - As nginx returns 204 for invalid content spots (to reduce items in logs)
   * - we need to convert the empty content to a reject
   * @param path
   * @param fragment
   */
  getContentForPath(path: string, useFallback: boolean, fragment?: string): Promise<any> {
    return this.contentService.get(path, useFallback, fragment).then(content => {
      return content && content.length ? Promise.resolve(content) : Promise.reject({});
    });
  }

  /**
   * On init, get the content required from the content server
   */
  ngOnInit(): Promise<any> {
    const path = this.getContentSpotFileName();

    // First get the content requested
    return this.getContentForPath(path, this.useFallback, this.fragment)
      .catch(() => {
        // If the content from contentful is empty we emit this event in order to handle the undefined content spot
        this.contentSpotDoesNotExist.emit(true);
        // If that doesn't exist, or is empty and the default contentId is defined, get that instead
        return !this.defaultContentId
          ? Promise.reject({})
          : this.getContentForPath(`content-spot/${this.defaultContentId}`, this.useFallback);
      })
      .then(html => {
        this.contentSpotLoaded.emit(true);
        this.content = html;
      })
      .catch(e => {
        // If the content from contentful is empty we emit this event in order to handle the undefined content spot
        this.contentSpotDoesNotExist.emit(true);
      }); // Expected 404 - no issues
  }
}
