import * as clone from 'clone';

export class MegaNav {
  shipping_country_id: number;
  items: MegaNavItem[];

  /**
   * Clone
   */
  clone(): MegaNav {
    return clone(this);
  }
}

export class MegaNavItem {
  name: string;
  styles?: string[];
  url: string;
  tags: string[] = [];
  backgroundColour?: string;
  underlineColour?: string;
  displayOn: string[];
  isFooterLink?: boolean;
  sections: MegaNavItemSection[];
}

export class MegaNavItemSection {
  name?: string;
  styles?: string[];
  url?: string;
  tags: string[] = [];
  sectionType?: string;
  displayOn: string[] = [];
  navPath?: MegaNavItemSectionLink[] | MegaNavItemSection[] | MegaNavItem[];
  positionIndex?: number;
  links: MegaNavItemSectionLink[];
}

export class MegaNavItemSectionLink {
  name: string;
  styles?: string[];
  shortCopy?: string;
  displayOn: string[];
  url: string;
  tags: string[] = [];
  positionIndex?: number;
  navPath?: MegaNavItemSectionLink[] | MegaNavItemSection[] | MegaNavItem[];
}

export class NavAnalyticsInfo {
  mainNavItem: string;
  primarySubNavItem: string;
  secondarySubNavItem: string;
  navStyle: string;
  contentSpot: boolean;
  mainNavItemNew: string;
  primarySubNavItemNew: string;
  secondarySubNavItemNew: string;
  brandId?: string;
  locale?: string;
  shippingCountryId?: number;
  subNavLength?: number;
  subNavPosition?: number;
}
