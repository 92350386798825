import { Injectable } from '@angular/core';
import { countries } from 'Environments/countries';
import { Country } from 'Shared/classes/country';
import { BackendService } from 'Shared/services/backend.service';

@Injectable({
  providedIn: 'root'
})
export class CountryModelService {
  countriesData = countries;

  constructor(private backend: BackendService) {}

  /**
   * From payload
   * @param res
   */
  static fromPayload(res): Country {
    // We need to add the isle of man, jersey and gurnsey as a code for gb
    const codes = res.code.toLowerCase() === 'gb' ? [res.code, 'im', 'je', 'gg'] : [res.code];
    const country = new Country();
    country.id = parseInt(res.shipping_country.id, 10);
    country.codes = codes;
    country.name = res.name;
    country.currencyCode = res.shipping_country.currency_code;
    country.phonePrefix = res.phone_number_prefix;
    country.timezone = res.user_country.timezone;
    country.address = {
      postcodeRegex: res.shipping_country.postcode_regex,
      requiredCity: res.shipping_country.city_required,
      requiredLine1: res.shipping_country.line1_required,
      requiredLine2: res.shipping_country.line2_required,
      requiredPhone: res.shipping_country.phone_number_required,
      requiredPostcode: res.shipping_country.postcode_required
    };
    country['availableForUser'] = res.shipping_country.web_enabled;

    return country;
  }

  /**
   * Get all
   */
  getAll(): Country[] {
    const ctrs = this.backend.deserialise(this.countriesData);
    return ctrs.map(c => CountryModelService.fromPayload(c));
  }
}
