
<div class="toastr__wrapper" *ngIf="message?.config">
  <div class="toastr {{message.config.cssClass}}" [@showToast]="{
      value: message.visible,
      params: {
        duration: message.config.easeTime
      }
    }" (mouseenter)="stopTimeout()" (mouseleave)="startTimeout()">
    <div class="toastr__inner">
      <div class="toastr__close-button" *ngIf="message.config.closeButton" aria-label="Close" (click)="manualClose()">
        <bw-icon [iconType]="'small-x'"></bw-icon>
      </div>
      <bw-feedback [delivery]="message.delivery" [type]="$any(message.type)" [modalScreen]="$any(message.modalScreen)" [issue]="message.issue" [stems]="message.stems" [resolution]="message.resolution" [issueOptionId]="message.issueOptionId" [userType]="message.userType" [showMoreStack]="false" [question]="message.message" (didSubmit)="startTimeout($event)" (touchedStars)="pauseTimeout($event)"></bw-feedback>
      <div class="toastr__progress-container" [hidden]="!message.config.autoDismiss">
        <div class="toastr__progress" [@toastProgress]="{
          value: messageTimeoutActive,
          params: {
            duration: message.config.timeOut
          }
        }"></div>
      </div>
    </div>
  </div>
</div>