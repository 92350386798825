import { HasContentSegment } from 'Content/guards/hasContentSegment.guard';
import { ContentComponent } from 'Content/components/content/content.component';
import { SitemapComponent } from 'Content/components/sitemap/sitemap.component';
import { BranchRedirectorComponent } from 'Content/components/branch-redirector/branch-redirector.component';
import { ReviewsComponent } from 'Content/components/reviews/reviews.component';
import { HasRemoteConfigResolver } from 'Shared/guards/hasRemoteConfig.resolver';
import { State } from 'Shared/classes/state';
import { FaqRedirectGuard } from 'Shared/guards/faq-redirect.guard';
import { ContentRedirectGuard } from 'Shared/guards/content-redirect.guard';
import { Trigger404Status } from 'Content/guards/trigger404Status.guard';
import { MultipleGuardsInSerial } from 'Shared/guards/multiple-guards-in-serial.guard';
import { HasInitialLoadedGuard } from 'Shared/guards/has-initial-loaded.guard';
import { ShareComponent } from 'Content/components/share/share.component';
import { getContentStates } from './content.states';
import { BlogsLandingPageComponent } from 'Content/components/blogs-landing-page/blogs-landing-page.component';

export const states: State[] = getContentStates({
  HasContentSegment,
  ContentComponent,
  SitemapComponent,
  BranchRedirectorComponent,
  ReviewsComponent,
  HasRemoteConfigResolver,
  FaqRedirectGuard,
  ContentRedirectGuard,
  Trigger404Status,
  MultipleGuardsInSerial,
  HasInitialLoadedGuard,
  BlogsLandingPageComponent,
  ShareComponent
});
