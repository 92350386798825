import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { LocationService } from 'Shared/services/location.service';
import { StateService } from 'Shared/services/state.service';
import { t } from 'Shared/utils/translations';
import { ConfigService } from 'Project/shared/services/config.service';

@Component({
  selector: 'bw-seo',
  template: ''
})
export class SeoComponent implements OnInit, OnDestroy {
  destroyFunctions: Function[] = [];
  document: any;

  // As most of these come from contentful, they are lowercase
  @Input()
  title: string;
  @Input()
  description: string;
  @Input()
  keywords: string;
  @Input()
  index: string | boolean;
  @Input()
  previewimage: string;
  @Input()
  canonicalurl: string;
  @Input() showTitle: boolean = true;

  showReviews: boolean;

  outputReviewSchemaOnUrls = ['home', 'checkout:tagonly', 'checkout:tag', 'checkout'];

  constructor(
    private windowRef: WindowRefService,
    private locationService: LocationService,
    private state: StateService,
    private configService: ConfigService
  ) {
    this.document = this.windowRef.nativeWindow.document;
  }

  /**
   * Add to the on destory functions
   */
  onDestroy(fn: Function): void {
    this.destroyFunctions.push(fn);
  }

  /**
   * Set a meta tag, either update or create
   */
  setMetaTag(name: string, content: string): void {
    let elem = this.document.querySelector(`meta[${name}]`);

    if (elem) {
      elem.parentNode.removeChild(elem);
    }

    if (content) {
      elem = this.document.createElement('meta');

      // Expect things like property='og:url' or name='description', we don't need the quotes here
      const attributes = name.replace(/"/g, '').split('=');
      elem.setAttribute(attributes[0], attributes[1]);
      elem.content = content;
      this.document.querySelector('head').appendChild(elem);
    }
  }

  /**
   * Add a link tag
   */
  setLinkTag(name: string, href: string, allowMultiple?: boolean, attrs?: any): void {
    const elems = this.document.querySelectorAll(`link[${name}]`);
    /*
    Some <link> tags can only be on the page once.
      eg < rel="canonical">
    However, some can be on the page multiple times (for different locales for example)
      eg
      <link rel="alternate" content="https://www.bloomandwild.fr/">
      <link rel="alternate" content="https://www.bloomandwild.de/">
      <link rel="alternate" content="https://www.bloomandwild.com/">
    */
    if (elems && !allowMultiple) {
      [].slice.call(elems).forEach((el) => {
        el.parentNode.removeChild(el);
      });
    }

    if (href) {
      const elem = this.document.createElement('link');
      // Expect things like property='og:url' or name='description', we don't need the quotes here
      const attributes = name.replace(/"/g, '').split('=');
      elem.setAttribute(attributes[0], attributes[1]);

      if (attrs) {
        Object.keys(attrs).forEach((key) => {
          elem.setAttribute(key, attrs[key]);
        });
      }

      elem.href = href;
      this.document.querySelector('head').appendChild(elem);
    }
  }

  /**
   * Get a meta tag
   */
  getMetaTag(name: string): string {
    const elem = this.document.querySelector(`meta[${name}]`);
    return elem ? elem.getAttribute('content') : '';
  }

  /**
   * Create a meta tag, then revert on destroy
   */
  createThenRevert(name: string, content: string, defaultContent: string): void {
    const oldContent = this.getMetaTag(name) || defaultContent || '';
    this.onDestroy(() => {
      this.setMetaTag(name, oldContent);
    });
    this.setMetaTag(name, content);
  }

  /**
   * Set the <title> and related meta
   * @param {String} title
   */
  setTitle(title: string): void {
    const defaultTitle = t('js.seo.meta.title') || '';
    const oldTitle = this.document.title || defaultTitle;
    this.onDestroy(() => {
      this.document.title = oldTitle;
    });

    // Set the title and related meta
    this.document.title = title || defaultTitle;
    this.createThenRevert('itemprop="name"', title, defaultTitle);
    this.createThenRevert('name="twitter:title"', title, defaultTitle);
    this.createThenRevert('property="og:title"', title, defaultTitle);
  }

  /**
   * Set the description
   * @param {String} desc
   */
  setDescription(desc: string): void {
    const defaultDescription = t('js.seo.meta.description') || '';
    const description = desc || defaultDescription;
    this.createThenRevert('name="description"', description, defaultDescription);
    this.createThenRevert('property="og:description"', description, defaultDescription);
    this.createThenRevert('name="twitter:description"', description, defaultDescription);
  }

  /**
   * Set the description
   * @param {string} previewImageURL
   */
  setSocialShareMeta(previewImageURL: string): void {
    const config = this.configService.getConfig();
    // the image has to be selected in Contentful, if not we'll use the default one
    const brandImages = {
      1: 'https://images.ctfassets.net/ucgi79tscdcj/3Xy7suoMqKfziU0cCLmhPS/904b998fe02afcb918e97680faa2ab64/share.jpg',
      2: 'https://images.ctfassets.net/ucgi79tscdcj/3ePznzf3Tcyq0S9HtNju8l/4adc89f67aba2410574d359d799cc2be/bloomon_log_uk_adc44002-7513-406f-8b49-14d9c0ec785d.png'
    };
    const defaultImageURL = `${brandImages[config.brandIdNumber]}?fit=fill\&w=1200\&h=630`;
    const fullImageURL = previewImageURL ? `https:${previewImageURL}?fit=fill\&w=1200\&h=630` : defaultImageURL;

    // og:url - esential meta tag for sharing
    const url = this.locationService.fullUrl();
    const ogURL = this.formatURL(url);

    // og:title and og:description already defined in setTitle
    this.createThenRevert('property="og:image"', fullImageURL, defaultImageURL);
    this.createThenRevert('name="twitter:card"', 'summary_large_image', '');
    this.createThenRevert('property="og:url"', ogURL, '');
  }

  /**
   * Set the keywords
   */
  setKeywords(keywrds: string): void {
    const defaultKeywords = t('js.seo.meta.keywords') || '';
    const keywords = keywrds || defaultKeywords;
    this.createThenRevert('name="keywords"', keywords, defaultKeywords);
  }

  /**
   * Set the canonical Url
   * @param {String} url
   */
  setCanonicalUrl(url: string): void {
    let canonicalURL = this.formatURL(url);

    // If URL exactly equals https://www.bloomandwild.com/de-at then
    // add a / to the URL - due to Google SEO requirements
    if (canonicalURL === 'https://www.bloomandwild.com/de-at') {
      canonicalURL += '/';
    }

    this.setLinkTag('rel="canonical"', canonicalURL);
    this.onDestroy((): void => {
      this.setLinkTag('rel="canonical"', '');
    });
  }

  /**
   * Formats URL for canonical and social meta use
   * - Mainly for 'old' style urls which include /content/
   * @param {String} url
   */
  formatURL(url: string): string {
    const formattedURL = url.split('?')[0].replace('/content/', '/').replace('%2F', '/').toLowerCase();
    return formattedURL.endsWith('/') ? formattedURL.slice(0, -1) : formattedURL; // if last character is / remove it
  }

  /**
   * Tell robots that we shouldn't index this page
   * - Mainly used with <bw-redirect> for old contentful pages
   * @param {String} shouldIndex
   */
  setRobotsIndex(shouldIndex: boolean): void {
    if (!shouldIndex) {
      this.setMetaTag('name="robots"', 'noindex');
    }
    this.onDestroy(() => {
      this.setMetaTag('name="robots"', '');
    });
  }

  /**
   * Should show the review schema?
   * @param {String} stateName
   * @returns {Boolean}
   */
  shouldShowReviewSchema(stateName): boolean {
    return !!this.outputReviewSchemaOnUrls.find((s) => s === stateName);
  }

  /**
   * On attribute change
   */
  ngOnInit(): void {
    this.index = this.index !== 'false' || !this.index;

    if (this.title && this.showTitle) {
      this.setTitle(this.title);
    }
    if (this.description) {
      this.setDescription(this.description);
    }
    if (this.keywords) {
      this.setKeywords(this.keywords);
    }

    this.setCanonicalUrl(this.canonicalurl || this.locationService.fullUrl());

    this.setRobotsIndex(this.index);

    this.showReviews = this.shouldShowReviewSchema(this.state.getCurrent().name);

    this.setSocialShareMeta(this.previewimage);
  }

  /**
   * On component destroy, run all the stored functions
   */
  ngOnDestroy(): void {
    this.destroyFunctions.forEach((fn) => fn());
  }
}
