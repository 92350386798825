
<div class="addon-card" [ngClass]="{ 'addon--selected' : addon.isSelected, 'addon--deselected': !addon.isSelected &amp;&amp; !addon.isSelectable &amp;&amp; shopType === 'default' }">
  <div class="addon__img"><img class="img--addon" (click)="onImageClick(addon)" [bwEcommImage]="addon.productCombinationImageUrls.length ? addon.productCombinationImageUrls[0] : addon.imageUrls[0]" width="1000" height="auto">
    <bw-icon *ngIf="showTick &amp;&amp; addon.isSelected" iconType="tick__selected"></bw-icon>
  </div>
  <div class="addon__contents">
    <p class="text-label--primary" *ngIf="addon?.type === 'standard_gift_card'; else otherAddons">Standard Card</p>
    <ng-template #otherAddons>
      <p class="text-label--primary">{{ addon.name }}</p>
    </ng-template>
  </div>
  <div class="addon__actions" *ngIf="shopType === 'default'">
    <!-- Addon Price-->
    <div class="addon__price">
      <p class="text-body--supporting" *ngIf="!addon?.price?.price">Free</p>
      <p class="text-body--supporting" *ngIf="!pricingExperimentActive &amp;&amp; addon?.price?.price">{{ addon.price | price }}</p>
      <ui-price *ngIf="pricingExperimentActive &amp;&amp; addon?.price?.price" [price]="addon.price | price" [price_before_discount]="addon.price.discount &gt; 0 ? (addon.price.original | price) : ''" price_type="supporting"></ui-price>
    </div>
    <!-- Addon Action buttons-->
    <div class="addon-requirement__no-min" *ngIf="!addonRequirement || !addonRequirement.min">
      <bw-button *ngIf="!addon.isSelected &amp;&amp; addon.isSelectable" buttonStyle="secondary" size="small" (click)="selectAddon(addon)" bwTrackAs="component.product-addon-list-modal.add">Add</bw-button>
      <bw-button *ngIf="addon.isSelected" buttonStyle="text-link" size="small" (click)="deselectAddon(addon)" bwTrackAs="component.product-addon-list-modal.remove">Remove</bw-button>
    </div>
    <div class="addon-requirement__min" *ngIf="addonRequirement?.min">
      <bw-button *ngIf="!addon.isSelected" buttonStyle="secondary" size="small" (click)="selectAddon(addon)" bwTrackAs="component.product-addon-list-modal.addon-select">Select</bw-button>
      <p class="addon__added" *ngIf="addon.isSelected">Selected</p>
    </div>
  </div>
  <div class="addon__actions" *ngIf="shopType === 'subscription'">
    <!-- Addon Price-->
    <div class="addon__price">
      <!-- Show price for all addons-->
      <p *ngIf="!addon?.price?.price &amp;&amp; !addon.isSelected">{{ addon.price | price }}</p>
      <p *ngIf="addon?.price?.price &amp;&amp; addonRequirement?.defaultAddonId !== addon.id">{{ addon.price | price }}</p>
      <!-- Required Addon with struck price-->
      <div class="addon__price-original" *ngIf="!addon?.price?.price &amp;&amp; addon.isSelected &amp;&amp; addonRequirement?.defaultAddonId === addon.id">
        <p class="addon__price-original__price" *ngIf="addon?.price?.original">{{ addon.price | price:'original' }}</p>
        <p class="addon__price-original__free-label">{{ addon.price | price }}</p>
      </div>
    </div>
    <!-- Addon Action buttons-->
    <div class="addon-requirement__no-min" *ngIf="!addonRequirement || !addonRequirement.min">
      <bw-button *ngIf="!addon.isSelected &amp;&amp; addon.isSelectable" buttonStyle="modal-primary" size="small" (click)="selectAddon(addon)" bwTrackAs="component.product-addon-list-modal.add">Add</bw-button>
      <bw-button *ngIf="addon.isSelected" buttonStyle="text-link" size="small" (click)="deselectAddon(addon)" bwTrackAs="component.product-addon-list-modal.remove">Remove</bw-button>
    </div>
    <div class="addon-requirement__min" *ngIf="addonRequirement?.min">
      <div class="addon-requirement__included" *ngIf="!addonRequirement || !addonRequirement.min">
        <bw-button *ngIf="!addon.isSelected &amp;&amp; addon.isSelectable" buttonStyle="modal-primary" size="small" (click)="selectAddon(addon)" bwTrackAs="component.product-addon-list-modal.add">Add</bw-button>
        <bw-button *ngIf="addon.isSelected" buttonStyle="text-link" size="small" (click)="deselectAddon(addon)" bwTrackAs="component.product-addon-list-modal.remove">Remove</bw-button>
      </div>
      <div class="addon-requirement__not-included" *ngIf="addonRequirement?.min">
        <bw-button *ngIf="!addon.isSelected" buttonStyle="modal-primary" size="small" (click)="selectAddon(addon)" bwTrackAs="component.product-addon-list-modal.addon-select">Select</bw-button>
        <p class="addon__added" *ngIf="addon.isSelected">Selected</p>
      </div>
    </div>
  </div>
</div>