import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from 'Shared/classes/order';
import * as dayjs from 'dayjs';
import { Discount } from 'Shared/classes/discount';
import { User } from 'Shared/classes/user';
import { FeaturesService } from 'Shared/services/features.service';
import { LoyaltyService } from 'Shared/services/loyalty.service';
import { t, TranslationKey } from 'Shared/utils/translations';
import { Label } from 'Browse/components/product-card-label/product-card-label.component';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-order-summary',
  templateUrl: './order-summary.component.html'
})
export class OrderSummaryComponent implements OnInit {
  @Input()
  order: Order;
  @Input()
  user?: User;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  postcodeBeforeCityEnabled: boolean;

  constructor(
    private featuresService: FeaturesService,
    private loyaltyService: LoyaltyService
  ) {}

  /**
   * @description Get total addons
   * @returns {boolean}
   */
  get hasAddons(): boolean {
    return this.order?.addons?.length > 0 && this.order?.addons?.filter((addon): boolean => addon.type !== 'standard_gift_card').length > 0;
  }

  /**
   * @description Is user a loyalty scheme member
   * @returns {boolean}
   */
  get isLoyaltySchemeMember(): boolean {
    return this.loyaltyService.isLoyaltySchemeMember;
  }

  doublePointsLabels(): Label[] {
    return [{ showIcon: true, type: 'rewards-bonus-points', text: t('js.component.product-card.rewards-label' as TranslationKey) }];
  }

  resetDatesTime(date: dayjs.Dayjs): dayjs.Dayjs {
    return date.set('millisecond', 0).set('second', 0).set('minute', 0).set('hour', 0);
  }

  dateIsToday(date: dayjs.Dayjs): boolean {
    return this.resetDatesTime(date).isSame(this.resetDatesTime(dayjs(new Date())));
  }

  ngOnInit(): void {
    this.postcodeBeforeCityEnabled = this.featuresService.getFeature('ADDRESS_FIELDS').postcodeBeforeCity;
  }
}
