import {
  Component,
  ComponentFactoryResolver,
  Injector,
  ElementRef,
  Output,
  Input,
  EventEmitter,
  ComponentFactory,
  ComponentRef,
  DoCheck,
  OnDestroy,
  OnChanges
} from '@angular/core';

import { availableComponents } from 'Project/content/available-components';
import bwButtonInterceptor from 'Project/content/interceptors/bw-button.interceptor';
import bwCarouselInterceptor from 'Project/content/interceptors/bw-carousel.interceptor';
import bwSlideshowInterceptor from 'Project/content/interceptors/bwSlideshow.interceptor';
import { StateService, StateParams } from 'Shared/services/state.service';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';

@Component({
  selector: 'bw-content-display',
  template: ''
})
export class ContentDisplayComponent implements DoCheck, OnDestroy {
  private hostElement: HTMLElement;
  private embeddedComponentFactories: Map<string, ComponentFactory<any>> = new Map();

  private usedComponents: ComponentRef<any>[] = [];

  componentInterceptors: any = {};

  @Output()
  docRendered = new EventEmitter();

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    elementRef: ElementRef,
    private injector: Injector
  ) {
    this.componentInterceptors = {
      'bw-button': bwButtonInterceptor,
      'bw-carousel': bwCarouselInterceptor
    };
    this.hostElement = elementRef.nativeElement;
    availableComponents.forEach((component) => {
      const factory = componentFactoryResolver.resolveComponentFactory(component);
      this.embeddedComponentFactories.set(factory.selector, factory);
    });
  }

  @Input()
  set content(content) {
    this.ngOnDestroy();
    if (content) {
      this.build(content);
      this.docRendered.emit();
    }
  }

  private build(content): void {
    this.hostElement.innerHTML = content || '';

    // loops through components with interceptors
    Object.keys(this.componentInterceptors).forEach((selector) => {
      const foundElements = this.hostElement.querySelectorAll(selector);
      Array.prototype.forEach.call(foundElements, (item) => {
        this.componentInterceptors[selector](item);
      });
    });

    if (!content) {
      return;
    }

    this.embeddedComponentFactories.forEach((factory, selector) => {
      // // console.log('selector', selector);
      const embeddedComponentElements = [].slice.call(this.hostElement.querySelectorAll(selector));

      for (const element of embeddedComponentElements) {
        // convert NodeList into an array, since Angular dosen't like having a NodeList passed
        // for projectableNodes
        const projectableNodes = [Array.prototype.slice.call(element.childNodes)];

        const embeddedComponent = factory.create(this.injector, projectableNodes, element);

        // apply inputs into the dynamic component
        // only static ones work here since this is the only time they're set
        for (const attr of (element as any).attributes) {
          embeddedComponent.instance[attr.nodeName] = attr.nodeValue;
        }
        this.usedComponents.push(embeddedComponent);
      }
    });
  }

  ngDoCheck(): void {
    this.usedComponents.forEach((comp) => comp.changeDetectorRef.detectChanges());
  }

  ngOnDestroy(): void {
    // destroy these components else there will be memory leaks
    this.usedComponents.forEach((comp) => {
      try {
        comp.destroy(); // If we are super quick navigating the site, components will load, but unable to be destroyed fully
      } catch (e) {
        console.warn(e);
      }
    });
    this.usedComponents.length = 0;
  }
}
