import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

@Injectable()
export class MultipleGuardsInSerial implements CanActivate {
  constructor(private injector: Injector) {}

  /**
   * Run any routeGuards in serial, and if one fails, return false
   * @param route
   */
  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    let promise = Promise.resolve(true);
    (route.routeConfig['routeGuards'] || []).forEach(guardToken => {
      const guard = this.injector.get(guardToken);
      promise = promise.then(opt => {
        return guard
          .canActivate(route)
          .then(r => (r ? Promise.resolve(r) : Promise.reject(false)))
          .catch(() => Promise.reject(false));
      });
    });

    return promise.catch(() => Promise.resolve(false));
  }
}
