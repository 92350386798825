import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { User, UserService } from 'Shared/services/user.service';
import { ContentService } from 'Shared/services/content.service';
import { BwForm, BwFormControl, BwFormDefintion } from 'Shared/classes/bw-form';
import { Email, EmailPreference } from 'Shared/classes/email';
import { AuthTypes } from 'Shared/components/modals/auth-modal/auth';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { Order } from 'Shared/classes/order';
import { Error } from 'Shared/classes/error';
import { EmailService } from 'Shared/services/email.service';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';
import { FeaturesService } from 'Shared/services/features.service';
import { LoyaltyService } from 'Shared/services/loyalty.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { ModalService } from 'Shared/services/modal.service';
import { RewardsInfoModalComponent } from 'Checkout/components/rewards-info-modal/rewards-info-modal.component';

type LoginModalControls = {
  email: BwFormControl<string>;
  password: BwFormControl<string>;
};

/* bw:view-encapsulation */
@Component({
  selector: 'bw-auth-login',
  templateUrl: './auth-login.component.html'
})
export class AuthLoginComponent extends BwForm<LoginModalControls> implements OnInit, OnChanges, OnDestroy {
  @Input() defaultEmail: string;
  @Input() enableRegister: boolean = true;
  @Input() checkoutOrigin: boolean = false;
  @Input() origin: 'default' | 'checkout' | 'occasions' = 'default';
  @Input() enableRestart: boolean = false;
  @Input() order?: Order;
  @Input() showFullForm: boolean = false;
  @Input() formInvalid: boolean = false;
  @Input() fullOrigin: string; // Used for Joining Rewards experiment heap events

  @Output() loginSuccess: EventEmitter<{ authMethod: string }> = new EventEmitter<{ authMethod: string }>();
  @Output() loginFail: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectTab: EventEmitter<AuthTypes> = new EventEmitter<AuthTypes>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() continueAsGuest: EventEmitter<{ email: string; user: string }> = new EventEmitter<{
    email: string;
    user: string;
  }>();
  @Output() registerNewUser: EventEmitter<{ email: string; validateForm?: boolean }> = new EventEmitter<{
    email: string;
    validateForm?: boolean;
  }>();
  @Output() registerGuestUser: EventEmitter<{ email: string; user: string }> = new EventEmitter<{ email: string; user: string }>();
  @Output() didPassEmail: EventEmitter<string> = new EventEmitter<string>();
  @Output() didFailOnPassword: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() didFailOnUntrustworthyLogin: EventEmitter<Error> = new EventEmitter<Error>();
  @Output() didChangeForm: EventEmitter<'email' | 'password'> = new EventEmitter<'email' | 'password'>();
  @Output() didJoinRewards: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading: boolean = false;
  showFailedLogin: boolean = false;
  requestResetPasswordSent: boolean = false;
  showValidationMessage: boolean = true;
  showPasswordControl: boolean = false;
  identifiedUser: string;
  submitError: Error;
  checkEmailOnInit: boolean = false;
  validateForm: boolean = false;
  isRewardsMember: boolean = false;
  loyaltyWelcomePoints: number;
  loyaltyOrderPoints: number;
  loyaltyPerksAlignment: string | null;
  showTandC: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private userService: UserService,
    private contentService: ContentService,
    private analyticsService: AnalyticsService,
    private emailService: EmailService,
    private optimizelyService: OptimizelyService,
    private featuresService: FeaturesService,
    private loyaltyService: LoyaltyService,
    private experimentService: ExperimentsService,
    private modalService: ModalService
  ) {
    super();
  }

  /**
   * Check if we want to show the Joining Rewards CTAs
   * Not already a rewards member and the experiment is active
   */
  get isJoiningRewardsOnLogin(): boolean {
    return this.inJoiningRewardsExperiment && !this.isRewardsMember;
  }

  /**
   * Check if the joining rewards experiment + FF is active and applicable
   */
  get inJoiningRewardsExperiment(): boolean {
    return (
      this.featuresService.getFeature('JOINING_REWARDS').active &&
      (this.experimentService.isActive('AUTO_OPT_IN_TO_REWARDS', 1) || this.experimentService.isActive('AUTO_OPT_IN_TO_REWARDS', 2))
    );
  }

  /**
   * On init
   */
  ngOnInit(): Promise<void> {
    super.ngOnInit();
    const { showTandC, perksAlignmentOverride } = this.featuresService.getFeature('JOINING_REWARDS');
    this.loyaltyPerksAlignment = perksAlignmentOverride ?? null;
    this.showTandC = showTandC;
    this.setPasswordValidators();

    if (this.defaultEmail?.length) {
      this.get('email')?.setValue(this.defaultEmail);
    }

    return this.loyaltyService.getLoyaltyWelcomePoints().then((points): void => {
      this.loyaltyWelcomePoints = points;

      this.calculateLoyaltyPoints();
    });
  }

  /**
   * On changes
   * @param changes
   */
  ngOnChanges(): void {
    if (this.formInvalid && !this.order.isSubscription() && this.valid) {
      this.continueGuest();
    }

    if (this.formInvalid && !this.valid) {
      this.get('email').markAsTouched();
    }

    if (this.formInvalid && this.order.isSubscription() && this.valid) {
      this.checkEmailOnInit = true;
    }

    if (this.enableRestart) {
      this.goBack();
    }

    // Calculate loyalty points
    this.calculateLoyaltyPoints();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * Build Form - BwForm required implementation
   */
  buildForm(): BwFormDefintion<LoginModalControls> {
    // Set the correct validation based on the different form types
    return {
      email: new FormControl('', {
        validators: [Validators.required, Validators.email]
      }),
      password: new FormControl('', {
        validators: []
      })
    };
  }

  /**
   * BwForm required implementation
   */
  getObject(): void {}

  /**
   * BwForm required implementation
   */
  setObject(): void {}

  /**
   * Track in heap
   * @param isSuccessful
   */
  trackSuccessfulLogin(isSuccessful: boolean): void {
    this.optimizelyService.trackEvent('successful_login');
    this.analyticsService.trackInHeap('authLoginSuccess', {
      isSuccessful
    });
  }

  /**
   * Show or hide loading spinner during login events
   * @param {boolean} load
   */
  onLoading(load: boolean): void {
    this.loading = load;
  }

  /**
   * Select a tab
   * @param {string} tab
   */
  tab(tab: AuthTypes): void {
    this.selectTab.emit(tab);
  }

  /**
   * Go back to email entry step
   * Reset password value and revalidates field
   */
  goBack(): void {
    this.checkEmailOnInit = false;
    this.showPasswordControl = false;
    this.showFailedLogin = false;
    this.get('password')?.setValue('');
    this.setPasswordValidators();
    this.didChangeForm.emit('email');
  }

  /**
   * Register new user flow
   */
  goToRegisterNewUser(event: { email: string; validateForm?: boolean }): void {
    return this.registerNewUser.emit(event);
  }

  /**
   * Register new user flow
   */
  goToRegisterGuestUser(guest: { email: string; user: string }): void {
    return this.registerGuestUser.emit(guest);
  }

  /**
   * Request reset password email
   */
  resetPassword(): Promise<void> {
    this.showValidationMessage = true;
    this.requestResetPasswordSent = false;
    this.showFailedLogin = false;
    this.get('password').clearValidators();
    this.get('password').reset();
    if (this.controls.email.getError('failedLogin')) {
      this.controls.email.setErrors(null);
    }
    this.get('email').markAsTouched();

    if (this.invalid) {
      return Promise.resolve(null);
    }

    this.loading = true;
    const email = new Email(this.get('email').value);
    return this.userService
      .requestPasswordReset(email)
      .then((): void => {
        this.loading = false;
        this.requestResetPasswordSent = true;
      })
      .catch((): void => {
        this.loading = false;
        this.requestResetPasswordSent = true;
      });
  }

  /**
   * On did fail login
   */
  didFailLogin(): void {
    this.tab('login');
  }

  onFailedPassword(): void {
    this.showFailedLogin = true;
    this.showValidationMessage = true;
    this.get('password').setErrors({ invalid: true });
    // Validate form forces formControls to be updated and revalidated within child components
    this.updateValueAndValidity();
    this.markAllAsTouched();
    this.didFailOnPassword.emit();
  }

  /**
   * Continue as guest
   */
  continueGuest(): void {
    if (this.get('email')?.valid && this.get('email')?.value) {
      this.analyticsService.trackInHeap('clickContinueAsGuest', {
        hasAccount: true
      });
      this.continueAsGuest.emit({ email: this.get('email').value, user: this.identifiedUser });
    }

    // fails due to failure to login in via third party
    this.loginFail.emit();
  }

  /**
   * Form focus event
   */
  formFocus(): void {
    this.showValidationMessage = true;

    if (this.showFailedLogin) {
      if (this.controls.password.getError('failedLogin')) {
        this.controls.password.setErrors(null);
      }
      if (this.controls.email.getError('failedLogin')) {
        this.controls.email.setErrors(null);
      }
      this.markAllAsTouched();
    }
  }

  /**
   * Submit the form
   */
  submit(event?: { passwordValid: boolean; email: Email; preference: EmailPreference }): Promise<void> {
    this.requestResetPasswordSent = false;
    this.showValidationMessage = true;
    this.showFailedLogin = false;

    this.markAsSubmitted();
    this.markAllAsTouched();

    if (this.invalid) {
      this.didFailLogin();
      return Promise.resolve();
    }

    this.loading = true;

    const email = this.get('email').value;
    const password = this.get('password').value;

    let emailPreferencePromise = Promise.resolve();

    // Updates preferences if user has opted in and changed preferences value
    if (this.featuresService.getFeature('OPTIN_LOGIN') && event?.preference && event.email.preference !== event.preference) {
      emailPreferencePromise = this.emailService.sendConsent(event.email, event.preference);
    }

    return emailPreferencePromise
      .then((): Promise<User> => this.userService.login(email, password))
      .then((user): Promise<void> => this.joinLoyaltyMembership(user))
      .then((): void => {
        this.contentService.refreshSegments();
      })
      .then((): void => {
        this.trackSuccessfulLogin(true);
        return this.loginSuccess.emit({ authMethod: 'email' });
      })
      .catch((e: Error): void => {
        this.loading = false;
        this.trackSuccessfulLogin(false);
        this.submitError = e;
        if (e?.kind === 'untrustworthyLoginAttempt') {
          return this.didFailOnUntrustworthyLogin.emit(e);
        }

        if (!this.showFullForm) {
          return this.onFailedPassword();
        }

        this.showFailedLogin = true;
        this.showValidationMessage = false;
        this.get('password').setErrors({ failedLogin: true });
        this.get('email').setErrors({ failedLogin: true });
        this.markAllAsTouched();

        this.didFailLogin();
      });
  }

  /**
   * On cancel
   */
  onCancel(): void {
    this.cancel.emit();
  }

  /**
   * validate and lookup user based on email
   */
  submitEmail(event: { identifier: string; isRewardsMember?: boolean; validateForm?: boolean }): void {
    this.identifiedUser = event.identifier;
    this.isRewardsMember = event.isRewardsMember;
    this.showPasswordControl = true;
    this.setPasswordValidators();

    this.validateForm = event?.validateForm ?? false;

    this.didPassEmail.emit(this.identifiedUser);
    this.didChangeForm.emit('password');
  }

  /**
   * Open rewards info modal
   * @returns {Promise<void>}
   */
  openRewardsInfoModal(): Promise<void> {
    return this.modalService
      .show(RewardsInfoModalComponent, {
        trackingKey: 'rewards-info-modal',
        class: 'modal-sm rewards-info-modal'
      })
      .catch((): void => {});
  }

  /**
   * Auto-enroll user into Rewards scheme
   * @returns {Promise<void>}
   */
  private joinLoyaltyMembership(user: User): Promise<void> {
    return user.isLoggedIn() && user.loyaltySchemeMembershipId === undefined && this.isJoiningRewardsOnLogin
      ? this.loyaltyService
          .joinLoyaltyMembership()
          .then((): void => {
            this.didJoinRewards.emit(true);
            this.analyticsService.trackJoiningRewards(this.fullOrigin);
          })
          .catch((): void => {})
      : Promise.resolve();
  }

  /**
   * Set password validators
   */
  private setPasswordValidators(): void {
    if (this.get('password')) {
      const validators = !this.showFullForm && !this.showPasswordControl ? null : [Validators.required];
      this.get('password').setValidators(validators);
      this.get('password').updateValueAndValidity();
    }
  }

  /**
   * Calculate loyalty points
   */
  private calculateLoyaltyPoints(): void {
    const order = this.order;
    const price = Order.calculateOrderTotal({ ...order });
    this.loyaltyOrderPoints = this.loyaltyService.calculateTotalOrderLoyaltyPoints(price);
  }
}
