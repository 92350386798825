export default function ngTemplateInterceptor(item): any {
  // change all the ng-hrefs to href
  Array.prototype.forEach.call(item.querySelectorAll('[ng-href]'), linkElement => {
    linkElement.setAttribute('href', linkElement.getAttribute('ng-href'));
  });

  // also makes instagram logo an svg
  item
    .querySelector('.insta-button-logo')
    .setAttribute('src', '/assets/brand-icons/social-instagram-brand-secondary.svg');

  return item;
}
