import { Injectable } from '@angular/core';
import { ConfigModelService } from 'Shared/models/config-model.service';
import { environment } from 'Environments/environment';
import { Purchase } from 'Shared/classes/purchase';
import { Country } from 'Shared/classes/country';
import { LocationService } from 'Shared/services/location.service';
import { StateService } from 'Shared/services/state.service';

import { LocalConfig, ForCountryConfig } from './config.service.typings';
import { RemoteConfig, StripeAccount } from 'Shared/models/config-model.service.typings';
import * as dayjs from 'dayjs';

export type Config = LocalConfig &
  RemoteConfig & {
    serverTime: dayjs.Dayjs;
  };

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private environment: LocalConfig = environment;

  constructor(private remoteConfig: ConfigModelService, private locationService: LocationService, private stateService: StateService) {}

  /**
   * Get the config
   */
  getConfig(): Config {
    const remote = this.remoteConfig.getConfig();
    return Object.assign({}, this.environment, remote) as Config;
  }

  /**
   * Get whether the site is in preview mode
   */
  isPreviewMode(): boolean {
    const state = this.stateService.getCurrent();
    return !!state.params.preview && this.getConfig().environment !== 'production';
  }

  /**
   * Get the contentful content type so we can preview content
   * @returns content type
   */
  hasContentfulContentTypeId(): string {
    const state = this.stateService.getInitial();

    const contentType = state.params.contentfulContentTypeId || '';

    return contentType;
  }

  /**
   * Get the klarna Token
   * @param purchase
   */
  getKlarnaToken(purchase: Purchase): Promise<string> {
    return this.remoteConfig.getKlarnaToken(purchase);
  }

  /**
   * Get the paypalToken
   */
  getPaypalToken(shippingCountry: Country): Promise<string> {
    return this.remoteConfig.getPaypalToken(shippingCountry);
  }

  /**
   * Get Stripe account
   */
  getStripeAccount(shippingCountry: Country): Promise<StripeAccount> {
    return this.remoteConfig.getStripeAccount(shippingCountry);
  }

  /**
   * For country
   * @param country
   */
  forCountry(country: Country): ForCountryConfig {
    const countryConfig = this.getConfig().shippingCountries;
    return countryConfig ? countryConfig[country.id] || ({} as ForCountryConfig) : ({} as ForCountryConfig);
  }
}
