import {
  HostListener,
  Directive,
  ElementRef,
  AfterViewInit,
  AfterViewChecked
} from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';

@Directive({
  selector: '[elastic]'
})
export class ElasticDirective implements AfterViewInit, AfterViewChecked {
  element: ElementRef;
  constructor(public el: ElementRef, public windowRef: WindowRefService) {
    this.element = el;
  }

  handleOnInput(nativeElement): void {
    const borderTopWidth = this.windowRef.nativeWindow
      .getComputedStyle(nativeElement, null)
      .getPropertyValue('border-top-width')
      .replace('px', '');

    const borderBottomWidth = this.windowRef.nativeWindow
      .getComputedStyle(nativeElement, null)
      .getPropertyValue('border-bottom-width')
      .replace('px', '');

    // This will re-adjust the height before then calculating
    // Useful for when the box gets smaller due to the user removing text
    this.element.nativeElement.style.height = 'auto';

    const height =
      this.element.nativeElement.scrollHeight +
      parseInt(borderTopWidth, 10) +
      parseInt(borderBottomWidth, 10);
    this.element.nativeElement.style.height = height + 'px';
  }

  @HostListener('input', ['$event.target'])
  onInput(nativeElement: any): void {
    this.handleOnInput(nativeElement);
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   // this.handleOnInput(this.el.nativeElement);
    // }, 0);
  }
  ngAfterViewChecked(): void {
    // setTimeout(() => {
    this.handleOnInput(this.el.nativeElement);
    // }, 0);
  }
}
