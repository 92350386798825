import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'Project/shared/services/config.service';
import { ViewportDetectionService } from 'Project/shared/services/viewport-detection.service';
import { DeviceOs } from 'Shared/services/viewport-detection.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-apps-banner',
  templateUrl: './apps-banner.component.html'
})
export class AppsBannerComponent implements OnInit {

  deviceOs: DeviceOs;
  imageSrc: string;
  imageAlt: string;

  constructor(
    private viewportDetectionService: ViewportDetectionService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.deviceOs = this.viewportDetectionService.checkDeviceOs();
    this.buildImageStrings();
  }

  /**
   * Build the strings that are needed for the apps banner image
   */
  buildImageStrings(): void {
    const locale = this.configService.getConfig().site;
    const imageName = this.deviceOs === 'ios' ? 'download-on-app-store.png' : 'download-on-play-store.png';

    this.imageSrc = `assets/images/${locale}/${imageName}`;
    this.imageAlt = `Get it on ${this.deviceOs === 'ios' ? 'AppStore' : 'Google Play'}`;
  }
}
