import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from 'Shared/services/config.service';
import { WindowRefService } from 'Shared/services/window.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { ContentService } from 'Shared/services/content.service';
import { FacebookService } from 'Shared/services/facebook.service';
import { UntilIdleService } from 'Shared/services/until-idle.service';
import { UserService } from 'Shared/services/user.service';
import { AppboyService } from 'Shared/services/third-parties/appboy.service';
import { BingService } from 'Shared/services/third-parties/bing.service';
import { BranchService } from 'Shared/services/third-parties/branch.service';
import { FacebookMarketingService } from 'Shared/services/third-parties/facebook-marketing.service';
import { TiktokMarketingService } from 'Shared/services/third-parties/tiktok-marketing.service';
import { GaService } from 'Shared/services/third-parties/ga.service';
import { GtmService } from 'Shared/services/third-parties/gtm.service';
import { GtagService } from 'Shared/services/third-parties/gtag.service';
import { GtagServiceGA4 } from 'Shared/services/third-parties/gtag-ga4.service';
import { HeapService } from 'Shared/services/third-parties/heap.service';
import { HotjarService } from 'Shared/services/third-parties/hotjar.service';
import { PinterestService } from 'Shared/services/third-parties/pinterest.service';
import { QuoraService } from 'Shared/services/third-parties/quora.service';
import { SnapchatService } from 'Shared/services/third-parties/snapchat.service';
import { TrustedShopsService } from 'Shared/services/third-parties/trusted-shops.service';
import { TvsquaredService } from 'Shared/services/third-parties/tvsquared.service';
import { DrtvService } from 'Shared/services/third-parties/drtv.service';
import { AppsFlyerService } from 'Shared/services/third-parties/appsflyer.service';
import { ZyperService } from 'Shared/services/third-parties/zyper.service';
import { ICookieConsent } from 'Shared/interfaces/cookie-consent';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { RecentlyViewedService } from 'Shared/services/recently-viewed.service';
import { InflcrService } from 'Shared/services/third-parties/inflcr.service';
import { FeaturesService } from 'Shared/services/features.service';
import { PartnerizeService } from './partnerize.service';
import { GoogleService } from 'Shared/services/third-parties/google.service';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';

export { ICookieConsent } from 'Shared/interfaces/cookie-consent';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  private window: any;
  config: any;
  debug: boolean = false;

  private defaultCookieConsent: ICookieConsent = {
    marketing: false,
    necessary: true,
    preferences: false,
    statistics: false
  };
  cookieConsent$: BehaviorSubject<ICookieConsent> = new BehaviorSubject(this.defaultCookieConsent);
  previousConsent: ICookieConsent;

  intialised: any = {
    marketing: false,
    necessary: true,
    preferences: false,
    statistics: false
  };

  constructor(
    private windowRef: WindowRefService,
    private appboyService: AppboyService,
    private bingService: BingService,
    private branchService: BranchService,
    private facebookMarketingService: FacebookMarketingService,
    private tiktokMarketingService: TiktokMarketingService,
    private appsFlyerService: AppsFlyerService,
    private pinterestService: PinterestService,
    private quoraService: QuoraService,
    private snapchatService: SnapchatService,
    private gtmService: GtmService,
    private gtagService: GtagService,
    private gtagServiceGA4: GtagServiceGA4,
    private tvsquaredService: TvsquaredService,
    private drtvService: DrtvService,
    private facebookService: FacebookService,
    private heapService: HeapService,
    private trustedShopsService: TrustedShopsService,
    private hotjarService: HotjarService,
    private gaService: GaService,
    private zyperService: ZyperService,
    private analyticsService: AnalyticsService,
    private untilIdleService: UntilIdleService,
    private configService: ConfigService,
    private userService: UserService,
    private contentService: ContentService,
    private experimentService: ExperimentsService,
    private recentlyViewedService: RecentlyViewedService,
    private inflcrService: InflcrService,
    private featuresService: FeaturesService,
    private partnerizeService: PartnerizeService,
    private googleService: GoogleService,
    private optimizelyService: OptimizelyService
  ) {
    this.window = this.windowRef.nativeWindow;
    this.config = this.configService.getConfig();
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
    // Update the observable with the consent stored in the cookie (if any) so we don't need to always wait the cookiebot
    this.cookieConsent$.next(this.getConsentCookie());
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<cookiebot>', ...args);
    }
  }

  /**
   * To autologin users that accepted preferences cookie we need to run this first
   * CookiebotOnConsentReady event triggers a bit too late
   *
   * Check for existing cookie and extract user preferences
   * Initialise necessary
   * Initialise preferences
   */
  init(options: { userSlug?: string; fingerprint?: string } = {}): ICookieConsent {
    this.initNecessary(options);

    const consent = this.getConsentCookie();
    if (consent.preferences) {
      this.initPreferences();
    }

    this.log('Initialise Necessary');
    return consent;
  }

  /**
   * Used to intialise services if a consent is already set
   * No waiting for cookiebot to do it
   */
  initExistingConsent(): ICookieConsent {
    const consent = this.getConsentCookie();

    this.setCapturedCookies(consent);
    this.untilIdleService.queue(() => {
      this.runThirdPartyInlineScripts(consent);
    }, 0);

    this.initServices(consent);

    this.log('Initialise existing consent');
    return consent;
  }

  /**
   * Replacement for cookiebot's run scripts function
   */
  runThirdPartyInlineScripts(cookieConsent: ICookieConsent): void {
    const cookieConsentScripts = this.windowRef.nativeWindow.document.querySelectorAll('[type="text/plain"]');
    const body = this.windowRef.nativeWindow.document.querySelector('body');
    cookieConsentScripts.forEach((s) => {
      const consent = s.getAttribute('data-cookieconsent');
      if (cookieConsent[consent]) {
        const script = s.cloneNode(true);
        const parent = s.parentElement;
        parent.removeChild(s);
        script.setAttribute('type', 'text/javascript');
        script.defer = false;
        body.appendChild(script);
      }
    });
  }

  /**
   * Get the consent cookie and extract the user preferences
   */
  getConsentCookie(): ICookieConsent {
    const cookie = this.windowRef.getCookie('CookieConsent') || '';

    return {
      necessary: true,
      preferences: cookie.indexOf('preferences:true') > -1,
      marketing: cookie.indexOf('marketing:true') > -1,
      statistics: cookie.indexOf('statistics:true') > -1
    };
  }

  /**
   * Subscribes to CookiebotOnConsentReady event
   * and initialises services based on user's preference
   */
  onConsentReady(): Observable<any> {
    return fromEvent(this.window, 'CookiebotOnConsentReady').pipe(
      map(() => {
        // get the cookie consent
        const cookieConsent = this.window.Cookiebot?.consent ?? this.defaultCookieConsent;
        this.cookieConsent$.next(cookieConsent);

        // Run the scripts in index.pug if any
        if (this.window.Cookiebot) {
          this.window.Cookiebot.runScripts();
        }

        // Enable services based on the cookie consent
        this.setCapturedCookies(cookieConsent);
        this.handleConsentChange(this.previousConsent, cookieConsent);
        this.initServices(cookieConsent);

        this.previousConsent = { ...cookieConsent };

        // Log cookie consent
        this.log('CookiebotOnConsentReady', cookieConsent);
        return cookieConsent;
      })
    );
  }

  /**
   * Handles the consent change
   * @param previousConsent
   * @param cookieConsent
   */
  handleConsentChange(previousConsent: ICookieConsent, cookieConsent: ICookieConsent): void {
    // When the marketing consent changes during the same session from accepted to rejected
    // set the optimize cookie as hash and reload
    // When the statistics or marketing consent changes during the same session from accepted to rejected, only reload

    if (this.featuresService.getFeature('GA4_CONSENT').active) {
      this.gtagServiceGA4.toggleGtagConsent(cookieConsent);
    }

    this.appboyService.toggleGoogleConsent(cookieConsent);

    const marketingChanged = previousConsent?.marketing && !cookieConsent.marketing;
    const statisticsChanged = previousConsent?.statistics && !cookieConsent.statistics;

    if (marketingChanged || statisticsChanged) {
      this.window.location.reload();
    }
  }

  /**
   * Initialise services
   * @param cookieConsent
   */
  initServices(cookieConsent: ICookieConsent): void {
    if (cookieConsent.statistics) {
      this.initStatistics();
    }

    if (cookieConsent.marketing) {
      this.initMarketing();
    }

    if (cookieConsent.preferences) {
      this.initPreferences();
    }

    this.toggleMarketing(cookieConsent.marketing);
  }

  /**
   * Initialise necessary services
   */
  initNecessary(options: { userSlug?: string; fingerprint?: string } = {}): void {
    this.untilIdleService.queue(() => {
      this.gtmService.init();
      this.log('Necessary Intialised: GTM');
    }, 1);

    if (this.featuresService.getFeature('GA4_CONSENT').active) {
      this.untilIdleService.queue(() => {
        this.gtagServiceGA4.initGtagConsent();
        this.log('Necessary Intialised: GA4 Consent defaults');
      }, 1);
    }
  }

  /**
   * Initialise statistics services
   */
  initStatistics(): void {
    if (this.intialised.statistics) {
      return;
    }
    if (this.config.optimizelyEnabled && this.optimizelyService.init) {
      this.untilIdleService.queue(() => {
        this.optimizelyService.trackingEnabled = true;
        this.optimizelyService.statisticsCookieAccepted = true;

        /**
         * On cookie acceptance we need to re-init the optimizely client
         * to make sure we are using the correct event dispatcher
         */
        this.optimizelyService.init().then(() => {
          // Optimizely needs to redecide the user's bucketing
          this.optimizelyService.redecide();
        });

        this.log('Statistics Intialised: Optimizely');
      }, 1);
    }

    this.untilIdleService.queue(() => {
      this.initHeap();
      this.log('Statistics Intialised: Heap');
    }, 1);

    if (this.configService.getConfig().trustedShopEnabled) {
      this.untilIdleService.queue(() => {
        this.trustedShopsService.init();
        this.log('Statistics Intialised: Trusted Shops');
      }, 0.5);
    }

    if (this.configService.getConfig().hotjarEnabled) {
      this.untilIdleService.queue(() => {
        this.hotjarService.init();
        this.log('Statistics Intialised: Hotjar');
      }, 0.5);
    }

    if (this.configService.getConfig().tvSquaredEnabled) {
      this.tvsquaredService.init();
      this.log('Statistics Intialised: TV Squared');
    }

    if (this.configService.getConfig().drTvEnabled) {
      this.untilIdleService.queue(() => {
        this.drtvService.init();
        this.log('Statistics Intialised: DRTV');
      }, 0);
    }

    this.untilIdleService.queue(() => {
      this.analyticsService.track('general.app.loaded');
      this.log('Statistics Intialised: Analytics Service', 'general.app.loaded');
    });

    // Set this as initialised, so services will be loaded twice
    this.intialised.statistics = true;
  }

  /**
   * Initialise marketing services
   */
  initMarketing(): void {
    if (this.intialised.marketing) {
      return;
    }

    this.untilIdleService.queue(() => {
      this.appsFlyerService.init();
      this.log('Marketing Intialised:Appsflyer');
    });

    if (this.config.braze?.enabled) {
      this.untilIdleService.queue(() => {
        const consent = this.getConsentCookie();
        this.appboyService.init(consent);
        this.log('Marketing Intialised: Appboy/Braze');
      });
    }

    if (this.config.adwordsEnabled) {
      this.untilIdleService.queue(() => {
        this.gtagService.init();
        this.log('Marketing Intialised: Gtag');
      }, 1);
    }

    if (this.config.adwordsEnabled) {
      this.untilIdleService.queue(() => {
        this.gtagServiceGA4.init();
        this.log('Marketing Intialised: Gtag GA4');
      }, 1);
    }

    if (this.config.inflcrPixelEnabled) {
      this.untilIdleService.queue(() => {
        this.inflcrService.init();
        this.log('Marketing Intialised: Inflcr');
      }, 0.5);
    }

    if (this.config.bingUetEnabled) {
      this.untilIdleService.queue(() => {
        this.bingService.init();
        this.log('Marketing Intialised: Bing');
      }, 0.5);
    }

    this.untilIdleService.queue(() => {
      this.branchService.init();
      this.log('Marketing Intialised: Branch');
    }, 1);

    this.untilIdleService.queue(() => {
      this.facebookMarketingService.init();
      this.log('Marketing Intialised: Facebook Pixel');
    }, 1);

    if (this.config.tiktokPixelEnabled) {
      this.untilIdleService.queue(() => {
        this.tiktokMarketingService.init();
        this.log('Marketing Intialised: Tiktok Pixel');
      }, 1);
    }

    this.untilIdleService.queue(() => {
      this.pinterestService.init();
      this.log('Marketing Intialised: Pinterest');
    }, 1);

    if (this.configService.getConfig().quoraEnabled) {
      this.untilIdleService.queue(() => {
        this.quoraService.init();
        this.log('Marketing Intialised: Quora');
      }, 1);
    }

    if (this.configService.getConfig().snapchatEnabled) {
      this.untilIdleService.queue(() => {
        this.snapchatService.init();
        this.log('Marketing Intialised: Snapchat');
      }, 1);
    }

    if (this.configService.getConfig().partnerizeEnabled) {
      this.untilIdleService.queue(() => {
        this.partnerizeService.init();
        this.log('Marketing Intialised: Partnerize');
      }, 1);
    }

    // Set this as initialised, so services will be loaded twice
    this.intialised.marketing = true;
  }

  /**
   * Set serviceInitialized for services that use POST to track
   * @param consent
   */
  toggleMarketing(consent: boolean = false): void {
    this.untilIdleService.queue(() => {
      this.zyperService.setServiceInitalised(consent);
      this.log(`Marketing services: Zyper ${consent ? 'enabled' : 'disabled'}`);
    }, 1);
  }

  /**
   * Initialise prefrences services
   */
  initPreferences(): void {
    if (this.intialised.preferences) {
      return;
    }
    // Facebook login
    if (this.featuresService.getFeature('FACEBOOK_ACCESS')) {
      this.untilIdleService.queue(() => {
        this.facebookService.init();
        this.log('Preferences Intialised: Facebook login');
      }, 1);
    }

    if (this.featuresService.getFeature('GOOGLE_ACCESS')) {
      this.untilIdleService.queue(() => {
        this.googleService.initClient();
        this.log('Preferences Intialised: Google Client');
      }, 1);
    }

    // LocalStorage
    this.window.bwEnableLocalStorage();
    this.log('Preferences Intialised: Enabled localStorage');

    // Recently Viewed Service Init
    this.recentlyViewedService.init();
    this.log('Preferences Intialised: Recently viewed service');

    // Set this as initialised, so services will be loaded twice
    this.intialised.preferences = true;
  }

  /**
   * Intialise Heap
   */
  initHeap(): Promise<any> {
    if (!this.config.heapEnabled) {
      return Promise.resolve();
    }

    return this.heapService
      .init()
      .then(() => {
        const user = this.userService.getUser();
        this.heapService.identify(user);
        this.heapService.setEventProperties(this.analyticsService.dimensions);

        const contentSegment = this.contentService.contentSegments();
        if (contentSegment && contentSegment.length) {
          const sortedSegments = (contentSegment || []).sort((a, b) => a.id - b.id).map((s) => s.id);
          const segmentString = sortedSegments && sortedSegments.length ? `|CS${sortedSegments.join('|CS')}|` : '';
          this.analyticsService.setDimension('contentSegment', segmentString);
        }
      })
      .catch(() => {
        console.warn('Heap is not available');
      });
  }

  /**
   * Used to renew user consent
   */
  renewConsent(): void {
    if (this.window.Cookiebot) {
      this.window.Cookiebot.renew();
      this.log('Consent renew triggered');
    }
  }

  /**
   * Utility function to set cookies that are captured at load
   * @param cookieType
   */
  setCapturedCookies(cookieConsent: ICookieConsent): void {
    const capturedCookies = {
      marketing: ['_gaexp'],
      statistics: [],
      preferences: []
    };

    for (const [key, value] of Object.entries(capturedCookies)) {
      if (cookieConsent[key]) {
        value.forEach((cookie) => this.window.bwSetFakeCookieAsReal(cookie));
      }
    }
  }
}
