import { Injectable } from '@angular/core';
import { Country } from 'Shared/classes/country';
import { Product } from 'Shared/classes/product';
import { UpsellOption } from 'Shared/classes/upsell-option';
import { APISerialisedJSONResponse, BackendService } from 'Shared/services/backend.service';
import * as dayjs from 'dayjs';
import { Discount } from 'Shared/classes/discount';
import { ConfigService } from 'Shared/services/config.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { FeaturesService } from 'Shared/services/features.service';
import { ApiProductResponse } from 'Shared/classes/delivery';
import { PeakShippingService } from 'Shared/services/peak-shipping.service';

@Injectable({
  providedIn: 'root'
})
export class ProductModelService {
  constructor(
    private backend: BackendService,
    private configService: ConfigService,
    private experimentService: ExperimentsService,
    private featureService: FeaturesService,
    private peakShippingService: PeakShippingService
  ) {}

  /**
   * From the backend payload
   * @param res
   */
  static fromPayload(
    res:
      | APISerialisedJSONResponse<'/v2/availability/products'>['data'][number]
      | APISerialisedJSONResponse<'/v2/skus/:productId' | ApiProductResponse>,
    isFreePeakDeliveryMessagingEnabled: boolean
  ): Product {
    const attr =
      (res as APISerialisedJSONResponse<'/v2/availability/products'>['data'][number]).attributes ||
      (res as APISerialisedJSONResponse<'/v2/skus/:productId'>);

    const product = new Product(parseInt(res.id, 10));

    // simple assignments
    product.slug = attr.slug;
    product.name = attr.name;
    product.lilyFree = attr.lily_free;
    product.over18Only = attr.eighteen_plus;
    product.collectionName = attr.collection_name;
    product.collectionId = attr.collection_id;
    product.isPreorder = attr.is_pre_order;
    product.isInStock = attr.in_stock;
    product.description = attr.description;
    product.longDescription = attr.long_description;
    product.shortDescription = attr.email_description;
    product.filterAttributes = attr.sku_attributes;
    product.videoUrl = attr.video_url;
    product.videoThumbnail = attr.video_thumbnail_url;
    product.type = attr.product_kind;

    // assignments with some logic
    product.bundleOnly = attr.bundle_only || false;
    product.singleOnly = attr.single_only || false;
    product.subscriptionOnly = attr.subscription_only || false;
    product.doubleRewardPoints = attr.double_reward_points || false;
    product.latestShippingOptionCutoff = attr.latest_shipping_option_cut_off ? dayjs(attr.latest_shipping_option_cut_off) : undefined;
    product.rating = { count: attr.rating_count, average: attr.rating_average };
    product.isSelfPurchaseSubscription = (attr.tags || []).includes('self-purchase-subscription');

    // assignments handled by product
    product.setImageUrls(attr.media, attr.imageUrls, res.bouquet_images);
    product.setUpsells(attr.associated_skus);
    product.setDiscountAddon(attr.discount_info, attr.currency);
    product.setAppearingToAndFrom(attr.appearing_from, attr.appearing_to);
    product.setDeliverableToAndFrom(attr.deliverable_from, attr.deliverable_to);
    product.setAddonPrimaryImage(attr.media);
    product.setSkuImageSwap(attr.media);
    product.setAddonRequirements(attr.addon_requirements);
    product.setPricing(attr.currency, attr.prices);

    product.setLabelsAndTags(
      attr.tags,
      attr.label,
      attr.label_type,
      attr.double_reward_points === true,
      isFreePeakDeliveryMessagingEnabled
    );

    return product;
  }

  /**
   * Using the products API, get a product details, but we don't care for it's price
   * @param product
   * @param country
   */
  getProduct(product: Product, country: Country): Promise<Product> {
    return !product ? Promise.resolve(undefined) : this.getProducts(country).then((products) => products.find((p) => p.id === product.id));
  }

  /**
   * Get an older product - ie one that doesnt exist in the products API
   * @param product
   */
  getOlderProduct(product: Product): Promise<Product> {
    const isFreePeakDeliveryMessagingEnabled = this.peakShippingService.isFreePeakDeliveryMessagingEnabled();

    return this.backend
      .get(null, `/v2/skus/${product.id}` as '/v2/skus/:productId', {
        useUrlAsCache: true,
        responseIsJsonApi: true,
        params: {
          include: 'bouquet_images'
        }
      })
      .then((res): Product => ProductModelService.fromPayload(res, isFreePeakDeliveryMessagingEnabled));
  }

  /**
   * Get the products, regardless of dates
   * @param country
   * @param orderIndex
   * @param discount
   */
  getProducts(country: Country, orderIndex?: number, discount?: Discount, deliveryDate?: any): Promise<Product[]> {
    const firstItemInPurchase = !orderIndex || orderIndex === 0;
    const discountCode = discount ? discount.code : undefined;

    return this.backend
      .get(null, '/v2/availability/products', {
        useUrlAsCache: true,
        sendExperiments: ['API_'],
        params: {
          shipping_country_id: country.id,
          first_item_in_purchase: firstItemInPurchase,
          discount_code: discountCode,
          delivery_date: deliveryDate,
          enable_gift_vouchers: this.featureService.getFeature('GIFT_VOUCHERS').active
        }
      })
      .then((res): Product[] => {
        const isFreePeakDeliveryMessagingEnabled = this.peakShippingService.isFreePeakDeliveryMessagingEnabled();
        const products =
          res && res.data ? res.data.map((r): Product => ProductModelService.fromPayload(r, isFreePeakDeliveryMessagingEnabled)) : [];

        // Swap sku images if experiments are running:
        return products ? this.swapSkuImages(products) : [];
      });
  }

  linkProductUpsells(upsells: UpsellOption[], products: Product[]): UpsellOption[] {
    const upsellOptions: UpsellOption[] = [];

    // Upsells can either have a product
    upsells.forEach((u) => {
      const upsellProduct = products.find((p) => u.product && p.id === u.product.id);

      if (upsellProduct) {
        upsellOptions.push({
          id: u.id,
          type: u.type,
          product: upsellProduct,
          toggleText: u.toggleText,
          infoHeading: u.infoHeading,
          infoBody: u.infoBody,
          promoImage: u.promoImage
        });
      }
    });

    return upsellOptions;
  }

  /**
   * Get all products
   * @param country
   */
  getAvailableProducts(country: Country, orderIndex: number, discount?: Discount, deliveryDate?: string): Promise<Product[]> {
    return this.getProducts(country, orderIndex, discount, deliveryDate).then((prods) => {
      const serverTime = this.configService.getConfig().serverTime;

      const products = prods.filter(
        (product) => product.appearingFrom.unix() <= serverTime.unix() && product.appearingTo.unix() >= serverTime.unix()
      );

      return products.map((product) => {
        product.upsells = this.linkProductUpsells(product.upsells, products);
        return product;
      });
    });
  }

  /**
   *
   * @param products
   * @returns
   */
  private swapSkuImages(products: Product[]): Product[] {
    // Clone the products
    let mappedProducts: Product[] = products.slice();

    // Get the config value to find the experiment & tags
    const config = this.configService.getConfig().web_sku_image_swapping;
    const sku_image_swapping_config = config ? config : [];

    // Extract active experiment settings
    sku_image_swapping_config.forEach((experiment) => {
      const activeExperiment = this.experimentService.getExperiment(experiment.experiment);

      // Create experiment & variant check
      const validExperiment =
        activeExperiment && activeExperiment.variant === experiment.variant && activeExperiment.name === experiment.experiment;

      const activeTag = validExperiment ? experiment.tag : undefined;

      // Now that we have exteacted them remove them from the product.imageUrls array to
      // avoid duplication on the Grid both In & Out of the experiment.
      // This is what stops the duplication of images
      mappedProducts = this.setSwappableSkuImageInCorrectPosition(mappedProducts, experiment.tag, false);

      // Check for experiemnt validity
      if (validExperiment) {
        mappedProducts = this.setSwappableSkuImageInCorrectPosition(mappedProducts, activeTag, true);
      }
    });

    return mappedProducts;
  }

  /**
   *
   * @param mappedProducts
   * @param tag
   * @param swapImages
   * @returns
   */
  private setSwappableSkuImageInCorrectPosition(mappedProducts: Product[], tag: string, swapImages: boolean): Product[] {
    mappedProducts.forEach((product) => {
      const mappedSkuSwapImages = [];
      // NOTE: we are using currentTag here because we wanna make sure we only extract images tagged for a specific
      // experiment variant
      product.skuImageSwap.forEach((img) => (img.tag.indexOf(tag) > -1 ? mappedSkuSwapImages.push(img) : undefined));

      // This is where we are actually swapping the main image with the new one.
      mappedSkuSwapImages.forEach((img, index) => {
        if (swapImages) {
          product.imageUrls.splice(index, 1, img.url);
        }

        // If the tagged image is within the imageUrls array remove it
        if (!swapImages && product.imageUrls.indexOf(img.url) > -1) {
          product.imageUrls.splice(product.imageUrls.indexOf(img.url), 1);
        }
      });
    });

    return mappedProducts;
  }
}
