
<ui-box box_fill_mode="fill" box_align_x="end" box_background="secondary" box_space="near" box_border_radius="soften" [box_space_left]="{mobile: 'near', tablet: 'near', laptop: 'default', desktop: 'default'}" [box_space_right]="{mobile: 'near', tablet: 'near', laptop: 'default', desktop: 'default'}">
  <ui-stack class="text-body--default text-body--emphasis" stack_direction="x" stack_distribute="space-between" stack_align="center" stack_gap="tight">
    <ng-container #ref>
      <ng-content></ng-content>
    </ng-container>
    <ui-stack stack_gap="tight">
      <ng-container><span *ngIf="!message">{{campaignMessage}}</span><span *ngIf="message">{{message}}</span></ng-container>
      <ui-button button_type="primary" button_size="small" (clicked)="openMentionMeModal()" button_text_nowrap="true"><span *ngIf="buttonText">{{buttonText}}</span><span *ngIf="!buttonText">Read more</span></ui-button>
    </ui-stack>
  </ui-stack>
</ui-box>