import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { ConfigService } from 'Shared/services/config.service';
import { StateService } from 'Shared/services/state.service';
import { WindowRefService } from 'Shared/services/window.service';
@Injectable({
  providedIn: 'root'
})
export class InflcrService {
  debug: boolean = false;
  initPromise: Promise<any>;
  serviceInitialized: boolean = false;
  window: any;
  utmContent: string;

  constructor(
    private windowRef: WindowRefService,
    private domUtils: DomUtilsService,
    private configService: ConfigService,
    public stateService: StateService
  ) {
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<inflcr>', ...args);
    }
  }

  trackPurchase(): Promise<any> {
    const config = this.configService.getConfig();
    if (this.serviceInitialized && config.inflcrPixelEnabled) {
      const imagePixelSrc = `https://www.inflcr.co/aff_l?offer_id=${config.inflcrOfferId}&adv_sub=${this.utmContent}`;
      return this.domUtils
        .loadPixelElement(imagePixelSrc, 'inflcrpixel')
        .then(() => this.log('Inflcr: loadPixelElement'));
    }
  }

  /**
   * Init
   */
  init(): void {
    const params = this.stateService.getInitial().queryParams;
    this.serviceInitialized = params?.utm_source === 'brandnet';
    this.utmContent = params?.utm_content;
  }
}
