
<div class="bw_newdesign bwMosaic" *ngIf="products?.length" analytics-category="quickShop">
  <div>
    <div class="container template-five">
      <div class="row">
        <div class="col-xs-12 header">
          <h3>Best sellers</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-3" *ngFor="let product of products">
          <div class="fill-tile"><a uiSref="checkout.productpage" [uiParams]="{slug: product.slug, skuId: product.id}" analytics-label="product.name" bwTrackAs="components.quickshop.buttons.view"><img class="card-img-top product__image d-block" [bwEcommImage]="product.imageUrls[0]" width="200">
              <div class="content-holder">
                <h3><span class="title">{{product.name}}</span></h3>
                <div class="button-holder"><span class="price">{{product.getPrice() | price}}</span><span class="underlined">view</span></div>
              </div></a></div>
        </div>
      </div>
    </div>
  </div>
</div>