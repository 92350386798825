import { Component, OnInit, Input, ElementRef, OnChanges } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-formcontrol-validation-messages',
  templateUrl: './formcontrol-validation-messages.component.html'
})
export class FormcontrolValidationMessagesComponent implements OnInit, OnChanges {
  @Input() formSubmitted: boolean;
  @Input() errors: ValidationErrors;
  @Input() touched: boolean;

  errorMessages: {
    key: string;
    element: any;
  }[] = [];

  constructor(private elementRef: ElementRef) {}

  /**
   * When the parent form has been submitted, trigger the status change to show/hide errors
   * @param change
   */
  ngOnChanges(): void {
    this.onStatusChange(this.errors);
  }

  /**
   * On status change, pick the error to display
   * @param errors
   */
  onStatusChange(errors: ValidationErrors): void {
    const controlErrors = errors || {};
    // For ease, set all the errors to hide (or remove show technically...)
    this.errorMessages.forEach((errorMessage): void => {
      errorMessage.element.classList.remove('shown');
    });

    // Then find the first error message, that matches a known error, or use `invalid`
    if (Object.keys(controlErrors).length) {
      const errorToShow = this.errorMessages.find((err): any => errors[err.key] || err.key === 'invalid');
      if (errorToShow) {
        errorToShow.element.classList.add('shown');
      }
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    // To improve performance, I've opted to use some basic dom manipulation here
    [].slice.call(this.elementRef.nativeElement.querySelectorAll('[for]')).forEach((elem) => {
      this.errorMessages.push({
        key: elem.getAttribute('for'),
        element: elem
      });
    });
  }
}
