
<!-- ng-template to hold the content which is then projected into the ngTemplateOutput-->
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
<div class="bw-vertical-tabs">
  <!-- Mobile Tabs - As an accordion, with content underneath-->
  <ng-container *ngIf="(viewportSizeIs$ | async)?.mobile">
    <div class="vertical-tabs__labels">
      <div *ngFor="let item of tabs"><a class="vertical-tabs__label" [ngClass]="{'selected': selectedTab === item}" (click)="selectTab(item)" [attr.href]="item?.url"><span>{{item.title}}</span>
          <bw-icon iconType="chevron-down" *ngIf="selectedTab !== item"></bw-icon>
          <bw-icon iconType="chevron-up" *ngIf="selectedTab === item"></bw-icon></a>
        <div class="vertical-tabs__content" *ngIf="selectedTab === item" [ngClass]="{'vertical-tabs__separator': selectedTab === item}">
          <bw-content-display *ngIf="selectedTab.htmlContent" [content]="selectedTab.htmlContent"></bw-content-display>
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Desktop Tabs -  with content to the right-->
  <ng-container *ngIf="(viewportSizeIs$ | async)?.not?.mobile">
    <div class="vertical-tabs__labels"><a class="vertical-tabs__label" *ngFor="let item of tabs" [ngClass]="{'selected': selectedTab === item}" [attr.href]="item?.url" (click)="selectTab(item)"><span>{{item.title}}</span>
        <bw-icon iconType="arrow__right"></bw-icon></a></div>
    <div class="vertical-tabs__content" *ngIf="selectedTab">
      <bw-content-display *ngIf="selectedTab.htmlContent" [content]="selectedTab.htmlContent"></bw-content-display>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </ng-container>
</div>