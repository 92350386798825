import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ConfigModelService } from 'Shared/models/config-model.service';

@Injectable()
export class HasConfig implements CanActivate {
  constructor(private remoteConfig: ConfigModelService) {}

  /**
   * Can activate
   */
  canActivate(): Promise<any> {
    return this.remoteConfig.hasRemoteConfig().then(() => true); // Return true
  }
}
