
<ui-box class="gift-voucher-picker" [box_space_left]="{'mobile': 'default', 'tablet': 'default','laptop': 'near', 'desktop': 'near'}" [box_space_right]="{'mobile': 'default', 'tablet': 'default','laptop': 'near', 'desktop': 'near'}" [box_space]="{'mobile': 'none', 'tablet': 'none','laptop': 'near', 'desktop': 'near'}">
  <ui-grid grid_gap="default">
    <ui-column [columns]="6">
      <ui-box box_space="none" box_align_y="center">
        <ui-image image_src="{{giftVoucherImage}}" image_alt_text="Digital gift card" [preload_aspect_ratio]="'4:3'"></ui-image>
      </ui-box>
    </ui-column>
    <ui-column [columns]="6">
      <ui-box box_align_y="center" [box_space]="{'mobile': 'none', 'tablet': 'none', 'laptop': 'expanded', 'desktop': 'expanded'}">
        <ui-stack stack_direction="y" stack_align="start" stack_distribute="start" [stack_wrap]="false">
          <ui-heading [heading_type]="'functional-primary'" heading_title="Digital gift card" heading_subtitle="&lt;ol&gt;&lt;li&gt;Choose how much you’d like to give them.&lt;/li&gt;&lt;li&gt;We’ll email you their digital gift card within 10 minutes&lt;/li&gt;&lt;li&gt;You can either forward the email, or print out the paper gift certificate&lt;/li&gt;&lt;li&gt;Voila! A lovely surprise.&lt;/li&gt;&lt;/ol&gt;"></ui-heading>
          <form [formGroup]="radioForm" (ngSubmit)="submit()">
            <ui-stack stack_direction="y" stack_align="start" stack_distribute="start" [stack_wrap]="true">
              <ui-form-group group_title="Choose your amount">
                <ui-stack stack_direction="x" stack_align="start" stack_distribute="start" [stack_wrap]="true" stack_gap="tight">
                  <ui-chip *ngFor="let giftVoucher of giftVoucherSkus" formControlName="voucherValues" chip_type="radio" label="{{ giftVoucher.pricing[0].original | price }}" value="{{ giftVoucher.pricing[0].original / 100}}" (click)="setGiftVoucher(giftVoucher)"></ui-chip>
                </ui-stack>
              </ui-form-group>
              <ui-button button_size="large" button_type="primary" button_event_type="submit">Send</ui-button><span><span class="text-body--supporting text-body--emphasis">Heads up:</span><span class="text-body--supporting"> you can’t use a discount code or credit to buy a digital gift card.</span></span>
            </ui-stack>
            <bw-loading-spinner *ngIf="isLoading"></bw-loading-spinner>
          </form>
        </ui-stack>
      </ui-box>
    </ui-column>
  </ui-grid>
</ui-box>