import { Product } from 'Shared/classes/product';

export class FilterGroup {
  key: string;
  filterItems: FilterItem[] = [];
  isMutuallyExclusive: boolean = false;
}

export class FilterItem {
  active: boolean; // exists in list of applied filters
  groupKey: string; // 'packaging'
  key: string; // 'hand-tied'
  name: string; // 'Hand Tied Flowers'
  results: number; // Number
  filterType: FilterMethodType;
  data: any;
  visible: boolean;
  splodgeColour: string;
  requiresProductsRefresh: boolean; // if true we should call the BE to refresh the products, e.g. the date filter
}

export class ProductFilterAttributes {
  key: string;
  values: string[];

  constructor() {}
}

export interface ModalFilterResponse {
  activeFilters: FilterItem[];
  filteredProducts: Product[];
}

export type FilterMethodType = 'keyValue' | 'priceRange' | 'newIn' | 'date' | 'tag';
