import { Injectable } from '@angular/core';
import { NavLinks, NavItem, SubNavItem } from 'Shared/classes/nav-item';
import { ContentsModelService } from 'Shared/models/contents-model.service';
import { StateService } from 'Shared/services/state.service';
import { Country } from 'Shared/classes/country';
@Injectable({
  providedIn: 'root'
})
export class NavContentsModelService {
  navNames: string[] = ['navbar_links', 'navbar_sublinks'];
  constructor(private contents: ContentsModelService, private stateService: StateService) {}

  private subNavFromPayload(parentSlug: string, subnavs: any): SubNavItem[] {
    return (subnavs || [])
      .filter((sublink: SubNavItem) => {
        return sublink.parentSlug === parentSlug;
      })
      .map((sublink) => {
        const subNavItem = new SubNavItem();
        subNavItem.tags = sublink.tags || [];
        subNavItem.name = sublink.name;
        subNavItem.slug = sublink.slug;
        subNavItem.parentSlug = parentSlug;
        subNavItem.url = sublink.url;
        return subNavItem;
      });
  }

  private fromPayload(navs: any): NavLinks {
    const items = new NavLinks();
    (navs.navbar_links || []).forEach((navbarLink) => {
      const link = new NavItem();
      link.name = navbarLink.name;
      link.slug = navbarLink.slug;
      link.url = navbarLink.url;
      link.tags = navbarLink.tags || [];
      link.colour = navbarLink.colour;
      link.shortDescription = navbarLink.shortDescription;
      link.hiddenCheckout = navbarLink.hiddenCheckout;
      link.hiddenHomepage = navbarLink.hiddenHomepage;
      link.hiddenSideMenu = navbarLink.hiddenSideMenu;
      link.visibleDesktopTop = navbarLink.visibleDesktopTop;
      link.visibleMobileTop = navbarLink.visibleMobileTop;
      link.visibleTabletTop = navbarLink.visibleTabletTop;
      link.sublinks = this.subNavFromPayload(navbarLink.slug, navs.navbar_sublinks);

      items.links.push(link);
    });

    return items;
  }

  private getNavContents(country: Country): Promise<any> {
    return this.contents.getAll().then((res) => {
      const navObject = {};
      const navContentItems = (res || []).filter(
        (r) => this.navNames.indexOf(r.attributes.name) !== -1
      );
      navContentItems.forEach((navItem) => {
        const navOb = (navItem.attributes.content || []).find(
          (navItemContent) => +navItemContent.shipping_country_id === country.id
        );
        navObject[navItem.attributes.name] = navOb && navOb.links ? navOb.links : [];
      });
      return navObject;
    });
  }

  /**
   * Get Navs for Nav reasons
   */
  getNav(country: Country): Promise<NavLinks> {
    return this.getNavContents(country).then((navObject) => this.fromPayload(navObject));
  }

  /**
   * Get the subnavs for the page you're on
   */
  getActiveNavDescription(country: Country): Promise<any> {
    return this.getNavContents(country).then((navObject) => {
      const parentLink = (navObject.navbar_links || []).find(
        (n) => n.url === this.stateService.currentUrl()
      );

      if (parentLink) {
        return {
          name: parentLink.name,
          description: parentLink.shortDescription
        };
      }
      const childLink =
        (navObject.navbar_sublinks || []).find((n) => n.url === this.stateService.currentUrl()) ||
        {};
      const parentLinkFromChild = (navObject.navbar_links || []).find(
        (n) => n.slug === childLink.parentSlug
      );
      return parentLinkFromChild
        ? {
            name: parentLinkFromChild.name,
            description: parentLinkFromChild.shortDescription
          }
        : {};
    });
  }

  /**
   * Get the subnavs for the page you're on
   */
  getActiveSubnav(country: Country): Promise<SubNavItem[]> {
    // url comes with applied params, only want main url so mapping of sublinks can be defined
    const url = this.stateService.currentUrl().split('?');
    return this.getNavContents(country).then((navObject) => {
      let slug;
      const parentLink = (navObject.navbar_links || []).find((n) => n.url.split('?')[0] === url[0]);
      const childLink = (navObject.navbar_sublinks || []).find(
        (n) => n.url.split('?')[0] === url[0]
      );

      if (parentLink) {
        slug = parentLink.slug;
      }

      if (!slug && childLink) {
        slug = childLink.parentSlug;
      }

      return this.subNavFromPayload(slug, navObject.navbar_sublinks);
    });
  }

  /**
   * Get TopLink, typically FlowerJournal
   */
  getBurgerLinks(country: Country): Promise<any> {
    return this.contents.getAll().then((res) => {
      const topLinks = res.find((r) => r.attributes.name === 'burger');
      return (topLinks?.attributes?.content || []).find(
        (tl) => tl.shipping_country_id === country.id
      );
    });
  }
}
