import { translations } from 'Environments/js-translations';

// We always use the bloom and wild "en" as our base locale
import * as JSTranslations from 'Bandw/../../config/locales/new_en.json';
export type TranslationKey = keyof typeof JSTranslations;

/**
 * Translation eg ('$s hello %s', 'Tom hello world')
 * @param key
 * @param placeholders
 */
/* tslint:disable */
export function t(key: TranslationKey, ...placeholders: Object[]): string {
  const stringToUse = (translations[key] || key) as string;
  return stringToUse.replace(/%s/g, (match) => {
    if (placeholders.length) {
      return placeholders.shift() as string;
    }
    return match as string;
  });
}
/* tslint: enable */
