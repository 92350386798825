import { Component, Input } from '@angular/core';

@Component({
  selector: 'bw-form-validation-message',
  templateUrl: './form-validation-message.component.html',
  styleUrls: ['./form-validation-message.component.scss']
})
export class FormValidationMessageComponent {
  @Input() for: string;
  show: boolean = false;

  constructor() {}
}
