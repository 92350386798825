import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'Shared/services/config.service';

@Component({
  selector: 'bw-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  constructor(private configService: ConfigService) {}

  ngOnInit() {
    const config = this.configService.getConfig();
    // console.log('config', config);
  }
}
