import { trigger, state, style, transition, animate } from '@angular/animations';

export const ToastrAnimations = [
  trigger('showToast', [
    state(
      'true',
      style({
        transform: 'translateY(0%)'
      })
    ),
    state(
      'false',
      style({
        transform: '*',
        boxShadow: '0px 0px 0px #222'
      })
    ),
    transition('* => true', [animate('{{duration}}ms cubic-bezier(1,1.47,.25,.89)')]),
    transition('true => false', [animate('{{duration}}ms cubic-bezier(.94,-0.17,.56,.84)')])
  ]),
  trigger('toastProgress', [
    state(
      'true',
      style({
        transform: 'scaleX(0)'
      })
    ),
    state(
      'false',
      style({
        transform: 'scaleX(1)'
      })
    ),
    transition('* => true', [animate('{{duration}}ms linear')])
  ])
];
