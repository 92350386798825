import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ElementRef } from '@angular/core';
import { BlogTagsPipe } from 'Shared/pipes/blog-tags.pipe';
/**
 * Like the contentful-image component, without a template,
 * this is basically a directive that calls the correct pipe based on the type of the content
 * We can't use directives in the dynamic content viewer
 */
@Component({
  selector: '[bw-contentful-pipe]',
  template: '<ng-content></ng-content>',
  providers: [ BlogTagsPipe, TitleCasePipe ]
})
export class ContentfulPipeComponent implements OnInit {
  elem: HTMLElement;
  originalSrc: string;

  constructor(
    private el: ElementRef,
    private blogTagsPipe: BlogTagsPipe,
    private titleCasePipe: TitleCasePipe) {
  }

  replaceContentByType(): void {
    if (this.originalSrc === 'blogTag') {
      this.elem.textContent = this.blogTagsPipe.transform(this.elem.textContent ?? '');
    }

    if (this.originalSrc === 'blogTagTitleCase') {
       const blogTags: string = this.blogTagsPipe.transform(this.elem.textContent ?? '');
       this.elem.textContent = this.titleCasePipe.transform(blogTags)
    }
  }

  ngOnInit(): void {
    this.elem = this.el.nativeElement;
    this.originalSrc = this.elem.getAttribute('bw-contentful-pipe');
    this.replaceContentByType();
  }
}