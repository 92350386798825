import { NgModule } from '@angular/core';
import {
  OasysAlertModule,
  OasysBannerModule,
  OasysBreadcrumbModule,
  OasysButtonGroupModule,
  OasysButtonModule,
  OasysCardModule,
  OasysCarouselModule,
  OasysCheckboxModule,
  OasysChipModule,
  OasysDividerModule,
  OasysFormErrorsModule,
  OasysFormGroupModule,
  OasysHeadingModule,
  OasysHeroModule,
  OasysIconModule,
  OasysImageModule,
  OasysLayoutModule,
  OasysPillModule,
  OasysPillGroupModule,
  OasysPriceModule,
  OasysPromoCardModule,
  OasysSelectCardModule,
  OasysTextInputModule,
  OasysTextModule,
  SectionModule,
  OasysComboboxModule,
  OasysLabelModule
} from 'oasys-lib';
import { environment } from 'Environments/environment';

@NgModule({
  imports: [
    OasysBreadcrumbModule,
    OasysButtonModule,
    OasysButtonGroupModule,
    OasysCardModule,
    OasysDividerModule,
    OasysHeadingModule,
    OasysHeroModule,
    OasysIconModule,
    OasysLayoutModule,
    OasysTextModule,
    OasysCarouselModule,
    OasysBannerModule,
    OasysAlertModule,
    OasysPriceModule,
    SectionModule,
    OasysImageModule.forRoot(environment),
    OasysFormGroupModule,
    OasysTextInputModule,
    OasysChipModule,
    OasysFormErrorsModule,
    OasysPromoCardModule,
    OasysCheckboxModule,
    OasysSelectCardModule,
    OasysPillModule,
    OasysPillGroupModule,
    OasysComboboxModule,
    OasysLabelModule
  ],
  exports: [
    OasysBreadcrumbModule,
    OasysButtonModule,
    OasysButtonGroupModule,
    OasysCardModule,
    OasysDividerModule,
    OasysHeadingModule,
    OasysHeroModule,
    OasysIconModule,
    OasysLayoutModule,
    OasysTextModule,
    OasysCarouselModule,
    OasysBannerModule,
    OasysAlertModule,
    SectionModule,
    OasysImageModule,
    OasysFormGroupModule,
    OasysTextInputModule,
    OasysChipModule,
    OasysFormErrorsModule,
    OasysPriceModule,
    OasysPromoCardModule,
    OasysCheckboxModule,
    OasysPillModule,
    OasysPillGroupModule,
    OasysSelectCardModule,
    OasysComboboxModule,
    OasysLabelModule
  ]
})
export class OasysLibModule {}
