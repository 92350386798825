import { Component, OnInit, Input } from '@angular/core';
import { LocationService } from 'Shared/services/location.service';
import { StateName, StateService } from 'Shared/services/state.service';
import { WindowRefService } from 'Shared/services/window.service';

@Component({
  selector: 'bw-redirect',
  template: '',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  @Input()
  to: string;

  window: any;

  constructor(
    private locationService: LocationService,
    private stateService: StateService,
    private windowRef: WindowRefService
  ) {
    this.window = this.windowRef.nativeWindow;
  }

  /**
   * On load, using a url go to the state
   */
  ngOnInit(): void {
    const params = this.locationService.getCurrentParams();
    if (params.redirectToState) {
      this.stateService.go(params.redirectToState as StateName);
      return;
    }

    const url = this.to || this.locationService.getCurrentParams().redirectTo;
    const isOwned = this.locationService.urlDetails(url).isOwnedDomain;
    this.window.location.href = !isOwned ? url : this.locationService.normaliseUrlForSite(url);
  }
}
