import { Injectable } from '@angular/core';
import { User } from 'Shared/classes/user';
import { Purchase } from 'Shared/classes/purchase';

@Injectable({
  providedIn: 'root'
})
export class RakutenService {
  constructor() {}

  /**
   * Log
   */
  log(...args): void {
    if (window['analyticsDebug']) {
      console.log('<rakuten>', ...args);
    }
  }

  /**
   * Add to rakuten global
   * - This is later picked up by GTM
   */
  trackPurchase(purchase: Purchase, user: User): void {
    const items = purchase.orders.map(order => {
      const price = (order.price.original / 100).toFixed(2);
      return {
        quantity: 1, // Always 1
        unitPrice: price,
        unitPriceLessTax: price,
        SKU: `order-${order.id}`,
        productName: order.product.name
      };
    });

    const rakutenData = {
      orderid: `purchase-${purchase.id}`,
      currency: purchase.orders[0].price.currency,
      customerStatus: user.isLoggedIn() || user.email.hasOrdered ? 'Existing' : 'New',
      conversionType: 'Sale',
      customerID: user.slug,
      discountCode: purchase.discount && purchase.discount.code ? purchase.discount.code : '',
      discountAmount: (purchase.price.discount / 100).toFixed(2),
      taxAmount: 0, // We don't know the tax on here
      lineitems: items
    };

    // We stringify and parse, otherwise rakuten will change the properties
    this.log('transaction', JSON.parse(JSON.stringify(rakutenData)));
    window['rakutenTransaction'] = rakutenData;
  }
}
