import { Injectable } from '@angular/core';
import { CountryService } from 'Shared/services/country.service';
import { features } from 'Environments/features';
import { Country } from 'Shared/classes/country';
import { AvailableFeatures } from './features.service.typings';

export type FeatureKey = keyof AvailableFeatures;
export type { AvailableFeatures };

export type Features = Record<string, AvailableFeatures>;

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {
  private features = (features as Features);

  constructor(private countryService: CountryService) { }

  /**
   * Get all the features
   * @param shippingCountry
   */
  getAllFeatures(shippingCountry?: Country): AvailableFeatures {
    const country = shippingCountry || this.countryService.forShipping;
    const countryCode = country && country.codes ? country.codes[0].toLowerCase() : null;
    return this.features[countryCode];
  }

  /**
   * Get the feature config data for the current shipping country
   * The data is stored in projects/bandw/config/features/{country}.features.json
   *
   * @example {"ADDONS": {"maxNumber": 3}, "SORT": false }
   *
   * @param key
   * @param shippingCountry //optional
   */
  getFeature<T extends FeatureKey>(key: T, shippingCountry?: Country): AvailableFeatures[T] {
    const countryFeatures = this.getAllFeatures(shippingCountry);
    // @todo update the type of the return value to be AvailabileFeatures[T] | boolean
    return ((countryFeatures || {}) as any)[key] || false;
  }
}
