import { Injectable } from '@angular/core';
import { AddressModelService } from 'Shared/models/address-model.service';
import { Address } from 'Shared/classes/address';
import { ShippingOption } from 'Shared/classes/shipping-option';
import { APISerialisedJSONResponse } from 'Shared/services/backend.service';
import { Error } from 'Shared/classes/error';

export const ADDRESS_COMPARE_KEYS = ['name', 'company', 'line1', 'line2', 'city', 'postcode'];

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  constructor(private addressModelService: AddressModelService) {}

  /**
   * Get all addresses
   */
  getAll(): Promise<Address[]> {
    return this.addressModelService.getAll();
  }

  /**
   * Delete an address
   * @param address
   */
  delete(address: Address): Promise<any> {
    return this.addressModelService.delete(address);
  }

  /**
   * Create an address
   * @param address
   */
  create(address: Address): Promise<Address> {
    return this.addressModelService.create(address);
  }

  /**
   * Update an address
   * @param address
   */
  update(address: Address): Promise<Address> {
    return this.addressModelService.update(address);
  }

  /**
   * Get an address
   * @param addressId
   */
  get(addressId: number): Promise<Address> {
    const address = new Address();
    address.id = addressId;
    return this.addressModelService.get(address);
  }

  /**
   * Check that the address is valid against the shipping option
   * @param address
   * @param shippingOption
   */
  check(
    address: Address,
    shippingOption: ShippingOption
  ): Promise<APISerialisedJSONResponse<'/v1/addresses/check'> | Error> {
    return this.addressModelService.check(address, shippingOption);
  }

  /**
   * Suggest alternatives
   * @param address
   */
  suggestAlternatives(address: Address): Promise<Address[]> {
    const toSuggest = [address];

    return this.addressModelService.suggestAlternatives(address).then((suggestions: Address[]) => {
      suggestions.forEach((suggestion) => {
        // If a line of the address differs (unless it's the same when lower-cased) ...
        const aLineDiffers = ADDRESS_COMPARE_KEYS.find((key) => {
          return `${suggestion[key] || ''}`.toLowerCase() !== `${address[key] || ''}`.toLowerCase();
        });

        // ... Add it to the array to return
        if (aLineDiffers) {
          toSuggest.push(suggestion);
        } else {
          // ... Or replace the 'original' if ONLY the capitalisation differs
          toSuggest[0] = suggestion;
        }
      });

      return toSuggest;
    });
  }
}
