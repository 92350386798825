import { Addon } from 'Shared/classes/addon';
import * as clone from 'clone';
export class GiftCard {
  message: string;
  cover: Addon;

  isActive: boolean = false;

  /**
   * Clone
   */
  clone(): GiftCard {
    return clone(this);
  }
}

export type GiftCardSections = 'message' | 'cover';
