import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import { t } from 'Shared/utils/translations';

@Pipe({
  name: 'localeRelativeDate'
})
export class LocaleRelativeDatePipe implements PipeTransform {
  transform(dateObject: dayjs.Dayjs, format: string, dayPrefix: string = ''): any {
    if (!dateObject) {
      return null;
    }
    const today = dayjs();
    const start = today.clone().startOf('day');
    const end = dateObject.clone().startOf('day');
    const diff = end.diff(start, 'day');

    if (diff === 0) {
      return t('js.pipe.relative-date.today');
    }
    if (diff === 1) {
      return t('js.pipe.relative-date.tomorrow');
    }

    return dayjs(dateObject).isValid() ? `${dayPrefix} ${dayjs(dateObject).format(format)}` : null;
  }
}
