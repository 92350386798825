import { Injectable } from '@angular/core';
import { Price } from 'Shared/classes/price';
import { Order } from 'Shared/classes/order';
import { BehaviorSubject } from 'rxjs';
import { Addon } from 'Shared/classes/addon';
import { ShopSectionComponent } from 'Checkout/components/shop-section/shop-section.component';
import { t, TranslationKey } from 'Shared/utils/translations';
import { ExperimentsService } from 'Shared/services/experiments.service';

interface CardSection {
  slug: string;
  cards: Addon[];
  title: string;
  copy: string;
}

@Injectable({
  providedIn: 'root'
})
export class GiftingOptionsService {
  public orderPrice$ = new BehaviorSubject<Price>(new Price('gbp', 0, 0));
  public giftingSections$ = new BehaviorSubject<ShopSectionComponent[]>([]);
  public activeGreetingCard$ = new BehaviorSubject<Addon>(new Addon());
  public activeGreetingSection$ = new BehaviorSubject<ShopSectionComponent>({} as ShopSectionComponent | null);
  public giftingSectionDetection: boolean;

  private _giftOptionsData: any; // To be properly typed after gifting cleanup
  private _giftCard: Addon;
  private _activeGreetingSectionID: number;
  private _defaultCardID: number;
  private _order: Order;

  private giftingCardTags: string[] = [
    'christmas-card',
    'birthday-card',
    'easter-card',
    'mothers-day-card',
    'fathers-day-card',
    'charity-card',
    'celebration-card',
    'limited-edition-card',
    'all-occasions-card',
    'basic-collection-card',
    'standard-collection-card',
    'seasonal-collection-card'
  ];

  constructor(private experimentsService: ExperimentsService) {}

  get defaultCardID(): number {
    return this._defaultCardID;
  }

  set defaultCardID(value: number) {
    this._defaultCardID = value;
  }

  get giftCard(): Addon {
    return this._giftCard;
  }

  set giftCard(value: Addon) {
    this._giftCard = value;
    this.activeGreetingCard$.next(value);
  }

  get activeGreetingSectionID(): number {
    return this._activeGreetingSectionID;
  }

  set activeGreetingSectionID(value: number) {
    if (this.giftingSectionDetection) {
      this._activeGreetingSectionID = value;
    } else {
      this._activeGreetingSectionID = 0;
    }
  }

  set activeGreetingSection(value: ShopSectionComponent) {
    if (this.giftingSectionDetection) {
      this.activeGreetingSection$.next(value);
    } else {
      this.activeGreetingSection$.next(this.giftingSections[0]);
    }
  }

  get giftOptionsData(): any {
    return this._giftOptionsData;
  }

  set giftOptionsData(value: any) {
    this._giftOptionsData = value;
  }

  get giftingSections(): ShopSectionComponent[] {
    return this.giftingSections$.getValue();
  }

  get order(): Order {
    return this._order;
  }

  set order(value: Order) {
    this._order = value;
  }

  public calculateOrderTotal(order: Order): void {
    this.orderPrice$.next(
      Order.calculateOrderTotal({
        product: order.product,
        addons: order.addons,
        duration: order.duration,
        frequency: order.frequency
      })
    );
  }

  public reset(): void {
    this._giftOptionsData = null;
    this._order = null;
    this._giftCard = null;
  }

  /**
   * @description Get greeting cards with correct tags
   * @param {Addon[]} cardData
   * @returns {Addon[]}
   */
  public getFilteredGreetingCards(cardData: Addon[]): Addon[] {
    if (this.experimentsService.isActive('DE_PAID_GREETING_CARDS', 1)) {
      cardData = cardData.filter((addon: Addon): boolean => addon.price?.price === 0);
    }

    // Hide celebration cards if the experiment is active
    if (this.experimentsService.isActive('HIDING_CELEBRATION_GC_COLLECTION', 1)) {
      cardData = cardData.filter((addon: Addon): boolean => !addon.tags.includes('celebration-card'));
    }

    return cardData.filter((addon: Addon): boolean => addon.tags.some((tag): boolean => this.giftingCardTags.includes(tag)));
  }

  /**
   * Get greeting card groups
   * @param cardData
   * @returns {CardSection[]}
   */
  public getGreetingCardGroups(cardData: Addon[]): CardSection[] {
    const greetingCardSections: CardSection[] = this.initialiseCardSections();

    cardData.forEach((addon: Addon): void => {
      addon.tags.forEach((tag): void => {
        if (this.giftingCardTags.includes(tag)) {
          greetingCardSections.forEach((section: CardSection): void => {
            if (section.slug === tag) {
              section.cards.push(addon);
            }
          });
        }
      });
    });

    greetingCardSections.forEach((section: CardSection): void => {
      const titleTranslation = `js.service.gifting-options.card-collections.${section.slug}.collection-name` as TranslationKey;
      const copyTranslation = `js.service.gifting-options.card-collections.${section.slug}.supporting-copy` as TranslationKey;

      section.title = t(titleTranslation);
      section.copy = t(copyTranslation);
    });

    return greetingCardSections.filter((greetingCardSection: CardSection): boolean => greetingCardSection.cards.length > 0);
  }

  /**
   * Initialise card sections
   * @returns {CardSection[]}
   */
  private initialiseCardSections(): CardSection[] {
    const giftingCardTagsArray: CardSection[] = [];

    // for each giftingCardTags create object and add to array
    this.giftingCardTags.forEach((giftingCardTag): void => {
      giftingCardTagsArray.push({
        slug: giftingCardTag,
        cards: [],
        title: '',
        copy: ''
      });
    });

    return giftingCardTagsArray;
  }
}
