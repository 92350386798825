import { Component, OnInit, HostListener, ElementRef, Input, AfterViewInit } from '@angular/core';
import { StateService } from 'Shared/services/state.service';
import { WindowRefService } from 'Shared/services/window.service';
import { LocationService } from 'Shared/services/location.service';
import { GaService } from 'Shared/services/third-parties/ga.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { ModalService } from 'Shared/services/modal.service';
import { CountryService } from 'Shared/services/country.service';
import { ToastrService } from 'Project/toastr/services/toastr.service';
import { HrefInterceptorService } from 'Project/shared/services/href-interceptor.service';

@Component({
  selector: 'a,[uiSref]:not(bw-button)',
  template: '<ng-content></ng-content>'
})
export class HrefInterceptorComponent implements AfterViewInit {

  element: ElementRef;
  domain: string;
  window: any;

  @Input('uiParams') uiParams;
  @Input('uiSref') uiSref;

  /**
   * Constructor
   * @param el
   */
  constructor(
    el: ElementRef,
    private stateService: StateService,
    private windowRef: WindowRefService,
    private countryService: CountryService,
    private locationService: LocationService,
    private gaService: GaService,
    private analyticsService: AnalyticsService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private hrefInterceptorService: HrefInterceptorService
  ) {
    // TODO: Move both of these to the ngOnInit stage
    this.element = el;
    this.window = this.windowRef.nativeWindow;
  }

  /**
   * Camel case and remove spaces etc
   * @param str
   */
  normalised(str: string): string {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
        index === 0 ? letter.toLowerCase() : letter.toUpperCase()
      )
      .replace(/\s+/g, '');
  }

  /**
   * We need to change the event brite links on demand, this is so we can then
   * track across multiple GA sites and views. It's hacky.
   * It can't go into the content model interceptors as the values are mostly dynamic.
   */
  changeEventBriteLink(elem: any): void {
    const params = [];
    const initial = this.stateService.getInitial().params;

    // _eboga is the eventbrite querystring to send the client through...
    params.push('_eboga=' + this.gaService.getClientId());
    params.push(initial.utm_campaign ? 'utm_campaign=' + initial.utm_campaign : '');
    params.push(initial.utm_content ? 'utm_content=' + initial.utm_content : '');
    params.push(initial.utm_id ? 'utm_id=' + initial.utm_id : '');
    params.push(initial.utm_medium ? 'utm_medium=' + initial.utm_medium : '');
    params.push(initial.utm_source ? 'utm_source=' + initial.utm_source : '');
    params.push(
      'utm_term=' + (document.referrer || window.location.href.replace(window.location.search, ''))
    );
    params.push('utm_referrer=' + window.location.href);

    // Just replace if it already exists (the user probably has copy/pasted the link)
    const href = elem.href.split('?');
    href[1] = params.join('&');
    elem.setAttribute('href', href.join('?'));
  }


  /**
   * Mouse down - change href before 'click' happens
   * @param event
   */
  @HostListener('mousedown', ['$event'])
  onmousedown(event: any): boolean {
    const url = event.target.href;

    if (url && url.length && url.indexOf('eventbrite.co.uk') > -1) {
      this.changeEventBriteLink(event.target);
    }

    const trackAs =
      this.element.nativeElement.getAttribute('bwTrackAs') ||
      this.element.nativeElement.getAttribute('bwtrackas');
    if (trackAs) {
      this.analyticsService.track(trackAs, {
        text: this.normalised(this.element.nativeElement.innerText)
      });
    }

    return true;
  }

  /**
   * On click
   */
  @HostListener('click', ['$event'])
  onClick(event: any): void {
    this.hrefInterceptorService.handleRoutingOnClick(this.element.nativeElement, event);
  }

  /**
   * After the view init (ie after any interpolation has happened)
   */
  ngAfterViewInit(): void {
    this.hrefInterceptorService.handleHrefDomains(this.element.nativeElement, this.uiSref, this.uiParams);
  }
}
