import * as clone from 'clone';
import * as dayjs from 'dayjs';
import { Availability } from 'Shared/classes/availability';
import { CurrencyCode, Price } from 'Shared/classes/price';
import { UpsellOption, UpsoldFrom } from 'Shared/classes/upsell-option';
import { DiscountInfo, DiscountType } from 'Shared/classes/discount-info';
import { ProductFilterAttributes } from 'Shared/classes/filter';
import { AddonType } from 'Shared/classes/addon';
import { t, TranslationKey } from 'Shared/utils/translations';
import {
  backendToOasysLabelTypes,
  Label,
  LabelType,
  OasysLabelType
} from 'Browse/components/product-card-label/product-card-label.component';
import {
  AssociatedSku,
  BouquetImages,
  Media,
  UnparsedAddonRequirement,
  UnparsedDiscountInfo
} from 'Shared/types/backend-api/availability-api.typings';

export interface AddonRequirement {
  kind: AddonType;
  min: number;
  max: number;
  defaultAddonId: number;
}

export type ProductType = 'flowers' | 'physical_gift_voucher' | 'digital_gift_voucher' | 'fake_product';
export class Product {
  id: number;
  type: ProductType;
  collectionId: number;
  collectionName: string;
  name: string;
  slug: string;
  imageUrls: any[] = [];
  tags: string[];

  addonRequirements: AddonRequirement[];
  // This user selected image to show on the addon modal on the grid.
  addonPrimaryImage: string;

  description: string;
  longDescription: string;
  shortDescription: string;

  rating: {
    count: number;
    average: number;
  };

  bundleOnly: boolean;
  singleOnly: boolean;
  subscriptionOnly: boolean;

  lilyFree: boolean;
  over18Only: boolean;

  pricing: Availability[];
  price: Availability;

  discountAddon: DiscountInfo;

  appearingFrom: dayjs.Dayjs;
  appearingTo: dayjs.Dayjs;
  deliverableFrom: dayjs.Dayjs;
  deliverableTo: dayjs.Dayjs;
  isPreorder: boolean = false;

  upsells: UpsellOption[];

  // BEST_IMAGERY EXPERIMENT
  // TODO: Remove after experiment
  // PIV CARD ID: #178039613
  // Spike card to make it a permanent feature: #178112623
  bestImagery: any[];

  upsoldFrom: UpsoldFrom;

  // Self purchase subscription
  isSelfPurchaseSubscription?: boolean;

  filterAttributes: ProductFilterAttributes[];

  // Only availavlble from the response of v2/availability/products with delivery_date param
  isInStock: boolean;
  latestShippingOptionCutoff: dayjs.Dayjs;

  // SKU_IMAGE_SWAP EXPERIMENT
  skuImageSwap?: SkuImageSwap[];

  videoUrl?: string;
  videoThumbnail?: string;

  labels?: Label[];
  doubleRewardPoints: boolean;

  constructor(id?: number, slug?: string) {
    this.id = id;
    this.slug = slug;
  }

  isDigitalGiftVoucher(): boolean {
    return this.type === 'digital_gift_voucher';
  }
  /**
   * Is the product a gift voucher
   * @returns
   */
  isGiftVoucher(): boolean {
    return ['physical_gift_voucher', 'digital_gift_voucher'].indexOf(this.type) > -1;
  }

  getTotalDeliveries(duration: number, frequency: number): number {
    return availabilityOptions.find((a) => a.duration === duration && a.frequency === frequency).amountOfDeliveries;
  }

  getTrackedDurationName(duration: number): string {
    if (duration < 1) {
      return 'Subscription';
    }
    if (duration === 1) {
      return 'OneOff';
    }
    return 'Bundle';
  }

  /**
   * Get the standard price either 1 or if a subscription -1
   * - Or if we can't find a price, return undefined
   * TODO - Improve the default for this - especially for bundles? What do we show?
   */
  getPrice(quantity?: number): Availability {
    if (!quantity && this.pricing.length) {
      return this.pricing[0];
    }

    let price = this.pricing.find((p) => p.quantity === quantity);
    if (!price) {
      price = this.pricing.find((p) => p.quantity === -1);
    }

    if (!price) {
      price = this.pricing && this.pricing.length ? this.pricing[0] : null;
    }
    return price;
  }

  getUpsellOption(type: string): UpsellOption {
    return this.upsells.find((upsell) => upsell.type === type);
  }

  /**
   * Get price for a given duration and frequency
   * @param duration
   * @param frequency
   */
  getPriceFor(duration: number, frequency?: number): Availability {
    return this.pricing.find((price) => price.duration === duration && (frequency === undefined || price.frequency === frequency));
  }

  getSubscriptionPrice(): Availability {
    return this.pricing.find((p) => p.duration === -1) || this.getPrice();
  }

  /**
   * Clear the discount from all the availability
   */
  clearDiscount(): Product {
    this.pricing = this.pricing.map((avail) => {
      avail.clearDiscount();
      return avail;
    });
    return this;
  }

  /**
   * Get upsell from association id
   * @param id
   */
  getUpsellFromAssociationId(id: number): UpsellOption {
    return this.upsells.find((u) => u.id === id);
  }

  /**
   * Is addon required
   * @param kind
   */
  getAddonRequired(kind?: string): AddonRequirement {
    if (!kind) {
      return this.addonRequirements?.length ? this.addonRequirements[0] : undefined;
    }

    return (this.addonRequirements || []).find((a) => a.kind === kind);
  }

  /**
   * Get the greeting card addon requirement
   * @returns
   */
  getGreetingCardAddonRequirement(): AddonRequirement {
    return (this.addonRequirements || []).find((a) => ['gift_card', 'premium_gift_card', 'standard_gift_card'].indexOf(a.kind) > -1);
  }

  setImageUrls(media: Media[], imageUrls: string[], bouquet_images?: BouquetImages[]): void {
    if (bouquet_images) {
      this.imageUrls = bouquet_images
        .filter((r): boolean => r.kind === 'letterbox-main')
        .map((image): string => image.urls.website_carousel.x1);
    } else {
      this.imageUrls = media?.length ? media.map((m): string => m.url) : imageUrls || [];
    }
  }

  setSkuImageSwap(media: Media[]): void {
    this.skuImageSwap = [];
    (media || []).forEach((img): number => this.skuImageSwap.push({ url: img.url, tag: img.tags_web }));
  }

  setUpsells(associated_skus: AssociatedSku[]): void {
    this.upsells = (associated_skus || []).map(
      (u): UpsellOption => ({
        id: u.id,
        type: u.kind,
        product: u.associated_sku_id ? new Product(u.associated_sku_id) : undefined,
        toggleText: u.display_text || u.bundle_duration,
        infoHeading: u.display_header,
        infoBody: u.display_body
      })
    );
  }

  /**
   * Mutate product to add the right tags and labels
   *
   * CO-2646 Hide free_delivery label when `isFreePeakDeliveryMessagingEnabled` is false,
   * and link related tag free-delivery when true.
   */
  setLabelsAndTags(
    tags: string[],
    featuredLabelText: string | undefined,
    featuredLabelType: LabelType | undefined,
    showRewardsLabel: boolean,
    isFreePeakDeliveryMessagingEnabled: boolean
  ): void {
    const extraTags = [];

    if (featuredLabelType === 'free_delivery') {
      if (isFreePeakDeliveryMessagingEnabled) {
        extraTags.push('free-delivery');
      } else {
        featuredLabelText = undefined;
        featuredLabelType = undefined;
      }
    }

    const rewardText = t('js.component.product-card.rewards-label');
    const labelType = featuredLabelType === undefined ? undefined : backendToOasysLabelTypes[featuredLabelType];

    const featuredLabels: Label[] = featuredLabelText
      ? [{ text: featuredLabelText, type: backendToOasysLabelTypes[featuredLabelType], showIcon: labelType !== undefined }]
      : [];
    const rewardLabels: Label[] = showRewardsLabel ? [{ text: rewardText, type: 'rewards-bonus-points', showIcon: true }] : [];

    this.labels = [...rewardLabels, ...featuredLabels];
    this.tags = [...(tags?.map((tag): string => tag.toLowerCase()) ?? []), ...extraTags];
  }

  /**
   * @description Get label by type
   * @param {OasysLabelType} type
   * @returns {Label}
   */
  getLabel(type: OasysLabelType): Label {
    return this.labels.find((label): boolean => label.type === type);
  }

  /**
   * Set pricing
   * @param pricing
   */
  setPricing(currency: any, pricesObj: any[]): void {
    this.pricing = (pricesObj || []).map((p) => {
      // The frontend treats subscription as -1 number of deliveries here
      const numberOfDeliveries = p.type === 'subscription' ? -1 : p.number_of_deliveries;

      const av = new Availability(currency, numberOfDeliveries, p.price_pennies, {
        discounted: p.price_pennies_discounted,
        rewardPoints: p.reward_points,
        rewardPointsWithBonus: p.reward_points_with_bonus
      });
      av.duration = p.type === 'subscription' ? -1 : p.duration;
      av.frequency = p.frequency;

      const totalDeliveries = p.number_of_deliveries || 1;
      av.perDelivery = new Price(currency, 1, av.original / totalDeliveries, { discounted: av.price / totalDeliveries });

      return av;
    });
  }

  setAddonRequirements(addonRequirements: Record<string, UnparsedAddonRequirement>): void {
    this.addonRequirements = Object.entries(addonRequirements || {}).map(
      ([key, value]): AddonRequirement => ({
        kind: key as AddonRequirement['kind'],
        min: value.min,
        max: value.max,
        defaultAddonId: value.default_addon_sku_id
      })
    );

    const giftCardRelatedRequirements = this.addonRequirements.find(
      (a): boolean => ['gift_card', 'premium_gift_card', 'standard_gift_card'].indexOf(a.kind) > -1
    );

    // Hack - ensure that every sku has an addon requirement for an optional gift card
    // This should be set-up in admin
    if (!giftCardRelatedRequirements) {
      this.addonRequirements.push({
        min: 1,
        max: 1,
        kind: 'gift_card',
        defaultAddonId: undefined
      });
    }
  }

  setDiscountAddon(discount_info: UnparsedDiscountInfo[], currency: CurrencyCode): void {
    try {
      this.discountAddon = (discount_info || [])
        .map((d): DiscountInfo => {
          const discount = new DiscountInfo();
          discount.id = d.id;
          discount.name = d.name;
          discount.slug = d.slug;
          discount.type = d.type as DiscountType;
          discount.price = new Price(currency, 1, d.price[0].price_pennies, { discounted: d.price[0].price_pennies_discounted });
          discount.imageUrls = d.media.map((m): string => m.url);
          return discount;
        })
        .find((di): boolean => di.type === DiscountType.Addon);
    } catch (e) {}
  }

  setAddonPrimaryImage(media: Media[]): void {
    this.addonPrimaryImage = media?.length ? media.find((m): boolean => m.tags_web.indexOf('addon-primary-image') > -1)?.url : undefined;
  }

  setAppearingToAndFrom(appearing_from: string, appearing_to: string): void {
    this.appearingFrom = dayjs(appearing_from);
    this.appearingTo = dayjs(appearing_to);
  }

  setDeliverableToAndFrom(deliverable_from: string, deliverable_to: string): void {
    this.deliverableFrom = dayjs(deliverable_from).startOf('day');
    this.deliverableTo = dayjs(deliverable_to).endOf('day');
  }

  clone(): Product {
    return clone(this);
  }
}

/**
 * Set duration based on bundles
 */
export enum Duration {
  ongoing = -1,
  threeMonth = 3,
  sixMonth = 6,
  year = 12
}

export const availabilityOptions = [
  {
    duration: 1,
    frequency: 0,
    amountOfDeliveries: 1
  },
  {
    duration: 3,
    frequency: 7,
    amountOfDeliveries: 12
  },
  {
    duration: 6,
    frequency: 7,
    amountOfDeliveries: 26
  },
  {
    duration: 12,
    frequency: 7,
    amountOfDeliveries: 52
  },
  {
    duration: 3,
    frequency: 14,
    amountOfDeliveries: 6
  },
  {
    duration: 6,
    frequency: 14,
    amountOfDeliveries: 12
  },
  {
    duration: 12,
    frequency: 14,
    amountOfDeliveries: 26
  },
  {
    duration: 3,
    frequency: 28,
    amountOfDeliveries: 3
  },
  {
    duration: 6,
    frequency: 28,
    amountOfDeliveries: 6
  },
  {
    duration: 12,
    frequency: 28,
    amountOfDeliveries: 12
  },
  {
    duration: -1,
    frequency: 7,
    amountOfDeliveries: -1
  },
  {
    duration: -1,
    frequency: 14,
    amountOfDeliveries: -1
  },
  {
    duration: -1,
    frequency: 28,
    amountOfDeliveries: -1
  }
];

/**
 * Used to collect types of bundles: 3 months, 6 months and 12 months
 * Implemented for product card modal
 */
export interface BundleProducts {
  3: Product[];
  6: Product[];
  12: Product[];
}

// SKU_IMAGE_SWAP EXPERIMENT
export interface SkuImageSwap {
  url: string;
  tag: string[];
}
