import * as dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import { BackendService } from 'Shared/services/backend.service';
import { Favourite } from 'Shared/classes/favourite';
import { LocalStorageService } from 'Shared/services/local-storage.service';
import { Product } from 'Shared/classes/product';
import { Country } from 'Shared/services/country.service';
import { UserService } from 'Shared/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class FavouritesModelService {
  constructor(
    private backend: BackendService,
    private localStorage: LocalStorageService,
    private userService: UserService
  ) {}

  /**
   * To payload
   * @param favourite
   */
  static toPayload(favourite: Favourite): any {
    return {
      data: {
        type: 'favourite_skus',
        attributes: {
          sku_id: favourite.product.id
        }
      }
    };
  }

  /**
   * From payload
   * @param data
   */
  private fromPayload(data: any, country: Country): Favourite {
    const favourite = new Favourite();
    favourite.country = country;
    favourite.id = parseInt(data.id, 10);
    favourite.createdAt = dayjs(data.created_at);
    favourite.product = new Product(data.sku_id); // TODO: Convert to a full product once the sku_favourite endpoint changes
    return favourite;
  }

  /**
   * Save the favourite
   * @param country
   * @param favourite
   */
  saveFavourite(favourite: Favourite): Promise<Favourite> {
    const user = this.userService.getUser();

    return this.backend
      .post(user, '/v2/favourite_skus', FavouritesModelService.toPayload(favourite), {
        requestIsJsonApi: true
      })
      .then(r => this.fromPayload(r.data, favourite.country));
  }

  /**
   * Get Remote favourites
   * @param user
   * @param country
   */
  getFavourites(country: Country, clearCache?: boolean): Promise<Favourite[]> {
    const user = this.userService.getUser();

    // TODO: Pass in the local favourites we know about in the querystring (Backend Piv card)
    return this.backend
      .get(user, '/v2/favourite_skus', {
        clearCache,
        useUrlAsCache: true,
        responseIsJsonApi: true,
        params: {
          shipping_country_id: country.id
        }
      })
      .then(favs => favs.map(f => this.fromPayload(f, country)));
  }

  /**
   * Delete the favourite
   * @param user
   * @param favourite
   */
  deleteFavourite(favourite: Favourite): Promise<any> {
    const user = this.userService.getUser();
    return this.backend.delete(user, `/v2/favourite_skus/${favourite.product.id}`);
  }
}
