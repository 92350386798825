
<div class="reviews-slim-banner" *ngIf="stats">
  <ui-container>
    <ui-box box_space_left="none" box_space_right="none" box_align_x="center" box_align_y="center">
      <ng-container *ngIf="reviewType === 'trustpilot'">
        <ui-stack [stack_direction]="(viewportSizeIs$ | async)?.mobile ? 'y' : 'x'" stack_gap="tight" stack_align="center" stack_distribute="fit">
          <ui-stack stack_direction="x" stack_gap="near" [stack_align]="(viewportSizeIs$ | async)?.mobile ? 'center' : 'start'" stack_distribute="fit">
            <ui-stack stack_direction="x" stack_gap="none" stack_align="center"><span class="text-body--default hidden-xs">Our customers say</span></ui-stack>
            <ui-stack stack_direction="x" stack_gap="tight" stack_align="center"><span class="text-label--primary">Excellent</span><img class="reviews-slim-banner__trustpilot-rating" src="/assets/images/Trustpilot_ratings_5star-RGB.svg" alt="Reviews from Trustpilot.com"></ui-stack>
          </ui-stack>
          <ui-stack stack_direction="x" stack_gap="near" stack_align="center" stack_distribute="fit">
            <ui-stack stack_direction="x" stack_gap="none" stack_align="end"><span><span class="text-body--supporting text-body--emphasis text-color--supporting">{{ stats.average_rating }}</span><span class="text-body--supporting text-color--supporting"> average |</span><span class="text-body--supporting text-body--emphasis text-color--supporting">  {{stats.count}}</span><span class="text-body--supporting text-color--supporting"> reviews</span></span></ui-stack>
            <ui-stack stack_direction="x" stack_gap="tight"><a href="https://uk.trustpilot.com/review/www.bloomandwild.com" target="_blank" rel="noopener noreferrer"><img class="reviews-slim-banner__trustpilot-logo" src="/assets/images/Trustpilot_brandmark_gr-blk-RGB.svg" alt="Reviews from Trustpilot.com"></a></ui-stack>
          </ui-stack>
        </ui-stack>
      </ng-container>
      <ng-container *ngIf="reviewType === 'trustedshops'">
        <ui-stack [stack_direction]="(viewportSizeIs$ | async)?.mobile ? 'y' : 'x'" stack_gap="tight" stack_align="center" stack_distribute="fit">
          <ui-stack stack_direction="x" stack_gap="tight" [stack_align]="(viewportSizeIs$ | async)?.mobile ? 'center' : 'start'" stack_distribute="fit"><img class="reviews-slim-banner__trustpilot-rating" src="/assets/images/trustedshops_starrow.svg" alt="Reviews from Trustpilot.com"><span class="text-label--primary">Excellent</span><a href="https://uk.trustpilot.com/review/www.bloomandwild.com" target="_blank" rel="noopener noreferrer"><img class="reviews-slim-banner__trustpilot-logo visible-xs" src="/assets/images/trustedshops_trustmark.svg" alt="Reviews from Trustpilot.com"></a></ui-stack>
          <ui-stack stack_direction="x" stack_gap="near" stack_align="center" stack_distribute="fit">
            <ui-stack stack_direction="x" stack_gap="near" stack_align="center">
              <ui-stack stack_gap="tight" stack_direction="x"><span><span class="text-body--supporting text-body--emphasis text-color--supporting">{{ stats.average_rating }}</span><span class="text-body--supporting text-color--supporting"> average</span><span class="text-color--supporting text-color--supporting"> |</span><span class="text-body--supporting text-body--emphasis text-color--supporting"> {{stats.count}}</span><span class="text-body--supporting text-color--supporting"> reviews</span></span></ui-stack>
              <ui-stack class="hidden-xs" stack_direction="x"><a href="https://uk.trustpilot.com/review/www.bloomandwild.com" target="_blank" rel="noopener noreferrer"><img class="reviews-slim-banner__trustpilot-logo" src="/assets/images/trustedshops_trustmark.svg" alt="Reviews from Trustpilot.com"></a></ui-stack>
            </ui-stack>
          </ui-stack>
        </ui-stack>
      </ng-container>
    </ui-box>
  </ui-container>
</div>