import { Component, OnInit } from '@angular/core';
import { BlogItemsService } from 'Content/services/BlogItemsService.service';
import { BlogCardItem } from 'Shared/interfaces/blog-card-item';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-flower-journal-blog-items',
  templateUrl: './flower-journal-blog-items.component.html'
})
export class FlowerJournalBlogItemsComponent implements OnInit {
  showEach: number;
  showMaximum: number;
  items: BlogCardItem[];
  category: string;
  title: string;

  constructor(private blogItemsService: BlogItemsService) {
    this.showEach = 9;
    this.showMaximum = this.showEach;
  }

  /**
   * Show more
   */
  showMore(): void {
    this.showMaximum += this.showEach;
  }

  /**
   * On Init
   */
  ngOnInit(): Promise<BlogCardItem[] | void> {
    return this.blogItemsService.getBlogItems(this.category).then((items): void => {
      this.items = items;
    });
  }
}
