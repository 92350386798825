
<div class="product-card-labels" *ngIf="productLabels?.length &gt; 0">
  <ui-stack [stack_wrap]="true" stack_direction="x" stack_gap="tiny">
    <ng-container *ngFor="let productLabel of productLabels">
      <ui-label *ngIf="productLabel.type !== 'rewards-bonus-points'" [showIcon]="productLabel.showIcon" [text]="productLabel.text" [type]="productLabel.type"></ui-label>
      <ui-box *ngIf="productLabel.type === 'rewards-bonus-points'" box_background="rewards-primary" box_border_radius="soften" box_fill_mode="fit" box_space="tiny" box_space_left="tight" box_space_right="tight">
        <ui-stack stack_align="center" stack_direction="x" stack_gap="tiny"><img src="/assets/brand-icons/rewards.svg"><span class="text-body--supporting text-color--highlight">{{ productLabel.text }}</span></ui-stack>
      </ui-box>
    </ng-container>
  </ui-stack>
</div>