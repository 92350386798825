import { Injectable } from '@angular/core';
import { BackendService } from 'Shared/services/backend.service';
import { ReviewsResponse, Review } from 'Shared/classes/review';
import { Product } from 'Shared/classes/product';
import * as dayjs from 'dayjs';
import { Country } from 'Shared/classes/country';

export { ReviewsResponse, Review } from 'Shared/classes/review';

@Injectable({
  providedIn: 'root'
})
export class ReviewsModelService {
  reviewsStatsPromise: { [key: number]: Promise<ReviewsResponse> } = {};
  allReviewsPromise: { [key: number]: { [key: number]: Promise<ReviewsResponse> } } = {};

  constructor(private backend: BackendService) {}

  fromPayload(response: any): ReviewsResponse {
    const reviewsResponse = new ReviewsResponse();
    reviewsResponse.averageRating = response.average_rating;
    reviewsResponse.count = response.count;
    reviewsResponse.productId = response.sku_id;
    reviewsResponse.reviews = (response.reviews || []).map(r => {
      const review = new Review();
      review.reviewer = (r.reviewer || '').split(' ')[0];
      review.date = dayjs(r.date);
      review.title = r.title;
      review.review = r.review;
      review.rating = r.rating;
      return review;
    });
    return reviewsResponse;
  }

  /**
   * Get all the reviews for a given country
   * @param country
   * @param limit
   */
  getAllReviews(country: Country, limit: number = 100): Promise<ReviewsResponse> {
    this.allReviewsPromise[country.id] = this.allReviewsPromise[country.id] || {};

    this.allReviewsPromise[country.id][limit] =
      this.allReviewsPromise[country.id][limit] ||
      Promise.all([
        this.backend.get(null, '/v1/reviews', {
          params: { limit, shipping_country_id: country.id }
        }),
        this.getReviewStats(country)
      ]).then(res => {
        const response = res[1];
        response.reviews = res[0].reviews;

        return this.fromPayload(response);
      });

    return this.allReviewsPromise[country.id][limit];
  }

  /**
   * Get the reviews for a given location
   * @param country
   * @param longitude
   * @param latitude
   */
  getLocationBasedReviews(
    country: Country,
    longitude: number,
    latitude: number
  ): Promise<ReviewsResponse> {
    const params: any = {
      limit: 6,
      limit_to_latest: 10000,
      shipping_country_id: country.id
    };

    if (latitude && longitude) {
      params.latitude = latitude;
      params.longitude = longitude;
    }

    return Promise.all([
      this.backend.get(null, '/v1/reviews/location_reviews', { params }),
      this.getReviewStats(country)
    ]).then(([reviews, stats]) => {
      const response = {
        reviews: reviews.reviews,
        average_rating: stats.average_rating,
        count: stats.count
      };
      return this.fromPayload(response);
    });
  }

  /**
   * Get product reviews for a sku
   * @param product
   * @param country
   */
  getProductReviews(country: Country, product: Product): Promise<ReviewsResponse> {
    return this.backend
      .get(null, '/v1/reviews/skus', {
        params: { id: product.id, shipping_country_id: country.id }
      })
      .then(res => this.fromPayload(res));
  }

  /**
   * Get the review status
   * @param country
   */
  getReviewStats(country: Country): Promise<any> {
    this.reviewsStatsPromise[country.id] =
      this.reviewsStatsPromise[country.id] ||
      this.backend
        .get(null, '/v1/reviews/stats', {
          params: {
            shipping_contry_id: country.id
          }
        })
        .then(response => response.review_stats);

    return this.reviewsStatsPromise[country.id];
  }
}
