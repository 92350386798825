import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ReviewsService } from 'Shared/services/reviews.service';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { Review, ReviewsResponse } from 'Shared/classes/review';
import { StateService } from 'Shared/services/state.service';
import { environment } from 'Environments/environment';
import { CountryService } from 'Shared/services/country.service';

import { reviews as enReviews } from './data/reviews-en';
import { reviews as nlReviews } from './data/reviews-nl';
import { reviews as deReviews } from './data/reviews-de';
import { reviews as dkReviews } from './data/reviews-dk';
import { reviews as beReviews } from './data/reviews-be';
import { ConfigService } from 'Project/shared/services/config.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-reviews-page',
  templateUrl: './reviews.component.html'
})
export class ReviewsComponent implements OnInit {
  source: 'reviews' | 'trustpilot' = 'reviews';
  reviewsData: ReviewsResponse;
  showReviewsTotal: boolean = true;

  bloomonHackReviewsData = {
    en: enReviews,
    nl: nlReviews,
    de: deReviews,
    dk: dkReviews,
    be: beReviews
  };

  constructor(
    private reviewsService: ReviewsService,
    private toastr: ToastrService,
    private state: StateService,
    private countryService: CountryService,
    private configService: ConfigService
  ) {}

  ngOnInit(): Promise<any> {
    if (environment.brandId === 'bloomon') {
      const site = this.configService.getConfig().site;
      this.reviewsData = {
        productId: 1,
        averageRating: 5,
        count: 50,
        reviews: this.bloomonHackReviewsData[site]
      };
      this.source = 'trustpilot';
      this.showReviewsTotal = false;
      return;
    }

    if (environment.locale === 'en') {
      this.source = 'trustpilot';
    }

    const shippingCountry = this.countryService.forShipping;
    return this.reviewsService
      .getAll(shippingCountry)
      .then((res) => {
        this.reviewsData = res;
      })
      .catch((error) => this.toastr.error(error.message));
  }
}
