import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textReplace'
})
export class TextReplacePipe implements PipeTransform {
  transform(string: string, replaceThis: string, withThis: string): string {
    if (!string || !withThis || string.indexOf(replaceThis) === -1) {
      return string;
    }

    return string.replace(new RegExp(`\\${replaceThis}`, 'gmi'), withThis);
  }
}
