/***
 *
 * Documentation:
 * https://businesshelp.snapchat.com/en-US/article/snap-pixel-about
 *
 *  The snapchat pixel works a little differently to others
 *  the command 'init' can only be fired once, and only when we know there is a user
 *  therefore, we store every command we may run, and when the user confirms cookies
 *  we first send the init command, and any future events
 */
import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { ConfigService } from 'Shared/services/config.service';
import { User } from 'Shared/classes/user';
import { Purchase } from 'Shared/classes/purchase';
import { Order } from 'Shared/classes/order';
import { Product } from 'Shared/classes/product';

@Injectable({
  providedIn: 'root'
})
export class SnapchatService {
  debug: boolean = false;
  window: any;
  private serviceInitialized: boolean = false;
  private hasUserIdentified: boolean = false;

  snapchatAccountKey: string;
  initPromise: Promise<any>;

  queue: {
    task: string;
    action: string;
    obj?: any;
  }[] = [];

  constructor(
    private windowRef: WindowRefService,
    private domUtils: DomUtilsService,
    private configService: ConfigService
  ) {
    this.snapchatAccountKey = this.configService.getConfig().snapchatAccountKey;
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Add to purchase
   * @param order
   */
  addToPurchase(order: Order): void {
    this.addToQueueAndExecute('track', 'START_CHECKOUT');

    const products = [];
    products.push({
      id: order.product.id,
      item_price: (order.product.getPrice().price / 100).toFixed(2)
    });

    (order.addons || []).forEach((addon) => {
      products.push({
        id: addon.id,
        item_price: (addon.getPrice().price / 100).toFixed(2)
      });
    });

    let total = 0;
    products.forEach((p) => {
      total += parseFloat(p.item_price);
    });

    this.addToQueueAndExecute('track', 'ADD_CART', {
      price: total.toFixed(2),
      currency: order.product.getPrice().currency,
      item_ids: products.map((p) => p.id),
      number_items: products.length,
      success: 1
    });
  }

  /**
   * Select a product
   * @param product
   */
  selectProduct(product: Product): void {
    this.addToQueueAndExecute('track', 'VIEW_CONTENT', {
      price: (product.getPrice().price / 100).toFixed(2),
      currency: product.getPrice().currency.toUpperCase(),
      item_ids: [product.id],
      number_items: 1,
      success: 1
    });
  }

  /**
   * Confirm the purchase
   * @param purchase
   */
  confirmPurchase(purchase: Purchase): void {
    const products = [];
    purchase.orders.forEach((order) => {
      products.push({
        id: order.product.id
      });
      (order.addons || []).forEach((addon) => {
        products.push({
          id: addon.id
        });
      });
    });

    this.addToQueueAndExecute('track', 'PURCHASE', {
      price: (purchase.price.price / 100).toFixed(2),
      currency: purchase.price.currency.toUpperCase(),
      transaction_id: purchase.id,
      item_ids: products.map((p) => p.id),
      number_items: products.length,
      success: 1
    });
  }

  /**
   * View a set of products
   * @param listTypeValue
   */
  viewProducts(listTypeValue: String): void {
    this.addToQueueAndExecute('track', 'SEARCH', {
      search_string: listTypeValue
    });
  }

  /**
   * No need to queue - page views can happen before the user is logged in
   */
  trackPage(): void {
    this.addToQueueAndExecute('track', 'PAGE_VIEW');
  }

  /**
   * User has started payment
   */
  startedPayment(): void {
    this.addToQueueAndExecute('track', 'ADD_BILLING');
  }

  /**
   * Add to the internal queue, or execute
   * @param task
   * @param action
   * @param obj
   * @param addTo
   * @param isUnique
   */
  addToQueueAndExecute(task: string, action: string, obj: any = {}, addTo: string = 'end'): void {
    if (addTo === 'end') {
      this.queue.push({
        task,
        action,
        obj
      });
    } else {
      this.queue.unshift({
        task,
        action,
        obj
      });
    }

    // Snapchat needs to have a user identified before any events are fired
    // For us, the user can change half-way through checkout
    this.executeQueue();
  }

  /**
   * Execute any stored snapchat commands
   */
  executeQueue(): void {
    if (this.serviceInitialized && this.hasUserIdentified && this.window.snaptr) {
      this.queue.forEach((command) => {
        this.window.snaptr(command.task, command.action, command.obj);
        if (this.debug) {
          console.log('<snapchat>', command.task, command.action, command.obj);
        }
      });
      this.queue = [];
    }
  }

  /**
   * Identify the user, only once though!
   * @param user
   */
  identify(user: User): void {
    if (this.hasUserIdentified || !user || !user.email || !user.email.sha256) {
      return;
    }
    this.hasUserIdentified = true;

    const obj = {
      user_hashed_email: user.email.sha256
    };
    // Remove any existing 'init' events
    this.queue = this.queue.filter((c) => c.task !== 'init');
    this.addToQueueAndExecute('init', this.snapchatAccountKey, obj, 'start');
  }

  /**
   * Init the script
   */
  init(): Promise<any> {
    this.initPromise =
      this.initPromise ||
      this.domUtils.loadScript('https://sc-static.net/scevent.min.js', 'snapchat').then(() => {
        this.serviceInitialized = true;

        // Reset the queue, except for the 'init' command
        this.queue = this.queue.filter((c) => c.task === 'init');
        this.executeQueue();
      });

    return this.initPromise;
  }
}
