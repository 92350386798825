import { Component, Input, OnInit } from '@angular/core';
import { ModalComponent } from 'Shared/services/modal.service';
import { Addon } from 'Shared/classes/addon';
import { Product, AddonRequirement } from 'Shared/classes/product';
import { Price } from 'Shared/classes/price';
import { AddonService } from 'Checkout/services/addon.service';
import { CountryService } from 'Shared/services/country.service';
import { Order, PurchaseService } from 'Checkout/services/purchase.service';
import { ShippingOption } from 'Shared/classes/shipping-option';
import * as dayjs from 'dayjs';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { DateUtilsService } from 'Shared/utils/date-utils.service';
import { ArrayUtils } from 'Shared/utils/array-utils';
import { ShopType } from 'Checkout/components/shop-checkout/shop-checkout.component';
import { FeaturesService } from 'Shared/services/features.service';

type ModalResolveData = {
  addons: Addon[];
  date: dayjs.Dayjs;
};
type ModalRejectData = {};

/* bw:view-encapsulation */
@Component({
  selector: 'bw-product-addon-list-modal',
  templateUrl: './product-addon-list-modal.component.html'
})
export class ProductAddonListModalComponent
  extends ModalComponent<ModalResolveData, ModalRejectData>
  implements OnInit
{
  @Input() product: Product;
  @Input() addons: Addon[];
  @Input() selectedAddons: Addon[] = [];
  @Input() shippingOption: ShippingOption;
  @Input() duration: number;
  @Input() frequency: number;
  @Input() date: dayjs.Dayjs;
  @Input() preferredDate: dayjs.Dayjs;
  @Input() forceUserToSelect: boolean = false;
  @Input() shopType: ShopType = 'default';

  @Input() addonRequirement: AddonRequirement = {
    kind: 'vase',
    min: 1,
    max: 1,
    defaultAddonId: undefined
  };

  price: Price;
  isLoading: boolean = false;

  constructor(
    private addonService: AddonService,
    private countryService: CountryService,
    private purchaseService: PurchaseService,
    private analyticsService: AnalyticsService,
    private featureService: FeaturesService
  ) {
    super();
  }

  /**
   * On selected addon
   */
  selectAddon(addon?: Addon, skipAnalytics: boolean = false): Promise<Addon[]> {
    const previouslySelected = this.selectedAddons.slice();
    // toggles the selection, so only one of each kind can be set
    const selected = previouslySelected.filter((a) => a.type !== this.addonRequirement.kind);
    selected.push(addon);
    return this.getAddons(selected).then((addons) => {
      if (!skipAnalytics) {
        this.analyticsService.trackAddonsSelected(
          previouslySelected,
          this.selectedAddons,
          undefined,
          undefined,
          undefined,
          this.product,
          'addon-modal'
        );
      }
      return addons;
    });
  }

  /**
   * Deselect the addon
   * @param addon
   */
  deselectAddon(addon: Addon): Promise<Addon[]> {
    const previouslySelected = this.selectedAddons.slice();
    const selected = previouslySelected.filter((a) => a.id !== addon.id);
    return this.getAddons(selected).then((addons) => {
      this.analyticsService.trackAddonsSelected(
        previouslySelected,
        this.selectedAddons,
        undefined,
        undefined,
        undefined,
        this.product,
        'addon-modal'
      );
      return addons;
    });
  }

  /**
   * Calculating total price
   * Price to show complete price from order
   */
  getPrice(): void {
    setTimeout(() => {
      this.price = Order.calculateOrderTotal({
        product: this.product,
        addons: this.selectedAddons,
        shippingOption: this.shippingOption,
        duration: this.duration,
        frequency: this.frequency
      });
    }, 0);
  }

  /**
   * On cancel
   */
  onCancel(): void {
    if (this.forceUserToSelect) {
      return this.onSubmit();
    }
    super.closeAsReject(undefined);
  }

  /**
   * On submit
   */
  onSubmit(): void {
    super.closeAsResolve({
      addons: this.selectedAddons,
      date: this.date
    });
  }

  /**
   * Filter out addons based on product addon requirements
   * @param addons
   */
  filterAddonsForKind(addons: Addon[], kind: string): Addon[] {
    return (addons || []).filter((a) => a.type === kind);
  }

  /**
   * Track the addon impression
   * @param addon
   * @param addonIndex
   */
  onAddonImpression(addon: Addon, addonIndex: number): void {
    this.analyticsService.trackAddonImpression(addon, addonIndex, this.product, 'addon-modal');
  }

  /**
   * Track by function
   * @param index
   * @param addon
   */
  addonTrackByFn(index, addon: Addon): number {
    return addon.id;
  }

  /**
   * Modal Resolver to get the required data
   */
  resolver(): Promise<any> {
    const country = this.countryService.forShipping;
    const currentPurchase = this.purchaseService.getPurchase();
    this.isLoading = true;

    this.date = this.date ? DateUtilsService.fromString(this.date) : undefined;
    this.preferredDate = this.preferredDate
      ? DateUtilsService.fromString(this.preferredDate)
      : undefined;

    return this.addonService
      .getAddonsForDefaultDeliveryDate(
        country,
        this.product,
        this.selectedAddons,
        currentPurchase.orders.length,
        true,
        this.preferredDate,
        currentPurchase.discount
      )
      .then(({ deliveryDate, addons }) => {
        const addonsForKind = this.filterAddonsForKind(addons, this.addonRequirement.kind);

        if (
          !this.addonRequirement.min &&
          (!addonsForKind.length || this.addonRequirement.min > addonsForKind.length)
        ) {
          return Promise.reject(true); // Don't show the modal and go to checkout
        }

        if (
          this.addonRequirement.min &&
          (!addonsForKind.length || this.addonRequirement.min > addonsForKind.length)
        ) {
          return Promise.reject(false); // Don't show the modal and go back to grid
        }

        this.date = deliveryDate ? deliveryDate.date : undefined;
        return Promise.resolve(); // Show the modal
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }

  /**
   * Get and set available addons based on next delivery dates
   */
  getAddons(selectedAddons: Addon[] = []): Promise<Addon[]> {
    const country = this.countryService.forShipping;
    const currentPurchase = this.purchaseService.getPurchase();
    this.isLoading = true;

    const previouslySelected = this.selectedAddons.slice();

    let promise: Promise<{ date: dayjs.Dayjs; addons: Addon[] }>;
    if (this.date) {
      promise = this.addonService
        .getAddons(
          country,
          this.product,
          selectedAddons,
          this.date,
          currentPurchase.orders.length,
          true,
          currentPurchase.discount
        )
        .then((addons) => ({ addons, date: this.date }));
    } else {
      promise = this.addonService
        .getAddonsForDefaultDeliveryDate(
          country,
          this.product,
          selectedAddons,
          currentPurchase.orders.length,
          true,
          this.preferredDate, // This may be undefined, and that's ok
          currentPurchase.discount
        )
        .then(({ deliveryDate, addons }) => ({ addons, date: deliveryDate?.date }));
    }

    return promise
      .then(({ date, addons }) => {
        this.date = date;
        this.isLoading = false;

        this.addons = ArrayUtils.moveToFront(this.addons, addons); // Keep the ordering we already have, if any

        this.selectedAddons = addons.filter((a) => a.isSelected);

        this.getPrice();

        return Promise.resolve(addons);
      })
      .catch(() => {
        this.addons = [];
        this.isLoading = false;
        return Promise.resolve([]);
      });
  }

  /**
   * On init
   */
  ngOnInit(): Promise<Addon[]> {
    this.selectedAddons = this.selectedAddons?.length ? this.selectedAddons : [];
    this.date = this.date ? DateUtilsService.fromString(this.date) : undefined;
    this.preferredDate = this.preferredDate
      ? DateUtilsService.fromString(this.preferredDate)
      : undefined;

    return this.getAddons(this.selectedAddons)
      .then((addons) => {
        const addonsOfKind = (this.addons || []).filter(
          (a) => a.type === this.addonRequirement.kind
        );
        const hasRequiredAddon = (this.selectedAddons || []).filter(
          (a) => a.type === this.addonRequirement.kind
        );
        if (
          addonsOfKind?.length &&
          this.addonRequirement.min &&
          hasRequiredAddon?.length < this.addonRequirement?.min
        ) {
          const defaultAddon = this.addonRequirement.defaultAddonId
            ? addonsOfKind.find((a) => a.id === this.addonRequirement.defaultAddonId)
            : undefined;
          return this.selectAddon(defaultAddon || addonsOfKind[0], true);
        }
        return Promise.resolve(addons);
      })
      .then((addons) => {
        this.addons = ArrayUtils.moveToFront(this.selectedAddons, addons);
        return Promise.resolve(addons);
      });
  }
}
