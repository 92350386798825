import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MetaBaseComponent } from 'Shared/components/meta-base/meta-base.component';

@Component({
  selector: 'bw-meta-faq',
  templateUrl: './meta-faq.component.html',
  styleUrls: ['./meta-faq.component.scss']
})
export class MetaFaqComponent extends MetaBaseComponent {
  @Input() faqschema: string;

  /**
   *
   * @returns FaqSchema
   */
  generateScript(): FaqSchema {
    const content = {
      '@context': 'https://schema.org/',
      '@type': 'FAQPage',
      mainEntity: JSON.parse(this.faqschema).map((faq) => ({
        '@type': 'Question',
        name: faq.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq.answer
        }
      }))
    };
    return content;
  }
}

interface FaqSchema {
  '@context': string;
  '@type': string;
  mainEntity: MainEntity[];
}

interface MainEntity {
  '@type': string;
  name: string;
  acceptedAnswer: AcceptedAnswer;
}

interface AcceptedAnswer {
  '@type': string;
  text: string;
}
