import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BwFormControl } from 'Shared/classes/bw-form';
import { AvailableIcon } from 'Shared/components/icon/icon.component';

const MAX_BACKEND_CHARACTER_LENGTH = 255;
const INPUT_LINE_HEIGHT = 24;
const INPUT_PADDING = 8;
const TEXTAREA_PADDING_BOTTOM = INPUT_PADDING + 2; // 2px transparent border to avoid text overflowing

/* bw:view-encapsulation */
@Component({
  selector: 'bw-form-text-input',
  templateUrl: './form-text-input.component.html'
})
export class FormTextInputComponent implements OnInit, AfterViewInit {
  @Input() label: string;
  @Input() labelPrefix: string;
  @Input() subtext: string;
  @Input() forceShowPlaceholder: boolean = false;
  @Input() placeholder: string;
  @Input() placeholderIcon: AvailableIcon;
  @Input() inputType: 'display' | 'text' | 'email' | 'tel' | 'password' = 'text';
  @Input() actionIconAlignment: 'centre' | 'top' = 'centre';
  @Input() hideValidationErrors: boolean = false;
  @Input() multiline: boolean = false;
  @Input() maxLength: number;
  @Input() bwFormControl: BwFormControl<any>;
  @Input() allowClear: boolean = false;
  @Input() giftingExperimentActive: boolean = false;
  @Input() autoComplete: string;
  @Input() displayFn: Function;

  @Input() scrollDisplayText: boolean = false;
  @Input() expandOnTypeToMaxCSSHeight: boolean = false;

  @Output() didClick = new EventEmitter<any>();
  @Output() didFocus = new EventEmitter<any>();
  @Output() didBlur = new EventEmitter<any>();
  @Output() didClear = new EventEmitter<any>();

  hasSubmitted: boolean = false;
  hasCallout: boolean = false;
  hasInstructions: boolean = false;
  isFocused: boolean = false;
  isExpandable: boolean = false;
  paddingForSubtext: number;
  labelName: string;
  id: string;
  maxCharCountReached = false;

  constructor(private elementRef: ElementRef) {}

  /**
   * Mark as submmitted
   */
  markAsSubmitted(): void {
    this.hasSubmitted = true;
  }

  /**
   * On input clicked
   */
  onInputClicked(): void {
    this.didClick.emit(true);
  }

  /**
   * On focus
   */
  onFocus(): void {
    this.isFocused = true;
    this.didFocus.emit(true);
  }

  /**
   * On blur
   */
  onBlur(): void {
    this.isFocused = false;
    this.didBlur.emit(true);
  }

  /**
   * On clear
   */
  onClear(): void {
    this.bwFormControl.setValue('');
    this.didClear.emit(true);
  }

  charCounter(): void {
    this.maxCharCountReached = this.bwFormControl.value.length > this.maxLength;
  }

  /**
   * Set Text Area Height
   */
  setTextAreaHeight(): void {
    const textAreaElem = this.elementRef.nativeElement.querySelector('textarea');
    textAreaElem.style.height = ''; // Reset first, to cause element "reflow"
    const scrollHeight = Math.ceil(parseFloat(textAreaElem.scrollHeight)); // Round up to ensure sub-pixel accuracy
    const newHeight = scrollHeight + INPUT_LINE_HEIGHT + TEXTAREA_PADDING_BOTTOM;
    textAreaElem.style.height = `${newHeight}px`;
  }

  /**
   * Set the max height of the control element (eg the textarea or display)
   * @param cssMaxHeight
   * @returns
   */
  setMaxHeightOfControlElement(cssMaxHeight: string): void {
    const elem = this.elementRef.nativeElement;

    const currentHeight = elem.clientHeight;
    const controlElement = elem.querySelector('.text-input__control');
    const controlAreaHeight = parseInt(window.getComputedStyle(controlElement).height);
    const remainingHeight = parseInt(currentHeight) - controlAreaHeight;

    const maxControlAreaHeight = parseInt(cssMaxHeight) - remainingHeight;
    controlElement.style.maxHeight = `${maxControlAreaHeight}px`;
  }

  /**
   * After view, set local properties
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      const elem = this.elementRef.nativeElement;
      this.hasCallout = !!elem.querySelector("[role='callout']");
      this.hasInstructions = !!elem.querySelector("[role='instructions']");

      const subtextElem = elem.querySelector('.text-input__subtext');
      this.paddingForSubtext = !!subtextElem ? subtextElem.clientWidth : undefined;

      if (this.expandOnTypeToMaxCSSHeight) {
        const cssMaxHeight = window.getComputedStyle(elem).maxHeight || 'none'; // default browser is 'none'

        // We don't support % here for maxHeight....
        if (cssMaxHeight && cssMaxHeight.indexOf('px') > -1) {
          this.setMaxHeightOfControlElement(cssMaxHeight);
          this.isExpandable = true;
        }
      }
    }, 1);
  }

  /**
   * On init, set the value
   */
  ngOnInit(): void {
    this.maxLength = !this.maxLength && this.inputType === 'text' ? MAX_BACKEND_CHARACTER_LENGTH : this.maxLength;

    this.labelName = `${this.labelPrefix ? this.labelPrefix : ''}${this.label}`;

    this.id = this.labelName.replace(/\W/g, '').toLowerCase();

    // Add an additional function, which marks this form as submitted
    this.bwFormControl['markAsSubmitted'] = () => {
      this.markAsSubmitted();
    };
  }
}
