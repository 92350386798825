import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { UserService, User } from 'Shared/services/user.service';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { ModalService } from 'Shared/services/modal.service';
import { OccasionReminder } from 'Shared/classes/occasion-reminder';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { Error } from 'Shared/classes/error';
import { AuthOrigin } from 'Shared/components/modals/auth-modal/auth';
import {
  OasysButtonModule,
  OasysFormErrorsModule,
  OasysFormGroupModule,
  OasysLayoutModule,
  OasysTextInputModule,
  OasysIconModule,
  OasysCheckboxModule
} from 'oasys-lib';
import { LoadingSpinnerComponent } from 'Shared/components/loading-spinner/loading-spinner.component';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';
import { FeaturesService } from 'Shared/services/features.service';
import { EmailService } from 'Shared/services/email.service';
import { ConfirmationAuthRewardsComponent } from 'Checkout/components/confirmation-auth-rewards/confirmation-auth-rewards.component';

interface CreateAccountForm {
  password: FormControl<string>;
  marketing: FormControl<boolean>;
}

/* bw:view-encapsulation */
@Component({
  standalone: true,
  imports: [
    OasysLayoutModule,
    OasysButtonModule,
    LoadingSpinnerComponent,
    NgIf,
    NgForOf,
    NgTemplateOutlet,
    OasysFormGroupModule,
    OasysFormErrorsModule,
    ReactiveFormsModule,
    OasysTextInputModule,
    OasysIconModule,
    OasysCheckboxModule,
    ConfirmationAuthRewardsComponent
  ],
  selector: 'bw-create-account',
  templateUrl: './create-account.component.html'
})
export class CreateAccountComponent implements OnInit, OnDestroy {
  @Input() user: User;
  @Input() token: string;
  @Input() isJoiningRewards: boolean;
  @Input() loyaltyWelcomePoints: number;
  @Input() loyaltyOrderPoints: number;
  @Input() hasRewardsBonusPoints: boolean;

  @Output() didSucceed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('marketing') marketingCheckbox: ElementRef<HTMLElement> | undefined;

  origin: AuthOrigin = 'confirmation';

  isGuest: boolean = true;
  loading: boolean = false;

  form: FormGroup<CreateAccountForm>;
  isSubmitted: boolean;
  showTandC: boolean = false;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private modalService: ModalService,
    private analyticsService: AnalyticsService,
    private optimizelyService: OptimizelyService,
    private featuresService: FeaturesService,
    private emailService: EmailService
  ) {}

  /**
   * On Init
   */
  ngOnInit(): Promise<void> {
    this.buildForm();

    // Fetch email preference as user is not logged in we aint passing preference in user object during checkout
    return this.emailService.check(this.user.email).then((email): void => {
      this.user.email = email;
      this.showTandC = this.featuresService.getFeature('JOINING_REWARDS').showTandC;
    });
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    this.form.markAsPristine();
    this.form.reset();
  }

  /**
   * build form
   * @returns void
   */
  buildForm(): void {
    this.form = new FormGroup<CreateAccountForm>({
      password: new FormControl<string>('', {
        validators: [Validators.required]
      }),
      marketing: new FormControl<boolean>(false)
    });
  }

  /**
   * open occasion modal
   * @returns {Promise<void>}
   */
  openOccasionModal(): Promise<void> {
    const occasion = new OccasionReminder();
    occasion.origin = 'purchaseConfirmation';
    this.analyticsService.trackInHeap('saveOccasion', { occasion });

    return this.modalService
      .showLazyModal(
        { name: 'CreateOccasionModalComponent' },
        {
          initialState: {}
        }
      )
      .then((): void => {})
      .catch((): void => {}); // Do nothing!
  }

  /**
   * on submit - reset password and create user password
   * Sets user as logged in
   */
  onSubmit(): Promise<void> {
    this.isSubmitted = true;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.markAsDirty();
      return Promise.resolve();
    }

    this.loading = true;
    const password = this.form.get('password').value;

    return this.userService.validateStrongPassword(password).then((res): Promise<void> => {
      if (res) {
        return this.resetPassword(password);
      }
      this.form.get('password').setErrors({ strongCommonPassword: true });
      this.loading = false;
    });
  }

  /**
   * Reset Password
   * @param password
   * @returns
   */
  private resetPassword(password: string): Promise<void> {
    const setEmailPreference = this.isJoiningRewards && this.user.email.preference < 1 ? this.setEmailPreference() : Promise.resolve();

    return setEmailPreference
      .then((): Promise<User> => this.userService.resetPassword(this.token, password))
      .then((user): void => {
        this.optimizelyService.trackEvent('reset_password');
        this.analyticsService.trackInHeap('authCreateAccount', {
          isSuccessful: true,
          optedIn: user.email.preference,
          origin: this.origin
        });
        this.loading = false;
        this.isGuest = false;
        this.userService.setAsLoggedIn(user);
        this.didSucceed.emit();
      })
      .catch((error: Error): void => {
        this.loading = false;
        this.toastr.error(error.message, error.title);
      });
  }

  /**
   * @description Set Email Preference
   * @returns {Promise<User>}
   */
  private setEmailPreference(): Promise<User> {
    const configEmailPreference = this.featuresService.getFeature('GDPR');

    const marketingLocation = 'Web - Confirmation - Create Account';
    const marketingLabel = this.marketingCheckbox?.nativeElement?.querySelector<HTMLElement>('.main-label')?.innerText?.trim() ?? '';

    const email = this.user.email.clone();

    email.preference = this.form.get('marketing').value
      ? configEmailPreference.optedInEmailPreference
      : configEmailPreference.optOutEmailPreference;

    email.consent = {
      copy: marketingLabel,
      method: 'Checkbox',
      location: marketingLocation
    };

    return this.emailService.update(email);
  }
}
