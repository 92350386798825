import { Component, OnInit, Input } from '@angular/core';
import { EmailService, Email } from 'Shared/services/email.service';
import { StateService } from 'Shared/services/state.service';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { WindowRefService } from 'Shared/services/window.service';
import { Preferences } from 'Shared/classes/email';
import { ContentService } from 'Shared/services/content.service';
import { ContentSegment } from 'Shared/models/segment-model.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-opt-out-redirect, bw-mothers-day-opt-out-redirect',
  templateUrl: './opt-out-redirect.component.html'
})
export class OptOutRedirectComponent implements OnInit {
  @Input() redirect: string;
  @Input() occasion: 'mothersDay' | 'fathersDay' | 'valentinesDay' | 'grandparentsDay' | 'grandmothersDay' = `mothersDay`;
  hideButton: boolean;
  constructor(
    private contentService: ContentService,
    private emailService: EmailService,
    private stateService: StateService,
    private toastr: ToastrService,
    private window: WindowRefService
  ) {}

  /**
   * On Click event
   * Updates emailService to opted out of mothers day and redirect to different page
   */
  onClick(): Promise<void> {
    const emailSlug = this.stateService.getCurrent().params['emailSlug'];

    const email = new Email('');
    email.slug = emailSlug;
    email.occasionPreferences = {
      [this.occasion]: Preferences.OptedOut
    };

    const elem = this.window.nativeWindow.document.querySelector('.mothers-day-opt-out__copy p');
    const signupCopy = elem ? elem.innerText.trim() : '';

    email.consent = {
      copy: signupCopy,
      method: 'Via ?emailSlug',
      location: `Web - ${this.window.nativeWindow.location.hostname}${this.window.nativeWindow.location.pathname}`
    };

    // Updating without preference, only the occasion preferece to set
    return this.emailService
      .update(email)
      .then((): Promise<ContentSegment[]> => this.contentService.refreshSegments())
      .then((): void => {
        this.hideButton = true;
      })
      .catch((error): void => {
        this.hideButton = false;
        this.toastr.error(error.title, error.message);
      });
  }

  /**
   * On init
   */
  ngOnInit(): void {}
}

export interface TagExclusionBySegment {
  content_segment_id: number;
  excluded_tags: string[];
}
