import { t } from 'Shared/utils/translations';

export const NextDeliverySubscriptionMessages = {
  en_GB: 'Invite your friends and get your next bouquet for free!',
  en_IE: t('js.confirm.page.banners.desktop.mention-me.sub'),
  de_DE: t('js.confirm.page.banners.desktop.mention-me.sub'),
  de_AT: t('js.confirm.page.banners.desktop.mention-me.sub'),
  en_DE: t('js.confirm.page.banners.desktop.mention-me.sub'),
  en_AT: t('js.confirm.page.banners.desktop.mention-me.sub')
};
export const NextDeliverySubscriptionMessagesbBloomon = {
  da_DK: t('js.confirm.page.banners.desktop.mention-me.sub'),
  de_DE: t('js.confirm.page.banners.desktop.mention-me.sub'),
  en_GB: 'Invite your friends and get your next bouquet for free!',
  nl_BE: t('js.confirm.page.banners.desktop.mention-me.sub'),
  nl_NL: t('js.confirm.page.banners.desktop.mention-me.sub')
};
