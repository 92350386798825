import { Injectable } from '@angular/core';
import { translations } from 'Environments/js-translations';
import { t } from 'Shared/utils/translations';

@Injectable({
  providedIn: 'root'
})
export class TranslationsServiceService {
  constructor() {}

  translate(key: string, ...placeholders: Object[]): string {
    const trans = translations;
    return (trans[key] || key).replace(/%s/g, match => {
      if (placeholders.length) {
        return placeholders.shift();
      }
      return match;
    });
  }
}
