import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-expansion-panel',
  templateUrl: './expansion-panel.component.html'
})
export class ExpansionPanelComponent implements OnInit {
  @Input() hideToggle: boolean = false;
  @Input() isOpen: boolean = false;
  @Output() trackInHeap: EventEmitter<any> = new EventEmitter();

  expand: boolean = false;

  togglePanel(): void {
    if (!this.hideToggle) {
      this.expand = !this.expand;
      this.trackInHeap.emit();
    }
  }

  /**
   * If we are hiding the toggle, then automatically expand it
   */
  ngOnInit(): void {
    if (this.hideToggle || this.isOpen) {
      this.expand = true;
    }
  }
}
