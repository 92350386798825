import { Injectable } from '@angular/core';
import { LocationService } from './location.service';
import { StateService } from './state.service';
import { WindowRefService } from './window.service';
import { StateName } from './state.service.typings';
import { ModalService } from './modal.service';
import { ContentModalComponent } from 'Project/content/components/content-modal/content-modal.component';
import { SubscriptionCancelModalComponent } from 'Project/account/components/subscription-cancel-modal/subscription-cancel-modal.component';
import { ContactUsModalComponent } from '../components/modals/contact-us-modal/contact-us-modal.component';
import { ProductCardModalComponent } from 'Project/browse/components/product-card-modal/product-card-modal.component';
import { t } from 'Shared/utils/translations';
import { ModalRejectData } from 'Modals/hc-quality-self-serve/hc-quality-self-serve.component';
import { ToastrService } from 'Project/toastr/services/toastr.service';
import { CheckoutService } from 'Checkout/services/checkout.service';
import { AnalyticsService } from './analytics.service';
import { MentionMeModalComponent } from 'Shared/components/third-parties/mention-me-modal/mention-me-modal.component';
import { UserService } from './user.service';
import { OrderService } from './order.service';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class HrefInterceptorService {
  window: any;
  bloomAndWildOwnedDomains = [
    'localhost',
    'bloomandwild.com',
    'bloomandwild.fr',
    'bloomandwild.de',
    'bloomon.de',
    'bloomon.be',
    'bloomon.nl',
    'bloomon.dk',
    'bloomon.co.uk',
    'bloomon.com',
    'bloomdev.org',
    'bloomstaging.dev'
  ];

  constructor(
    private stateService: StateService,
    private locationService: LocationService,
    private windowRef: WindowRefService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private checkoutService: CheckoutService,
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private orderService: OrderService
  ) {
    this.window = this.windowRef.nativeWindow;
  }

  /**
   *
   * @param element
   * @param event
   * @returns void
   */
  handleRoutingOnClick(element: HTMLAnchorElement, event: any): void {
    const a = element;

    // Check for contentful-analytics
    const contentfulAnalyticsData = a.getAttribute('contentful-analytics');
    if (contentfulAnalyticsData) {
      this.handleContentfulAnalytics(contentfulAnalyticsData);
    }

    const subsParams = this.locationService.getParamsAsObject(a.search);
    const modalName = a.getAttribute('bw-launch-modal') || a.getAttribute('launch-modal') || subsParams['launch-modal'];

    // If the element has "newTab" queryParam on the href, open in a new tab
    if (a.getAttribute('href')?.includes('newTab')) {
      a.setAttribute('target', '_blank');
    }

    if (modalName) {
      const attributes = [].slice
        .call(a.attributes)
        .filter((attr) => attr.name.indexOf('modal-data-') > -1)
        .reduce((acc, attr) => {
          acc[attr.name.replace('modal-data-', '')] = attr.value;
          return acc;
        }, {});

      this.launchModal(modalName, Object.assign({}, attributes, subsParams));
      event.preventDefault();
      return;
    }

    // If new window requried continue as usual
    const target = a.getAttribute('target');
    if (target === '_blank' || target === '_external') {
      return;
    }

    // If no link, just continue as normal
    const href = a.getAttribute('href');
    if (!href) {
      return;
    }

    // If we are not on the same domain, it *must* be another site, so just continue
    if (a.toString().indexOf(this.locationService.mainDomain) !== 0) {
      return;
    }

    // If the link is a subfolder, but the app URL isn't part of the URL, it must mean it's an external url
    if (a.pathname.match(/^\/\w{2}-\w{2}\//) && a.toString().indexOf(this.locationService.appUrl) < 0) {
      return;
    }

    // if we are on a subfolder, but the link is NOT a subfolder, it must be another domain
    if ((this.locationService.appUrlSubfolder || '').match(/^\/\w{2}-\w{2}/) && !a.pathname.match(/^\/\w{2}-\w{2}\//)) {
      return;
    }

    // At this point, all the links *should* be internal, so we can remove the app url to get the correct path to pass to stateservice
    const pathname = a.toString().replace(this.locationService.appUrl, '');
    let path = pathname.substr(-1) === '/' ? pathname.slice(0, -1) : pathname; // if last character is / remove it
    path = path.replace(a.search, ''); // replace any search with empty, we need to convert them to an object
    const url = path && path.length ? path : '/'; // , if empty, go to homepage '/'
    const params = this.locationService.getParamsAsObject(a.search);
    this.stateService.goToUrl(url, params);
    event.preventDefault();
  }

  /**
   *
   * @param element
   * @returns
   */
  handleHrefDomains(element: HTMLAnchorElement, uiSref?: StateName, uiParams?: any): void {
    const a = element;

    // Convert state UI links into href so that users can easily click
    if (uiSref || uiParams) {
      const hrefToSet = this.stateService.href(uiSref, uiParams);
      a.setAttribute('href', hrefToSet);
    }

    // No href - do nothing
    const href = a.getAttribute('href') || '';
    if (!href) {
      return;
    }

    // If it's not a "safe" domain, ensure blank and noopener for security
    // TODO: I think this should be "endsWith" rather than just checking the hostname
    const hostname = a.hostname || this.window.location.hostname; // IE11 can return hostame as blank for relative URLs
    const isNotBWDomain = !this.bloomAndWildOwnedDomains.find((d) => hostname.indexOf(d) > -1);
    if (isNotBWDomain) {
      a.setAttribute('rel', 'noopener');
      a.setAttribute('target', '_blank');
      return;
    }

    // If the link is a ?countryId, attempt to convert it to a subfolder
    if ((a.search || '').indexOf('countryId=') > -1) {
      const bestMatchUrl = this.locationService.convertCountryToSubfolder(a.toString());
      a.setAttribute('href', bestMatchUrl);
      // if external, add attribute to avoid url to be normalised
      const isExternal = this.locationService.urlDetails(bestMatchUrl).isExternal;
      if (isExternal) {
        a.setAttribute('target', '_external');
      }
    }

    // // Set subfolder sites (eg /en-gb/) as external, UNLESS it's the same subdomain site we are on now
    if (a.pathname.match(/^\/\w{2}-\w{2}\//) && a.pathname.indexOf(this.locationService.appUrlSubfolder) !== 0) {
      a.setAttribute('target', '_external');
    }

    // if not external we need to re-write the url by normalising it against the site we are on
    // Some links may already be forced to `_external` which is why we can't combine with the above if
    if (a.getAttribute('target') !== '_external') {
      const finalUrl = this.locationService.normaliseUrlForSite(href);
      a.setAttribute('href', finalUrl);
    }

    // Set a class if active
    const activeClass = a.getAttribute('hrefActive');
    if (activeClass && this.locationService.path() === href) {
      a.classList.add(activeClass);
    }
  }

  /**
   * Launch a modal from a click.
   * Sadly this can't be a seperate component, as an element can't have two components
   * AND it can't be a directive as it's being called from contentful
   * TODO: Somehow refactor the launch-modal.component to use similar logic (Card #166575745)
   * @param modalName
   */
  launchModal(modalName: string, params?: any): Promise<any> {
    if (modalName === 'resumeSubscription') {
      const user = this.userService.getUser();

      // If user is logged in, show the most recent lapsed subscription
      if (user && user.isLoggedIn()) {
        this.orderService
          .getAll()
          .then((orders) => {
            // Get all lapsed subscriptions and sort by date
            const sortedLapsedSubs = orders
              .filter((order) => order.type === 'subscription' && ['cancelled', 'paused'].includes(order.state))
              .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf());

            // If we have a lapsed subscription, show the modal and redirect to the next delivery
            if (sortedLapsedSubs && sortedLapsedSubs[0]) {
              return this.modalService
                .showLazyModal(
                  { name: 'OrderSubscriptionResumeModalComponent' },
                  {
                    initialState: {
                      order: sortedLapsedSubs[0]
                    },
                    trackingKey: 'subscription-resume',
                    keyboard: true
                  }
                )
                .then(() => {
                  this.stateService.go('subscriptionNextDelivery' as StateName, { orderId: sortedLapsedSubs[0].id });
                })
                .catch(() => {});
            }
          })
          .catch(() => {
            // If we can't get the orders, just redirect to the orders page
            this.stateService.go('account.orders');
          });
      }

      // If user is not logged in, redirect to the orders page
      if (!user || !user.isLoggedIn()) {
        this.stateService.go('account.orders');
      }
    }

    if (modalName === 'helpCenterOrder') {
      return this.modalService
        .showLazyModal({ name: 'HcDeliverySelfServeComponent' }, { initialState: {}, class: 'modal-sm help-center-modal' })
        .then(() => {})
        .catch(() => {});
    }

    if (modalName === 'helpCenterQuality') {
      return this.modalService
        .showLazyModal(
          { name: 'HcQualitySelfServeComponent' },
          {
            initialState: { userIsRecipient: params['userisrecipient'] === 'true' },
            class: 'modal-sm help-center-modal',
            keyboard: true
          }
        )
        .catch((data: ModalRejectData) => {
          if (!data) {
            return;
          }

          const { showFeedback, ...formData } = data;

          if (showFeedback) {
            this.toastr.feedback(t('js.component.feedback.quality-question'), formData);
          }
        });
    }

    if (modalName === 'mentionMeShare') {
      // MentionMe share modal
      return this.modalService
        .showLazyModal(
          { name: 'MentionMeModalComponent' },
          {
            initialState: {
              situation: 'sub',
              integrationType: 'referreroffer',
              segment: 'subscriber'
            },
            class: 'modal-lg bw-modal--mention-me-payment',
            keyboard: true
          }
        )
        .catch((): void => {});
    }

    if (modalName === 'content') {
      return this.modalService
        .show(ContentModalComponent, {
          initialState: {
            contentPath: params.content,
            asIframe: false,
            title: params.heading || ''
          },
          trackingKey: 'content',
          trackingValue: 'content',
          class: 'modal-lg',
          keyboard: true
        })
        .catch(() => {});
    }

    if (modalName === 'cancelsubscription') {
      return this.modalService
        .show(SubscriptionCancelModalComponent, {
          initialState: params,
          trackingKey: 'subscriptioncancel',
          trackingValue: 'subscriptioncancel',
          class: 'modal-sm bw-modal--subscription-cancel',
          useNativeScroll: true,
          keyboard: true
        })
        .catch(() => {});
    }

    if (modalName === 'contactus') {
      return this.modalService
        .show(ContactUsModalComponent, {
          initialState: params,
          trackingKey: 'contactus',
          trackingValue: 'contactus',
          class: 'modal-sm bw-modal--contact-us',
          useNativeScroll: true,
          keyboard: true
        })
        .catch(() => {});
    }

    if (modalName === 'subscription') {
      // eg launch-modal="subscription" modal-data-product-id="2955"
      return this.modalService
        .show(ProductCardModalComponent, {
          initialState: {
            initialProductId: parseInt(params['product-id'], 10)
          },
          trackingKey: 'subscription',
          trackingValue: `contentfulPage-productId-${params['product-id']}`,
          class: 'modal-md',
          useNativeScroll: true,
          keyboard: true
        })
        .then((res) => {
          if (res.product) {
            this.stateService.addDataToCurrentState({
              isProductGrid: true
            });

            const nextState: any = this.checkoutService.getCheckoutStartingPoint(res.product);

            return this.stateService.go(nextState, {
              data: {
                product: res.product,
                params: {
                  frequency: res.params.frequency,
                  duration: res.params.duration
                },
                productModalUsed: true,
                giftingOptionsEnabled: nextState === 'checkout.giftOptions'
              }
            });
          }
        })
        .catch(() => {});
    }
    return Promise.resolve();
  }

  /**
   * This fires heap analytics to track clicks on contentful elements
   *
   * @param jsonString - passed json string
   */
  private handleContentfulAnalytics(jsonString: string): void {
    try {
      const contentfulAnalyticsData = JSON.parse(jsonString);

      this.analyticsService.trackInHeap(contentfulAnalyticsData.trackingName, contentfulAnalyticsData.data);
    } catch (error) {
      console.error(error);
    }
  }
}
