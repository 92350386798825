
<div class="reviews-display" [ngClass]="{'reviews_rebrand': reviewsrebrand}">
  <div class="reviews-display__top-info">
    <div class="row" *ngIf="!reviewsrebrand">
      <div class="col-md-12"><span class="reviews-display__heading serif">We're the UK's most-loved online florist*</span>
        <div class="reviews-display__copy"><p>But don’t just take our word for it. Here’s what everyone’s been saying about us...</p></div>
      </div>
    </div>
    <div class="reviews-display__top-info__inner" *ngIf="reviewsrebrand">
      <div class="visible-xs reviews-display__trustpilot_info_mobile">
        <bw-reviews-stats modifier="condensed" innerclass="text-center" innerContentclass="justify-content-center"></bw-reviews-stats>
      </div>
      <ng-container *ngIf="source !== 'trustedshops'"><span class="bw-font-headline-regular">We're the UK's most-loved online florist*</span><span class="hidden-xs reviews-display__trustpilot-info"><img src="/assets/brand-icons/rebrand-homepage-trustpilot.svg"></span></ng-container>
      <ng-container *ngIf="source === 'trustedshops'">
        <div class="reviews-display__trustedshops-heading">
          <h2 class="bw-font-headline-regular">We're the UK's most-loved online florist*</h2>
          <div class="bw-font-body-regular"><p>But don’t just take our word for it. Here’s what everyone’s been saying about us...</p></div>
        </div>
        <div class="hidden-xs reviews-display__trustedshops-info">
          <bw-reviews-stats modifier="condensed" innerclass="text-center"></bw-reviews-stats>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="h-slider" *ngIf="reviews &amp;&amp; !reviewsrebrand">
    <div class="h-slider__item-container">
      <div class="h-slider__item" *ngFor="let review of reviews">
        <bw-review-card [reviews]="review" linkToReview="/reviews" [reviewsrebrand]="reviewsrebrand" [maxlength]="125" [enableBreakPoints]="false" [source]="source"></bw-review-card>
      </div>
    </div>
  </div>
  <div class="review_row" *ngIf="reviews &amp;&amp; reviewsrebrand">
    <div class="review_col" *ngFor="let review of reviews">
      <bw-review-card [reviews]="review" linkToReview="/reviews" [reviewsrebrand]="reviewsrebrand" [maxlength]="125" [enableBreakPoints]="false" [source]="source"></bw-review-card>
    </div>
  </div>
  <div class="reviews-display__view-more" *ngIf="reviewsrebrand &amp;&amp; source === 'trustedshops'"><a class="reviews-display__more bwLink under pointer" href="https://uk.trustpilot.com/review/www.bloomandwild.com" target="_blank" rel="noopener noreferrer" bwTrackAs="component.reviews-display.more">Read more reviews</a></div>
  <div class="reviews-display__view-more" *ngIf="!reviewsrebrand"><a class="reviews-display__more bwLink under pointer" href="https://uk.trustpilot.com/review/www.bloomandwild.com" target="_blank" rel="noopener noreferrer" bwTrackAs="component.reviews-display.more">Read more reviews</a></div>
  <div class="reviews-display__disclaimer" [ngClass]="{'text-left': reviewsrebrand}">
    <div class="small">*Based on all UK online florists rated on Trustpilot or Reviews.co.uk</div>
  </div>
</div>