import { RouteReuseStrategy, UrlSegmentGroup } from '@angular/router';
import { ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { State } from 'Shared/classes/state';
import { ActivatedState } from 'Shared/classes/activated-state';

import { Injectable } from '@angular/core';
@Injectable()
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  asPath(snapshot: any) {
    let route = snapshot;
    const statePath = [];
    // First create a "state path" of activated routes (from parent, down to the child)
    do {
      route = route.firstChild;
      if (route) {
        const state = new ActivatedState(
          ((route.routeConfig as State) || {}).name,
          new UrlSegmentGroup(route.url, {}).toString(),
          route.params,
          route.queryParams,
          route.data || {}
        );
        statePath.push(state);
      }
    } while (route && route.firstChild);

    return statePath;
  }

  statePathAsActivatedState(statePath: any[]): ActivatedState {
    // Combine the data into a master route, the child exending the parent's data/params
    const fullState = statePath.reduce(
      (acc, state) => {
        acc.name.push(state.name);
        acc.url.push(state.url);
        return {
          name: acc.name,
          data: Object.assign({}, acc.data, state.data),
          pathParams: Object.assign(acc.pathParams || {}, state.pathParams),
          queryParams: Object.assign(acc.queryParams || {}, state.queryParams),
          url: acc.url
        };
      },
      {
        name: [],
        url: []
      }
    );

    const activatedState = new ActivatedState(
      fullState.name.filter((s) => s).join('.'),
      fullState.url.filter((s) => s).join('/'),
      fullState.pathParams,
      fullState.queryParams,
      fullState.data,
      statePath
    );
    return activatedState;
  }

  shouldReuseRoute(to: ActivatedRouteSnapshot, from: ActivatedRouteSnapshot): boolean {
    // We only want to reuse the route (ie not re-init the component) if ....

    // .. we are going somewhere (ie has a "from")
    if (from && from.firstChild) {
      const toPath = this.asPath(to);
      const toState = this.statePathAsActivatedState(toPath);

      const fromPath = this.asPath(to);
      const fromState = this.statePathAsActivatedState(fromPath);

      // ... and the state (parent) name is the same
      return (
        toState.name === fromState.name &&
        // ... and the flag `shouldReuseRoute` has been set
        toState.data.shouldReuseRoute &&
        fromState.data.shouldReuseRoute
      );
    }

    return false;
  }
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  // this is were the magic happens boys
  routeOrNotToRoute(to: string, from: string): boolean {
    const toUrl = to.split(new RegExp(/[&|?]/)).sort();
    const fromUrl = from.split(new RegExp(/[&|?]/)).sort();

    const filteredToUrl = this.removedFilters(toUrl);
    const filteredFromUrl = this.removedFilters(fromUrl);
    return filteredFromUrl === filteredToUrl;
  }

  removedFilters(arr: string[]): any {
    return arr.filter((item) => item.search('filters=') === -1).join('&');
  }
}
