import { Injectable } from '@angular/core';
import { SMSSignupModel } from 'Content/models/sms-signup-model.service';
import { TelephoneNumberUtilsService } from 'Shared/utils/telephone-number-utils.service';

@Injectable({
  providedIn: 'root'
})
export class SMSSignupService {
  constructor(
    private smsSignupModel: SMSSignupModel,
    private telephoneNumberUtils: TelephoneNumberUtilsService
  ) {}

  sendSMS(phonePrefix, number): Promise<any> {
    const intNumber = TelephoneNumberUtilsService.convertToInternationalNumber(phonePrefix, number);

    return this.smsSignupModel.sendSMS(intNumber);
  }
}
