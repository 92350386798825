
<div class="live-contact position-relative">
  <!-- Desktop-->
  <div class="hidden-xs hidden-sm hidden-md" *ngIf="phoneEnabled"><span class="light u-font-medium phone-number select-all"><img class="icon mr-1" src="/assets/brand-icons/phone.svg">020 3879 4099</span></div>
  <!-- Mobile-->
  <div class="hidden-lg">
    <div dropdown #dropdown="bs-dropdown" (click)="preventClose($event)">
      <button class="live-contact__trigger position-relative" dropdownToggle type="button"><img src="/assets/brand-icons/nav-chat.svg"></button>
      <div class="bwDropdownMenu dropdown-menu dropdown-menu-right" *dropdownMenu>
        <div class="bwDropdownMenu__content">
          <div class="bwRhythmSmall" *ngIf="chatOnline">
            <div class="d-flex">
              <div class="mr-2"><img class="icon mr-1" src="/assets/brand-icons/nav-chat.svg"></div>
              <div class="flex-fill mb-2">
                <h5 class="strong mb-0">Chat<span class="u-font-small recommended ml-2">Recommended</span></h5>
                <div class="d-flex align-items-center">
                  <div class="availability-circle available"></div><span>Available</span>
                </div>
                <button class="bwButton primary bwRhythmSmallTop" (click)="openChat()" bwTrackAs="components.live-contact.chat_now">Chat now</button>
              </div>
            </div>
          </div>
          <div class="bwRhythmSmall" *ngIf="phoneEnabled">
            <div class="d-flex">
              <div class="mr-2"><img class="icon mr-1" src="/assets/brand-icons/phone.svg"></div>
              <div class="flex-fill mb-2">
                <h5 class="strong mb-0 select-all">020 3879 4099</h5><a class="bwButton primary bwRhythmSmallTop bwRhythmExtraSmall" href="tel:02038794099" bwTrackAs="components.live-contact.call_now">Call now</a>
                <div>
                  <div class="text-muted u-font-small">9am-9pm Monday-Friday</div>
                  <div class="text-muted u-font-small">9am-6pm Saturday & Sunday</div>
                </div>
                <div class="u-font-extra-small text-muted italic mt-1">Except on Easter Sunday and Christmas Day</div>
              </div>
            </div>
          </div>
          <div>
            <div class="d-flex">
              <div class="mr-2"><img class="icon mr-1" src="/assets/brand-icons/email-envelope.svg"></div>
              <div class="flex-fill mb-2">
                <h5 class="strong mb-0">Submit an enquiry</h5><a class="bwButton primary bwRhythmSmallTop" href="/help" (click)="closeDropdown()" bwTrackAs="components.live-contact.contact_us">Contact us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>