import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ValidationService } from 'Shared/services/validation.service';
import { OasysButtonModule, OasysFormErrorsModule, OasysFormGroupModule, OasysLayoutModule, OasysTextInputModule } from 'oasys-lib';
import { NgFor, NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from 'Shared/components/loading-spinner/loading-spinner.component';
import { UserLookupService } from 'Shared/services/user-lookup.service';

/**
 * PLEASE DO NOT TOUCH THIS COMPONENT
 * Unauthorized changes may lead to unexpected behaviour in the authentication flow
 *
 * The Auth Email Form Component is critical for the authentication flow
 * This component includes stricly the email check logic
 * Any other decorators/logic Like rewards checkout icons, My account notes should be handled in the Feature components like Auth modal outside this component.
 *
 * Bellow is a tutorial how you can use the form inside Authentication modal(bw-auth-modal) and Checkout flow(bw-details-user)
 * Visit Authentication modal(bw-auth-modal) and Checkout flow(bw-details-user) for implementation context
 *
 * Example of struture for implenting inside a component like Authentication modal(bw-auth-modal) or Checkout flow(bw-details-user):
 *
    Feature Component ----------------------------------------------------
    |
    |   +emailExtras ------------------ Custom Feature-specific logic
    |   bw-auth-email-form ----------- EMAIL Auth logic DO NOT TOUCH
    |
    |   +passwordExtras -------------- Custom Feature-specific logic
    |   bw-auth-password-form -------- Auth logic DO NOT TOUCH
    |
    |   +registerExtras -------------- Custom Feature-specific logic
    |   bw-register-form ------------- Auth logic DO NOT TOUCH
    |
    |   +guestExtras ----------------- Custom Feature-specific logic
    |   bw-auth-guest-form ----------- Auth logic DO NOT TOUCH
    ---------------------------------------------------------------
 */
@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgFor,
    NgIf,
    OasysLayoutModule,
    OasysButtonModule,
    OasysFormGroupModule,
    OasysTextInputModule,
    OasysFormErrorsModule,
    LoadingSpinnerComponent
  ],
  selector: 'bw-auth-email-form',
  templateUrl: './auth-email-form.component.html'
})
export class AuthEmailFormComponent implements OnInit, OnDestroy {
  @Output() didSubmit: EventEmitter<{ isGuest: boolean; isRewardsMember?: boolean; validateForm?: boolean; email?: string }> =
    new EventEmitter<{
      isGuest: boolean;
      isRewardsMember?: boolean;
      validateFormControl?: boolean;
      email?: string;
    }>();
  @Output() registerNewUser: EventEmitter<{ email: string; validateForm?: boolean }> = new EventEmitter<{
    email: string;
    validateForm?: boolean;
  }>();
  @Output() didEmailChanged: EventEmitter<{ email?: string }> = new EventEmitter<{ email?: string }>();
  @Input() email: string;

  form: FormGroup;
  isSubmitted: boolean = false;
  loading: boolean = false;

  constructor(private userLookupService: UserLookupService) {}

  /**
   * On Init
   */
  ngOnInit(): void {
    this.buildForm();
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    this.form.markAsPristine();
    this.form.reset();
  }

  /**
   * Submit and check email by user
   */
  submit(): Promise<void> {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.isSubmitted = true;

    if (this.form.invalid) {
      return Promise.resolve();
    }
    this.loading = true;
    const email = this.form.get('email').value;

    return this.userLookupService
      .checkUserByEmail(email)
      .then(({ loyaltySchemeMembership, guest }): void =>
        this.didSubmit.emit({
          isRewardsMember: loyaltySchemeMembership,
          validateForm: false,
          email: email,
          isGuest: guest
        })
      )
      .catch((): void => this.registerNewUser.emit({ email: email, validateForm: false }))
      .finally((): void => {
        this.loading = false;
      });
  }

  /**
   * Check email change to sent to the parent component
   */
  didEmailInputChanged(): void {
    this.didEmailChanged.emit({ email: this.form.get('email').value });
  }

  /**
   * Build form
   */
  private buildForm(): void {
    this.form = new FormGroup({
      email: new FormControl(this.email ?? '', [Validators.required, ValidationService.email()])
    });
  }
}
