import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { StateService } from 'Shared/services/state.service';

const hasPerformed = {}; // Things can only be performed once

@Directive({
  selector: '[bwTimingEvent]'
})
export class TimingEventDirective implements OnInit {
  @Input('bwTimingEvent') trackingName: string;
  window: any;

  constructor(
    private el: ElementRef,
    private windowRef: WindowRefService,
    private analyticsService: AnalyticsService,
    private stateService: StateService
  ) {
    this.window = this.windowRef.nativeWindow;
  }

  /**
   * Measure the timings against the index:load event
   */
  measureTimings(): void {
    if (this.trackingName && !hasPerformed[this.trackingName]) {
      hasPerformed[this.trackingName] = true;
      this.window.performance.mark(`bw:${this.trackingName}:end`);
      this.window.performance.measure(
        `bw:${this.trackingName}`,
        undefined, // We want to measure from the navigation start
        `bw:${this.trackingName}:end`
      );

      const measure = this.window.performance.getEntriesByName(`bw:${this.trackingName}`);

      if (measure && measure[0]) {
        this.analyticsService.trackTiming(
          `bw:${this.trackingName}`,
          parseInt(measure[0].duration.toFixed(0), 0)
        );
      }
    }
  }

  isInViewPort(element): boolean {
    const bounding = element.getBoundingClientRect();

    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  shouldMeasureTimings(): boolean {
    return !!(
      this.isInViewPort(this.el.nativeElement) &&
      this.stateService.isInitialPage &&
      this.window.performance &&
      this.window.performance.mark
    );
  }

  ngOnInit(): void {
    // At the moment, this is only needed for the 'onload' event
    // In the future, it might be something else
    this.el.nativeElement.onload = () => {
      if (this.shouldMeasureTimings()) {
        this.measureTimings();
      }
    };
  }
}
