import { Injectable } from '@angular/core';
import { User } from 'Shared/classes/user';
import { Country } from 'Shared/classes/country';
import { UserModelService } from 'Shared/models/user-model.service';
import { environment } from 'Environments/environment';
import { Carousel } from 'Shared/classes/carousel';
import { CarouselExperiment, CarouselExperimentVaraint } from 'Shared/classes/carousel-experiment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
// personalisation.bloomandwild.com/v1/carousels?user_slug={slug}&device_fingerprint={deviceId}&shipping_country_id=1&locale=en&platform=web|ios|android&version=1.3.2

type CarouselParamType = Record<string, string | number | boolean | readonly (string | number | boolean)[]>;
@Injectable({
  providedIn: 'root'
})
export class CarouselModelService {
  cache: Record<string, Promise<Carousel[]>> = {};

  private locale: string;
  private personalisationUrl: string;

  constructor(private userModel: UserModelService, private httpClient: HttpClient) {
    this.locale = environment.locale;
    this.personalisationUrl = environment.personalisationUrl;
  }

  /**
   *  From Payload
   * @param res
   */
  fromPayload(res: any): Carousel {
    const carousel = new Carousel();
    carousel.userSegmentId = parseInt(res.user_segment_id, 10);
    carousel.type = res.type;
    carousel.typeValue = res.type_value;
    carousel.productIds = res.sku_ids.map((s: string): number => parseInt(s, 10));
    carousel.experiments = (res.experiments || []).map((e: { name: string; variants: unknown[] }): CarouselExperiment => {
      const experiment = new CarouselExperiment();
      experiment.name = e.name;
      experiment.variants = (e.variants || []).map((v: { variant: string; sku_ids: string[] }): CarouselExperimentVaraint => {
        const variant = new CarouselExperimentVaraint();
        variant.variant = parseInt(v.variant, 10);
        variant.productIds = v.sku_ids.map((s: string): number => parseInt(s, 10));
        return variant;
      });

      return experiment;
    });
    return carousel;
  }

  /**
   * Get Remote Carousel
   * @param userSlug
   * @param fingerPrint
   */
  getCarouselsForUser(shippingTo: Country, user?: User): Promise<Carousel[]> {
    const url = `${this.personalisationUrl}/v1/sku-ordering`;

    const params = {
      device_fingerprint: this.userModel.getFingerprint(),
      shipping_country_id: `${shippingTo.id}`,
      locale: this.locale,
      platform: 'web'
    } as CarouselParamType;

    if (user && user.slug) {
      params.user_slug = user.slug;
    }

    return (this.cache[shippingTo.id] =
      this.cache[shippingTo.id] ||
      lastValueFrom(
        this.httpClient.get(url, {
          params
        })
      )
        .then((r): any => (r && r['orderings'] ? r['orderings'].map((c: any): Carousel => this.fromPayload(c)) : []))
        .catch(
          (e): Promise<never> =>
            // Return the error
            Promise.reject(e)
        ));
  }
}
