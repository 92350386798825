import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ReviewsService } from 'Shared/services/reviews.service';
import { CountryService } from 'Shared/services/country.service';
import {
  IActiveViewports,
  ViewportDetectionService
} from 'Shared/services/viewport-detection.service';
import { FeaturesService } from 'Shared/services/features.service';
import { BehaviorSubject } from 'rxjs';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-reviews-slim-banner',
  templateUrl: './reviews-slim-banner.component.html'
})
export class ReviewsSlimBannerComponent implements OnInit {
  stats: any;
  reviewType: string;
  viewportSizeIs$: BehaviorSubject<IActiveViewports> = this.viewPortDetection.viewportSizeIs$;

  constructor(
    private reviewsService: ReviewsService,
    private countryService: CountryService,
    private viewPortDetection: ViewportDetectionService,
    private featureService: FeaturesService
  ) {}

  getAndSetReviewStats(): Promise<any> {
    const shippingCountry = this.countryService.forShipping;

    return this.reviewsService.getReviewStats(shippingCountry).then((res) => {
      this.stats = res;

      if (this.stats.count) {
        this.stats.count = this.stats.count.toLocaleString();
      }
    });
  }

  setReviewType(): void {
    this.reviewType = this.featureService.getFeature('REVIEWS').active
      ? this.featureService.getFeature('REVIEWS').type
      : '';
  }

  ngOnInit() {
    this.getAndSetReviewStats();
    this.setReviewType();
  }
}
