import { Component, OnInit, Input, OnChanges, ViewEncapsulation } from '@angular/core';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-review-stars-trustpilot',
  templateUrl: './review-stars-trustpilot.component.html'
})
export class ReviewStarsTrustpilotComponent implements OnInit, OnChanges {
  @Input()
  rating: number;
  @Input()
  large: boolean;
  @Input()
  larger: boolean;

  ngOnInit(): void {
    if (this.rating) {
      this.rating = Math.ceil(this.rating);
    }
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }
}
