import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { WindowRefService } from 'Shared/services/window.service';
import { Error } from 'Shared/classes/error';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { Card, CardType } from 'Shared/classes/card';
import { BankRedirect } from 'Shared/classes/bank-redirect';
import { Purchase } from 'Shared/classes/purchase';
import { t } from 'Shared/utils/translations';
import { User } from 'Shared/services/user.service';
import { LocationService } from 'Shared/services/location.service';
import { Country, CountryService } from 'Shared/services/country.service';
import { ConfigService } from 'Shared/services/config.service';
import { Order } from '../classes/order';
import { BankRedirectTypes } from '../classes/charges';
import { CheckoutService } from 'Checkout/services/checkout.service';
import { LayoutObject, StripeElements, StripeError } from '@stripe/stripe-js';
import { StripeAccount } from 'Shared/models/config-model.service.typings';

interface LegacyStripeError {
  error: {
    message: string;
    code: string;
    type: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  public Stripe: any | undefined; // CamelCase to match Stripe's docs for ease
  public StripeConnect: any | undefined; // Stripe instance that uses the connected accounts to finalise a payment

  public checkoutStripeElements: StripeElements;

  private sdkPromiseInExperiment = false;
  private sdkPromise: Promise<any> | undefined;

  // Stripe Payment Element layout options

  // TODO possibly read signals to know if element should be collapsed by default
  private stripeElementLayout: LayoutObject = {
    type: 'accordion',
    radios: false,
    spacedAccordionItems: true
  };

  private stripeElementPaymentOrder: CardType[] = ['bancontact', 'ideal', 'apple_pay', 'google_pay', 'card', 'sofort'];

  constructor(
    private domUtils: DomUtilsService,
    private windowRef: WindowRefService,
    private analyticsService: AnalyticsService,
    private locationService: LocationService,
    private countryService: CountryService,
    private configService: ConfigService,
    private checkoutService: CheckoutService
  ) {}

  /**
   * Handle any stripe messages into a more 'Bloom & Wild' friendly way
   * @param res
   * @returns {Error}
   */
  handleStripeErrorMessages({ code, type }: StripeError): Error {
    const error = new Error({
      message: t('js.payment.error.stripe.generic.message'),
      title: t('js.payment.error.stripe.generic.title'),
      code: code ?? undefined,
      kind: type ?? undefined
    });

    error.meta = {
      source: 'stripeErrors',
      code
    };
    return error;
  }

  /**
   * Handle any legacy stripe messages into a more 'Bloom & Wild' friendly way
   * @param res
   * @returns {Error}
   */
  handleLegacyStripeErrorMessages(res: LegacyStripeError): Error {
    const { code, type } = res.error ?? {};

    const error = new Error({
      message: t('js.payment.error.stripe.generic.message'),
      title: t('js.payment.error.stripe.generic.title'),
      code: code ?? undefined,
      kind: type ?? undefined
    });

    error.meta = {
      source: 'stripeErrors',
      code: res.error ? res.error.code : undefined
    };
    return error;
  }

  /**
   * Load the SDK
   * @returns {Promise<void>}
   * NOTE: Deliberately messy at the moment due to legacy Stripe loading alongside Stripe Payment Element loading
   * In the future this will be tidied up with the rollout of SPE i.e No need to load Stripe and StripeConnect together
   */
  loadSDK(): Promise<void> {
    const shouldLoadNewStripeElement = this.checkoutService.isInStripePaymentElementExperiment();

    if (shouldLoadNewStripeElement !== this.sdkPromiseInExperiment || this.sdkPromise === undefined) {
      this.sdkPromiseInExperiment = shouldLoadNewStripeElement;
      this.sdkPromise = this.initStripe(shouldLoadNewStripeElement);
    }

    return this.sdkPromise;
  }

  /**
   * Handle the payment
   * @param paymentOption
   * @param paymentSecret
   */
  handlePayment(paymentSecret: string, paymentOption: Card): Promise<any> {
    const args = [
      paymentSecret,
      {
        payment_method: paymentOption.token
      },
      {
        handleActions: true
      }
    ];

    // stripe sdk confirm functions
    const stripeFunction = paymentOption.kind === 'sepa_debit' ? 'confirmSepaDebitPayment' : 'confirmCardPayment';

    return this.StripeConnect[stripeFunction](...args).then((result) => {
      if (!result.error) {
        return Promise.resolve(result);
      }
      const error = this.handleStripeErrorMessages(result);
      this.analyticsService.trackError(error);
      return Promise.reject(error);
    });
  }

  /**
   * Handle the card setup intent for future use
   * Used to add card from my account
   * @param setupIntentSecret
   * @param card
   */
  handleCardSetup(setupIntentSecret: string, card: Card): Promise<any> {
    return this.Stripe.confirmCardSetup(setupIntentSecret, {
      payment_method: card.token
    }).then((result) => {
      if (!result.error) {
        return Promise.resolve(result);
      }

      const error = this.handleLegacyStripeErrorMessages(result);

      this.analyticsService.trackError(error);
      return Promise.reject(error);
    });
  }

  /**
   * Create a stripe token given a stripe card object
   * @param card
   */
  createToken(card: any, orderShippingCountry?: Country): Promise<any> {
    return this.Stripe.createToken(card, orderShippingCountry).then((result) => {
      if (!result.error) {
        return Promise.resolve(result.token);
      }

      const error = this.handleStripeErrorMessages(result);
      this.analyticsService.trackError(error);
      return Promise.reject(error);
    });
  }

  /**
   * Get the payment intent details
   * Used by Payment result in my account, not in the main flow
   * @param paymentIntent
   */
  getPaymentIntent(paymentIntent: string): Promise<any> {
    return this.Stripe.retrievePaymentIntent(paymentIntent).then((result) => {
      const isSuccess = result && result.paymentIntent && result.paymentIntent.status === 'succeeded';

      if (isSuccess) {
        return Promise.resolve(result);
      }

      if (!result.error) {
        result.error = {
          code: 'paymentIntentFailed',
          message: 'Payment Intent Failed',
          type: 'paymentIntent'
        };
      }

      const error = this.handleLegacyStripeErrorMessages(result);

      this.analyticsService.trackError(error);
      return Promise.reject(error);
    });
  }

  /**
   * Initiates the iDeal redirect
   */
  confirmBankRedirectPayment(res: string, paymentMethod: CardType, paymentDetails: BankRedirect['paymentDetails']): Promise<any> {
    // stripe sdk redirect methods
    const stripeRedirects = {
      ideal: 'confirmIdealPayment',
      bancontact: 'confirmBancontactPayment',
      sofort: 'confirmSofortPayment'
    };

    return this.StripeConnect[stripeRedirects[paymentMethod]](res, paymentDetails, {
      handleActions: true
    }).then((result) => {
      if (!result.error) {
        return Promise.resolve(result);
      }

      const error = this.handleLegacyStripeErrorMessages(result);

      this.analyticsService.trackError(error);
      return Promise.reject(error);
    });
  }

  /**
   * Returns the redirect url for a bank redirect payment method
   */
  getRedirectUrl(user: User, purchase: Purchase, paymentMethod: CardType): string {
    const host = this.locationService.getHostWithSubfolder();
    let returnURL = `https://${host}/restore.html#purchaseId=${purchase?.id}&purchaseToken=${
      purchase?.token
    }&bankRedirect=${paymentMethod}&userEmail=${user?.email?.address}&userFullName=${encodeURIComponent(user?.fullName)}`;

    if (user?.token) {
      returnURL += `&userToken=${user.token}`;
    }

    return returnURL;
  }

  /**
   * Returns the redirect url using order for a bank redirect payment method
   * @param {User} user
   * @param {Order} order
   * @param {BankRedirectTypes} type
   * @returns {string}
   */
  getOrderRedirectUrl(user: User, order: Order, type: BankRedirectTypes): string {
    const host = this.locationService.getHostWithSubfolder();

    let returnURL = `https://${host}/restore.html#orderId=${order?.id}&bankRedirect=${type}&userEmail=${
      user?.email?.address
    }&userFullName=${encodeURIComponent(user?.fullName)}`;

    if (user?.token) {
      returnURL += `&userToken=${user.token}`;
    }

    const { activeWithFailedPayment, pausedDueToFailedPayment } = order.subscription?.failedPaymentsData ?? {};
    if (activeWithFailedPayment === true || pausedDueToFailedPayment === true) {
      returnURL += '&hasFailedPayment=true';
    }

    if (order.stateIs('paused')) {
      returnURL += '&isPausedOrder=true';
    }

    return returnURL;
  }

  /**
   * Init stripe script and instance
   * @returns {Promise<void>}
   */
  private initStripe(shouldLoadNewStripeElement: boolean): Promise<void> {
    const shippingCountry = this.countryService.forShipping;
    const script = 'https://js.stripe.com/v3/';

    if (shouldLoadNewStripeElement) {
      console.error('[StripeService] Experimental Stripe must not be loaded from the StripeService');

      return Promise.resolve();
    }

    return this.domUtils
      .loadScript(script, 'stripe')
      .then((): Promise<StripeAccount> => this.configService.getStripeAccount(shippingCountry))
      .then((stripeConfig: StripeAccount): void => {
        // ideally tidy up in the future
        const stripeFn = this.windowRef.nativeWindow.Stripe;
        const options = stripeConfig?.stripeAccountId ? { stripeAccount: stripeConfig.stripeAccountId } : {};
        if (shouldLoadNewStripeElement) {
          // do nothing
        } else {
          this.Stripe = stripeFn(stripeConfig.stripeClientKey); // OLD STRIPE ELEMENT
          // Initialising the stripe instance with connected accounts
          this.StripeConnect = stripeFn(stripeConfig.stripeClientKey, options);
        }
      });
  }
}
