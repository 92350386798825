import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { WindowRefService } from 'Shared/services/window.service';
import { environment } from 'Environments/environment';
import { Purchase } from 'Shared/classes/purchase';
import { User } from 'Shared/classes/user';
import { FeaturesService } from 'Shared/services/features.service';
import { UserService } from 'Shared/services/user.service';
import { StateService } from '../state.service';

@Injectable({
  providedIn: 'root'
})
export class GtagService {
  private serviceInitialized: boolean = false;
  private window: Window;
  private debug: boolean = false;
  private accountId: string;

  constructor(
    private domUtils: DomUtilsService,
    private windowRef: WindowRefService,
    private featureService: FeaturesService,
    private userService: UserService,
    private stateService: StateService
  ) {
    this.window = this.windowRef.nativeWindow;
    this.accountId = environment.gtagConversionId;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Log
   */
  log(...args: unknown[]): void {
    if (this.debug) {
      console.log('<gtag>', ...args);
    }
  }

  /**
   * Track Conversion
   * @param obj
   */
  google_trackConversion(eventType: string, obj: { [key: string]: unknown; send_to: string }): void {
    if (this.window['gtag'] && this.serviceInitialized) {
      try {
        this.window.gtag('event', eventType, obj);
        this.log(eventType, obj);
      } catch (e: unknown) {}
    }
  }

  /**
   * Track Enhanced Conversion
   * @param user
   */
  google_trackEnhancedConversion(user: User): void {
    if (this.window['gtag'] && this.serviceInitialized) {
      try {
        this.window.gtag('set', 'user_data', {
          email: user.email.address
        });
        this.log(user);
      } catch (e: unknown) {}
    }
  }

  /**
   * Helper function execute code for the mid funnel events
   * @param eventType - The type of the event to be tracked
   * @param label - The label value for the specific event
   */
  trackMidFunnelEvent(eventType: string, label: string): void {
    if (this.featureService.getFeature('ENHANCED_CONVERSION')) {
      this.google_trackEnhancedConversion(this.userService.getUser());
    }

    this.google_trackConversion(eventType, {
      send_to: `${this.accountId}/${label}`,
      event_trigger: eventType
    });
  }

  /**
   * Track when a user lands on a page, currently only being used for the homepage
   */
  sendPageView(): void {
    const currentUrl: string = this.stateService.getCurrent().url;
    if (currentUrl === '' || currentUrl === '/') {
      this.trackMidFunnelEvent('view_homepage', environment.adwordsMidFunnel.ViewHomepage);
    }
  }

  /**
   * Track when a user visits the grid
   */
  trackViewListItems(): void {
    this.trackMidFunnelEvent('view_items_list', environment.adwordsMidFunnel.ViewItemList);
  }

  /**
   * Track when a user views a product by opening the product modal or visiting the PDP page
   */
  trackProductView(): void {
    this.trackMidFunnelEvent('view_item', environment.adwordsMidFunnel.ViewItem);
  }

  /**
   * Track when a user selects a product and goes to checkout
   */
  trackProductSelected(): void {
    this.trackMidFunnelEvent('begin_checkout', environment.adwordsMidFunnel.BeginCheckout);
  }

  /**
   * Track when a user reaches the payment page
   */
  trackViewCart(): void {
    this.trackMidFunnelEvent('view_cart', environment.adwordsMidFunnel.ViewCart);
  }

  /**
   * This is the original adwords pixel which tracks when a user completes a purchase
   */
  trackConversion(purchase: Purchase, user: User): void {
    const environmentAdwordsConversion = purchase.hasSubscriptionOrder()
      ? environment.adwordsConversionSubs
      : environment.adwordsConversion;

    const conversionLabel = environmentAdwordsConversion.find((obj): boolean => {
      const accId: string[] = this.accountId.split('-');
      return obj.id === accId[1];
    });

    if (this.featureService.getFeature('ENHANCED_CONVERSION')) {
      this.google_trackEnhancedConversion(user);
    }

    // ! orderCount is 1 not 0 as logged in users even on first order have an order count of 1
    this.google_trackConversion('conversion', {
      send_to: `${this.accountId}/${conversionLabel.label}`,
      value: (purchase.price.price / 100).toFixed(2),
      currency: purchase.price.currency,
      new_customer: !(user.orderCount > 1 || user.email?.hasOrdered || false),
      transaction_id: purchase.id
    });
  }

  /**
   * Init GTAG
   */
  init(): Promise<void> {
    const key: string = environment.gtagConversionId;
    return this.domUtils.loadScript(`https://www.googletagmanager.com/gtag/js?id=${key}`, 'gtag').then((): void => {
      this.serviceInitialized = true;
      this.log('init gtag service');

      if (environment.adwordsMidFunnel.enabled) {
        this.sendPageView();
      }
    });
  }
}
