import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ServiceBarService } from 'Shared/services/service-bar.service';
import { ServiceBarMessage } from 'Shared/classes/service-bar-message';
import * as dayjs from 'dayjs';
import { CountryService } from 'Shared/services/country.service';
import { Subscription } from 'rxjs';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-service-bar',
  templateUrl: './service-bar.component.html'
})
export class ServiceBarComponent implements OnInit, OnDestroy {
  today: dayjs.Dayjs = dayjs();
  message: ServiceBarMessage;
  countryForShipping$: Subscription;

  @Output() loaded: EventEmitter<any> = new EventEmitter();

  constructor(
    private serviceBarService: ServiceBarService,
    private countryService: CountryService
  ) {}

  getValidOnGoingMessage(messages: ServiceBarMessage[]): ServiceBarMessage {
    return (messages || [])
      .filter(m => m.daysVisible.indexOf(this.today.day()) !== -1)
      .find(m => {
        const todayHourMinutes = parseInt(`${this.today.hour()}${this.today.minute()}`, 10);
        const isBefore = +m.from.format('HHmm') < todayHourMinutes;
        const isAfter = +m.to.format('HHmm') > todayHourMinutes;
        return m.active && isAfter && isBefore;
      });
  }

  getValidOverrideMessage(messages: ServiceBarMessage[]): ServiceBarMessage {
    return (messages || []).find(m => {
      return m.active && m.to.isAfter(this.today) && m.from.isBefore(this.today);
    });
  }

  getMessage(): Promise<any> {
    return this.serviceBarService.getAllServiceBarMessages().then(response => {
      this.message =
        this.getValidOverrideMessage(response[1]) || this.getValidOnGoingMessage(response[0]);
      if (this.message && this.message.tags && this.message.tags.indexOf('universal') > -1) {
        document.body.setAttribute('service-bar-tag', 'universal');
         // If response contains universal tag then we need to override the settings and always show the service bar
        this.message.deviceSettings.hideOnMobile = false;
        this.message.deviceSettings.hideOnDesktop = false;
      }
      this.loaded.emit();
    });
  }

  ngOnInit(): void {
    this.countryForShipping$ = this.countryService.forShipping$.subscribe(data => {
      this.getMessage();
    });
  }

  ngOnDestroy(): void {
    if (this.countryForShipping$) {
      this.countryForShipping$.unsubscribe();
    }
  }
}
