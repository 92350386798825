import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { InitialLoadService } from 'Shared/services/initial-load.service';

@Injectable()
export class HasInitialLoadedGuard implements CanActivate {
  constructor(private initialLoadService: InitialLoadService) {}

  /**
   * Only activate if the initial load service has init
   */
  canActivate(): Promise<boolean> {
    return this.initialLoadService
      .init()
      .then(() => Promise.resolve(true))
      .catch(() => Promise.resolve(true)); // Always pass
  }
}
