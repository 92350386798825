import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'blogTags'
})
export class BlogTagsPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    value = value.replace('-and-', ' & ');
    value = value.replace(/-/g, ' ');
    return value;
  }
}