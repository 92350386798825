
<bw-seo title="Bloom &amp; Wild Reviews | Bloom &amp; Wild" description="Read online customer reviews of Bloom &amp; Wild and find out why we are the UK's top rated flower company. Read independent reviews of Bloom &amp; Wild now."></bw-seo>
<div class="reviews-landing-page container text-center reviews-landing-page--{{source}}" itemscope itemtype="http://schema.org/Product">
  <div class="reviews-landing-page__header row justify-content-lg-center"><span itemprop="name" style="display:none">Bloom &amp; Wild</span>
    <h1 class="page-heading bwRhythmTop" *ngIf="source === 'trustpilot'">The UK's top rated flower delivery company</h1>
    <h1 class="page-heading bwRhythmTop" *ngIf="source === 'reviews'">Reviews</h1>
    <p class="col-lg-6">From birthdays to anniversaries, there are many reasons to send flowers, and we know that each one is as important as the next. That's why we promise to take care to make each flower delivery extra special.</p>
    <div class="reviews__actions col-lg-12">
      <bw-button uiSref="checkout.base" bwTrackAs="reviews.page.buttons.order">Order yours</bw-button>
    </div>
  </div>
  <div class="reviews-landing-page__items">
    <div class="card-columns" *ngIf="reviewsData">
      <bw-review-card *ngFor="let review of reviewsData.reviews" [reviews]="review" [maxlength]="1000" [enableBreakPoints]="true" [source]="source"></bw-review-card>
    </div>
  </div>
  <div class="reviews-landing-page__stats" *ngIf="reviewsData &amp;&amp; source === 'reviews'">
    <p class="heading large serif">The UK's top rated flower delivery company</p>
    <bw-review-stars [rating]="reviewsData.averageRating" [larger]="true"></bw-review-stars>
    <p class="u-font-small sub-heading" itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating"><span itemprop="reviewCount" style="display:none">{{reviewsData.count}}</span><span class="text-muted" itemprop="ratingValue">{{ reviewsData.averageRating }} ({{ reviewsData.count }} reviews)</span></p>
    <p class="u-font-small review__powered-by">Reviews from <a href="//uk.trustpilot.com/review/www.bloomandwild.com">Trustpilot.com</a></p>
  </div>
  <div class="reviews-landing-page__stats" *ngIf="reviewsData &amp;&amp; source === 'trustpilot' &amp;&amp; showReviewsTotal">
    <bw-reviews-stats modifier="large" innerclass="text-center"></bw-reviews-stats>
  </div>
</div>