/* eslint-disable */
export const knownExperiments = [
  {
    "name": "API_REWARDS_DOUBLE_POINTS",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "AUTO_OPT_IN_TO_REWARDS",
    "variants": [
      0,
      1,
      2
    ]
  },
  {
    "name": "BLOOMON_BE_MULTI_CODE_REDEMPTION",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "BLOOMON_FILTERS",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "BLOOMON_NL_MULTI_CODE_REDEMPTION",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "BLOOMON_SUBS_GRID_REDESIGN",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "BW_RANGE_GRID",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "CHECKOUT_PERSISTENT_STORAGE_TIME",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "DATE_FILTER_FROM_BE",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "DESKTOP_SPLASH_CATEGORIES",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "DE_PAID_GREETING_CARDS",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "DISCOUNT_ERROR_CHANGE",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "EXPIRY_DATE_AND_CVC",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "HIDE_THE_PHONE_NUMBER_FIELD",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "HIDING_CELEBRATION_GC_COLLECTION",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "HPT106_HIDING_DPD_CLASSIC",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "HPT42_BW_UK_WEB_KLARNA",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "HPT73_BW_UK_WEB_DISCOUNTED_PRICING_ON_GREETING_CARD_MODAL",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "HPT78_BW_DE_WEB_DEFAULT_TO_CHEAPEST_DATE",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "HPT93_BW_UK_WEB_PASSWORD_COMPLEXITY",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "LAZY_GRID",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "NEW_AUTH_FORMS",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "NEW_KLARNA_PAYMENT_OPTIONS",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "PDP_SLICE_TWO",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "PDP_VIDEO",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "PRICING_CLARITY_SLICE_1",
    "variants": [
      0,
      1,
      2,
      3,
      4
    ]
  },
  {
    "name": "STRIPE_PAYMENT_ELEMENT",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "THUISWINKEL_CERTIFICATION",
    "variants": [
      0,
      1
    ]
  },
  {
    "name": "VIDEO_GREETING_CARD",
    "variants": [
      0,
      1
    ]
  }
];