import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { Product } from 'Shared/classes/product';
import { Purchase } from 'Shared/classes/purchase';
import { Order } from 'Shared/classes/order';
import { environment } from 'Environments/environment';
import { User } from 'Shared/classes/user';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { Error } from 'Shared/classes/error';
import { CurrencyCode } from 'Shared/classes/price';
import { StateService } from '../state.service';

export interface GaProductImpressions {
  id: number;
  name: string;
  category: string;
  list: string;
  price: number;
  position: number;
  brand: string;
}

@Injectable({
  providedIn: 'root'
})
export class GaService {
  private dimensions: any = {}; // Available dimensions by config
  private window: any;
  private brand: string;
  private debug: boolean = false;
  private initPromise: Promise<any>;
  private serviceInitialized: boolean = false;
  private googleUniversalAnalyticsID: string = '';

  private trackerName = 'BWGATracker';

  constructor(
    private windowRef: WindowRefService,
    private domUtils: DomUtilsService,
    private stateService: StateService
  ) {
    this.dimensions = environment.gaDimensions;
    this.brand = environment.brandId; // Due to circular dependancies
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
    this.googleUniversalAnalyticsID = environment.gaId;
  }

  /**
   * GA Call
   * @param args
   */
  ga(fnName: string, ...args): void {
    // We need to make sure we are using the same tracker object each time for commands
    // that are not create or 'enhanced ecommerce'
    const command = fnName === 'create' ? 'create' : `${this.trackerName}.${fnName}`;

    try {
      if (this.serviceInitialized && this.window['ga']) {
        this.window.ga(command, ...args);

        if (this.debug) {
          console.log('<ga>', command, ...args);
        }
      }
    } catch (e) {}
  }

  /* ===========================
  Utils
  =========================== */

  /**
   * Forcibly set the URL
   * @param url
   */
  setUrl(url: string): void {
    this.ga('set', 'page', url);
  }

  /**
   * Set Referral link
   * @param url
   */
  setReferrer(url: string): void {
    this.ga('set', 'referrer', url);
  }

  /**
   * Set Currency
   * @param currency
   */
  setCurrency(currency: CurrencyCode): void {
    this.ga('set', 'currencyCode', currency);
  }

  /**
   * Send a page view
   */
  sendPageView(): void {
    this.ga('send', 'pageview');
  }

  /**
   * Get the tracker
   */
  getTracker(): any {
    try {
      return this.window.ga ? this.window.ga.getByName(this.trackerName) : null;
    } catch (e) {}
  }

  /**
   * Get the client Id (This is GA's unique user id)
   */
  getClientId(): string {
    const tracker = this.getTracker();
    return tracker ? tracker.get('clientId') : '';
  }

  /**
   * Identify a user within GA
   */
  identify(user: User): void {
    this.ga('set', {
      userId: user.slug
    });
  }

  /**
   * Set dimension
   * @param key
   * @param value
   */
  setDimension(key: string, value: string): void {
    try {
      const obj = {};
      obj[key] = `${value}`; // Convert to string

      const dimension = this.dimensions[key];
      if (this.window['ga'] && dimension) {
        this.window.ga(`${this.trackerName}.set`, `dimension${dimension}`, obj[key]);
      }
    } catch (e) {}
  }

  /* ===========================
  Sending Commands
  =========================== */

  /**
   * Send an event
   * @param obj
   */
  private sendEvent(obj: {
    eventCategory: string;
    eventAction: string;
    eventLabel?: string;
    eventValue?: string;
    nonInteraction?: boolean; // Interaction events count towards session and bounce rate
  }): void {
    this.ga('send', {
      hitType: 'event',
      eventCategory: obj.eventCategory,
      eventAction: obj.eventAction,
      eventLabel: obj.eventLabel,
      eventValue: obj.eventValue,
      nonInteraction: obj.nonInteraction
    });
  }

  /* ===========================
  Tracking Commands
  =========================== */

  /**
   * Track a standard event
   * @param obj
   */
  trackEvent(obj: {
    eventCategory: string;
    eventAction: string;
    eventLabel?: string;
    eventValue?: string;
  }): void {
    const event: any = obj;
    event.nonInteraction = obj.eventCategory !== 'web:ui:click';
    this.sendEvent(event);
  }

  /**
   * Track a product impression
   * @param product
   * @param listName
   * @param position
   */
  trackImpression(products: GaProductImpressions[], currency: string): any {
    products.forEach((p) => {
        this.ga('ec:addImpression', p);
    });

    // As per GA's suggestion, this should be sent as a 'Purchase'.... even though it's not...
    this.sendEvent({
      eventCategory: 'Transaction',
      eventAction: 'Purchase',
      nonInteraction: true
    });
  }

  /**
   *
   * @param products
   */
  trackModularProductView(products: {index: number, product: Product}[]): void {
    products.forEach(p => {
      if (p.product?.id === -1) {
        this.sendEvent({
          eventCategory: 'ModularContentBlock',
          eventAction: 'View',
          eventLabel: `${p.index + 1}-${p.product.tags[0]}`
        });
      }
    })
  }

  /**
   * Track product details for multiple products
   * @param products
   * @param orderType
   */
  trackProductDetailViewed(products: Product[], orderType: 'OneOff' = 'OneOff'): void {
    products.forEach((product, index) => {
      this.ga('ec:addProduct', {
        id: product.id,
        name: product.name,
        category: product.collectionName,
        brand: this.brand,
        variant: orderType,
        position: index + 1
      });
    });
    this.ga('ec:setAction', 'detail');

    this.sendEvent({
      eventCategory: 'Transaction',
      eventAction: 'Purchase'
    });
  }

  /**
   * Track Product Selected
   * @param product
   * @param orderType
   */
  trackProductSelected(product: Product, orderType: string): void {
    this.ga('ec:addProduct', {
      id: product.id,
      name: product.name,
      category: product.collectionName,
      variant: orderType,
      position: 1
    });

    this.ga('ec:setAction', 'click');

    this.sendEvent({
      eventCategory: 'Transaction',
      eventAction: 'Purchase'
    });
  }

  /**
   * Track Product View
   * @param product
   */
  trackProductView(product: Product): void {
    this.ga('ec:addProduct', {
      id: product.id,
      name: product.name,
      category: product.collectionName,
      position: 1
    });

    this.ga('ec:setAction', 'detail');

    this.sendEvent({
      eventCategory: 'Transaction',
      eventAction: 'Purchase'
    });
  }

  /**
   * Track add to purchase
   * @param order
   */
  trackAddToPurchase(order: Order): any {
    this.ga('ec:addProduct', {
      id: order.product.id,
      name: order.product.name,
      category: order.product.collectionName,
      variant: order.getTrackedDurationName(),
      price: (order.getPrice(true, false).price / 100).toFixed(2),
      quantity: 1 // Always 1
    });

    this.ga('ec:setAction', 'add');

    this.sendEvent({
      eventCategory: 'Transaction',
      eventAction: 'Purchase'
    });
  }

  /**
   * Track purchase complete
   * @param purchase
   */
  trackPurchaseComplete(purchase: Purchase, products?: any[]): any {
    (products || []).forEach((product) => this.ga('ec:addProduct', product));

    this.ga('ec:setAction', 'purchase', {
      id: purchase.id,
      revenue: (purchase.price.price / 100).toFixed(2)
    });

    this.sendEvent({
      eventCategory: 'Transaction',
      eventAction: 'Purchase'
    });

    this.ga('send', {
      hitType: 'event',
      eventCategory: 'Global Transaction Complete',
      eventValue: (purchase.price.price / 100).toFixed(),
      currency: purchase.currency,
      eventAction: purchase.id
    });
  }

  /**
   * Track speed
   * @param category
   * @param variable
   * @param value
   */
  trackSpeed(category: string, variable: string, value: number): void {
    this.ga('send', {
      hitType: 'timing',
      timingCategory: category,
      timingVar: variable,
      timingValue: value,
      nonInteraction: true
    });
  }

  /**
   * Track error
   * @param error
   */
  trackError(error: Error): void {
    const eventLabel = `${error.code || ''}${error.message ? ' | ' + error.message : ''}`;
    this.ga('send', {
      hitType: 'event',
      eventCategory: error.meta?.source || error.code,
      eventAction: 'web:ui:error',
      eventLabel: eventLabel,
      nonInteraction: true
    });
  }

  init(options: { userSlug?: string; fingerprint?: string } = {}): Promise<any> {
    this.initPromise =
      this.initPromise ||
      this.domUtils
        .loadScript('https://www.google-analytics.com/analytics.js', 'google-analytics')
        .then(() => {
          this.serviceInitialized = true;

          const gaSettings = {
            storage: 'none',
            name: this.trackerName
          };
          if (options.fingerprint) {
            gaSettings['clientId'] = options.fingerprint;
          }

          this.ga('create', this.googleUniversalAnalyticsID, gaSettings);
          this.ga('require', 'ec');
          // Set IP anonymisation
          this.ga('set', 'anonymizeIp', true);

          if (options.userSlug) {
            const u = new User(); // Temporary user object
            u.slug = options.userSlug;
            this.identify(u);
          }
          const previousState = this.stateService.getFrom();
          // if the user was successful to get to the brankredirect route then we must first set the referral link
          // so we don't start a new session
          if (previousState && previousState.name === 'checkout.bankredirect') {
            this.setReferrer('https://stripe.com');
          }
          // Send pageview event with GA load
          this.sendPageView();
        });
    return this.initPromise;
  }
}
