import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ModalService } from 'Shared/services/modal.service';
import { ContentModalComponent } from 'Content/components/content-modal/content-modal.component';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-open-content-modal',
  template: '<ng-content></ng-content>'
})
export class OpenContentModalComponent implements OnInit {
  @Input() contentKey: string;
  @Input() asIframe: boolean = false;
  @Input() classNames: string;
  @Input() heading: string;

  constructor(private modalService: ModalService) {}

  @HostListener('click', ['$event'])
  onClick(event: Event): Promise<void> {
    // To stop the click going to parent elements (eg in the T&Cs for germany Checkout)
    event.stopPropagation();
    event.preventDefault();

    return this.modalService
      .show(ContentModalComponent, {
        initialState: {
          contentPath: this.contentKey,
          asIframe: this.asIframe,
          title: this.heading || ' '
        },
        class: `modal-lg  ${this.asIframe ? 'modal-iframe' : ''} ${this.classNames}`
      })
      .then(() => {})
      .catch(() => {}); // Nothing
  }

  ngOnInit(): void {}
}
