import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalResponse, ModalResponseSubject } from 'Shared/services/modal.service';
import { User } from 'Shared/services/user.service';
import { Order } from 'Shared/classes/order';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-subscription-cancel-modal',
  templateUrl: './subscription-cancel-modal.component.html'
})
export class SubscriptionCancelModalComponent implements ModalResponse {
  modalResponse: Subject<ModalResponseSubject> = new Subject<ModalResponseSubject>();
  user: User;
  order: Order;
  constructor() {}
  onCancel(): void {
    this.modalResponse.next({ success: false });
  }
  onSuccess(): void {
    this.modalResponse.next({
      success: true,
      data: {}
    });
  }
}
