import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ConfigModelService } from 'Shared/models/config-model.service';
import { WindowRefService } from 'Shared/services/window.service';

@Injectable()
export class Trigger404Status implements Resolve<any> {
  window: any;
  constructor(private windowRef: WindowRefService) {
    this.window = this.windowRef.nativeWindow;
  }

  /**
   * Can activate
   */
  resolve(): Promise<any> {
    // To ensure Seo4Ajax picks this up as a proper 404
    // `this.window.onCaptureReady` is injected via the pre-render
    // Docs: https://www.seo4ajax.com/docs/capture-hooks/
    if (this.window.onCaptureReady) {
      this.window.onCaptureReady(404);
    }
    return Promise.resolve(true);
  }
}
