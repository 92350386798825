import { OnInit, Component, Input } from '@angular/core';
import { DeliveryService } from 'Shared/services/delivery.service';
import { Delivery } from 'Shared/classes/delivery';
import { StateParams, StateService } from 'Shared/services/state.service';
import { ViewportDetectionService } from 'Shared/services/viewport-detection.service';
import { BehaviorSubject } from 'rxjs';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-delivery-tracking',
  templateUrl: './delivery-tracking.component.html'
})
export class DeliveryTrackingComponent implements OnInit {
  @Input() isOpen: boolean;
  delivery: Delivery;
  orderToken: string;
  viewportSizeIs$: BehaviorSubject<any> = this.viewportDetectionService.viewportSizeIs$;

  constructor(
    private deliveryService: DeliveryService,
    public stateService: StateService,
    private viewportDetectionService: ViewportDetectionService
  ) {}

  ngOnInit(): Promise<any> {
    const params = this.stateService.getInitial().queryParams as StateParams;

    // Do nothing if we don't have a deluvery id
    if (!params.deliveryId || !params.deliveryTrackingToken) {
      return Promise.resolve();
    }
    this.orderToken = params.deliveryTrackingToken || null;
    const d = new Delivery();
    d.id = parseInt(params.deliveryId, 10);

    return this.deliveryService.get(d, params.deliveryTrackingToken).then((delivery) => {
      this.delivery = delivery as Delivery;
    });
  }
}
