
<div class="modal-header">
  <div class="modal-title">Basket</div>
  <button class="close" aria-label="close" (click)="onCancel()"><span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body" #bwModal>
  <div class="bwRhythmContainer">
    <bw-purchase-basket [spaceLimited]="true" [user]="user" (basketEmpty)="onBasketEmpty()" (editedOrder)="onCancel()" (cancel)="onCancel()"></bw-purchase-basket>
  </div>
</div>
<div class="modal-footer center">
  <div class="bwButtonGroup vertical spaced" [hidden]="!(purchase$ | async)?.orders?.length">
    <bw-button buttonStyle="primary" size="medium" (click)="checkout()" bwTrackAs="component.basket-modal.auto_key.string_2">Check out</bw-button>
    <bw-button buttonStyle="modal-tertiary" size="medium" (click)="onCancel()" bwTrackAs="component.basket-modal.auto_key.string_3">Continue shopping</bw-button>
  </div>
</div>