import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DiscountService } from 'Checkout/services/discount.service';
import { BehaviorSubject } from 'rxjs';
import { PurchaseService, Purchase } from 'Checkout/services/purchase.service';
import { DiscountMessagePipe } from 'Shared/pipes/discount-message.pipe';
import { CountryService } from 'Shared/services/country.service';

@Component({
  selector: 'bw-discount-bar',
  templateUrl: './discount-bar.component.html',
  styleUrls: ['./discount-bar.component.scss']
})
export class DiscountBarComponent implements OnInit {
  showDiscountBar: boolean = false;
  showDiscountBar$: BehaviorSubject<boolean> = this.discountService.showDiscountBar$;
  purchase$: BehaviorSubject<Purchase> = this.purchaseService.purchase$;
  discountValue: string;

  @Output() loaded: EventEmitter<any> = new EventEmitter();

  constructor(
    private discountService: DiscountService,
    private discountMessagePipe: DiscountMessagePipe,
    private countryService: CountryService,
    private purchaseService: PurchaseService
  ) {}

  ngOnInit(): void {
    const purchase = this.purchase$.getValue();

    if (purchase && purchase.discount) {
      this.discountValue = this.discountMessagePipe.transform(
        purchase.discount,
        this.countryService.forShipping.currencyCode
      );
      this.loaded.emit();
    }
  }
}
