import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  HostListener
} from '@angular/core';
import { MegaNavItemSection, MegaNavItemSectionLink } from 'Shared/classes/mega-nav-item';
import { WindowRefService } from 'Shared/services/window.service';
/* bw:view-encapsulation */
@Component({
  selector: 'bw-mega-nav-desktop-child',
  templateUrl: './mega-nav-desktop-child.component.html'
})
export class MegaNavDesktopChildComponent implements AfterViewInit {
  @Input() childSectionLinks: MegaNavItemSection[];
  @Input() childSectionColour: string;
  @Input() height: number = undefined;
  @Input() childSectionUnderline: string;
  @Input() loadImages: boolean;
  @Output() navClicked = new EventEmitter<any>();
  @Output() contentSpotDidClicked = new EventEmitter<any>();
  @Output() closeNavs = new EventEmitter<any>();
  @ViewChild('childNavItem', { static: true } as any) childNavItem;
  horizontalAdjustment: number = 0;

  constructor(private windowRef: WindowRefService) {}

  navDidClicked(navItem: MegaNavItemSectionLink | MegaNavItemSection): any {
    this.navClicked.emit(navItem);
  }

  contentSpotClicked(section: MegaNavItemSection): any {
    this.contentSpotDidClicked.emit(section);
  }

  closeAllNavs(): void {
    this.closeNavs.emit();
  }

  preventMenuOverflow(): void {
    this.horizontalAdjustment = 0;
    setTimeout(() => {
      const pageWidth = this.windowRef.nativeWindow.innerWidth;
      const itemRect = this.childNavItem.nativeElement.getBoundingClientRect();
      const itemX = itemRect.x || itemRect.left;
      if (itemX + itemRect.width > pageWidth) {
        this.horizontalAdjustment = -(itemX + itemRect.width + 16 - pageWidth);
      }
    }, 0);
  }

  handleKeyUp(keyboardEvent: KeyboardEvent): void {
    switch (keyboardEvent.code || keyboardEvent.keyCode) {
      case 'Escape':
      case 27:
        this.closeAllNavs();
        break;
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    this.preventMenuOverflow();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.preventMenuOverflow();
    }, 500);
  }
}
