import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrAnimations } from 'Base/app/toastr/toastr.animations';
import { ToastrService, FeedbackToastrMessage } from 'Base/app/toastr/services/toastr.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-feedback-toastr',
  templateUrl: './feedback-toastr.component.html',
  animations: ToastrAnimations
})
export class FeedbackToastrComponent implements OnInit {
  @ViewChild('toastProgress') toastProgress: ElementRef;

  toastProgressAnimationClass: string = 'toastr__progress--animation-running';
  message: FeedbackToastrMessage;
  messageClosing: boolean = false;

  messageTimeoutActive: boolean = false;
  messageTimeoutPaused: boolean = false;
  messageTimeout: any;
  easeTimeout: any;

  component: FeedbackToastrComponent = this;

  constructor(private toastrService: ToastrService) {}

  /**
   * Close Toastr
   */
  close(): Promise<void> {
    this.stopTimeout();
    this.messageClosing = true;

    // set message to hidden (triggering the animation)
    this.message.visible = false;

    return new Promise((resolve) => {
      clearTimeout(this.easeTimeout);
      // set timeout for length of animation
      this.easeTimeout = setTimeout(() => {
        this.message = undefined;
        this.messageClosing = false;
        resolve();
      }, this.message.config.easeTime);
    });
  }

  /**
   * Manual close toastr
   */
  manualClose(): Promise<void> {
    // message close triggered by the user
    return this.close().then(() => {
      this.toastrService.next();
    });
  }

  pauseTimeout(pause: boolean): void {
    clearTimeout(this.messageTimeout);
    this.messageTimeoutActive = false;
    this.messageTimeoutPaused = pause;
  }

  /**
   * Stop toastr timeout
   */
  stopTimeout(): void {
    clearTimeout(this.messageTimeout);
    this.messageTimeoutActive = false;
  }

  /**
   * Start toastr timeout
   */
  startTimeout(pause?: boolean): void {
    if (pause !== undefined) {
      this.messageTimeoutPaused = pause;
    }

    if (this.messageClosing || this.messageTimeoutPaused) {
      // do nothing if the current message is closing or paused
      return;
    }

    if (this.message.config.autoDismiss) {
      // set timeout for dismissal of message
      this.messageTimeout = setTimeout(() => {
        this.close().then(() => {
          this.toastrService.next();
        });
      }, this.message.config.timeOut);

      this.messageTimeoutActive = true;
    }
  }

  /**
   * Displaying message on toastr
   * @param message
   */
  displayMessage(message: FeedbackToastrMessage): void {
    this.message = message;

    if (message?.config) {
      // only mark new message as visible if it has config
      this.message.visible = true;

      // needs to be wrapped in a timeout otherwise the animations stumble up
      setTimeout(() => {
        this.startTimeout();
      });
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.toastrService.message$.subscribe((message: FeedbackToastrMessage) => {
      if (message?.type.indexOf('feedback') < 0) {
        return;
      }

      if (this.message) {
        // if there's already a message, close then display new one
        this.close().then(() => {
          this.displayMessage(message);
        });
        return;
      }

      this.displayMessage(message);
    });
  }
}
