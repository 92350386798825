import { Injectable } from '@angular/core';
import { UserService } from 'Shared/services/user.service';
import { BackendService } from 'Shared/services/backend.service';
import { Country } from 'Shared/classes/country';
import { Product } from 'Shared/classes/product';
import { Discount } from 'Shared/classes/discount';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DiscountModelService {
  cache: any = {};

  constructor(private userService: UserService, private backend: BackendService) {}

  /**
   * From payload
   * @param res
   */
  static fromPayload(res: any): Discount {
    if (!res.data || !res.data.attributes) {
      return null;
    }
    const attr = res.data.attributes;
    const amount = (attr.amount || 0) * 100; // Comes back as a float....
    return new Discount(attr.code, attr.percentage, amount, attr.delivery_number_range, attr.campaign_description);
  }

  clearCache(): void {
    this.cache = {};
  }

  // TODO: this file could be changed to use CampaignCodesModel, as it's the same endpoint

  /**
   * Validate a code
   * @param code
   * @param country
   * @param product
   */
  check(discount: Discount, country: Country, product?: Product, clearCache?: boolean, params?: Params): Promise<Discount> {
    const user = this.userService.getUser();

    const email = user.email ? user.email.address : undefined;
    const skuId = product ? product.id : undefined;
    const collectionId = product ? product.collectionId : undefined;
    const countryId = country.id;

    // TODO: Tidy up the caching layer from discount model to backend model here. See card
    // https://www.pivotaltracker.com/story/show/170750634
    if (clearCache) {
      return this.backend
        .get(user, `/v2/codes/${discount.code}`, {
          params: {
            email,
            sku_id: skuId,
            collection_id: collectionId,
            country_id: countryId,
            deeplink: params ? params['shortlink'] : undefined,
            user_slug: params ? params['user_slug'] : undefined
          }
        })
        .then((res): Discount => DiscountModelService.fromPayload(res));
    }

    const cacheKey = [`e-${email}`, `s-${skuId}`, `c-${collectionId}`, `co-${countryId}`].join('-');

    this.cache[cacheKey] =
      this.cache[cacheKey] ||
      this.backend
        .get(user, `/v2/codes/${discount.code}`, {
          params: {
            email,
            sku_id: skuId,
            collection_id: collectionId,
            country_id: countryId,
            deeplink: params ? params['shortlink'] : undefined,
            user_slug: params ? params['user_slug'] : undefined
          }
        })
        .then((res): Discount => DiscountModelService.fromPayload(res));

    return this.cache[cacheKey];
  }
}
