import { Directive, Input, OnInit } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { HotjarService } from 'Shared/services/third-parties/hotjar.service';
import { ExperimentsService } from 'Shared/services/experiments.service';

@Directive({
  selector: '[bwExperimentEvent]'
})
export class ExperimentEventDirective implements OnInit {
  @Input('bwExperimentEvent')
  bwExperimentEvent: string;

  constructor(
    private windowRef: WindowRefService,
    private hotjarService: HotjarService,
    private experimentService: ExperimentsService
  ) {}

  ngOnInit(): void {
    // TODO - Remove dataLayer fallback when GTM added
    this.windowRef.nativeWindow.dataLayer = this.windowRef.nativeWindow.dataLayer || [];
    this.hotjarService.tagRecording(this.bwExperimentEvent);
    this.experimentService.fireEvent(this.bwExperimentEvent);
  }
}
