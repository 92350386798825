
<div class="review-summary">
  <div class="review-summary__stars">
    <bw-review-stars [rating]="rating?.averageRating" [roundingScale]="0.25"></bw-review-stars><span class="avg">{{rating?.averageRating}}</span><small>average</small>
  </div>
  <div class="review-summary__count"><small>{{rating?.count}} reviews on</small><img src="/assets/images/reviews-logo.png" alt="Reviews"></div>
  <!-- Schema - for SEO--><span class="rating-desc" style="display:none" itemscope itemtype="http://schema.org/Product"><span itemprop="name">Bloom &amp; Wild</span><span itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating"> - Rated <span itemprop="ratingValue">{{rating?.averageRating}}</span>/5 based on <span itemprop="reviewCount">{{rating?.count}}</span> Recent Reviews</span>
    <div class="row reviews">
      <div class="col-sm-4 review" *ngFor="let review of reviews" itemprop="review" itemscope itemtype="http://schema.org/Review"><span itemprop="author">{{review.reviewer}}</span><span itemprop="reviewBody">“{{review.review}}”</span><span itemprop="dateCreated">{{review.date | date:'yyyy-MM-ddTHH:mm:ssZ'}}</span><span itemprop="reviewRating">{{review.rating}} / 5 Stars</span></div>
    </div></span>
</div>