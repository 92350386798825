
<div class="blogs-carousel" *ngIf="blogs">
  <div class="blogs-carousel--heading">
    <h2>Floral stories</h2>
  </div>
  <bw-carousel [spaceBetweenItems]="true" [paginationButtons]="true" [itemsPerPageDesktop]="4" [itemsPerPageTablet]="3" [itemsPerPageMobile]="2">
    <div class="blogs-carousel-item bw-carousel__item" *ngFor="let blog of blogs; let i = index" [attr.index]="i">
      <div class="blogs-carousel__content">
        <div class="blogs-carousel__content--image"><a href="{{blog.slug}}"><img [imageRole]="i % 2 !== 0 ? 'expressive-list-even' : 'expressive-list-odd'" [bwEcommImage]="blog.imageUrl"></a></div>
        <div class="blogs-carousel__content--tags">
          <div *ngFor="let tag of blog?.tags"><a href="blog/tagged/undefined">{{tag | blogTags}}</a></div>
        </div>
        <div class="blogs-carousel__content--title"><a href="{{blog.slug}}">{{blog.title}}</a></div>
        <div class="blogs-carousel__content--date"><span>{{blog.publishedDate.split('T')[0]}}</span></div>
      </div>
    </div>
  </bw-carousel>
</div>