import { Directive, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';

export type BasicBrowserEvent = {
  type: 'touchend' | 'mousedown' | 'keyup';
  keyCode: number;
  key: string;
  target: HTMLElement;
};

@Directive({
  selector: '[bwClickOutside]'
})
export class ClickOutsideDirective implements OnChanges {
  @Input('bwClickOutside') shouldApply: boolean = true;
  @Input('bwClickOutsideByClass') byClass: boolean = false;
  @Output() clickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef, private windowRef: WindowRefService) { }

  ngOnChanges(): void {
    if (this.shouldApply) {
      this.addBrowserEventListeners();
      return;
    }

    this.removeBrowserEventListeners();
  }

  /**
   * Attach listeners
   */
  addBrowserEventListeners(): void {
    this.windowRef.nativeWindow.addEventListener('mouseup', this as unknown as any, false);
    this.windowRef.nativeWindow.addEventListener('touchend', this as unknown as any, false);
    this.windowRef.nativeWindow.addEventListener('keyup', this as unknown as any);
  }

  /**
   * Remove listeners
   */
  removeBrowserEventListeners(): void {
    this.windowRef.nativeWindow.removeEventListener('mouseup', this as unknown as any);
    this.windowRef.nativeWindow.removeEventListener('touchend', this as unknown as any);
    this.windowRef.nativeWindow.removeEventListener('keyup', this as unknown as any);
  }

  handleEvent(event: BasicBrowserEvent) {
    const isClickEvent = ['mouseup', 'touchend'].indexOf(event.type) > -1;
    const isEscapeKey = event.type === 'keyup' && (event.keyCode === 27 || event.key === 'Escape');
    const isTabKey = event.type === 'keyup' && (event.keyCode === 9 || event.key === 'Tab');

    if (isEscapeKey) {
      this.clickOutside.emit();
    } else if (isTabKey || isClickEvent) {
      let isOutside = false;
      if (this.byClass && event.target?.className) {
        isOutside = !this.elementRef.nativeElement.getElementsByClassName(event.target.className)
      } else {
        isOutside = !this.elementRef.nativeElement.contains(event.target);
      }

      if (isOutside) {
        this.clickOutside.emit();
      }
    }
  }
}
