import {
  Component,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SimpleChanges
} from '@angular/core';
import { BwButton, BwButtonState, BwButtonSize, BwButtonStyle, BwButtonType, BwButtonBoolean } from 'Shared/components/button/button';
import { StateName, StateService } from 'Project/shared/services/state.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-button',
  templateUrl: './button.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnInit, OnChanges {
  button: BwButton;

  @Input() href: string;
  @Input() uiSref: StateName | string;
  @Input() uiParams: any;
  @Input() size: BwButtonSize = 'medium';
  @Input() buttonStyle: BwButtonStyle = 'primary';
  @Input() fullWidth: BwButtonBoolean = false;
  @Input() buttonType: BwButtonType = 'button';
  @Input() state: BwButtonState;
  @Input() description: string;
  @Input() target: string;
  @Input() iconOnly: BwButtonBoolean = false;
  @Output() clicked = new EventEmitter<any>();

  constructor(
    private changes: ChangeDetectorRef,
    private stateService: StateService
  ) {}

  /**
   * Emit button is successful clicked
   */
  onClick(): void {
    this.clicked.emit(true);
  }

  /**
   * Create button with given attributes
   */
  createButton(): BwButton {
    // Updates href if state inputs are used, keeps simples ngIf
    if (this.uiSref || this.uiParams) {
      this.href = this.stateService.href(this.uiSref, this.uiParams || {}) ?? undefined;
    }

    return {
      clicked: this.clicked,
      size: this.size,
      fullWidth: this.fullWidth,
      style: this.buttonStyle,
      state: this.state,
      href: this.href,
      type: this.buttonType,
      displayClasses: [
        `bw-button--size-${this.size}`,
        `bw-button--${this.fullWidth === true || this.fullWidth === 'true' ? 'full-width' : 'auto'}`,
        `bw-button--style-${this.buttonStyle}`,
        `bw-button--${this.state}`,
        this.iconOnly === true || this.iconOnly === 'true' ? 'bw-button--icon-only' : undefined
      ]
        .filter((d): boolean => !!d)
        .join(' ')
    } as BwButton;
  }

  /**
   * On changes
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.state || changes.buttonStyle) {
      this.button = this.createButton();
      this.changes.markForCheck();
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.button = this.createButton();
  }
}
