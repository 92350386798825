import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ProductCardType } from 'Project/shared/classes/grid-product';
import {
  IModularProductCard,
  IModularProductCardCta
} from 'Project/shared/interfaces/modular-product-card-content';
import { AnalyticsService } from 'Project/shared/services/analytics.service';
import { ModularProductCardService } from 'Project/shared/services/modular-product-card.service';
import { StateService } from 'Project/shared/services/state.service';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';

@Component({
  selector: 'bw-modular-content-product-card',
  templateUrl: './modular-content-product-card.component.html',
  styleUrls: ['./modular-content-product-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ModularContentProductCardComponent implements OnInit {
  @Input() campaignname: string;
  @Input() iscontentful: boolean = false;
  @Input() selectedProductCardType: ProductCardType = ProductCardType.standard;
  productCardType = ProductCardType;
  campaign: IModularProductCard;

  constructor(
    private modularProductService: ModularProductCardService,
    private analyticsService: AnalyticsService,
    private stateService: StateService,
    private optimizelyService: OptimizelyService
  ) {}

  ngOnInit(): void {
    if (!this.iscontentful && this.campaignname) {
      this.campaign = this.modularProductService.modularProductCards.find(
        (item) => item.name === this.campaignname
      );
    }

    if (this.iscontentful && this.campaignname) {
      const contentByShippingCountry =
        this.modularProductService.filterContentFromShippingCountryId();
      const content = this.modularProductService.mapModularContentCardData(
        contentByShippingCountry || []
      );
      this.campaign = content.find((item) => item.name === this.campaignname);
    }
  }

  /**
   * Tracking & change state
   * @param card
   */
  navigateAndTrack(card: IModularProductCard, cta: IModularProductCardCta): void {
    // Track Click
    this.optimizelyService.trackEvent('click_content_card');
    this.analyticsService.trackInHeap('ModularContentBlockClick', {
      modularProductCardIndex: card.activePlacement.gridPosition,
      modularProductCardHasCta: card.content.ctas.length,
      primaryCta: card.content.ctas[0] ? card.content.ctas[0].link : undefined,
      secondaryCta: card.content.ctas[1] ? card.content.ctas[1].link : undefined,
      modularProductCardCUrclickon: cta.link,
      modularProductCardMessage:
        card.content.copy && card.content.heading
          ? `${card.content.heading}-${card.content.copy}`
          : undefined,
      modularProductCardId: card.id,
      modularProductCardName: card.name,
      modularProductCardSource: this.stateService.getCurrent().url,
      modularProductCardCtaCopy: cta.copy
    });
    this.analyticsService.track('grid.modular-product-card-cta', {
      value: `${card.name}-${card.activePlacement.gridPosition}`
    });
  }
}
