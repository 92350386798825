import { t } from 'Shared/utils/translations';

/**
 * ContentChildren aren't picked up by angular from Contentful html :''''''''(
 */
export default function instagramFeedInterceptor(item): any {
  const children = [].slice.call(item.children);

  children.forEach(child => {
    if (child.nodeName.toLowerCase() === 'img') {
      const image = document.createElement('div');
      image.setAttribute('class', 'instagram-feed__image');
      image.innerHTML = child.outerHTML;
      item.appendChild(image);
      child.parentNode.removeChild(child);
      return;
    }

    if (child.nodeName.toLowerCase() === 'div') {
      const followButton = document.createElement('a');
      followButton.setAttribute('href', t('js.social.instagram.url'));
      followButton.setAttribute('target', '_blank');
      followButton.setAttribute('class', 'instagram-feed__follow-button');
      followButton.innerHTML = child.outerHTML;
      item.appendChild(followButton);
      child.parentNode.removeChild(child);
    }
  });

  return item;
}
