import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ViewChild,
  ElementRef
} from '@angular/core';
import { GridProduct } from 'Shared/classes/grid-product';
import { Product } from 'Shared/classes/product';
import { AnalyticsService } from 'Shared/services/analytics.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-product-option-list',
  templateUrl: './product-option-list.component.html'
})
export class ProductOptionListComponent implements OnInit, OnChanges {
  @Input() products: GridProduct[] | Product[];
  @Input() index: number;
  @Input() hidePaginationMobile: boolean;
  @Input() selectedProduct: Product;
  @Input() showPrice: boolean;

  @Output() productSelected: EventEmitter<{
    product: GridProduct | Product;
    index: number;
  }> = new EventEmitter<{ product: GridProduct; index: number }>();

  @ViewChild('optionList') element: ElementRef;

  currentSlideIndex: number;
  loading: boolean = false;

  constructor(private analyticsService: AnalyticsService) {}

  /**
   * on product selection
   * @param product
   * @param index
   */
  onProductSelect(product: GridProduct | Product, index = 0): void {
    this.currentSlideIndex = index;
    this.productSelected.emit({ product, index });
  }

  onInit(): void {
    this.loading = true;
    // forces digest
    setTimeout(() => {
      // Find the best product to select
      if (this.index >= 0 && this.products[this.index]) {
        this.onProductSelect(this.products[this.index], this.index);
      } else if (this.selectedProduct) {
        const foundIndex = this.products.findIndex((p) => p.id === this.selectedProduct.id);
        if (foundIndex > -1) {
          this.onProductSelect(this.products[foundIndex], foundIndex);
        }
      }
      this.loading = false;
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.products) {
      this.onInit();

      // for each product in the modal we fire an impression
      this.products.forEach((prod) => {
        this.analyticsService.trackSubscriptionModalProductImpression(prod);
      });
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.onInit();
  }
}
