import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { ConfigService } from 'Shared/services/config.service';
import { WindowRefService } from 'Shared/services/window.service';
import { User } from 'Shared/classes/user';
import { Purchase } from 'Shared/classes/purchase';
import { FeaturesService } from 'Shared/services/features.service';

/**
 * Docs: https://support.appsflyer.com/hc/en-us/articles/360001610038-PBA-web-SDK-integration-guide
 * Help: https://support.appsflyer.com/hc/en-us/articles/360002075858-Troubleshooting-the-web-SDK
 * How to test: https://support.appsflyer.com/hc/en-us/articles/360012729178-Test-PBA-web-SDK-integration
 */
@Injectable({
  providedIn: 'root'
})
export class AppsFlyerService {
  bannerKey: string = '';
  pbaKey: string = '';
  debug: boolean = false;
  window: any;
  initPromise: Promise<any>;
  serviceInitialized: boolean = false;

  constructor(
    private windowRef: WindowRefService,
    private domUtils: DomUtilsService,
    private configService: ConfigService
  ) {
    this.bannerKey = this.configService.getConfig().appsFlyerBannerKey;
    // PBA is " People-Based Attribution (PBA)" or they call it the "WEB DEV key"
    this.pbaKey = this.configService.getConfig().appsFlyerPBAKey;
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<appsflyer>', ...args);
    }
  }

  /**
   * Run the appsflyer code
   * @param args
   */
  appsflyer(...args): void {
    // Check to make sure service was intialized and in the window object before tracking
    if (this.window['AF'] && this.serviceInitialized) {
      this.window['AF'](...args);
      this.log(...args);
    }
  }

  /**
   * Identify the user
   * @param user
   */
  identify(user: User): void {
    if (user && user.slug) {
      this.appsflyer('pba', 'setCustomerUserId', user.slug);
    }
  }

  /**
   * Track the purchase in appsflyer
   * @param purchase
   */
  trackPurchase(purchase: Purchase): void {
    this.appsflyer('pba', 'event', {
      eventType: 'EVENT',
      eventName: 'af_purchase',
      eventRevenue: (purchase.price.price / 100).toFixed(2),
      eventRevenueCurrency: purchase.price.currency.toUpperCase(),
      eventPrice: (purchase.price.price / 100).toFixed(2),
      eventQuantity: purchase.orders.length,
      eventOrderId: purchase.id,
      eventReceiptId: purchase.id
    });
  }

  /**
   * Init
   */
  init(): Promise<any> {
    // Taken from the appsflyers install SDK
    this.window.AppsFlyerSdkObject = 'AF';
    this.window.AF =
      this.window.AF ||
      function (): void {
        (this.window.AF.q = this.window.AF.q || []).push(
          [Date.now()].concat(Array.prototype.slice.call(arguments))
        );
      };
    this.window.AF.id = this.window.AF.id || {
      pba: { webAppId: this.pbaKey, measurementStatus: true },
      banners: {
        key: this.bannerKey
      }
    };

    this.window.AF.plugins = {};

    this.initPromise =
      this.initPromise ||
      this.domUtils
        .loadScript(`https://websdk.appsflyer.com/?st=banners,pba`, 'appsflyer')
        .then(() => {
          this.serviceInitialized = true;

          // Uncomment the below to help with any debugging via appsflyer
          // window['AF_SDK'].PLUGINS.PBA.turnOnDevMode();

          const bannerEnabled = this.configService.getConfig().appsFlyerBannerEnabled;
          const args = bannerEnabled ? ['banners', 'showBanner'] : ['banners'];
          this.appsflyer(...args);
        });

    return this.initPromise;
  }
}
