import * as dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import { BackendService, APISerialisedJSONResponse } from 'Shared/services/backend.service';
import { Address } from 'Shared/classes/address';
import { DeliveryDate } from 'Shared/classes/delivery-date';
import { ShippingOption } from 'Shared/classes/shipping-option';
import { Product } from 'Shared/classes/product';
import { Price } from 'Shared/classes/price';
import { Delivery } from 'Shared/classes/delivery';

@Injectable({
  providedIn: 'root'
})
export class DeliveryDateModelService {
  constructor(private backend: BackendService) {}

  /**
   * From the 'Option' payload
   * @param o
   * @param product
   */
  fromOptionPayload(
    o: APISerialisedJSONResponse<'/v2/availability/delivery_dates'>[number]['delivery_date_options'][number],
    product: Product
  ): ShippingOption {
    const option = new ShippingOption();
    option.id = parseInt(o.shipping_option_id, 10);
    option.price = new Price(o.currency, 1, o.price);
    option.name = o.name;
    option.message = o.message;
    option.hasSlots = o.has_slots;
    option.setCutOff(o.cutoff);
    option.product = product; // So we know which option relates to which product
    option.maxNoteLength = parseInt(o.note_length, 10);
    option.successRateMessage = o.delivery_pill;

    option.hasPhoneNumber = o.show_phone_number_field;
    option.hasPhoneNumberRequired = o.phone_number_required;
    option.hasGiftCard = o.show_gift_card_fields;
    option.daysForDelivery = o.days_for_delivery;

    // The subsequentDeliveries is the 'original' shippingOption
    option.subsequentDeliveries = o.subsequent_deliveries_option
      ? this.fromOptionPayload(o.subsequent_deliveries_option, product)
      : undefined;
    return option;
  }

  /**
   * From the whole payload
   */
  fromPayload(res: APISerialisedJSONResponse<'/v2/availability/delivery_dates'>[number], product: Product): DeliveryDate {
    const day = new DeliveryDate();
    day.setDate(res.id);
    day.shippingOptions = res.delivery_date_options.map((o): ShippingOption => this.fromOptionPayload(o, product));
    day.isDeliverable = res.date_is_deliverable;
    day.hasStock = res.has_stock;

    return day;
  }

  /**
   * Get the dates
   * TODO: Improve caching, actually store the dates as objects and return as necessary
   * @param address
   * @param product
   */
  getDates(address: Address, product: Product, start: dayjs.Dayjs, end: dayjs.Dayjs, delivery?: Delivery): Promise<DeliveryDate[]> {
    const postcode = address ? address.getPostcode() : undefined;
    const startDate = start.format('YYYY-MM-DD');
    const daysAhead = end.diff(start, 'day') + 1;

    return this.backend
      .get(null, '/v2/availability/delivery_dates', {
        responseIsJsonApi: true,
        sendExperiments: ['API_'],
        useUrlAsCache: true,
        params: {
          postcode: postcode || undefined,
          days: daysAhead,
          from_date: startDate,
          shipping_country_id: address.country.id,
          sku_id: product.id,
          shipping_option_id: delivery ? delivery.shippingOption.id : undefined
        }
      })
      .then((res): DeliveryDate[] =>
        (res ?? []).map((d): DeliveryDate => {
          const dDate = this.fromPayload(d, product);

          // Filter within deliverable from/to dates - Ideally the backend should to this too
          const withinWindow = product.deliverableFrom.unix() <= dDate.date.unix() && dDate.date.unix() <= product.deliverableTo.unix();

          dDate.shippingOptions = withinWindow ? dDate.shippingOptions : [];

          return dDate;
        })
      );
  }

  /**
   * Get the dates
   * TODO: Improve caching, actually store the dates as objects and return as necessary
   * @param address
   * @param product
   */
  getDatesByOrder(
    address: Address,
    product: Product,
    shippingOption: ShippingOption,
    start: dayjs.Dayjs,
    end: dayjs.Dayjs
  ): Promise<DeliveryDate[]> {
    const postcode = address ? address.getPostcode() : undefined;
    const startDate = start.format('YYYY-MM-DD');
    const daysAhead = end.diff(start, 'day') + 1;

    return this.backend
      .get(null, '/v2/availability/delivery_dates', {
        responseIsJsonApi: true,
        sendExperiments: ['API_'],
        useUrlAsCache: true,
        params: {
          postcode: postcode || undefined,
          days: daysAhead,
          from_date: startDate,
          shipping_country_id: address.country.id,
          sku_id: product.id,
          shipping_option_id: shippingOption ? shippingOption.id : undefined
        }
      })
      .then((res): DeliveryDate[] =>
        res.map((d): DeliveryDate => {
          const dDate = this.fromPayload(d, product);

          // Filter within deliverable from/to dates - Ideally the backend should to this too
          const withinWindow = product.deliverableFrom.unix() <= dDate.date.unix() && dDate.date.unix() <= product.deliverableTo.unix();

          dDate.shippingOptions = withinWindow ? dDate.shippingOptions : [];

          return dDate;
        })
      );
  }
}
