import { Pipe, PipeTransform } from '@angular/core';
import { Discount } from 'Shared/classes/discount';
import { t } from 'Shared/utils/translations';
import { PricePipe } from 'Shared/pipes/price.pipe';
import { Price, CurrencyCode } from 'Shared/classes/price';

@Pipe({
  name: 'discountMessage'
})
export class DiscountMessagePipe implements PipeTransform {
  constructor(private pricePipe: PricePipe) {}

  transform(discount: Discount, currency: CurrencyCode): any {
    let msg: string = '';
    if (discount.percentage) {
      msg = `-${discount.percentage}%`;
    } else if (discount.amountPennies) {
      const amountPrice = new Price(currency, 1, discount.amountPennies);
      msg = this.pricePipe.transform(amountPrice);
    }

    return t('js.components.order-form.messages.discount.applied_amount', msg);
  }
}
