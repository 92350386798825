import { Component, Input, Injector, ContentChildren, AfterViewInit } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { FormValidationMessageComponent } from 'Shared/components/form-validation-message/form-validation-message.component';

@Component({
  selector: 'bw-form-validation-messages',
  templateUrl: './form-validation-messages.component.html',
  styleUrls: ['./form-validation-messages.component.scss']
})
export class FormValidationMessagesComponent implements AfterViewInit {
  @ContentChildren(FormValidationMessageComponent)
  messages: any;

  @Input()
  controlName: string;
  formControl: AbstractControl;

  constructor(private injector: Injector) {}

  /**
   * Find the control by name
   * @param control
   * @param controlName
   */
  findControlWithName(control: any, controlName: string): AbstractControl {
    for (const key in control) {
      if (control.hasOwnProperty(key)) {
        const childComponent = control[key];
        if (childComponent instanceof FormGroup) {
          const foundControl = childComponent.get(controlName);
          if (foundControl) {
            return foundControl;
          }
        }
      }
    }
    return;
  }

  /**
   * Show messages for a specific form
   * @param control
   */
  showMessage(control: AbstractControl): void {
    // as this.messages uses ContentChildren - it might not be populated
    if (!this.messages) {
      return;
    }

    const errors = Object.assign({}, control.errors, { invalid: control.invalid });

    // Only show the first message that matches
    let messageShowing;
    this.messages.toArray().forEach(message => {
      message.show = !messageShowing && errors[message.for];
      messageShowing = messageShowing || message.show;
    });
  }

  ngAfterViewInit(): void {
    const parentComponents = this.injector['view'].component;
    this.formControl = this.findControlWithName(parentComponents, this.controlName);

    if (!this.formControl) {
      this.formControl = parentComponents[this.controlName];
    }

    if (!this.formControl) {
      // console.log('No control found for ', this.controlName);
      return;
    }

    this.showMessage(this.formControl);

    this.formControl.statusChanges.subscribe(() => {
      this.showMessage(this.formControl);
    });
  }
}
