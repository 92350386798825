import { Component, Input, OnInit } from '@angular/core';
import { CountryService } from 'Shared/services/country.service';
import { t } from 'Shared/utils/translations';

@Component({
  selector: 'bw-app-store-link',
  templateUrl: './app-store-link.component.html'
})
export class AppStoreLinkComponent implements OnInit {
  @Input() context: string = '';
  @Input() appStoreLink: string = '';

  linkToAppStore: string;
  siteConfig: string;

  constructor(private countryService: CountryService) {}

  /**
   * On init
   */
  ngOnInit(): void {
    this.linkToAppStore = this.appStoreLink || t('js.generic.site.app_download.iOS', this.context);
    this.siteConfig = this.countryService.siteConfigValue('locale.backend').toLowerCase();
  }
}
