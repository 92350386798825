import { Component, ViewEncapsulation } from '@angular/core';
import { ModalResponse, ModalResponseSubject } from 'Shared/services/modal.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'bw-in-app-message',
  templateUrl: './in-app-message.component.html',
  styleUrls: ['./in-app-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InAppMessageComponent implements ModalResponse {
  modalResponse: Subject<ModalResponseSubject> = new Subject<ModalResponseSubject>();
  header: string;
  message: string;
  imageUrl: string;
  buttonText: string;
  backgroundColor: string;

  constructor() {}

  onSuccess(): void {
    this.modalResponse.next({ success: false });
  }

  onCancel(): void {
    this.modalResponse.next({ success: false });
  }
}
