import { Component, HostListener } from '@angular/core';
import { ZendeskWidgetService } from 'Shared/services/third-parties/zendesk-widget.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-zendesk-button',
  templateUrl: './zendesk-button.component.html'
})
export class ZendeskButtonComponent {
  loading: boolean;

  constructor(private zendeskWidgetService: ZendeskWidgetService) {}

  /**
   * Initalize the Zendesk chat widget on mouse over
   * @returns {Promise<unknown>}
   */
  @HostListener('mouseover')
  onMouseOver(): Promise<unknown> {
    return this.zendeskWidgetService.init();
  }

  /**
   * Initalize and open the Zendesk chat widget
   * @returns {Promise<boolean>}
   */
  onClick(): Promise<boolean | void> {
    setTimeout((): void => {
      this.loading = true;
    }, 1);
    return this.zendeskWidgetService
      .init()
      .then((): Promise<boolean> => {
        this.zendeskWidgetService.openChat();
        setTimeout((): void => {
          this.loading = false;
        }, 5000);
        return this.zendeskWidgetService.waitForLoad();
      })
      .catch((err: Error): void => {
        console.error(err);
      });
  }
}
