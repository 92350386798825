import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LazyLoaderService } from 'Project/shared/services/lazy-loader.service';

export const availableIcons = {
  'chevron-left': '/assets/brand-icons/back_chevron_black.svg',
  'chevron-right': '/assets/branded-icons/chevron__right.svg',
  'chevron-down': '/assets/brand-icons/chevron-down.svg',
  'chevron-up': '/assets/brand-icons/chevron-up.svg',
  tick__success: '/assets/branded-icons/tick__success.svg',
  tick__selected: '/assets/branded-icons/tick__selected.svg',
  'red-alert': '/assets/branded-icons/exclamation__danger.svg',
  'tick--green': '/assets/brand-icons/tick--green.svg',
  fr: '/assets/branded-icons/flag__fr.svg',
  gb: '/assets/branded-icons/flag__gb.svg',
  de: '/assets/branded-icons/flag__de.svg',
  at: '/assets/branded-icons/flag__at.svg',
  ie: '/assets/branded-icons/flag__ie.svg',
  dk: '/assets/branded-icons/flag__dk.svg',
  nl: '/assets/branded-icons/flag__nl.svg',
  be: '/assets/branded-icons/flag__be.svg',
  'small-x': '/assets/branded-icons/x.svg',

  burger: '/assets/branded-icons/burger.svg',
  user: '/assets/branded-icons/user.svg',
  user__active: '/assets/branded-icons/user__active.svg',
  basket: '/assets/branded-icons/basket.svg',
  basket__active: '/assets/branded-icons/basket__active.svg',
  heart: '/assets/branded-icons/heart.svg',
  heart__active: '/assets/branded-icons/heart__active.svg',
  arrow__left: '/assets/branded-icons/arrow__left.svg',
  arrow__right: '/assets/branded-icons/arrow__right.svg',
  zoom: '/assets/branded-icons/zoom.svg',
  'review-star': '/assets/branded-icons/review-star.svg',
  padlock: '/assets/branded-icons/padlock.svg',
  locked: '/assets/branded-icons/locked.svg',
  upload: '/assets/brand-icons/upload.svg',
  upload__rotated: '/assets/brand-icons/upload-rotated.svg',
  'star-empty': '/assets/images/feedback/star-empty.svg',
  'star-fill': '/assets/images/feedback/star-fill.svg',
  calendar__outline: '/assets/brand-icons/calendar-outline.svg',
  edit__action: '/assets/branded-icons/edit-orders.svg',
  lashes: '/assets/images/opt-in/lashes.svg',
  discount: '/assets/branded-icons/discount.svg',
  play: '/assets/branded-icons/play.svg',
  stop: '/assets/branded-icons/stop.svg',

  // Social Icons
  instagram: '/assets/icons/social-icon-instagram-opt.svg',
  facebook: '/assets/icons/social-icon-facebook-opt.svg',
  twitter: '/assets/icons/social-icon-twitter-opt.svg',

  // Download apps
  'appStore-en': '/assets/brand-icons/download-on-app-store.en.svg',
  'appStore-da': '/assets/brand-icons/download-on-app-store.da.svg',
  'appStore-de': '/assets/brand-icons/download-on-app-store.de.svg',
  'appStore-nl': '/assets/brand-icons/download-on-app-store.nl.svg',
  'appStore-de-at': '/assets/brand-icons/download-on-app-store.de-at.svg',
  'appStore-nl-be': '/assets/brand-icons/download-on-app-store.nl-be.svg',
  'googlePlay-en': '/assets/brand-icons/download-on-google-play.en.svg',
  'googlePlay-de': '/assets/brand-icons/download-on-google-play.de.svg',
  'googlePlay-de-at': '/assets/brand-icons/download-on-google-play.de-at.svg',

  // Content spot
  'happiness-guaranteed-repeated': '/assets/brand-icons/happiness-guarranteed-repeating.svg',
  'happiness-guaranteed-mobile': '/assets/brand-icons/happiness-guaranteed-mobile.svg',

  // Trustpilot
  trustpilotReviewsDesktop: '/assets/images/trustpilot-reviews--desktop.svg',
  trustpilotReviews: '/assets/images/trustpilot-reviews.svg',
  trustedShops: '/assets/images/trusted-shops.svg',

  // Payment Icons
  mastercard: '/assets/payment-icons/mastercard-logo.svg',
  visa: '/assets/payment-icons/visa-logo.svg',
  'american express': '/assets/payment-icons/amex-logo.svg',
  americanexpress: '/assets/payment-icons/amex-logo.svg',
  amex: '/assets/payment-icons/amex-logo.svg',
  'apple-pay': '/assets/payment-icons/applepay-logo.svg',
  paypal: '/assets/payment-icons/paypal-logo.svg',
  'google-pay': '/assets/payment-icons/googlepay-logo.svg',
  klarna: '/assets/payment-icons/klarna-logo.svg',
  ideal: '/assets/payment-icons/ideal-logo.svg',
  sofort: '/assets/payment-icons/sofort-icon.svg',
  bancontact: '/assets/payment-icons/bancontact-logo.svg',
  sepa: '/assets/payment-icons/sepa.svg',
  credit_card: '/assets/brand-icons/credit-card.svg',
  klarnaDark: '/assets/payment-icons/Klarna-logo-black.svg',

  // Alerts
  alertInCircle: '/assets/brand-icons/alert-in-circle.svg',
  success: '/assets/brand-icons/tick-green.svg',
  info: '/assets/brand-icons/alert-in-circle.svg',
  warning: '/assets/brand-icons/alert-in-circle.svg',
  successInfo: '/assets/brand-icons/alert-in-circle.svg',
  list: '/assets/brand-icons/tick-green.svg',

  // Grid View Toggle Icons
  columnsInactive: '/assets/brand-icons/ico_grid-view_default.svg',
  columnsActive: '/assets/brand-icons/ico_grid-view_active.svg',
  stackInactive: '/assets/brand-icons/ico_stack-view_default.svg',
  stackActive: '/assets/brand-icons/ico_stack-view_active.svg',

  // Actionable list
  edit_btn: '/assets/branded-icons/edit-btn.svg',
  delete_btn: '/assets/branded-icons/bin-btn.svg',
  // description components (dibdabs)
  // leaving these commented as they don't work due to input being dynamic, need to find fix
  // so we can use bw-icon globally

  'description-through-door': '/assets/branded-icons/description-through-door.svg',
  'description-through-address': '/assets/branded-icons/description-through-address.svg',
  // 'description-subscriptions': '/assets/branded-icons/description-subscriptions',
  'description-polaroid': '/assets/branded-icons/description-polaroid.svg',
  'description-letterbox': '/assets/branded-icons/description-letterbox.svg',
  'description-hands-flowers': '/assets/branded-icons/description-hands-flowers.svg',
  'description-hand-tied': '/assets/branded-icons/description-hand-tied.svg',
  'description-flowers-3-stems': '/assets/branded-icons/description-flowers-3-stems.svg',
  'description-delivery-van': '/assets/branded-icons/description-delivery-van.svg',
  'description-care-instructions': '/assets/branded-icons/description-care-instructions.svg',
  'delivery-illustrations': '/assets/branded-icons/delivery-illustrations.svg',
  'description-reward-points': '/assets/branded-icons/description-reward-points.svg',
  // 'description-calendar': '/assets/branded-icons/description-calendar',
  // 'description-bud-to-bloom': '/assets/branded-icons/description-bud-to-bloom',
  // 'description-bouquet': '/assets/branded-icons/description-bouquet'

  // subscription features icons
  'subscription-features-frequency': '/assets/brand-icons/subscription-features_frequency.svg',
  'subscription-features-pause': '/assets/brand-icons/subscription-features_pause.svg',
  'subscription-features-manage': '/assets/brand-icons/subscription-features_manage.svg'
};

export type IconVariation = undefined | 'border' | 'border-rounded' | 'rounded';
export type AvailableIcon = keyof typeof availableIcons;

/* bw:view-encapsulation */
@Component({
  selector: 'bw-icon',
  templateUrl: './icon.component.html'
})
export class IconComponent implements AfterViewInit, OnChanges {
  @Input() iconType: AvailableIcon;
  @Input() variation: IconVariation;
  @Input() altText: string;
  @Input() fetchPriority: 'high' | 'medium' | 'low' = 'low';

  availableIcons = availableIcons;
  elem: any;

  constructor(
    el: ElementRef,
    private lazyLoadService: LazyLoaderService
  ) {
    this.elem = el.nativeElement;
  }

  ngAfterViewInit(): void {
    this.lazyLoadService.addToQueue(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.iconType && !changes.iconType.firstChange) {
      this.load();
    }
  }

  /**
   * Loads the icon into the lazy load service
   */
  load(): void {
    const element = this.elem.querySelector('.icon--icon');

    if (element) {
      element.setAttribute('src', availableIcons[this.iconType]);
    }
  }

  ngOnDestroy(): void {
    this.lazyLoadService.unqueue(this);
  }
}
