import { Pipe, PipeTransform } from '@angular/core';
import { Product } from 'Shared/classes/product';

@Pipe({
  name: 'subscriptionSaving'
})
export class SubscriptionSavingPipe implements PipeTransform {
  transform(
    product: Product,
    prefix: string,
    from: number,
    to: number,
    threshold: number = 0
  ): any {
    if (!product) {
      return '';
    }

    try {
      const price1 =
        to < 0 ? product.getSubscriptionPrice().price : product.getPrice(to).price / to;
      const price2 =
        from < 0 ? product.getSubscriptionPrice().price : product.getPrice(from).price / from;

      if (!price1 || !price2) {
        return '';
      }

      const different = ((price2 - price1) / price2) * 100;

      if (different < threshold) {
        return '';
      }

      const prefixStr = prefix ? `${prefix} ` : '';
      return different > 0 ? `${prefixStr}${different.toFixed(0)}%` : '';
    } catch {
      return '';
    }
  }
}
