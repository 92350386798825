import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ConfigModelService } from 'Shared/models/config-model.service';

@Injectable()
export class HasRemoteConfigResolver implements Resolve<any> {
  constructor(private configModelService: ConfigModelService) {}

  resolve(): Promise<any> {
    return this.configModelService.hasRemoteConfig();
  }
}
