import { Component, AfterViewInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { LocationService } from 'Shared/services/location.service';
import { ModalService } from 'Shared/services/modal.service';
import { AuthModalComponent } from 'Shared/components/modals/auth-modal/auth-modal.component';
import { ContentModalComponent } from 'Content/components/content-modal/content-modal.component';
import { t } from 'Shared/utils/translations';
import { WindowRefService } from 'Shared/services/window.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { StateService } from 'Shared/services/state.service';
import { Delivery } from 'Shared/classes/delivery';

@Component({
  selector: 'bw-launch-modal',
  template: ''
})
export class LaunchModalComponent implements AfterViewInit, OnDestroy {
  @Input()
  modalToLaunch: string;
  @Input()
  heading: string;
  @Input()
  contentKey: string;
  @Input()
  timeout: number;
  @Input()
  waitFor: number;
  @Input()
  restartTimerOnActivity: boolean;
  @Output()
  hasLaunched = new EventEmitter<any>();

  // Custom inputs for different modals
  @Input() delivery: Delivery;

  onActivityCallback: Function;
  onActivityTimer: any;

  constructor(
    private locationService: LocationService,
    private modalService: ModalService,
    private windowRef: WindowRefService,
    public experimentService: ExperimentsService,
    private analyticsService: AnalyticsService,
    private stateService: StateService
  ) {}

  timeoutAsPromise(ms: number): Promise<unknown> {
    if (!ms) {
      return Promise.resolve(true);
    }

    return new Promise((resolve): void => {
      // Reference to a function which we can then use later
      const restartTimer = (): void => {
        clearTimeout(this.onActivityTimer);
        this.onActivityTimer = setTimeout((): void => {
          // mmModal is metion me modal
          const modalStatus = !this.windowRef.nativeWindow.document.querySelectorAll('.modal-open, [id^="mmModal"]').length;

          if (modalStatus) {
            resolve(true);
          }
        }, ms);
      };

      this.onActivityCallback = restartTimer;

      if (this.restartTimerOnActivity) {
        this.windowRef.nativeWindow.document.addEventListener('click', this);
      }

      // Then finally, call the function
      restartTimer();
    });
  }

  /**
   * Launch the required modal
   */
  launchRequiredModal(): Promise<any> {
    const modalToLaunch = this.modalToLaunch || this.stateService.getCurrent().params.modalToLaunch;

    if (modalToLaunch === 'authModal') {
      return this.modalService
        .show(AuthModalComponent, {
          initialState: { origin: 'modal', fullOrigin: 'webAccountLaunchModal' },
          trackingKey: 'auth-modal-url-login'
        })
        .catch((): void => {});
    }

    if (modalToLaunch === 'authRegister') {
      return this.modalService
        .show(AuthModalComponent, {
          initialState: {
            selectedTab: 'register',
            origin: 'modal',
            fullOrigin: 'webAccountLaunchModal'
          },
          trackingKey: 'auth-modal-url-register'
        })
        .catch((): void => {});
    }

    if (modalToLaunch === 'deliveryTracking' && this.delivery) {
      return this.modalService
        .showLazyModal(
          { name: 'DeliveryTrackingModalComponent' },
          {
            class: 'deliveryTracking left autoWidth fullHeight',
            animationDirection: 'left',
            initialState: {
              delivery: this.delivery
            }
          }
        )
        .catch((): void => {});
    }

    if (modalToLaunch === 'createAccount') {
      return this.modalService
        .showLazyModal(
          {
            name: 'ResetPasswordModalComponent'
          },
          {
            initialState: {
              modalTitle: t('js.components.launch-modal.createAccount.title'),
              token: this.stateService.getCurrent().params.resetToken
            },
            trackingKey: 'auth-modal-reset-password'
          }
        )
        .catch((): void => {});
    }

    if (modalToLaunch === 'resetPassword') {
      // Many users come through to this via the 'branch-redirector',for some (crazy) reason, the
      // resetToken param often is cleared, hence we also check for the token
      const token = this.stateService.getCurrent().params.resetToken ?? this.stateService.getCurrent().params.token;
      const email = this.stateService.getCurrent().params.email ?? null;
      return this.modalService
        .showLazyModal(
          {
            name: 'ResetPasswordModalComponent'
          },
          {
            initialState: {
              token,
              email,
              modalTitle: t('js.components.launch-modal.resetPassword.title')
            },
            trackingKey: 'auth-modal-reset-password'
          }
        )
        .catch((): void => {});
    }

    if (modalToLaunch === 'activateAccountMagicLink') {
      // Many users come through to this via the 'branch-redirector',for some (crazy) reason, the
      // resetToken param often is cleared, hence we also check for the token
      const email = this.stateService.getCurrent().params?.email;
      return this.modalService
        .showLazyModal(
          { name: 'MagicLinkModalComponent' },
          {
            initialState: {
              email
            },
            class: 'modal-sm'
          }
        )
        .catch((): void => {});
    }

    if (modalToLaunch === 'mentionMeShare') {
      return this.timeoutAsPromise(this.timeout)
        .then(
          (): Promise<any> =>
            this.modalService.show(ContentModalComponent, {
              initialState: {
                contentPath: 'content-spot/mention-me-share-iframe',
                asIframe: true,
                title: this.heading || ' '
              },
              class: 'modal-lg bw-modal--mention-me-share'
            })
        )
        .catch((): void => {}); // Nothing
    }

    if (modalToLaunch === 'fullClosureModal') {
      // Adding a little delay for smoothness
      return this.timeoutAsPromise(this.timeout).then((): Promise<any> => {
        this.hasLaunched.emit(true);
        return this.modalService
          .show(ContentModalComponent, {
            initialState: {
              contentPath: 'content-spot/covid-modal',
              asIframe: false,
              title: this.heading || ' '
            },
            class: 'modal-lg bw-modal--full-closure'
          })
          .catch((): void => {
            this.hasLaunched.emit(true);
          });
      });
    }

    return Promise.resolve();
  }

  handleEvent(): void {
    if (this.onActivityCallback) {
      this.onActivityCallback();
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.onActivityTimer);
    this.onActivityCallback = undefined;
  }

  ngAfterViewInit(): void {
    setTimeout((): void => {
      this.launchRequiredModal().catch((): void => {});
    }, 500);
  }
}
