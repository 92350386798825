import { Injectable } from '@angular/core';
import { UserService, User } from 'Shared/services/user.service';
import { LocalStorageService } from 'Shared/services/local-storage.service';
import { EmailPreference } from 'Shared/classes/email';
import { Subscription } from 'rxjs';
import { WindowRefService } from 'Shared/services/window.service';

@Injectable({
  providedIn: 'root'
})
export class NewsletterStatusService {
  storageKey: string = 'newsletterSignedUp';
  debug: boolean;
  public hasSignedUpToNewsletter: boolean;

  constructor(
    private userService: UserService,
    private localStorage: LocalStorageService,
    private window: WindowRefService
  ) {}

  private isGuestSignedUp(): boolean {
    const v = this.localStorage.getString(this.storageKey);
    return v ? JSON.parse(v) : false;
  }

  checkIfSignedUp(user: User): boolean {
    const isSignedUp = user.isLoggedIn()
      ? user.email.preference !== EmailPreference.OptedOut
      : this.isGuestSignedUp();
    this.recordPreference(isSignedUp);
    return this.hasSignedUpToNewsletter;
  }

  private listenForUserChange(): Subscription {
    return this.userService.user$.subscribe((user) => {
      this.checkIfSignedUp(user);
    });
  }

  recordOptOut(): void {
    this.recordPreference(false);
  }

  recordSignup(): void {
    this.recordPreference(true);
  }

  recordPreference(preference: boolean): void {
    this.hasSignedUpToNewsletter = this.isGuestSignedUp();
    this.localStorage.set(this.storageKey, preference.toString());
  }

  init(): void {
    this.listenForUserChange();

    this.debug = this.window.nativeWindow.location.search.indexOf('analyticsDebug=true') > -1;
    if (this.debug) {
      console.log(
        '<personalisation>',
        'hasSignedUpToNewsletter',
        this.hasSignedUpToNewsletter ? 'true' : 'false'
      );
    }
  }
}
