
<div class="bw-auth-email-form" *ngIf="form" id="bw-auth-email-form">
  <form [formGroup]="form" [class.ng-submitted]="isSubmitted">
    <ui-form-group group_title="Log in or create account" [group_title_hidden]="true">
      <ui-text-input formControlName="email" name="text" id="email" label="Your email" type="email" autocomplete="on" (didChange)="didEmailInputChanged()" [optional]="false">
        <ui-form-errors [errors]="form.get('email')?.errors" [error_messages]="{&quot;emailPattern&quot;:&quot;Please enter a valid email address (e.g johndoe&amp;#64;domain.com)&quot;,&quot;required&quot;:&quot;Please enter a valid email address (e.g johndoe&amp;#64;domain.com)&quot;}"></ui-form-errors>
      </ui-text-input>
    </ui-form-group>
  </form>
  <ui-box box_space="none" box_space_top="near">
    <ui-button button_type="primary" button_full_width="true" bwtrackas="checkout.userDetails.createAccount" (clicked)="submit()" (keydown.enter)="submit()">Continue</ui-button>
  </ui-box>
</div>
<bw-loading-spinner [fullscreen]="false" *ngIf="loading"></bw-loading-spinner>