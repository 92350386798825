import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callback'
})
export class CallbackPipe implements PipeTransform {
  transform(value: any, callback?: Function): string {
    if (typeof callback === 'function') {
      return callback(value);
    }
    return value;
  }
}
