
<ng-container *ngIf="activeBreadcrumbs.length &gt; 0">
  <div class="footer-breadcrumb">
    <div class="content-container">
      <div class="breadcrumbs" *featureDisabled="'VISUAL_NAV'"><a class="breadcrumbs__home" uiSref="homepage"><span><img src="/assets/brand-icons/home.svg"></span></a><span><img src="/assets/brand-icons/arrow-right.svg"></span>
        <div class="breadcrumbs__items" *ngFor="let breadcrumbs of activeBreadcrumbs"><span><a class="u-font-medium" *ngIf="breadcrumbs.url" [attr.url]="breadcrumbs.url" href="{{breadcrumbs.url}}" (click)="breadcrumbInteractionTracking(breadcrumbs)"><span>{{breadcrumbs.name}}</span></a></span>
          <p class="u-font-medium" *ngIf="!breadcrumbs.url"><span>{{breadcrumbs.name}}</span></p><span><img src="/assets/brand-icons/arrow-right.svg"></span>
        </div>
      </div>
      <!-- Visual Nav Footer breadcrumbs-->
      <div class="breadcrumbs breadcrumbs__visual-nav" *featureEnabled="'VISUAL_NAV'"><a class="breadcrumbs__home" uiSref="homepage"><span><a bwTrackAs="component.footerBreadcrumb.home">Home</a></span></a><span class="breadcrumbs__items-arrow"><img src="/assets/brand-icons/arrow-right-black.svg"></span>
        <div class="breadcrumbs__items" *ngFor="let breadcrumbs of activeBreadcrumbs"><span><a *ngIf="breadcrumbs.url" [attr.url]="breadcrumbs.url" href="{{breadcrumbs.url}}" (click)="breadcrumbInteractionTracking(breadcrumbs)">{{breadcrumbs.name}}</a></span>
          <p class="u-font-medium" *ngIf="!breadcrumbs.url"><span>{{breadcrumbs.name}}</span></p><span class="breadcrumbs__items-arrow"><img src="/assets/brand-icons/arrow-right-black.svg"></span>
        </div>
      </div>
    </div>
  </div>
  <bw-meta-breadcrumb [activeBreadcrumbs]="activeBreadcrumbs"></bw-meta-breadcrumb>
</ng-container>