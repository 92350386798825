
<bw-staging-helper></bw-staging-helper>
<bw-toastr></bw-toastr>
<bw-feedback-toastr></bw-feedback-toastr>
<bw-nav *ngIf="(ready &amp;&amp; !hideNav)" [displayNav]="displayNav" [displayShop]="displayShop"></bw-nav>
<div class="main-content-height">
  <router-outlet *ngIf="ready"></router-outlet>
</div>
<!--dynamic.-->
<bw-footer *ngIf="ready"></bw-footer>
<!-- IF config has fullClosure true, IF has not already launched-->
<bw-launch-modal *ngIf="!hasLaunched &amp;&amp; stateIsCheckout &amp;&amp; showFullClosureModal" (hasLaunched)="hasLaunchedModal($event)" modalToLaunch="fullClosureModal" [timeout]="800"></bw-launch-modal>
<bw-zendesk-button *ngIf="cookieConsent?.preferences &amp;&amp; showZendeskWidget &amp;&amp; displayZendesk"></bw-zendesk-button>
<bw-maintenance-listener></bw-maintenance-listener>
<bw-fullscreen-loading-spinner-listener></bw-fullscreen-loading-spinner-listener>
<bw-seo-hreflang-alternate></bw-seo-hreflang-alternate>