import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService, ICookieConsent } from 'Shared/services/third-parties/cookie.service';
import { Observable } from 'rxjs';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-video',
  templateUrl: './video.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoComponent implements OnInit {
  @Input()
  data: any;
  @Input({ required: false }) video_style: string = '';
  @Input({ required: false }) video_url: string = '';
  cookieConsent$: Observable<ICookieConsent> = this.cookieService.cookieConsent$;

  constructor(private cookieService: CookieService, private domSanitizer: DomSanitizer, private changeDetection: ChangeDetectorRef) {}

  ngOnInit(): void {
    const d = `(${this.data})`;
    this.data = eval(d);

    if (this.video_url) {
      this.data.videoUrl = this.video_url;
    }
  }

  getVideoLink(): any {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      `${this.data.videoUrl}/?showinfo=0&color=white&rel=0&playsinline=1&modestbranding=1`
    );
  }

  /**
   * Opens the cookiebot popup to renew the consent
   */
  renewCookieConsent(): void {
    this.cookieService.renewConsent();
  }
}
