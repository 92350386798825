import * as clone from 'clone';
import { Country } from 'Shared/classes/country';
import { Email } from 'Shared/services/email.service';

export class Address {
  public id: number;
  public pcaID: string;
  public name: string;
  public company: string;
  public vat?: string;
  public line1: string;
  public line2: string;
  public city: string;
  public postcode: string;
  public phone: string;
  public country: Country;
  public note: string;
  public doorCode?: string;
  public floor?: string;
  public apartmentNumber?: string;
  public buildingNumber?: string;
  public email?: Email;

  constructor(country?: Country, id?: number) {
    this.country = country;
    this.id = id;
  }

  /**
   * Get the squished postcode eg CR4 1DN => cr41dn
   */
  getSquishedPostcode(): string {
    return (this.postcode || '').toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
  }

  /**
   * Get the postcode, strip out multiple spaces and trim
   */
  getPostcode(): string {
    return this.postcode
      ? `${this.postcode}`.replace(/\s\s+/g, ' ').toUpperCase().trim()
      : undefined;
  }

  /**
   * If the address is empty
   */
  isEmpty(): boolean {
    const items = [this.name, this.company, this.line1, this.line2, this.postcode, this.city];
    return items.filter((i) => (i || '').length).length === 0;
  }

  /**
   * Clone
   */
  clone(): Address {
    return clone(this);
  }
}
