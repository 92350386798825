import { Component, OnInit, Input } from '@angular/core';
import { MegaNavService } from 'Shared/services/mega-nav.service';
import { MegaNav } from 'Shared/classes/mega-nav-item';
import { NavBreadcrumbs } from 'Shared/classes/nav-breadcrumbs';
import { StateService } from 'Shared/services/state.service';
import { ActivatedState } from 'Shared/classes/activated-state';
import { LocationService } from 'Shared/services/location.service';
import { translations } from 'Environments/js-translations';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { ConfigService } from 'Shared/services/config.service';
import { CountryService } from 'Shared/services/country.service';
import { ActivatedRoute } from '@angular/router';

import { blogCategories } from 'Shared/components/footer-breadcrumb/footer-breadcrumb-blog-categories';
import { WindowRefService } from 'Shared/services/window.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { FeaturesService } from 'Shared/services/features.service';

export const baseCarouselBreadCrumb = translations['js.meganav.base.breadcrumb'];
export const sendFlowerText = translations['js.component.footer-breadcrumb.send-flowers'];
export const flowerDeliveryText = translations['js.components.seo_links.links.flower_delivery'];
export const shopFlowerText = translations['js.component.footer-breadcrumb.shop'];
/* bw:view-encapsulation */
@Component({
  selector: 'bw-footer-breadcrumb',
  templateUrl: './footer-breadcrumb.component.html'
})
export class FooterBreadcrumbComponent implements OnInit {
  blogCategories = blogCategories;

  megaNavContent: MegaNav;
  activeBreadcrumbs: any[] = [];
  currentStateUrl: string;
  currentState: ActivatedState;
  listType: any;
  stateOnSuccessSubscriber: any;
  checkoutBaseUrl: string;
  visualNavFeatureEnabled: boolean = false;

  @Input() breadcrumbstoprepend: any;
  @Input() islocationpage: string;
  @Input() blogtags: string;
  @Input() currenttitle: string;

  constructor(
    private megaNavService: MegaNavService,
    private stateService: StateService,
    private locationService: LocationService,
    private analyticsService: AnalyticsService,
    private configService: ConfigService,
    private countryService: CountryService,
    private activatedRoute: ActivatedRoute,
    private windowRef: WindowRefService,
    private experimentService: ExperimentsService,
    private featureService: FeaturesService
  ) {
    this.checkoutBaseUrl = this.stateService.href('checkout.base');
  }

  ngOnInit(): void {
    if (this.blogtags) {
      return this.buildBreadCrumbForBlogItem();
    }

    // Check if Visual Nav feature is enabled
    this.visualNavFeatureEnabled = this.featureService.getFeature('VISUAL_NAV');
    this.buildBreadcrumb();
  }

  /**
   * Build breadcrumbs for the blog items
   * @param breadcrumbString
   */
  buildBreadCrumbForBlogItem(): void {
    const blogtags = JSON.parse(this.blogtags);
    const countrySite = this.configService.getConfig().countrySite;

    const blog = this.blogCategories.find((c) => c.sites.indexOf(countrySite) > -1);

    if (!blog) {
      this.activeBreadcrumbs = [];
      return;
    }

    const breadcrumbs = [];

    // Firstly, add the base "Flower Journal" link
    breadcrumbs.push({ url: blog.baseBreadcrumb.url, name: blog.baseBreadcrumb.name });

    // Find the matching category for this blog post
    const category = blog.categories.find((c) => blogtags.indexOf(c.tag) > -1);
    if (category) {
      breadcrumbs.push({
        url: category.url,
        name: category.name
      });
    }

    // The h1 tag is *usually* found on the page, if so, use it, if not, use the title which is passed in
    const titleElem = this.windowRef.nativeWindow.document.querySelector('content h1');
    breadcrumbs.push({
      url: this.locationService.path(),
      name: (titleElem && titleElem['innerText']) || this.currenttitle
    });

    // Finally, set the breadcrumbs
    this.activeBreadcrumbs = breadcrumbs;
  }

  buildBreadcrumb(): void {
    this.activeBreadcrumbs = [];
    this.currentState = this.stateService.getCurrent();

    // do not show on homepage
    if (this.currentState.name === 'homepage') {
      return;
    }

    this.listType = this.locationService.getListType();
    this.currentStateUrl =
      `/${this.checkoutBaseUrl}/${this.listType.type}/${this.listType.value}`.toLowerCase();

    // location page case, to target better
    if (this.islocationpage === 'true') {
      this.activeBreadcrumbs = this.buildLocationPageBreadcrumb();
    } else if (this.listType.type === 'base') {
      // base carousel page
      // Visual Nav Feature has slightly different breadcrumbs
      this.activeBreadcrumbs.push({
        name: this.visualNavFeatureEnabled ? shopFlowerText : `${sendFlowerText}`,
        url: `${this.checkoutBaseUrl}`
      });
    }

    // If Visual Nav has an active NavPath then use that
    if (
      this.stateService.activeVisualNavPath &&
      this.visualNavFeatureEnabled &&
      this.listType.type === 'tagOnly'
    ) {
      return this.setActiveVisualNavPath();
    }

    if (this.listType.type === 'tagOnly' || this.listType.type === 'productPage') {
      this.initMegaNavContents();
    }
  }

  /**
   *  If Visual Nav has an active NavPath then use that
   */
  setActiveVisualNavPath(): void {
    this.activeBreadcrumbs.unshift({
      name: shopFlowerText,
      url: `/${this.checkoutBaseUrl}`
    });

    (this.stateService.activeVisualNavPath || []).forEach((item) => {
      this.activeBreadcrumbs.push({
        name: item.name,
        url: item.url
      });
    });
  }

  breadcrumbInteractionTracking(clickedBreadcrumb): void {
    // If user interacts with the breacrumbs then set active visual nav path as null
    this.stateService.activeVisualNavPath = null;

    this.analyticsService.trackInHeap('breadcrumbsClicked', {
      breadcrumbState: this.activeBreadcrumbs.map((crumb) => crumb.name).join(),
      breadcrumb: clickedBreadcrumb.name
    });
  }

  buildLocationPageBreadcrumb(): any[] {
    // 1. Filters to ensure that they all have a name and a url
    // 2. Then de-duplicate the list, based on the name
    // 3. Then finally, add the url to every item
    const breadcrumbs = JSON.parse(this.breadcrumbstoprepend)
      .filter((item) => item.name && item.url)
      .reduce((acc, curr) => (acc.find((a) => a.name === curr.name) ? acc : [...acc, curr]), [])
      .map((item) => ({
        name: item.name,
        url: `${this.configService.getConfig().domain}${item.url}`
      }));

    breadcrumbs.unshift({ name: sendFlowerText, url: `/${this.checkoutBaseUrl}` });
    breadcrumbs[breadcrumbs.length - 1].name = `${flowerDeliveryText} ${
      breadcrumbs[breadcrumbs.length - 1].name
    }`;
    return breadcrumbs;
  }

  /**
   * Get the Mega Nav Content
   */

  initMegaNavContents(): Promise<any> {
    return this.megaNavService.getMegaNav(this.countryService.forShipping).then((nav) => {
      this.megaNavContent = nav;
      this.activeBreadcrumbs = this.stateService.activeChildNavPath
        ? this.removeDuplicateCrumbs(this.stateService.activeChildNavPath)
        : [];

      this.getBreadcrumbs(this.megaNavContent);
      this.stateService.activeChildNavPath = null;

      // Visual Nav has slightly different breadcrumbs
      this.activeBreadcrumbs.unshift({
        name: this.visualNavFeatureEnabled ? shopFlowerText : sendFlowerText,
        url: `/${this.checkoutBaseUrl}`
      });

      if (this.listType.type === 'tagOnly' && this.activeBreadcrumbs.length === 1) {
        const name = this.stateService.getCurrent().params?.tagOnly;
        this.activeBreadcrumbs.push({
          name: name?.charAt(0).toUpperCase() + name?.slice(1),
          url: `${this.stateService.getCurrent().url}`
        });
      }

      if (this.listType.type === 'productPage' && this.activeBreadcrumbs.length === 1) {
        const currentState = this.stateService.getCurrent();
        const params = currentState.params;
        const tagOnlyName = params.tagOnly;
        if (tagOnlyName && tagOnlyName.length) {
          this.activeBreadcrumbs.push({
            name: tagOnlyName?.charAt(0).toUpperCase() + tagOnlyName.slice(1).replace(/-/g, ' '),
            url: `${this.stateService.href(
              'checkout.tagOnly',
              Object.assign({
                tagOnly: tagOnlyName,
                filters: params.filters
              })
            )}`
          });
        }
        this.activatedRoute.data.subscribe((data) => {
          if (!data || !data.productDetails || !data.productDetails.carouselProduct) {
            return;
          }
          this.activeBreadcrumbs.push({
            name: data.productDetails.carouselProduct.name,
            url: null
          });
        });
      }
    });
  }

  /**
   *
   * @param breadcrumbs
   */
  removeDuplicateCrumbs(breadcrumbs): any {
    const parentCrumb = (breadcrumbs || []).slice().splice(0, 1);

    const filteredBreadcrumbs = (breadcrumbs || []).filter((crumb) =>
      parentCrumb[0].url !== crumb.url ? crumb : ''
    );

    return parentCrumb.concat(filteredBreadcrumbs);
  }

  /**
   * Get Breadcrumbas on every state change
   */
  getBreadcrumbs(nav: MegaNav): void {
    // First check parents
    const foundInParent = this.checkParents(nav);

    this.activeBreadcrumbs = foundInParent && foundInParent.length > 0 ? foundInParent : [];

    // If not found in parents recursively find in children
    if (!foundInParent.length) {
      const breadcrumbs = this.megaNavService.findPathToNavItem(nav, this.currentStateUrl);
      this.activeBreadcrumbs = this.removeDuplicateCrumbs(breadcrumbs);

      // Remove empty emtries
      this.activeBreadcrumbs = this.activeBreadcrumbs.filter((elem) => {
        if (elem.name) {
          return elem;
        }
      });
    }
  }

  private checkParents(nav): NavBreadcrumbs[] {
    const navParentItems = ((nav && nav.items) || [])
      .map((navItem) => {
        return {
          name: navItem.name,
          url: navItem.url,
          underlineColour: navItem.underlineColour ? navItem.underlineColour : ''
        };
      })
      .filter((navItems) => navItems.url === this.currentStateUrl);

    return navParentItems;
  }
}
