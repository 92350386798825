import { Injectable } from '@angular/core';
import { MegaNavContentsModelService } from 'Shared/models/mega-nav-contents-model.service';
import { MegaNav } from 'Shared/classes/mega-nav-item';
import { CountryService, Country } from 'Shared/services/country.service';
import { NavBreadcrumbs } from 'Shared/classes/nav-breadcrumbs';
import { ContentService } from 'Shared/services/content.service';
import { ContentSegment } from 'Shared/models/segment-model.service';

@Injectable({
  providedIn: 'root'
})
export class MegaNavService {
  shippingCountryId: number;
  megaNavContents: MegaNav;

  constructor(
    private megaNavModelService: MegaNavContentsModelService,
    private countryService: CountryService,
    private contentService: ContentService
  ) {}

  /**
   * Get Mega Nav  Contents
   */
  getMegaNav(shippingCountry: Country): Promise<MegaNav> {
    return Promise.all([
      this.megaNavModelService.getMegaNav(shippingCountry.id),
      this.contentService.getContentSegments()
    ]).then(([megaNav, segments]: [MegaNav, ContentSegment[]]) => {
      return this.excludeNavItemsForSegments(segments, megaNav);
    });
  }

  /**
   * Exclude any gift cards based on the user's segment
   * @param segments
   * @param config
   * @param covers
   */
  private excludeNavItemsForSegments(segments: ContentSegment[], megaNav: MegaNav): MegaNav {
    const toExclude = segments.reduce((acc, segment) => {
      acc.push(...segment.excluded_tags);
      return acc;
    }, []);

    const megaNavCloned = megaNav.clone();

    // Loop though items, then sections, then links
    megaNavCloned.items = megaNavCloned.items
      .filter((item) => !toExclude.find((t) => item.tags.indexOf(t) > -1))
      .map((item) => {
        item.sections = item.sections
          .filter((section) => !toExclude.find((t) => section.tags.indexOf(t) > -1))
          .map((section) => {
            section.links = section.links.filter(
              (link) => !toExclude.find((t) => link.tags.indexOf(t) > -1)
            );

            return section;
          });

        return item;
      });

    return megaNavCloned;
  }

  /**
   *
   * @param navNode // Represents each node (nested array) within the nav
   * @param navItemToFind // the url to find
   */
  findPathToNavItem(navNode, navItemToFind): NavBreadcrumbs[] {
    // Our exit statement for the recursive function
    // Our exit statement for the recursive function
    if (((navNode && navNode.url) || []) === navItemToFind) {
      return [];
    }

    const nav = navNode.items || navNode.sections || navNode.links;

    if (Array.isArray(nav)) {
      // Using this format of For loop because I wanna return/exit
      for (let i = 0; i < nav.length; i++) {
        const result = this.findPathToNavItem(nav[i], navItemToFind);
        if (Array.isArray(result)) {
          return [
            {
              name: !nav[i].shortCopy ? nav[i].name : nav[i].shortCopy,
              url: nav[i].url,
              underlineColour: nav[i].underlineColour ? nav[i].underlineColour : '',
              backgroundColour: nav[i].backgroundColour ? nav[i].backgroundColour : ''
            }
          ].concat(result);
        }
      }
    }
  }
}
