
<div class="search-address">
  <bw-form-dropdown *ngIf="(addressLookupEnabled &amp;&amp; bwFormControl?.invalid) || forceAddressLookup" [bwFormControl]="bwFormControl" inputType="search" [data]="results" [showLoading]="showLoading" [forceShowPlaceholder]="showLoading" [setValueOnSelect]="setValueOnSelect" [label]="label" [debounceTime]="250" autoComplete="do-not-autocomplete" [displayFn]="postcodeBeforeCityEnabled ? displayPostcodeBeforeCity : onDisplay" (didSearch)="onSearch($event)" (didSelect)="onSelect($event)" (didClick)="onClick()">
    <ng-container role="instructions">
      <div *ngIf="newStyle" (click)="onClick()" (keyup.enter)="onClick()" tabIndex="0">Search for an address or <a class="bwLink" bwTrackAs="component.address-search.auto_key.string_2">Enter address manually</a></div>
      <div *ngIf="!newStyle" (click)="onClick()" (keyup.enter)="onClick()" tabIndex="0">Enter address manually</div>
    </ng-container>
    <ng-container role="errors">
      <div for="invalid">Search and select a recipient address</div>
    </ng-container>
  </bw-form-dropdown>
  <bw-form-text-input *ngIf="(!addressLookupEnabled || bwFormControl?.valid) &amp;&amp; !forceAddressLookup" [bwFormControl]="bwFormControl" [multiline]="multiline" [label]="label" inputType="display" [displayFn]="postcodeBeforeCityEnabled ? displayPostcodeBeforeCity : onDisplay" [forceShowPlaceholder]="!addressLookupEnabled &amp;&amp; bwFormControl.invalid" (didClick)="onClick()">
    <ng-container role="errors">
      <div for="invalid">Search and select a recipient address</div>
    </ng-container>
  </bw-form-text-input>
</div>