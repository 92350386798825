import { Pipe, PipeTransform } from '@angular/core';
import { t } from 'Shared/utils/translations';

@Pipe({
  name: 'frequency'
})
export class FrequencyPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value === 7) {
      return t('js.pipe.week');
    }
    if (value === 14) {
      return t('js.pipe.two-weeks');
    }
    if (value === 28) {
      return t('js.pipe.month');
    }
  }
}
