import { Injectable } from '@angular/core';
import { TicketModelService } from 'Shared/models/ticket-model.service';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  constructor(private ticketModelService: TicketModelService) {}

  createTicket(payload: any): Promise<any> {
    return this.ticketModelService.createTicket(payload);
  }
}
