import {
  Component,
  OnInit,
  forwardRef,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl,
  Validators,
  FormGroup
} from '@angular/forms';
import { Address } from 'Shared/classes/address';
import { UserService, User } from 'Shared/services/user.service';
import { AddressService } from 'Shared/services/address.service';
import { Country } from 'Shared/classes/country';
import { ModalService } from 'Shared/services/modal.service';
import { AddressModalComponent } from 'Shared/components/address-modal/address-modal.component';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { FeaturesService } from 'Shared/services/features.service';
import { ShippingOption } from 'Shared/classes/shipping-option';
import { ValidateRecipientSearch } from 'Checkout/validators/validateRecipientSearch';
import { CountryService } from 'Shared/services/country.service';
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-recipient-search',
  templateUrl: './recipient-search.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RecipientSearchComponent)
    }
  ]
})
export class RecipientSearchComponent
  implements ControlValueAccessor, OnInit, OnDestroy, OnChanges
{
  recipientForm: FormGroup;
  showAddressPhone: boolean = false;
  showAddressPhoneTooltip: boolean = false;
  userSubscription: any;
  pcaEnabled: boolean = false;
  mouseDown: boolean = false;
  popperTrigger: NgxPopperjsTriggers = NgxPopperjsTriggers.click;
  popperPlacement: NgxPopperjsPlacements = NgxPopperjsPlacements.TOPEND;

  @Output() touchedRecipient: EventEmitter<any> = new EventEmitter();
  @Output() recipientSubmitted: EventEmitter<string> = new EventEmitter();
  @Input() country: Country;
  @Input() address: Address;
  @Input() formSubmitted: boolean;
  @Input() shippingOption?: ShippingOption;

  defaultPhoneValidators = [
    Validators.pattern(/^[- +()]*[0-9][- +()0-9]*$/),
    Validators.minLength(7),
    Validators.maxLength(25)
  ];

  savedAddresses: Address[] = [];
  nameSearchResults: any[] = [];

  onTouched: Function = () => {};
  onChanges: Function = () => {};

  constructor(
    private userService: UserService,
    private addressService: AddressService,
    private modalService: ModalService,
    private featuresService: FeaturesService,
    private countryService: CountryService
  ) {
    this.constructComponent();
  }

  /**
   * Construct the form
   */
  private constructComponent(): void {
    const countryConfig = this.featuresService.getFeature('ADDRESS_FIELDS');

    const nameValidators = [Validators.required];
    if (countryConfig.firstAndSecondNameRequired) {
      nameValidators.push(Validators.pattern(/\S+\s\S+/));
    }

    const country = this.country || this.countryService.forShipping;
    const addressValidator = [
      Validators.required,
      ValidateRecipientSearch.postcodePattern(country.address.postcodeRegex)
    ];

    if (!countryConfig.postcodeOptional) {
      addressValidator.push(ValidateRecipientSearch.postcodeRequired);
    }
    if (countryConfig.postcodeLength > -1) {
      addressValidator.push(ValidateRecipientSearch.postcodeLength(countryConfig.postcodeLength));
    }

    this.recipientForm = new FormGroup({
      name: new FormControl(undefined, { updateOn: 'blur', validators: nameValidators }),
      address: new FormControl(undefined, addressValidator),
      phone: new FormControl('', this.defaultPhoneValidators)
    });
  }

  /**
   * On changes
   * @param obj
   */
  ngOnChanges(obj): void {
    // If shipping option has changed, re-validate the form
    if (obj.shippingOption) {
      this.setPhoneValidationRules();
    }
  }

  /**
   * CVA - Write the value
   * @param address
   * @param emitEvent - To emit all form events
   */
  writeValue(address: Address, emitEvent: boolean = false): void {
    this.recipientForm.get('name').setValue(address ? address.name : '', { emitEvent });
    this.recipientForm.get('phone').setValue(address ? address.phone : '', { emitEvent });
    this.recipientForm.get('address').setValue(address ? address : '', { emitEvent });
    if (address && address.name) {
      this.recipientForm.get('name').markAsDirty();
    }
    if (address && address.phone) {
      this.recipientForm.get('phone').markAsDirty();
    }
    if (address) {
      this.recipientForm.get('address').markAsDirty();
    }

    // On form reset
    if (address === null) {
      this.nameSearchResults = this.savedAddresses;
      this.recipientForm.markAsPristine();
      this.recipientForm.markAsUntouched();
    }

    this.touchedRecipient.emit(address);
  }

  /**
   * CVA - Register on changes and update the angular model
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChanges = fn;
    this.recipientForm.valueChanges.subscribe((val) => {
      this.onChanges(this.getCurrentValue());
    });
  }

  /**
   * CVA - Register on touched event
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * CVA - Set disabled state
   * @param isDisabled
   */
  setDisabledState(isDisabled: any): void {
    return isDisabled ? this.recipientForm.disable() : this.recipientForm.enable();
  }

  /**
   * Get the current Value as an address
   * TODO: Should this only have a value if this form is valid???
   */
  getCurrentValue(): Address {
    const addressValue = this.recipientForm.get('address').value;
    const nameValue = this.recipientForm.get('name').value;
    const phoneValue = this.recipientForm.get('phone').value;
    if (!addressValue && !nameValue && !phoneValue) {
      return undefined;
    }

    const address = (addressValue || new Address(this.country)).clone();
    address.country =
      address.country || (addressValue ? addressValue.country : undefined) || this.country;

    address.name = nameValue || (addressValue ? addressValue.name : undefined);
    address.phone = phoneValue;
    return address;
  }

  /**
   * Open the address modal
   */
  openAddressModal(): Promise<any> {
    const currentAddress = this.getCurrentValue();
    const address = currentAddress ? currentAddress : new Address(this.country);

    return this.modalService
      .show(AddressModalComponent, {
        initialState: {
          address,
          showVatField: false,
          doNotCreate: true,
          userIsGuest: !this.userService.getUser().isLoggedIn(),
          limitCountry: address.country
        }
      })
      .then((addr) => {
        this.setAddress(addr);
        this.onTouched();
        return this.refreshSavedAddressList();
      });
  }

  /**
   * User just wants the name - no problem
   * @param name
   */
  onManualNameEntry(name: string): void {
    this.recipientForm.get('name').setValue(name);
    this.onTouched();
  }

  /**
   * If a name has been selected
   * @param $event
   */
  onSavedAddressSelected(address: Address): void {
    const addr = address.clone();
    this.setAddress(addr);
    this.onTouched();
  }

  /**
   * If valid, hide the dropdown
   * @param dropdown
   */
  closeOnBlurIfValid($event: any, dropdown: any): void {
    if (this.recipientForm.get('name').valid && !this.mouseDown) {
      // Starting to click or tap on an address will blur the input
      // which will close the dropdown, ultimately meaning the touchend or mouseup
      // event comes too late, and no address is selected
      // by checking mouse/touch status we avoid this bug
      dropdown.hide();
      this.recipientSubmitted.emit(this.recipientForm.get('name').value);
    }
  }

  /**
   * Prevent Close
   * @param event
   */
  preventClose(event: Event): void {
    event.stopImmediatePropagation();
  }

  handleMouseDown(mouseDown): void {
    this.mouseDown = mouseDown;
  }

  /**
   * Set the address
   * TODO: Can we combine this with the CVA stuff?
   * @param address
   */
  setAddress(address: Address): void {
    this.writeValue(address, true);
    this.recipientForm.get('name').markAsDirty();
    this.recipientForm.get('address').markAsDirty();
    this.recipientForm.get('phone').markAsDirty();
  }

  /**
   * On manual address select
   * @param $event
   */
  onManualAddressSelect($event): Promise<any> {
    return this.openAddressModal();
  }

  /**
   * If we've found an address set the form
   * @param addr
   */
  onSearchedAddressSelected(address: Address): any {
    if (!address) {
      return;
    }
    const addr = address.clone();
    addr.name = this.recipientForm.get('name').value;
    addr.phone = this.recipientForm.get('phone').value;
    this.setAddress(addr);
    this.onTouched();
  }

  /**
   * If a user focuses on the address display field, launch the modal
   */
  onAddressDisplayFocus(): Promise<any> {
    return !this.pcaEnabled ? this.openAddressModal() : Promise.resolve(true);
  }

  /**
   * Refresh the saved address list if the user is logged in
   */
  refreshSavedAddressList(): Promise<any> {
    if (!this.userService.getUser().isLoggedIn()) {
      this.nameSearchResults = [];
      this.savedAddresses = [];
      return Promise.resolve([]);
    }

    return this.addressService.getAll().then((addresses) => {
      this.savedAddresses = addresses.filter((a) => a.country.id === this.country.id);
      this.nameSearchResults = this.savedAddresses;
    });
  }

  /**
   * On querySearch search, filter the results
   * @param $event
   */
  onNameSearch($event: any): any {
    // We want to by-pass the angular model, as using blur as onUpdate for the model makes the UI much easier
    const querySearch = $event.srcElement.value || '';
    let results: any = (this.savedAddresses || []).slice();
    if (querySearch && this.savedAddresses && this.savedAddresses.length) {
      results = results.filter((address) => this.filterAddress(address, querySearch));
      results.unshift({
        name: querySearch,
        isPlaceholder: true
      });
    }

    this.nameSearchResults = results;
  }

  private filterAddress(item: any, querySearch: any): any {
    return !!Object.values(item).find((line) => this.getQuerySearchIndex(line, querySearch) > -1);
  }

  private getQuerySearchIndex(item: any, querySearch: string): number {
    return (item || '').toString().toLowerCase().indexOf(querySearch.toLowerCase());
  }

  private setPhoneValidationRules(): void {
    const countryConfig = this.featuresService.getFeature('ADDRESS_FIELDS', this.country);
    this.showAddressPhoneTooltip = countryConfig.showAddressPhoneTooltip;

    this.showAddressPhone = countryConfig.showAddressPhone;

    const validations = this.defaultPhoneValidators.slice();

    this.showAddressPhone =
      (this.shippingOption && this.shippingOption.hasPhoneNumber) ||
      (this.shippingOption && this.shippingOption.hasPhoneNumberRequired) ||
      countryConfig.showAddressPhone;

    if (this.shippingOption && this.shippingOption.hasPhoneNumberRequired) {
      validations.push(ValidateRecipientSearch.requirePhoneForShippingOption(this.shippingOption));
    }

    this.recipientForm.get('phone').setValidators(validations);
    this.recipientForm.get('phone').updateValueAndValidity();
  }

  /**
   * On init
   */
  ngOnInit(): Promise<any> {
    const countryConfig = this.featuresService.getFeature('ADDRESS_FIELDS', this.country);
    this.showAddressPhoneTooltip = countryConfig.showAddressPhoneTooltip;
    this.setPhoneValidationRules();

    this.userSubscription = this.userService.user$.subscribe((user) => {
      this.refreshSavedAddressList();
    });

    this.pcaEnabled = this.featuresService.getFeature('ADDRESS_LOOKUP');

    return this.refreshSavedAddressList();
  }

  /**
   * Destroy
   */
  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }
}
