import { ReviewsAPI } from './reviews-api.typings';
import { PurchasesAPI } from './purchase-api.typings';
import { AddressesAPI } from './addresses-api.typings';
import { CardsAPI } from './card-api.typings';
import { EmailAddressesAPI } from './email-address-api.typings';
import { UsersAPI } from './users-api.typings';
import { AvailabilityAPI } from './availability-api.typings';
import { InvoicesAPI } from './invoices-api.typings';
import { GiftCertificateAPI } from './gift-certificate-api.typings';
import { OrdersAPI } from './orders-api.typings';
import { UserLookupAPI } from './user-lookup-api.typings';
import { ReasonsAPI } from './reasons-api.typings';
import { SchedulingAPI } from './scheduling-api.typings';
import { SubscriptionAPI } from './subscription-api.typings';
import { ChargesAPI } from './charges-api.typings';
import { LoyaltyAPI } from './loyalty-api.typings';

/**
 * Basic Query Params type
 */
export type BasicParams = {
  [key: string]: string | boolean | number | (string | number)[];
  locale?: never; // We automatically append locale, so no need to pass it here
};

/**
 * Combined Type, extend with more APIs using the '&' syntax
 */
export type BackendAPI = ReviewsAPI &
  PurchasesAPI &
  AddressesAPI &
  CardsAPI &
  EmailAddressesAPI &
  UsersAPI &
  AvailabilityAPI &
  InvoicesAPI &
  GiftCertificateAPI &
  OrdersAPI &
  UserLookupAPI &
  ReasonsAPI &
  SchedulingAPI &
  SubscriptionAPI &
  ChargesAPI &
  LoyaltyAPI;

/*
 * List of all typed API endpoints
 */
export type APIEndpoint = keyof BackendAPI;

/**
 * Generic Type to return back the query params based on the API Endpoint
 */
export type APIRequestQueryParams<T> = T extends APIEndpoint ? BackendAPI[T]['requestQueryParams'] : BasicParams;

/**
 * Generic Type to return back the SerialisedJSONREsponse based on the API endpoint
 */
export type APISerialisedJSONResponse<T> = T extends APIEndpoint ? BackendAPI[T]['serialisedJSONResponse'] : any;

/**
 * Generic type to return the request body for a given API endpoint
 */
export type APIJSONRequest<T> = T extends APIEndpoint ? BackendAPI[T]['JSONRequest'] : any;

/**
 * Generic type to return any additional headers based on the API endpoint
 */
export type APIHeaders<T> = T extends APIEndpoint ? BackendAPI[T]['additionalHeaders'] : any;

/**
 * Generic type to return back based on a paginated API endpoint
 */
export type APIPaginationResponse = {
  data: any;
  meta: {
    pagination: {
      page: {
        size: number;
        total: number;
        sort: string;
      };
      links: {
        [key: string]: string;
      };
      link_params: {
        [key: string]: {
          page: {
            [key: string]: any;
          };
        };
      };
    };
  };
};

/**
 * API pagination options
 */
export type APIPaginationOptions = {
  page?: {
    total?: number;
    size: number;
    before?: string;
    after?: string;
  };
  before?: string;
  after?: string;
  size?: number;
  sort?: string;
  next?: boolean; // used to choose between before or after used
  total?: number;
};

/**
 * Standardised paginated response
 */
export type APIPaginatedResponse<T> = {
  data: T;
  paginationOptions: APIPaginationOptions;
};

/**
 * Default pagination options
 */
export const defaultAPIPaginationOptions = {
  size: 6,
  sort: 'desc'
} as APIPaginationOptions;
