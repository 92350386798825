import { Component, OnInit } from '@angular/core';
import { CookieService, ICookieConsent } from 'Shared/services/third-parties/cookie.service';
import { StateParams, StateService } from 'Shared/services/state.service';
import { Observable } from 'rxjs';
import { MentionMeParameters } from 'Shared/classes/mention-me';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-share',
  templateUrl: './share.component.html'
})
export class ShareComponent implements OnInit {
  cookieConsent$: Observable<ICookieConsent> = this.cookieService.cookieConsent$;
  segment!: string;
  parameters!: MentionMeParameters;
  campaignData: unknown;

  constructor(private cookieService: CookieService, private stateService: StateService) {}

  /**
   * Subscrition subscriber campaing check
   */
  get isSubscriberCampaign(): boolean {
    return this.campaignData && this.campaignData['subscriptionCampaign'] === true;
  }
  /**
   * On init
   */
  ngOnInit(): void {
    const current = this.stateService.getCurrent();
    const params = current.params as StateParams;
    const data = this.stateService.getCurrent().data;
    this.campaignData = data;
    this.parameters = {
      fullname: params?.fullname || undefined,
      email: params?.email || undefined
    };
    this.segment = current.data?.['segment'] ?? current.params?.['segment'] ?? undefined;
  }
}
