import { Component, OnInit, ViewEncapsulation } from '@angular/core';

/* bw:view-encapsulation */
@Component({
  selector: 'separator-bar',
  templateUrl: './separator-bar.component.html'
})
export class SeparatorBarComponent implements OnInit {
  ctaType: string;
  boldText: string;
  standardText: string;

  ngOnInit(): any {
    this.boldText = this['bold-text'] || '';
    this.standardText = this['standard-text'] || '';
  }
}
