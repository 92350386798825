/**
 * Note towards delivery discount range and possible options:
 * [1,1] - upfront upfront payment discounted (for bundles this means all deliveries are discounted, not just first)
 * [1,3] - multi upfront cost + deliveries 2 and 3
 * [1,null] - all all deliveries
 * [3,5] - multi deliveries from 3 to 5, not discounted upfront (Currently not used but APIs can return this scenario)
 */

export class Discount {
  code: string;
  percentage?: string;
  amountPennies?: number;
  empty: boolean;
  fromDeliveryNumber: number;
  toDeliveryNumber: number;
  codeRedemptionId?: number;
  removable?: boolean;
  nearestApplicationMessage?: string;
  description?: string;
  referral?: boolean;
  redeemedOnDeliveryId?: number;
  active?: boolean; // Used in checkout
  campaign_description?: string; // Used in checkout

  constructor(code: string, percentage?: string, amount?: number, deliveryRange?: number[], description?: string) {
    this.code = this.normalizedCode(code);
    this.percentage = percentage;
    this.amountPennies = amount;
    this.description = description;

    this.empty = !percentage && !amount;

    this.fromDeliveryNumber = deliveryRange ? deliveryRange[0] : 1;
    this.toDeliveryNumber = deliveryRange ? (deliveryRange[1] === null ? -1 : deliveryRange[1]) : 1;
  }

  /**
   * Normalise discount code
   * @param {string} code
   * @returns {string}
   */
  private normalizedCode(code: string): string {
    if (code) {
      return code.trim().toLowerCase();
    }
    return code;
  }
}
