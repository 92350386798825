import { t } from 'Shared/utils/translations';

// Types of auth modal forms
export type AuthTypes = 'login' | 'reset' | 'register' | 'claim';
export type AuthSteps = 'email' | 'password' | 'register' | 'guest';

// Account offerings interface
export interface AccountOffering {
  key: string;
  name: string;
  iconName: string;
  uiIconName?: string;
  show?: boolean;
}

// Accounts offerings used on verify and register auth forms
export const ACCOUNT_OFFERINGS: AccountOffering[] = [
  {
    key: 'checkout',
    name: t('js.component.auth-register.offering-checkout'),
    iconName: 'credit_card',
    uiIconName: 'card'
  },
  {
    key: 'occasions',
    name: t('js.component.auth-register.offering-occasions'),
    iconName: 'calendar__outline',
    uiIconName: 'calendar'
  },
  {
    key: 'account',
    name: t('js.component.auth-register.offering-account'),
    iconName: 'edit__action',
    uiIconName: 'edit'
  },
  {
    key: 'invoices',
    name: t('js.component.auth-register.offering-invoices'),
    iconName: 'upload__rotated',
    uiIconName: 'download'
  }
];

export type AuthOrigin = 'checkout' | 'account' | 'occasions' | 'confirmation' | 'modal' | 'hcDelivery' | 'hcQuality';
