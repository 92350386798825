import { Component, OnInit } from '@angular/core';
import { ContentService } from 'Shared/services/content.service';
import { LocationService } from 'Shared/services/location.service';
import { LazyLoaderService } from 'Shared/services/lazy-loader.service';
import { StateService } from 'Shared/services/state.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { WindowRefService } from 'Shared/services/window.service';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'Shared/services/config.service';
import { translations } from 'Environments/js-translations';
import { FeaturesService } from 'Project/shared/services/features.service';

@Component({
  selector: 'content',
  template: `
    <bw-content-display [content]="content"></bw-content-display>
    <bw-launch-modal></bw-launch-modal>
  `
})
export class ContentComponent implements OnInit {
  content = '';
  window: any;
  constructor(
    private contentService: ContentService,
    private stateService: StateService,
    private lazyLoadService: LazyLoaderService,
    private analyticsService: AnalyticsService,
    private state: StateService,
    public experimentSerivce: ExperimentsService,
    private windowRef: WindowRefService,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private featureService: FeaturesService
  ) {
    this.window = this.windowRef.nativeWindow;
  }

  /**
   * Track page view in heap
   * @param path
   */
  trackPageView(path: string): void {
    const currentState = this.stateService.getCurrent();
    // waiting for the DOM to render
    setTimeout(() => {
      if (currentState && currentState.name === 'content') {
        this.analyticsService.trackInHeap('contentfulPageViewed', {
          contentfulPageUrl: path,
          contentfulPageTitle: this.window.document.title,
          referrerUrl: currentState && currentState.from ? currentState.from.url : this.window.document.referrer
        });
      }
    }, 1000);
  }

  /**
   * On init
   */
  ngOnInit(): Promise<any> {
    const currentState = this.stateService.getCurrent();

    // Due to complexities over child and grand-child routes, we want to get the current state data
    let routeData: any = currentState.data;
    try {
      const activatedRouteData = this.route.data['value'];
      routeData = Object.assign({}, currentState.data, activatedRouteData);
    } catch (e) {}

    let path: string = routeData.contentToLoad || this.stateService.getCurrent().url;

    // TODO : remove once old contenful is disabled
    if (!this.featureService.getFeature('HELP_CENTRE_V2') && (currentState.name as string).includes('help')) {
      path = translations['js.routing.content.help'];
    }

    return this.contentService
      .get(path)
      .then((html) => {
        this.content = html;
        this.trackPageView(path);
      })
      .catch((error) => {
        // If we are trying to go to a SEO location page, but we are trying to fallback, instead, redirect
        const fallbackDomain = this.configService.getConfig().contentFallbackDomain;
        if (fallbackDomain && error.message === 'doNotFallbackToLocationPage') {
          this.window.location.href = `${fallbackDomain}/${path}`;
          return;
        }

        // An empty string eg "" is valid here - we just don't redirect
        const fourOhFourRoute = typeof routeData.custom404 !== 'undefined' ? routeData.custom404 : '404';

        this.analyticsService.track('routing.404', { path });

        if (fourOhFourRoute.length) {
          this.state.go(fourOhFourRoute, {}, { skipLocationChange: true });
        }
      });
  }
}
