import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ReviewsService } from 'Shared/services/reviews.service';
import { Review } from 'Shared/classes/review';
import { CountryService } from 'Shared/services/country.service';
import { BehaviorSubject } from 'rxjs';
import {
  IActiveViewports,
  ViewportDetectionService
} from 'Shared/services/viewport-detection.service';
import { t } from 'Shared/utils/translations';
import { FeaturesService } from 'Shared/services/features.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-reviews-testimonials-carousel',
  templateUrl: './reviews-testimonials-carousel.component.html'
})
export class ReviewsTestimonialsCarouselComponent implements OnInit {
  reviews: Review[];
  source: '' | 'trustpilot' | 'trustedshops' = '';
  stats: any;
  viewportSizeIs$: BehaviorSubject<IActiveViewports> = this.viewPortDetection.viewportSizeIs$;
  headingTitle: string = '';
  headingSubtitle: string = '';
  reviewType: string;

  constructor(
    private reviewService: ReviewsService,
    private countryService: CountryService,
    private viewPortDetection: ViewportDetectionService,
    private featureService: FeaturesService
  ) {}

  getAndSetReviewStats(): Promise<any> {
    const shippingCountry = this.countryService.forShipping;

    return this.reviewService.getReviewStats(shippingCountry).then((res) => {
      this.stats = res;

      if (this.stats.count) {
        this.stats.count = this.stats.count.toLocaleString();
      }
    });
  }

  setReviewType(): void {
    this.reviewType = this.featureService.getFeature('REVIEWS').active
      ? this.featureService.getFeature('REVIEWS').type
      : '';
  }

  ngOnInit(): Promise<any> {
    this.headingTitle = t('js.component.reviews-display.tagline');
    this.headingSubtitle = t('js.component.reviews-display.intro');

    this.setReviewType();
    this.getAndSetReviewStats();

    const shippingCountry = this.countryService.forShipping;
    return this.reviewService.getAll(shippingCountry, 4).then((res) => {
      this.reviews = res.reviews;
    });
  }
}
