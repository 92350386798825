import { Injectable } from '@angular/core';
import { BackendService } from 'Shared/services/backend.service';
import * as dayjs from 'dayjs';
import { RangeDiscoveryApiService } from 'Shared/services/range-discovery-api.service';
import { WindowRefService } from 'Shared/services/window.service';
import { RangeProductFilterParams, RangeProductParams, RangeProductFilter, RangeProductsResponse } from 'Shared/classes/range-products';
import { Product } from 'Shared/classes/product';
import { PeakShippingService } from 'Shared/services/peak-shipping.service';
@Injectable({
  providedIn: 'root'
})
export class RangeProductModelService {
  constructor(
    private backend: BackendService,
    private rangeDiscoveryApiService: RangeDiscoveryApiService,
    private windowRefService: WindowRefService,
    private peakShippingService: PeakShippingService
  ) {}

  static fromRangePayload(res, isFreePeakDeliveryMessagingEnabled: boolean): Product {
    const attr = res.attributes;
    const product = new Product(res.id);

    // simple assignments
    product.slug = attr.slug;
    product.name = attr.name;
    product.lilyFree = attr.lily_free;
    product.over18Only = attr.eighteen_plus;
    product.collectionName = attr.collection_name;
    product.collectionId = attr.collection_id;
    product.isPreorder = attr.is_pre_order;
    product.isInStock = attr.in_stock;
    product.description = attr.description;
    product.longDescription = attr.long_description;
    product.shortDescription = attr.email_description;
    product.videoUrl = attr.video_url;
    product.videoThumbnail = attr.video_thumbnail_url;
    product.filterAttributes = attr.sku_attributes;
    product.type = attr.product_kind;

    // assignments with some logic
    product.bundleOnly = attr.bundle_only || false;
    product.singleOnly = attr.single_only || false;
    product.subscriptionOnly = attr.subscription_only || false;
    product.doubleRewardPoints = attr.double_reward_points || false;
    product.latestShippingOptionCutoff = attr.latest_shipping_option_cut_off ? dayjs(attr.latest_shipping_option_cut_off) : undefined;
    product.isSelfPurchaseSubscription = (attr.tags || []).includes('self-purchase-subscription');
    product.rating = { count: attr.rating_count, average: attr.rating_average };

    // assignments handled by product
    product.setUpsells(attr.associated_skus);
    product.setAddonPrimaryImage(attr.media);
    product.setDeliverableToAndFrom(attr.deliverable_from, attr.deliverable_to);
    product.setAppearingToAndFrom(attr.appearing_from, attr.appearing_to);
    product.setSkuImageSwap(attr.media);
    product.setImageUrls(attr.media, attr.imageUrls, res.bouquet_images);
    product.setDiscountAddon(attr.discount_info, attr.currency);
    product.setAddonRequirements(attr.addon_requirements);
    product.setPricing(attr.currency, attr.prices);

    product.setLabelsAndTags(
      attr.tags,
      attr.label,
      attr.label_type,
      attr.double_reward_points === true,
      isFreePeakDeliveryMessagingEnabled
    );

    return product;
  }

  /**
   * Convert filters object into series of params parsable by range products api
   * @param filters
   */
  filtersAsQueryObjects(filters: RangeProductFilterParams): RangeProductFilterParams {
    if (!filters) {
      return;
    }

    return Object.keys(filters).reduce((init, key): RangeProductFilterParams => {
      if (Array.isArray(filters[key])) {
        init[`filters[${key}][]`] = filters[key];
      } else {
        init[`filters[${key}]`] = filters[key];
      }
      return init;
    }, {} as RangeProductFilterParams);
  }

  /**
   * Get all products from BE
   * @param brandId
   * @param country
   * @param pageSize
   */
  getAvailableRangeProducts(params: RangeProductParams): Promise<RangeProductsResponse> {
    const backendRangeUrl = this.rangeDiscoveryApiService.rangeDiscoveryServer;
    const {
      bouquetSlug,
      brandId,
      country,
      filteredTagonly,
      filters,
      orderIndex,
      pageSize,
      prioritisedTag,
      sortType,
      user,
      validatedDiscountCode,
      excludedTags
    } = params;

    const isFreePeakDeliveryMessagingEnabled = this.peakShippingService.isFreePeakDeliveryMessagingEnabled();

    return this.backend
      .get(null, backendRangeUrl, {
        useUrlAsCache: true,
        sendExperiments: ['API_'],
        useFullUrlFromInput: true,
        params: {
          brand: brandId,
          shipping_country_id: country.id,
          user_slug: user?.slug,
          'page[size]': pageSize,
          sort: sortType ? sortType : undefined,
          // On the BE we can support the prioritization of multiple slugs, but we currently only support one on the FE
          'prioritize[slugs][]': bouquetSlug ?? undefined,
          // On the BE we can support the prioritization of multiple tags, but we currently only support one on the FE
          'prioritize[tags][]': prioritisedTag ?? undefined,
          'filters[tag]': filteredTagonly ?? undefined,
          'filters[-tags][]': excludedTags ?? undefined,
          device_fingerprint: JSON.parse(this.windowRefService.nativeWindow['bwFingerprint'] || '""').replace(/\"/gim, ''),
          discount_code: validatedDiscountCode,
          first_item_in_purchase: orderIndex === 0,
          ...this.filtersAsQueryObjects(filters)
        }
      })
      .then((res): RangeProductsResponse => {
        const availableProducts =
          res && res.data
            ? res.data.map((r): Product => RangeProductModelService.fromRangePayload(r, isFreePeakDeliveryMessagingEnabled))
            : [];
        const availableFilters = res && res.filters ? res.filters : [];
        const filtersReset = res && res.filters_reset;
        return { availableProducts, availableFilters, filtersReset };
      })
      .catch((): { availableProducts: Product[]; availableFilters: RangeProductFilter[] } =>
        // TODO: in case this fails it should fallback to the old v2/availability/products
        ({ availableProducts: [], availableFilters: [] })
      );
  }
}
