import { Component, AfterViewInit, OnInit, Input, EventEmitter, Output, HostListener, OnDestroy } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Subscription } from 'rxjs';
import { CookieService, ICookieConsent } from 'Shared/services/third-parties/cookie.service';
import { User, UserService } from 'Shared/services/user.service';
import { ContentService } from 'Shared/services/content.service';
import { t } from 'Shared/utils/translations';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { WindowRefService } from 'Shared/services/window.service';
import { EmailService } from 'Shared/services/email.service';
import { FeaturesService } from 'Shared/services/features.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { GoogleService } from 'Shared/services/third-parties/google.service';
import { ExperimentsService } from 'Shared/services/experiments.service';

type AuthTab = 'login' | 'register';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-auth-third-party',
  templateUrl: './auth-third-party.component.html'
})
export class AuthThirdPartyComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() showSeparator: boolean = false;
  @Input() selectedTab: AuthTab = 'login';
  @Input() marketingPerference!: boolean;
  @Input() isModal: boolean = false;

  @Output() didLoad = new EventEmitter<boolean>();
  @Output() didSucceed = new EventEmitter<{
    authMethod: string;
  }>();
  @Output() didFail = new EventEmitter<void>();

  cookieConsent$: BehaviorSubject<ICookieConsent>;
  googleCredentials$: Subscription;

  constructor(
    private cookieService: CookieService,
    private userService: UserService,
    private contentService: ContentService,
    private toastr: ToastrService,
    private windowRefService: WindowRefService,
    private emailService: EmailService,
    private featuresService: FeaturesService,
    private analyticsService: AnalyticsService,
    private googleService: GoogleService,
    private experimentsService: ExperimentsService
  ) {}

  /**
   * Check to show cookie consent if third party cta are present and avaiable
   */
  get showCookieConsent(): boolean {
    return this.featuresService.getFeature('FACEBOOK_ACCESS');
  }

  /**
   * Handle window size change and google button resizing
   */
  @HostListener('window:resize', ['$event'])
  onGoogleButtonResize(): void {
    setTimeout((): void => {
      this.googleService.loadLibrary();
    }, 0);
  }

  /**
   * On Init
   */
  ngOnInit(): void {
    this.cookieConsent$ = this.cookieService.cookieConsent$;
    this.showSeparator = this.handleSeparator();
    this.googleService.initLibrary(this.isModal);
    this.googleCredentials$ = this.googleService?.googleCredential$.pipe(distinctUntilChanged()).subscribe((): void => {
      if (!this.isModal) {
        this.onSuccessfullLogin('google');
      }
    });
  }

  /**
   * On after view init
   */
  ngAfterViewInit(): void {
    // SetTimemout used to make sure load event is after view render
    setTimeout((): void => {
      this.googleService.loadLibrary();
    }, 0);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    if (this.googleCredentials$) {
      this.googleCredentials$.unsubscribe();
    }
  }

  /**
   * Handle if fb login or register
   * @param {AuthTab} choice
   */
  onFbAction(choice: AuthTab): Promise<void> {
    return choice === 'login' ? this.fbLogin() : this.fbRegister();
  }

  /**
   * Renew cookie consent
   */
  renewCookieConsent(): void {
    this.cookieService.renewConsent();
  }

  /**
   * Track in heap
   * @param {boolean} hasAccount
   */
  private track(hasAccount: boolean): void {
    this.analyticsService.trackInHeap('authViaFacebook', { hasAccount });
  }

  /**
   * On loading action
   * @param loading
   */
  private onLoadingAction(loading: boolean): void {
    this.didLoad.emit(loading);
  }

  /**
   * Did successfully login
   */
  private onSuccessfullLogin(authMethod: string): void {
    this.didSucceed.emit({ authMethod });
  }

  /**
   * Failed to login
   */
  private onFailedLogin(): void {
    this.didFail.emit();
  }

  /**
   * Set email perference for new register user
   * @param {User} user
   */
  private setEmailPreference(user: User): Promise<void> {
    const configEmailPreference = this.featuresService.getFeature('GDPR');

    user.email.preference = this.marketingPerference
      ? configEmailPreference.optedInEmailPreference
      : configEmailPreference.optOutEmailPreference;

    user.email.consent = {
      copy: (
        this.windowRefService.nativeWindow.document.querySelector('#bw-register-form #marketing span') as HTMLElement
      ).innerText.trim(),
      method: 'Ticked Checkbox',
      location: 'Web - Register - Via Facebook'
    };

    return this.emailService.update(user.email);
  }

  /**
   * Facebook register
   */
  private fbRegister(): Promise<void> {
    this.onLoadingAction(true);
    return this.userService
      .facebookLogin()
      .then((user: User): Promise<void> => {
        this.contentService.getContentSegments();
        this.track(true);
        return this.setEmailPreference(user);
      })
      .then((): void => this.didSucceed.emit({ authMethod: 'facebook' }))
      .catch((): void => {
        this.track(false);
        this.toastr.error(t('js.bwFlashMessage.generic.auth.fb_login_failed'));
        this.onLoadingAction(false);
      });
  }

  /**
   * Facebook login
   */
  private fbLogin(): Promise<void> {
    this.onLoadingAction(true);
    return this.userService
      .facebookLogin()
      .then((): void => {
        this.contentService.getContentSegments();
        this.track(true);
        this.onLoadingAction(false);
        return this.onSuccessfullLogin('facebook');
      })
      .catch((): void => {
        this.track(false);
        this.toastr.error(t('js.bwFlashMessage.generic.auth.fb_login_failed'));
        this.onLoadingAction(false);
        return this.onFailedLogin();
      });
  }

  /**
   * Handle if separator should be shown
   */
  private handleSeparator(): boolean {
    const googleFeature = this.featuresService.getFeature('GOOGLE_ACCESS');
    const facebookFeature = this.featuresService.getFeature('FACEBOOK_ACCESS');

    if ((googleFeature || facebookFeature) && this.showSeparator) {
      return true;
    }

    return false;
  }
}
