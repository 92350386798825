
<div class="bw-auth-password-form" *ngIf="form" id="bw-auth-password-form">
  <ui-box box_space="none" box_space_bottom="default">
    <ui-stack stack_gap="near" stack_direction="x" stack_align="center">
      <ui-box box_space="tiny" box_border_radius="round" box_border_color="primary" box_border_width="primary" box_border_style="solid" box_fill_mode="fit">
        <ui-icon icon_name="user"></ui-icon>
      </ui-box>
      <div class="text-body--default">Welcome back {{identifiedUser}}</div>
    </ui-stack>
  </ui-box>
  <form [formGroup]="form" [class.ng-submitted]="isSubmitted">
    <ui-form-group group_title="Log in or create account" [group_title_hidden]="true">
      <ui-text-input formControlName="password" name="password" id="password" label="Password" type="password" autocomplete="off" [optional]="false">
        <ui-form-errors *ngIf="showValidationMessage" [errors]="form.get('password')?.errors" [error_messages]="{&quot;required&quot;:&quot;Please enter your password.&quot;,&quot;failedLogin&quot;:&quot;Hmm. That’s not the right password. Please check and try again.&quot;,&quot;invalid&quot;:&quot;Hmm. That’s not the right password. Please check and try again.&quot;}"></ui-form-errors>
      </ui-text-input>
    </ui-form-group>
    <ui-box box_space="near" box_space_left="none" box_space_right="none">
      <ui-stack *ngIf="!submitError || submitError?.code!=='tooManyRequests'" stack_align="end"><a class="text-body--supporting text-link--paragraph" button_type="tertiary" button_size="small" (click)="resetPassword()" bwTrackAs="component.auth-login.auto_key.string_5">Forgot password?</a>
        <ui-alert *ngIf="requestResetPasswordSent" tone="positive">
          <ui-alert-content>We’ve sent you an email with a link to reset your password.</ui-alert-content>
        </ui-alert>
      </ui-stack>
    </ui-box>
    <ui-alert *ngIf="showFailedLogin &amp;&amp; !isInvalidEmailOrPassword &amp;&amp; submitError">
      <ui-alert-title>{{submitError?.title}}</ui-alert-title>
      <ui-alert-content>{{submitError?.message}}</ui-alert-content>
    </ui-alert>
    <ng-container *ngIf="optinLoginExperiment">
      <div class="login__forgot-password" *ngIf="!isJoiningRewards">
        <ui-checkbox *ngIf="emailUser?.preference === 0" formControlName="consent" checkbox_type="secondary" id="consent" label="Keep me updated with special offers, exciting products and news from Bloom &amp; Wild."><span>You can view our <a href="/privacy-statement" target="_blank">privacy statement here</a> and unsubscribe at any time.</span></ui-checkbox>
      </div>
    </ng-container>
    <ng-container *ngIf="isJoiningRewards">
      <ui-checkbox *ngIf="emailUser?.preference === 0" formControlName="consent" checkbox_type="secondary" id="consent" label="Keep me updated via email with Bloom &amp; Wild Rewards, my birthday treat and VIP perks, as well as special offers and news about our products."><span>You can view our <a class="text-link--paragraph" href="/privacy-statement" target="_blank">privacy statement here</a> (to learn how we handle your personal data) and unsubscribe at any time.</span></ui-checkbox>
      <ui-box *ngIf="origin === 'checkout' &amp;&amp; showTandC" box_space="none" box_space_bottom="default">
        <p class="text-body--supporting text-color--supporting">You can <a class="text-link--paragraph" href="/bw-rewards-terms" target="_blank">read our full T&Cs here</a>.</p>
      </ui-box>
    </ng-container>
  </form>
  <ui-box box_space="none" box_space_top="near">
    <ui-button button_type="primary" button_full_width="true" bwtrackas="checkout.userDetails.createAccount" (clicked)="submit()" (keydown.enter)="submit()">Continue</ui-button>
  </ui-box>
</div>
<bw-loading-spinner *ngIf="loading" [fullscreen]="false" message="Logging in..."></bw-loading-spinner>