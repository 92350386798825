import { Component, Input } from '@angular/core';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-faq',
  templateUrl: './faq.component.html'
})
export class FaqComponent {
  @Input() delivered: boolean;
  constructor() {}
}
