
<div class="flagSelector" *ngIf="context === 'navbar'">
  <!-- Dropdown style-->
  <div class="flagSelector__as-dropdown">
    <div class="label mr-1 hidden-xs hidden-sm bw-font-body-small">Ship to:</div>
    <div class="active-country" [popper]="popper1content" [popperTrigger]="popperTrigger" [popperHideOnClickOutside]="true" [popperHideOnScroll]="true" [popperPlacement]="popperPlacement" [popperApplyClass]="'popper__country-select'" [popperApplyArrowClass]="'popper__arrow-left'" (popperOnShown)="dropdownShown=true;" (popperOnHidden)="dropdownShown=false;" [ngClass]="{'dropdown-shown': dropdownShown}"><img class="flag" src="assets/brand-icons/flag-{{siteCountry?.codes[0]}}.svg" alt="{{siteCountry?.name}}" title="{{siteCountry?.name}}" width="25">
      <div class="icon__chevron"></div>
    </div>
    <popper-content #popper1content>
      <div class="country" *ngFor="let country of countries" (click)="i18nModal(country)" [ngClass]="{'selected': siteCountry.id === country.id}">
        <bw-icon class="flag" [iconType]="$any(country.codes[0])" altText="{{country?.name}}"></bw-icon>
        <div class="icon__tick"></div>
      </div>
    </popper-content>
  </div>
</div>
<!-- when the slector is opened from checkout-->
<div class="checkoutSelector" *ngIf="context === 'checkout'">
  <p>Invalid {{siteCountry.name | textReplace:'United Kingdom':'UK'}} postcode. If you're looking to ship outside of {{siteCountry.id === 1 ? 'the' : '' }} {{siteCountry.name | textReplace:'United Kingdom':'UK'}}, then you will need to <bw-button buttonStyle="text-link" (click)="i18nModal(siteCountry)">change your delivery country.</bw-button></p>
</div>
<div class="checkoutSelector" *ngIf="context === 'checkout-ie'">
  <p>Invalid Eircode. If you're looking to ship outside of Ireland, then you will need to <bw-button buttonStyle="text-link" (click)="i18nModal(siteCountry)">change your delivery country.</bw-button></p>
</div>