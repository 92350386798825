/**
 * Extract the data from the help center from HTML to attributes, which we can then pick up in the help-center component
 * @param item
 */
export default function bwHelpCenterInterceptor(item): any {
  // Loop through the tabs, then the links
  const tabs = [].slice.call(item.querySelectorAll('bw-help-tab')).map(tab => {
    const links = [].slice.call(tab.querySelectorAll('bw-help-link')).map(link => ({
      href: link.getAttribute('href'),
      title: link.getAttribute('title')
    }));

    return {
      links: links.slice(),
      title: tab.getAttribute('title'),
      icon: tab.getAttribute('icon'),
      href: tab.getAttribute('href')
    };
  });

  // Then delete all the insides, leaving just the component and the attribute data
  item.innerHTML = '';
  item.setAttribute('tabs', JSON.stringify(tabs));

  return item;
}
