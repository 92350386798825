import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from 'Shared/services/config.service';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { ImageRole } from 'Project/content/components/contentful-image/contentful-image';
import { WindowRefService } from '../services/window.service';
@Pipe({
  name: 'imageSize'
})
export class ImageSizePipe implements PipeTransform {
  constructor(
    private configService: ConfigService,
    private domUtilsService: DomUtilsService,
    private windowRef: WindowRefService
  ) {}

  imageRoleVariables = {
    'product-main': '--ui__main-image__ratio-',
    'product-secondary': '--ui__secondary-image__ratio-',
    'hero-banner': '--ui__hero-banner-image__ratio-',
    'hero-banner-mobile': '--ui__hero-banner-mobile-image__ratio-',
    'expressive-list-even': '--ui__expressive-list-even-image__ratio-',
    'expressive-list-odd': '--ui__expressive-list-odd-image__ratio-',
    'story-block': '--ui__story-block-image__ratio-',
    'generic-content-block-item': '--ui__generic-content-block-item__ratio-',
    'blog-hero': '--ui__blog-hero-image__ratio-',
    'blog-item-rectangle': '--ui__blog-item-rectangle-image__ratio-',
    'blog-item-square': '--ui__blog-item-square-image__ratio-'
  };

  WEBP_FORMAT_STRING: string = 'format(webp)';
  JPEG_FORMAT_STRING: string = 'format(jpeg)';

  getRequiredHeight(imageWidth: number, imageRole: ImageRole): number {
    if (imageRole !== 'default') {
      const rootElement = this.windowRef.nativeWindow.document.documentElement;

      const arWidth: number = parseInt(
        this.windowRef.nativeWindow
          .getComputedStyle(rootElement)
          .getPropertyValue(`${this.imageRoleVariables[imageRole]}width`),
        10
      );
      const arHeight: number = parseInt(
        this.windowRef.nativeWindow
          .getComputedStyle(rootElement)
          .getPropertyValue(`${this.imageRoleVariables[imageRole]}height`),
        10
      );

      return Math.floor((imageWidth / arWidth) * arHeight);
    }

    return imageWidth;
  }

  transform(
    imageUrl: string,
    width: string | number,
    height?: string,
    imageRole?: ImageRole,
    quality?: number
  ): string {
    const format = this.domUtilsService.supportsWebP
      ? this.WEBP_FORMAT_STRING
      : this.JPEG_FORMAT_STRING;
    const mediaUrl = this.configService.getConfig().mediaUrl;
    // height used to determine if image is not wanted in square ratio, 0 refers to auto
    // For more information: https://thumbor.readthedocs.io/en/latest/

    const qualityString = quality !== undefined && quality !== 100 ? `:quality(${quality})` : ``;

    if (height === 'auto') {
      return `${mediaUrl}/v1/${width}x0/filters:${format}${qualityString}/${imageUrl}`;
    }

    if (imageRole && imageRole !== 'default') {
      const calculatedHeight = this.getRequiredHeight(
        typeof width === 'string' ? parseInt(width, 10) : width,
        imageRole
      );
      return `${mediaUrl}/v1/${width}x${calculatedHeight}/filters:${format}${qualityString}/${imageUrl}`;
    }

    // AspectRatioHeight is used when an image is uploaded as a square ratio, but requires a different ratio (bloomon)
    return `${mediaUrl}/v1/${width}x${width}/filters:${format}${qualityString}/${imageUrl}`;
  }
}
