
<div class="mega-nav-mobile-child-v2">
  <!-- HEADER SECTION-->
  <div class="mega-nav-mobile-child-v2__header" (click)="closeNav()">
    <div class="mega-nav-mobile-child-v2__header--cta">
      <bw-icon iconType="chevron-left"></bw-icon>
    </div>
    <div class="mega-nav-mobile-child-v2__header--title"><span>{{childSectionLinks?.name}}</span></div>
  </div>
  <!-- MAIN CONTENT SECTION-->
  <div class="mega-nav-mobile-child-v2__content">
    <div class="mega-nav-mobile-child-v2__content--all-cta"><a [attr.href]="childSectionLinks?.parentSectionLink" aria-label="See all" bwTrackAs="component.mega-nav-mobile-child.see-all-cta" tabindex="0"><span>See all</span></a></div>
    <ul class="mega-nav-mobile-child-v2__content--links" *ngFor="let section of (childSection || {}).sections">
      <!-- SECTION HEADER LINK-->
      <li class="mega-nav-mobile-child-v2__content--section-link" *ngIf="section.name || section.sectionType === 'subnav'"><a *ngIf="section.sectionType === 'subnav'" (click)="navClicked(section)" [attr.href]="section.url" [attr.aria-label]="section.name + '.'" tabindex="0"><span>{{section.name}}
            <!-- New Nav Icon--><img [ngClass]="section.styles" src="/assets/brand-icons/new-label.svg"></span></a></li>
      <!-- SECTION CHILD LINKS-->
      <ng-container *ngIf="section.sectionType === 'subnav'">
        <li class="mega-nav-mobile-child-v2__content--section-child-link" *ngFor="let link of section.links"><a [attr.href]="link.url" [attr.aria-label]="link.name + ' ' + link.styles + '.'" tabindex="0"><span (click)="navClicked(link);">{{!link.shortCopy ? link.name : link.shortCopy}}
              <!-- New Nav Icon--><img [ngClass]="link.styles" src="/assets/brand-icons/new-label.svg"></span></a></li>
      </ng-container>
      <!-- CONTENT SPOT-->
      <bw-content-spot *ngIf="section.sectionType === 'content'" (click)="navIteractionTracking(section)" contentId="megnav" fragment="#{{section.name}}"></bw-content-spot>
    </ul>
  </div>
</div>