
<div class="position-relative">
  <div class="modal-header">
    <div class="modal-title" *ngIf="!modalTitleOverride &amp;&amp; isUpdating">Update Address</div>
    <div class="modal-title" *ngIf="!modalTitleOverride &amp;&amp; !isUpdating">Create Address</div>
    <div class="modal-title" *ngIf="modalTitleOverride">{{modalTitleOverride}}</div>
    <button class="close" aria-label="close" (click)="onCancel()"><span aria-hidden="true">&times;</span></button>
  </div>
  <form [formGroup]="modalForm">
    <div class="modal-body" #bwModal>
      <bw-address-form-new [bwParentForm]="modalForm" bwFormControlName="address" [showVatField]="showVatField" [hideNameField]="hideNameField" [address]="address" [limitCountry]="limitCountry" [showChangeCountryOnInvalidPostcode]="showChangeCountryOnInvalidPostcode" [shippingOption]="shippingOption" [phoneNumberRequired]="showPhoneField"></bw-address-form-new>
    </div>
    <div class="modal-footer">
      <bw-button buttonStyle="modal-secondary" size="medium" (click)="onCancel()" bwTrackAs="account.modals.address.buttons.cancel">Cancel</bw-button>
      <bw-button buttonStyle="modal-primary" size="medium" (click)="onSubmit()" bwTrackAs="account.modals.address.buttons.submit">Save address</bw-button>
    </div>
  </form>
  <bw-loading-spinner [fullscreen]="false" *ngIf="loading"></bw-loading-spinner>
</div>