import { Injectable } from '@angular/core';
import { StateService } from 'Shared/services/state.service';
import { User, UserService } from 'Shared/services/user.service';
import { AuthModalComponent } from 'Shared/components/modals/auth-modal/auth-modal.component';
import { ModalService } from 'Shared/services/modal.service';
import { t } from 'Shared/utils/translations';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { LoyaltyService } from 'Shared/services/loyalty.service';
import { RewardsConfirmationModalComponent } from 'Account/components/rewards/rewards-confirmation-modal/rewards-confirmation-modal.component';
import { LoyaltyMembership } from 'Shared/classes/loyalty';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { ErrorItem } from 'Shared/classes/error';

@Injectable()
export class HasRewardsLoggedIn {
  rewardsSignUpLocation: string;
  rewardsSignUpSource: string;

  constructor(
    private userService: UserService,
    private modalService: ModalService,
    public experimentService: ExperimentsService,
    private stateService: StateService,
    private loyaltyService: LoyaltyService,
    private analyticsService: AnalyticsService
  ) {}
  /**
   * Can activate
   */
  canActivate(): Promise<void | RewardsConfirmationModalComponent> {
    const user = this.userService.getUser();

    this.trackHeapEvent('joinRewardsSignUp', user);

    if (user?.loggedIn) {
      return this.loyaltyService
        .joinLoyaltyMembership()
        .then((): Promise<any> => this.stateService.goToUrl(t('js.routing.rewards_route')))
        .then((): Promise<void | RewardsConfirmationModalComponent> => this.openConfirm(false))
        .catch((error: ErrorItem): Promise<void | RewardsConfirmationModalComponent> => {
          if (error.code === 'unprocessableEntity') {
            return this.openConfirm(true);
          }
          this.stateService.goToUrl(t('js.routing.rewards_route'));
          return Promise.reject(error);
        });
    } else {
      return this.userService
        .authenticate()
        .catch((): Promise<{ email?: string }> => this.showLoginModal())
        .then((): Promise<LoyaltyMembership> => this.loyaltyService.joinLoyaltyMembership())
        .then((): Promise<User> => this.userService.refreshUser())
        .then((): Promise<any> => this.stateService.goToUrl(t('js.routing.rewards_route')))
        .then((): Promise<void | RewardsConfirmationModalComponent> => this.openConfirm(false))
        .catch((error: ErrorItem): Promise<void | RewardsConfirmationModalComponent> => {
          if (error.code === 'unprocessableEntity') {
            return this.openConfirm(true);
          }
          this.stateService.goToUrl(t('js.routing.rewards_route'));
          return Promise.reject(error);
        });
    }
  }

  /**
   * Show the login modal
   */
  private showLoginModal(): Promise<{ email?: string }> {
    return this.modalService.show(AuthModalComponent, {
      initialState: {
        title: t('js.guard.has-logged-in.title'),
        origin: 'account',
        fullOrigin: 'webAccountLaunchModal'
      },
      ignoreBackdropClick: true
    });
  }

  private openConfirm(existingMember: boolean): Promise<void | RewardsConfirmationModalComponent> {
    return this.modalService
      .show(RewardsConfirmationModalComponent, {
        initialState: {
          existingMember: existingMember,
          heapData: {
            rewardsSignUpLocation: this.rewardsSignUpLocation ?? undefined,
            rewardsSignUpSource: this.rewardsSignUpSource ?? undefined
          }
        },
        trackingKey: existingMember ? 'open-successfulRewardsExistingMemberModal' : 'open-successfulRewardsJoinModal',
        keyboard: true
      })
      .catch((): void => {
        this.stateService.goToUrl(t('js.routing.rewards_route'));
      });
  }

  /**
   * Track heap events
   * @param {string} eventName
   * @param {unknown[]} args
   */
  private trackHeapEvent(eventName: string, user: User, data?: object): void {
    const state = this.stateService.getInitial();

    const sourceClickKey = Object.keys(this.analyticsService.tracked).filter((key): string => {
      if (this.analyticsService.tracked[key] !== undefined && this.analyticsService.tracked[key].event === 'web:ui:click') {
        return key;
      }
    });

    const sourceClick = sourceClickKey.length > 0 ? this.analyticsService.tracked[sourceClickKey[0]] : undefined;
    this.rewardsSignUpLocation = sourceClick ? state.url + ' & ' + sourceClick?.category + ' - ' + sourceClick?.label : state.url;
    this.rewardsSignUpSource = state.params?.utm_medium;

    const userData = {
      utm_medium: state.params?.utm_medium ?? undefined,
      utm_campaign: state.params?.utm_campaign ?? undefined,
      utm_content: state.params?.utm_content ?? undefined,
      utm_source: state.params?.utm_source ?? undefined,
      rewardsSignUpSource: this.rewardsSignUpSource ?? undefined,
      rewardsSignUpLocation: this.rewardsSignUpLocation ?? undefined
    };

    data = { user, ...userData, ...data };
    this.analyticsService.trackInHeap(eventName, data);
  }
}
