import { Injectable, NgZone } from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { ConfigService } from 'Shared/services/config.service';
import { WindowRefService } from 'Shared/services/window.service';
import { Purchase } from 'Shared/classes/purchase';
import { User } from 'Shared/classes/user';
/**
 * Trusted Shops Loader
 * Docs - http://www.trustedshops.co.uk/support/trustbadge/trustbadge-custom/
 * and - http://support.trustedshops.com/en/apps/trustbadge-integration?shop_id=&variant=&yOffset=
 */
@Injectable({
  providedIn: 'root'
})
export class TrustedShopsService {
  window: any;
  serviceInitialized: boolean = false;
  debug: boolean = false;

  constructor(
    private domUtils: DomUtilsService,
    private configService: ConfigService,
    private windowRef: WindowRefService,
    private _ngZone: NgZone
  ) {
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<trustedshops>', ...args);
    }
  }

  /**
   * Run the trusted shops Code
   * @param args
   */
  trustedshops(...args): void {
    if (this.window['trustbadge'] && this.serviceInitialized) {
      this.window['trustbadge'](...args);
      this.log(...args);
    }
  }

  /**
   * Track the purchase
   * @param purchase
   * @param user
   */
  trackPurchase(purchase: Purchase, user: User): void {
    // Already there - Remove it
    if (this.serviceInitialized) {
      return this._ngZone.runOutsideAngular(() => {
        this.removeTracker();
        this.hideCheckoutTrustcard();
        const order = purchase.orders[0];
        // 'tsCheckoutOrderPaymentType' doesn't actually matter
        const el = document.createElement('div');
        el.innerHTML = `
        <div id="trustedShopsCheckout" style="display: none;">
        <span id="tsCheckoutOrderNr">${order.id}</span>
        <span id="tsCheckoutBuyerEmail">${user.email.address}</span>
        <span id="tsCheckoutOrderAmount">${order.price.price / 100}</span>
        <span id="tsCheckoutOrderCurrency">${order.price.currency}</span>
        <span id="tsCheckoutOrderPaymentType">PAYPAL</span>
        <span id="tsCheckoutOrderEstDeliveryDate">${order.firstDeliveryDate.format(
          'YYYY-MM-DD'
        )}</span>
        </div>`;
        document.body.appendChild(el);
        this.reInit();
        this.log(`Tracking the purchase: ${purchase}, order: ${order}`);
      });
    }
  }

  removeTracker(): void {
    const elem = document.querySelector('#trustedShopsCheckout');
    if (elem) {
      elem.parentNode.removeChild(elem);
    }
  }

  /**
   * To hide the checkout Trustcard, we need to create a custom div with ID customCheckoutDiv
   */
  hideCheckoutTrustcard(): void {
    const el = document.createElement('div');
    el.id = 'customCheckoutDiv';
    el.style.display = 'none';
    document.body.appendChild(el);
  }

  /**
   * Re-init the data - this will then send it to trusted shops
   */
  reInit(): void {
    try {
      if (this.window.trustbadge) {
        this.window.trustbadge.remove();
        this.window.trustbadge.reInitialize();
      }
    } catch (e) {
      console.warn(e.toString());
    }
  }

  init(): Promise<any> {
    return this._ngZone.runOutsideAngular(() => {
      const config = this.configService.getConfig();

      // This is the weird way trusted shops applies it's config
      this.window._tsConfig = {
        yOffset: '' /* offset from page bottom */,
        variant: 'custom_reviews' /* default, reviews, custom, custom_reviews */,
        customElementId:
          'trustedShopContainer' /* required for variants custom and custom_reviews */,
        trustcardDirection:
          '' /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */,
        customBadgeWidth: '' /* for custom variants: 40 - 90 (in pixels) */,
        customBadgeHeight: '' /* for custom variants: 40 - 90 (in pixels) */,
        disableResponsive: 'true' /* deactivate responsive behaviour */,
        disableTrustbadge: 'false' /* deactivate trustbadge */,
        customCheckoutElementId: 'customCheckoutDiv'
      };
      return this.domUtils
        .loadScript(`//widgets.trustedshops.com/js/${config.trustedShopId}.js`, 'trustedshops')
        .then(() => {
          this.serviceInitialized = true;
          this.trustedshops('load');
        });
    });
  }
}
