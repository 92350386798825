import {
  Injectable,
  ComponentFactoryResolver,
  Injector,
  ApplicationRef,
  EmbeddedViewRef,
  ComponentRef
} from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { ModalBaseComponent } from 'Shared/components/modal-base/modal-base.component';
import { FullscreenLoadingSpinnerService } from 'Shared/services/fullscreen-loading-spinner.service';

@Injectable({
  providedIn: 'root'
})
export class ModalDisplayService {
  ref: any;

  constructor(
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private windowRef: WindowRefService,
    private fullScreenLoadingSpinner: FullscreenLoadingSpinnerService
  ) {}

  private injectInitialSate(componentRef: any, initialState: any): void {
    Object.keys(initialState).forEach((key) => {
      try {
        componentRef.instance[key] = initialState[key];
      } catch (e) {}
    });
    return componentRef;
  }

  create(component: any, options?): Promise<ComponentRef<ModalBaseComponent>> {
    const factory = this.resolver.resolveComponentFactory(ModalBaseComponent);
    const resolvedComponent = this.resolveComponent(component, options);
    const ngContent = resolvedComponent.elementArray;
    const modalContentChild = resolvedComponent.ref;
    const componentRef = factory.create(this.injector, ngContent);

    if (options && options.initialState) {
      this.injectInitialSate(componentRef, options.initialState);
    }

    componentRef.instance.modalRef = componentRef as ComponentRef<any>;
    componentRef.instance.childRef = modalContentChild;

    componentRef.instance.historyUrl = options.historyUrl || '';
    componentRef.instance.class = options.class || '';
    componentRef.instance.ignoreBackdropClick = options.ignoreBackdropClick;
    componentRef.instance.animationDirection = options.animationDirection;
    componentRef.instance.underNav = options.underNav;
    componentRef.instance.closeOnStateChange = options.closeOnStateChange;
    componentRef.instance.keyboard = options.keyboard;
    componentRef.instance.modalName = options.modalName;
    componentRef.instance.useNativeScroll = options.useNativeScroll;

    let resolver;
    if (resolvedComponent.ref?.instance?.resolver) {
      resolver = () => {
        return resolvedComponent.ref?.instance?.resolver();
      };
      this.fullScreenLoadingSpinner.show();
    }

    const p = resolver ? resolver() : Promise.resolve();
    return p
      .then(() => {
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        this.windowRef.nativeWindow.document.body.appendChild(domElem);
        componentRef.hostView.detectChanges();

        if (resolver) {
          this.fullScreenLoadingSpinner.hide();
        }

        return componentRef as ComponentRef<ModalBaseComponent>;
      })
      .catch((e) => {
        if (resolver) {
          this.fullScreenLoadingSpinner.hide();
        }

        return Promise.reject(e);
      });
  }

  destroy(modalRef: ComponentRef<ModalBaseComponent>): void {
    if (modalRef) {
      modalRef.destroy();
      modalRef.instance.childRef.destroy();
      this.applicationRef.detachView(modalRef.hostView);
    }
  }

  resolveComponent(component: any, options: any): any {
    const factory = this.resolver.resolveComponentFactory(component);
    const componentRef = factory.create(this.injector) as ComponentRef<any>;

    if (options && options.initialState) {
      this.injectInitialSate(componentRef, options.initialState);
    }

    componentRef.hostView.detectChanges();
    this.applicationRef.attachView(componentRef.hostView);
    return {
      ref: componentRef,
      elementArray: [[componentRef.location.nativeElement]]
    };
  }
}
