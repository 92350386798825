import { Injectable } from '@angular/core';
import { ReviewsModelService, ReviewsResponse } from 'Shared/models/reviews-model.service';
import { Country } from 'Shared/services/country.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  constructor(private reviewsModelService: ReviewsModelService) {}

  /**
   * Get all reviews
   * @param country
   * @param limit
   */
  getAll(country: Country, limit?: number): Promise<ReviewsResponse> {
    return this.reviewsModelService.getAllReviews(country, limit);
  }

  /**
   * Get review stats
   * @param country
   */
  getReviewStats(country: Country): Promise<any> {
    return this.reviewsModelService.getReviewStats(country);
  }

  /**
   * Get location based reviews
   * @param country
   * @param longitude
   * @param latitude
   */
  getLocationBasedReviews(country: Country, longitude: number, latitude: number): Promise<any> {
    return this.reviewsModelService.getLocationBasedReviews(country, longitude, latitude);
  }
}
