
<div class="bw-rewards-info-modal">
  <div class="modal-header">
    <h4 class="modal-title text-heading--functional--secondary">Bloom & Wild Rewards</h4>
    <ui-button button_size="small" button_type="tertiary" button_icon="close" button_icon_placement="iconOnly" (clicked)="onCancel()"><span aria-hidden="true">&times;</span></ui-button>
  </div>
  <div class="modal-body" #bwModal>
    <ui-stack>
      <p>Love caring wildly? Join the club. It’s our little way of saying thank you for being so thoughtful.</p>
      <ui-stack stack_direction="x" stack_gap="tight" stack_align="center">
        <ui-image class="bw-rewards-info-modal__image" image_src="https://images.ctfassets.net/1d9ajmvjpuhw/7uUpD9IhGJdEJjYHZvcgmH/374912d4e3d646cfb8746b337ad5cb58/rewards-earn-points.svg" loading="auto" image_alt_text="Earn points every time you shop"></ui-image>
        <ui-stack stack_gap="tight">
          <p class="text-body--default text-body--emphasis">Earn points every time you shop</p>
          <p class="text-body--supporting">And unlock vouchers. (They add up fast.)</p>
        </ui-stack>
      </ui-stack>
      <ui-stack stack_direction="x" stack_gap="tight" stack_align="center">
        <ui-image class="bw-rewards-info-modal__image" image_src="https://images.ctfassets.net/1d9ajmvjpuhw/5qbd5zgq7qlgwJG1C7rj3c/96e25d35b8a0c7df4a169be194f6dc73/rewards-birthday-treat.svg" loading="auto" image_alt_text="Birthday treat"></ui-image>
        <ui-stack stack_gap="tight">
          <p class="text-body--default text-body--emphasis">Birthday treat</p>
          <p class="text-body--supporting">50% off anything you fancy.</p>
        </ui-stack>
      </ui-stack>
      <ui-stack stack_direction="x" stack_gap="tight" stack_align="center">
        <ui-image class="bw-rewards-info-modal__image" image_src="https://images.ctfassets.net/1d9ajmvjpuhw/59c51YAfI1osnm8yjwH1JT/233cbdbb49a35efc1cb1d5e9304ee117/rewards-sneak-peeks.svg" loading="auto" image_alt_text="Exclusive sneak peeks"></ui-image>
        <ui-stack stack_gap="tight">
          <p class="text-body--default text-body--emphasis">Exclusive sneak peeks</p>
          <p class="text-body--supporting">Be the first to shop new launches.</p>
        </ui-stack>
      </ui-stack>
      <ui-stack stack_direction="x" stack_gap="tight" stack_align="center">
        <ui-image class="bw-rewards-info-modal__image" image_src="https://images.ctfassets.net/1d9ajmvjpuhw/4UIqeshN9cTdSUqAQ1goij/fde751ca765a0799791e9bec6962be7f/rewards-secret-sales.svg" loading="auto" image_alt_text="Secret sales"></ui-image>
        <ui-stack stack_gap="tight">
          <p class="text-body--default text-body--emphasis">Secret sales</p>
          <p class="text-body--supporting">And member-only discounts.</p>
        </ui-stack>
      </ui-stack>
      <p class="text-body--supporting text-color--supporting">You can <a class="text-link--paragraph" href="/bw-rewards-terms" target="_blank">read our full T&Cs here</a>.</p>
    </ui-stack>
  </div>
</div>