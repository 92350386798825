import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Review } from 'Shared/classes/review';
import { ReviewsService } from 'Shared/services/reviews.service';
import { t, TranslationKey } from 'Shared/utils/translations';
import { CountryService } from 'Shared/services/country.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-location-reviews',
  templateUrl: './location-reviews.component.html'
})
export class LocationReviewsComponent implements OnInit {
  @Input()
  limit: number; // limit from location pages
  @Input()
  source: string = 'reviews';
  @Input()
  longitude: string;
  @Input()
  latitude: string;
  @Input()
  county: string;
  @Input()
  town: string;
  @Input()
  description: string;

  review: Review[];
  address: any;
  reviewStats: any;

  constructor(private reviewService: ReviewsService, private countryService: CountryService) {}

  ngOnInit(): Promise<any> {
    const lat = parseFloat(this.latitude);
    const long = parseFloat(this.longitude);
    this.address = {
      town: this.town,
      county: this.county,
      description: this.description
    };

    const shippingCountry = this.countryService.forShipping;
    return this.reviewService.getLocationBasedReviews(shippingCountry, long, lat).then(res => {
      this.review = this.limit ? res.reviews.slice(0, this.limit) : res.reviews;
      this.reviewStats = {
        averageRating: res.averageRating,
        count: res.count
      };
    });
  }

  randomiseText(review: any): string {
    const key_id = review.review.length % 4;
    const key = `js.components.location-reviews.review-action-text_${key_id <= 3 ? key_id : 0}`;
    return t(key as TranslationKey);
  }
}
