import { Component, Input, OnInit } from '@angular/core';
import { CountryService } from 'Shared/services/country.service';
import { Product } from 'Shared/classes/product';
import { StateGoParams, StateName, StateService } from 'Shared/services/state.service';
import { ProductService } from 'Checkout/services/product.service';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { FeaturesService } from 'Shared/services/features.service';
import { CheckoutService } from 'Checkout/services/checkout.service';
import { Error } from 'Shared/classes/error';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-gift-voucher-picker',
  templateUrl: './gift-voucher-picker.component.html'
})
export class GiftVoucherPickerComponent implements OnInit {
  // inputs in lowercase for contentful rendering
  @Input() giftvouchertype: 'physical' | 'digital';
  @Input() defaultprice: number;
  @Input() packagingcopy: string;
  giftVoucherSkus: Product[];
  giftVoucherImage: string;
  selectedGiftVoucher: Product;
  isLoading: boolean = false;
  radioForm: FormGroup;

  constructor(
    private productService: ProductService,
    private countryService: CountryService,
    private stateService: StateService,
    private analyticsService: AnalyticsService,
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    private featureService: FeaturesService,
    private checkoutService: CheckoutService
  ) {}

  /**
   * @description On init
   * @returns {Promise<void>}
   */
  ngOnInit(): Promise<void> {
    this.giftVoucherImage = this.featureService.getFeature('GIFT_VOUCHERS').image ?? '';
    this.isLoading = true;

    this.radioForm = this.formBuilder.group({
      voucherValues: new FormControl(this.featureService.getFeature('GIFT_VOUCHERS').defaultVoucherValue)
    });

    return this.getGiftVouchers();
  }

  /**
   * Set selected Gift Voucher
   * @param giftVoucher
   */
  setGiftVoucher(giftVoucher: Product): void {
    this.selectedGiftVoucher = giftVoucher;
  }

  /**
   * On SEND click
   */
  onGiftVoucherSend(): void {
    const stateName = this.checkoutService.getCheckoutStartingPoint(this.selectedGiftVoucher) as StateName;

    this.stateService.go(stateName, {
      data: {
        product: this.selectedGiftVoucher,
        params: {}
      }
    } as StateGoParams<typeof stateName>);
  }

  /**
   * @description submit product selection
   */
  submit(): void {
    const selectedProduct = this.giftVoucherSkus.find(
      (item): boolean => item.getPrice().price / 100 === Number(this.radioForm.value.voucherValues)
    );
    this.stateService.go(
      'checkout.start' as StateName,
      {
        data: {
          product: selectedProduct,
          params: {}
        }
      } as StateGoParams<'checkout.start'>
    );
  }

  /**
   * Get Products List
   */
  getGiftVouchers(): Promise<void> {
    const orderIndex = 1;

    return this.productService
      .getAvailableProducts(this.countryService.forShipping, orderIndex)
      .then((products): void => {
        const productType = this.giftvouchertype === 'physical' ? 'physical_gift_voucher' : 'digital_gift_voucher';
        this.giftVoucherSkus = products
          .filter((p): boolean => p.type === productType)
          .sort((a, b): number => a.getPrice().price - b.getPrice().price);

        const defaultPrice = this.defaultprice;

        this.selectedGiftVoucher =
          this.giftVoucherSkus.find((item): boolean => item.getPrice().price / 100 === defaultPrice) || this.giftVoucherSkus[0];

        this.analyticsService.trackMultipleProductDetailViewed(this.giftVoucherSkus);

        this.isLoading = false;
      })
      .catch((e: Error): void => {
        this.toaster.error(e.message, e.title);
      });
  }
}
