import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { WindowRefService } from 'Shared/services/window.service';
import { environment } from 'Environments/environment';
import { Purchase } from 'Project/shared/classes/purchase';
import { UserService, User } from 'Shared/services/user.service';
import { Product } from 'Shared/classes/product';
import { Order } from 'Shared/classes/order';
import { NavAnalyticsInfo } from 'Shared/classes/mega-nav-item';
import { ConfigService } from 'Shared/services/config.service';
import { CountryService } from 'Shared/services/country.service';
import { CurrencyCode } from 'Shared/classes/price';
import { FilterItem } from 'Shared/classes/filter';
import { ExperimentOptions } from 'Project/shared/classes/experiment';
import { ICookieConsent } from './cookie.service';
import { FeaturesService } from '../features.service';

export interface ga4DataInterface {
  brandId?: string;
  locale?: string;
  clientId?: string;
  deliveryCountry?: string;
  deliveryCountryId?: number;
  send_to?: string;
  user_id?: string;
  slug?: string;
  status_login?: Boolean;
  currency?: CurrencyCode;
  value?: string | number;
  items?: ga4DataInterfaceItem[];
  coupon?: string;
  shipping_tier?: string;
  coupon_value?: string;
  user_action?: string;
  isValid?: boolean;
  productIds?: Number[];
  transaction_id?: number;
  shipping?: number;
  option_selected?: string;
  addedFiltersAlphabetical?: FilterItem[];
  addedFilters?: FilterItem[];
  activeFilters?: FilterItem[];
  payment_type?: string;
  method?: string;
  modalType?: string;
  form_name?: string;
  field_name?: string;
  experiment_id?: string;
  experiment_name?: string;
  variation_id?: string | number;
  eventAction?: string;
  eventCategory?: string;
  eventLabel?: string;
  eventValue?: string;
  flower_size?: string;
  page_location?: string;
  page_path?: string;
  page_referrer?: string;
}

export interface ga4DataInterfaceItem {
  item_id?: number;
  item_name?: string;
  currency?: CurrencyCode;
  item_category?: string;
  item_variant?: string;
  price?: string | number;
  item_list_id?: string;
  item_list_name?: string;
  quantity?: number;
}

export type FormName = 'contact us';
export type FieldName =
  | 'fullName'
  | 'email'
  | 'orderNumber'
  | 'postCode'
  | 'deliveryAddress'
  | 'quality'
  | 'delivery'
  | 'your question'
  | 'description'
  | 'question about';
@Injectable({
  providedIn: 'root'
})
export class GtagServiceGA4 {
  private serviceInitialized: boolean = false;
  private window: any;
  private debug: boolean = false;
  private key: string;
  private experiments: ExperimentOptions[] = [];
  user: User;
  clientId: string;

  constructor(
    private domUtils: DomUtilsService,
    private windowRef: WindowRefService,
    private configService: ConfigService,
    private countryService: CountryService,
    private userService: UserService,
    private featuresService: FeaturesService
  ) {
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
    this.key = environment.gtagGA4ConversionId;
  }

  /**
   * Sanitize URL
   * @param url
   * @returns
   */
  private sanitizeUrl(url: string): string {
    if ((url && url.startsWith('https://www.')) || url.startsWith('https://')) {
      // Parse the URL
      const urlObj = new URL(url);
      // Remove the token, email and fullname from the URL
      urlObj.searchParams.delete('token');
      urlObj.searchParams.delete('email');
      urlObj.searchParams.delete('fullname');

      return urlObj.href;
    }
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<ga4>', ...args);
    }
  }

  /**
   * GA4 Call
   * @param fnName
   * @param args
   */
  ga4(fnName: string, ...args): void {
    try {
      if (this.serviceInitialized && this.window['gtag']) {
        this.window.gtag(fnName, ...args);

        if (this.debug) {
          console.log('<ga4>', fnName, ...args);
        }
      }
    } catch (e) {}
  }

  logAdvancedEvent(fnName: string, eventName: string, ga4Data: ga4DataInterface): void {
    const config = this.configService.getConfig();
    this.user = this.userService.getUser();

    // clone the object
    const params = { ...ga4Data };

    const isUserLoggedIn = this.user.isLoggedIn();
    params.clientId = this.clientId ? this.clientId : null;
    params.brandId = config.brandId ? config.brandId : null;
    params.locale = config.locale ? config.locale : null;
    params.deliveryCountry = this.countryService.forSite.name ? this.countryService.forSite.name : null;
    params.deliveryCountryId = this.countryService.forShipping.id ? this.countryService.forShipping.id : null;
    params.send_to = this.key;

    // Sanitize the URL & set the page_location, page_path, page_referrer for every event
    params.page_location = this.sanitizeUrl(this.window.location.href);
    params.page_path = this.window.location.pathname;
    params.page_referrer = this.sanitizeUrl(this.window.document.referrer);

    if (isUserLoggedIn) {
      params.user_id = this.user?.slug;
      params.slug = this.user?.slug;
    }
    params.status_login = isUserLoggedIn;

    this.ga4(fnName, eventName, params);
  }

  /**
   * Send page view event
   */
  sendPageView(): void {
    this.logAdvancedEvent('event', 'page_view', {});
  }

  /**
   * Track interaction with the nav
   * @param navAnalyticsInfo
   */
  trackInteractionNav(navAnalyticsInfo: NavAnalyticsInfo): void {
    this.logAdvancedEvent('event', 'nav_interaction', navAnalyticsInfo);
  }

  /**
   * Track the generic click
   * @param obj
   */
  trackClick(obj: { eventCategory: string; eventAction: string; eventLabel?: string; eventValue?: string }): void {
    this.logAdvancedEvent('event', 'genericClick', {
      eventAction: obj.eventAction,
      eventCategory: obj.eventCategory,
      eventLabel: obj.eventLabel,
      eventValue: obj.eventValue
    });
  }

  /**
   * Track Product View event
   * @param product
   * @param listValue
   * @param productListUniqueIndentifier
   */
  trackProductView(product: Product, listValue: string, productListUniqueIndentifier: string): void {
    this.logAdvancedEvent('event', 'view_item', {
      currency: product?.getPrice().currency,
      value: (product?.getPrice().price / 100).toFixed(2),
      items: [
        {
          item_id: product?.id,
          item_name: product?.name,
          currency: product?.getPrice().currency,
          item_category: product?.collectionName,
          price: (product?.getPrice().price / 100).toFixed(2),
          quantity: 1,
          item_list_id: productListUniqueIndentifier,
          item_list_name: listValue ? listValue : '',
          item_variant: this.getItemVariant(product)
        }
      ]
    });
  }

  /**
   * Track Subscription Soft-Lead(item selected from subs page)
   * @param type
   */
  trackSubscriptionSoftLead(type: string): void {
    this.logAdvancedEvent('event', 'subscription_soft_lead', {
      flower_size: type
    });
  }

  /**
   * Track Subscription Purchase
   * @param purchase
   */
  trackSubscriptionPurchase(purchase: Purchase): void {
    this.logAdvancedEvent('event', 'subscription_purchase', {
      flower_size: purchase.orders[0].product.name,
      value: purchase.price.price / 100,
      transaction_id: purchase.id
    });
  }

  /**
   * Track Product Selected(start checkout)
   * @param product
   * @param purchase
   */
  trackProductSelected(
    product: Product,
    purchase: Purchase,
    duration: number,
    listValue?: string,
    productListUniqueIndentifier?: string
  ): void {
    this.logAdvancedEvent('event', 'begin_checkout', {
      currency: product?.getPrice().currency,
      value: (product?.getPrice().price / 100).toFixed(2),
      coupon: purchase?.discount ? purchase?.discount?.code : '',
      items: [
        {
          item_id: product?.id,
          item_name: product?.name,
          currency: product?.getPrice().currency,
          item_category: product?.collectionName,
          price: (product?.getPrice().price / 100).toFixed(2),
          quantity: 1,
          item_variant: product?.getTrackedDurationName(duration),
          item_list_id: productListUniqueIndentifier ? productListUniqueIndentifier : '',
          item_list_name: listValue ? listValue : ''
        }
      ]
    });
  }

  /**
   * Track the remove item from cart/basket
   * @param order
   */
  removeFromCart(order: Order, listValue?: string, productListUniqueIndentifier?: string): void {
    const product = order?.product;
    this.logAdvancedEvent('event', 'remove_from_cart', {
      currency: order?.price?.currency,
      value: order?.price?.price / 100,
      items: [
        {
          item_id: product?.id,
          item_name: product?.name,
          currency: order?.price?.currency,
          item_category: product?.collectionName,
          item_variant: product?.getTrackedDurationName(order.duration),
          price: order?.price?.price / 100,
          quantity: 1,
          item_list_id: productListUniqueIndentifier ? productListUniqueIndentifier : '',
          item_list_name: listValue ? listValue : ''
        }
      ]
    });
  }

  /**
   * Track View Cart(add to cart/arrive at payment page)
   * @param orders
   */
  trackViewCart(purchase: Purchase): void {
    const orders = purchase.orders;

    if (orders.length) {
      const items = orders.map((order) => {
        return {
          item_id: order?.product?.id,
          item_name: order?.product?.name,
          currency: order?.price?.currency,
          item_category: order?.product?.collectionName,
          item_variant: order?.product?.getTrackedDurationName(order.duration),
          price: (order?.price?.price / 100).toFixed(2),
          quantity: 1
        };
      });

      this.logAdvancedEvent('event', 'view_cart', {
        currency: orders[0]?.price?.currency,
        value: (purchase?.price?.price / 100).toFixed(2),
        items: items
      });
    }
  }

  /**
   * Track Add To Purchase(add to cart/arrive at payment page)
   * @param product
   * @param purchase
   */
  trackAddToPurchase(order: Order, listValue: string, productListUniqueIndentifier: string): void {
    const product = order?.product;
    this.logAdvancedEvent('event', 'add_to_cart', {
      currency: product?.getPrice().currency,
      value: (product?.getPrice().price / 100).toFixed(2),
      items: [
        {
          item_id: product?.id,
          item_name: product?.name,
          currency: product?.getPrice().currency,
          item_category: product?.collectionName,
          item_list_id: productListUniqueIndentifier,
          item_list_name: listValue ? listValue : '',
          item_variant: product?.getTrackedDurationName(order.duration),
          price: (product?.getPrice().price / 100).toFixed(2),
          quantity: 1
        }
      ]
    });
  }

  /**
   * Track user login
   * @param method
   */
  trackUserLogin(method: string): void {
    this.logAdvancedEvent('event', 'login', { method: method });
  }

  /**
   * Track user register
   * @param method
   */
  trackUserRegister(method: string): void {
    this.logAdvancedEvent('event', 'sign_up', { method: method });
  }

  /**
   * Track sort interactions
   * @param optionSelected
   */
  trackSortingOption(optionSelected: string): void {
    this.logAdvancedEvent('event', 'sort_products', { option_selected: optionSelected });
  }

  /**
   * Track add payment info
   * @param method
   * @param purchase
   */
  trackAddPaymentInfo(method: string, purchase: Purchase): void {
    const orders = purchase.orders;
    if (orders.length) {
      const items = orders.map((order) => {
        return {
          item_id: order?.product?.id,
          item_name: order?.product?.name,
          currency: order?.price?.currency,
          item_category: order?.product?.collectionName,
          item_variant: order?.product?.getTrackedDurationName(order.duration),
          price: (order?.price?.price / 100).toFixed(2),
          quantity: 1
        };
      });

      this.logAdvancedEvent('event', 'add_payment_info', {
        payment_type: method,
        coupon: purchase?.discount ? purchase?.discount?.code : '',
        currency: purchase?.price?.currency,
        value: (purchase?.price?.price / 100).toFixed(2),
        items: items
      });
    }
  }

  /**
   * Track sort interactions
   * @param ga4Info
   */
  trackFilterOptions(ga4Info): void {
    this.logAdvancedEvent('event', 'select_filter', {
      addedFiltersAlphabetical: ga4Info.addedFiltersAlphabetical,
      addedFilters: ga4Info.addedFilters,
      activeFilters: ga4Info.activeFilters
    });
  }

  /**
   * Get item variant function
   * @param product
   */
  getItemVariant(product: Product): string {
    if (product?.bundleOnly) {
      return 'Bundle';
    }
    if (product?.subscriptionOnly) {
      return 'Subscription';
    }
    return 'OneOff';
  }

  /**
   * Track add to favourites/wishlist
   * @param product
   */
  trackAddToWishlist(product: Product, listValue: string, productListUniqueIndentifier: string): void {
    this.logAdvancedEvent('event', 'add_to_wishlist', {
      currency: product?.getPrice().currency,
      value: (product?.getPrice().price / 100).toFixed(2),
      items: [
        {
          item_id: product?.id,
          item_name: product?.name,
          currency: product?.getPrice().currency,
          item_category: product?.collectionName,
          item_variant: this.getItemVariant(product),
          price: (product?.getPrice().price / 100).toFixed(2),
          item_list_id: productListUniqueIndentifier ? productListUniqueIndentifier : '',
          item_list_name: listValue ? listValue : ''
        }
      ]
    });
  }

  /**
   * Track purchase
   * @param product
   */
  trackPurchase(purchase: Purchase): void {
    const orders = purchase.orders;
    if (orders.length) {
      const items = orders.map((order) => {
        return {
          item_id: order?.product?.id,
          item_name: order?.product?.name,
          currency: order?.price?.currency,
          item_category: order?.product?.collectionName,
          item_variant: order?.product?.getTrackedDurationName(order.duration),
          price: (order?.price?.price / 100).toFixed(2),
          quantity: 1
        };
      });

      const shippingTotal = orders.map((order) => order?.shippingOption?.price?.price).reduce((prev, next) => prev + next);

      this.logAdvancedEvent('event', 'purchase', {
        transaction_id: purchase?.id,
        value: (purchase?.price?.price / 100).toFixed(2),
        shipping: shippingTotal / 100,
        currency: purchase?.price?.currency,
        coupon: purchase?.discount?.code,
        items: items
      });
    }
  }

  /**
   * Track add shipping info
   * @param order
   */
  trackAddShippingInfo(order: Order, listValue: string, productListUniqueIndentifier: string): void {
    const product = order?.product;
    this.logAdvancedEvent('event', 'add_shipping_info', {
      currency: order?.getPrice().currency,
      value: (order?.getPrice().price / 100).toFixed(2),
      shipping_tier: order?.shippingOption?.name,
      items: [
        {
          item_id: product?.id,
          item_name: product?.name,
          currency: order?.getPrice().currency,
          item_category: product?.collectionName,
          item_variant: this.getItemVariant(order?.product),
          price: (order?.getPrice().price / 100).toFixed(2),
          quantity: 1,
          item_list_id: productListUniqueIndentifier ? productListUniqueIndentifier : '',
          item_list_name: listValue ? listValue : ''
        }
      ]
    });
  }

  /**
   * Track modalView
   * @param modalType
   */
  trackModalView(modalType: string): void {
    this.logAdvancedEvent('event', 'modalView', {
      modalType: modalType
    });
  }

  /**
   * Track the field enter
   * @param formName
   * @param fieldName
   */
  trackEnterField(formName: FormName, fieldName: FieldName): void {
    this.logAdvancedEvent('event', 'enter_field', {
      form_name: formName,
      field_name: fieldName
    });
  }

  /**
   * Track the form submit
   * @param formName
   */
  trackFormSubmit(formName: FormName): void {
    this.logAdvancedEvent('event', 'submit_form', {
      form_name: formName
    });
  }

  /**
   * Track add promocode
   * @param userAction
   * @param purchase
   */
  trackAddPromoCode(userAction: string, code: string, isCodeValid: boolean): void {
    this.logAdvancedEvent('event', 'promo_code', {
      coupon_value: code,
      user_action: userAction,
      isValid: isCodeValid
    });
  }

  /**
   * Track view list items
   * @param products
   * @param productIds
   */
  trackViewListItems(products: Product[], productIds: Number[], listValue: string): void {
    if (products.length) {
      const items = products.map((product) => {
        return {
          item_id: product?.id,
          item_name: product?.name,
          currency: product?.getPrice().currency,
          item_category: product?.collectionName,
          item_list_name: listValue ? listValue : '',
          item_variant: this.getItemVariant(product),
          price: (product?.getPrice().price / 100).toFixed(2),
          quantity: 1
        };
      });

      this.logAdvancedEvent('event', 'view_item_list', {
        productIds: productIds,
        items: items
      });
    }
  }

  /**
   * Track view experiment
   * @param experiment - object that contains experiment details
   */
  trackViewExperiment(experiment: ExperimentOptions): void {
    const experimentObj = {
      experiment_id: experiment.id,
      experiment_name: experiment.name,
      variation_id: experiment.variant
    };
    this.logAdvancedEvent('event', 'view_experiment', experimentObj);
  }

  /**
   * Set currently running experiments to be used by heap
   * @param currentlyRunningExperiments
   */
  setExperimentsRunning(currentlyRunningExperiments: ExperimentOptions[]) {
    this.experiments = currentlyRunningExperiments;
  }

  /**
   * Toggle gtag consent
   * @param {ICookieConsent} iCookieConsent
   * @param {boolean} iCookieConsent.statistics
   * @param {boolean} iCookieConsent.marketing
   */
  toggleGtagConsent({ statistics, marketing }: ICookieConsent): void {
    this.window.gtag('consent', 'update', {
      analytics_storage: statistics ? 'granted' : 'denied',
      ad_storage: marketing ? 'granted' : 'denied'
    });
  }

  /**
   * Init gtag consent from defaults
   */
  initGtagConsent(): void {
    const { analytics_storage, ad_storage, functionality_storage, personalization_storage, security_storage } =
      this.featuresService.getFeature('GA4_CONSENT').defaultValues;

    this.window.gtag('consent', 'default', {
      analytics_storage,
      ad_storage,
      functionality_storage,
      personalization_storage,
      security_storage
    });
  }

  /**
   * Init GTAG
   */
  init(): Promise<any> {
    return this.domUtils.loadScript(`https://www.googletagmanager.com/gtag/js?id=${this.key}`, 'gtag').then(() => {
      this.window.gtag('get', this.key, 'client_id', (client_id) => {
        this.clientId = client_id;
      });
      this.serviceInitialized = true;
      this.log('init GA4 service');

      this.experiments.forEach((experiment) => {
        this.trackViewExperiment(experiment);
      });
    });
  }
}
