
<div class="row bwFlowerJournalBlogItems text-center">
  <div class="col-12" *ngIf="title">
    <h3 class="bwRhythm">{{ title }}</h3>
  </div>
  <div class="blog-item-list">
    <div class="col-sm-12 col-md-6 col-lg-4" *ngFor="let item of items | slice:0:showMaximum" [ngClass]="{'hidden-xs': !(item?.description || item?.slug)}">
      <div class="blog-item"><a class="image" href="{{item?.slug}}"><img class="main-image" src="{{item?.imageUrl}}"></a>
        <div class="blog-item__content" *ngIf="item?.description &amp;&amp; item?.slug">
          <div class="content__date u-font-small text-muted">{{item?.publishedDate | localeDate:'D MMMM YYYY'}}</div>
          <h3 class="content__title">{{item?.title}}</h3>
          <p class="content__summary">{{item?.description}}</p>
          <bw-button class="content__link" buttonStyle="text-link" href="{{item?.slug}}" bwTrackAs="components.bwFlowerJournalBlogItems.read_more">Read more</bw-button>
        </div>
      </div>
    </div>
  </div>
  <div class="show-more" *ngIf="items &amp;&amp; showMaximum &lt; items.length">
    <bw-button buttonStyle="secondary" size="medium" (click)="showMore()" bwTrackAs="components.bwFlowerJournalBlogItems.show_more">Show more articles</bw-button>
  </div>
</div>