import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';

import { IntersectionService, IntersectionOptions } from 'Shared/services/intersection.service';
import { GridProduct } from 'Shared/classes/grid-product';
import { WindowRefService } from 'Shared/services/window.service';
import { FeatureBannerDisplayService } from 'Shared/services/feature-banner-display.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { Product } from 'Shared/classes/product';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { ViewportDetectionService } from 'Shared/services/viewport-detection.service';
@Component({
  selector: 'bw-product-card-image',
  templateUrl: './product-card-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCardImageComponent implements OnInit, AfterViewInit, OnDestroy {
  /* bw:view-encapsulation */

  @Input() product: GridProduct | Product;
  @Input() index: number;
  @Input() bestImageryProductImages: any;
  @Input() lazyLoad: boolean;
  @Input() cardActive: boolean;
  @Input() scrollProductCardImages: EventEmitter<number>;
  @Input() imageUrls: IImage[];
  @Output() currentSlideIndexChanged: EventEmitter<any> = new EventEmitter();

  withinViewport: boolean;
  intersectionService: IntersectionService;
  density: number;
  document: any;
  scrollProductCardImagesSubscription: any;
  isMobile: boolean = this.viewportDetectionService.viewportSizeIs$.getValue().mobile;

  constructor(
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private windowRef: WindowRefService,
    private featureService: FeatureBannerDisplayService,
    private analyticsService: AnalyticsService,
    private experimentService: ExperimentsService,
    private viewportDetectionService: ViewportDetectionService
  ) {
    this.document = this.windowRef.nativeWindow.document;
  }

  showImage(): void {
    this.windowRef.nativeWindow.requestAnimationFrame(() => {
      this.withinViewport = true;
      this.cdr.detectChanges();
      this.setupSwipeDetect();
    });
  }

  setupSwipeDetect(): void {
    const scrollContainer = this.elRef.nativeElement.querySelector(`.swipe-container${this.index}`);

    if (!scrollContainer) {
      return;
    }

    scrollContainer.addEventListener('scroll', this, { passive: true });
  }

  /*
      Scroll Handle Event
  */
  handleEvent(): void {
    this.trackImageScrolled(this.product);
    this.destroySwipeDetect();
  }

  trackImageScrolled(product: GridProduct | Product): void {
    this.analyticsService.trackInHeap('imageScrolled', { product });
  }

  destroySwipeDetect(): void {
    const scrollContainer = this.elRef.nativeElement.querySelector(`.swipe-container${this.index}`);

    if (!scrollContainer) {
      return;
    }

    scrollContainer.removeEventListener('scroll', this, { passive: true });
  }

  setupLazyLoading(): void {
    if (!this.lazyLoad) {
      return;
    }

    const options: IntersectionOptions = {
      respondWhenIntersecting: true,
      removeAfterObserved: true,
      rootMargin: '0% 100% 200% 0%',
      rootElement: this.windowRef.nativeWindow.document.querySelector('.product-card-scroll-context')
    };
    this.intersectionService = new IntersectionService();
    this.intersectionService
      .setup(options)
      .observeElements([this.elRef.nativeElement])
      .subscribe((response) => {
        if (response && response.isIntersecting) {
          this.showImage();
        }
      });
  }

  onSlideEnter(index: number): void {
    this.currentSlideIndexChanged.emit(index);
  }

  setupScrollEvents(): void {
    this.scrollProductCardImagesSubscription = this.scrollProductCardImages.subscribe((activeSlideIndex) => {
      const container = this.document.querySelector(`.swipe-container${this.index}`);
      const elementToScrollTo = this.document.getElementById(`product-card-image-id-1`);

      container.scrollTo({
        left: elementToScrollTo.offsetWidth * activeSlideIndex,
        behavior: 'smooth'
      });
    });
  }

  ngOnDestroy(): void {
    if (this.intersectionService) {
      this.intersectionService.onDestroy();
      this.intersectionService = null;
    }
    this.destroySwipeDetect();
    if (this.scrollProductCardImages) {
      this.scrollProductCardImagesSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setupLazyLoading();
    }, 0);
  }

  ngOnInit(): void {
    if (!this.lazyLoad) {
      this.showImage();
    }

    if (this.scrollProductCardImages) {
      this.setupScrollEvents();
    }
  }
}

interface IImage {
  type?: 'image' | 'video';
  src: string;
}
