import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'localeDate'
})
export class LocaleDatePipe implements PipeTransform {
  transform(dateObject: dayjs.Dayjs | Date | string, format: string): any {
    return dayjs(dateObject).isValid() ? dayjs(dateObject).format(format) : null;
  }
}
