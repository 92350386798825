import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { FeaturesService, FeatureKey } from 'Shared/services/features.service';

/**
 * How to use (a string is passed so we need '')
 * div(*featureEnabled="'ADDONS'")
 * div(*featureDisabled="'ADDONS'")
 *
 */
@Directive({
  selector: '[featureEnabled], [featureDisabled]'
})
export class FeatureDirective implements OnInit {
  private feature: FeatureKey;
  private isFeatureEnabled: boolean = false;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private featuresService: FeaturesService) {}

  @Input() set featureEnabled(value: string) {
    this.feature = value.toUpperCase() as FeatureKey;
    this.isFeatureEnabled = true;
  }

  @Input() set featureDisabled(value: string) {
    this.feature = value.toUpperCase() as FeatureKey;
    this.isFeatureEnabled = false;
  }

  ngOnInit(): void {
    const featureConfig = this.featuresService.getFeature(this.feature);
    const isShown = this.isFeatureEnabled === !!featureConfig;
    if (isShown) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
