import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  Input
} from '@angular/core';
import { PopoverInfoDirective } from 'Shared/components/popover-info/popover-info.directive';
import { StateService } from 'Shared/services/state.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-popover-info-content',
  templateUrl: './popover-info.component.html'
})
export class PopoverInfoComponent implements AfterViewInit {
  @ViewChild('popoverInfoContent') public popoverInfoContent: ElementRef;

  @Input() isCheckoutPage: boolean;

  public popover: PopoverInfoDirective;
  public display = false;

  constructor(protected cdr: ChangeDetectorRef, private stateService: StateService) {}

  public ngAfterViewInit(): void {
    this.shown();
    this.cdr.detectChanges();
  }

  shown(): void {
    if (!this.popover) {
      return;
    }
  }

  hidden(): void {
    if (!this.popover) {
      return;
    }
    this.popover.hide();
  }
}
