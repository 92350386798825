export const reviews = [
  {
    date: '2021-11-16T22:48:40Z',
    title: 'Beautiful combination of flowers and…',
    review:
      'Beautiful combination of flowers and colours. Love how each bouquet is different. Would highly recommend',
    rating: 5,
    reviewer: 'Megan b'
  },
  {
    date: '2021-11-10T16:14:46Z',
    title: 'A monthly treat',
    review:
      'Once a month I open the door to receive a beautiful bouquet that is just as stunning as the last but so totally different to the month before. The unique selection of fresh exotic and cottage garden flowers never cease to delight.',
    rating: 5,
    reviewer: 'Lesley (Bexhill)'
  },
  {
    date: '2021-11-02T18:19:49Z',
    title: 'Wonderful as ever',
    review: 'Wonderful as ever',
    rating: 5,
    reviewer: 'Jan'
  },
  {
    date: '2021-11-02T09:05:00Z',
    title: 'I usually LOVE my Bloomon flowers',
    review:
      'I usually LOVE my Bloomon flowers, and would have given a 5 star review, however my delivery last week (28 October) was disappointing.  It included a lily, which I have asked to be excluded, and the rose in there has a broken stem.\nUsuallly though, the flowers are spectacular!',
    rating: 4,
    reviewer: 'Natalie Brindle'
  },
  {
    date: '2021-10-26T14:36:59Z',
    title: 'Lovely autumn colours',
    review: 'Always different from shop bought, I always get compliments about my bloomon flowers.',
    rating: 5,
    reviewer: 'Margaret Jack'
  },
  {
    date: '2021-09-22T08:22:18Z',
    title: 'Bloomon are definitely the best',
    review:
      'Bloomon are definitely the best. \nOn top of wonderful flowers that last up to 3 weeks if well attended to, their flowers are responsibly grown and they’re improving on that to minimise the environmental impact. Plus they have an excellent customer service which is always a plus. I’ve been a loyal customer of theirs for a 2 solid years now and I’ve placed orders in Belgium and Germany too. Bloomon please go to Italy too!',
    rating: 5,
    reviewer: 'VINCENZO I'
  },
  {
    date: '2021-09-20T17:24:08Z',
    title: 'Stunning flowers!',
    review: 'Stunning flowers!',
    rating: 5,
    reviewer: 'Hanna Nilsson'
  },
  {
    date: '2021-09-10T16:31:54Z',
    title: 'Another spectacular arrangement',
    review: 'Another spectacular arrangement, colour texture, and mix . Well done you',
    rating: 5,
    reviewer: 'Mary  Hawkes'
  },
  {
    date: '2021-06-29T13:53:31Z',
    title: 'Lovely flower selections',
    review:
      "Lovely flower selections. Not as exciting as in the company's earlier days; the bouquets are a little more predictable and unadventurous, but still beautiful and long lasting. Good customer care if you ever encounter problems with a delivery.",
    rating: 4,
    reviewer: 'Allister Frost'
  },
  {
    date: '2021-04-06T12:50:19Z',
    title: 'I love it!',
    review:
      "The Atelier designs tend to be outstanding. I haven't seen similar contemporary designs at any other online florists. Some of the stems are short lasting, but majority holds up very well, and even 10 days later I have a fresh looking bouquet on the table. Haven't had any issues with delivery so far. + I ordered a vase once from them, that I'm super happy with.",
    rating: 5,
    reviewer: 'Eszter Balogh'
  },
  {
    date: '2021-03-30T08:31:31Z',
    title: 'Stunningly beautiful flowers which are very long-lasting',
    review:
      'I have a fortnightly subscription and my experience is that the flowers are stunningly beautiful, very long-stemmed, very varied and very long-lasting. Every stem is a different flower or leaf, which makes for a really glorious, contemporary display, much more interesting than your usual bouquet. I change the water every 3 or 4 days as they advise, and most of the bouquet lasts 3 weeks. \n\nI had one delivery where two heads were broken: customer service was prompt and responsive, giving me a discount from the following delivery. Orders for others have also been lovely.',
    rating: 5,
    reviewer: 'Nicky Galzini'
  },
  {
    date: '2020-06-18T13:20:44Z',
    title: 'Stunning contemporary flowers that last…',
    review:
      "Stunning contemporary flowers that last for more than 2 weeks if you follow their tips on how to prolong their stems vase life.  i.e. clean the vase and change the water every 3 days.  This works.  Can't fault the customer service as they always reply to emails quickly and have resolved any problems promptly.  I've had bouquets from them for a couple of years now and can't rate them highly enough.  Friends I've recommended them to are also delighted.",
    rating: 5,
    reviewer: 'Montessa !'
  },
  {
    date: '2020-05-15T13:39:56Z',
    title: 'I love the flowers from this company',
    review:
      "I love the flowers from this company.  I've been receiving fortnightly flowers from Bloomon for over a year.  Each bouquet is completely different with interesting flowers and fantastic colours.  They are a little pricey but the flowers typically last 10 days.",
    rating: 4,
    reviewer: 'Catrin Harley'
  }
];
