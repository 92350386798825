import { Injectable } from '@angular/core';
import { BackendService } from 'Shared/services/backend.service';

interface TicketPayload {
  problem: string;
  reason: string;
  resolution: string;
  issue: string;
  stem: string;
  question: string;
  description: string;
  deliveryAddress: string;
  postCode: string;
  order: string;
  fullName: string;
  email: string;
  fileNames: any[];
}

@Injectable({
  providedIn: 'root'
})
export class TicketModelService {
  constructor(private backend: BackendService) {}

  /**
   * To Payload
   * @param data
   */
  toPayload(data: TicketPayload): any {
    let message = data.question;

    const additional = [
      data.deliveryAddress ? `Address: ${data.deliveryAddress}` : undefined,
      data.description ? `Description: ${data.description}` : undefined,
      data.resolution ? `Suggested Resolution: ${data.resolution}` : undefined,
      data.issue ? `Suggested Issue: ${data.issue}` : undefined,
      data.stem ? `Suggested Stem: ${data.stem}` : undefined,
      data.problem ? `Problem area: ${data.problem}` : undefined
    ]
      .filter((s) => s && s.length)
      .join('\n');

    if (additional) {
      message = `${message}\n\n${additional}`;
    }

    return {
      data: {
        type: 'tickets',
        attributes: {
          message,
          kind: data.reason,
          order_id: data.order,
          postcode: data.postCode,
          full_name: data.fullName,
          email: data.email,
          filenames: data.fileNames || undefined
        }
      }
    };
  }

  /**
   * Create a ticket
   * @param payload
   */
  public createTicket(payload: TicketPayload): Promise<any> {
    return this.backend.post(null, '/v2/tickets', this.toPayload(payload), {
      requestIsJsonApi: true,
      responseIsJsonApi: true
    });
  }
}
