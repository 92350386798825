import { Pipe, PipeTransform } from '@angular/core';
import { t } from 'Shared/utils/translations';

@Pipe({
  name: 'dayOfWeek'
})
export class DayOfWeekPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return [
      t('js.pipe.day-of-week.sunday'),
      t('js.pipe.day-of-week.monday'),
      t('js.pipe.day-of-week.tuesday'),
      t('js.pipe.day-of-week.wednesday'),
      t('js.pipe.day-of-week.thursday'),
      t('js.pipe.day-of-week.friday'),
      t('js.pipe.day-of-week.saturday')
    ][value];
  }
}
