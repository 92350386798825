import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { User } from 'Shared/classes/user';
import { Error as BwError } from 'Shared/classes/error';
import { BugsnagStatic } from '@bugsnag/core';

@Injectable({
  providedIn: 'root'
})
export class BugsnagService {
  bugsnagClient: BugsnagStatic;
  debug: boolean = false;

  constructor(private windowRef: WindowRefService) {
    this.debug = (this.windowRef.nativeWindow as Window).location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * @description Log
   * @param {unknown[]} args
   */
  log(...args: unknown[]): void {
    if (this.debug) {
      console.log('<bugsnag>', ...args);
    }
  }

  /**
   * @description Get the bugsnag client
   * @returns {BugsnagStatic}
   */
  getClient(): BugsnagStatic {
    this.bugsnagClient = this.bugsnagClient || this.windowRef.nativeWindow['bugSnagClient'];
    return this.bugsnagClient;
  }

  /**
   * @description Log an event in bugsnag
   * @param {BwError} err
   */
  logEvent(err: BwError): void {
    try {
      const client = this.getClient();
      if (client) {
        client.notify(new Error(`API/${err.code}`), (event): void => {
          event.addMetadata('error', {
            message: err.message,
            title: err.title,
            code: err.code,
            kind: err.kind ?? ''
          });
        });
      }
    } catch (e: unknown) {}
  }

  /**
   * @description Set dimension
   * @param {string} key
   * @param {unknown} value
   */
  setDimension(key: string, value: unknown): void {
    try {
      const client = this.getClient();
      if (client) {
        const obj = {};
        obj[key] = value;
        let user = client.getUser();
        user = Object.assign(user, obj);
        client.setUser(user.id, user.email, user.name);
        this.log('dimension', obj);
      }
    } catch (e: unknown) {}
  }

  /**
   * @description Identidy
   * @param {User} user
   */
  identify(user: User): void {
    try {
      if (this.bugsnagClient && user && user.slug) {
        const client = this.getClient();
        if (client && user && user.slug) {
          client.setUser(user.slug);
          this.log('identify', user.slug);
        }
      }
    } catch (e: unknown) {}
  }
}
