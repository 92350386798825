import { Injectable } from '@angular/core';
import {
  MegaNav,
  MegaNavItem,
  MegaNavItemSection,
  MegaNavItemSectionLink
} from 'Shared/classes/mega-nav-item';
import { ContentsModelService } from 'Shared/models/contents-model.service';
import { translations } from 'Environments/js-translations';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { ConfigService } from '../services/config.service';
import { FeaturesService } from '../services/features.service';

export const checkoutBaseUrl = translations['js.routing.checkout_base'];
@Injectable({
  providedIn: 'root'
})
export class MegaNavContentsModelService {
  navNames: string[] = ['mega_nav_links'];
  megaNavPromise: Promise<any>;

  constructor(
    private contentsModel: ContentsModelService,
    private experimentService: ExperimentsService,
    private configService: ConfigService,
    private featuresService: FeaturesService
  ) {}
  /**
   *
   * @param nav
   */
  private fromPayload(nav: any): MegaNav {
    const mappedNav = new MegaNav();

    nav.forEach((navItems) => {
      mappedNav.shipping_country_id = navItems.shipping_country_id;
      mappedNav.items = navItems.items.map((data) => {
        const megaNavItem = new MegaNavItem();
        megaNavItem.name = data.name;
        megaNavItem.styles = data.styles;
        megaNavItem.url = data.url;
        megaNavItem.tags = data.tags || [];
        megaNavItem.displayOn = data.display_on;
        megaNavItem.backgroundColour = data.background_colour;
        megaNavItem.underlineColour = data.underline_colour;
        megaNavItem.isFooterLink = (data.tags ?? []).indexOf('footer') > -1;

        // one of biggest hacks of the century but limited time
        megaNavItem.sections =
          data.sections.length > 0 && data.sections[0].name && data.sections[0].url
            ? this.megaNavSectionsFromPayload(data.sections, megaNavItem)
            : [];

        return megaNavItem;
      });
    });
    return mappedNav;
  }

  /**
   *
   * @param sectionItems
   */
  private megaNavSectionsFromPayload(sectionItem: any, megaNavItem: any): MegaNavItemSection[] {
    // create a positio index for all parents & children in squencial order
    // (i.e Parent is at position 1(child within parent 1 is at position 2), Next parent is at position 3  and so on
    // This helps with tracking
    let positionIndex = 0;
    const mappedSections = sectionItem.map((items) => {
      positionIndex += 1;
      const megaNavItemSection = new MegaNavItemSection();
      megaNavItemSection.tags = items.tags;
      megaNavItemSection.name = items.name;
      megaNavItemSection.styles = items.styles;
      megaNavItemSection.url = items.url;
      megaNavItemSection.positionIndex = positionIndex;
      megaNavItemSection.sectionType =
        items.sectionType && items.sectionType === 'content' ? items.sectionType : 'subnav';
      megaNavItemSection.navPath = [megaNavItem, megaNavItemSection];
      megaNavItemSection.displayOn = items.display_on;
      if (items && items.links) {
        megaNavItemSection.links = items.links.map((link) => {
          // we only wanna track for subnav with links & content spots have no children
          if (items.sectionType !== 'content') {
            positionIndex += 1;
          }
          const megaNavItemSectionLink = new MegaNavItemSectionLink();
          megaNavItemSectionLink.name = link.name;
          megaNavItemSectionLink.tags = link.tags;
          megaNavItemSectionLink.styles = link.styles;
          megaNavItemSectionLink.shortCopy = !link.shortCopy ? '' : link.shortCopy;
          megaNavItemSectionLink.url = link.url;
          megaNavItemSectionLink.displayOn = link.display_on;
          megaNavItemSectionLink.navPath = [
            megaNavItem,
            megaNavItemSection,
            megaNavItemSectionLink
          ];
          megaNavItemSectionLink.positionIndex = positionIndex;
          return megaNavItemSectionLink;
        });
      }
      return megaNavItemSection;
    });
    return mappedSections;
  }

  /**
   * Get Mega Nav Content for Mega Nav Reasons
   */
  getMegaNav(shippingCountryId: number): Promise<any> {
    // Get the MEGA_NAV_SWAP feature and set the navNames accordingly
    const feature = this.featuresService.getFeature('MEGA_NAV_SWAP');

    return this.experimentService
      .waitForExperiment(feature.key)
      .then((experiment) => {
        if (feature.active && experiment.variant > 0) {
          this.navNames = this.getNavNamebasedOnVariant(experiment.variant);
        } else {
          this.navNames = ['mega_nav_links'];
        }

        return this.contentsModel.getAll().then((res) => {
          const navObject = [];
          const navContentItems = res.filter(
            (r) => this.navNames.indexOf(r.attributes.name) !== -1
          );
          navContentItems.forEach((navItem) => {
            const navOb = navItem.attributes.content.find(
              (navItemContent) => +navItemContent.shipping_country_id === shippingCountryId
            );

            navObject.push(navOb);
          });
          return this.fromPayload(navObject);
        });
      })
      .catch((error) => console.log(error));
  }

  /**
   * Based on experiment variant return the nav name to pull out
   * of the contents endpoint
   *
   * Card (#176477217) to remove this experiment once it's over
   */
  getNavNamebasedOnVariant(variant: number): string[] {
    if (variant === 1) {
      return ['mega_nav_links_exp_v1'];
    }

    if (variant === 2) {
      return ['mega_nav_links_exp_v2'];
    }
  }
}
