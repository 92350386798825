export const reviews = [
  {
    date: '2021-11-23T18:20:20Z',
    title: 'De boeketten zijn altijd origineel',
    review:
      'De boeketten zijn altijd origineel, helemaal mijn smaak. De bloemen zijn ook bijzonder vers en blijven gemakkelijk 2 weken mooi. Je weet ook tot op bijna de minuut wanneer het boeket geleverd wordt.',
    rating: 5,
    reviewer: 'misee'
  },
  {
    date: '2021-11-23T09:44:41Z',
    title: 'Altijd een gevarieerd boeket met…',
    review: 'Altijd een gevarieerd boeket met superverse bloemen. Blijft minstens twee weken mooi!',
    rating: 5,
    reviewer: 'Sofie'
  },
  {
    date: '2021-11-22T18:58:22Z',
    title: 'We zijn tevreden.',
    review: 'We zijn tevreden, ze komen hun afspraken na.',
    rating: 5,
    reviewer: 'M&F'
  },
  {
    date: '2021-11-17T18:14:42Z',
    title: 'Mooie samenstelling qua kleuren en…',
    review: 'Mooie samenstelling qua kleuren en bloemen',
    rating: 5,
    reviewer: 'Mevr Sonja Bilsen'
  },
  {
    date: '2021-11-16T06:59:04Z',
    title: 'Heel stijlvol en origineel boeket!',
    review:
      'Heel mooi boeket! Echt stijlvol!\nOmdat ik graag een wat voller boeket heb, heb ik voor in de toekomst een “Deluxe” boeket op het oog.',
    rating: 4,
    reviewer: 'Sabine D'
  },
  {
    date: '2021-11-15T19:14:25Z',
    title: 'Vrolijke bloemen!',
    review:
      'Ik bestel een boeket om de 3 w, word er heel vrolijk van!\nDe bloemen zijn mooi, vers, altijd anders, kleurrijk \nDe levering gaat perfect, berichtje de dag op voorhand om hoe laat ze worden geleverd, klopt altijd\nJe kan ook makkelijk leverdatum aanpassen…\n\nIk ben helemaal fan!',
    rating: 5,
    reviewer: 'Els'
  },
  {
    date: '2021-11-15T18:22:27Z',
    title: 'Een aanrader',
    review: 'Een aanrader! Top!',
    rating: 4,
    reviewer: 'Gerald'
  },
  {
    date: '2021-11-15T13:40:34Z',
    title: 'Perfect!',
    review:
      'alles verliep perfect: \nsnelle levering, stevige verpakking. De bloemen waren allemaal intact en in goede staat. \nHet boeket staat heel mooi in de bijgeleverde vaas. \n\nIk ben super tevreden !',
    rating: 5,
    reviewer: 'hanne de'
  },
  {
    date: '2021-11-13T17:32:09Z',
    title: 'Amazing bouquet',
    review:
      'Amazing bouquet. Magnifique et ça enjolive le salon et la salle à manger. Je recommande vivement',
    rating: 4,
    reviewer: 'Arlette Mugisho Izuba'
  },
  {
    date: '2021-11-13T17:27:02Z',
    title: 'Prachtige ruiker',
    review:
      'Prachtige ruiker. Enkel had ik voor de levering geen bericht gekregen wanneer dit zou gebeuren. Was juist terug thuis maar ik had mij er kunnen naar regelen om te wachten om van huis te gaan.',
    rating: 4,
    reviewer: 'Marijke Van den Eynden'
  },
  {
    date: '2021-11-11T18:30:08Z',
    title: 'In de wolken met mijn Original boeket',
    review:
      'In de wolken met mijn Original boeket.  Zachte herfstkleuren, perfecte grote. Staat hier al twee weken te blinken.',
    rating: 5,
    reviewer: 'Kim'
  },
  {
    date: '2021-11-09T13:02:16Z',
    title: 'Super content van de vlotte en…',
    review:
      'Super content van de vlotte en vriendelijk levering van de bloemen die mij elke 14 dagen worden geleverd.',
    rating: 5,
    reviewer: 'Maria'
  }
];
