import { Dayjs } from 'dayjs';

export class ReviewsResponse {
  productId: number;
  averageRating: number;
  count: number;
  reviews: Review[];
}

export class Review {
  reviewer: string;
  date: Dayjs;
  relativeDate?: string;
  title: string;
  review: string;
  rating: number;

  constructor(review?: string, rating?: number, reviewer?: string) {
    this.review = review;
    this.rating = rating;
    this.reviewer = reviewer;
  }
}
