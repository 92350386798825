import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { MegaNavService } from 'Shared/services/mega-nav.service';
import { CountryService, Country } from 'Shared/services/country.service';
import {
  MegaNavItemSection,
  MegaNavItemSectionLink,
  MegaNavItem
} from 'Shared/classes/mega-nav-item';
import { StateService } from 'Shared/services/state.service';
import { WindowRefService } from 'Shared/services/window.service';
import { BehaviorSubject } from 'rxjs';
import { FeaturesService } from 'Shared/services/features.service';
import { ViewportDetectionService } from 'Shared/services/viewport-detection.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-mega-nav',
  templateUrl: './mega-nav.component.html'
})
export class MegaNavComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() deviceType: string;
  @Input() showFilters$: BehaviorSubject<boolean>;
  @Input() siteChanged: boolean;
  @Output() filterDidToggle = new EventEmitter<any>();
  nav: MegaNavItem[];
  shippingCountry: Country;
  showNav: boolean = true;
  tallestSubnavHeight: number = 0;
  countryForShipping: any;
  viewportSizeIs$: BehaviorSubject<any> = this.viewPortDetection.viewportSizeIs$;

  constructor(
    private megaNavService: MegaNavService,
    private countryService: CountryService,
    private stateService: StateService,
    private windowRef: WindowRefService,
    private featureService: FeaturesService,
    private viewPortDetection: ViewportDetectionService
  ) {}

  ngOnInit(): Promise<any> {
    this.shippingCountry = this.countryService.forShipping;

    // if the shipping country changes we want to re-init the nav
    this.countryForShipping = this.countryService.forShipping$.subscribe((data) => {
      return this.init();
    });
    // intialize Mega Nav
    return this.init();
  }

  init(): Promise<any> {
    this.shippingCountry = this.countryService.forShipping;
    return this.megaNavService.getMegaNav(this.shippingCountry).then((nav) => {
      this.nav = nav.items;
      setTimeout(() => {}, 10); // Force angular digest
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getTallestSubNavHeight();
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.countryForShipping && this.countryForShipping.unsubscribe) {
      this.countryForShipping.unsubscribe();
    }
  }

  getTallestSubNavHeight(): void {
    const sections = this.windowRef.nativeWindow.document.querySelectorAll(
      '.bw-mega-nav-desktop-child'
    );

    for (let i = 0; i < sections.length; i++) {
      this.tallestSubnavHeight =
        sections[i].offsetHeight > this.tallestSubnavHeight
          ? sections[i].offsetHeight
          : this.tallestSubnavHeight;
    }
  }

  filterDidToggleEmit(): void {
    this.filterDidToggle.emit();
  }

  /**
   *
   * @param navItem
   */
  navDidClicked(navItem: MegaNavItemSectionLink | MegaNavItemSection): any {
    // If the use interacts with mega nav then clear the activeVisualNavPath
    this.stateService.activeVisualNavPath = null;
  }
}
