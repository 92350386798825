import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ContentService } from 'Shared/services/content.service';

@Injectable()
export class HasContentSegment implements CanActivate {
  constructor(private contentSevice: ContentService) {}

  /**
   * Can activate
   */
  canActivate(): Promise<any> {
    const currentSegments = this.contentSevice.contentSegments();
    if (currentSegments && currentSegments.length) {
      return Promise.resolve(true);
    }

    // Always resolve, but ensure the init of the content service has happened
    return this.contentSevice
      .getContentSegments()
      .then(() => Promise.resolve(true))
      .catch(() => Promise.resolve(true));
  }
}
