import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalResponse, ModalResponseSubject } from 'Shared/services/modal.service';
import { ModuleTab } from 'Shared/components/module-tabs/module-tabs';
import { t } from 'Shared/utils/translations';
import { Product } from 'Shared/classes/product';
import { ProductService } from 'Checkout/services/product.service';
import { CountryService } from 'Shared/services/country.service';
import { PurchaseService } from 'Checkout/services/purchase.service';
import { Discount } from 'Shared/classes/discount';
import { Error } from 'Shared/classes/error';
import { AppboyService } from 'Shared/services/third-parties/appboy.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-product-card-modal',
  templateUrl: './product-card-modal.component.html'
})
export class ProductCardModalComponent implements ModalResponse, OnInit {
  modalResponse: Subject<ModalResponseSubject> = new Subject<ModalResponseSubject>();

  initialProductId: number; // used for the modal launch
  initialProduct: Product;

  isLoading: boolean = false;
  product: Product;
  bundleProducts: ModuleTab[] = [];
  selectedProduct: Product;
  discount: Discount;
  selectedFrequency: number;
  associatedSubscriptionTypes: Product[];
  numberOfDeliveries: number;
  footerLoad: boolean = true;

  // First ModuleTab component - ongoing vs fixed
  typeSubscriptionTab = new ModuleTab({
    name: t('js.component.product-card-modal.ongoing'),
    shortName: t('js.component.product-card-modal.ongoing-short'),
    event: () => this.selectSubscriptionTab()
  });
  typeBundleTab = new ModuleTab({
    name: t('js.component.product-card-modal.fixed'),
    event: () => this.selectBundleTab()
  });
  subscriptionType: ModuleTab[] = [this.typeSubscriptionTab, this.typeBundleTab];

  constructor(
    private productService: ProductService,
    private countryService: CountryService,
    private purchaseService: PurchaseService,
    private appboyService: AppboyService
  ) {}

  /**
   * Change the Frequency - only for ongoing subscriptions
   * @param frequency
   */
  changeFrequency(frequency: number): void {
    this.selectedFrequency = frequency;
  }

  /**
   * Select the ongoing tab
   */
  selectSubscriptionTab(): void {
    this.changeSelectedProduct(this.product);
    this.changeFrequency(28);
  }

  /**
   * Select the bundle tab
   */
  selectBundleTab(bundle?: Product): void {
    const initial =
      bundle || (this.product.upsells || []).find((u) => u.type === 'subsmodal-bundle').product;

    this.changeSelectedProduct(initial);
    this.changeFrequency(-1);
  }

  /**
   * On submit, pass the product, and the frequency/duration if needed
   */
  onSubmit(): void {
    const data = {} as any;
    data.product = this.selectedProduct;

    // Ongoing subscriptions are treated in the frontend as -1 duration
    const duration = this.selectedProduct.bundleOnly
      ? this.selectedProduct.getPrice().quantity
      : -1;
    const frequency = this.selectedProduct.bundleOnly ? 28 : this.selectedFrequency;
    data.params = {
      frequency,
      duration
    };

    this.modalResponse.next({
      data,
      success: true
    });
  }

  /**
   * On cancel
   */
  onCancel(): void {
    this.modalResponse.next({ success: false });
  }

  /**
   * On upsell selected, change the product, but don't change any association types
   * @param product
   */
  onUpsellSelected(product: Product): void {
    this.footerLoad = false;

    setTimeout(() => {
      this.selectedProduct = product;
      this.footerLoad = true;
    }, 0);
  }

  /**
   * Change the selected product
   * @param product
   */
  changeSelectedProduct(product: Product): void {
    if (this.selectedProduct && product.id === this.selectedProduct.id) {
      return; // Do nothing, it's the same
    }

    this.selectedProduct = product;

    this.associatedSubscriptionTypes = (product.upsells || [])
      .filter((u) => u.type === 'subsmodal-type')
      .map((u) => {
        const pCopy = u.product.clone();
        pCopy.collectionName = u.infoBody;

        return pCopy;
      });
  }

  /**
   * Get the available products
   */
  getProducts(): Promise<Product[]> {
    const purchase = this.purchaseService.getPurchase();

    return this.productService.getAvailableProducts(
      this.countryService.forShipping,
      purchase.orders.length,
      purchase.discount
    );
  }

  /**
   * Get the associated subscription product if needed
   * @param product
   */
  getRelatedSubscriptionProduct(product: Product): Promise<Product> {
    if (product.subscriptionOnly) {
      return Promise.resolve(product);
    }

    return this.getProducts().then((products) => {
      // Find the subscription product, given the bundle product
      const foundSubscription = products.find(
        (p) =>
          !!(p.upsells || []).find(
            (u) => u.type === 'subsmodal-bundle' && u.product.id === product.id
          )
      );
      return foundSubscription ? Promise.resolve(foundSubscription) : Promise.reject({});
    });
  }

  /**
   * Get the initial product
   * @param productId
   */
  getInitialProduct(productId): Promise<Product> {
    return this.getProducts().then((products) => {
      const foundProduct = products.find((p) => p.id === productId);
      return foundProduct ? Promise.resolve(foundProduct) : Promise.reject({});
    });
  }

  /**
   * On Init
   */
  ngOnInit(): Promise<any> {
    this.isLoading = true;

    const purchase = this.purchaseService.getPurchase();
    this.numberOfDeliveries =
      (purchase.discount && purchase.discount.toDeliveryNumber) || undefined;

    // Check that the product is available, with the correct price
    const promise = this.initialProductId
      ? this.getInitialProduct(this.initialProductId)
      : Promise.resolve(this.initialProduct);

    return promise
      .then((initialProduct) => {
        this.initialProduct = initialProduct;
        return this.getRelatedSubscriptionProduct(this.initialProduct);
      })
      .then((product) => {
        this.product = product;

        this.bundleProducts = (this.product.upsells || [])
          .filter((u) => u.type === 'subsmodal-bundle')
          .map((u) => {
            return new ModuleTab({
              name: u.toggleText,
              active: u.product.id === this.initialProduct.id,
              event: () => this.changeSelectedProduct(u.product)
            });
          });

        if (!this.bundleProducts.length) {
          return Promise.reject({});
        }

        if (this.initialProduct.bundleOnly) {
          this.selectBundleTab(this.initialProduct);
          this.typeBundleTab.active = true;
        } else {
          this.selectSubscriptionTab();
          this.typeSubscriptionTab.active = true;
        }

        if (this.initialProduct.subscriptionOnly) {
          this.appboyService.logEvent('web:page:subsmodalongoing');
        }

        this.isLoading = false;
      })
      .catch(() => {
        this.modalResponse.next({
          success: false,
          data: {
            error: new Error({
              code: 'unableToOpenSubscriptionModal'
            })
          }
        });
      });
  }
}
