import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, Input } from '@angular/core';
import { OasysLayoutModule } from 'oasys-lib';
import { StateService } from 'Shared/services/state.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';
import { ViewportDetectionService } from 'Project/shared/services/viewport-detection.service';
import { BehaviorSubject, Subscription } from 'rxjs';

// This component is visible on mobile only(for now)

/* bw:view-encapsulation */
@Component({
  selector: 'bw-splash-categories',
  templateUrl: './splash-categories.component.html',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, OasysLayoutModule]
})
export class SplashCategoriesComponent implements OnInit {
  viewportSizeIs$: BehaviorSubject<any> = this.viewportDetectionService.viewportSizeIs$;

  constructor(
    private stateService: StateService,
    private analyticsService: AnalyticsService,
    private optimizelyService: OptimizelyService,
    private viewportDetectionService: ViewportDetectionService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.optimizelyService.trackEvent('viewSplashCategories');

      const currentState = this.stateService.getCurrent();
      this.analyticsService.trackInHeap('viewSplashCategories', {
        location: currentState.url ? currentState.url : currentState.name === 'homepage' ? '/' : currentState.name
      });
    }, 100);
  }
}
