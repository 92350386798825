import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Project/shared/utils/dom-utils.service';
import { ConfigService } from '../config.service';
import { WindowRefService } from '../window.service';
import { Purchase } from 'Shared/classes/purchase';
import { User } from 'Shared/classes/user';

export type DrtvGoalId = 1 | 2 | 3 | 4;

const drtvGoalsProps = {
  orderConfirmation: {
    goalId: 1,
    props: ['purchaseId', 'userId', 'revenue', 'orderCount', 'promoCode']
  },
  viewCarousel: { goalId: 2, props: ['pagePath', 'carouselType', 'carouselTag', 'carouselLength'] },
  checkoutStart: { goalId: 3, props: ['skuId', 'orderType', 'productPrice', 'productName'] },
  arrivePayment: {
    goalId: 4,
    props: ['purchaseId', 'purchaseAmount', 'promoCode', 'userId', 'orderCount']
  }
};

export interface DrtvData {
  purchaseId?: number;
  userId?: string;
  revenue?: string;
  skuId?: number;
  orderType?: string;
  productPrice?: number;
  productName?: string;
  orderCount?: number;
  promoCode?: any;
  pagePath?: string;
  carouselType?: string;
  carouselTag?: string;
  carouselLength?: number;
  purchaseAmount?: any;
}
@Injectable({
  providedIn: 'root'
})
export class DrtvService {
  debug: boolean = false;
  initPromise: Promise<any>;
  serviceInitialized: boolean = false;
  url: string;
  window: any;

  constructor(
    private domUtils: DomUtilsService,
    private configService: ConfigService,
    private windowRef: WindowRefService
  ) {
    this.url = this.configService.getConfig().drtvUrl;
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<drtv>', ...args);
    }
  }

  /**
   * Init
   */
  init(): Promise<any> {
    const scriptUrl = `${this.url}/spef.min.js`;
    this.window._spef = this.window._spef || [];
    this.window._spef.push(['enableLinkTracking']);
    this.window._spef.push(['trackPageView']);
    this.window._spef.push(['setTrackerUrl', this.url + '/piwik.php']);
    this.window._spef.push(['setSiteId', this.configService.getConfig().drtvId]);

    this.initPromise =
      this.initPromise ||
      this.domUtils.loadScript(scriptUrl, 'drtv').then(() => {
        this.log('Init', scriptUrl);
        this.serviceInitialized = true;
      });

    return this.initPromise;
  }

  /**
   * Track the goal specified via id and set the custom variables defined in drtvData
   * @param id
   * @param drtvData
   */
  trackGoal(id: DrtvGoalId, drtvData: DrtvData): void {
    if (this.window._spef && this.configService.getConfig().drTvEnabled) {
      Object.entries(drtvData).forEach(([key, value], index) => {
        this.window._spef.push(['setCustomVariable', index + 1, key, value, 'visit']);
      });
      this.window._spef.push(['trackGoal', id]);
      this.log('trackGoal', id, drtvData);
    }
  }

  /**
   * Build the payload data object to pass to trackGoal, from the event name and the drtvData
   * @param eventName
   * @param drtvData
   */
  trackDrtvGoal(eventName: string, drtvData: any): void {
    if (this.window._spef && this.configService.getConfig().drTvEnabled) {
      const params = {
        pagePath: drtvData.pagePath ? drtvData.pagePath : undefined,
        carouselLength: drtvData.carouselLength ? drtvData.carouselLength : undefined,
        carouselTag: drtvData.carouselTag ? drtvData.carouselTag : undefined,
        carouselType: drtvData.carouselType ? drtvData.carouselType : undefined,
        purchaseId: drtvData.purchaseId ? drtvData.purchaseId : undefined,
        purchaseAmount: drtvData.purchaseAmount ? drtvData.purchaseAmount : undefined,
        promoCode: drtvData.promoCode ? drtvData.promoCode : undefined,
        userId: drtvData.userId ? drtvData.userId : undefined,
        orderCount: drtvData.orderCount ? drtvData.orderCount : undefined,
        skuId: drtvData.skuId ? drtvData.skuId : undefined,
        orderType: drtvData.orderType ? drtvData.orderType : undefined,
        productPrice: drtvData.productPrice ? drtvData.productPrice : undefined,
        productName: drtvData.productName ? drtvData.productName : undefined,
        revenue: drtvData.revenue ? drtvData.revenue : undefined
      };

      Object.keys(params).forEach((key) => {
        if (!(drtvGoalsProps[eventName].props.indexOf(key) > -1)) {
          delete params[key];
        }
      });
      this.trackGoal(drtvGoalsProps[eventName].goalId, params);
    }
  }

  /**
   * Track the page view
   */
  trackPage(): void {
    if (this.window._spef && this.serviceInitialized) {
      this.window._spef.push(['trackPageView']);
      this.log('Tracked Page View');
    }
  }

  /**
   * Track a Purchase
   * @param purchase
   */
  trackPurchase(purchase: Purchase, user: User): void {
    if (this.window._spef && this.configService.getConfig().drTvEnabled) {
      const orderLenght = purchase.orders.length;
      const purchasePrice = (purchase.price.price / 100).toFixed(2);
      const purchaseDiscount =
        purchase.discount && purchase.discount.code ? purchase.discount.code : '';

      this.window._spef.push(['setCustomVariable', 1, 'orderCount', orderLenght, 'visit']);
      this.window._spef.push(['setCustomVariable', 2, 'promoCode', purchaseDiscount, 'visit']);
      this.window._spef.push(['setCustomVariable', 3, 'userId', user.slug, 'visit']);

      this.window._spef.push(['trackEcommerceOrder', purchase.id, purchasePrice]);
      this.log('trackEcommerceOrder', purchase.id, purchasePrice);

      this.trackDrtvGoal('orderConfirmation', {
        purchaseId: purchase.id,
        userId: user.slug,
        revenue: purchasePrice,
        orderCount: purchase.orders.length,
        promoCode: purchaseDiscount
      });
    }
  }
}
