import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';

@Injectable({
  providedIn: 'root'
})
export class CustomKeysService {
  constructor(private windowRef: WindowRefService) {}

  /**
   * Emit a custom js Event if user presses arrow keys and:
   * - They aren't in a modal window
   * - They aren't focused on a text input or textarea
   * @param keyCode
   */
  handleCarouselArrowKeys(keyCode: number): void {
    const leftArrowKey = 37;
    const rightArrowKey = 39;
    if (keyCode !== leftArrowKey && keyCode !== rightArrowKey) {
      return;
    }
    const body = this.windowRef.nativeWindow.document.querySelector('body');
    if (body && body.classList.contains('modal-open')) {
      return;
    }
    const focusedInput = this.windowRef.nativeWindow.document.querySelector('input:focus');
    const focusedTextarea = this.windowRef.nativeWindow.document.querySelector('textarea:focus');
    if (focusedInput || focusedTextarea) {
      return;
    }

    if (keyCode === leftArrowKey) {
      // IE 11 Event Fix
      let event;
      if (typeof Event === 'function') {
        event = new Event('carouselLeftArrowKey');
      } else {
        event = document.createEvent('Event');
        event.initEvent('carouselLeftArrowKey', true, true);
      }
      this.windowRef.nativeWindow.document.dispatchEvent(event);
    }
    if (keyCode === rightArrowKey) {
      // IE 11 Event Fix
      let event;
      if (typeof Event === 'function') {
        event = new Event('carouselRightArrowKey');
      } else {
        event = document.createEvent('Event');
        event.initEvent('carouselRightArrowKey', true, true);
      }
      this.windowRef.nativeWindow.document.dispatchEvent(event);
    }
  }

  init(): void {
    this.windowRef.nativeWindow.addEventListener('keydown', event =>
      this.handleCarouselArrowKeys(event.keyCode)
    );
  }
}
