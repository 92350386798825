import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private window: any;
  private key: String = 'BW.';
  private sessionStorage = {};

  /**
   * Constructor
   * @param windowRefService
   */
  constructor(private windowRefService: WindowRefService) {
    this.window = this.windowRefService.nativeWindow;
  }

  /**
   * Clear all of the local storage
   */
  clearLocalStorage(): void {
    this.window.localStorage.clear();
  }

  /**
   * Attempt to get from local storage
   * @param key
   */
  private getFromLocalStorage(key: string, storageType: StorageType = StorageType.Local): string {
    if (storageType === StorageType.Local && this.window.bwLocalStorage) {
      return this.window.bwLocalStorage.getItem(key) || null; // .getItem will return null
    }
    return this.sessionStorage[key] || null;
  }

  /**
   * Set in local storage, or remove
   * @param key
   * @param data
   */
  private setInLocalStorage(key: string, data: any, storageType: StorageType = StorageType.Local): void {
    if (storageType === StorageType.Local) {
      this.window.bwLocalStorage.setItem(key, data);
    }
    this.sessionStorage[key] = data;
  }

  /**
   * Remove from the local storage
   * @param key
   */
  private removeFromLocalStorage(key: string, storageType: StorageType = StorageType.Local): any {
    if (storageType === StorageType.Local) {
      this.window.bwLocalStorage.removeItem(key);
    }
    this.sessionStorage[key] = undefined;
  }

  /**
   * Gets as a string
   * @param key
   */
  getString(key: string, storageType: StorageType = StorageType.Local): string {
    const k = `${this.key}${key}`;
    const item = this.getFromLocalStorage(k, storageType) || '';

    // Removes any double encoding stuff
    return item.replace(/"/g, '');
  }

  /**
   * Get an item
   * @param key
   */
  get(key: String, storageType: StorageType = StorageType.Local): any {
    const k = `${this.key}${key}`;
    const stored = this.getFromLocalStorage(k, storageType);
    if (stored === null || stored === undefined || stored === '{}') {
      return undefined;
    }

    const item = JSON.parse(stored);
    return item && Object.keys(item).length ? item : undefined;
  }

  /**
   * Set an item
   * @param key
   * @param data
   */
  set(key: string, data?: Object, storageType: StorageType = StorageType.Local): any {
    const k = `${this.key}${key}`;

    if (!data) {
      this.removeFromLocalStorage(k, storageType);
      return;
    }

    const toStore = JSON.stringify(data);
    this.setInLocalStorage(k, toStore, storageType);
  }
}

export enum StorageType {
  Local = 'localStorage',
  Session = 'sessionStorage'
}

export interface StorageFunction {
  clear(): any;
  getItem(key: string): any;
  setItem(key: string, data?: any): any;
  removeItem(key: string): any;
}
