import { Injectable } from '@angular/core';
import { APISerialisedJSONResponse, BackendService } from 'Shared/services/backend.service';
import { UserService } from 'Shared/services/user.service';
import { Card, CardBrand } from 'Shared/classes/card';
import { Purchase } from 'Shared/classes/purchase';
import { Country, CountryService } from 'Shared/services/country.service';
import { CardsAPIResponse, CardsSetupAPIResponse } from 'Shared/types/backend-api/card-api.typings';

@Injectable({
  providedIn: 'root'
})
export class CardModelService {
  constructor(private backend: BackendService, private userService: UserService, private countryService: CountryService) {}

  /**
   * From payload
   * @param res CardsAPIResponse
   */
  public static fromPayload(res: APISerialisedJSONResponse<'/v1/cards/*'>['cards'][number]): Card {
    const card = new Card();
    card.id = res.id;
    card.brand = res.brand || res.name;
    card.brandTidied = res.kind === 'sepa_debit' ? 'sepa' : ((card.brand || '').replace(/\W/g, '').toLowerCase() as CardBrand);
    card.kind = res.kind;
    card.expiry = {
      month: res.exp_month,
      year: res.exp_year
    };
    card.last4 = res.last4 ? res.last4.toString() : undefined;
    card.name = res.name;
    card.token = res.token;
    card.isTemporary = res.temporary === undefined ? false : res.temporary;
    return card;
  }

  /**
   * Can get all the cards
   */
  public getAll(cardKinds?: string[], orderShippingCountry?: Country): Promise<Card[]> {
    const user = this.userService.getUser();
    const shippingCountry = orderShippingCountry ? orderShippingCountry.id : this.countryService.forShipping.id;
    const params = {
      shipping_country_id: shippingCountry,
      'filter[kind]': cardKinds && cardKinds.length ? cardKinds.join(',') : undefined
    };
    return this.backend
      .get(user, '/v1/cards', { params })
      .then((res: { cards: CardsAPIResponse[] }): Card[] =>
        res && res.cards ? res.cards.map((c: CardsAPIResponse): Card => CardModelService.fromPayload(c)) : []
      );
  }

  /**
   * Create a stripe card
   * @param stripeToken
   * @param isTemporary
   * @param purchase Optional associated purchase
   */
  public createStripeCard(
    stripeToken: string,
    isTemporary: boolean = true,
    purchase?: Purchase,
    orderShippingCountry?: Country
  ): Promise<Card> {
    const user = this.userService.getUser();
    const shippingCountry = orderShippingCountry ? orderShippingCountry.id : this.countryService.forShipping.id;
    return this.backend
      .post(
        user,
        '/v1/cards' as 'POST:/v1/cards',
        {
          stripe_token: stripeToken,
          temporary: isTemporary,
          shipping_country_id: shippingCountry
        },
        purchase && purchase.id
          ? {
            params: {
              purchase_id: purchase.id
            },
            headers: {
              'x-purchase-token': purchase.token
            }
          }
          : {}
      )
      .then((r): Card => CardModelService.fromPayload(r.card));
  }

  /**
   * Create a paypal card
   * @param paypalToken
   * @param isTemporary
   * @param purchase Optional associated purchase
   */
  public createPaypal(paypalToken: string, isTemporary: boolean = false, purchase?: Purchase): Promise<Card> {
    const user = this.userService.getUser();
    const shippingCountry = this.countryService.forShipping.id;
    return this.backend
      .post(
        user,
        '/v1/cards' as 'POST:/v1/cards',
        {
          kind: 'braintree',
          token: paypalToken,
          temporary: isTemporary,
          shipping_country_id: shippingCountry
        },
        purchase && purchase.id
          ? {
            params: {
              purchase_id: purchase.id
            },
            headers: {
              'x-purchase-token': purchase.token
            }
          }
          : {}
      )
      .then((r): Card => CardModelService.fromPayload(r.card));
  }

  /**
   * Create klarna token
   * @param klarnaToken
   * @param isTemporary
   * @param purchase An optional associated purchase
   */
  public createKlarna(klarnaToken: string, isTemporary: boolean = false, purchase?: Purchase): Promise<Card> {
    const user = this.userService.getUser();
    const shippingCountry = this.countryService.forShipping.id;
    return this.backend
      .post(
        user,
        '/v1/cards' as 'POST:/v1/cards',
        {
          kind: 'klarna',
          token: klarnaToken,
          temporary: isTemporary,
          shipping_country_id: shippingCountry
        },
        purchase && purchase.id
          ? {
            params: {
              purchase_id: purchase.id
            },
            sendExperiments: true,
            headers: {
              'x-purchase-token': purchase.token
            }
          }
          : {}
      )
      .then((r): Card => CardModelService.fromPayload(r.card));
  }

  /**
   * Setup the card for future use
   * @param card
   */
  public getSetupIntentForFutureUse(card: Card, isPhoneSource: boolean = false, orderShippingCountry?: Country): Promise<string> {
    const user = this.userService.getUser();
    const shippingCountry = orderShippingCountry ? orderShippingCountry.id : this.countryService.forShipping.id;

    const options: any = {};
    if (isPhoneSource) {
      options.params = {
        source: 'phone'
      };
    }

    return this.backend
      .put(
        user,
        '/v1/cards/setup',
        {
          params: {
            stripe_card_token: card.token,
            shipping_country_id: shippingCountry
          }
        },
        options
      )
      .then((res: CardsSetupAPIResponse): string => (res && res.client_secret ? res.client_secret : ''));
  }

  /**
   * Delete a ard
   * @param card
   */
  public delete(card: Card): Promise<unknown> {
    const user = this.userService.getUser();
    const shippingCountry = this.countryService.forShipping.id;
    return this.backend.delete(user, `/v1/cards/${card.id}`, {
      params: {
        shipping_country_id: shippingCountry
      }
    });
  }
}
