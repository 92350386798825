import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FeatureBannerDisplayService } from 'Shared/services/feature-banner-display.service';
import { AnalyticsService } from 'Shared/services/analytics.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-feature-banner',
  templateUrl: './feature-banner.component.html'
})
export class FeatureBannerComponent implements OnInit {
  constructor(private featureBannerDisplayService: FeatureBannerDisplayService) {}
  @Input() name: string;

  @Input() ctaText: string;
  @Input() contentText: string;
  @Input() titleText: string;
  @Input() titleImage: string;

  @Output() didClickCta = new EventEmitter<any>();
  @Output() didDismiss = new EventEmitter<any>();

  didClick(): void {
    this.didClickCta.emit();
  }

  hide(): void {
    this.didDismiss.emit();
  }

  ngOnInit(): void {
    this.featureBannerDisplayService.register(this.name);
  }
}
