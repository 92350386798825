import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ReviewsService } from 'Shared/services/reviews.service';
import { ConfigService } from 'Shared/services/config.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { CountryService } from 'Shared/services/country.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-reviews-stats',
  templateUrl: './reviews-stats.component.html'
})
export class ReviewsStatsComponent implements OnInit {
  stats: any;
  site: string; // fr/de/uk - used for experiment removed after use

  @Input()
  modifier: string = 'normal';
  @Input()
  innerclass: string = '';
  @Input()
  innerContentclass: string = '';

  constructor(
    private reviewsService: ReviewsService,
    private configService: ConfigService,
    public experimentService: ExperimentsService,
    private countryService: CountryService
  ) {}

  ngOnInit(): Promise<any> {
    const shippingCountry = this.countryService.forShipping;
    this.site = this.configService.getConfig().site;
    return this.reviewsService.getReviewStats(shippingCountry).then(res => {
      this.stats = res;

      if (this.stats.count) {
        this.stats.count = this.stats.count.toLocaleString();
      }
    });
  }
}
