import { Pipe, PipeTransform } from '@angular/core';
import { Address } from 'Shared/classes/address';

@Pipe({
  name: 'addressInline'
})
export class AddressInlinePipe implements PipeTransform {
  transform(address: Address, hideName: boolean = false, postcodeBeforeCity: boolean = false): any {
    if (!address || address === undefined) {
      return '';
    }

    const addressArr = [
      !hideName ? address.name : undefined,
      address.company,
      address.line1,
      address.line2,
      postcodeBeforeCity? address.postcode : address.city,
      postcodeBeforeCity? address.city : address.postcode,
    ];

    return addressArr
      .filter((line) => (line || '').length)
      .map((l) => l.trim())
      .join(',\n ');
  }
}
