
<div class="popper-info-content" *ngIf="display" [ngClass]="{
      'longVersion': isCheckoutPage
    }" #popoverInfoContent role="popover">
  <div class="popper-info-content__arrow"></div>
  <div class="popper-info-content__body">
    <div class="popper-info-content__body-content">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="popper-info-content__body-close"><img src="/assets/brand-icons/x.svg" (click)="hidden()"></div>
</div>