import { Component, OnInit, Input } from '@angular/core';
import { Product } from 'Shared/classes/product';
import { Price } from 'Shared/classes/price';

@Component({
  selector: 'bw-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss']
})
export class ProductPriceComponent implements OnInit {
  @Input() product: Product;
  @Input() showOriginal: boolean = true;
  @Input() frequency: string;

  price: Price;
  isDiscounted: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (this.frequency) {
      this.price = this.product.getPriceFor(-1, parseInt(this.frequency, 10));
    }

    this.price = this.price || this.product.getPrice();
    this.isDiscounted = !!this.price.discount;
  }
}
