import * as clone from 'clone';
import * as dayjs from 'dayjs';
import { Price } from 'Shared/classes/price';

export type AddonType = 'standard_gift_card' | 'premium_gift_card' | 'gift_card' | 'default' | 'vase' | 'pot' | 'PRODUCT_CARD';

const greetingCardTypes: AddonType[] = ['premium_gift_card', 'standard_gift_card', 'gift_card'];

export class Addon {
  id: number;
  type: AddonType;
  name: string;
  slug: string;
  imageUrls: string[] = [];
  productCombinationImageUrls: string[] = [];
  tags: string[];

  description: string;
  longDescription: string;
  shortDescription: string;

  rating: {
    count: number;
    average: number;
  };

  over18Only: boolean;

  price: Price | undefined;

  appearingFrom: dayjs.Dayjs;
  appearingTo: dayjs.Dayjs;
  deliverableFrom: dayjs.Dayjs;
  deliverableTo: dayjs.Dayjs;

  isSelectable: boolean = true;
  isSelected: boolean = false;

  constructor(id?: number, slug?: string) {
    this.id = id;
    this.slug = slug;
  }

  /**
   * Is greeting card type
   * @returns
   */
  isGreetingCardType(): boolean {
    return greetingCardTypes.indexOf(this.type) > -1;
  }

  /**
   * Get the price
   * note: this is for compatibility reasons for 'Product' class
   */
  getPrice(): Price {
    return this.price;
  }

  /**
   * Clone the addon
   */
  clone(): Addon {
    return clone(this);
  }
}
