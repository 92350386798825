import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LazyLoaderService {
  observer: IntersectionObserver;

  constructor() {
    this.observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          this.onObserved(entry);
        });
      },
      {
        rootMargin: '0px 200px 200px 0px' // Bottom
      }
    );
  }

  /**
   * Function triggers once the element has been observed
   * @param elem
   */
  onObserved(elem: any): void {
    if (elem.isIntersecting) {
      elem.target.component.load();
      this.observer.unobserve(elem.target);
    }
  }

  /**
   * Add the component to the lazy load queue
   * @param component
   */
  addToQueue(component: any): void {
    // Firstly, create a reference to the original component
    component.elem.component = component;

    // Then observe the element
    this.observer.observe(component.elem);
  }

  /**
   * Unqueue
   * @param id
   */
  unqueue(component: any): void {
    this.observer.unobserve(component.elem);
  }
}
