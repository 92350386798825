import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Delivery } from 'Shared/classes/delivery';
import { ContentfulFormService } from 'Content/services/contentful-form.service';
import { UserService, User } from 'Shared/services/user.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { BwForm, BwFormControl, BwFormDefintion } from 'Shared/classes/bw-form';
import { Error } from 'Shared/classes/error';
import { ModalScreen, Resolution } from 'Shared/components/help-center/hc-modal';

const ADDITIONAL_FEEDBACK_LESS_THAN = 4;

const FORM_ENTRY_KEYS = {
  delivery_tracking_feedback: 'feedback_delivery_tracking',
  delivery_feedback: 'feedback_hc_delivery_self_serve',
  quality_feedback: 'feedback_hc_quality_self_serve'
};

type FeedbackTypes = keyof typeof FORM_ENTRY_KEYS;

type FeedbackControls = {
  score: BwFormControl<number>;
  message: BwFormControl<string>;
};

/* bw:view-encapsulation */
@Component({
  selector: 'bw-feedback',
  templateUrl: './feedback.component.html'
})
export class FeedbackComponent extends BwForm<FeedbackControls> implements OnInit, OnDestroy {
  @Input() delivery: Delivery;
  @Input() question: string;
  @Input() showMoreStack: boolean = false; // show both stars and message at the same time
  @Input() type: FeedbackTypes;
  @Input() modalScreen: ModalScreen;

  // Related to HC Quality Self Serve
  @Input() issue: string;
  @Input() stems: string[];
  @Input() resolution: Resolution;
  @Input() issueOptionId: string;
  @Input() userType: string;

  user: User;
  totalPoints: number = 0;
  maxTotalPoints = [1, 2, 3, 4, 5];

  isLoading: boolean = false;
  showMoreFeedback: boolean = false;
  submitted: boolean = false;
  message: string = '';

  @Output() didSubmit: EventEmitter<boolean> = new EventEmitter();
  @Output() touchedStars: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private contentfulFormService: ContentfulFormService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private toastr: ToastrService
  ) {
    super();
  }

  /**
   * Build Form - BwForm required implementation
   */
  buildForm(): BwFormDefintion<FeedbackControls> {
    return {
      score: new FormControl(0, Validators.required),
      message: new FormControl('')
    };
  }

  /**
   * BwForm required implementation
   */
  getObject(): void {}

  /**
   * BwForm required implementation
   */
  setObject(): void {}

  /**
   * Add the point score
   */
  submitBasicFeedback(point: number): Promise<any> {
    this.showMoreFeedback = point < ADDITIONAL_FEEDBACK_LESS_THAN;
    this.setTotalPoint(point);
    this.touchedStars.emit(this.showMoreFeedback);
    return this.showMoreFeedback ? Promise.resolve(true) : this.submit();
  }

  /**
   * Toggle show more feedback
   */
  shareMore(): void {
    this.showMoreFeedback = true;
  }

  /**
   * Set the total points
   */
  setTotalPoint(point: number): void {
    this.get('score').setValue(point);
    this.totalPoints = point;
  }

  /**
   * Submit the feedback
   */
  submit(): Promise<void> {
    return this.rate().then(() => {
      this.submitted = true;
    });
  }

  /**
   * Heap Tracking
   */
  heapTracking(eventName: string, rate: number): void {
    this.analyticsService.trackInHeap(eventName, {
      isLoggedIn: this.user.isLoggedIn(),
      feedbackRaiting: rate
    });
  }

  /**
   * Send the rating
   * @param delivery
   * @param rating
   * @param text
   */
  rate(): Promise<void> {
    const formData = {
      rating: this.get('score').value,
      text: this.get('message').value,
      userSlug: this.user.slug,
      modalScreen: this.modalScreen,

      // For HC Quality Self Serve Modal
      quality_issue: this.issue,
      quality_stems: this.stems,
      quality_resolution: this.resolution,
      quality_issue_option_id: this.issueOptionId,
      quality_userType: this.userType
    };

    if (this.delivery) {
      formData['deliveryId'] = this.delivery.id;
      formData['deliveryStatus'] = this.delivery.state === 'delivered' ? 'delivered' : 'shipping';
    }

    this.isLoading = true;
    return this.contentfulFormService
      .submitForm(formData, FORM_ENTRY_KEYS[this.type])
      .then(() => {
        this.isLoading = false;
        this.heapTracking('components.delivery-tracking.feedback.submit', this.totalPoints);
        this.submitted = true;
        this.didSubmit.emit(false); // unpause in toastr feedback
      })
      .catch((e: Error) => {
        this.toastr.error(e.message, e.title);
      });
  }

  /**
   * On init
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.user = this.userService.getUser();
  }

  /**
   * On component destroy
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
