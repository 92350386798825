/***
 *
 * Documentation:
 * https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking
 * https://developers.facebook.com/docs/facebook-pixel/implementation/dynamic-ads
 *
 *
 */
import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { ConfigService } from 'Shared/services/config.service';
import { User } from 'Shared/classes/user';
import { Purchase } from 'Shared/classes/purchase';
import { Order } from 'Shared/classes/order';
import { Product } from 'Shared/classes/product';

@Injectable({
  providedIn: 'root'
})
export class FacebookMarketingService {
  private serviceInitialized: boolean = false;
  debug: boolean = false;
  window: any;

  facebookMarketingId: number;
  initPromise: Promise<any>;

  constructor(
    private windowRef: WindowRefService,
    private domUtils: DomUtilsService,
    private configService: ConfigService
  ) {
    this.facebookMarketingId = parseInt(this.configService.getConfig().facebookMarketingId, 10);
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<facebook-marketing>', ...args);
    }
  }

  /**
   * the FBQ call
   * @param args
   */
  fbq(...args): void {
    if (this.window.fbq && this.serviceInitialized) {
      try {
        this.window.fbq(...args);
        this.log(...args);
      } catch (e) {}
    }
  }

  /**
   * Add an order to the purchase
   * @param productTrackArr -> Note different format to usual, see analytics.service
   */
  addToPurchase(order: Order): void {
    const products = [];
    products.push({
      // Standard attributes
      id: order.product.id,
      quantity: 1,
      item_price: (order.product.getPrice().original / 100).toFixed(2),
      item_name: order.product.name,

      // Other attributes
      item_price_original: (order.product.getPrice().original / 100).toFixed(2),
      item_price_revenue: (order.product.getPrice().price / 100).toFixed(2),
      item_price_discount: (order.product.getPrice().discount / 100).toFixed(2),
      variant: order.getTrackedDurationName(),
      total_deliveries: order.getTotalDeliveries()
    });

    (order.addons || []).forEach((addon) => {
      products.push({
        id: addon.id,
        quantity: 1,
        item_price: (addon.getPrice().original / 100).toFixed(2),
        item_name: addon.name
      });
    });

    let total = 0;
    products.forEach((p) => {
      total += parseFloat(p.item_price);
    });

    const obj = {
      value: total.toFixed(2),
      currency: order.product.getPrice().currency,
      content_type: 'product',
      content_ids: products.map((p) => p.id),
      contents: products
    };
    this.fbq('track', 'AddToCart', obj);
  }

  /**
   * Select a product
   * @param product
   */
  selectProduct(product: Product): void {
    const obj = {
      value: (product.getPrice().original / 100).toFixed(2),
      currency: product.getPrice().currency,
      content_type: 'product',
      content_ids: [product.id],
      contents: [
        {
          quantity: 1,
          item_price: (product.getPrice().original / 100).toFixed(2),
          item_name: product.name,
          id: product.id,

          // Additional attributes
          item_price_original: (product.getPrice().original / 100).toFixed(2),
          item_price_revenue: (product.getPrice().price / 100).toFixed(2),
          item_price_discount: (product.getPrice().discount / 100).toFixed(2)
        }
      ]
    };
    this.fbq('track', 'ViewContent', obj);
  }

  /**
   * Set the user properties
   * @param obj
   */
  setUserProperties(obj: any): void {
    this.fbq('setUserProperties', this.facebookMarketingId, obj);
  }

  /**
   * Identify and re-init the facebook pixel
   * @param user
   */
  identify(user: User): void {
    this.fbq('init', this.facebookMarketingId, { uid: user.slug });
  }

  /**
   * Confirm a purchase (Confirmation page)
   * @param purchase
   */
  confirmPurchase(purchase: Purchase): void {
    const products = [];
    const subscriptions = [];

    purchase.orders.forEach((order) => {
      products.push({
        // Standard attributes
        id: order.product.id,
        quantity: 1,
        item_price: (order.price.original / 100).toFixed(2),
        item_name: order.product.name,

        // Other attributes
        item_price_original: (order.price.original / 100).toFixed(2),
        item_price_revenue: (order.price.price / 100).toFixed(2),
        item_price_discount: (order.price.discount / 100).toFixed(2),
        variant: order.getTrackedDurationName(),
        total_deliveries: order.getTotalDeliveries()
      });
      (order.addons || []).forEach((addon) => {
        products.push({
          id: addon.id,
          quantity: 1,
          item_price: 0, // the backend doesn't tell us the price of the addon via v3/purchases
          item_name: addon.name
        });
      });

      // Build subscriptions obj for tracking
      if (order.getTrackedDurationName() === 'Subscription') {
        subscriptions.push({
          subscription_id: order.id,
          currency: purchase.price.currency,
          value: (order.price.price / 100).toFixed(2)
        });
      }
    });

    const obj = {
      value: (purchase.price.price / 100).toFixed(2),
      currency: purchase.price.currency,
      content_type: 'product',
      content_ids: products.map((product) => product.id),
      contents: products
    };

    // track Purchase
    this.fbq('track', 'Purchase', obj);

    // track Subscription
    subscriptions.forEach((subscription) => this.fbq('track', 'Subscribe', subscription));
  }

  /**
   * View the Product Modal
   * @param product
   */
  viewProductModal(product: Product): void {
    const obj = {
      value: (product.getPrice().price / 100).toFixed(2),
      currency: product.getPrice().currency,
      content_type: 'product',
      content_ids: [product.id],
      contents: [
        {
          quantity: 1,
          item_price: (product.getPrice().price / 100).toFixed(2),
          item_name: product.name,
          id: product.id,

          // Additional
          item_price_original: (product.getPrice().original / 100).toFixed(2),
          item_price_revenue: (product.getPrice().price / 100).toFixed(2),
          item_price_discount: (product.getPrice().discount / 100).toFixed(2)
        }
      ]
    };
    this.fbq('trackCustom', 'ProductModal', obj);
  }

  /**
   * View products
   * @param products
   * @param listTypeValue eg tag/letterbox
   */
  viewProducts(products: Product[], listTypeValue: string): void {
    const obj = {
      content_type: 'product',
      content_ids: products.map((product) => product.id),
      currency: products[0].type !== 'fake_product' ? products[0].getPrice().currency : undefined,
      search_string: listTypeValue,
      contents: products.map((product) => ({
        quantity: 1,
        item_price:
          product.type !== 'fake_product' ? (product.getPrice().price / 100).toFixed(2) : undefined,
        item_name: product.name,
        id: product.id
      }))
    };

    this.fbq('track', 'Search', obj);
  }

  /**
   * Started payment flow
   */
  startedPayment(): void {
    this.fbq('track', 'AddPaymentInfo', {});
  }

  /**
   * General init, if cached, return
   */
  init(): Promise<any> {
    this.initPromise =
      this.initPromise ||
      this.domUtils
        .loadScript('https://connect.facebook.net/en_US/fbevents.js', 'fbmarketing')
        .then(() => {
          this.serviceInitialized = true;
          this.fbq('init', this.facebookMarketingId);
          this.trackPage();
        });
    return this.initPromise;
  }

  /**
   * Track a standard page view
   */
  trackPage(): void {
    this.fbq('track', 'PageView');
  }
}
