import { NgModule, ErrorHandler } from '@angular/core';
import { Location } from '@angular/common';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { SharedModule } from 'Shared/shared.module';
import { ContentModule } from 'Project/content/content.module';
import { AppComponent } from 'Shared/components/app/app.component';
import { FooterComponent } from 'Shared/components/footer/footer.component';
import { NavComponent } from 'Shared/components/nav/nav.component';
import { ConfirmModalComponent } from 'Shared/components/modals/confirm-modal/confirm-modal.component';
import { AccountBurgerMenuComponent } from 'Shared/components/modals/account-burger-menu/account-burger-menu.component';
import { BasketModalComponent } from 'Shared/components/modals/basket-modal/basket-modal.component';
import { ContentModalComponent } from 'Content/components/content-modal/content-modal.component';
import { StickyScrollMonitorDirective } from 'Shared/directives/sticky-scroll-monitor.directive';
import Bugsnag from '@bugsnag/js';
import { environment } from 'Environments/environment';
import { BwToastrModule } from 'Base/app/toastr/toastr.module';
import { CustomRouteReuseStrategy } from 'Shared/guards/custom-route-reuse.strategy';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactUsModalComponent } from 'Shared/components/modals/contact-us-modal/contact-us-modal.component';
import { states as contentStates } from 'Project/content/content.routing';
import { lazyStates } from 'Environments/known-states';
import { SubscriptionCancelModalComponent } from 'Account/components/subscription-cancel-modal/subscription-cancel-modal.component';
import { SubscriptionCancelFormComponent } from 'Account/components/subscription-cancel-modal/subscription-cancel-form/subscription-cancel-form.component';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

// configure Bugsnag ASAP, before any other imports
if (environment.bugSnagEnabled) {
  Bugsnag.start({
    apiKey: environment.bugSnagApiKey,
    appVersion: window['bwVersion'],
    enabledReleaseStages: ['production', 'staging'],
    releaseStage: environment.environment,
    appType: `frontend/${environment.brandName}/${environment.countrySite}`,
    autoTrackSessions: true,
    enabledErrorTypes: {
      unhandledExceptions: true,
      unhandledRejections: false
    },
    trackInlineScripts: false,
    metadata: {
      site: {
        name: environment.brandShort,
        country: environment.countrySite,
        version: window['bwVersion']
      }
    },
    generateAnonymousId: false
  });
}

export class BwErrorHandler implements ErrorHandler {
  handleError(error: Error): void {
    const errorStr = error.toString();
    const silencedErrors = ['ExpressionChangedAfterItHasBeenCheckedError'];
    const errorIsSilenced = silencedErrors.some((silencedError): boolean => errorStr.includes(silencedError));
    if (!errorIsSilenced) {
      console.error(error);
    }
  }
}

/**
 * Error Handler Function per environment
 */
export function errorHandlerFactory(): ErrorHandler {
  return environment.environment === 'development' ? new BwErrorHandler() : new BugsnagErrorHandler();
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    // UIRouterModule,
    RouterModule.forRoot([...lazyStates, ...contentStates], {
      enableTracing: false,
      scrollPositionRestoration: 'top'
    }),
    BwToastrModule,
    SharedModule,
    ContentModule // Last so that it's catch all route is lowest priority
  ],
  declarations: [
    AppComponent,
    NavComponent,
    ConfirmModalComponent,
    ContactUsModalComponent,
    FooterComponent,
    AccountBurgerMenuComponent,
    BasketModalComponent,
    ContentModalComponent,
    SubscriptionCancelModalComponent,
    SubscriptionCancelFormComponent,
    StickyScrollMonitorDirective
  ],
  exports: [RouterModule, StickyScrollMonitorDirective],
  providers: [
    Location,
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
