import { Injectable } from '@angular/core';
import { DeliveryDate } from 'Shared/classes/delivery-date';
import * as dayjs from 'dayjs';
import { Product } from 'Shared/classes/product';

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {
  /**
   * From date String (YYYY-MM-DD)
   * @param dateStr
   */
  static fromString(dateStr: string | dayjs.Dayjs | undefined): dayjs.Dayjs | undefined {
    if (!dateStr) {
      return undefined;
    }
    // Accidetnally passed in an instance? Just check it's valid first...
    if (typeof dateStr !== 'string') {
      return dateStr.isValid() ? dateStr : undefined;
    }

    const date = dateStr ? dayjs(dateStr) : undefined;
    return date && date.isValid() ? date : undefined;
  }

  /**
   * Get the dates within a product's deliverable from/to date
   * @param product
   * @param requestedDate
   */
  static dateRangeForProduct(product: Product, serverTime: dayjs.Dayjs, idealDate: dayjs.Dayjs): { start: dayjs.Dayjs; end: dayjs.Dayjs } {
    let start: dayjs.Dayjs;
    let end: dayjs.Dayjs;

    if (idealDate.isAfter(product.deliverableTo)) {
      end = product.deliverableTo.clone().add(1, 'day');
      start = end
        .clone()
        .subtract(15, 'day') // to take into account the whole month before
        .startOf('month');
      return { start, end };
    }
    const validDate = product.deliverableFrom.isBefore(serverTime) ? serverTime : product.deliverableFrom;
    return DateUtilsService.dateRange(validDate);
  }

  /**
   * A basic date range, with our usual "buffer" to enhance API caches
   * @param date
   */
  static dateRange(date: dayjs.Dayjs): { start: dayjs.Dayjs; end: dayjs.Dayjs } {
    const start = date.clone().startOf('month');
    // prettier-ignore
    const end = start.clone().endOf('month').add(10, 'day');
    return { start, end };
  }

  /**
   * Get the nearest free avaiable date
   * @param deliveryDates
   * @param date
   */
  static getNearestFreeAvailableTo(deliveryDates: DeliveryDate[], date: dayjs.Dayjs): DeliveryDate {
    const freeDates = (deliveryDates || [])
      .slice()
      .filter((d) => d.shippingOptions.find((option) => option.price && option.price.price === 0));

    return freeDates.length > 0 ? DateUtilsService.getNearestAvailableTo(freeDates, date) : undefined;
  }

  /**
   * Get the nearest date that is available
   * @param deliveryDates
   * @param date
   */
  static getNearestAvailableTo(deliveryDates: DeliveryDate[], date: dayjs.Dayjs): DeliveryDate {
    const differences = (deliveryDates || [])
      .filter((d) => d.shippingOptions && d.shippingOptions.length)
      .map((d) => ({
        diff: Math.abs(d.date.startOf('day').diff(date, 'millisecond')),
        obj: d
      }))
      .sort((a, b) => a.diff - b.diff);

    return differences.length ? differences[0].obj : undefined;
  }

  /**
   * Get the nearest and cheapest available date
   * @param {DeliveryDate[]} deliveryDates
   * @param {dayjs.Dayjs} date
   * @returns {DeliveryDate}
   */
  static getNearestAndCheapestAvailableTo(deliveryDates: DeliveryDate[], date: dayjs.Dayjs): DeliveryDate {
    // fetch the nearest available date
    const nearestDeliveryDate = DateUtilsService.getNearestAvailableTo(deliveryDates, date);

    if (nearestDeliveryDate) {
      // Checks if the next available day has cheaper default shipping option
      // if not default to the nearest available day
      const index = deliveryDates.findIndex((d): boolean => d.date.isSame(nearestDeliveryDate.date));
      const nextDeliveryDate = deliveryDates[index + 1];

      if (!nextDeliveryDate?.shippingOptions?.length) {
        return nearestDeliveryDate;
      }

      return nextDeliveryDate.shippingOptions[0].price.price < nearestDeliveryDate.shippingOptions[0].price.price
        ? nextDeliveryDate
        : nearestDeliveryDate;
    }

    return undefined;
  }

  /**
   * Reset a date's time to midnight
   */
  dateToMidnight(date: Date): Date {
    // TODO Consider for UTC and such and such

    const d = date;
    d.setHours(0, 0, 0, 0);
    return d;
  }

  /**
   * Check whether d1 is before d2, ignoring time.
   */
  dayIsBeforeDay(d1: Date, d2: Date): boolean {
    const d1Reset = this.dateToMidnight(d1);
    const d2Reset = this.dateToMidnight(d2);
    return d1Reset.getTime() < d2Reset.getTime();
  }

  constructor() {}
}
