import * as dayjs from 'dayjs';

export class ServiceBarMessage {
  active: boolean;
  message: string = '';
  tags: string[] = [];

  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
  daysVisible: number[];

  backgroundColour: string;
  textColour: string;
  weight: number;

  deviceSettings: {
    hideOnMobile: boolean;
    hideOnDesktop: boolean;
  };
}
