
<div class="bw-nav page-category--{{pageCategory}}" [hidden]="!displayNav" id="site-nav" [ngClass]="{'sticky sticky-desktop sticky-mobile-limited': pageCategory === 'home'}" bwStickyScrollMonitor>
  <div class="bw-nav__buy-more" *featureEnabled="'CONFIRMATION_BUY_MORE_STATUS_BAR'">
    <bw-keep-shopping></bw-keep-shopping>
  </div>
  <div class="bw-nav__service-bar">
    <bw-service-bar *ngIf="show &amp;&amp; !(showDiscountBar$ | async)" (loaded)="serviceBarDidLoad()"></bw-service-bar>
  </div>
  <div class="bw-nav__discount-bar" *featureEnabled="'DISCOUNT_BAR'">
    <bw-discount-bar *ngIf="(showDiscountBar$ | async)" (loaded)="serviceBarDidLoad()"></bw-discount-bar>
  </div>
  <!-- On checkout start hide the nav and header-->
  <ng-container *ngIf="!hideNavigation">
    <div class="bw-navbar__container position-relative container is--sticky-header" role="navigation" aria-labelledby="navSite" [ngClass]="{'has-orders-in-basket' : (purchase$ | async)?.orders?.length &gt; 0}">
      <div class="bw-navbar bw-navbar__top" [ngClass]="{'has-orders-in-basket' : (purchase$ | async)?.orders?.length &gt; 0, 'bw-nav__top--bottom-border': visualCategoryNavEnabled}">
        <div class="bw-navbar__left">
          <!-- Burger Menu (Mobile only)-->
          <div class="bw-navbar__item hidden-sm hidden-md hidden-lg">
            <button class="bwIconButton" (click)="openBurgerMenu()" bwTrackAs="component.nav.burgerMenu.open" aria-label="Open Menu" type="button" tabindex="0">
              <bw-icon iconType="burger"></bw-icon>
            </button>
          </div>
          <!-- Login / Account Menu-->
          <div class="bw-navbar__item bw-navbar__item--account"><a class="bwIconButton" (click)="accountClicked()" bwTrackAs="component.bw-nav.login_or_register">
              <!-- Person icon-->
              <div class="icon">
                <bw-icon [iconType]="(user$ | async)?.loggedIn ? 'user__active' : 'user'"></bw-icon>
              </div>
              <!-- Login or register text-->
              <!-- Hidden on Mobile-->
              <!-- Visible on Desktop if user logged out--><span class="bwIconButton__label hidden-xs hidden-sm" *ngIf="!(user$ | async)?.loggedIn">Log in / Register</span>
              <!-- User credit balance-->
              <!-- Visible if logged in user has credit--><span class="bw-font-body-small credit-label" *ngIf="(user$ | async)?.loggedIn &amp;&amp; (user$ | async)?.hasCredit()">Credit<span class="credit-colon ml-1 hidden-xs hidden-sm">:</span><span class="bwIconButton__label strong">{{(user$ | async) | userCredit:currentCurrency | price}}</span></span></a>
            <!-- User credit apply button-->
            <bw-button class="hidden-xs hidden-sm" *ngIf="(user$ | async)?.loggedIn &amp;&amp; (user$ | async)?.hasCredit()" buttonStyle="modal-primary" size="small" (click)="creditOverlayToggle()" bwTrackAs="header.credit.apply">Apply</bw-button>
            <bw-feature-banner class="feature-credit-banner" *ngIf="showCreditOverlay" name="creditNavBanner" ctaText="Shop Now" contentText="You can apply credit &lt;br/&gt; at checkout" titleImage="/assets/branded-icons/yay-{{site}}.svg" (didClickCta)="creditOverlayToggle()" (didDismiss)="creditOverlayToggle()"></bw-feature-banner>
            <bw-i18n-selector class="hidden-sm hidden-xs" (siteChanged)="ngOnInit()"></bw-i18n-selector>
          </div>
        </div>
        <div class="bw-navbar__center">
          <!-- Main Logo--><a uiSref="homepage"><img class="logo_logo" src="assets/branded-icons/logo.svg" alt="Bloom &amp; Wild"></a>
        </div>
        <div class="bw-navbar__right">
          <div class="bw-navbar__item">
            <ui-stack stack_direction="x" stack_align="center" stack_gap="near">
              <ng-container *inExperiment="{name: 'THUISWINKEL_CERTIFICATION', variants: [1]}"><a *ngIf="(viewportSizeIs$ | async)?.desktop || (viewportSizeIs$ | async)?.largeTablet" href="https://www.thuiswinkel.org/leden/bloomon/certificaat/" bwTrackAs="thuiswinkel.cta-clicked" aria-label="Thuiswinkel"><img class="thuiswinkel-logo" src="/assets/brand-icons/Thuiswinkel_Colour_Horizontal.svg" alt="Thuiswinkel Logo"></a></ng-container>
              <!-- Favourites Heart-->
              <bw-favourites-indicator class="favourites-animation-destination" bwTrackAs="component.nav.favourite"></bw-favourites-indicator>
            </ui-stack>
          </div>
          <!-- Basket-->
          <div class="bw-navbar__item bw-nav__basket"><a class="bwIconButton" (click)="basketMenu()" bwTrackAs="component.bw-nav.auto_key.string_5">
              <bw-icon [iconType]="((purchase$ | async)?.orders?.length) ? 'basket__active' : 'basket'"></bw-icon><span class="bwIconButton__label hidden-xs hidden-sm">Basket</span><span *ngIf="(purchase$ | async)?.orders?.length"><span class="hidden-xs hidden-sm ml-1">:</span><span class="ml-1">{{(purchase$ | async)?.orders?.length}}</span></span></a></div>
          <div class="bw-nav__shop">
            <div class="hidden-xs">
              <div *ngIf="!isFullClosure">
                <bw-button buttonStyle="primary" size="medium" uiSref="checkout.base" bwTrackAs="component.bw-nav.auto_key.string_3">Shop now</bw-button>
              </div>
              <div *ngIf="isFullClosure">
                <bw-button buttonStyle="primary" size="medium" href="/help" bwTrackAs="component.bw-nav.auto_key.string_6">Get help fast</bw-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mega Nav-->
    <div class="bw-mega-nav container">
      <bw-mega-nav (filterDidToggle)="launchMobileFilters()" [showFilters$]="showFilters$" *ngIf="show"></bw-mega-nav>
    </div>
  </ng-container>
</div>
<bw-delivery-tracking *ngIf="showDeliveryTracking" [isOpen]="isInitialPage"></bw-delivery-tracking>