const getFormStructureFromHTML = html => {
  const fields = [];
  [].slice.call(html.querySelectorAll('.form-group')).forEach(field => {
    const control = field.querySelector('.form-control, input');
    if (!control) {
      return;
    }

    fields.push({
      name: control.getAttribute('name'),
      required: !!control.getAttribute('required'),
      type: control.getAttribute('type'),
      label: field.querySelector('label').innerText,
      maxLength: control.getAttribute('maxLength'),
      requiredText: control.getAttribute('requiredText')
    });
  });

  return fields;
};

export default function bwContentfulFormInterceptor(form, doc?): any {
  let key: string = '';
  const attributes = [].slice.call(form.attributes);

  // // loops thround the form's attributes to find the key
  attributes.forEach(attribute => {
    if (attribute.nodeName === 'key') {
      key = attribute.value;
    }
  });

  // // grabs the formFields from the DOM using the key as the ID
  const formElem = (doc || document).getElementById(key);
  if (!key || !formElem) {
    return;
  }

  // // Finally, get the strucure as JSON, then pass into the form
  const fields = getFormStructureFromHTML(formElem);

  form.innerHTML = '';
  form.setAttribute('structure', JSON.stringify(fields));

  return form;
}
