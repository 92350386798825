import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CountryService } from 'Shared/services/country.service';
import { lastValueFrom } from 'rxjs';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-sitemap',
  templateUrl: './sitemap.component.html'
})
export class SitemapComponent implements OnInit {
  links: string[];

  constructor(private http: HttpClient, private countryService: CountryService) {}

  ngOnInit(): Promise<void> {
    const url = this.countryService.siteConfigValue('sitemap.file');
    return lastValueFrom(this.http.get(url)).then((links: string[]): void => {
      this.links = links;
    });
  }
}
