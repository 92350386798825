import * as dayjs from 'dayjs';

import { Injectable } from '@angular/core';
import { Product } from 'Shared/classes/product';

import { FilterItem, FilterGroup } from 'Shared/classes/filter';
import { BehaviorSubject, Subject } from 'rxjs';
import { FilterModelService } from 'Checkout/models/filter-model.service';
import { Country } from 'Shared/classes/country';
import { LocationService } from 'Shared/services/location.service';
import { HotjarService } from 'Shared/services/third-parties/hotjar.service';
import { UserService } from 'Shared/services/user.service';
import { HeapService } from 'Shared/services/third-parties/heap.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  showFilters$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  launchFiltersModal$: Subject<boolean> = new Subject();
  resetActiveFilters$: Subject<boolean> = new Subject();
  removeFilters$: Subject<FilterItem[]> = new Subject();
  openDatePicker: boolean = false;

  constructor(
    public filterModelService: FilterModelService,
    private locationService: LocationService,
    private hotjarService: HotjarService,
    private userService: UserService,
    private heapService: HeapService,
    private analyticsService: AnalyticsService,
    public experimentService: ExperimentsService,
    private optimizelyService: OptimizelyService
  ) {}

  /**
   * Triggers the resetting of the filters
   */
  shouldResetFilters(): void {
    this.resetActiveFilters$.next(true);
  }

  /**
   * Should launch the filters modal
   */
  shouldLaunchFiltersModal(): void {
    this.launchFiltersModal$.next(true);
  }

  /**
   * Should show the filters
   * @param shouldShow
   */
  shouldShowFilters(shouldShow: boolean): void {
    this.showFilters$.next(shouldShow);
  }

  private newInFilter(products: Product[]): Product[] {
    return (products || []).filter((p) => !p.rating.count);
  }

  private priceRange(products: Product[], data: { from: number; to: number }): Product[] {
    const params = this.locationService.getCurrentParams();
    const isTypeSubscription = params.type && params.type === 'subscription';

    const filteredForPRice = (products || []).filter((p) => {
      if (isTypeSubscription) {
        const subscription = p.getSubscriptionPrice();
        return subscription.price >= data.from && subscription.price <= data.to;
      }
      const oneOff = p.getPrice();
      return oneOff.price >= data.from && oneOff.price <= data.to;
    });
    return filteredForPRice;
  }

  private basicStringFilter(products: Product[], groupKey: string, data: { key: string; value: string }): Product[] {
    return products.filter((p) => {
      return !!p.filterAttributes.find((attribute) => {
        const isItTho = attribute.key === groupKey && attribute.values.map((v) => v.toLowerCase()).indexOf(data.key.toLowerCase()) !== -1;
        return isItTho;
      });
    });
  }

  private tagFilter(products: Product[], data: { key: string; value: string }): Product[] {
    return products.filter((p) => p.tags?.indexOf(data.value) > -1);
  }

  private dateFilter(products: Product[], data: { key: string; value: any }): Product[] {
    if (data.value !== 'nextDay' && this.experimentService.isActive('DATE_FILTER_FROM_BE', 1)) {
      return products;
    }
    const desiredDate = data.value === 'nextDay' ? dayjs().add(1, 'day') : data.value;
    const dayAfterDesiredDate = desiredDate.add(1, 'day');
    return products.filter((p) => {
      const isDeliverableToAfterToday = p.deliverableTo.isAfter(desiredDate, 'day');
      const isDeliverableTomorrowAtTheEarliest = p.deliverableFrom.isBefore(dayAfterDesiredDate, 'day');
      return isDeliverableToAfterToday && isDeliverableTomorrowAtTheEarliest;
    });
  }

  performFilter(products: Product[], filterType: string, groupKey: string, data: any): Product[] {
    if (filterType === 'keyValue') {
      return this.basicStringFilter(products, groupKey, data);
    }

    if (filterType === 'priceRange') {
      return this.priceRange(products, data);
    }

    if (filterType === 'newIn') {
      return this.newInFilter(products);
    }

    if (filterType === 'date') {
      return this.dateFilter(products, data);
    }

    if (filterType === 'tag') {
      return this.tagFilter(products, data);
    }
  }

  previewFilter(currentFilters: FilterItem[], filterItemToPreview: FilterItem, products: Product[] = []): number {
    const previewFilters = (currentFilters || []).slice();
    previewFilters.push(filterItemToPreview);
    const activeFiltersGrouped = this.groupActiveFilters(previewFilters);
    const currentProducts = this.filterProducts(products, activeFiltersGrouped);

    let filterPreviewResults;

    const groupItem = activeFiltersGrouped.find((filterGroup) => {
      return filterGroup.key === filterItemToPreview.groupKey;
    });

    if (!(currentFilters || []).length || (groupItem && groupItem.filterItems.length > 1)) {
      filterPreviewResults = this.performFilter(
        currentProducts,
        filterItemToPreview.filterType,
        filterItemToPreview.groupKey,
        filterItemToPreview.data
      );
    } else {
      filterPreviewResults = this.filterProducts(currentProducts, activeFiltersGrouped);
    }

    filterPreviewResults.filter((item, index, self) => {
      return this.onlyUnique(item, index, self, 'id');
    });

    return filterPreviewResults.length;
  }

  filterProductsAgainstGroup(activeFilters: FilterItem[], products: Product[]): Product[] {
    const activeFiltersGrouped = this.groupActiveFilters(activeFilters);
    return this.filterProducts(products, activeFiltersGrouped) || [];
  }

  /**
   * Track filters when applied
   * @param filterItems
   * @param activeFilters
   * @param products
   */
  trackFiltersAdded(filterItems: FilterItem[], activeFilters: FilterItem[], products: Product[]): void {
    const user = this.userService.getUser();
    const listType = this.locationService.getListType();
    const length = (products || []).length;

    const changed = filterItems.map((f) => `${f.groupKey}:${f.name}`.replace(/ /gim, ''));
    const active = activeFilters.map((f) => `${f.groupKey}:${f.name}`.replace(/ /gim, ''));

    // Optimizely tracking
    this.optimizelyService.trackEvent('apply_filter');

    filterItems.forEach((filterItem) => {
      if (filterItem.groupKey === 'price') {
        this.optimizelyService.trackEvent('apply_price_filter');
      }
    });

    const ga4Info = {
      addedFiltersAlphabetical: changed.sort().join(','),
      addedFilters: changed.join(','),
      activeFilters: active.sort().join()
    };
    this.analyticsService.trackFilterOptions(ga4Info);

    this.analyticsService.trackInHeap('carouselFiltersAdded', {
      user,
      listType,
      carouselLength: length,
      addedFiltersAlphabetical: changed.sort().join(','),
      addedFilters: changed.join(','),
      activeFiltersAlphabetical: active.join(','),
      activeFilters: active.sort().join()
    });
  }

  /**
   * Track filters when removed
   * @param filterItems
   * @param activeFilters
   * @param products
   */
  trackFiltersRemoved(filterItems: FilterItem[], activeFilters: FilterItem[], products: Product[]): void {
    const user = this.userService.getUser();
    const listType = this.locationService.getListType();
    const length = (products || []).length;

    const changed = filterItems.map((f) => `${f.groupKey}:${f.name}`.replace(/ /gim, ''));
    const active = activeFilters.map((f) => `${f.groupKey}:${f.name}`.replace(/ /gim, ''));

    this.analyticsService.trackInHeap('carouselFiltersRemoved', {
      user,
      listType,
      carouselLength: length,
      removedFiltersAlphabetical: changed.sort().join(','),
      removedFilters: changed.join(','),
      activeFiltersAlphabetical: active.join(','),
      activeFilters: active.sort().join()
    });
  }

  /**
   * Apply a filter, given a list
   * @param filterItemToApply
   * @param currentFilters
   * @param products
   */
  applyFilters(
    filterItemsToApply: FilterItem[],
    currentFilters: FilterItem[],
    products: Product[]
  ): {
    activeFilters: FilterItem[];
    filteredProducts: Product[];
  } {
    let activeFilters = filterItemsToApply.concat(...currentFilters);

    // Remove duplicates(especially on the mobile filters)
    // This is due to the fact that the initProducts function is called twice
    activeFilters = (activeFilters || []).filter((item, index) => {
      return (
        index ===
        activeFilters.findIndex((filterItem) => {
          return filterItem.groupKey === item.groupKey && filterItem.key === item.key;
        })
      );
    });

    const filteredProducts = this.filterProductsAgainstGroup(activeFilters, products);
    return { activeFilters, filteredProducts };
  }

  /**
   *  Remove a filter
   * @param filterItemToRemove
   * @param currentFilters
   * @param products
   */
  removeFilters(
    filterItemsToRemove: FilterItem[],
    currentFilters: FilterItem[],
    products: Product[]
  ): {
    activeFilters: FilterItem[];
    filteredProducts: Product[];
  } {
    const changedFilters = currentFilters.filter((f) => {
      const found = filterItemsToRemove.find((toRemove) => toRemove.key === f.key && toRemove.name === f.name);
      return !found;
    });

    const filteredProducts = this.filterProductsAgainstGroup(changedFilters, products);
    return {
      filteredProducts,
      activeFilters: changedFilters
    };
  }

  onlyUnique(value: any, index: number, self: any, comparisonKey: string): boolean {
    const selfIndex = self.findIndex((arrayItem) => {
      return arrayItem[comparisonKey] === value[comparisonKey];
    });
    return selfIndex === index;
  }

  groupActiveFilters(activeFilters: FilterItem[]): FilterGroup[] {
    let filterGroups = activeFilters.map((filter) => {
      const filterGroup = new FilterGroup();
      filterGroup.key = filter.groupKey;
      return filterGroup;
    });

    filterGroups = filterGroups.filter((item, index, self) => {
      return this.onlyUnique(item, index, self, 'key');
    });

    filterGroups = filterGroups.map((filter) => {
      filter.filterItems = activeFilters.filter((filterItems) => filterItems.groupKey === filter.key);
      return filter;
    });

    return filterGroups;
  }

  filterProducts(products: Product[], activeFiltersGrouped: FilterGroup[]): Product[] {
    let filterResults = [];
    let filteredProducts = [];

    let activeFiltersCount = 0;

    (activeFiltersGrouped || []).forEach((filterGroup) => {
      // If filteredProducts has length then some filters have been applied
      // By filtering on the filteredProducts list instead of Products
      // we end up doing 'AND' type filtering 'between' groups.
      filteredProducts = filteredProducts.length ? filteredProducts : (products || []).slice();

      activeFiltersCount += filterGroup.filterItems.length;

      if (filterGroup && filterGroup.filterItems && filterGroup.filterItems.length) {
        filterGroup.filterItems.forEach((f) => {
          const results = this.performFilter(filteredProducts, f.filterType, f.groupKey, f.data);

          // For each filterItem filter over the same list of products
          // Results in an 'OR' type filter when 'within' a group
          // EG: Products = [1, 2, 3, 4, 5];
          // Filter1 = <= 3;
          // Return [1, 2, 3];
          // Filter2 = >= 5;
          // Return [5];

          (results || []).forEach((result) => {
            // Ultimately return [1, 2, 3, 5];
            // By adding results to results array
            filterResults.push(result);
          });
        });
      }

      // Remove duplicates
      filterResults = (filterResults || []).filter((item, index, self) => {
        return this.onlyUnique(item, index, self, 'id');
      });

      // Set filteredProducts with the previous group's filter results
      filteredProducts = filterResults;
      filterResults = [];
    });

    if (filteredProducts && filteredProducts.length) {
      return products.slice().filter((product) => {
        return (
          filteredProducts.findIndex((filteredProduct) => {
            return filteredProduct.id === product.id;
          }) !== -1
        );
      });
    }

    if (activeFiltersCount) {
      return [];
    }

    return products;
  }

  getFilterAttributes(country: Country): Promise<FilterGroup[]> {
    return this.filterModelService.getFilterAttributes(country);
  }

  getSpecificDateFromFilters(filters: FilterItem[]): string {
    const specificDate = filters?.find((filter) => filter.groupKey === 'specificDate' && filter.active);
    if (specificDate) {
      return specificDate.data.key;
    }

    const nextDelivery = filters?.find((filter) => filter.key === 'nextDayDelivery' && filter.active);
    if (nextDelivery) {
      return dayjs().add(1, 'day').format('YYYY-MM-DD');
    }

    return undefined;
  }

  /**
   * This function is required for the PROMINENT_FILTERS experiment,
   * it allow unrelated components to know if the date picker should be shown or hidden
   *
   * @param toggle - boolean that will set the openDatePicker variable
   */
  toggleDatePicker(toggle: boolean): void {
    this.openDatePicker = toggle;
  }
}
