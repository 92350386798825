import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { Order, Purchase, PurchaseService } from 'Checkout/services/purchase.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Error } from 'Shared/classes/error';
import { User } from 'Shared/classes/user';
import { ConfirmModalComponent } from 'Shared/components/modals/confirm-modal/confirm-modal.component';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { ModalService } from 'Shared/services/modal.service';
import { StateService } from 'Shared/services/state.service';
import { t } from 'Shared/utils/translations';
import { CheckoutService } from 'Checkout/services/checkout.service';
import { StateName } from 'Shared/services/state.service.typings';
import { RouteDefinitions } from 'Shared/services/state.service.typings';
import { AddonService } from 'Checkout/services/addon.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-purchase-basket',
  templateUrl: './purchase-basket.component.html'
})
export class PurchaseBasketComponent implements OnInit, OnDestroy {
  @Input() spaceLimited: boolean;
  @Input({ required: false }) user?: User;

  @Output() basketEmpty: EventEmitter<any> = new EventEmitter<any>();
  @Output() didRemoveOrder: EventEmitter<{ order: Order; orderIndex: number }> = new EventEmitter<{ order: Order; orderIndex: number }>();
  @Output() didClickEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  purchase$: BehaviorSubject<Purchase>;
  purchaseSubscription: Subscription;
  orders: Order[];
  productCardModalUsed: boolean = false;
  loading: boolean;
  private purchase: Purchase;

  constructor(
    private purchaseService: PurchaseService,
    private state: StateService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private analyticsService: AnalyticsService,
    private checkoutService: CheckoutService
  ) {
    this.purchase$ = this.purchaseService.purchase$;
    this.loading = false;
  }

  /**
   * Edit Order
   * @param order
   */
  editOrder(order: Order): void {
    this.didClickEdit.emit();

    const stateName = this.checkoutService.getCheckoutEditStartingPoint(order) as keyof RouteDefinitions;

    this.state.go(stateName as StateName, {
      order,
      orderId: order.id, // To populate the param
      productCardModalUsed: this.productCardModalUsed,
      isEditingOrder: stateName === 'checkout.giftOptions' ? true : undefined
    });
  }

  /**
   * Remove order
   * @param order
   */
  removeOrder(order: Order, orderIndex: number): Promise<any> {
    const initialState = {
      title: t('js.directives.checkout.bwBasket.removeFromBasket.confirmModal.title'),
      body: t('js.directives.checkout.bwBasket.removeFromBasket.confirmModal.content'),
      successText: t('js.directives.checkout.bwBasket.removeFromBasket.confirmModal.successText'),
      cancelText: t('js.directives.checkout.bwBasket.removeFromBasket.confirmModal.cancelText')
    };
    return this.modalService
      .show(ConfirmModalComponent, {
        initialState,
        class: 'modal-sm',
        dismissDisplayingModals: false
      })
      .then(() => {
        this.loading = true;
        return this.purchaseService.removeOrder(order);
      })
      .then((response: { purchase: Purchase; errors: Error[] }) => {
        const purchase: Purchase = response.purchase;
        const errors: Error[] = response.errors;

        const orderEditId =
          this.state.getCurrent().name === 'checkout.editorder' ? parseInt(this.state.getCurrent().params.orderId, 10) : null;
        this.analyticsService.removeFromBasket(order);

        // if more then 1 order in purchase  & removing the product thats currently in edit state then go to payment screen
        if (purchase.orders.length >= 1 && order.id === orderEditId) {
          return this.state.go('checkout.payment');
        }
        this.loading = false;

        if (!purchase.orders.length) {
          this.basketEmpty.emit();

          // if no orders in purchase then go to the base carousel
          return this.state.go('checkout.base');
        }

        this.didRemoveOrder.emit({ order, orderIndex });

        // if we have errors, show toastr
        if (errors?.length) {
          errors.forEach(
            (error, index) => setTimeout(() => this.toastr.error(error.message, error.title), 1000 * index) // we need a timeout so we make sure we show all the errors
          );
        }
      })
      .catch((error: Error) => {
        this.loading = false;
        if (error?.message) {
          this.toastr.error(error.message, error.title);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.purchaseSubscription) {
      this.purchaseSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    const stateData = this.state.getCurrent().data;
    this.productCardModalUsed = stateData.productCardModalUsed;
    this.purchaseSubscription = this.purchase$.subscribe((purchase) => {
      this.purchase = purchase;
      this.orders = purchase.orders;
    });
    this.analyticsService.trackViewBasket(this.purchase$.getValue());
  }
}
