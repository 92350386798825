
<div class="modal-header">
  <div class="modal-title">Delivery country & language</div>
  <button class="close" aria-label="close" (click)="onCancel()"><span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body" #bwModal>
  <div class="bwForm text-center">
    <h6 class="wide">Delivery country</h6>
    <div class="i18n-button-container">
      <ng-container *ngFor="let country of countries">
        <bw-button [buttonStyle]="selectedCountry?.id !== country?.id ? 'modal-secondary' : 'modal-primary'" [fullWidth]="true" (click)="setSelectedCountry(country)">
          <div class="icon" *ngIf="country &amp;&amp; country?.codes"><img src="assets/brand-icons/flag-{{country?.codes[0]}}.svg" alt="{{country?.name}} flag"></div><span>{{country.name}} / {{country.currencyCode}}</span>
        </bw-button>
      </ng-container>
    </div>
    <h6 class="wide">Language</h6>
    <div class="i18n-button-container">
      <bw-button *ngFor="let language of availableLanguages" (click)="setSelectedLanguage(language)" [buttonStyle]="selectedLanguage.code !== language.code ? 'modal-secondary' : 'modal-primary'" [fullWidth]="true"><span>{{language.name}}</span></bw-button>
    </div>
    <ng-container *ngIf="context!=='checkout'">
      <div class="info-copy" [ngClass]="{'opacity--0': preferredCountry?.id === selectedCountry?.id}">
        <bw-alert alertStyle="info">Availablility, prices and shipping services will be updated to reflect the local market.</bw-alert>
      </div>
    </ng-container>
    <ng-container *ngIf="context==='checkout'">
      <div class="info-copy" [ngClass]="{'opacity--0': preferredCountry?.id === selectedCountry?.id}">
        <bw-alert alertStyle="info">By clicking Save, you will be directed to the selected delivery country site. You will need to reselect your gift and go to checkout again.</bw-alert>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer hidden-sm hidden-md hidden-lg center">
  <bw-button buttonStyle="secondary" (click)="onCancel()" bwTrackAs="component.i18n.auto_key.string_5">Cancel</bw-button>
  <bw-button buttonStyle="modal-primary" (click)="onSuccess()" bwTrackAs="component.i18n.auto_key.string_6">Save</bw-button>
</div>
<div class="modal-footer hidden-xs">
  <bw-button buttonStyle="secondary" (click)="onCancel()" bwTrackAs="component.i18n.auto_key.string_5">Cancel</bw-button>
  <bw-button buttonStyle="modal-primary" (click)="onSuccess()" bwTrackAs="component.i18n.auto_key.string_6">Save</bw-button>
</div>