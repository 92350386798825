import { Component, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { ReviewsService } from 'Shared/services/reviews.service';
import { CountryService } from 'Shared/services/country.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-reviews-overview',
  templateUrl: './reviews-overview.component.html'
})
export class reviewsOverviewComponent implements OnInit {
  heading: string = '';
  detail: string = '';
  reviews: any;

  constructor(
    private elementRef: ElementRef,
    private reviewService: ReviewsService,
    private countryService: CountryService
  ) {
    this.heading = this.elementRef.nativeElement.getAttribute('header-copy') || '';
    this.detail = this.elementRef.nativeElement.getAttribute('body-copy') || '';
  }

  ngOnInit(): Promise<any> {
    const shippingCountry = this.countryService.forShipping;
    return this.reviewService.getAll(shippingCountry).then(r => {
      this.reviews = r.reviews.splice(0, 3);
    });
  }
}
