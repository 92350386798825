import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'Base/app/app.module';
import { environment } from 'Environments/environment';
import * as smoothscroll from 'smoothscroll-polyfill';
import * as dayjs from 'dayjs';
import { t } from 'Shared/utils/translations';
import { Locale } from 'Shared/classes/locale';

const locale: Locale = {
  name: environment.locale, // name String
  weekdays: [
    t('js.datepicker.label.day.sunday'),
    t('js.datepicker.label.day.monday'),
    t('js.datepicker.label.day.tuesday'),
    t('js.datepicker.label.day.wednesday'),
    t('js.datepicker.label.day.thursday'),
    t('js.datepicker.label.day.friday'),
    t('js.datepicker.label.day.saturday')
  ],
  weekdaysShort: [
    t('js.datepicker.label.short-day.sunday'),
    t('js.datepicker.label.short-day.monday'),
    t('js.datepicker.label.short-day.tuesday'),
    t('js.datepicker.label.short-day.wednesday'),
    t('js.datepicker.label.short-day.thursday'),
    t('js.datepicker.label.short-day.friday'),
    t('js.datepicker.label.short-day.saturday')
  ],
  weekStart: 1, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
  months: [
    t('js.datepicker.label.month.january'),
    t('js.datepicker.label.month.february'),
    t('js.datepicker.label.month.march'),
    t('js.datepicker.label.month.april'),
    t('js.datepicker.label.month.may'),
    t('js.datepicker.label.month.june'),
    t('js.datepicker.label.month.july'),
    t('js.datepicker.label.month.august'),
    t('js.datepicker.label.month.september'),
    t('js.datepicker.label.month.october'),
    t('js.datepicker.label.month.november'),
    t('js.datepicker.label.month.december')
  ],
  ordinal: (n) => `${n}º`, // ordinal Function (number) => return number + output
  formats: {
    // abbreviated format options allowing localization
    LTS: 'h:mm:ss A',
    LT: 'h:mm A',
    L: 'MM/DD/YYYY',
    LL: 'MMMM D, YYYY',
    LLL: 'MMMM D, YYYY h:mm A',
    LLLL: 'dddd, MMMM D, YYYY h:mm A',
    // lowercase/short, optional formats for localization
    l: 'D/M/YYYY',
    ll: 'D MMM, YYYY',
    lll: 'D MMM, YYYY h:mm A',
    llll: 'ddd, MMM D, YYYY h:mm A'
  },
  relativeTime: {
    // relative time format strings, keep %s %d as the same
    future: 'in %s', // e.g. in 2 hours, %s been replaced with 2hours
    past: '%s ago',
    s: 'a few seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours', // e.g. 2 hours, %d been replaced with 2
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years'
  },
  meridiem: (hour, minute, isLowercase) => {
    // OPTIONAL, AM/PM
    return hour > 12 ? 'PM' : 'AM';
  }
};
dayjs.locale(locale, null); // load locale for later use
export default locale;

if (environment.environment !== 'development') {
  enableProdMode();
}

if (!Object.entries) {
  Object.entries = (obj) => {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i); // preallocate the Array
    while (i--) {
      resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }

    return resArray;
  };
}

smoothscroll.polyfill();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
