import { Injectable } from '@angular/core';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { ContentService } from 'Shared/services/content.service';
import { StateService } from 'Shared/services/state.service';
import { UserService } from 'Shared/services/user.service';
import { t } from 'Shared/utils/translations';
import { PurchaseService, Purchase } from 'Checkout/services/purchase.service';
import { CountryService } from 'Shared/services/country.service';
import { Discount } from 'Shared/classes/discount';
import { DiscountMessagePipe } from 'Shared/pipes/discount-message.pipe';
import { ConfigModelService } from 'Shared/models/config-model.service';

@Injectable({
  providedIn: 'root'
})
export class InitialLoadService {
  private promise: Promise<any>;

  constructor(
    private userService: UserService,
    private contentService: ContentService,
    private stateService: StateService,
    private toastr: ToastrService,
    private countryService: CountryService,
    private purchaseService: PurchaseService,
    private discountMessagePipe: DiscountMessagePipe,
    private configModelService: ConfigModelService
  ) {}

  /**
   * Check the discount code
   * @param purchase
   * @param code
   */
  private checkDiscountCode(purchase: Purchase, code: string): Promise<any> {
    const isCheckout = this.stateService.getCurrent().name.indexOf('checkout') > -1;

    // a little hack for the Product Page, we want to apply the disocunt
    const isProductPage = this.stateService.getCurrent().name.indexOf('checkout.productpage') > -1;
    const isTreatYourselfSubsPage = this.stateService.getCurrent().name.indexOf('checkout.subscription') > -1;
    // No code - or the purchase already has a valid discount applied, or we are checkout
    // Note: on checkout, we (due to the "discount bar") logic, set onParamDiscountCode
    if ((isCheckout || !code) && !isProductPage && !isTreatYourselfSubsPage) {
      return Promise.resolve({});
    }

    if (purchase && purchase.discount && purchase.discount.code) {
      this.toastr.success(t('js.components.app.discount.messages.already.title'), t('js.components.app.discount.message.already.message'));
      return Promise.resolve({});
    }

    const country = this.countryService.forSite;
    const promise =
      purchase && purchase.id ? this.purchaseService.applyDiscount(code) : this.purchaseService.checkDiscount(new Discount(code), country);

    return promise
      .then((p) => {
        const toastrConfig = this.toastr.getToastrConfig();

        this.toastr.success(
          this.discountMessagePipe.transform(p.discount, country.currencyCode),
          t('js.components.app.discount.messsage.applied.message', p.discount.code),
          toastrConfig
        );
      })
      .catch((e) => {
        this.toastr.error(e.message, e.title);
      });
  }

  /**
   * Inital Load Check
   */
  private initialLoadCheck(): Promise<any> {
    const code = this.stateService.getInitial().params.discountCode;
    const untrustworthyLoginTokenExpired = this.stateService.getInitial().params.untrustworthyLoginTokenExpired;

    return Promise.all([
      this.userService.authenticate().catch((e) => Promise.resolve(e)), // Do nothing,
      this.contentService.getContentSegments().catch(() => Promise.resolve([])),
      this.configModelService.hasRemoteConfig()
    ]).then(([user, contentSegments]) => {
      if (user) {
        this.userService.setCurrentUser(user);
      }

      this.contentService.setCurrentSegments(contentSegments);

      if (untrustworthyLoginTokenExpired) {
        this.toastr.error(t('js.service.initial-load.untrustworthy-expired-token'));
      }

      if (!code) {
        return Promise.resolve();
      }
      // As some discount codes are for logged in users only
      // We check the discount code AFTER attempting to authenticate the user
      return this.checkDiscountCode(new Purchase(), code);
    });
  }

  /**
   * Do the initial load checks
   */
  init(): Promise<any> {
    this.promise = this.promise || this.initialLoadCheck();
    return this.promise;
  }
}
