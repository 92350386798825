import { Component, OnInit, AfterViewInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { OccasionRemindersModelService } from 'Shared/models/occasion-reminders-model.service';
import { ConfigService } from 'Shared/services/config.service';
import { WindowRefService } from 'Shared/services/window.service';
import { UserService, User } from 'Shared/services/user.service';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { LayoutBackgrounds } from 'oasys-lib/lib/components/layout/Layout';
import { OccasionLaunchComponent } from 'Project/shared/components/occasion-launch/occasion-launch.component';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-occasion-banner',
  templateUrl: './occasion-banner.component.html',
})
export class OccasionBannerComponent implements OnInit {
  @Input() occasionrebrand: boolean = false;
  occasionsCount: number;
  offerDetail = this.config.getConfig().occasionsOffers['add3credit5'];
  bannerElement: Element;
  timer;
  progress: number;
  user$: BehaviorSubject<User>;

  yellowBalloonLeft: HTMLElement;
  redBalloonLeft: HTMLElement;
  blueBalloonLeft: HTMLElement;

  redBalloonRight: HTMLElement;
  blueBalloonRight: HTMLElement;

  // Variables for the new style banner for the new contentful space
  // TODO: Once fully migrated to the new space, we should remove code relating to the existing banner
  @ViewChild(OccasionLaunchComponent) occasionLaunch:OccasionLaunchComponent
  @Input() new_banner: boolean = false;
  @Input() new_banner_background: LayoutBackgrounds;
  @Input() new_banner_cta_text: string;
  @Input() new_banner_title: string;
  @Input() new_banner_surtitle: string;
  @Input() new_banner_subtitle: string;

  constructor(
    public experimentService: ExperimentsService,
    private occasionsService: OccasionRemindersModelService,
    private config: ConfigService,
    private windowService: WindowRefService,
    private userService: UserService,
    private analyticsService: AnalyticsService
  ) {
    this.user$ = this.userService.user$;
  }

  getOccasionsLength(): Promise<number> {
    if (this.userService.getUser().isLoggedIn()) {
      return this.occasionsService.getSavedOccasions().then(occasions => {
        this.occasionsCount = occasions.length;
        return this.occasionsCount;
      });
    }

    this.occasionsCount = 0;

    return Promise.resolve(this.occasionsCount);
  }

  onSuccess(): void {
    this.getOccasionsLength();
    this.analyticsService.track('component.occasionBanner.action.add.success');
  }

  onCancel(): void {
    this.getOccasionsLength();
    this.analyticsService.track('component.occasionBanner.action.add.cancel');
  }

  subscribeToUser(): void {
    this.user$.subscribe(() => {
      this.getOccasionsLength();
    });
  }

  ngOnInit(): Promise<number> {
    this.subscribeToUser();
    return this.getOccasionsLength();
  }
}
