import { Component, Input } from '@angular/core';
import { GridProduct } from 'Shared/classes/grid-product';
import { Product } from 'Shared/classes/product';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-product-card-compact',
  templateUrl: './product-card-compact.component.html'
})
export class ProductCardCompactComponent {
  @Input() product: GridProduct | Product;
  @Input() index: number;
  @Input() showPrice: boolean;
}
