
<div class="order-summary">
  <div class="order__details">
    <div class="order__image"><img [attr.alt]="order.product?.name" [bwEcommImage]="order.product.imageUrls[0]" width="250"></div>
    <div class="order__content">
      <div class="content__info">
        <!-- Product Header-->
        <div class="info__header">
          <ui-stack class="header-left" stack_gap="tight">
            <div class="info__collection-name" *featureEnabled="'COLLECTION_NAME'">{{ order.product.collectionName }}</div>
            <div class="info__name">{{ order.product.name }}</div>
            <bw-product-card-label *ngIf="order.loyaltyBonusPointsCampaignId &amp;&amp; isLoyaltySchemeMember" [productLabels]="doublePointsLabels()"></bw-product-card-label>
          </ui-stack>
          <ng-container *inExperiment="{ name: 'PRICING_CLARITY_SLICE_1', variants: [0, 1, 3] }">
            <div class="info__price" *ngIf="order.price">{{ order.price | price }}<span class="price__discount" *ngIf="order.price.discount">{{  order.price | price:'original'  }}</span></div>
          </ng-container>
          <ng-container *inExperiment="{ name: 'PRICING_CLARITY_SLICE_1', variants: [2, 4] }">
            <ui-price *ngIf="order.price" price="{{ order.price | price }}" price_before_discount="{{ (order.price.discount ? order.price : undefined) | price: 'original' }}" price_type="primary"></ui-price>
          </ng-container>
        </div>
        <ui-box *ngIf="hasAddons || order.type !== 'oneoff'" box_fill_mode="fit" box_space="none" box_space_bottom="tight" box_space_top="tight">
          <ui-stack stack_gap="tight">
            <!-- Subscription Duration-->
            <div class="info__type" *ngIf="order.type !== 'oneoff'">{{ order.duration | duration }} Subscription, &nbsp;<span *ngIf="order.duration &gt; 0 &amp;&amp; !order.product.bundleOnly &amp;&amp; !order.product.subscriptionOnly">starting with {{ order.product.name }}</span></div>
            <!-- Addon Badge-->
            <div class="delivery__addon no-badge" *ngIf="hasAddons">
              <div class="badge badge-pill badge-light light" *ngFor="let addon of order.addons | addonType: ['standard_gift_card'] : true">+ {{ addon.name }}</div>
            </div>
          </ui-stack>
        </ui-box>
        <bw-purchase-basket-discount-message [order]="order"></bw-purchase-basket-discount-message>
      </div>
      <ui-box class="info__delivery" box_space="none" box_space_top="near" data-hj-suppress>
        <!-- Recipient-->
        <ng-container *ngIf="order?.product?.type !== 'digital_gift_voucher'">
          <p class="section-title">Recipient</p>
          <div class="delivery__address">
            <div><span class="recipient-name">To &nbsp;</span>{{ order.address.name }}</div>
            <div *ngIf="order.address.line1">
              <ng-container *ngIf="!postcodeBeforeCityEnabled"><span [innerHtml]="order.address | address: false : false : true"></span></ng-container>
              <ng-container *ngIf="postcodeBeforeCityEnabled"><span [innerHtml]="order.address | address: false : true : true"></span></ng-container>
            </div>
          </div>
        </ng-container>
        <!-- Digital Gift Voucher Recipient-->
        <ng-container *ngIf="order?.product?.type === 'digital_gift_voucher' &amp;&amp; user?.email?.address">
          <p class="section-title">Recipient</p>
          <div class="delivery__address">
            <div><span class="recipient-name">To &nbsp;</span>{{ user.email.address }}</div>
          </div>
        </ng-container>
        <!-- Delivery Details-->
        <ng-container *ngIf="order.firstDeliveryDate || order.timeslot">
          <p class="section-title">Delivery</p>
          <div class="delivery__date"><span *ngIf="!dateIsToday(order.firstDeliveryDate)">{{order.firstDeliveryDate.toDate() | localeDate:'dddd, MMMM D'}}</span><span *ngIf="dateIsToday(order.firstDeliveryDate)">Today</span><span *ngIf="order.timeslot &amp;&amp; order.timeslot.start">&nbsp; &#64; {{order.timeslot.localStart.toDate() | localeDate:'h:mma'}}</span><span *ngIf="order.timeslot &amp;&amp; order.timeslot.end">&nbsp; - {{order.timeslot.localEnd.toDate() | localeDate:'h:mma'}}</span><br><span *ngIf="order?.shippingOption">{{ order.shippingOption.name }}</span></div>
        </ng-container>
        <div class="badge badge-pill badge-light" *ngIf="order?.shippingOption?.successRateMessage"><span>{{ order.shippingOption.successRateMessage }}</span></div>
        <!-- For optional actions-->
        <ng-content select="[role=content-actions]"></ng-content>
      </ui-box>
    </div>
  </div>
</div>