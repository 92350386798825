
<div class="modal-header">
  <div class="modal-title text-heading--functional--secondary">{{title}}</div>
  <ui-button button_size="small" button_type="tertiary" button_icon="close" button_icon_placement="iconOnly" (clicked)="onCancel()"><span aria-hidden="true">&times;</span></ui-button>
</div>
<div class="modal-body" #bwModal>
  <ui-box>
    <div class="text-body--default text-body--emphasis" [innerHtml]="body"></div>
  </ui-box>
</div>
<div class="modal-footer">
  <ui-button-group #buttongroup button_group_collapse_below="none">
    <ui-button button_type="secondary" button_size="large" (clicked)="onCancel()" bwTrackAs="components.account.confirm-modal.cancel">{{cancelText}}</ui-button>
    <ui-button [button_type]="type" button_size="large" (clicked)="onSuccess()" #successButton bwTrackAs="components.account.confirm-modal.success">{{successText}}</ui-button>
  </ui-button-group>
</div>