import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'Environments/environment';

@Pipe({
  name: 'toFixed'
})
export class ToFixedPipe implements PipeTransform {
  transform(value: any, decimals: number = 0, locale?: string): string {
    let v = value;

    if (typeof v === 'string') {
      v = parseFloat(v);
    }

    v = parseFloat(v.toFixed(decimals)).toLocaleString(locale || environment.locale);

    return v;
  }
}
