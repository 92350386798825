
<div class="reviews__overview row home-reviews">
  <h3>{{heading}}</h3>
  <p>{{detail}}</p>
  <div class="reviews">
    <div class="review col-sm-4" *ngFor="let review of reviews">
      <blockquote>
        <p>{{review.review}}</p>
        <p class="name">
          <bw-review-stars [rating]="review.rating" [roundingScale]="0.25"></bw-review-stars><span>- {{review.reviewer}}</span>
        </p>
        <p class="u-font-small text-muted text-right">{{review.date | fromNow}}</p>
      </blockquote>
    </div>
  </div>
  <div class="overview__actions bwButtonGroup spaced bwRythmTop">
    <bw-button buttonStyle="modal-secondary" size="large" uiSref="reviews" bwTrackAs="component.reviews-overview.read_more">Read more reviews</bw-button>
    <bw-button buttonStyle="primary" size="large" uiSref="checkout.base" bwTrackAs="component.reviews-overview.shop">Shop</bw-button>
  </div>
</div>