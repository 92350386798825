import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import {
  MegaNavItemSectionLink,
  MegaNavItemSection,
  MegaNavItem,
  NavAnalyticsInfo
} from 'Shared/classes/mega-nav-item';
import { StateService } from 'Shared/services/state.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';

@Component({
  selector: 'bw-mega-nav-mobile-child',
  templateUrl: './mega-nav-mobile-child.component.html',
  styleUrls: ['./mega-nav-mobile-child.component.scss']
})
export class MegaNavMobileChildComponent implements OnChanges {
  @Input() childSectionLinks: any;
  @Input() childSections: any;
  @Output() navChildClicked = new EventEmitter();
  @Output() closeSecondaryNav = new EventEmitter();
  isActive: any;
  childSection: any;
  show: boolean;

  constructor(
    private state: StateService,
    private analyticsService: AnalyticsService,
    private optimizelyService: OptimizelyService,
  ) {}

  navClicked(navItem: MegaNavItemSectionLink | MegaNavItemSection): void {
    this.navIteractionTracking(navItem);
    this.state.activeChildNavPath = navItem.navPath;

    this.state.activeVisualNavPath = null;
  }

  closeNav(): void {
    this.closeSecondaryNav.emit();
  }

  /**
   *
   * @param navSections
   * @returns
   */
  getSubNavLength(navSections: MegaNavItemSection[]): number {
    let subNavLength = 0;

    (navSections || []).forEach((section) => {
      // Get the length of all sub navs
      if (section.sectionType === 'subnav') {
        subNavLength += section.links.length;
      }
    });

    // also get length of all parent section
    subNavLength += navSections?.length;

    return subNavLength;
  }

  navIteractionTracking(navItem): void {
    this.navChildClicked.emit();
    const navAnalyticsInfo = this.buildNavAnalyticsInfo(navItem);
    this.optimizelyService.trackEvent('nav_interaction');
    this.analyticsService.trackInHeap('navInteraction', navAnalyticsInfo);
    this.analyticsService.trackInteractionNav(navAnalyticsInfo);
  }

  // Build the nav analytics info object
  buildNavAnalyticsInfo(navItem): NavAnalyticsInfo {
    const baseCondition = navItem && navItem.navPath && navItem.sectionType !== 'content';

    return {
      mainNavItem: navItem.navPath[0] instanceof MegaNavItem ? navItem.navPath[0].name : undefined,
      primarySubNavItem:
        baseCondition && navItem.navPath[1] instanceof MegaNavItemSection
          ? navItem.navPath[1].name
          : undefined,
      secondarySubNavItem:
        baseCondition && navItem.navPath[2] instanceof MegaNavItemSectionLink
          ? navItem.navPath[2].name
          : undefined,
      navStyle: 'mega-nav',
      contentSpot: navItem.sectionType === 'content',
      mainNavItemNew: this.setMainNavItemNew(navItem),
      primarySubNavItemNew: this.setPrimarySubNavItemNew(baseCondition, navItem),
      secondarySubNavItemNew: this.setSecondarySubNavItemNew(baseCondition, navItem),
      subNavLength: this.getSubNavLength(this.childSectionLinks?.sections),
      subNavPosition: navItem.positionIndex
    };
  }

  ngOnChanges(change): void {
    // Check if this is the first time the user tapped on a menu
    if (!this.childSection) {
      // Set the current active menu section that is open (i.e letterbox, handtied, subscriptions etc)
      this.childSection = change.childSectionLinks.currentValue;
      setTimeout(() => {
        // Change this to apply a class to start the fade-in transition
        this.show = true;
      }, 0);
    } else {
      // If theres already a active menu section set then update this to fade-out transition
      this.show = false;
      setTimeout(() => {
        // Set new active section
        this.childSection = change.childSectionLinks.currentValue;
        // Fade that in
        this.show = true;
      }, 100);
    }
  }

  setMainNavItemNew(navItem): any {
    return (navItem.navPath[0].styles || []).indexOf('new') > -1;
  }

  setPrimarySubNavItemNew(baseCondition: boolean, navItem): any {
    return baseCondition && (navItem.navPath[1].styles || []).indexOf('new') > -1;
  }

  setSecondarySubNavItemNew(baseCondition: boolean, navItem): any {
    return (
      baseCondition && navItem.navPath[2] && (navItem.navPath[2].styles || []).indexOf('new') > -1
    );
  }
}
