import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Review } from 'Shared/classes/review';
import { Dayjs } from 'dayjs';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-review-testimonial-card',
  templateUrl: './review-testimonial-card.component.html'
})
export class ReviewTestimonialCardComponent {
  @Input() reviewData: Review;
  @Input() maxReviewTitleLength: number;
  @Input() maxReviewLength: number;
  @Input()
  reviewType: string;
}
