import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { OasysButtonModule, OasysHeadingModule, OasysLayoutModule, OasysImageModule } from 'oasys-lib';
import { ModalComponent } from 'Shared/services/modal.service';

type ModalResolveData = void;
type ModalRejectData = void;

@Component({
  selector: 'bw-rewards-info-modal',
  standalone: true,
  imports: [CommonModule, OasysButtonModule, OasysHeadingModule, OasysLayoutModule, OasysImageModule],
  templateUrl: './rewards-info-modal.component.html',
  styleUrls: ['./rewards-info-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RewardsInfoModalComponent extends ModalComponent<ModalResolveData, ModalRejectData> {
  constructor() {
    super();
  }

  /**
   * On cancel
   */
  onCancel(): void {
    super.closeAsReject();
  }
}
