
<div class="help-center">
  <!-- Tabs-->
  <ul class="help-center__tabs">
    <li class="tabs__tab tab--icon-{{tab.icon}}" *ngFor="let tab of tabs" [ngClass]="{'tab--active': activeTab?.href === tab.href }"><a [attr.href]="tab.href">{{ tab.title }}</a></li>
  </ul>
  <!-- Main container-->
  <div class="help-center__container">
    <!-- Content-->
    <div class="help-center__content" *ngIf="activeTab">
      <h1>{{activeTab.title}}</h1>
      <div class="help-center__area">
        <!-- Aside-->
        <div class="help-center__aside">
          <ul class="help-center__links">
            <li class="links__link" *ngFor="let link of activeTab?.links" [ngClass]="{'link--active': activeLink?.href === link.href }"><a [attr.href]="link.href">{{ link.title }}</a>
              <!-- Mobile content display-->
              <bw-content-spot *ngIf="showContent &amp;&amp; !(viewportSizeIs$ | async)?.desktop &amp;&amp; activeLink?.href === link.href" (contentSpotDoesNotExist)="noHelpContent()" (contentSpotLoaded)="onContentLoaded()" [urlOverride]="activeLink.href"></bw-content-spot>
            </li>
          </ul>
        </div>
        <!-- Desktop Content Display-->
        <div class="help-center__article" *ngIf="(viewportSizeIs$ | async)?.desktop">
          <bw-content-spot *ngIf="showContent" (contentSpotDoesNotExist)="noHelpContent()" (contentSpotLoaded)="onContentLoaded()" [urlOverride]="activeLink.href"></bw-content-spot>
        </div>
      </div>
    </div>
    <bw-loading-spinner *ngIf="isLoading"></bw-loading-spinner>
    <!-- 404 Help center-->
    <div class="help-center__fourohfour" *ngIf="!hasContent">
      <div class="fourohfour__holder">
        <h3>Oops! This page doesn't exist</h3>
        <p>If you need some help, just flick through our tabs above as we might have the answer you're looking for.</p>
      </div>
    </div>
  </div>
  <!-- TODO: Disable SEO when not on a link page-->
</div>