import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { ConfigService } from 'Shared/services/config.service';
import { CountryService } from 'Shared/services/country.service';

@Injectable({
  providedIn: 'root'
})
export class PeakShippingService {
  private _peakShippingCutoffDate: dayjs.Dayjs | undefined;

  constructor(
    private configService: ConfigService,
    private countryService: CountryService
  ) {}

  /**
   * @description Get peak shipping cutoff date
   * @returns {dayjs.Dayjs | undefined}
   */
  public get peakShippingCutoffDate(): dayjs.Dayjs | undefined {
    return this._peakShippingCutoffDate;
  }

  /**
   * @description Set peak shipping cutoff date0
   * @param {string} date
   */
  private set peakShippingCutoffDate(date: string) {
    this._peakShippingCutoffDate = dayjs(date);
  }

  /**
   * Check if default for date is free shipping
   * @returns {boolean}
   */
  public isDefaultFreeShipping(): boolean {
    const country = this.countryService.forShipping;
    return this.configService.getConfig().web_default_to_next_free_delivery_date[country.id];
  }

  /**
   * @description Check if free peak delivery messaging feature is enabled
   * @returns {boolean}
   */
  public isFreePeakDeliveryMessagingEnabled(): boolean {
    const items = this.configService.getConfig()?.free_peak_delivery_messaging ?? [];
    const locale = this.configService.getConfig().locale;
    const country = this.countryService.forShipping;

    const site = items.find((item): boolean => item.locale === locale && item.shipping_country_id === country.id);
    this.peakShippingCutoffDate = site?.cutoff_date ?? undefined;
    return site?.active ?? false;
  }
}
