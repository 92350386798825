import { GenericRouteDefintion } from 'Shared/services/state.service';
import { translations } from 'Environments/js-translations';
import { State } from 'Shared/classes/state';

export const helpUrl = translations['js.routing.content.help'];
export const giftVouchersUrl = translations['js.routing.gift_vouchers'];
export const shareUrl = translations['js.routing.share'];
export const subShareUrl = translations['js.routing.subs-share'];
const blogPrefix = translations['js.blog.prefix'];

export interface ContentRouteDataDefintions {
  redirectContent: GenericRouteDefintion;
  homepage: GenericRouteDefintion;
  tracking: GenericRouteDefintion;
  help: GenericRouteDefintion;
  faq: GenericRouteDefintion;
  sitemap: GenericRouteDefintion;
  branch: GenericRouteDefintion;
  reviews: GenericRouteDefintion;
  giftvouchers: GenericRouteDefintion;
  'blog.base': GenericRouteDefintion;
  'blog.tagged': GenericRouteDefintion;
  pageNotFound: GenericRouteDefintion;
  '404': GenericRouteDefintion;
  content: GenericRouteDefintion;
  initial: GenericRouteDefintion;
  share: GenericRouteDefintion;
  'share-subscription': GenericRouteDefintion;
  invite: GenericRouteDefintion;
}

/**
 * To enable AOT
 * @param dep
 */
export function hasConfigResolveFn(dep): void {
  return dep.canActivate();
}

/**
 * To enable AOT
 * @param dep
 */
export function hasContentSegmentResolveFn(dep): void {
  return dep.canActivate();
}

export function redirectToFAQ(): void {
  window.location.href = translations['js.routing.faqs'] || '/404';
}

export function contentRedirect(transition: any): any {
  return {
    state: 'content',
    params: {
      path: transition.params().path
    }
  };
}

export function getContentStates(
  refs = {
    HasContentSegment: undefined,
    ContentComponent: undefined,
    SitemapComponent: undefined,
    BranchRedirectorComponent: undefined,
    ReviewsComponent: undefined,
    HasRemoteConfigResolver: undefined,
    FaqRedirectGuard: undefined,
    ContentRedirectGuard: undefined,
    Trigger404Status: undefined,
    MultipleGuardsInSerial: undefined,
    HasInitialLoadedGuard: undefined,
    BlogsLandingPageComponent: undefined,
    ShareComponent: undefined
  }
): State[] {
  return [
    {
      name: 'redirectContent',
      path: 'content',
      canActivate: [refs.ContentRedirectGuard],
      children: [
        {
          name: 'redirectPath',
          path: '**',
          component: refs.ContentComponent
        }
      ]
    },
    {
      name: 'homepage',
      path: '',
      component: refs.ContentComponent,
      resolve: [refs.HasRemoteConfigResolver],
      canActivate: [refs.HasContentSegment]
    },
    {
      name: 'tracking',
      path: 'tracking/:trackingCode',
      data: {
        contentToLoad: 'homepage'
      },
      component: refs.ContentComponent,
      resolve: [refs.HasRemoteConfigResolver],
      canActivate: [refs.HasContentSegment]
    },
    {
      name: 'giftvouchers',
      path: `${giftVouchersUrl}`,
      component: refs.ContentComponent,
      canActivate: [refs.MultipleGuardsInSerial],
      routeGuards: [refs.HasInitialLoadedGuard],
      data: {
        preload: true,
        delay: true,
        isProductGrid: true
      } // To mark as a fake product grid to enable back button/change to work correctly
    },
    // TODO: remove after AB test
    {
      name: 'help',
      path: helpUrl,
      component: refs.ContentComponent,
      resolve: [refs.HasRemoteConfigResolver],
      canActivate: [refs.HasContentSegment],
      children: [
        {
          name: 'base',
          path: '',
          data: {
            custom404: '' // Don't have a custom 404 page to redirect too
          },
          component: refs.ContentComponent
        },
        {
          name: 'subcategory',
          path: ':tab',
          children: [
            {
              name: 'base',
              path: '',
              data: {
                custom404: ''
              },
              component: refs.ContentComponent
            },
            {
              name: 'link',
              path: ':link',
              data: {
                custom404: ''
              },
              component: refs.ContentComponent
            }
          ]
        }
      ]
    },
    {
      name: 'faq',
      path: 'faqs',
      component: refs.ContentComponent,
      resolve: [refs.HasRemoteConfigResolver],
      canActivate: [refs.HasContentSegment, refs.FaqRedirectGuard]
    },
    {
      name: 'sitemap',
      path: 'sitemap',
      component: refs.SitemapComponent,
      resolve: [refs.HasRemoteConfigResolver]
    },
    // Example branch link /branch?forward=/my-credit/:mycredit&mycredit=hello-world&_branch_match_id=435693624008117893
    // Or https://bnc.lt/7Zdc/8EjDjLvv6I?token=B1qCLVEmGLyeskDxyKGR will redirect to:
    // => /branch?forward=%2Freset-password%2F%3Atoken&token=B1qCLVEmGLyeskDxyKGR&_branch_match_id=579242491832287726
    {
      name: 'branch',
      path: 'branch',
      component: refs.BranchRedirectorComponent,
      resolve: [refs.HasRemoteConfigResolver]
    },
    {
      name: 'reviews',
      path: 'reviews',
      component: refs.ReviewsComponent,
      resolve: [refs.HasRemoteConfigResolver]
    },
    {
      name: 'blog.base',
      path: blogPrefix,
      component: refs.BlogsLandingPageComponent,
      data: { isNewSpace: true },
      resolve: [refs.HasRemoteConfigResolver]
    },
    {
      name: 'blog.tagged',
      path: `${blogPrefix}/tagged/:tagged`,
      component: refs.BlogsLandingPageComponent,
      data: { isNewSpace: true },
      resolve: [refs.HasRemoteConfigResolver]
    },
    {
      name: 'share',
      path: shareUrl,
      component: refs.ShareComponent,
      resolve: [refs.HasRemoteConfigResolver]
    },
    {
      name: 'share-subscription',
      path: subShareUrl,
      component: refs.ShareComponent,
      resolve: [refs.HasRemoteConfigResolver],
      data: {
        subscriptionCampaign: true
      }
    },
    {
      name: 'share',
      path: 'share',
      pathMatch: 'full',
      resolve: [refs.HasRemoteConfigResolver],
      redirectTo: shareUrl
    },
    {
      name: 'invite',
      path: 'invite',
      component: refs.ShareComponent,
      data: { segment: 'subscriber' },
      resolve: [refs.HasRemoteConfigResolver]
    },
    {
      name: 'pageNotFound',
      path: '',
      redirectTo: '/404',
      pathMatch: 'full',
      resolve: [refs.HasRemoteConfigResolver]
    },
    {
      name: '404',
      path: '404',
      data: {
        contentToLoad: '404'
      },
      component: refs.ContentComponent,
      resolve: [refs.HasRemoteConfigResolver, refs.Trigger404Status]
    },
    {
      name: 'content', // Keep this last always
      path: '**',
      component: refs.ContentComponent,
      resolve: [refs.HasRemoteConfigResolver],
      canActivate: [refs.HasContentSegment]
    }
  ];
}
