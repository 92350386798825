export class InAppMessage {
  extras: InAppMessageExtras;

  id: string;
  header: string;
  message: string;
  imageUrl: string;
  buttonText: string;
  backgroundColor?: string;

  constructor(message: any) {
    this.extras = message['extras'] ? message['extras'] : {};

    this.id = this.extras['msg-id'] || Math.random().toString();

    this.backgroundColor = this.extras['background-color'];

    this.buttonText =
      message['buttons'] && message['buttons'].length ? message['buttons'][0].text : null;

    this.header = message.header;

    this.message = message.message;

    this.imageUrl = message.imageUrl;
  }
}

export interface InAppMessageExtras {
  // This interface relates to key-value pairs set on braze
  // The keys should not be edited, as they will match braze campaigns
  'msg-id': string; // eg 'ctr' (click to reveal)
  'background-color': string; // eg '£5', '20%'
}
