import { Injectable } from '@angular/core';
import { BackendService } from 'Shared/services/backend.service';
import { CampaignCode } from 'Shared/classes/code';
import { PurchaseService } from 'Checkout/services/purchase.service';
import { Country } from 'Shared/classes/country';

@Injectable({
  providedIn: 'root'
})
export class CampaignCodesModelService {
  constructor(private backend: BackendService, private purchaseService: PurchaseService) {}

  fromPayload(res: any): CampaignCode {
    const code = new CampaignCode();
    code.code = res.data.attributes.code;
    code.amount = res.data.attributes.amount;
    code.currency = res.data.attributes.amount_currency;
    code.percentage = res.data.attributes.percentage;

    return code;
  }

  /**
   * Get a code
   * - Used for TV newsletter experiment. Seems a bit hacky as doesn't follow the other experiments enabled endpoints
   * - NOTE: Experiments/variants is different to the other backend API endpoints :(
   */
  getCode(
    shippingCountry?: Country
  ): Promise<CampaignCode> {
    return this.backend
      .post(
        null,
        '/v2/codes',
        {},
        {
          params: {
            shipping_country_id: shippingCountry ? shippingCountry.id : undefined
          }
        }
      )
      .then(r => (r ? this.fromPayload(r) : undefined));
  }
}
