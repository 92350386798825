
<fieldset class="bw-form-radio-input" *ngIf="bwFormControl &amp;&amp; options?.length" [ngClass]="{
    'is-invalid': bwFormControl.invalid,
    'is-valid': bwFormControl.valid,
    'is-dirty': bwFormControl.dirty,
    'is-pristine': bwFormControl.pristine,
    'is-touched': bwFormControl.touched,
    'is-untouched': bwFormControl.untouched,
    'is-submitted': hasSubmitted,
    'has-description': hasDescription
    }" [class]="'input--size-' + size">
  <label class="radio-input__container" *ngFor="let option of options" [attr.for]="option.id" [ngClass]="{'is-selected': bwFormControl.value === option.value, 'has-description': option?.description}">
    <div class="radio-input__control">
      <input class="bwForm-no-checkbox-styles" [formControl]="bwFormControl" type="radio" [attr.aria-label]="option.label" [attr.id]="option.id" [attr.name]="option.id" [value]="option.value">
    </div>
    <div class="radio-input__label">
      <div class="radio-input__label-copy" *ngIf="option.label" [innerHtml]="option.label"></div>
      <div class="radio-input__label-description" *ngIf="option.description &amp;&amp; !hideDescriptions" [innerHtml]="option.description"></div>
    </div>
  </label>
</fieldset>