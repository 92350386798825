import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { BwFormControl } from 'Shared/classes/bw-form';
import { UserLookupService } from 'Shared/services/user-lookup.service';
import { Order } from 'Shared/classes/order';
import { ConfigService } from 'Shared/services/config.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-auth-login-email',
  templateUrl: './auth-login-email.component.html'
})
export class AuthLoginEmailComponent implements OnChanges {
  @Input() email: BwFormControl<string>;
  @Input() showThirdPartySeparate: boolean = false;
  @Input() origin: 'default' | 'checkout' | 'occasions' = 'default';
  @Input() showUserGuest: boolean = false;
  @Input() showSeparator: boolean = false;
  @Input() order?: Order;
  @Input() checkEmailOnInit: boolean = false;

  @Output() didSubmit: EventEmitter<{ identifier: string; isRewardsMember?: boolean; validateForm?: boolean }> = new EventEmitter<{
    identifier: string;
    isRewardsMember?: boolean;
    validateFormControl?: boolean;
  }>();
  @Output() didSuccessLogin: EventEmitter<{ authMethod: string }> = new EventEmitter<{ authMethod: string }>();
  @Output() didFailLogin: EventEmitter<void> = new EventEmitter<void>();
  @Output() registerNewUser: EventEmitter<{ email: string; validateForm?: boolean }> = new EventEmitter<{
    email: string;
    validateForm?: boolean;
  }>();
  @Output() registerGuestUser: EventEmitter<{ email: string; user: string; validateForm?: boolean }> = new EventEmitter<{
    email: string;
    user: string;
    validateForm?: boolean;
  }>();
  @Output() didGuestUser: EventEmitter<{ email: string; user: string; validateForm?: boolean }> = new EventEmitter<{
    email: string;
    user: string;
    validateForm?: boolean;
  }>();

  loading: boolean = false;

  constructor(
    private userLookupService: UserLookupService,
    private analyticsService: AnalyticsService,
    private configService: ConfigService
  ) {}

  /**
   * On changes
   * @returns {Promise<void>}
   */
  ngOnChanges(): Promise<void> {
    if (this.checkEmailOnInit) {
      return this.submit();
    }

    return Promise.resolve();
  }

  /**
   * Track in heap
   * @param accountStatus
   */
  track(accountStatus: 'registered' | 'guest' | 'none', verificaitonDisplayed?: boolean): void {
    this.analyticsService.trackInHeap('authContinueWithEmail', {
      accountStatus,
      verificaitonDisplayed,
      origin: this.origin
    });
  }

  /**
   * Submit email to be checked
   */
  submit(): Promise<void> {
    this.email.markAsTouched();

    if (this.email.invalid) {
      return Promise.resolve();
    }

    this.loading = true;
    return this.userLookupService
      .checkUserByEmail(this.email.value)
      .then(({ identifier, guest, loyaltySchemeMembership }): void => {
        this.loading = false;
        let identifiedUserName = identifier;
        const brandId = this.configService.getConfig().brandId;
        if (brandId === 'bloomon') {
          identifiedUserName = identifiedUserName + ' ' + identifiedUserName;
        }
        if (guest) {
          this.track('guest', true);
          return this.registerGuestUser.emit({
            email: this.email.value,
            user: identifiedUserName,
            validateForm: this.checkEmailOnInit
          });
        }

        this.track('registered', false);
        return this.didSubmit.emit({ identifier, isRewardsMember: loyaltySchemeMembership, validateForm: this.checkEmailOnInit });
      })
      .catch((): void => {
        this.loading = false;
        this.track('none');
        return this.registerNewUser.emit({ email: this.email.value, validateForm: this.checkEmailOnInit });
      });
  }

  /**
   * Successful login via third party
   * @param {{ authMethod: string }} options
   */
  onSuccessLogin(options: { authMethod: string }): void {
    this.didSuccessLogin.emit(options);
  }

  /**
   * Fail to login via third party
   */
  onFailedLogin(): void {
    this.didFailLogin.emit();
  }

  /**
   * Show loading UI
   * @param {boolean} load
   */
  onLoading(load: boolean): void {
    this.loading = load;
  }

  trackGuest(hasAccount: boolean): void {
    this.analyticsService.trackInHeap('clickContinueAsGuest', {
      hasAccount
    });
  }

  onGuest(): Promise<void> {
    this.loading = true;
    this.email.markAsTouched();

    if (this.email.invalid) {
      this.loading = false;
      return Promise.resolve();
    }

    return this.userLookupService
      .checkUserByEmail(this.email.value)
      .then((data): void => {
        this.loading = false;
        this.trackGuest(true);
        return this.didGuestUser.emit({ email: this.email.value, user: data.identifier });
      })
      .catch((): void => {
        this.loading = false;
        this.trackGuest(false);
        return this.didGuestUser.emit({ email: this.email.value, user: null });
      });
  }
}
