import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { WindowRefService } from 'Shared/services/window.service';
import { Error } from 'Shared/classes/error';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { CardType } from 'Shared/classes/card';
import { Purchase } from 'Shared/classes/purchase';
import { t } from 'Shared/utils/translations';
import { UserService } from 'Shared/services/user.service';
import { LocationService } from 'Shared/services/location.service';
import { CountryService } from 'Shared/services/country.service';
import { ConfigService } from 'Shared/services/config.service';
import { Order } from '../classes/order';
import { PaymentIntent, Stripe, StripeElements, StripeElementsOptionsClientSecret, StripeError } from '@stripe/stripe-js';
import { PurchaseModelService } from 'Checkout/models/purchase-model.service';
import { StripeAccount } from 'Shared/models/config-model.service.typings';
import { BugsnagService } from './third-parties/bugsnag.service';

export interface StripeElementsData {
  /**
   * Used to confirm payments made using saved cards for a PaymentIntent
   */
  clientSecret: string;
  /**
   * Used by payment flows to validate data entry, and to confirm payments for new cards on all Intents
   */
  elements: StripeElements;
  /**
   * Used by backend API calls to update a PaymentIntent or fetched saved cards linked to a SetupIntent
   */
  intentId: number;
}

type ConfirmPaymentIntentTypes = { type: 'new'; shouldSavePaymentMethod: boolean } | { type: 'saved'; token: string };

export type ConfirmPaymentIntentDetails = ConfirmPaymentIntentTypes & {
  cardType: CardType;
  userEmail: string | undefined;
};

type ConfirmSetupIntentTypes =
  | { type: 'account' }
  | { type: 'order'; order: Order; bankRedirectType: CardType; isPrepayment: boolean }
  | { type: 'minValuePurchase'; purchase: Purchase; bankRedirectType: CardType };

export type ConfirmSetupIntentDetails = ConfirmSetupIntentTypes & { userEmail: string | undefined };

@Injectable({
  providedIn: 'root'
})
export class StripeApiService {
  private stripeLoadingPromise: Promise<Stripe> | undefined;

  constructor(
    private domUtils: DomUtilsService,
    private windowRef: WindowRefService,
    private analyticsService: AnalyticsService,
    private locationService: LocationService,
    private countryService: CountryService,
    private configService: ConfigService,
    private purchaseModelService: PurchaseModelService,
    private userService: UserService,
    private bugsnagService: BugsnagService
  ) {}

  /**
   * Parse any stripe messages into a more 'Bloom & Wild'-friendly structure
   * @param res
   * @returns {Error}
   */
  private parseStripeError({ code, type }: StripeError): Error {
    const error = new Error({
      message: t('js.payment.error.stripe.generic.message'),
      title: t('js.payment.error.stripe.generic.title'),
      code,
      kind: type
    });

    error.meta = {
      source: 'stripeErrors',
      code
    };

    return error;
  }

  /**
   * Generate a url to be used by Stripe to return users to the site after redirect
   * @param options the intended app area to return to after the redirect, along with required data
   * @returns string representing the full url to return to after the redirect
   */
  private generateRedirectUrl(
    options:
      | { type: 'purchase'; purchase: Purchase; bankRedirectType: CardType }
      | { type: 'minValuePurchase'; purchase: Purchase; bankRedirectType: CardType; intentId: number }
      | { type: 'account'; intentId: number }
      | { type: 'order'; intentId: number; order: Order; bankRedirectType: CardType; isPrepayment: boolean }
  ): string {
    const host = this.locationService.getHostWithSubfolder();
    const user = this.userService.getUser();

    const hashArgs: [string, string | number | boolean][] = [
      ['userEmail', user?.email?.address],
      ['userFullName', encodeURIComponent(user?.fullName)],
      ['userToken', user?.token],
      ['target', options.type]
    ];

    if (options.type !== 'account') {
      hashArgs.push(['bankRedirect', options.bankRedirectType]);
    }

    if (options.type !== 'purchase') {
      hashArgs.push(['intentId', options.intentId]);
    }

    if (options.type === 'purchase' || options.type === 'minValuePurchase') {
      hashArgs.push(['purchaseId', options.purchase.id], ['purchaseToken', options.purchase.token]);
    } else if (options.type === 'order') {
      const hasFailedPayment =
        options.order.subscription?.failedPaymentsData?.activeWithFailedPayment === true ||
        options.order.subscription?.failedPaymentsData?.pausedDueToFailedPayment === true;
      hashArgs.push(
        ['orderId', options.order.id],
        ['isPausedOrder', options.order.stateIs('paused')],
        ['hasFailedPayment', hasFailedPayment],
        ['isPrepayment', options.isPrepayment]
      );
    }

    return `https://${host}/restore.html#${hashArgs
      .filter(([, arg]): boolean => arg !== undefined && arg !== false)
      .map(([key, arg]): string => `${key}=${arg.toString()}`)
      .join('&')}`;
  }

  /**
   * Generate the Stripe Payment Element
   * @param purchase
   * @param options
   * @param storeCard
   */
  public async generateElementsFromPaymentIntent(
    purchaseId: number,
    purchaseToken: string,
    purchaseHasSubscriptionOrder: boolean,
    options: StripeElementsOptionsClientSecret
  ): Promise<StripeElementsData> {
    const stripe = await this.getStripeApi();
    const { id: intentId, client_secret: clientSecret } = await this.purchaseModelService.getStripePaymentIntent(
      purchaseId,
      purchaseToken,
      purchaseHasSubscriptionOrder
    );
    const elements = stripe.elements({ ...options, clientSecret });

    return {
      intentId,
      clientSecret,
      elements
    };
  }

  public async generateElementsFromSetupIntent(
    shippingCountryId: number,
    options: StripeElementsOptionsClientSecret
  ): Promise<StripeElementsData> {
    const stripe = await this.getStripeApi();
    const { id: intentId, client_secret: clientSecret } = await this.purchaseModelService.getStripeSetupIntent(shippingCountryId);
    const elements = stripe.elements({ ...options, clientSecret });

    return {
      intentId,
      clientSecret,
      elements
    };
  }

  public async confirmPaymentIntent(
    purchase: Purchase,
    details: ConfirmPaymentIntentDetails,
    stripeElementsData: StripeElementsData
  ): Promise<PaymentIntent> {
    const stripe = await this.getStripeApi();
    const confirmParams = {
      return_url: this.generateRedirectUrl({ type: 'purchase', purchase, bankRedirectType: details.cardType }),
      payment_method: details.type === 'saved' ? details.token : undefined,
      payment_method_data:
        details.userEmail === undefined
          ? undefined
          : {
              billing_details: {
                email: details.userEmail
              }
            }
    };

    if (details.type !== 'new') {
      delete confirmParams.payment_method_data;
    }

    await this.purchaseModelService.finaliseStripePaymentIntent(
      purchase,
      stripeElementsData.intentId,
      details.type === 'new' && details.shouldSavePaymentMethod
    );
    await this.fetchElementsUpdates(stripeElementsData);

    const { paymentIntent, error } = await stripe.confirmPayment({
      clientSecret: details.type === 'saved' ? stripeElementsData.clientSecret : undefined,
      elements: details.type === 'new' ? stripeElementsData.elements : undefined,
      confirmParams: confirmParams,
      redirect: 'if_required'
    });

    if (error !== undefined) {
      const processedError = this.parseStripeError(error);
      this.analyticsService.trackError(processedError);
      throw processedError;
    }

    return paymentIntent;
  }

  public async confirmSetupIntent(details: ConfirmSetupIntentDetails, stripeElementsData: StripeElementsData): Promise<void> {
    const stripe = await this.getStripeApi();

    const { error } = await stripe.confirmSetup({
      elements: stripeElementsData.elements,
      confirmParams: {
        return_url: this.generateRedirectUrl({ ...details, intentId: stripeElementsData.intentId }),
        payment_method_data:
          details.userEmail === undefined
            ? undefined
            : {
                billing_details: {
                  email: details.userEmail
                }
              }
      },
      redirect: 'if_required'
    });

    if (error !== undefined) {
      const processedError = this.parseStripeError(error);
      this.analyticsService.trackError(processedError);
      throw processedError;
    }
  }

  public async fetchElementsUpdates(stripeElementsData: StripeElementsData): Promise<void> {
    const { error: elementsError } = await stripeElementsData.elements.fetchUpdates();

    if (elementsError !== undefined) {
      const e = new Error({
        message: elementsError.message,
        code: 'stripeUpdatesError'
      });
      this.bugsnagService.logEvent(e);
      throw e;
    }
  }

  public getStripeApi(): Promise<Stripe> {
    const shippingCountry = this.countryService.forShipping;

    this.stripeLoadingPromise ||= this.domUtils
      .loadScript('https://js.stripe.com/v3/', 'stripe')
      .then((): Promise<StripeAccount> => this.configService.getStripeAccount(shippingCountry))
      .then((stripeConfig: StripeAccount): Stripe => {
        const stripeFn = this.windowRef.nativeWindow.Stripe;
        const options = stripeConfig?.stripeAccountId ? { stripeAccount: stripeConfig.stripeAccountId } : {};
        const locale = this.configService.getConfig().stripeLocale;

        return stripeFn(stripeConfig.stripeClientKey, { locale: locale, ...options }) as Stripe;
      });

    return this.stripeLoadingPromise;
  }
}
