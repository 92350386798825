import { BwForm, BwFormControl, BwFormDefintion } from 'Shared/classes/bw-form';

import { Component, OnInit, Input, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { Validators, FormControl, ValidatorFn } from '@angular/forms';
import { Address } from 'Shared/classes/address';
import { CountryService } from 'Shared/services/country.service';
import { Country } from 'Shared/classes/country';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { AvailableFeatures, FeaturesService } from 'Shared/services/features.service';
import { ShippingOption } from 'Shared/classes/shipping-option';
import { ValidateRecipientSearch } from 'Checkout/validators/validateRecipientSearch';
import { DropDownOption } from 'Shared/components/form-dropdown/form-dropdown.component';
import { I18nSelectorComponent } from 'Shared/components/i18n-selector/i18n-selector.component';
import { AvailableIcon } from 'Shared/components/icon/icon.component';

type AddressFormControls = {
  name: BwFormControl<string>;
  company: BwFormControl<string>;
  vat: BwFormControl<string>;
  line1: BwFormControl<string>;
  line2: BwFormControl<string>;
  city: BwFormControl<string>;
  postcode: BwFormControl<string>;
  country: BwFormControl<Country>;
  phone: BwFormControl<string>;
};

/* bw:view-encapsulation */
@Component({
  selector: 'bw-address-form-new',
  templateUrl: './address-form-new.component.html'
})
export class AddressFormNewComponent extends BwForm<AddressFormControls> implements OnInit, OnDestroy {
  @ViewChild('countrySelector') countrySelector: I18nSelectorComponent;

  @Input() address: Address;
  @Input() limitCountry: Country;
  @Input() shippingOption?: ShippingOption;
  @Input() hideNameField: boolean = false;
  @Input() showVatField: boolean = false;
  @Input() showChangeCountryOnInvalidPostcode: boolean = false;
  @Input() phoneNumberRequired: boolean = false;

  @Output() didChangeCountryModalOpen: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  showVatNumber: boolean = false;
  showAddressPhone: boolean = false;
  addressPhoneRequired: boolean;
  countriesOptions: DropDownOption[];
  countries: Country[];

  telephonePlaceholderPrefix: string = '';
  showDoorCodeAsLine2Label: boolean = false;
  postcodeLength: number;
  showAddressPostcodeAltCopy: boolean;
  postcodeBeforeCityEnabled: boolean;
  countryInvalidPostcode: boolean;

  constructor(
    private countryService: CountryService,
    public experimentService: ExperimentsService,
    private analyticsService: AnalyticsService,
    private featureService: FeaturesService
  ) {
    super();
    this.countries = this.countryService.getCountries();
  }

  ngOnInit(): void {
    this.getFormFeatures();
    super.ngOnInit();

    this.countries = this.limitCountry ? [this.limitCountry] : this.countries;

    this.countriesOptions = this.countries.map(
      (c): DropDownOption => ({
        value: c,
        label: c.name,
        prefixIcon: c.codes[0] as AvailableIcon
      })
    );

    const address = this.address.clone();
    address.country = this.address.country || this.countries[0];
    this.setObject(address);
  }

  /**
   * On destroy, remove from parent form
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * Get Form Features
   */
  getFormFeatures(): void {
    this.postcodeLength = this.featureService.getFeature('ADDRESS_FIELDS').postcodeLength;
    this.postcodeBeforeCityEnabled = this.featureService.getFeature('ADDRESS_FIELDS').postcodeBeforeCity;
    this.countryInvalidPostcode =
      this.featureService.getFeature('ADDRESS_FIELDS').countryInvalidPostcodeError && this.showChangeCountryOnInvalidPostcode;
  }

  /**
   * Display as the country name
   * @param country
   * @returns
   */
  displayAsCountryName(country: Country): string {
    return country.name;
  }

  /**
   * Build the form
   * @returns
   */
  buildForm(): BwFormDefintion<AddressFormControls> {
    return {
      name: new FormControl('', {
        validators: [Validators.required]
      }),
      company: new FormControl(''),
      vat: new FormControl(''),
      line1: new FormControl('', {
        validators: [Validators.required]
      }),
      line2: new FormControl(''),
      city: new FormControl('', {
        validators: [Validators.required]
      }),
      postcode: new FormControl('', {
        validators: [Validators.required]
      }),
      country: new FormControl(undefined, {
        validators: [Validators.required]
      }),
      phone: new FormControl('')
    };
  }

  /**
   * Get form value as object
   * @returns
   */
  getObject(): Address {
    const addr = new Address();
    addr.country = this.get('country').value;
    addr.name = this.get('name').value;
    addr.company = this.get('company').value;
    addr.vat = this.get('vat').value;
    addr.line1 = this.get('line1').value;
    addr.line2 = this.get('line2').value;
    addr.city = this.get('city').value;
    addr.postcode = (this.get('postcode').value || '').toUpperCase();
    addr.pcaID = undefined; // We ensure this is cleared, as we've not use PCA (or loquate) to populate the address
    addr.phone = this.get('phone').value;
    return addr;
  }

  /**
   * Update form value
   * @param object
   */
  setObject(address: Address): void {
    this.get('name').setValue(address.name);
    this.get('company').setValue(address.company);
    this.get('vat').setValue(address.vat);
    this.get('line1').setValue(address.line1);
    this.get('line2').setValue(address.line2);
    this.get('city').setValue(address.city);
    this.get('postcode').setValue(address.postcode);
    this.get('phone').setValue(address.phone);
    this.get('country').setValue(address.country);
    this.setValidatorsBasedOnCountry(address.country);

    this.runValidators();
  }

  /**
   * Submit
   */
  onSubmit(): void {
    super.markAsSubmitted();
  }

  /**
   * On phone blur
   */
  onPhoneBlur(): void {
    if (this.get('phone').valid) {
      this.analyticsService.track('checkout.deliveryDetails.phoneWasAdded');
    }
  }

  /**
   * On postcode blur, make uppercase to match regex
   */
  onPostcodeBlur(): void {
    const postcode = this.get('postcode').value || '';
    this.get('postcode').setValue(postcode.toUpperCase());
    this.runValidators();
  }

  /**
   * Dynamic set validators depending on the country
   * @param country
   */
  setValidatorsBasedOnCountry(country: Country): void {
    const countryConfig = this.featureService.getFeature('ADDRESS_FIELDS', country);

    if (this.showVatField) {
      this.showVatNumber = countryConfig.showVatNumber;
    }

    // ! Phone number requirement override
    if (this.phoneNumberRequired) {
      this.shippingOption = new ShippingOption();
      this.shippingOption.hasPhoneNumber = true;
      this.shippingOption.hasPhoneNumberRequired = true;
    }

    // Phone Validators
    this.setPhoneValidation(country);

    this.showDoorCodeAsLine2Label = !!countryConfig.showDoorCodeAsLine2Label;

    this.telephonePlaceholderPrefix = `+${country.phonePrefix}`;

    // Name Validators
    if (!this.hideNameField) {
      const nameValidators = [Validators.required];
      if (countryConfig.firstAndSecondNameRequired) {
        nameValidators.push(Validators.pattern(/\S+\s\S+/));
      }

      this.get('name').setValidators(nameValidators);
    }

    // Postcode validators
    const postcodeValidators = [];
    if (!countryConfig.ignorePostcodeValidation) {
      postcodeValidators.push(Validators.pattern(new RegExp(country.address.postcodeRegex)));
    }

    if (countryConfig.postcodeLength && countryConfig.postcodeLength > -1) {
      postcodeValidators.push(Validators.minLength(countryConfig.postcodeLength));
      postcodeValidators.push(Validators.maxLength(countryConfig.postcodeLength));
    }

    if (!countryConfig.postcodeOptional) {
      postcodeValidators.push(Validators.required);
    }

    // used for Ireland label (uses eircode and not postcode)
    this.showAddressPostcodeAltCopy = countryConfig.showAddressPostcodeAltCopy;

    this.get('postcode').setValidators(postcodeValidators);

    super.runValidators();
  }

  /**
   * On country changed
   */
  onCountryChanged(): void {
    const country = this.get('country').value;
    this.setValidatorsBasedOnCountry(country);
  }

  /**
   * On country selected
   * CHANGE_DELIVERY_COUNTRY experiment
   */
  onCountrySelected(option: DropDownOption): Promise<void> {
    if (option.value?.id === this.limitCountry?.id || !this.countrySelector) {
      return Promise.resolve();
    }

    this.didChangeCountryModalOpen.emit(true);
    return this.countrySelector.i18nModal?.(option.value).then((): void => {
      this.didChangeCountryModalOpen.emit(false);
    });
  }

  /**
   * On country selected
   * CHANGE_DELIVERY_COUNTRY experiment
   */
  private setPhoneValidation(country: Country): void {
    const countryConfig = this.featureService.getFeature('ADDRESS_FIELDS', country);

    const phoneValidations = this.getPhoneValidations();

    this.showAddressPhone = this.checkAddressPhone(countryConfig);
    this.addressPhoneRequired = this.isAddressPhoneRequired();

    const showFeature = this.featureService.getFeature('SHOW_RECIPIENT_PHONE_NUMBER');
    if (
      showFeature &&
      this.showAddressPhone &&
      this.experimentService.isActive('HIDE_THE_PHONE_NUMBER_FIELD', 1) &&
      !this.addressPhoneRequired
    ) {
      this.showAddressPhone = false;
    }

    if (this.shippingOption && this.shippingOption.hasPhoneNumberRequired) {
      phoneValidations.push(ValidateRecipientSearch.requirePhoneForShippingOption(this.shippingOption));
    }

    this.get('phone').setValidators(phoneValidations);
  }

  private getPhoneValidations(): ValidatorFn[] {
    return [Validators.pattern(/^[- +()]*[0-9][- +()0-9]*$/), Validators.minLength(7), Validators.maxLength(25)];
  }

  private checkAddressPhone(countryConfig: AvailableFeatures['ADDRESS_FIELDS']): boolean {
    return (
      (this.shippingOption && this.shippingOption.hasPhoneNumber) ||
      (this.shippingOption && this.shippingOption.hasPhoneNumberRequired) ||
      countryConfig.showAddressPhone
    );
  }

  private isAddressPhoneRequired(): boolean {
    return this.shippingOption && this.shippingOption.hasPhoneNumberRequired;
  }
}
