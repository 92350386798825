import * as clone from 'clone';

import { CurrencyCode } from 'Shared/classes/price';

export class Country {
  name: string;
  codes: string[];
  id: number;
  phonePrefix: string;
  currencyCode: CurrencyCode;
  timezone: string;
  address: {
    requiredPhone: boolean;
    requiredCity: boolean;
    requiredPostcode: boolean;
    requiredLine1: boolean;
    requiredLine2: boolean;
    postcodeRegex: string;
  };

  constructor(id?: number, name?: string, phonePrefix?: string) {
    this.id = id;
    this.name = name;
    this.phonePrefix = phonePrefix;
  }

  /**
   * Clone
   */
  clone(): Country {
    return clone(this);
  }
}
