import * as dayjs from 'dayjs';
import { Product } from 'Shared/classes/product';
import { Country } from 'Shared/classes/country';
import * as clone from 'clone';
import { GridProduct } from 'Shared/classes/grid-product';

export class Favourite {
  product: Product | GridProduct;
  id: number;
  createdAt: dayjs.Dayjs;
  isShoppable: boolean;
  country: Country;
  isGiftVoucher: boolean;

  constructor() {
    this.createdAt = dayjs();
  }

  isLocal(): boolean {
    return !this.id || !this.createdAt;
  }

  clone(): Favourite {
    return clone(this);
  }
}
