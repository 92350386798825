import { Component, OnInit } from '@angular/core';
import { ModalResponse, ModalResponseSubject } from 'Shared/services/modal.service';
import { Subject } from 'rxjs';
import { User } from 'Shared/classes/user';
import { Order } from 'Shared/classes/order';
import { Delivery } from 'Shared/classes/delivery';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-contact-us-modal',
  templateUrl: './contact-us-modal.component.html'
})
export class ContactUsModalComponent implements ModalResponse, OnInit {
  modalResponse: Subject<ModalResponseSubject> = new Subject<ModalResponseSubject>();

  reason: string;
  resolutions: string;
  user: User;
  order: Order;
  delivery: Delivery;
  formtype: string = 'general';
  issues: string;
  stems: string;
  problems: string;

  constructor(private optimizelyService: OptimizelyService) {}

  onCancel(): void {
    this.modalResponse.next({ success: false });
  }

  onSuccess(): void {
    this.modalResponse.next({
      success: true,
      data: {}
    });
  }

  ngOnInit(): void {
    // Tracking Optimizely Events
    this.optimizelyService.trackEvent('click_to_contact');
  }
}
