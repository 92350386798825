import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BwFormControl } from 'Shared/classes/bw-form';
import { UserService } from 'Shared/services/user.service';
import { Email, EmailPreference } from 'Shared/classes/email';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { EmailService } from 'Shared/services/email.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { FeaturesService } from 'Shared/services/features.service';
import { Error } from 'Shared/classes/error';

interface SubmitEvent {
  passwordValid: boolean;
  email: Email;
  preference: EmailPreference;
}

/* bw:view-encapsulation */
@Component({
  selector: 'bw-auth-login-password',
  templateUrl: './auth-login-password.component.html'
})
export class AuthLoginPasswordComponent implements OnInit, OnChanges {
  @Input() password: BwFormControl<string>;
  @Input() email: string;
  @Input() identifiedUser: string;
  @Input() showValidationMessage: boolean = true;
  @Input() showFailedLogin: boolean = false;
  @Input() origin: 'default' | 'checkout' | 'occasions' = 'default';
  @Input() submitError?: Error;
  @Input() validateForm: boolean = false;
  @Input() showTandC: boolean = false;

  @Output() didSubmit: EventEmitter<SubmitEvent> = new EventEmitter<SubmitEvent>();
  @Output() didFail: EventEmitter<void> = new EventEmitter<void>();

  consent: BwFormControl<boolean>;
  requestResetPasswordSent: boolean = false;
  loading: boolean = false;
  showPassword: boolean = false;
  emailUser: Email;
  isInvalidEmailOrPassword: boolean = false;

  constructor(
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private emailService: EmailService,
    public experimentService: ExperimentsService,
    private featuresService: FeaturesService
  ) {}

  /**
   * Optin Login Experiment
   */
  get optinLoginExperiment(): boolean {
    return this.featuresService.getFeature('OPTIN_LOGIN');
  }

  get isJoiningRewards(): boolean {
    return (
      this.featuresService.getFeature('JOINING_REWARDS').active &&
      (this.experimentService.isActive('AUTO_OPT_IN_TO_REWARDS', 1) || this.experimentService.isActive('AUTO_OPT_IN_TO_REWARDS', 2))
    );
  }

  /**
   * boolean wrapper around 'consent' FormControl for use by marketing checkbox component
   */
  get marketingConsent(): boolean {
    return this.consent.value;
  }

  set marketingConsent(consent: boolean) {
    this.consent.setValue(consent);
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.analyticsService.trackInHeap('authLogin');
    this.consent = new FormControl(null, []);
    // Check Email Marketing Preference
    this.emailService.check(new Email(this.email)).then((email: Email): void => {
      this.emailUser = email;
      this.consent.setValue(this.emailUser?.preference !== 0);
    });
  }

  /**
   * On changes
   */
  ngOnChanges(): void {
    // ValidateForm needs to be updated based on when form is submitted and emitted on
    const { invalid, touched } = this.password;
    this.validateForm = touched && invalid;

    this.showValidationMessage = this.submitError?.kind === 'invalidEmailOrPassword' || this.validateForm;
    this.isInvalidEmailOrPassword = this.submitError?.kind === 'invalidEmailOrPassword';
  }

  /**
   * Form focus event
   */
  formFocus(): void {
    this.showValidationMessage = true;

    if (this.showFailedLogin && this.password.getError('failedLogin')) {
      this.password.setErrors(null);
      this.password.markAsTouched();
    }
  }

  /**
   * Reset password request
   */
  resetPassword(): Promise<void> {
    this.showValidationMessage = true;
    this.requestResetPasswordSent = false;
    this.showFailedLogin = false;

    this.password.clearValidators();
    this.password.reset();

    if (this.password.invalid) {
      return Promise.resolve();
    }

    this.loading = true;
    const emailObject = new Email(this.email);
    return this.userService
      .requestPasswordReset(emailObject)
      .catch((): void => {})
      .then((): void => {
        this.loading = false;
        this.requestResetPasswordSent = true;
        this.password.setValidators([Validators.required]);
        this.password.updateValueAndValidity();
      });
  }

  /**
   * Submit with valid password submitted
   */
  submit(): void {
    this.requestResetPasswordSent = false;
    this.showValidationMessage = true;
    this.showFailedLogin = false;

    // For error handling, important to make form as submitted and touched
    this.password.markAsTouched();
    this.password['markAsSubmitted']();
    if (this.password.invalid) {
      return this.didFail.emit();
    }
    const emailSubscribedPreference = this.featuresService.getFeature('GDPR').emailSubscribedPreference;

    // preference should be default to current set preference
    let preference = this.emailUser?.preference;

    // if preference is not set, default to consent checkout outcome
    if (preference < 1) {
      preference = this.consent.value ? emailSubscribedPreference : 0;
    }

    return this.didSubmit.emit({
      passwordValid: true,
      email: this.emailUser,
      preference
    });
  }
}
