import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Product } from 'Shared/classes/product';
import { RouteDefinitions, StateParams, StateService } from 'Shared/services/state.service';
import { Country, CountryService } from 'Shared/services/country.service';
import { Purchase, PurchaseService } from 'Checkout/services/purchase.service';
import { ProductService } from 'Checkout/services/product.service';
import { Addon } from 'Shared/classes/addon';
import * as dayjs from 'dayjs';
import { DateUtilsService } from 'Shared/utils/date-utils.service';
import { AddonService } from 'Checkout/services/addon.service';
import { t } from 'Shared/utils/translations';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { FullscreenLoadingSpinnerService } from 'Shared/services/fullscreen-loading-spinner.service';
import { ActivatedState } from 'Shared/classes/activated-state';

@Injectable()
export class HasProductGuard implements CanActivate {
  constructor(
    private stateService: StateService,
    private countryService: CountryService,
    private purchaseService: PurchaseService,
    private productService: ProductService,
    private addonService: AddonService,
    private toastrService: ToastrService,
    private fullScreenLoadingSpinnerService: FullscreenLoadingSpinnerService
  ) {}

  /**
   * Get the chosen product
   * @param currentState
   */
  getChosenProduct(
    stateData: ActivatedState<'checkout.start'>['data']['data'],
    stateParams: StateParams,
    purchase: Purchase,
    country: Country
  ): Promise<Product> {
    if (stateData.product && stateData.upsoldFrom) {
      const upsoldProduct = (stateData.product.upsells || []).find((u) => u.id === stateData.upsoldFrom.id);
      return upsoldProduct ? Promise.resolve(upsoldProduct.product) : Promise.reject();
    }

    if (stateData.product) {
      return Promise.resolve(stateData.product);
    }
    const productSlug = stateParams.bouquet;
    if (!productSlug) {
      return Promise.reject('');
    }

    return this.productService.getAvailableProducts(country, (purchase.orders || []).length, purchase.discount).then((products) => {
      const product = products.find((p) => p.slug === productSlug);
      return product ? Promise.resolve(product) : Promise.reject('');
    });
  }

  /**
   * Get the chosen addon from various places, ensuring uniqueness
   * @param stateData
   * @param stateParams
   * @param product
   */
  getChosenAddons(
    stateData: RouteDefinitions['checkout.start']['data']['data'],
    stateParams: StateParams,
    product: Product,
    purchase: Purchase,
    country: Country,
    date?: dayjs.Dayjs
  ): Promise<Addon[]> {
    const addonSlugs: string[] = [];

    (stateData.addonSlug || '').split(',').forEach((addonSlug) => {
      addonSlugs.push(addonSlug);
    });
    (stateParams.addon || '').split(',').forEach((addonSlug) => {
      addonSlugs.push(addonSlug);
    });
    if (product && product.discountAddon && product.discountAddon.slug) {
      addonSlugs.push(product.discountAddon.slug);
    }
    (stateData.addons || []).forEach((addon) => {
      addonSlugs.push(addon.slug);
    });

    const chosenAddons = addonSlugs
      .filter((slug) => slug) // ensure nothing is empty
      .filter((value, index, self) => self.indexOf(value) === index) // ensure unique
      .map((addonSlug) => {
        const a = new Addon();
        a.slug = addonSlug;
        return a;
      });

    if (!chosenAddons.length) {
      return Promise.resolve([]);
    }

    return this.addonService
      .getAddonsForDefaultDeliveryDate(country, product, chosenAddons, purchase.orders.length, true, date, purchase.discount)
      .then(({ deliveryDate, addons }) => {
        const selectedAddons = addons.filter((a) => a.isSelected);

        // if we had 3 selected before, but now only have 2 show the message
        // No need to show if we have 0 before, but now we have 1 selected
        if (chosenAddons.length > selectedAddons.length) {
          this.toastrService.error(
            t('js.component.product-addons.toaster.invalid.detail'),
            t('js.component.product-addons.toaster.invalid')
          );
        }

        return selectedAddons;
      });
  }

  /**
   * Can activate
   */
  canActivate(): Promise<any> {
    const forShipping = this.countryService.forShipping;
    const toState = this.stateService.getTo<'checkout.start'>();
    const stateData = (toState?.data?.data ?? {}) as ActivatedState<'checkout.start'>['data']['data'];
    const stateParams: StateParams = Object.assign(new StateParams(), stateData.params, toState.params);

    let product: Product;
    const purchase = this.purchaseService.getPurchase();
    this.fullScreenLoadingSpinnerService.show();

    // TODO: remove this evil from here
    // Required currently due to way we manage order and purchase state data
    // Protection against user using the back button on the browser.
    // Once user lands on payment page, purchase is created so basket will not be empty.
    // Not perfect but safe to send user to grid as using back button doesn't preserve state data and at current checkout levers state data to heavily for basic order information.
    if (purchase.orders.length && !stateData?.product) {
      this.fullScreenLoadingSpinnerService.hide();
      this.stateService.go('checkout.base');
      return Promise.resolve(false);
    }

    if (purchase.orders.length) {
      this.fullScreenLoadingSpinnerService.hide();
      return Promise.resolve(true);
    }

    return this.getChosenProduct(stateData, stateParams, purchase, forShipping)
      .then((p): Promise<Addon[]> => {
        product = p;
        const date = stateParams.date ? DateUtilsService.fromString(stateParams.date) : undefined;

        return this.getChosenAddons(stateData, stateParams, product, purchase, forShipping, date);
      })
      .then((addons): Promise<boolean> => {
        const data = toState.data ?? {}; // note: data.data! (this is due to how data is passed from the grid)
        data.data = (data.data ?? {}) as ActivatedState<'checkout.start'>['data']['data'];
        data.data.addons = addons;
        data.data.addonSlug = undefined;
        data.data.product = product;
        this.stateService.addDataToToState(data);
        this.fullScreenLoadingSpinnerService.hide();
        return Promise.resolve(true);
      })
      .catch((): void => {
        this.fullScreenLoadingSpinnerService.hide();
        this.stateService.go('checkout.base');
      });
  }
}
