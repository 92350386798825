
<div class="container">
  <div class="col-md">
    <div class="bw-help-center-homepage">
      <div class="bw-help-center-homepage__base">
        <div class="bw-help-center-homepage__title"><span>We're happy to help.</span></div>
        <bw-horizontal-tabs [tabs]="displayTabs" [selected]="selectedTab" (select)="selectContentGroup($event)">
          <div class="bw-help-center-homepage__header" *ngIf="selectedTab">{{selectedTab.label}}</div>
          <div class="bw-help-center-homepage__selected" *ngIf="selectedTab">
            <bw-vertical-tabs [tabs]="selectedTab.content" [selected]="selectedArticle" (select)="selectContentArticle($event)">
              <bw-button *ngIf="selectedArticle?.showContactForm" buttonStyle="secondary" size="medium" (clicked)="openContactUpModal()" bwTrackAs="page.contact_us.form.header">Contact Us</bw-button>
            </bw-vertical-tabs>
          </div>
        </bw-horizontal-tabs>
      </div>
    </div>
  </div>
</div>