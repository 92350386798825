import { Component, Input } from '@angular/core';
import { Review } from 'Shared/classes/review';
import { MetaBaseComponent } from 'Shared/components/meta-base/meta-base.component';
import { ReviewsService } from 'Shared/services/reviews.service';
import { WindowRefService } from 'Shared/services/window.service';
import { ConfigService } from 'Shared/services/config.service';

@Component({
  selector: 'bw-meta-local-business',
  templateUrl: './meta-local-business.component.html',
  styleUrls: ['./meta-local-business.component.scss']
})
export class MetaLocalBusinessComponent extends MetaBaseComponent {
  @Input()
  reviews: Review[] = [];
  @Input()
  reviewStats: any = {};
  @Input()
  address: any = {};

  generateScript(): any {
    const reviews = this.reviews;
    return {
      '@context': 'https://schema.org/',
      '@type': 'LocalBusiness',
      image: '/assets/brand-icons/bwLogo.svg',
      address: `Bloom & Wild, ${this.address.town}, ${this.address.county}`,
      telephone: this.configService.getConfig().phone_number,
      name: 'Bloom & Wild',
      aggregateRating: {
        '@type': 'AggregateRating',
        name: 'Bloom & Wild',
        description: this.address.description,
        bestRating: '5',
        worstRating: '0',
        ratingValue: this.reviewStats.averageRating,
        reviewCount: this.reviewStats.count
      },
      reviews: reviews.map((review) => ({
        '@type': 'review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: review.rating
        },
        author: {
          '@type': 'Person',
          name: review.reviewer
        },
        datePublished: review.date,
        reviewBody: review.review,
        publisher: {
          '@type': 'Organization',
          name: 'Bloom & Wild'
        }
      }))
    };
  }
}
