import { Component, OnInit } from '@angular/core';
import { ModalResponse, ModalResponseSubject } from 'Shared/services/modal.service';
import { Subject } from 'rxjs';
import { Country } from 'Shared/classes/country';
import { CountryService } from 'Shared/services/country.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { countrySelectorContext } from 'Shared/components/i18n-selector/i18n-selector.component';
import { ConfigService } from 'Shared/services/config.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-i18n',
  templateUrl: './i18n.component.html'
})
export class I18nComponent implements OnInit, ModalResponse {
  modalResponse: Subject<ModalResponseSubject> = new Subject<ModalResponseSubject>();
  preferredCountry: Country;
  selectedCountry: Country;
  countries: Country[];
  languages: Record<string, Language[]>;
  availableLanguages: Language[];
  selectedLanguage: Language;
  context: countrySelectorContext = 'navbar';

  constructor(private countryService: CountryService, private analyticsService: AnalyticsService, private configService: ConfigService) {
    this.languages = {
      gb: [
        {
          name: 'English',
          code: 'en'
        }
      ],
      ie: [
        {
          name: 'English',
          code: 'en'
        }
      ],
      de: [
        {
          name: 'English / Englisch',
          code: 'en'
        },
        {
          name: 'Deutsch',
          code: 'de'
        }
      ],
      fr: [
        {
          name: 'English / Anglais',
          code: 'en'
        },
        {
          name: 'Français',
          code: 'fr'
        }
      ],
      at: [
        {
          name: 'English / Englisch',
          code: 'en'
        },
        {
          name: 'Deutsch',
          code: 'de'
        }
      ]
    };
  }

  onSuccess(): void {
    this.analyticsService.track('component.modal.close', {
      modalType: 'deliveryCountrySelectionApplied',
      modalValue: `countryId${this.selectedCountry.id}_${this.selectedLanguage?.code}`
    });
    this.modalResponse.next({
      success: true,
      data: {
        selectedCountry: this.selectedCountry,
        selectedLocale: this.selectedLanguage ? this.selectedLanguage.code : undefined
      }
    });
  }

  onCancel(): void {
    // Track events
    this.analyticsService.trackInHeap('exitModal', {
      modalType: 'deliveryCountrySelectionModal'
    });
    this.analyticsService.track('component.country-modal.close', {
      modalType: 'deliveryCountrySelectionModal'
    });
    this.modalResponse.next({ success: false });
  }

  setSelectedCountry(country: Country): void {
    this.selectedCountry = country;
    this.availableLanguages = this.languages[this.selectedCountry.codes[0]];
    this.selectedLanguage = this.availableLanguages[0];
  }

  setSelectedLanguage(language: Language): void {
    this.selectedLanguage = language;
  }

  ngOnInit(): void {
    this.countries = this.countryService.getCountries();
    this.preferredCountry = this.countries.find((c): boolean => c?.id === this.preferredCountry?.id);

    if (this.preferredCountry !== undefined) {
      this.setSelectedCountry(this.preferredCountry);
    }
  }
}

export interface Language {
  name: string;
  code: string;
}
