
<div class="quick-shop-bestseller" *ngIf="products?.length" [ngClass]="{'quick-shop-bestseller--rebrand': rebrand}">
  <div class="container bestseller-content">
    <div class="heading-top" *ngIf="!rebrand">
      <h2 class="header serif">Meet our bestsellers</h2>
    </div>
    <div class="content-row" [ngClass]="{'content-row--center': rebrand}">
      <div class="fill-tile col-xs-6 col-md-3 bestseller-products-item" *ngFor="let product of products; let productIndex = index" intersectionObserverEvents [observerThreshold]="0.9" (hasEntered)="trackProductImpression($event)"><a (click)="productSelected(product, productIndex)">
          <div class="product-images"><img class="card-img-top product__image d-block" [bwEcommImage]="product.imageUrls[0]" bwTimingEvent="TTFF:QS"><img class="product__image d-block image__hover" [bwEcommImage]="product.imageUrls[1]"></div>
          <div class="title serif">{{product.name}}</div>
          <!-- Location pages have a different style-->
          <div class="content-bottom--location-page" *ngIf="source === 'location-page'">
            <div class="content-bottom__price"><span class="price has-discount" *ngIf="product.getPrice().discount">{{product.getPrice() | price:'original'}}</span><span class="price">{{product.getPrice() | price}}</span></div>
            <div class="content-bottom__cta"><span class="ctaButton">
                <bw-button buttonStyle="primary" size="medium" [fullWidth]="true" bwTrackAs="components.quickshop.v2.cta">View</bw-button></span></div>
          </div>
          <div class="content-bottom" *ngIf="source !== 'location-page'" [ngClass]="{'content-bottom--rebrand': rebrand}"><span class="price has-discount" *ngIf="product.getPrice().discount">{{product.getPrice() | price:'original'}}</span><span class="price">{{product.getPrice() | price}}</span><span *ngIf="rebrand">
              <bw-button buttonStyle="primary" size="small" bwTrackAs="components.quickshop.v2.cta">View</bw-button></span>
            <!-- TODO: Remove once the rebrand is launched internationally--><span class="ctaButton" *ngIf="!rebrand">
              <bw-button buttonStyle="primary" size="small" bwTrackAs="components.quickshop.v2.cta">View</bw-button></span>
          </div></a></div>
    </div>
  </div>
</div>