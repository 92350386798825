
<div class="product-card-compact">
  <div class="product-card-compact__image">
    <bw-product-card-image [product]="product" [index]="index" [cardActive]="true"></bw-product-card-image>
  </div>
  <div class="product-card-compact__content">
    <div class="product-card-compact__container">
      <div class="product-card-compact__heading">{{ product.name }}</div>
      <div class="product-card-compact__description">{{ product.collectionName }}</div>
    </div>
    <div class="product-card-compact__price">
      <bw-product-price *ngIf="showPrice" [product]="product"></bw-product-price>
    </div>
  </div>
</div>