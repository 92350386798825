import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'bw-collapse-panel',
  templateUrl: './bw-collapse-panel.component.html',
  styleUrls: ['./bw-collapse-panel.component.scss']
})
export class BwCollapsePanelComponent implements OnInit {
  items: any = [];

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    const itemsAttr = this.elementRef.nativeElement.getAttribute('items');
    const items = itemsAttr ? JSON.parse(itemsAttr) : [];

    this.items = items.map(item => ({
      details: item.fields.bodyCopy,
      header: item.fields.headerCopy
    }));

    console.log('items', this.items);
  }
}
