import { Injectable } from '@angular/core';
import { ClaimedReward, LoyaltyMembership, LoyaltyPointBalance, LoyaltyReward } from 'Shared/classes/loyalty';
import { CurrencyCode } from 'Shared/classes/price';
import { APISerialisedJSONResponse, BackendService } from 'Shared/services/backend.service';
import { CountryService } from 'Shared/services/country.service';
import { UserService } from 'Shared/services/user.service';
import * as dayjs from 'dayjs';

const ENDPOINT_VERSION = '2023-10-12';
const WELCOME_ENDPOINT_VERSION = '2024-07-04';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyModelService {
  constructor(private userService: UserService, private backendService: BackendService, private countryService: CountryService) {}

  /**
   * Retrieve loyalty membership
   * @param {number} id -> LoyaltyMemberShipSchemeId from user
   * @returns {Promise<LoyaltyMembership>}
   */
  public retrieveLoyaltyMembershipPointBalance(id: number): Promise<LoyaltyMembership> {
    const user = this.userService.getUser();
    const country = this.countryService.forShipping;

    return this.backendService
      .get(user, `/${ENDPOINT_VERSION}/loyalty/memberships/${id}/retrieve-points-balance`, {
        params: {
          shipping_country_id: country.id
        }
      })
      .then(
        (res: APISerialisedJSONResponse<'/${ENDPOINT_VERSION}/loyalty/memberships/:id/retrieve-points-balance'>): LoyaltyMembership =>
          this.fromPayload(res)
      );
  }

  /**
   * Retrieve loyalty membership
   * @param {number} id -> LoyaltyMemberShipSchemeId from user
   * @returns {Promise<LoyaltyMembership>}
   */
  public claimLoyaltyMembershipVoucher(id: number, rewardId: number): Promise<ClaimedReward> {
    const user = this.userService.getUser();
    const country = this.countryService.forShipping;

    return this.backendService
      .post(
        user,
        `/${ENDPOINT_VERSION}/loyalty/memberships/${id}/claim-reward/${rewardId}`,
        {
          shipping_country_id: country.id
        },
        {}
      )
      .then((res): ClaimedReward => this.fromPayloadReward(res));
  }

  /**
   * Join loyalty membership
   * @returns {Promise<LoyaltyMembership>}
   */
  public joinLoyaltyMembership(): Promise<LoyaltyMembership> {
    const user = this.userService.getUser();
    const country = this.countryService.forShipping;

    return this.backendService
      .post(
        user,
        `/${ENDPOINT_VERSION}/loyalty/memberships`,
        {
          shipping_country_id: country.id
        },
        {}
      )
      .then((res): LoyaltyMembership => this.fromPayload(res));
  }

  /**
   * Get loyalty welcome points
   * @returns {number}
   */
  public getLoyaltyWelcomePoints(): Promise<number> {
    const country = this.countryService.forShipping;

    return this.backendService
      .get(null, `/${WELCOME_ENDPOINT_VERSION}/loyalty/welcome-points`, {
        params: {
          shipping_country_id: country.id
        }
      })
      .then((res): number => res?.welcome_points);
  }

  private fromPayloadReward(
    res: APISerialisedJSONResponse<'/${ENDPOINT_VERSION}/loyalty/memberships/:id/claim-reward/:reward_id'>
  ): ClaimedReward {
    if (res) {
      return {
        id: res.id ?? undefined,
        balance: res.available_balance_pennies ?? undefined,
        currency: (res.currency as CurrencyCode) ?? undefined,
        expiresOn: res.expires_on ? dayjs(res.expires_on) : undefined,
        name: res.name ?? undefined,
        code: res.code ?? undefined
      };
    }
    return null;
  }

  /**
   * Loyalty membership fromPayload
   * @param {APISerialisedJSONResponse<'/${ENDPOINT_VERSION}/loyalty/memberships/:id/retrieve-points-balance'>} res
   * @returns {LoyaltyMembership}
   */
  private fromPayload(
    res: APISerialisedJSONResponse<'/${ENDPOINT_VERSION}/loyalty/memberships/:id/retrieve-points-balance'>
  ): LoyaltyMembership {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { membership_start_date, points, next_reward, claimed_rewards } = res;
    return {
      startDate: membership_start_date ? dayjs(membership_start_date) : undefined,
      points: this.getLoyaltyMembershipPointBalance(points),
      nextReward: this.getLoyaltyMembershipReward(next_reward),
      claimedRewards: this.getLoyaltyMemebershipClaimedRewards(claimed_rewards)
    };
  }

  private getLoyaltyMemebershipClaimedRewards(
    claimed_rewards: APISerialisedJSONResponse<'/2023-10-12/loyalty/memberships/:id/retrieve-points-balance'>['claimed_rewards']
  ): ClaimedReward[] {
    const list = claimed_rewards?.map((res): ClaimedReward => this.fromPayloadReward(res));
    return list;
  }

  /**
   * Loyalty membership get point balance
   * @param {APISerialisedJSONResponse<'/${ENDPOINT_VERSION}/loyalty/memberships/:id/retrieve-points-balance'>['points']} res
   * @returns {LoyaltyPointBalance}
   */
  private getLoyaltyMembershipPointBalance(
    res: APISerialisedJSONResponse<'/${ENDPOINT_VERSION}/loyalty/memberships/:id/retrieve-points-balance'>['points']
  ): LoyaltyPointBalance {
    if (!res) {
      return undefined;
    }

    return {
      pending: res.pending ?? undefined,
      available: res.available ?? undefined,
      total: res.total ?? undefined
    };
  }

  /**
   * Loyalty membership get reward
   * @param {APISerialisedJSONResponse<'/${ENDPOINT_VERSION}/loyalty/memberships/:id/retrieve-points-balance'>['next_reward']} res
   * @returns {LoyaltyReward}
   */
  private getLoyaltyMembershipReward(
    res: APISerialisedJSONResponse<'/${ENDPOINT_VERSION}/loyalty/memberships/:id/retrieve-points-balance'>['next_reward']
  ): LoyaltyReward {
    if (!res) {
      return undefined;
    }

    return {
      id: res.id,
      milestone: res.milestone ?? undefined,
      milestoneMessage: res.milestone_message ?? undefined,
      pointsToReachMilestone: res.points_to_reach_milestone ?? undefined,
      name: res.name ?? undefined,
      description: res.description ?? undefined,
      isRedeemable: res.is_redeemable ?? undefined
    };
  }
}
