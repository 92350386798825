
<div class="sms-signup">
  <div class="card-body">
    <div class="card-text" *ngIf="success">
      <div class="alert alert-success">Thank you, we've sent you an SMS with a link to the app.</div>
    </div>
    <div class="card-text" *ngIf="!success">
      <form class="bwForm bwRhythm" [formGroup]="smsSignupForm" (ngSubmit)="sendSMS()" [ngClass]="{'ng-submitted': formSubmitted}" name="smsSignupForm">
        <div class="form-group">
          <input class="form-control form-control--prefix-dropdown" id="number" name="number" formControlName="number" maxLength="31" type="tel" placeholder="Enter mobile number">
          <label class="wide" for="number">Enter mobile number</label>
          <bw-formcontrol-validation-messages [errors]="smsSignupForm.get('number').errors" [touched]="smsSignupForm.get('number').touched" [formSubmitted]="formSubmitted">
            <div for="required">Please enter a valid mobile number</div>
            <div for="invalid">Please enter a valid mobile number</div>
          </bw-formcontrol-validation-messages>
          <div dropdown #dropdown="bs-dropdown">
            <button class="form-control dropdown-toggle" dropdownToggle type="button">
              <div class="bwFlag" *ngIf="selectedCountry" ngClass="bwFlag--{{ selectedCountry.codes[0] }}"></div>
            </button>
            <div class="form-items-select dropdown-menu dropdown-menu--compact" *dropdownMenu><a class="dropdown-item" href="javascript:void(0)" onclick="return false" *ngFor="let country of countries" (click)="selectCountry(country)">
                <div class="bwFlag" ngClass="bwFlag--{{ country.codes[0] }}"></div><span>+{{ country.phonePrefix }}</span></a></div>
          </div>
          <div class="form-actions">
            <bw-button buttonStyle="primary" size="medium" type="submit" bwTrackAs="component.sms-signup.auto_key.string_1">Get link</bw-button>
          </div>
        </div>
      </form>
      <bw-loading-spinner *ngIf="isLoading"></bw-loading-spinner>
    </div>
  </div>
</div>