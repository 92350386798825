import * as dayjs from 'dayjs';
import { ShippingOption } from 'Shared/classes/shipping-option';
export class Timeslot {
  id: string;
  start: dayjs.Dayjs;
  localStart: dayjs.Dayjs;
  end: dayjs.Dayjs;
  localEnd: dayjs.Dayjs;
  expires: dayjs.Dayjs;
  shippingOption: ShippingOption;

  /**
   * Set the starting time via a date str
   * @param dateStr
   */
  setStart(dateStr: string): void {
    if (!dateStr) {
      return;
    }
    this.start = dayjs(dateStr);
    this.localStart = dayjs(dateStr.split('+')[0]);
  }

  /**
   * Set the ending time
   */
  setEnd(dateStr: string): void {
    if (!dateStr) {
      return;
    }
    this.end = dayjs(dateStr);
    this.localEnd = dayjs(dateStr.split('+')[0]);
  }
}
