
<div class="recipient-search" [formGroup]="recipientForm" *ngIf="country">
  <div class="tight-form">
    <!-- Recipient Name Search-->
    <div class="form-group" dropdown #dropdown="bs-dropdown" [ngClass]="{'ng-focus' :  dropdown.isOpen}" *ngIf="recipientForm.get('name').invalid || recipientForm.get('address').pristine">
      <input class="form-control" name="name" id="name" formControlName="name" type="search" dropdownToggle autocomplete="off" (input)="onNameSearch($event)" (focus)="dropdown.show()" (click)="dropdown.show()" (blur)="closeOnBlurIfValid($event, dropdown)" tabindex="0">
      <label class="wide" for="name">Recipient name</label>
      <bw-form-validation-icon></bw-form-validation-icon>
      <bw-formcontrol-validation-messages [errors]="recipientForm.get('name').errors" [touched]="recipientForm.get('name').touched" [formSubmitted]="formSubmitted">
        <div for="invalid">Recipient name is needed</div>
      </bw-formcontrol-validation-messages>
      <div class="form-items-select dropdown-menu height-limited" *dropdownMenu [hidden]="!nameSearchResults || !nameSearchResults.length">
        <ng-container *ngFor="let result of nameSearchResults" role="menu"><a class="dropdown-item ellipsis" *ngIf="!result.isPlaceholder" href="javascript:void(0)" onclick="return false" (mousedown)="handleMouseDown(true); preventClose($event); dropdown.hide(); onSavedAddressSelected(result);" (touchstart)="handleMouseDown(true)" (mouseup)="handleMouseDown(false)" (touchend)="handleMouseDown(true)" (click)="preventClose($event); dropdown.hide(); onSavedAddressSelected(result);" bwTrackAs="component.recipient-search.saved-recipient">
            <div class="d-flex justify-content-between">
              <div class="dropdown-item__result-title">{{result.name}}</div>
              <div class="dropdown-item__result-hint"> Saved recipient</div>
            </div>
            <div class="dropdown-item__result-subtitle text-muted">{{result | addressInline:true}}</div></a><a class="dropdown-item ellipsis" *ngIf="result.isPlaceholder" role="menu" href="javascript:void(0)" onclick="return false" (click)="preventClose($event); dropdown.hide(); onManualNameEntry(result.name)" bwTrackAs="component.recipient-search.name.manual">
            <div class="dropdown-item__result-title">{{result.name}}</div>
            <div class="dropdown-item__result-subtitle text-muted">Add as a new recipient</div></a></ng-container>
      </div>
    </div>
    <!-- Recipient Name Display-->
    <div class="form-group recipient-search__input-display" *ngIf="recipientForm.get('name').valid &amp;&amp; recipientForm.get('address').dirty" (click)="openAddressModal()">
      <div class="form-control">{{recipientForm.get('name').value}}<img class="icon" src="/assets/brand-icons/chevron-right.svg" alt></div>
      <label class="wide" for="name">Recipient name</label>
    </div>
    <!-- Recipient Adress Lookup-->
    <div class="form-group" *ngIf="pcaEnabled &amp;&amp; recipientForm.get('address').invalid">
      <bw-address-search label="Recipient address" [required]="true" [country]="country" (addressSelected)="onSearchedAddressSelected($event)" (newAddressSelected)="onManualAddressSelect($event)" [formSubmitted]="formSubmitted" [newStyle]="true"></bw-address-search>
    </div>
    <!-- Recipient Address Display-->
    <div class="form-group recipient-search__input-display" *ngIf="!pcaEnabled || recipientForm.get('address').valid" (click)="openAddressModal()" tabindex="0" (focus)="onAddressDisplayFocus()">
      <div class="form-control">{{ recipientForm.get('address').value | addressInline:true }} &nbsp;<img class="icon" src="/assets/brand-icons/chevron-right.svg" alt></div>
      <label class="wide" for="address">Recipient address</label>
    </div>
    <!-- Telephone-->
    <div class="form-group recipient-search__input input--phone" *ngIf="showAddressPhone">
      <input class="form-control" type="tel" autocomplete="phone" name="phone" id="phone" placeholder="+{{country.phonePrefix}}" formControlName="phone">
      <label class="wide" for="phone">Phone number (mandatory)<span *ngIf="showAddressPhoneTooltip" [popper]="whyPhone" [popperShowOnStart]="false" [popperTrigger]="popperTrigger" [popperHideOnClickOutside]="true" [popperHideOnScroll]="true" [popperPlacement]="popperPlacement">Why?</span></label>
      <div class="prefix">
        <div class="bwFlag" ngClass="bwFlag--{{ country.codes[0] }}"></div>
      </div>
      <bw-form-validation-icon></bw-form-validation-icon>
      <bw-formcontrol-validation-messages [errors]="recipientForm.get('phone').errors" [touched]="recipientForm.get('phone').touched" [formSubmitted]="formSubmitted">
        <div for="invalid">Phone number appears to be invalid</div>
      </bw-formcontrol-validation-messages>
      <popper-content #whyPhone>
        <div class="phone__tooltip">
          <div class="u-font-small">We will notify the recipient of their delivery timeslot, share delivery updates and also reach out in the event of any delivery access issues.</div>
        </div>
      </popper-content>
    </div>
  </div>
</div>