import { Component, HostListener } from '@angular/core';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { FeaturesService } from 'Shared/services/features.service';
import { DigitalGeniusWidgetService } from 'Shared/services/third-parties/digital-genius-widget.service';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';
import { ZendeskWidgetService } from 'Shared/services/third-parties/zendesk-widget.service';

@Component({
  selector: '[bw-chat-window]',
  template: '<ng-content></ng-content>'
})
export class ChatWindowComponent {
  constructor(
    private zendeskWidgetService: ZendeskWidgetService,
    private optimizelyService: OptimizelyService,
    private digitalGeniusWidgetService: DigitalGeniusWidgetService,
    private experimentService: ExperimentsService,
    private featuresService: FeaturesService
  ) {}

  @HostListener('click')
  onClick(): Promise<boolean | void> {
    // Tracking Optimizely Events
    this.optimizelyService.trackEvent('click_to_contact');

    if (this.featuresService.getFeature('DIGITAL_GENIUS_WIDGET')) {
      this.digitalGeniusWidgetService.launchWidget();
    } else {
      return this.zendeskWidgetService
        .init()
        .then((): Promise<boolean> => {
          this.zendeskWidgetService.openChat();
          return this.zendeskWidgetService.waitForLoad();
        })
        .catch((err): void => {
          console.error(err);
        });
    }
  }
}
