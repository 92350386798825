import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { transition, animate, style, trigger, state } from '@angular/animations';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-process-bar',
  templateUrl: './process-bar.component.html',
})
export class ProcessBarComponent implements OnInit, OnChanges {
  percentage: number = 0;
  @Input() maxValue?: number = 100;
  @Input() currentValue?: number = 0;

  ngOnInit(): void {
    this.percentage = this.getPercentageValue();
  }

  ngOnChanges(): void {
    this.percentage = this.getPercentageValue();
  }

  private getPercentageValue(): number {
    return (this.currentValue / this.maxValue) * 100;
  }
}
