
<div class="bw-button">
  <!-- We can't have the same ng-content inside two *ngIfs - so we need to use the ng-template trick here-->
  <!-- https://github.com/angular/angular/issues/22972#issuecomment-407358396-->
  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
  <!-- A Links--><a tabindex="0" role="button" *ngIf="button.href" [ngClass]="button.displayClasses" [attr.href]="button.href" [attr.aria-label]="button.description || null" [attr.title]="button.description || null" [attr.target]="target || null">
    <ng-container *ngTemplateOutlet="content"></ng-container></a>
  <!-- Buttons-->
  <button *ngIf="!button.href" [type]="button.type" [ngClass]="button.displayClasses" (click)="onClick()" [attr.disabled]="button.state === 'disabled' || null" [attr.aria-label]="description || null" [attr.title]="description || null">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</div>