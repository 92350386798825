import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Addon } from 'Shared/classes/addon';
import { AddonRequirement } from 'Shared/classes/product';
import { ShopType } from 'Checkout/components/shop-checkout/shop-checkout.component';
import { ExperimentsService } from 'Shared/services/experiments.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-addon-card',
  templateUrl: './addon-card.component.html'
})
export class AddonCardComponent implements OnInit {
  @Input() addon: Addon;
  @Input() addonRequirement?: AddonRequirement;
  @Input() showTick: boolean = false;
  @Input() shopType: ShopType = 'default';

  @Output() addonSelected = new EventEmitter<Addon>();
  @Output() addonRemoved = new EventEmitter<Addon>();
  @Output() addonImageClicked = new EventEmitter<Addon>();

  public pricingExperimentActive: boolean = false;

  constructor(private experimentsService: ExperimentsService) {}

  ngOnInit(): void {
    this.pricingExperimentActive = this.experimentsService.isActive('HPT73_BW_UK_WEB_DISCOUNTED_PRICING_ON_GREETING_CARD_MODAL', 1);
  }

  /**
   * On image click
   * @param addon
   * @returns
   */
  onImageClick(addon: Addon): void {
    this.addonImageClicked.emit(addon);
  }

  /**
   * Select the addon
   * @param addon
   */
  selectAddon(addon: Addon): void {
    this.addonSelected.emit(addon);
  }

  /**
   * Deselect the addon
   * @param addon
   */
  deselectAddon(addon: Addon): void {
    this.addonRemoved.emit(addon);
  }
}
