import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService, ICookieConsent } from 'Shared/services/third-parties/cookie.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-cookie-content',
  templateUrl: './cookie-content.component.html'
})
export class CookieContentComponent implements OnInit {
  @Input() html: any;
  @Input() consent: string;
  @Input() cookiemessage: string;
  cookieConsent$: BehaviorSubject<ICookieConsent>;

  constructor(private cookieService: CookieService) {}

  /**
   * Opens the cookiebot popup to renew the consent
   */
  renewCookieConsent(): void {
    this.cookieService.renewConsent();
  }

  /**
   * Initialise
   */
  ngOnInit(): void {
    this.cookieConsent$ = this.cookieService.cookieConsent$;
  }
}
