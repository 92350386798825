export type ModuleTabsStyle = 'primary' | 'secondary';

export class ModuleTab {
  name: string;
  shortName?: string;
  subCopy?: string;
  event: () => void;
  active?: boolean;

  constructor(moduleTab: ModuleTab) {
    this.name = moduleTab.name;
    this.shortName = moduleTab.shortName;
    this.event = moduleTab.event;
    this.subCopy = moduleTab.subCopy;
    this.active = moduleTab.active || false;
  }
}
