
<div class="bw-email-preferences">
  <form class="bw-email-preferences__form" *ngIf="email" [formGroup]="formGroup" [ngClass]="{'ng-submitted': hasSubmitted}" (ngSubmit)="submit()">
    <fieldset class="bw-email-preferences__form-set" *ngIf="!showMinimal">
      <bw-form-radio-input [bwFormControl]="controls.preference" [options]="defaultOptions" [size]="'medium'"></bw-form-radio-input>
    </fieldset>
    <fieldset class="bw-email-preferences__form-set" *ngIf="showMinimal">
      <bw-alert *ngIf="email.preference === 4"><span>We've sent you an email to update your contact preferences. Please click the link in this email to confirm your newsletter subscription. Thanks!</span></bw-alert>
      <bw-form-radio-input [bwFormControl]="controls.preference" [options]="minimalOptions" [size]="'medium'"></bw-form-radio-input>
    </fieldset><span>We value your privacy and will never sell or pass your information to a 3rd party without your express permission. For more details, visit our <a href="/terms-and-privacy">T&Cs page</a>.</span>
    <div class="bw-email-preferences__alert" *ngIf="success">
      <bw-alert alertStyle="success">Your settings have been changed. If you have any questions, please feel free to email us  <bw-button buttonStyle="text-link" href="/help?subject=Web: Settings Update">here</bw-button>.</bw-alert>
    </div>
    <div class="bw-email-preferences__submit">
      <bw-button buttonStyle="modal-primary" size="medium" buttonType="submit" bwTrackAs="components.bwAccountEmail.buttons.update">Update details</bw-button>
    </div>
  </form>
</div>
<bw-loading-spinner *ngIf="loading || !email" [fullscreen]="true"></bw-loading-spinner>