import { Pipe, PipeTransform } from '@angular/core';
import { CountryService } from 'Shared/services/country.service';
import { User } from 'Shared/classes/user';

@Pipe({
  name: 'userCredit'
})
export class UserCreditPipe implements PipeTransform {
  constructor(private countryService: CountryService) {}

  transform(user: User, currency?: string): any {
    if (!user) {
      return undefined;
    }
    if (currency === 'forShipping' || !currency) {
      return user.availableCredit[this.countryService.forShipping.currencyCode];
    }

    if (currency === 'forUser') {
      return user.availableCredit[user.defaultCreditCurrency.toUpperCase()];
    }

    return user.availableCredit[currency.toUpperCase()];
  }
}
