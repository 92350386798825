import { Injectable } from '@angular/core';
import { BackendService } from 'Shared/services/backend.service';
import { environment } from 'Environments/environment';
import { ConfigService } from 'Shared/services/config.service';
import { FeaturesService } from 'Shared/services/features.service';

type BackendContentItem = {
  id: string;
  type: 'contents';
  attributes: {
    name: string; // TODO: Better name typings
    content: any;
  };
};

@Injectable({
  providedIn: 'root'
})
export class ContentsModelService {
  contentAPIPrefix: string = '';

  contentNames: string[] = [
    'service_bar_on_going',
    'service_bar_overrides',
    'burger',
    'navbar_links',
    'navbar_sublinks',
    'topLink',
    'price_filters',
    'mega_nav_links',
    'mega_nav_links_exp_v1',
    'mega_nav_links_exp_v2',
    'visual_nav',
    'visual_nav_exp_v1'
  ];
  contentsPromise: Promise<any>;

  constructor(
    private backend: BackendService,
    private configService: ConfigService,
    private featuresService: FeaturesService
  ) {
    this.contentAPIPrefix = this.getContentAPIPrefix();
  }

  /**
   * Set the content names
   */
  private getContentAPIPrefix(): string {
    return this.featuresService.getFeature('PREFIX_CONTENTS_API_WITH_BRAND')
      ? `${this.configService.getConfig().brandName}_`
      : '';
  }

  /**
   * Get all of the contents
   * @returns
   */
  getAll(): Promise<any> {
    this.contentsPromise =
      this.contentsPromise ||
      this.backend
        .get(null, '/v2/contents', {
          params: {
            'filter[name]': this.contentNames.map((c) => `${this.contentAPIPrefix}${c}`).join(',')
          }
        })
        .then((result) => {
          return (result.data as BackendContentItem[]).map((d) => {
            d.attributes.name = d.attributes.name.replace(this.contentAPIPrefix, '');
            return d;
          });
        });
    return this.contentsPromise;
  }
}
