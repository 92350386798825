import { Component, OnInit } from '@angular/core';
import { UserService } from 'Shared/services/user.service';
import { MentionMeService } from 'Shared/services/third-parties/mention-me.service';
import { ModalService } from 'Shared/services/modal.service';
import { ContentModalComponent } from 'Content/components/content-modal/content-modal.component';
import { CookieService, ICookieConsent } from 'Shared/services/third-parties/cookie.service';
import { Observable } from 'rxjs';

@Component({
  selector: '[bw-mention-me-launch-referee]',
  template:
    '<div *ngIf="(cookieConsent$ | async)?.marketing"><span (click)="openModal()"><ng-content></ng-content></span></div>',
  styleUrls: ['./mention-me-launch-referee.component.scss']
})
export class MentionMeLaunchRefereeComponent implements OnInit {
  promiseCache: Promise<any>;
  cookieConsent$: Observable<ICookieConsent> = this.cookieService.cookieConsent$;

  constructor(
    private userService: UserService,
    private mentionMeService: MentionMeService,
    private modalService: ModalService,
    private cookieService: CookieService
  ) {}

  /**
   * Open the modal with the mention me share stuff in it
   */
  openModal(): Promise<any> {
    return this.promiseCache
      .then(url => {
        return this.modalService.show(ContentModalComponent, {
          initialState: {
            contentPath: url,
            asIframe: true,
            title: ' '
          },
          class: 'modal-lg bw-modal--mention-me-share',
          keyboard: true
        });
      })
      .catch(() => {});
  }

  ngOnInit(): Promise<any> {
    const user = this.userService.getUser();
    this.promiseCache = this.mentionMeService.getReferrerWebViewURL(user);

    return this.promiseCache;
  }
}
