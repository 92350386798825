import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'Environments/environment';
import { t } from 'Shared/utils/translations';

@Pipe({
  name: 'possessive'
})
export class PossessivePipe implements PipeTransform {
  methods = {
    en: (n, s) => this.apostropheMethod(n, s),
    de: (n, s) => this.apostropheMethod(n, s),
    nl: (n, s) => this.simpleMethodWithSeparator(n, s),
    dk: (n, s) => this.simpleMethodWithSeparator(n, s),
    be: (n, s) => this.simpleMethodWithSeparator(n, s),
    'nl-be': (n, s) => this.simpleMethodWithSeparator(n, s),
    da: (n, s) => this.simpleMethodWithSeparator(n, s),
    fr: (n, s) => this.ofMethod(n, s)
  };

  accents = 'ÀÁÂÃÄÅĄàáâãäåąßÒÓÔÕÕÖØÓòóôõöøóÈÉÊËĘèéêëęðÇĆçćÐÌÍÎÏìíîïÙÚÛÜùúûüÑŃñńŠŚšśŸÿýŽŻŹžżź';
  out = 'AAAAAAAaaaaaaaBOOOOOOOOoooooooEEEEEeeeeeeCCccDIIIIiiiiUUUUuuuuNNnnSSssYyyZZZzzz';

  normalizeString(string: string): string {
    return string
      .split('')
      .map((letter) => {
        const i = this.accents.indexOf(letter);
        return i !== -1 ? this.out[i] : letter;
      })
      .join('');
  }

  firstLetter(string: string): string {
    return string.charAt(0);
  }

  lastLetter(string: string): string {
    return string.slice(-1);
  }

  isVowel(string: string): boolean {
    const s = this.normalizeString(string);
    const vowels = ['a', 'e', 'i', 'o', 'u'];
    return s && vowels.indexOf(s.toLowerCase()) !== -1;
  }

  isLetterS(string: string): boolean {
    const s = this.normalizeString(string);
    return s.toLowerCase() === 's';
  }

  simpleMethod(noun: string, subject: string): string {
    return `${noun} ${subject}`;
  }
  simpleMethodWithSeparator(noun: string, subject: string): string {
    return `${noun}: ${subject}`;
  }

  ofMethod(noun: string, subject: string): string {
    return this.isVowel(this.firstLetter(noun))
      ? t('js.pipe.possessive.fr_vowel', subject, noun)
      : t('js.pipe.possessive.fr_consonant', subject, noun);
  }

  apostropheMethod(noun: string, subject: string): string {
    return this.isLetterS(this.lastLetter(noun))
      ? t('js.pipe.possessive.apostrophe_s', noun, subject)
      : t('js.pipe.possessive.apostrophe', noun, subject);
  }

  transform(noun: string, subject: string): string {
    if (this.methods[environment.locale] === undefined) {
      return this.simpleMethod(noun, subject);
    }
    return this.methods[environment.locale](noun, subject);
  }
}
