import { Injectable } from '@angular/core';
import { ContentfulFormModel } from 'Content/models/contentful-form-model.service';

@Injectable({
  providedIn: 'root'
})
export class ContentfulFormService {
  formInstances: any = {};

  constructor(private contentfulFormModel: ContentfulFormModel) {}

  submitForm(formData: any, formKey: string): Promise<any> {
    return this.contentfulFormModel.submitForm(formData, formKey);
  }
}
