import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ComponentRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { ModalResponseSubject, ModalBaseResponse } from 'Shared/services/modal.service';
import { WindowRefService } from 'Shared/services/window.service';
import { ModalAnimations } from 'Shared/components/modal-base/modal-base.animations';
import { StateService } from 'Shared/services/state.service';
import { ConfigService } from 'Shared/services/config.service';
import { CountryService } from 'Shared/services/country.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { Location } from '@angular/common';
@Component({
  selector: 'bw-modal-base',
  templateUrl: './modal-base.component.html',
  styleUrls: ['./modal-base.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'bw-modal',
    role: 'dialog',
    tabindex: '-1',
    '[attr.aria-modal]': 'true'
  },
  animations: ModalAnimations
})
export class ModalBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  onDismiss: Subject<ModalBaseResponse> = new Subject<ModalBaseResponse>();
  modalRef: ComponentRef<any>;
  childRef: ComponentRef<any>;
  modalResponse$: any;

  modalName: string;
  class: string;
  ignoreBackdropClick: boolean;
  animationDirection: 'bottom' | 'right' | 'left' | 'none' | 'center' = 'bottom';
  underNav: boolean;
  keyboard: boolean;
  closeOnStateChange: boolean;
  useNativeScroll: boolean = false;
  historyUrl: string;
  site: string;
  shippingTo: number;
  bodyOffset: number;

  eventBinds = ['popstate', 'keyup', 'touchmove'];

  backdropVisibility: string = 'hidden';
  modalVisibility: string = `out-${this.animationDirection}`;

  dismissEvent: { success: boolean; data: any };
  scrollElement: HTMLElement;
  bwCSS;
  bodyRef;

  previousPageUrl: string;
  iosMobileDevice: boolean;

  constructor(
    private windowRef: WindowRefService,
    private elementRef: ElementRef,
    private changes: ChangeDetectorRef,
    private stateService: StateService,
    private configService: ConfigService,
    public experimentService: ExperimentsService,
    private countryService: CountryService,
    private location: Location
  ) {
    const ie =
      navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1;
    if (!ie) {
      this.bwCSS = CSS;
    }

    this.site = this.configService.getConfig().site;
    this.shippingTo = this.countryService.forShipping.id;
  }

  getScrollElement(): HTMLElement {
    return this.windowRef.nativeWindow.document.querySelector('.bw-modal__scrollview');
  }

  dismissAnimated(): void {
    this.animateOut();
  }

  dismissAnimationCompleted(event): void {

    if (event.toState.indexOf('out') !== -1) {
      if (this.childRef && this.childRef.instance['modalOutAnimationDidComplete']) {
        this.childRef.instance['modalOutAnimationDidComplete']();
      }

      // Reset body Scroll when Opening Address Modal on iOS Device only
      if (this.childRef && this.childRef.instance.handleIosScroll && this.iosMobileDevice) {
        this.resetBodyIosScroll();
      }

      this.onDismiss.next({
        success: this.dismissEvent.success,
        data: this.dismissEvent.data,
        ref: this.modalRef
      });
    }

    if (event.toState.indexOf('in') !== -1) {
      if (this.childRef && this.childRef.instance['modalInAnimationDidComplete']) {
        this.childRef.instance['modalInAnimationDidComplete']();
      }
    }
  }

  undoHistoryUrl(): void {
    if (!this.previousPageUrl) {
      return;
    }

    this.location.replaceState(this.previousPageUrl);
  }

  setHistoryUrl(): void {
    this.previousPageUrl = this.previousPageUrl || window.location.pathname;
    this.location.go(this.historyUrl);
  }

  onBackdropClick(success: boolean): void {
    if (this.childRef.instance.onBackdropClick) {
      return this.childRef.instance.onBackdropClick();
    }

    this.dismissModal(success);
  }

  dismissModal(success: boolean, data?: any): void {
    if (this.historyUrl && this.historyUrl.length) {
      this.undoHistoryUrl();
    }
    this.undoClasses();
    this.dismissEvent = { success, data };
    this.dismissAnimated();
  }

  setupSubscriptions(): void {
    if (
      !this.childRef ||
      (this.childRef && this.childRef.instance && !this.childRef.instance.modalResponse)
    ) {
      console.error(
        'Modal Attempting to display a component that does not implement ModalResponse behavior subject'
      );
      return;
    }
    this.modalResponse$ = this.childRef.instance.modalResponse.subscribe(
      (response: ModalResponseSubject) => {
        this.dismissModal(response.success, response.data);
      }
    );
  }

  setupTransitionSubscription(): void {
    if (!this.closeOnStateChange) {
      return;
    }

    this.stateService.onBefore$.subscribe(() => {
      this.dismissModal(true);
    });
  }

  handleScroll(event: Event | any): void {
    if (!this.scrollElement || this.useNativeScroll) {
      return;
    }

    if (this.scrollElement.scrollTop === 0) {
      this.scrollElement.scrollTop = 1;
      event.preventDefault();
      return;
    }

    if (
      this.scrollElement.scrollHeight ===
      this.scrollElement.scrollTop + this.scrollElement.offsetHeight
    ) {
      this.scrollElement.scrollTop -= 2;
      event.preventDefault();
      return;
    }
  }

  handleEscape(event): void {
    if (event.keyCode === 27 && this.keyboard) {
      this.dismissModal(false);
    }
  }

  handlePopstate(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dismissModal(false);
  }

  // bool return for unit testing
  handleTouchEvents(event): boolean {
    if (this.useNativeScroll) {
      return;
    }

    const backdropElement = event.target.className.indexOf('bw-modal__backdrop-content') !== -1;
    const modalContent = event.target.className.indexOf('modal-open') !== -1;
    if (!backdropElement && !modalContent) {
      return false;
    }
    if (event.type === 'touchmove') {
      event.preventDefault();
      event.stopPropagation();
      return true;
    }
    return false;
  }

  handleEvent(event): any {
    // Escape = 27
    if (event.type === 'keyup') {
      this.handleEscape(event);
    }

    if (event.type === 'popstate') {
      if (!this.closeOnStateChange) {
        return;
      }
      this.handlePopstate(event);
    }

    if (event.type === 'scroll') {
      this.handleScroll(event);
    }

    if (event.type === 'touchstart' || event.type === 'touchmove') {
      this.handleTouchEvents(event);
    }
  }

  setupListeners(): void {
    this.eventBinds.forEach((eventName: string) => {
      this.windowRef.nativeWindow.addEventListener(eventName, this, { passive: false });
    });
  }

  destroyListeners(): void {
    this.eventBinds.forEach((eventName: string) => {
      this.windowRef.nativeWindow.removeEventListener(eventName, this);
    });
    if (this.scrollElement) {
      this.scrollElement.removeEventListener('scroll', this);
    }
  }

  focusWindow(): void {
    this.elementRef.nativeElement.focus();
  }

  animateOut(): void {
    this.backdropVisibility = 'hidden';
    this.modalVisibility = `out-${this.animationDirection}`;
    this.detectChanges();
  }

  animateIn(): void {
    this.animateOut();
    this.backdropVisibility = 'visible';
    this.modalVisibility = 'in';
    this.detectChanges();
    // Handle body Scroll when Opening Address Modal on iOS Device only
    if (this.childRef && this.childRef.instance.handleIosScroll && this.iosMobileDevice) {
      this.handleBodyIosScroll();
    }
  }

  /**
   * Handle iOS body scroll: Save the current scroll offset and add a fixed position to the body
   * Inspired by: https://github.com/sweetalert2/sweetalert2/commit/4a2d36b494fbae879c9a17f92af90cb61f03b4d5
   */
  handleBodyIosScroll(): void {
    // SetTimeout used so the body scrolls after animationIn is complete
    // this avoids the user seeing the scroll happening
    setTimeout(() => {
      this.bodyOffset = this.windowRef.nativeWindow.scrollY;
      this.bodyRef.classList.add('iosBodyScroll');
    }, 400);
  }

  /**
   * Reset iOS body scroll: scroll to previous scroll offset and remove fixed position
   */
  resetBodyIosScroll(): void {
    if (this.bodyOffset) {
      this.windowRef.nativeWindow.scroll(0, this.bodyOffset);
      this.bodyRef.classList.remove('iosBodyScroll');
    }
  }

  setupClasses(): void {
    setTimeout(() => {
      this.bodyRef.classList.add('modal-open');
      if (this.modalName) {
        this.bodyRef.classList.add(`modal-showing-${this.modalName}`);
        this.elementRef.nativeElement.classList.add(`modal-showing-${this.modalName}`);
      }
      if (this.underNav) {
        this.elementRef.nativeElement.classList.add('under-nav');
        this.bodyRef.classList.add('modal--under-nav');
      }
    });
  }

  undoClasses(): void {
    this.bodyRef.classList.remove('modal-open');
    if (this.modalName) {
      this.bodyRef.classList.remove(`modal-showing-${this.modalName}`);
    }
    this.bodyRef.classList.remove('modal--under-nav');
  }

  preventOverscroll(): void {
    if (
      this.useNativeScroll ||
      !this.bwCSS ||
      (this.bwCSS && this.bwCSS.supports('overscroll-behavior', 'contain'))
    ) {
      return;
    }

    if (this.scrollElement) {
      this.scrollElement.addEventListener('scroll', this);
      this.scrollElement.addEventListener('touchmove', this);
      this.scrollElement.scrollTo({ left: 0, top: 1, behavior: 'auto' });
    }
  }

  detectChanges(): void {
    if (!this.changes['destroyed']) {
      this.changes.detectChanges();
    }
  }

  ngOnDestroy(): void {
    if (this.modalResponse$ && this.modalResponse$.unsubscribe) {
      this.modalResponse$.unsubscribe();
    }
    this.destroyListeners();
    this.previousPageUrl = undefined;
  }

  ngAfterViewInit(): void {
    this.scrollElement = this.getScrollElement();
    this.animateIn();

    this.preventOverscroll();
  }

  ngOnInit(): void {
    // Detect if on iOS mobile device
    this.iosMobileDevice =
      /iPad|iPhone|iPod/.test(this.windowRef.nativeWindow.navigator.userAgent) ||
      (this.windowRef.nativeWindow.navigator.platform === 'MacIntel' && this.windowRef.nativeWindow.navigator.maxTouchPoints > 1);

    if (this.historyUrl && this.historyUrl.length) {
      this.setHistoryUrl();
    }

    if (this.useNativeScroll) {
      this.eventBinds = this.eventBinds.filter((s) => s !== 'touchmove');
    }
    // TODO subscribe to modals service and hide when other shown
    this.setupSubscriptions();
    this.setupListeners();
    this.setupTransitionSubscription();
    this.focusWindow();
    this.setupClasses();
    this.bodyRef = this.windowRef.nativeWindow.document.querySelector('body');
  }
}
