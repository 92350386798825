import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef
} from '@angular/core';
import { AvailableIcon } from 'Shared/components/icon/icon.component';

type AlertStyleIconDefintion = {
  [key: string]: AvailableIcon;
};

export const ALERT_STYLE_ICONS: AlertStyleIconDefintion = {
  default: 'alertInCircle',
  success: 'success',
  info: 'info',
  list: 'list',
  successInfo: 'successInfo',
  warning: 'warning'
};

export type BwAlertStyle = keyof typeof ALERT_STYLE_ICONS;

interface BwAlert {
  header: string;
  style: BwAlertStyle;
  iconType: AvailableIcon;
  displayClasses: string;
}

/* bw:view-encapsulation */
@Component({
  selector: 'bw-alert',
  templateUrl: './alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent implements OnInit, OnChanges {
  @Input() header: string;
  @Input() alertStyle: BwAlertStyle = 'default';
  @Input() showIcon: boolean = true;

  alert: BwAlert;

  constructor(private changes: ChangeDetectorRef) {}

  /**
   * Create alert with given attributes
   */
  createAlert(): BwAlert {
    const iconType = ALERT_STYLE_ICONS[this.alertStyle];

    return {
      iconType,
      header: this.header,
      style: this.alertStyle,
      displayClasses: `bw-alert--${this.alertStyle}`
    };
  }

  /**
   * On changes
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alertStyle) {
      this.alert = this.createAlert();
      this.changes.markForCheck();
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.alert = this.createAlert();
  }
}
