import { Injectable } from '@angular/core';
import { Error } from 'Shared/classes/error';
import { t } from 'Shared/utils/translations';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { File } from 'Shared/classes/file';

/**
 * I've put the below in here as it only relates to this service
 * and does not differ between sites or envrionments.
 * Once day it could live somewhere else, but for ease - here is probably ok :)
 */
/* tslint:disable */
const S3_CONFIG = {
  'bw-contact-uploads': {
    policy:
      'ewogICJleHBpcmF0aW9uIjogIjIwOTktMDEtMDFUMDA6MDA6MDBaIiwKICAiY29uZGl0aW9ucyI6IFsKICAgIHsiYnVja2V0IjogImJ3LWNvbnRhY3QtdXBsb2FkcyJ9LAogICAgWyJzdGFydHMtd2l0aCIsICIka2V5IiwgInVwbG9hZHMvIl0sCiAgICB7ImFjbCI6ICJwcml2YXRlIn0sCiAgICBbInN0YXJ0cy13aXRoIiwgIiRDb250ZW50LVR5cGUiLCAiIl0sCiAgICBbImNvbnRlbnQtbGVuZ3RoLXJhbmdlIiwgMCwgNTI0Mjg4MF0KICBdCn0K',
    signature: 'sBUt8txs248GvSQgmfQ3Rv6WH6M=',
    accessKey: 'AKIAJQ5XRWAHTZNNZX3A'
  },
  'bw-form-uploads': {
    policy:
      'eyJleHBpcmF0aW9uIjoiMjA5OS0wMS0wMVQwMDowMDowMFoiLCJjb25kaXRpb25zIjpbWyJzdGFydHMtd2l0aCIsIiRrZXkiLCJ1cGxvYWRzLyJdLFsic3RhcnRzLXdpdGgiLCIkQ29udGVudC1UeXBlIiwiIl0seyJidWNrZXQiOiJidy1mb3JtLXVwbG9hZHMifSxbInN0YXJ0cy13aXRoIiwiJGtleSIsIiJdLHsiYWNsIjoicHJpdmF0ZSJ9LFsiY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA3Mzc0MTgyNF1dfQ==',
    signature: 'kRVrS12niZGReqeGcznSpXIzjDY=',
    accessKey: 'AKIAJXANQLIOW5NZ7PTA'
  }
};
/* tslint:enable */

@Injectable({
  providedIn: 'root'
})
export class FileModelService {
  awsURL: string;
  xmlHTTPRequest: any;
  formData: any;

  constructor(private analyticsService: AnalyticsService) {
    this.xmlHTTPRequest = XMLHttpRequest;
    this.formData = FormData;
  }

  /**
   * Upload a file
   * @param bucket
   * @param file
   * @param progressCallback
   */
  public uploadFile(bucket: string, file: File, progressCallback?: Function): Promise<any> {
    if (file.uploadPromise) {
      return file.uploadPromise;
    }

    let fileName = `${new Date().getTime()}${Math.random()}`.replace('.', '');
    fileName += `.${file.name.split('.').pop()}`; // Extension

    file.uploadError = undefined;
    file.uploadProgress = 0;
    file.uploadedAs = fileName;

    const s3Config = S3_CONFIG[bucket];

    const fd = new this.formData();

    fd.append('policy', s3Config.policy);
    fd.append('signature', s3Config.signature);
    fd.append('AWSAccessKeyId', s3Config.accessKey);
    fd.append('key', `uploads/${file.uploadedAs}`);
    fd.append('acl', 'private');
    fd.append('Content-Type', file.file.type);

    fd.append('file', file.file); // This MUST be last, otherwise AWS ignores it

    const url = `https://${bucket}.s3-eu-west-1.amazonaws.com/`;

    const request = new this.xmlHTTPRequest();

    file.uploadPromise = new Promise((resolve, reject) => {
      const onError = () => {
        const msg = /<Message>(.*)<\/Message>/gim.exec(request.responseText || '');
        const error = new Error({
          message: msg && msg[1] ? msg[1] : t('js.service.file-upload.error.message'),
          code: 'fileUploadError'
        });
        this.analyticsService.trackError(error);
        file.uploadError = error;
        reject(error);
      };

      request.open('post', url, true);
      request.upload.onprogress = e => {
        if (e.lengthComputable) {
          const progress = e.loaded / e.total;
          file.uploadProgress = progress;

          if (progressCallback) {
            progressCallback(progress);
          }
        }
      };

      request.onerror = res => {
        onError();
      };

      request.onreadystatechange = () => {
        const status = request.status;
        if (status === 0 || (status >= 200 && status < 400)) {
          // The request has been completed successfully
        } else {
          onError();
        }
      };

      request.onload = res => {
        if (request.status === 204) {
          return resolve(file.uploadedAs);
        }
        onError();
      };

      // Finally, send the request
      request.send(fd);
    });

    return file.uploadPromise;
  }
}
