
<div class="reviews-stats reviews-stats--{{modifier}} {{innerclass}}" *ngIf="stats">
  <ng-container *ngIf="modifier === 'normal'">
    <div class="reviews-stats__stars d-flex align-items-center">
      <bw-review-stars class="d-inline-flex" [large]="true" [rating]="stats.average_rating"></bw-review-stars><span class="reviews-stats__rating d-inline-flex">{{ stats.average_rating }}</span>
    </div>
    <div class="reviews-stats__info d-block"><span class="reviews-stats__count">
        {{ stats.count }}
reviews</span><img src="/assets/images/reviews-logo.svg" alt="Reviews from reviews.co.uk"></div>
  </ng-container>
  <ng-container *ngIf="modifier === 'condensed'">
    <div class="reviews-stats__stars d-flex {{innerContentclass}}">
      <bw-icon class="reviews-stats__logo trustpilot hidden-xs hidden-sm" iconType="trustpilotReviewsDesktop" altText="Reviews from Trustpilot.com"></bw-icon>
      <bw-icon class="reviews-stats__logo trustpilot hidden-md hidden-lg" iconType="trustpilotReviews" altText="Reviews from Trustpilot.com"></bw-icon>
    </div>
    <div class="reviews-stats__info d-inline-flex"><span class="reviews-stats__rating d-inline-flex"><strong>{{ stats.average_rating }}</strong>average</span><span class="reviews-stats__count d-inline-flex"><strong>{{ stats.count }}</strong>reviews</span></div>
  </ng-container>
  <ng-container *ngIf="modifier === 'averages'"><span class="text-muted reviews-stats__rating d-inline-flex">{{ stats.average_rating }}</span><span class="text-muted reviews-stats__count d-inline-flex">({{ stats.count }} reviews)</span></ng-container>
  <ng-container *ngIf="modifier === 'large'">
    <div class="reviews-stats__stars d-flex"><img class="reviews-stats__logo trustpilot" src="/assets/images/trustpilot-excellent-large.png" alt="Reviews from Trustpilot.com"></div>
    <div class="reviews-stats__info d-inline-flex"><span class="reviews-stats__rating d-inline-flex"><strong>{{ stats.average_rating }}</strong>average</span><span class="reviews-stats__count d-inline-flex"><strong>{{ stats.count }}</strong>reviews</span></div>
  </ng-container>
  <ng-container *ngIf="modifier === 'linear'">
    <div class="review-stats__linear">
      <div class="reviews-stats__stars d-flex"><a class="d-flex" href="https://uk.trustpilot.com/review/www.bloomandwild.com" target="_blank" rel="noopener noreferrer">
          <bw-icon class="reviews-stats__logo trustpilot hidden-xs hidden-sm" iconType="trustpilotReviewsDesktop" altText="Reviews from Trustpilot.com"></bw-icon>
          <bw-icon class="reviews-stats__logo trustpilot hidden-md hidden-lg" iconType="trustpilotReviews" altText="Reviews from Trustpilot.com"></bw-icon></a></div>
      <div class="reviews-stats__info d-inline-flex"><span class="reviews-stats__rating d-inline-flex"><strong>{{ stats.average_rating }}&nbsp;</strong>average</span><span class="reviews-stats__count d-inline-flex"><strong>{{ stats.count }}&nbsp;</strong>reviews</span></div>
    </div>
  </ng-container>
</div>