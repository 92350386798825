
<div class="bw-form-text-input" *ngIf="bwFormControl" [ngClass]="{
    'input--display': inputType === 'display',
    'input--text': inputType !== 'display',
    'is-focused': isFocused,
    'is-invalid': bwFormControl.invalid,
    'char-count-invalid': maxCharCountReached,
    'is-valid': bwFormControl.valid,
    'is-dirty': bwFormControl.dirty,
    'is-pristine': bwFormControl.pristine,
    'is-touched': bwFormControl.touched,
    'is-untouched': bwFormControl.untouched,
    'is-submitted': hasSubmitted,
    'has-click-event': didClick.observers.length,
    'has-clear-icon': allowClear,
    'has-subtext': subtext,
    'has-hidden-validation': hideValidationErrors,
    'has-callout': hasCallout,
    'has-allowed-multiline': multiline,
    'has-expandable-control-area': isExpandable,
    'has-scrolled-display-text': scrollDisplayText,
    'has-action-icon-top': actionIconAlignment === 'top',
    'has-action-icon-centre': actionIconAlignment === 'centre',
    'has-placeholder-icon': !!placeholderIcon,
    'has-placeholder-forced': forceShowPlaceholder,
    'has-instructions': hasInstructions,
    'has-label-prefix': labelPrefix
    }">
  <div class="text-input__container" [ngClass]="{'gifting-experiment': giftingExperimentActive}" (click)="inputType === 'display' &amp;&amp; onInputClicked()" (focus)="inputType === 'display' &amp;&amp; onFocus()" (blur)="inputType === 'display' &amp;&amp; onBlur()" (keyup.enter)="inputType === 'display' &amp;&amp; onInputClicked()" [attr.tabindex]="inputType === 'display' ? 0 : -1">
    <div class="text-input__control">
      <label *ngIf="!giftingExperimentActive" [attr.for]="id" [style.padding-right.px]="paddingForSubtext"><span class="label--prefix" *ngIf="labelPrefix">{{labelPrefix}}&nbsp;</span><span class="label--text">{{label}}</span></label>
      <!-- Inputs-->
      <div class="control__display-value" *ngIf="inputType === 'display'">
        <div class="value--container" *ngIf="!forceShowPlaceholder &amp;&amp; bwFormControl.value">
          <div class="value--text" data-hj-suppress>{{ bwFormControl.value | callback:displayFn}}</div>
        </div>
        <div class="value--placeholder" *ngIf="forceShowPlaceholder || !bwFormControl.value" [innerHtml]="placeholder || '&amp;nbsp;'"></div>
      </div>
      <input class="control__value" tabindex="0" *ngIf="inputType !== 'display' &amp;&amp; !multiline" data-hj-suppress [formControl]="bwFormControl" (click)="onInputClicked()" (focus)="onFocus()" (blur)="onBlur()" (keyup.tab)="onInputClicked()" (keyup.enter)="onInputClicked()" [attr.id]="id" [attr.name]="id" [attr.type]="inputType" [attr.maxlength]="maxLength" [attr.placeholder]="placeholder" [attr.autocomplete]="autoComplete || undefined" [attr.ariaLabel]="labelName">
      <textarea class="control__value" tabindex="0" *ngIf="multiline &amp;&amp; inputType === 'text' &amp;&amp; !giftingExperimentActive" [formControl]="bwFormControl" data-hj-suppress (focus)="onFocus()" (blur)="onBlur()" [attr.id]="id" [attr.name]="id" [attr.type]="inputType" [attr.maxlength]="maxLength" [attr.placeholder]="placeholder" [attr.autocomplete]="autoComplete || undefined" [attr.ariaLabel]="labelName" (keyup)="isExpandable &amp;&amp; setTextAreaHeight()"></textarea>
      <textarea class="control__value control__value--gifting-experiment" tabindex="0" *ngIf="multiline &amp;&amp; inputType === 'text' &amp;&amp; giftingExperimentActive" [formControl]="bwFormControl" data-hj-suppress (keyup)="charCounter()" (focus)="onFocus()" (blur)="onBlur()" [attr.id]="id" [attr.name]="id" [attr.type]="inputType" [attr.autocomplete]="autoComplete || undefined" [attr.placeholder]="placeholder"></textarea>
      <ng-container *ngIf="!giftingExperimentActive">
        <!-- Icons-->
        <div class="control__icon-holder holder--placeholder">
          <bw-icon *ngIf="placeholderIcon" [iconType]="placeholderIcon"></bw-icon>
        </div>
        <div class="control__icon-holder holder--actions">
          <bw-icon *ngIf="didClick.observers.length &amp;&amp; inputType === 'display'" [iconType]="'chevron-right'"></bw-icon>
          <!-- (mousedown) to bubble before the blur event-->
          <bw-icon class="action--clear" *ngIf="isFocused &amp;&amp; allowClear" [iconType]="'small-x'" (mousedown)="onClear()"></bw-icon>
          <ng-container *ngIf="!isFocused &amp;&amp; inputType !== 'display' &amp;&amp; (hasSubmitted || bwFormControl.touched)">
            <bw-icon class="action--invalid" *ngIf="bwFormControl.invalid" [iconType]="'red-alert'"></bw-icon>
            <bw-icon class="action--valid" *ngIf="bwFormControl.valid" [iconType]="'tick__success'"></bw-icon>
          </ng-container>
        </div>
        <!-- Border & subtext-->
        <div class="text-input__subtext" *ngIf="subtext"><span class="subtext__text" *ngIf="!hasCallout">{{subtext}}</span>
          <bw-button class="subtext__link" buttonStyle="text-link" *ngIf="hasCallout">{{subtext}}</bw-button>
          <ng-content select="[role=callout]"></ng-content>
        </div>
      </ng-container>
    </div>
    <!-- Additional instructions-->
    <ng-content select="[role=instructions]"></ng-content>
  </div>
  <!-- Error messages-->
  <bw-formcontrol-validation-messages *ngIf="!hideValidationErrors" [errors]="bwFormControl.errors" [touched]="bwFormControl.touched" [formSubmitted]="hasSubmitted">
    <ng-content select="[role=errors]"></ng-content>
  </bw-formcontrol-validation-messages>
</div>