import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  AfterViewInit,
  ElementRef,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { fromEvent, Subscription, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { BwForm, BwFormControl, BwFormDefintion } from 'Shared/classes/bw-form';
import { AvailableIcon } from 'Shared/components/icon/icon.component';

type FormDropdownControl = {
  search: BwFormControl<string>;
};

export interface DropDownOption<T = any> {
  value: T;
  label: string;
  description?: string;
  isGroupingOption?: boolean;
  subtext?: string;
  prefixIcon?: AvailableIcon;
}

/* bw:view-encapsulation */
@Component({
  selector: 'bw-form-dropdown',
  templateUrl: './form-dropdown.component.html'
})
export class FormDropdownComponent
  extends BwForm<FormDropdownControl>
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() bwFormControl: BwFormControl<any>;
  @Input() inputType: 'display' | 'search';
  @Input() label: string;
  @Input() placeholder: string;
  @Input() optionSubtext: string;
  @Input() data: DropDownOption[];
  @Input() displayFn: Function;
  @Input() debounceTime: number = 0;
  @Input() hasMaxHeight: boolean = true;
  @Input() multiline: boolean = false;
  @Input() showLoading: boolean = false;
  @Input() expandContainerWhenOpen: boolean = false;
  @Input() allowClear: boolean = true;
  @Input() forceShowPlaceholder: boolean = false;
  @Input() setValueOnSelect: boolean = true;
  @Input() autoComplete: string;

  // Events
  @Output() didSearch: EventEmitter<string> = new EventEmitter();
  @Output() didClick: EventEmitter<boolean> = new EventEmitter();
  @Output() didFocus: EventEmitter<boolean> = new EventEmitter();
  @Output() didBlur: EventEmitter<boolean> = new EventEmitter();
  @Output() didSelect: EventEmitter<DropDownOption> = new EventEmitter();

  @ViewChild('instructions') instructions: ElementRef;

  //
  hasSubmitted: boolean = false;
  hasDescription: boolean = false;
  hasInstructions: boolean = false;
  isDropdownOpen: boolean = false;
  isFocused: boolean = false;
  isDisplay: boolean = false;

  selectedIndex: number;

  searchSubscribe: Subscription;
  id: string;

  constructor() {
    super();
  }

  /**
   * Build the form
   */
  buildForm(): BwFormDefintion<FormDropdownControl> {
    return {
      search: new FormControl('')
    };
  }

  /**
   * BwForm required implementation
   */
  getObject() {}

  /**
   * BwForm required implementation
   */
  setObject() {}

  /**
   * Mark as submmitted
   */
  markAsSubmitted(): void {
    this.hasSubmitted = true;
  }

  /**
   * Angular trackBy
   * @returns
   */

  trackBy(index: number, option: DropDownOption) {
    return option.value;
  }

  /**
   * On focus
   */
  onFocus(): void {
    this.isFocused = true;
    this.didFocus.emit(true);
  }

  /**
   * On blur
   */
  onBlur(): void {
    this.isFocused = false;
    if (!this.isDropdownOpen) {
      this.didBlur.emit(true);
    }
  }

  /**
   * Handle the click on a display type input
   */
  onInputClicked(): void {
    if (this.inputType === 'search' && !this.isDisplay) {
      this.hasInstructions =
        this.hasInstructions || this.instructions?.nativeElement?.childNodes?.length > 0;
      this.isDropdownOpen = true;
      return;
    }

    if (this.inputType === 'search' && this.isDisplay) {
      this.didClick.emit(true);
      return;
    }

    if (this.data?.length) {
      this.toggleDropDown();
    }
  }

  /**
   * Handle the outside dropdown click
   */
  onOusideClick(): void {
    if (this.isDropdownOpen) {
      this.isDropdownOpen = false;
      this.didBlur.emit(true);
      this.bwFormControl.markAsTouched();
    }
  }

  /**
   * Toggle the dropdown
   */
  toggleDropDown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  /**
   * Handle option selection
   * @param option
   * @returns
   */
  onOptionSelected(option: DropDownOption, index: number): void {
    // If we have multiple options grouped under the same label
    if (option.isGroupingOption) {
      this.didSearch.emit(option.value);
      this.data = [];
      this.isDropdownOpen = true;
      return;
    }

    if (this.setValueOnSelect) {
      this.bwFormControl.setValue(option.value);
      this.bwFormControl.updateValueAndValidity();
    }

    // set the placeholder as undefined so it can show the value
    this.placeholder = undefined;
    this.isDropdownOpen = false;
    this.selectedIndex = index;

    if (this.inputType === 'search') {
      this.isDisplay = true;
    }

    this.didSelect.emit(option);
  }

  /**
   * On init
   */
  ngOnInit(): void {
    // bwForm init
    super.ngOnInit();

    // set the dropdown id based on the label text
    this.id = `dropdown_${(this.label || '').replace(/\W/g, '').toLowerCase()}`;

    // If a value is set already
    if (this.bwFormControl?.value && this.bwFormControl?.valid) {
      // set the selected index
      // if the value is not an object, compare
      // if the value is an object, use displayFn to caompare
      // if displayFn is not available, do nothing (JSON.stringify can impact performmace)
      this.selectedIndex = this.data?.findIndex((option) => {
        if (typeof option.value !== 'object') {
          return option.value === this.bwFormControl.value;
        }
        if (typeof this.displayFn === 'function') {
          return this.displayFn(option.value) === this.displayFn(this.bwFormControl.value);
        }
      });

      this.isDisplay = this.inputType === 'search';
      this.placeholder = undefined;
      return;
    }

    // Drop down of type search, listen for values changes
    if (this.inputType === 'search') {
      this.searchSubscribe = this.get('search')
        .valueChanges.pipe(debounce((value) => timer(value ? +this.debounceTime : 0)))
        .subscribe((value: string) => {
          this.isDropdownOpen = true;
          this.didSearch.emit(value);
        });
    }
  }

  ngAfterViewInit() {
    // markAsSubmitted
    if (this.bwFormControl) {
      this.bwFormControl['markAsSubmitted'] = () => {
        this.markAsSubmitted();
      };

      // To close the dropdown when instructions has been clicked on
      fromEvent(this.instructions.nativeElement, 'click').subscribe(() => {
        this.isDropdownOpen = false;
      });
    }
  }
  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.searchSubscribe) {
      this.searchSubscribe.unsubscribe();
    }
  }
}
