import { Component, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import {
  MegaNavItemSection,
  MegaNavItemSectionLink,
  MegaNavItem,
  NavAnalyticsInfo
} from 'Shared/classes/mega-nav-item';
import { WindowRefService } from 'Shared/services/window.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { BehaviorSubject } from 'rxjs';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { StateService } from 'Shared/services/state.service';
import { translations } from 'Environments/js-translations';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';

export const checkoutBaseUrl = translations['js.routing.checkout_base'];

/* bw:view-encapsulation */
@Component({
  selector: 'bw-mega-nav-desktop',
  templateUrl: './mega-nav-desktop.component.html'
})
export class MegaNavDesktopComponent implements OnChanges {
  @Input() height: number;
  @Input() navItems: MegaNavItem[];
  @Input() showFilters$: BehaviorSubject<boolean>;
  @Output() navClicked = new EventEmitter<any>();
  @Output() filterDidToggle = new EventEmitter<any>();
  tallestSubnavHeight: number = 0;
  lastTappedItem: string = '';
  activeClass: string = 'is--active';
  navOpen: boolean;
  loadImages: boolean;

  constructor(
    private windowRef: WindowRefService,
    private analyticsService: AnalyticsService,
    public experimentService: ExperimentsService,
    private stateService: StateService,
    private optimizelyService: OptimizelyService,
  ) {}

  hideAll(): void {
    this.navOpen = false;
    const navSections = this.windowRef.nativeWindow.document.querySelectorAll(
      '.bw-mega-nav-desktop__link'
    );
    // IE 11 does not support forEach on querySelectorAll jazz
    for (const navSection of navSections) {
      navSection.classList.remove(this.activeClass);
    }
  }

  // Heap Events tracking, obvi brah!
  navInteractionTracking(
    navItem,
    navPath?: MegaNavItemSectionLink[] | MegaNavItemSection[] | MegaNavItem[]
  ): void {
    const navAnalyticsInfo = this.buildNavAnalyticsInfo(navItem, navPath);
    this.optimizelyService.trackEvent('nav_interaction');
    this.analyticsService.trackInHeap('navInteraction', navAnalyticsInfo);
    this.analyticsService.trackInteractionNav(navAnalyticsInfo);
    this.stateService.activeVisualNavPath = null;
  }

  // Build the nav analytics info object
  buildNavAnalyticsInfo(
    navItem,
    navPath?: MegaNavItemSectionLink[] | MegaNavItemSection[] | MegaNavItem[]
  ): NavAnalyticsInfo {
    const baseCondition = navPath && navItem.sectionType !== 'content';
    const mainNavItem = navPath ? navPath[0] : navItem;

    return {
      mainNavItem: navItem instanceof MegaNavItem ? navItem.name : navItem.navPath[0].name,
      primarySubNavItem:
        baseCondition && navPath[1] instanceof MegaNavItemSection ? navPath[1].name : undefined,
      secondarySubNavItem:
        baseCondition && navPath[2] instanceof MegaNavItemSectionLink ? navPath[2].name : undefined,
      navStyle: 'mega-nav',
      contentSpot: navItem.sectionType === 'content',
      mainNavItemNew: this.setMainNavItemNew(mainNavItem),
      primarySubNavItemNew: this.setPrimarySubNavItemNew(baseCondition, navPath),
      secondarySubNavItemNew: this.setSecondarySubNavItemNew(baseCondition, navPath)
    };
  }

  setMainNavItemNew(mainNavItem): any {
    return mainNavItem.styles && mainNavItem.styles.indexOf('new') > -1;
  }

  setPrimarySubNavItemNew(baseCondition: boolean, navPath): any {
    return baseCondition && (navPath[1].styles || []).indexOf('new') > -1;
  }

  setSecondarySubNavItemNew(baseCondition: boolean, navPath): any {
    return baseCondition && navPath[2] && (navPath[2].styles || []).indexOf('new') > -1;
  }

  navDidClicked(navItem: MegaNavItemSectionLink | MegaNavItemSection): any {
    this.navInteractionTracking(navItem, navItem.navPath);
    this.navClicked.emit(navItem);
    setTimeout(() => {
      this.hideAll();
    }, 0);
  }

  contentSpotClicked(section: MegaNavItemSection): void {
    this.navInteractionTracking(section, section.navPath);
  }

  handleTouchHover(
    event: Event,
    linkItem: Element,
    linkName: string,
    isHover: boolean,
    navItem?: MegaNavItem
  ): void {
    this.hideAll();
    const tappedItem = linkName;

    if (
      ((this.lastTappedItem.length && this.lastTappedItem !== tappedItem) ||
        !this.lastTappedItem.length) &&
      navItem.sections.length
    ) {
      event.preventDefault();
      this.setHover(linkItem, isHover);
      this.lastTappedItem = linkName;
      return;
    }
    this.setHover(linkItem, false);
    this.lastTappedItem = '';
  }

  setHover(linkItem: Element, isHover: boolean, navItem?: MegaNavItem): void {
    if (
      (isHover && !navItem) ||
      (isHover && navItem && navItem.sections && navItem.sections.length)
    ) {
      this.loadImages = true;
      this.navOpen = true;
      return linkItem.classList.add(this.activeClass);
    }
    this.navOpen = false;
    return linkItem.classList.remove(this.activeClass);
  }

  handleKeyUp(keyboardEvent: KeyboardEvent, linkItem: Element): void {
    switch (keyboardEvent.code || keyboardEvent.keyCode) {
      case 'Tab':
      case 9:
        this.hideAll();
        if (linkItem.querySelector('bw-mega-nav-desktop-child')) {
          this.setHover(linkItem, true);
        }
        break;

      case 'Escape':
      case 27:
        this.hideAll();
        break;
    }
  }

  toggleDesktopFilters(): void {
    this.filterDidToggle.emit();
  }

  ngOnChanges(): void {
    this.tallestSubnavHeight = this.height;
  }
}
