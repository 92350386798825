export class BoostedProductExperiment {
  experiment: string;
  variants: {
    variant: number;
    skus: number[];
  }[];
  carousels: {
    type: string;
    value: string;
    segment_ids: number[];
  }[];
}
