export class NavLinks {
  links: NavItem[] = [];
}

export class NavItem {
  name: string;
  slug: string;
  url: string;
  colour: string;
  tags: string[] = [];
  shortDescription: string;

  hiddenCheckout: boolean;
  hiddenHomepage: boolean;
  hiddenSideMenu: boolean;
  visibleDesktopTop: boolean;
  visibleMobileTop: boolean;
  visibleTabletTop: boolean;

  sublinks: SubNavItem[] = [];
}

export class SubNavItem {
  name: string;
  slug: string;
  url: string;
  parentSlug: string;
  tags: string[] = [];
}
