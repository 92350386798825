import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ProductGridViewToggleService,
  IGridView
} from 'Project/browse/services/product-grid-view-toggle.service';
import { Discount } from 'Project/shared/classes/discount';
import { GridProduct, ProductCardType } from 'Project/shared/classes/grid-product';
import { AddonRequirement, Product } from 'Project/shared/classes/product';
import { UpsoldFrom } from 'Project/shared/classes/upsell-option';
import { ViewportDetectionService } from 'Project/shared/services/viewport-detection.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'bw-product-card-content',
  templateUrl: './product-card-content.component.html',
  styleUrls: ['./product-card-content.component.scss']
})
export class ProductCardContentComponent implements OnInit {
  @Input() product: Product | GridProduct;
  @Input() productDiscount: Discount;
  @Input() showBundlePricing: boolean;
  @Input() showSubscriptionPricing: boolean;
  @Input() upsoldFrom: UpsoldFrom;
  @Input() isGiftVoucher: boolean;

  @Input() showRating: boolean = true;
  @Input() selectedProductCardType: ProductCardType = ProductCardType.standard;
  productCardType = ProductCardType;

  @Output() contentProductWasSelected = new EventEmitter<boolean>();
  @Output() contentProductInfoWasSelected = new EventEmitter<any>();
  @Output() contentProductWasUpsold = new EventEmitter<UpsoldFrom | Product>();
  @Output() contentProductReviewsSelected = new EventEmitter<any>();

  gridViewToggle$: BehaviorSubject<IGridView> = this.gridViewToggleService.gridViewToggle$;
  viewportSizeIs$: BehaviorSubject<any> = this.viewportDetectionService.viewportSizeIs$;

  constructor(
    private gridViewToggleService: ProductGridViewToggleService,
    private viewportDetectionService: ViewportDetectionService
  ) {}

  ngOnInit() {}

  upsell(upsoldProduct: UpsoldFrom) {
    this.contentProductWasUpsold.emit(upsoldProduct);
  }

  productSelected() {
    this.contentProductWasSelected.emit();
  }

  productInfoSelected() {
    this.contentProductInfoWasSelected.emit();
  }

  productReviewsSelected() {
    this.contentProductReviewsSelected.emit();
  }
}
