import * as dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import { ContentsModelService } from 'Shared/models/contents-model.service';
import { ServiceBarMessage } from 'Shared/classes/service-bar-message';
import { environment } from 'Environments/environment';
import { ContentService } from 'Shared/services/content.service';
import { SettingsService } from 'Shared/services/settings.service';
import { IServiceBarMessageData, IServiceBarMessageSettings } from '../interfaces/service-bar-message-settings';

@Injectable({
  providedIn: 'root'
})
export class ServiceBarService {
  messagesKey: string = 'service_bar_on_going';
  overrideMessagesKey: string = 'service_bar_overrides';
  daysOfWeek: string[] = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ];

  constructor(
    private contentsService: ContentsModelService,
    private contentService: ContentService,
    private settingsService: SettingsService
  ) {}

  private fromPayload(payload: IServiceBarMessageSettings): ServiceBarMessage[] {
    const serviceBarData: IServiceBarMessageData[] = payload.data;
    const serviceBarDeviceSettings: ServiceBarMessage['deviceSettings'] = {
      hideOnMobile: payload.hideOnMobile,
      hideOnDesktop: payload.hideOnDesktop
    };
    const serviceBar: ServiceBarMessage[] = (serviceBarData || []).map((message: IServiceBarMessageData)=> {
      const serviceBarMessage = new ServiceBarMessage();

      serviceBarMessage.active = message.active;
      serviceBarMessage.message = message.message || '';

      serviceBarMessage.tags = message.tags || [];
      serviceBarMessage.backgroundColour = message.backgroundColour;
      serviceBarMessage.textColour = message.textColour;
      serviceBarMessage.weight = message.weight;
      serviceBarMessage.daysVisible = message.daysVisible
        ? message.daysVisible.map((dv) =>
            this.daysOfWeek.findIndex((i) => i.toLowerCase() === dv.toLowerCase())
          )
        : null;

      const fromDateSplit = message.fromDate.split('-');
      const fromTimeSplit = (message.fromTime || '00:00').split(':');
      serviceBarMessage.from = dayjs()
        .set('second', 0)
        .set('minute', +fromTimeSplit[1])
        .set('hour', +fromTimeSplit[0])
        .set('date', +fromDateSplit[2])
        .set('month', +fromDateSplit[1] - 1)
        .set('year', +fromDateSplit[0]);

      const toDateSplit = message.toDate.split('-');
      const toTimeSplit = (message.toTime || '23:59').split(':');
      serviceBarMessage.to = dayjs()
        .set('second', 0)
        .set('minute', +toTimeSplit[1])
        .set('hour', +toTimeSplit[0])
        .set('date', +toDateSplit[2])
        .set('month', +toDateSplit[1] - 1)
        .set('year', +toDateSplit[0]);
      serviceBarMessage.deviceSettings = serviceBarDeviceSettings;

      return serviceBarMessage;
    })

    return serviceBar;
  }

  private getMessagesForKey(key: string): Promise<any> {
    const data = this.settingsService.getSetting(key) || [];
    return Promise.resolve(data);
  }

  getOnGoingServiceBarMessages(): Promise<ServiceBarMessage[]> {
    return this.getMessagesForKey(this.messagesKey).then((c) => this.fromPayload(c));
  }

  getOverrideServiceBarMessages(): Promise<ServiceBarMessage[]> {
    return this.getMessagesForKey(this.overrideMessagesKey).then((c) => this.fromPayload(c));
  }

  /**
   * Get all the service bar messages, with segmentation over-rides
   */
  getAllServiceBarMessages(): Promise<ServiceBarMessage[][]> {
    return Promise.all([
      this.getOnGoingServiceBarMessages(),
      this.getOverrideServiceBarMessages(),
      this.contentService.getContentSegments()
    ]).then(([ongoing, override, contentSegments]) => {
      const toExclude = contentSegments.reduce((acc, segment) => {
        acc.push(...segment.excluded_tags);
        return acc;
      }, []);

      return [
        ongoing.filter((msg) => !toExclude.find((t) => (msg.tags || []).indexOf(t) > -1)),
        override.filter((msg) => !toExclude.find((t) => (msg.tags || []).indexOf(t) > -1))
      ];
    });
  }
}

export { ServiceBarMessage } from 'Shared/classes/service-bar-message';
