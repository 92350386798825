
<ng-container *ngIf="optOutForm &amp;&amp; optOutForm!=='UnknownType'">
  <bw-opt-out-redirect class="text-center" redirect="/occasion-day-opted-out" [occasion]="optOutForm">
    <p>{{buttonText}}</p>
  </bw-opt-out-redirect>
</ng-container>
<form class="bwForm" *ngIf="!optOutForm">
  <div class="alert alert-success" *ngIf="success">{{successMessage}}</div>
  <div class="form-container" [hidden]="success">
    <div class="form-group" *ngFor="let field of fieldsStructure">
      <!-- Checkbox-->
      <bw-form-checkbox-input *ngIf="field.type === 'checkbox'" [bwFormControl]="formGroup.get(field.name)"><span role="label">{{field.label}}</span>
        <ng-container role="errors">
          <div for="required">{{field.requiredText}}</div>
        </ng-container>
      </bw-form-checkbox-input>
      <!-- If form is a file-->
      <!-- TODO: Decide what the bucket location should be for the form-->
      <div class="input-wrapper" *ngIf="field.type === 'file'">
        <bw-form-file-upload class="form-control" [s3BucketLocation]="awsS3Bucket" [limit]="field.maxLength &gt; 0 ? field.maxLength : -1" [uploadInstantly]="true" (filesChanged)="onFilesChanged($event, field.name)"></bw-form-file-upload>
        <label class="wide" [attr.for]="field.name">{{field.label}}</label>
      </div>
      <!-- If email or text - usual stuff-->
      <bw-form-text-input [bwFormControl]="formGroup.get(field.name)" *ngIf="field.type === 'email' || field.type === 'text' || field.type === 'textarea'" [maxLength]="field.maxLength || 150" [multiline]="field.type === 'textarea'" [expandOnTypeToMaxCSSHeight]="field.type === 'textarea'" [label]="field.label" [inputType]="'text'">
        <ng-container role="errors">
          <div for="required">{{field.requiredText}}</div>
          <div for="email">Not a valid email address</div>
          <div for="maxlength">Text is too long</div>
        </ng-container>
      </bw-form-text-input>
    </div>
    <div class="form-group text-center">
      <bw-button analytics-label="submitQuery" buttonType="submit" buttonStyle="primary" [size]="buttonSize" (clicked)="submitForm()">{{ buttonText }}</bw-button>
    </div>
  </div>
  <bw-loading-spinner *ngIf="loading"></bw-loading-spinner>
</form>