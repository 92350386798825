import { Injectable, ComponentRef } from '@angular/core';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { Subject } from 'rxjs';
import { ModalDisplayService } from 'Shared/services/modal-display.service';
import { ModalBaseComponent } from 'Shared/components/modal-base/modal-base.component';
import { HeapService } from 'Shared/services/third-parties/heap.service';
import { WindowRefService } from 'Shared/services/window.service';
import { ILazyModalsList } from 'Modals/interfaces/lazy-modals-list';
import { getLazyModalModule } from 'Modals/modal-declarations';
import { FullscreenLoadingSpinnerService } from 'Shared/services/fullscreen-loading-spinner.service';
import { Error } from 'Shared/classes/error';
import { GaService } from 'Shared/services/third-parties/ga.service';

/**
 * Given a Class that extends ModalComponent
 * Return back the Type parameter of the class
 */
type ResolvedDataType<T> = T extends ModalComponent<infer U, infer R> ? U : any;
type RejectedDataType<T> = T extends ModalComponent<infer U, infer R> ? R : any;

// To allow for the older-style 'callable' type syntax, disable tslint for this line
/* tslint:disable:callable-types */
type Class<T> = { new (...args: any[]): T };

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  window: Window;
  bwModalRefs: ComponentRef<ModalBaseComponent>[] = [];

  constructor(
    private analyticsService: AnalyticsService,
    private modalDisplay: ModalDisplayService,
    private heapService: HeapService,
    private windowRefService: WindowRefService,
    private loadingSpinnerService: FullscreenLoadingSpinnerService,
    private gaService: GaService
  ) {
    this.window = this.windowRefService.nativeWindow;
  }

  /**
   * Hide all modals currently displaying
   */
  hideAllModals(): void {
    this.bwModalRefs.forEach((modal) => {
      modal.instance.dismissModal(false, {
        sendModalCloseEvent: false
      });
    });
    this.bwModalRefs = [];
  }

  // Typescript doesn't support promise.reject types - https://github.com/microsoft/TypeScript/issues/7588
  // - so we can only use the resolved data, and not any rejected data
  /**
   * Show the modal
   * @param component
   * @param options
   * @returns
   */
  show<T>(component: Class<T>, options?: BwModalOptions<T>): Promise<ResolvedDataType<T>> {
    const config = Object.assign(new ModalServiceDefaultOptions(), options || {});

    if (config.dismissDisplayingModals) {
      this.hideAllModals();
    }

    // Blur any elements which are active, this ensures that input fields under the modal aren't active, and therefore the keyboard isn't shown
    setTimeout(() => {
      const activeElement = this.window.document.activeElement;
      try {
        if (activeElement && activeElement['blur']) {
          activeElement['blur']();
        }
      } catch (e) {}
    }, 0);

    return new Promise((resolve, reject) => {
      this.modalDisplay
        .create(component, config)
        .then((modalRef) => {
          if (config.trackingKey) {
            this.analyticsService.track('component.modal.show', {
              modalType: options.trackingKey,
              modalValue: options.trackingValue
            });
            this.heapService.logAdvancedEvent('modalView', {
              modalType: options.trackingKey
            });
            this.analyticsService.trackModalView(options.trackingKey);
          }

          this.bwModalRefs.push(modalRef);
          modalRef.instance.onDismiss.subscribe((response) => {
            if (!response) {
              return;
            }
            this.modalDisplay.destroy(response.ref as any); // shut up types

            if (
              options?.trackingKey &&
              !response.success &&
              response?.data?.['sendModalCloseEvent'] !== false
            ) {
              this.analyticsService.track('component.modal.close', {
                modalType: options.trackingKey,
                modalValue: options.trackingValue
              });
            }

            return response.success ? resolve(response.data) : reject(response.data);
          });
        })
        .catch((e) => {
          reject(e);
        });
    }) as any;
  }

  /**
   *
   * @param component : The component name, must be exactly how the component is named
   * @param options
   * @returns
   */
  showLazyModal<T>(component: ILazyModalsList, options?: BwModalOptions<T>): Promise<any> {
    // Incase user is on a slower connection
    this.loadingSpinnerService.show();

    return getLazyModalModule(component)
      .catch((e) => {
        const error = new Error({
          message: `Unable to lazy load modal ${component?.name}`,
          code: 'modalNotLazyLoaded'
        });

        this.gaService.trackError(error);
        return Promise.reject();
      })
      .then((c) => {
        this.loadingSpinnerService.hide();
        return this.show(c[component?.name], options);
      });
  }
}

/**
 * Ensure the "initialState" is correctly typed, based on the modal being used
 * If the modal DOES NOT extend `ModalComponent`, then allow the 'any' type
 */
type InitialState<Type> = Type extends ModalComponent<any, any> ? Partial<Type> : any;

export interface BwModalOptions<T> {
  /**
   * initialState
   * Data that is available inside the child component.
   *
   * Example:
   * `modalTitle: 'Hello World'`
   * `modalCopy: 'Welcome to this modal, bow to its majesty!'`
   *
   * Required:
   * All properties must be defined in the child modal with the same name as the key
   * Otherwise the data will be discarded and you will feel silly
   */
  initialState?: InitialState<T>;

  /**
   * modalName
   *
   * If name is defined then a class with the format of:
   * `modal-showing-MODAL-NAME-HERE` will be appended to the body
   */
  modalName?: string;

  /**
   * ignoreBackdropClick
   *
   * Stop the modal dismissing if the backdrop is clicked
   */
  ignoreBackdropClick?: boolean;

  /**
   * class
   *
   * Arbitrary classes, will be appended to body on modal display
   */
  class?: string;

  /**
   * trackingKey
   * trackingVAlue
   *
   * Used for analytics, will send event when modal opened
   */
  trackingKey?: string;
  trackingValue?: string;

  /**
   * underNav
   *
   * If true modal will appear under navbar and appropriate
   * padding will be added inside the modal container
   */
  underNav?: boolean;

  /**
   * animationDirection
   *
   * animate the modal in and out
   * bottom - the default, the classic, the legend
   * right | left - used for 'burger menus' typically
   */
  animationDirection?: 'bottom' | 'right' | 'left' | 'none' | 'center';
  /**
   * Dismiss any modals that are currently displaying,
   * immediately before showing our new modal
   */
  dismissDisplayingModals?: boolean;

  /**
   * keyboard
   *
   * enable or disable using escape key to dismiss
   */
  keyboard?: boolean;

  /**
   * closeOnStateChange
   *
   * this flag tells the modal if it should close when the state changes
   */
  closeOnStateChange?: boolean;

  /**
   * Ignore any custom scroll events
   */
  useNativeScroll?: boolean;

  /**
   * Set URL via history API
   */
  historyUrl?: string;
}

export class ModalServiceDefaultOptions {
  animationDirection: 'bottom' | 'right' | 'left' | 'none' = 'bottom';
  class: string = 'modal-sm';
  underNav: boolean = false;
  dismissDisplayingModals: boolean = true;
  keyboard: boolean = true;
  closeOnStateChange: boolean = true;
  useNativeScroll = false;
  historyUrl: string;
}

export interface ModalResponseSubject {
  success: boolean;
  data?: object;
}

export interface ModalBaseResponse extends ModalResponseSubject {
  ref: ComponentRef<ModalBaseComponent | any>;
}

export interface ModalResponse {
  modalResponse: Subject<ModalResponseSubject>;
  onSuccess?(): void;
  onCancel?(): void;
}

/**
 * Abstract Modal Component, with promise typings
 */
export abstract class ModalComponent<TResolveData, TRejectData> {
  private modalResponse: Subject<ModalResponseSubject> = new Subject<ModalResponseSubject>();

  /**
   * Close the modal, ensuring the data is typed
   * @param status
   * @param data
   * @returns
   */
  closeAsResolve(data: TResolveData): TResolveData {
    this.modalResponse.next({
      data: (data as unknown) as object,
      success: true
    });
    return data;
  }

  /**
   * Close with an error
   * @param data
   * @returns
   */
  closeAsReject(data: TRejectData): TRejectData {
    this.modalResponse.next({
      data: (data as unknown) as object,
      success: false
    });
    return data;
  }
}
