
<div class="bw-rewards-confirmation-modal">
  <ui-box box_background="rewards-primary" box_space="none">
    <div class="modal-header">
      <p *ngIf="!existingMember">You are in!</p>
      <p *ngIf="existingMember">Welcome back</p>
      <ui-button button_size="small" button_type="tertiary-inverse" button_icon="close" button_icon_placement="iconOnly" (clicked)="onCancel()"><span aria-hidden="true">&times;</span></ui-button>
    </div>
    <div class="modal-body">
      <ui-box box_space="tight" box_background="transparent" box_align_x="center">
        <ui-stack stack_distribute="center" stack_align="center">
          <ui-heading class="text-color--highlight" *ngIf="!existingMember" heading_type="secondary" heading_title="Welcome to Rewards"></ui-heading>
          <ui-heading class="text-color--highlight" *ngIf="existingMember" heading_type="secondary" heading_title="Have we met before?"></ui-heading>
          <ui-box box_fill_mode="fill" box_space="none">
            <ui-image *ngIf="!existingMember" image_alt_text="Rewards confirmation image" image_src="https://images.ctfassets.net/1d9ajmvjpuhw/2Q9c0l3HW0DhuPWHBf7h6k/cd9c4d19b541da4c6438f1a06e120e92/CRM_Arch_Outline.gif"></ui-image>
            <ui-box *ngIf="existingMember" box_background="rewards-primary" box_space="expanded" box_space_top="none" box_space_bottom="none">
              <ui-image image_alt_text="Rewards confirmation image" image_src="https://images.ctfassets.net/ucgi79tscdcj/3DAShbi5bTlhxeQhnADm0c/92f9ff7ac945d809346c1440e9a214f8/arch_rewards.svg"></ui-image>
            </ui-box>
          </ui-box>
          <p class="text-center text-body--default" *ngIf="!existingMember &amp;&amp; welcomePoints">We’ve added {{welcomePoints}} welcome points to your pot.</p>
          <p class="text-center text-body--default" *ngIf="existingMember">Hey there, it looks like you’re already a Rewards member. Welcome back!</p>
          <ui-button button_type="expressive" button_size="large" button_full_width="true" (clicked)="gotToRewards()"> Go to Rewards</ui-button>
          <ui-button button_type="tertiary-inverse" button_size="small" button_full_width="true" (clicked)="goToShopNow()"> Keep earning points</ui-button>
        </ui-stack>
      </ui-box>
    </div>
  </ui-box>
</div>