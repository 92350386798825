import { Injectable } from '@angular/core';

function getWindow(): any {
  return window;
}

@Injectable()
export class WindowRefService {
  private window: any;
  get nativeWindow(): any {
    return getWindow();
  }

  constructor() {
    this.window = this.nativeWindow;
  }

  /**
   * Get cache from the window
   * @param key
   */
  getCache(key: string): any {
    return this.nativeWindow.bwAjaxCache &&
      this.nativeWindow.bwAjaxCache[key] &&
      (this.nativeWindow.bwAjaxCache.length || typeof this.nativeWindow.bwAjaxCache === 'object')
      ? this.nativeWindow.bwAjaxCache[key]
      : undefined;
  }

  /**
   * Get a cookie by name
   * https://stackoverflow.com/questions/10730362/get-cookie-by-name
   * @param name
   */
  getCookie(name: string): any {
    const value = `; ${this.window.document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return '';
  }

  /**
   * Set a Cookie
   * @param name
   * @param value
   * @param days Number days till cookie expires
   */
  setCookie(name: string, value: unknown, days: number): void {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();
    document.cookie = name + '=' + value + ';' + expires + ';path=/';
  }

  /**
   * Clear all the cookies:
   * https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
   */
  clearAllCookies(): void {
    const cookies = this.window.document.cookie.split(';');
    cookies.forEach((cookie) => {
      const equalPosition = cookie.indexOf('=');
      const name = equalPosition > -1 ? cookie.substr(0, equalPosition) : cookie;
      this.window.document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    });
  }

  clearCache(key: string): any {
    if (this.nativeWindow.bwAjaxCache && this.nativeWindow.bwAjaxCache[key]) {
      this.nativeWindow.bwAjaxCache[key] = undefined;
    }

    if (this.nativeWindow.bwPromiseCache && this.nativeWindow.bwPromiseCache[key]) {
      this.nativeWindow.bwPromiseCache[key] = undefined;
    }
  }

  /**
   * Get the cache as a resolved promise
   * @param key
   * @param fallback Passed as a function to not run the promise first
   */
  getCacheAsPromise(key: string, fallback: Function): Promise<any> {
    // First check if we have a standard 'window cache'
    const cache = this.getCache(key);
    if (cache) {
      return Promise.resolve(cache);
    }

    // // Check the window for a promiseCache
    const promiseCache = this.getPromiseCache(key);
    if (promiseCache) {
      return promiseCache.then((res) => {
        // Fallback to AlternatePromise if no data
        return res || (fallback && fallback()) || undefined;
      });
    }
    // Finally return the alternate promise
    return fallback();
  }

  /**
   * Get the Promise cache (if available)
   * @param key
   */
  getPromiseCache(key: string): Promise<any> {
    return this.nativeWindow.bwPromiseCache && this.nativeWindow.bwPromiseCache[key] ? this.nativeWindow.bwPromiseCache[key] : undefined;
  }

  isElementVisible(elementTop: number, elementHeight: number, checkBounds: string): boolean {
    const elementBottom = elementTop + elementHeight;

    if (checkBounds === 'bottom' && elementBottom > this.nativeWindow.innerHeight + this.nativeWindow.scrollY) {
      return true;
    }

    if (checkBounds === 'top' && elementTop < this.nativeWindow.scrollY) {
      return true;
    }

    return false;
  }

  /**
   * Get the y position of the window
   */
  getWindowBottomPosition(): number {
    const doc = this.nativeWindow.document.documentElement;
    const top = (this.nativeWindow.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    return top + this.getWindowHeight();
  }

  getWindowHeight(): number {
    return this.nativeWindow.innerHeight;
  }

  getScrollElements(selector: string): Promise<Element> {
    let attempts = 0;
    const maxAttempts = 3;
    return new Promise((resolve, reject) => {
      const intObj = setInterval(() => {
        attempts++;
        const elem = document.querySelector(selector);
        if (elem) {
          resolve(elem);
          clearInterval(intObj);
        } else if (attempts > maxAttempts) {
          resolve(null);
        }
      }, 100);

      const ele = document.querySelector(selector);
      if (ele) {
        resolve(ele);
      } else {
        setTimeout(() => {
          clearInterval(intObj);
        }, 1 * 1000);
      }
    });
  }

  /**
   * Wait for a queryselector to be valid
   */
  waitForQuerySelector(cssSelector: string, maxTries: number = 10, retryMs: number = 200): Promise<Element> {
    return new Promise((resolve, reject) => {
      let attempts = 0;

      const fn = () => {
        const elem = document.querySelector(cssSelector);
        if (elem) {
          clearInterval(interval);
          resolve(elem);
        } else if (attempts === maxTries) {
          clearInterval(interval);
          reject(false);
        }
      };

      const interval = setInterval(() => {
        attempts++;
        fn();
      }, retryMs);

      fn();
    });
  }

  /**
   * Scroll to the order form, or keep checking until the element appears then scroll
   */
  scrollToElem(selector: string, offsetSelector?: string, block: string = 'start', behavior: string = 'smooth'): void {
    const scrollToForm = (e?: any) => {
      if (e) {
        e.scrollIntoView({ behavior: behavior, block: 'start' });
      }
    };

    if (!offsetSelector || !offsetSelector.length) {
      this.getScrollElements(selector).then((e) => scrollToForm(e));
    } else {
      Promise.all([this.getScrollElements(selector), this.getScrollElements(offsetSelector)]).then((elements) => {
        const elementTop = elements[0] ? elements[0].getBoundingClientRect().top : 0;
        const offsetElementHeight = elements[1] ? elements[1].getBoundingClientRect().height : 0;
        const windowScroll = this.nativeWindow.pageYOffset || this.nativeWindow.scrollTop || 0;
        const topCalc = windowScroll + elementTop - offsetElementHeight;
        this.nativeWindow.scroll({
          top: topCalc,
          left: 0,
          behavior: behavior
        });
      });
    }
  }

  getElementTop(element: Element, offset: number = 0): number {
    return element
      ? element.getBoundingClientRect().top + ((this.nativeWindow.scrollY || this.nativeWindow.pageYOffset || 0) - offset)
      : this.nativeWindow.scrollY || this.nativeWindow.pageYOffset || 0;
  }

  getElementLeft(element: Element): number {
    return element.getBoundingClientRect().left;
  }

  scrollSelectorIntoView(selector: string, offset: number = 0, boundsOverride?: string): void {
    this.getScrollElements(selector).then((elm) => {
      if (elm) {
        this.scrollElementIntoView(elm, offset, boundsOverride);
      }
    });
  }

  /**
   * Checks the specified side of the element is in view and scrolls accordingly
   * @param {Element} element
   * @param {Integer} offset
   */
  scrollElementIntoView(element: Element, offset: number = 0, checkBounds: string = 'bottom'): void {
    if (!element) {
      return;
    }

    const elementTop = this.getElementTop(element);
    const elementHeight = element.getBoundingClientRect().height;
    const elementBottom = elementTop + elementHeight;

    if (checkBounds !== 'none' && !this.isElementVisible(elementTop, elementHeight, checkBounds)) {
      return;
    }

    let targetScrollY =
      this.nativeWindow.scrollY + (elementBottom - (this.nativeWindow.innerHeight + this.nativeWindow.scrollY) - offset) + 32;

    if (checkBounds === 'top' || checkBounds === 'none') {
      targetScrollY = elementTop - offset;
    }

    this.nativeWindow.scroll({
      top: targetScrollY,
      left: 0,
      behavior: 'smooth'
    });
  }

  /**
   * Scroll to top of page
   */
  scrollToTop(): void {
    this.nativeWindow.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
