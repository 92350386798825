import { Injectable } from '@angular/core';
import { InvoiceModelService } from 'Shared/models/invoice-model.service';
import { Purchase } from 'Shared/classes/purchase';
import { Invoice } from 'Shared/classes/invoice';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  constructor(private invoiceModelService: InvoiceModelService) {}

  /**
   * Generate an invoice given a purchase
   * @param purchase
   */
  generate(purchase: Purchase): Promise<Invoice> {
    return this.invoiceModelService.generate(purchase);
  }
}
