
<div class="modal-header">
  <div class="modal-title">{{title}}</div>
  <button class="close" *ngIf="showCloseCross" aria-label="close" (click)="onCancel()"><span aria-hidden="true">&times;</span></button>
  <bw-button *ngIf="showModalCta" buttonStyle="primary" size="large" (click)="onCancel()">{{modalCtaText}}</bw-button>
</div>
<div class="modal-body" #bwModal>
  <iframe *ngIf="asIframe"></iframe>
  <bw-content-display *ngIf="!asIframe" [content]="content"></bw-content-display>
  <bw-loading-spinner [fullscreen]="false" *ngIf="loading"></bw-loading-spinner>
</div>
<div class="modal-footer" *ngIf="showModalCta">
  <bw-button buttonStyle="primary" fullWidth="true" size="large" (click)="onCancel()">{{modalCtaText}}</bw-button>
</div>