import { FormGroup, FormControl } from '@angular/forms';
import { Address } from 'Shared/classes/address';
import { ShippingOption } from 'Shared/classes/shipping-option';

export class ValidateRecipientSearch {
  /**
   * Require the phone for the shipping option
   * @param shippingOption
   */
  static requirePhoneForShippingOption(shippingOption: ShippingOption): any {
    return (formControl: FormControl): any => {
      const isValid =
        !shippingOption.hasPhoneNumberRequired ||
        (formControl.value && shippingOption.hasPhoneNumberRequired);

      return isValid ? null : { phoneNumberRequiredForShippingOption: true };
    };
  }

  /**
   * Validate against a regex
   * @param postcodeRegex
   */
  static postcodePattern(postcodeRegex: string): any {
    const regex = new RegExp(postcodeRegex, 'gmi');
    return (formGroup: FormGroup): any => {
      const address: Address = formGroup.value || {};
      const postcode = address.postcode || '';

      return postcode.match(regex) ? null : { postcodePatternInvalid: true };
    };
  }

  /**
   * Postcode length
   * @param length
   */
  static postcodeLength(length: number): any {
    return (formGroup: FormGroup): any => {
      const address: Address = formGroup.value || {};
      const postcode = address.postcode || '';
      return postcode.length === length ? null : { postcodePatternInvalid: true };
    };
  }

  /**
   * Postcode required
   * @param formGroup
   */
  static postcodeRequired(formGroup: FormGroup): any {
    const address: Address = formGroup.value || {};
    const postcode = address.postcode || '';

    return postcode.length ? null : { postcodeRequired: true };
  }

  static lineOneRequired(formGroup: FormGroup): any {
    const address: Address = formGroup.value || {};

    return (address.line1 || '').length ? null : { line1Required: true };
  }

  static cityRequired(formGroup: FormGroup): any {
    const address: Address = formGroup.value || {};

    return (address.city || '').length ? null : { cityRequired: true };
  }
}
