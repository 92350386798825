import * as dayjs from 'dayjs';
import { Component, OnInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { t, TranslationKey } from 'Shared/utils/translations';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { AnalyticsService } from 'Shared/services/analytics.service';

@Component({
  selector: 'bw-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CountdownComponent implements OnInit, OnDestroy {
  constructor(private analytics: AnalyticsService, public experimentService: ExperimentsService) {}

  @Input()
  countdowntoutcdateandtime: string;

  @Input()
  premessage: string = '';

  @Input()
  postmessage: string = '';

  @Input()
  mondayutctime: string;
  @Input()
  tuesdayutctime: string;
  @Input()
  wednesdayutctime: string;
  @Input()
  thursdayutctime: string;
  @Input()
  fridayutctime: string;
  @Input()
  saturdayutctime: string;
  @Input()
  sundayutctime: string;

  @Input()
  timeoutmessage: string = t('js.countdown.time_over');

  @Input()
  experimentfallback: string = '';

  @Input()
  minimumMinutes: number;

  initialBrowserDate: dayjs.Dayjs;
  initialBrowserDifference: number;
  date: dayjs.Dayjs;
  refreshInterval;
  countdown: string;
  countdownComplete: boolean;
  time: number;

  daysOfWeek: string[] = [
    'sundayutctime',
    'mondayutctime',
    'tuesdayutctime',
    'wednesdayutctime',
    'thursdayutctime',
    'fridayutctime',
    'saturdayutctime'
  ];

  utcTime(): dayjs.Dayjs {
    return dayjs().add(new Date().getTimezoneOffset(), 'minute');
  }

  currentServerTime(): dayjs.Dayjs {
    const difference = this.utcTime().diff(this.initialBrowserDate, 'second', false);
    return this.utcTime().add(difference - this.initialBrowserDifference, 'second');
  }

  setCountdown(): void {
    this.countdown = '';
    this.time = this.time - 1;

    if (this.minimumMinutes && this.time / 60 > this.minimumMinutes) {
      this.countdownOver();
      return;
    }

    if (this.time < 0) {
      return this.countdownOver();
    }

    const times = [
      {
        unit: 'days',
        value: `${Math.floor(this.time / (60 * 60 * 24))}`
      },
      {
        unit: 'hours',
        value: `${Math.floor((this.time / (60 * 60)) % 24)}`
      },
      {
        unit: 'minutes',
        value: `${('0' + Math.floor((this.time / 60) % 60)).slice(-2)}`
      },
      {
        unit: 'seconds',
        value: `${('0' + Math.floor(this.time % 60)).slice(-2)}`
      }
    ].filter(i => i.value !== '0');

    // Should only fire analytics event when countdown actually begins counting down
    this.analytics.track('countdownDidCountdown', null, true);

    this.countdown = times
      .map(timeItem => {
        const value = timeItem.value;
        const unit = `${t(`js.countdown.stopwatch_${timeItem.unit}` as TranslationKey)}`;
        return `${value}${unit}`;
      })
      .join(' ');
  }

  countdownOver(): void {
    clearInterval(this.refreshInterval);
    this.countdownComplete = true;
  }

  startCountdown(): void {
    this.time = this.date.unix() - this.currentServerTime().unix();
    this.refreshInterval = setInterval(() => {
      this.setCountdown();
    }, 1000);
  }

  setupDate(date: dayjs.Dayjs): void {
    this.date = date;
    this.startCountdown();
  }

  ngOnDestroy(): void {
    this.countdownOver();
  }

  ngOnInit(): void {
    this.initialBrowserDate = dayjs();
    this.initialBrowserDifference = this.utcTime().diff(this.initialBrowserDate, 'second', false);

    if (this.countdowntoutcdateandtime) {
      this.setupDate(dayjs(this.countdowntoutcdateandtime));
    } else if (
      this.mondayutctime ||
      this.tuesdayutctime ||
      this.wednesdayutctime ||
      this.thursdayutctime ||
      this.fridayutctime ||
      this.saturdayutctime ||
      this.sundayutctime
    ) {
      const activeDate = this.daysOfWeek[this.utcTime().day()];
      const activeTimeArray = this[activeDate].split(':');
      const repeatUTCTime = this.utcTime()
        .set('hour', 0)
        .set('minute', 0)
        .add(activeTimeArray[0], 'hour')
        .add(activeTimeArray[1], 'minute');
      this.setupDate(repeatUTCTime);
    }
  }
}
