import { ElementRef, Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';

type IntersectionCallback = (n: IntersectionObserverEntry) => any;

interface IntersectionConfig {
  rootMargin: string;
  threshold: number;
  root?: Element | null;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalIntersectionService {
  private observers: {
    [key: string]: IntersectionObserver;
  } = {};

  private window;

  constructor(windowRef: WindowRefService) {
    this.window = windowRef.nativeWindow;
  }

  /**
   * Un observe
   * @param element
   * @param observer optional observer to unobserve from
   */
  unobserve(element: any, observer?: IntersectionObserver): void {
    if (observer) {
      return observer.unobserve(element);
    }

    const key = element.intersectionObserverKey;
    if (key && this.observers[key]) {
      this.observers[key].unobserve(element);
    }
  }

  /**
   * Observe the element with given options, and run the callbacks when neccessary
   * @param element
   * @param options
   * @param onIntersectEntry
   * @param onIntersectExit
   */
  observe(
    element: any,
    options: IntersectionConfig | IntersectionObserver,
    onEntry?: IntersectionCallback,
    onExit?: IntersectionCallback
  ): IntersectionObserver {
    // Passed in an instance, use it
    if (options instanceof IntersectionObserver) {
      options.observe(element);
      return options;
    }

    element.onIntersectEntryCallback = onEntry;
    element.onIntersectExitCallback = onExit;

    // If we pass in a root element, we can't really cache, so intead, create a random one and return
    const key = options.root
      ? `${+new Date()}${Math.random()}`
      : `${options.rootMargin}|${options.threshold}`;

    // Store it on the element, we can then use this via `unobserve`
    element.intersectionObserverKey = key;

    // If we don't have a cached observer, create a new one
    if (!this.observers[key]) {
      this.observers[key] = this.createObserver(options);
    }

    // Finally, observe the element
    this.observers[key].observe(element);

    return this.observers[key];
  }

  /**
   * On intersection callback, run the callbacks for each entry
   * @param entries
   */
  private onIntersectionCallback(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.target['onIntersectEntryCallback']) {
        entry.target['onIntersectEntryCallback'](entry);
      }
      if (!entry.isIntersecting && entry.target['onIntersectExitCallback']) {
        entry.target['onIntersectExitCallback'](entry);
      }
    });
  }

  /**
   * Create an intersection Observer
   * @param options
   */
  createObserver(options: IntersectionConfig): IntersectionObserver {
    return new this.window.IntersectionObserver((entries) => {
      this.onIntersectionCallback(entries);
    }, options);
  }
}
