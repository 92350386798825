import * as dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import { BackendService } from 'Shared/services/backend.service';
import { UserService } from 'Shared/services/user.service';
import {
  OccasionReminder,
  OccasionType,
  OccasionTypeDisplay
} from 'Shared/classes/occasion-reminder';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { ContentService } from 'Shared/services/content.service';
import { ContentSegment } from 'Shared/models/segment-model.service';

@Injectable({
  providedIn: 'root'
})
export class OccasionRemindersModelService {
  constructor(
    private backend: BackendService,
    private userService: UserService,
    private contentService: ContentService,
    private analyticsService: AnalyticsService
  ) {}

  static occasionReminderFromPayload(occasion: any): OccasionType {
    const reminder = new OccasionType();
    reminder.id = parseInt(occasion.id, 10);
    reminder.tags = occasion.attributes.tags || [];
    reminder.displayName = occasion.attributes.display_name;
    reminder.kind = occasion.attributes.kind;
    reminder.relation = occasion.attributes.relation;
    reminder.displayImageUrl = occasion.attributes.display_image_url;
    reminder.associatedDate = occasion.attributes.suggested_date
      ? dayjs(occasion.attributes.suggested_date)
      : null;
    return reminder;
  }

  static occasionReminderFromKindPayload(occasion: any): OccasionType {
    const reminder = new OccasionType();
    reminder.id = parseInt(occasion.id, 10);
    reminder.kind = occasion.attributes.name;
    reminder.tags = occasion.attributes.tags || [];
    reminder.associatedDate = occasion.attributes.suggested_date
      ? dayjs(occasion.attributes.suggested_date)
      : null;
    return reminder;
  }

  static occasionReminderToPayload(occasion: OccasionReminder): Object {
    return {};
  }

  static occasionKindFromPayload(occaisonKind: any): OccasionType {
    const reminderKind = new OccasionType();
    reminderKind.displayName = occaisonKind.name;
    reminderKind.tags = occaisonKind.attributes.tags || [];
    reminderKind.associatedDate = occaisonKind.associatedDate
      ? dayjs(occaisonKind.associatedDate)
      : null;
    return reminderKind;
  }

  static fromPayload(occasion: any): OccasionReminder {
    const reminder = new OccasionReminder();
    reminder.id = parseInt(occasion.id, 10);
    reminder.recipient = occasion.recipient_name;

    const type = occasion.occasion_type || {};

    reminder.type.id = type.id;
    reminder.type.displayName = type.display_name || occasion.kind;
    reminder.type.displayKind = type.display_kind || occasion.kind;
    reminder.type.kind = type.kind || occasion.kind;
    reminder.type.relation = type.relation || occasion.relation;
    reminder.type.tags = type.tags || occasion.tags || [];

    // Move this logic to backend
    reminder.type.hasRecipient = !(
      reminder.type.kind === `Valentine's Day` || reminder.type.kind === `Mother's Day`
    );

    reminder.type.associatedDate = occasion.occurs_on ? dayjs(occasion.occurs_on) : null;
    return reminder;
  }

  static toPayload(occasion: OccasionReminder): Object {
    return {
      id: occasion.id,
      recipient_name: occasion.recipient, // TODO when Ged gets stuff on stagin'
      kind: occasion.type.kind,
      occurs_on: (occasion.type.associatedDate || dayjs()).format('YYYY-MM-DD'),
      relation: occasion.type.relation,
      occasion_type_id: occasion.type.id
    };
  }

  getSavedOccasions(): Promise<OccasionReminder[]> {
    const user = this.userService.getUser();
    return this.backend.get(user, '/v1/occasions').then((res) => {
      return res && res.occasions
        ? res.occasions.map((o) => OccasionRemindersModelService.fromPayload(o))
        : [];
    });
  }

  /**
   * Get the suggestions
   */
  getSuggestions(): Promise<OccasionType[]> {
    return Promise.all([
      this.contentService.getContentSegments(),
      this.backend.get(null, '/v2/occasion_types')
    ]).then(([contentSegments, res]) => {
      const toExclude = contentSegments.reduce((acc, segment) => {
        acc.push(...segment.excluded_tags);
        return acc;
      }, []);

      const types = res
        ? res.data.map((d) => OccasionRemindersModelService.occasionReminderFromPayload(d))
        : [];

      return types.filter(
        (occType) => !toExclude.find((t) => (occType.tags || []).indexOf(t) > -1)
      );
    });
  }

  /**
   * Get backend occasion types
   */
  private getBackendOccasionTypes(): Promise<OccasionType[]> {
    return this.backend.get(null, '/v2/occasion_kind_suggestions').then((r) => {
      return r
        ? r.data.map((d) => OccasionRemindersModelService.occasionReminderFromKindPayload(d))
        : [];
    });
  }

  /**
   * Get the Occasion Types
   */
  getOccasionTypes(occasionTypes?: OccasionTypeDisplay[]): Promise<OccasionTypeDisplay[]> {
    return Promise.all([
      this.contentService.getContentSegments(),
      occasionTypes ? occasionTypes : this.getBackendOccasionTypes()
    ]).then(([contentSegments, types]) => {
      const toExclude = contentSegments.reduce((acc, segment) => {
        acc.push(...segment.excluded_tags);
        return acc;
      }, []);

      return types.filter(
        (occType) => !toExclude.find((t) => (occType.tags || []).indexOf(t) > -1)
      );
    });
  }

  create(occasion: OccasionReminder): Promise<any> {
    this.analyticsService.track('occasions.create');
    const user = this.userService.getUser();
    return this.backend.post(
      user,
      '/v1/occasions',
      OccasionRemindersModelService.toPayload(occasion)
    );
  }

  update(occasion: OccasionReminder): Promise<any> {
    const user = this.userService.getUser();
    return this.backend.put(
      user,
      `/v1/occasions/${occasion.id}`,
      OccasionRemindersModelService.toPayload(occasion)
    );
  }

  delete(occasion: OccasionReminder): Promise<any> {
    const user = this.userService.getUser();
    return this.backend.delete(user, `/v1/occasions/${occasion.id}`);
  }
}
