
<div class="newsletter-signup">
  <h4 class="serif newsletter-signup__heading">Get 10% off your first order when you join our newsletter</h4>
  <form class="newsletter-signup__form" [formGroup]="form" #thisForm="ngForm" name="form" (ngSubmit)="signup()" [ngClass]="{'ng-submitted': thisForm.submitted}">
    <div class="newsletter-signup__form-inner">
      <div class="newsletter-signup__input">
        <input class="form-control" placeholder="Email address" id="email" type="email" name="email" formControlName="email"><a class="newsletter-signup__validation-icon" *ngIf="thisForm.submitted &amp;&amp; (form.controls.email.errors?.required || form.controls.email.errors?.pattern)" [popper]="newsletter_error_tooltip" [popperShowOnStart]="false" [popperTrigger]="popperTrigger" [popperHideOnClickOutside]="true" [popperHideOnScroll]="true" [popperPlacement]="popperPlacement" [popperApplyClass]="'popper__newsletter-select'" [popperApplyArrowClass]="'popper__arrow-right'"><img src="/assets/brand-icons/exclamation--red.svg"></a>
      </div>
      <bw-button buttonStyle="primary" size="large" buttonType="submit" bwTrackAs="newsletter.standard.signup">Sign me up</bw-button>
    </div>
    <!-- The popper needs to go outside the context for better positioning-->
    <popper-content #newsletter_error_tooltip><span class="u-font-small">
        <div class="tooltip-body" *ngIf="form.controls.email.errors?.required">This field is required</div>
        <div class="tooltip-body" *ngIf="form.controls.email.errors?.pattern">Please enter a valid email address</div></span></popper-content>
    <div class="d-flex">
      <p class="newsletter-signup__disclaimer-text">Enter your email address to receive special offers, exciting products and news from Bloom & Wild. You can view our <a href="/privacy-statement" target="_blank">privacy statement</a> and unsubscribe at any time.</p>
      <div class="newsletter-signup__disclaimer-tooltip"><img [popper]="newsletter_disclaimer_tooltip" [popperShowOnStart]="false" [popperTrigger]="popperTrigger" [popperHideOnClickOutside]="true" [popperHideOnScroll]="true" [popperPlacement]="popperPlacement" [popperApplyClass]="'popper__disclaimer-select'" [popperApplyArrowClass]="'popper__arrow-right'" src="/assets/brand-icons/questionmark.svg"></div>
      <popper-content #newsletter_disclaimer_tooltip><span class="u-font-small">
          <div class="tooltip-body">Discount excludes gift subscriptions, see our <a href="/terms-and-privacy">T&Cs</a> for full terms</div></span></popper-content>
    </div>
  </form>
  <!-- Success overlay-->
  <div class="newsletter-signup__success" *ngIf="success">
    <div class="newsletter-signup__success-container">
      <h3 class="newsletter-signup__success-heading serif"><img class="newsletter-signup__flurry-lines newsletter-signup__flurry-lines--before" src="/assets/brand-icons/dashes_three.svg">Welcome to the club<img class="newsletter-signup__flurry-lines newsletter-signup__flurry-lines--after" src="/assets/brand-icons/dashes_two.svg"></h3>
      <p class="newsletter-signup__success-content"><a class="bwLink strong" href="/send-flowers?discountCode={{promoCode}}">Click here</a> to use code <a class="bwLink strong" href="/send-flowers?discountCode={{promoCode}}">{{ promoCode }}</a> for {{'newsletter.discount.promo.pennies' | siteConfig | price}} off your next order. You will also receive an email with your code.</p>
    </div>
  </div>
</div>