import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { translations } from 'Environments/js-translations';
import { WindowRefService } from 'Shared/services/window.service';

@Injectable({
  providedIn: 'root'
})
export class FaqRedirectGuard implements CanActivate {
  window: any;
  constructor(private windowRef: WindowRefService) {
    this.window = this.windowRef.nativeWindow.location.href;
  }
  canActivate(): any {
    this.window = translations['js.routing.faqs'] || '/404';
    return true;
  }
}
