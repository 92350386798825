import { ValidationErrors } from '@angular/forms';
import { BwFormControl } from 'Shared/classes/bw-form';

export class ValidateUserDetails {
  /**
   * Simple full name validation
   * @param value
   * @returns
   */
  static fullNameContainsSpace(value: string | undefined): ValidationErrors | null {
    return (value || '').trim().match(/\S+\s\S+/) ? null : { fullNameRequired: true };
  }

  /**
   * Ensure full name
   * @param formGroup
   */
  static fullNameRequired(nameControl: BwFormControl<string>): ValidationErrors | null {
    return ValidateUserDetails.fullNameContainsSpace(nameControl.value);
  }
}
