import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { StateService } from 'Shared/services/state.service';
import { WindowRefService } from 'Shared/services/window.service';
import { ViewportDetectionService } from 'Shared/services/viewport-detection.service';
import { BehaviorSubject } from 'rxjs';

interface Link {
  title: string;
  href: string;
}

interface Tab {
  icon?: string;
  title: string;
  href: string;
  links: Link[];
}

/* bw:view-encapsulation */
@Component({
  selector: 'bw-help-center',
  templateUrl: './help-center.component.html'
})
export class HelpCenterComponent implements OnInit, OnDestroy {
  @Input() tabs: Tab[] = [];

  activeLink: any;
  activeTab: any;
  isLoading = false;
  viewportSizeIs$: BehaviorSubject<any> = this.viewportDetectionService.viewportSizeIs$;
  showContent: boolean = false; // Used to re-generate the DOM for the content-spot to re-init
  hasContent: boolean = true; // Used to show content if its available, if not, show a 404 message

  observable;

  constructor(
    private stateService: StateService,
    private viewportDetectionService: ViewportDetectionService,
    private windowRef: WindowRefService
  ) {}

  /**
   * On loading changing
   * @param bool
   */
  onLoadingChanged(bool: boolean): void {
    setTimeout(() => {
      this.isLoading = bool;
    }, 500);
  }

  /**
   * Set the activated tab based on a URL match
   */
  setActivatedTab(shouldScroll?: boolean): void {
    const currentUrl = this.stateService.getCurrent().url;
    this.activeTab = undefined;
    this.activeLink = undefined;
    const isDesktop = this.viewportSizeIs$.getValue()?.desktop;

    this.tabs = this.tabs.map((tab) => {
      tab.links = tab.links.map((link) => {
        if (currentUrl.indexOf(link.href) > -1) {
          this.activeLink = link;
        }
        return link;
      });
      if (currentUrl.indexOf(tab.href) > -1) {
        this.activeTab = tab;
      }
      return tab;
    });

    // If we haven't found a matching one, let's set the first tab and the first link as active
    if (!this.activeTab) {
      this.activeTab = this.tabs[0];
    }

    // Bit of a hack here to scroll when on mobile
    if (shouldScroll && this.activeLink && !isDesktop) {
      setTimeout(() => {
        const elem = document.querySelector(`.link--active`);
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      }, 5);
    }

    if (!this.activeLink && this.activeTab && isDesktop) {
      this.activeLink = this.activeTab.links[0];
    }
  }

  /**
   * If we don't have any help content
   */
  noHelpContent(): void {
    this.hasContent = false;
    this.isLoading = false;
  }

  /**
   * On content loading
   */
  onContentLoaded(): void {
    this.hasContent = true;
    this.isLoading = false;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    if (this.observable) {
      this.observable.unsubscribe();
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    // We need to convert JSON which is passed in, to something useful
    let tabs: Tab[] = [];
    try {
      tabs = JSON.parse(this.tabs as any);
    } catch (e) {}

    this.tabs = tabs;

    this.observable = this.stateService.onSuccess$.subscribe((event) => {
      if (event.to) {
        this.setActivatedTab(true);

        // Reload the content spot, and presume content
        this.showContent = false;

        // Presume content
        this.hasContent = true;
        this.isLoading = this.activeLink;
        setTimeout(() => {
          this.showContent = true;
        }, 1);
      }
    });
    this.setActivatedTab(false);
    this.showContent = true;
  }
}
