import { Component } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { StateService } from 'Shared/services/state.service';
import {
  ModalComponent,
  ModalResponse,
  ModalResponseSubject,
  ModalService
} from 'Shared/services/modal.service';
import { LocationService } from 'Shared/services/location.service';
import { PurchaseService, Purchase } from 'Checkout/services/purchase.service';
import { User } from 'Shared/classes/user';
import { UserService } from 'Shared/services/user.service';

type ModalResolveData = {};
type ModalRejectData = {};

/* bw:view-encapsulation */
@Component({
  selector: 'bw-basket-modal',
  templateUrl: './basket-modal.component.html'
})
export class BasketModalComponent extends ModalComponent<ModalResolveData, ModalRejectData> {
  reloadOnClose: boolean = false;
  purchase$: BehaviorSubject<Purchase>;
  user: User;

  constructor(
    private state: StateService,
    private locationService: LocationService,
    private purchaseService: PurchaseService,
    private userService: UserService,
  ) {
    super();
    this.user = this.userService.getUser();
    this.purchase$ = this.purchaseService.purchase$;
  }

  modalOutAnimationDidComplete(): void {
    if (this.reloadOnClose) {
      this.state.reload();
    }
  }

  onBasketEmpty(): void {
    this.reloadOnClose = true;
    this.onCancel();
  }

  checkout(): void {
    this.state.go('checkout.payment');
  }

  onSuccess(): void {}

  onCancel(): void {
    super.closeAsReject({});
  }
}
