import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { StateName, StateService } from 'Shared/services/state.service';
import { User, UserService } from 'Shared/services/user.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { AuthModalComponent } from 'Shared/components/modals/auth-modal/auth-modal.component';
import { t } from 'Shared/utils/translations';
import { ModalService } from 'Shared/services/modal.service';

@Injectable()
export class HasAppToWebLoggedIn implements CanActivate {
  constructor(
    private userService: UserService,
    public experimentService: ExperimentsService,
    private stateService: StateService,
    private modalService: ModalService
  ) {}

  redirectSuccessURL: string;
  /**
   * Can activate
   */
  canActivate(): Promise<any> {
    const user = this.userService.getUser();
    const token = this.stateService.getCurrent().queryParams['token'] ?? undefined;
    const redirectUrlFail = this.stateService.getCurrent().queryParams['redirect'] ?? undefined;
    const orderId = this.stateService.getCurrent().queryParams['orderId'] ?? undefined;

    if (user.loggedIn) {
      return Promise.resolve(true);
    }
    return this.userService
      .authenticateAppToWeb(token)
      .then((userResponse: User): boolean => {
        this.redirectSuccessURL = userResponse.redirectDestination;
        return true;
      })
      .then((): Promise<User> => this.userService.refreshUser())
      .then((): Promise<any> => this.stateService.goToUrl(this.redirectSuccessURL))
      .catch((): boolean => {
        if (redirectUrlFail) {
          this.stateService.go(redirectUrlFail as StateName, { orderId: orderId });
        } else {
          this.stateService.go('homepage');
        }
        return false;
      });
  }
}
