import { Injectable } from '@angular/core';
import {
  APIJSONRequest,
  APISerialisedJSONResponse,
  BackendService
} from 'Shared/services/backend.service';
import { UserService } from 'Shared/services/user.service';
import { Purchase } from 'Shared/classes/purchase';
import { Invoice } from 'Shared/classes/invoice';

@Injectable({
  providedIn: 'root'
})
export class InvoiceModelService {
  constructor(private backend: BackendService, private userService: UserService) {}

  /**
   * To payload
   * @param purchaseId
   */
  public static toPayload(purchaseId: number): APIJSONRequest<'/v2/invoices'> {
    return {
      data: {
        type: 'invoices',
        attributes: {
          purchase_id: purchaseId
        }
      }
    };
  }

  /**
   * From payload
   * @param res
   */
  public static fromPayload(res: APISerialisedJSONResponse<'/v2/invoices'>): Invoice {
    return {
      number: res.data.attributes.number,
      url: res.data.attributes.url
    };
  }

  /**
   * Generate an invoice, and return the URL
   * @param purchaseId
   */
  public generate(purchase: Purchase): Promise<Invoice> {
    const user = this.userService.getUser();
    return this.backend
      .post(user, '/v2/invoices', InvoiceModelService.toPayload(purchase.id), {
        requestIsJsonApi: true
      })
      .then((res) => InvoiceModelService.fromPayload(res));
  }
}
