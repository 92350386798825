import { Pipe, PipeTransform } from '@angular/core';
import { Addon } from 'Shared/classes/addon';

@Pipe({
  name: 'addonType'
})
export class AddonTypePipe implements PipeTransform {
  constructor() {}

  /**
   * Only show addons on allowed type or exclude addons of types
   * @param addons
   * @param types
   * @param exclude
   */
  transform(addons: Addon[], types: string[], exclude?: boolean): Addon[] {
    if (exclude) {
      return (addons || []).filter(a => types.indexOf(a.type) < 0);
    }

    return (addons || []).filter(a => types.indexOf(a.type) > -1);
  }
}
