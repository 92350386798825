import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class Highlight implements PipeTransform {
  prefix: string = '<strong>';
  suffix: string = '</strong>';

  transform(text: string, range: string): String {
    if (!range) {
      return text;
    }

    let textString = text;
    const rangeArray = range
      .split(';')[0]
      .split(',')
      .reverse();

    rangeArray.forEach(rangeItem => {
      const rangeStart = +rangeItem.split('-')[0];
      const rangeEnd = +rangeItem.split('-')[1];

      textString = `${textString.substring(0, rangeStart)}${this.prefix}${textString.substring(
        rangeStart,
        rangeEnd
      )}${this.suffix}${textString.substring(rangeEnd, textString.length)}`;
      return textString;
    });

    return textString;
  }
}
