
<ng-container *ngIf="!showSofterGDPRMessage">
  <div class="user-details-consent__short" *ngIf="showOptionsOnly">
    <p class="label-text">Stay up to date with special offers, exciting products and news from Bloom and Wild.</p>
    <p>You can <a class="bwLink under" href="/privacy-statement" target="_blank">view our privacy statement</a> or unsubscribe at any time. <a class="bwLink under" (click)="toggleOptInOptions()"><span *ngIf="!optionsShown">See options</span> <span *ngIf="optionsShown">Close</span></a></p>
    <div class="user-details-consent__form-group" [hidden]="!optionsShown">
      <p><strong>Please tick what you would like to see from us!</strong></p>
      <bw-form-radio-input [bwFormControl]="bwFormControl" [options]="options" [hideDescriptions]="true" [size]="'small'"></bw-form-radio-input>
    </div>
  </div>
  <ng-container *ngIf="!showOptionsOnly">
    <!-- Full GDPR consent-->
    <div class="user-details-consent" *ngIf="email?.preference === -1">
      <p>Stay up to date with special offers, exciting products and news from Bloom and Wild. You can <a class="bwLink under" href="/privacy-statement" target="_blank">view our privacy statement</a> or unsubscribe at any time. <a class="bwLink under" (click)="toggleOptInOptions()"><span *ngIf="!optionsShown">See options</span> <span *ngIf="optionsShown">Close</span></a></p>
      <div class="user-details-consent__form-group" [hidden]="!optionsShown">
        <ui-box box_space="none" box_space_top="near" box_space_bottom="tight">
          <p><strong>Please tick what you would like to see from us!</strong></p>
        </ui-box>
        <bw-form-radio-input [bwFormControl]="bwFormControl" [options]="options" [hideDescriptions]="true" [size]="'small'"></bw-form-radio-input>
      </div>
    </div>
    <form [formGroup]="formGroup">
      <div class="user-details-consent user-details-consent--simple" *ngIf="formGroup.controls?.simpleConsent &amp;&amp; email?.preference === 0 &amp;&amp; !AutoOptInToRewards" [ngClass]="{'is-checked': formGroup.controls.simpleConsent.value}">
        <div class="user-details-consent__form-group">
          <bw-form-checkbox-input [bwFormControl]="formGroup.controls.simpleConsent" [size]="'large'" [align]="'right'"><span role="label">
              <div class="label-text" *ngIf="!loggedIn">Stay up to date with special offers and exciting product news when you sign up to our newsletter. You can <a href="/privacy-statement" target="_blank">view our privacy statement here</a> and unsubscribe at anytime.</div>
              <div class="label-text" *ngIf="loggedIn">Stay up to date with special offers and exciting product news. You can <a href="/privacy-statement" target="_blank">view our privacy statement</a> or unsubscribe at any time.</div></span></bw-form-checkbox-input>
        </div>
      </div>
      <ui-form-group *ngIf="AutoOptInToRewards &amp;&amp; email?.preference &lt; 1" group_title="Marketing Form" [group_title_hidden]="true">
        <ui-checkbox *ngIf="!loggedIn" formControlName="simpleConsent" checkbox_type="secondary" id="marketing" label="Keep me updated via email with Bloom &amp; Wild Rewards, my birthday treat and VIP perks, as well as special offers and news about our products."><span>You can view our <a class="text-link--paragraph" href="/privacy-statement" target="_blank">privacy statement here</a> (to learn how we handle your personal data) and unsubscribe at any time.</span></ui-checkbox>
        <ui-checkbox *ngIf="loggedIn" formControlName="simpleConsent" checkbox_type="secondary" id="marketing" label="Keep me updated via email with Bloom &amp; Wild Rewards, my birthday treat and VIP perks, as well as special offers and news about our products."><span>Read our <a class="text-link--paragraph" href="/privacy-statement" target="_blank">privacy statement here</a> and unsubscribe at any time.</span></ui-checkbox>
      </ui-form-group>
    </form>
  </ng-container>
</ng-container>
<div class="user-details-consent" *ngIf="showSofterGDPRMessage">
  <ng-container *ngIf="email?.preference !== 1 &amp;&amp; email?.preference !== 2 &amp;&amp; email?.preference !== 3">
    <p>Nach dem Kauf senden wir Ihnen regelmäßig ausgesuchte Angebote zu ähnlichen Produkten aus unserer Kollektion. Sie können dem Erhalt von Werbe-Emails jederzeit widersprechen.</p>
  </ng-container>
</div>