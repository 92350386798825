import { Injectable } from '@angular/core';
import { BackendService } from 'Shared/services/backend.service';

@Injectable({
  providedIn: 'root'
})
export class SMSSignupModel {
  constructor(private backend: BackendService) {
  }

  sendSMS(number): Promise<any> {
    return this.backend.post(null, '/v1/sms', { number });
  }
}
