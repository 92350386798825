import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Location, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { knownExperiments as duringBuildExperiments } from 'Environments/known-experiments';
import { StateService } from 'Shared/services/state.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { ContentService } from 'Shared/services/content.service';
import { BackendService } from 'Shared/services/backend.service';
import { WindowRefService } from 'Shared/services/window.service';
import { CookieService, ICookieConsent } from 'Shared/services/third-parties/cookie.service';
import { LocalStorageService } from 'Shared/services/local-storage.service';
import { ContentSegment, SegmentService } from 'Shared/services/segment.service';
import { Product, ProductGridService } from 'Checkout/services/product-grid.service';
import { ConfigService } from 'Shared/services/config.service';
import { CountryService } from 'Shared/services/country.service';
import { CheckoutService } from 'Checkout/services/checkout.service';
import { StateName } from 'Shared/services/state.service.typings';
import { LoadingSpinnerComponent } from 'Shared/components/loading-spinner/loading-spinner.component';
import {
  KnownExperiment,
  KnownSegment,
  StagingHelperFormControls,
  STAGING_SITES,
  StagingSite,
  StagingHelperSections,
  ProductType
} from './staging-helper';
import {
  OasysButtonGroupModule,
  OasysButtonModule,
  OasysChipModule,
  OasysDividerModule,
  OasysFormGroupModule,
  OasysHeadingModule,
  OasysIconModule,
  OasysLayoutModule,
  OasysPillGroupModule,
  OasysPillModule,
  OasysTextInputModule
} from 'oasys-lib';
import { takeWhile, timer } from 'rxjs';
import { RangeDiscoveryApiService } from 'Shared/services/range-discovery-api.service';

@Component({
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgTemplateOutlet,
    NgClass,
    ReactiveFormsModule,
    LoadingSpinnerComponent,
    OasysLayoutModule,
    OasysButtonModule,
    OasysButtonGroupModule,
    OasysFormGroupModule,
    OasysTextInputModule,
    OasysIconModule,
    OasysChipModule,
    OasysDividerModule,
    OasysHeadingModule,
    OasysPillGroupModule,
    OasysPillModule
  ],
  selector: 'bw-staging-helper',
  templateUrl: './staging-helper.component.html',
  styleUrl: './staging-helper.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class StagingHelperComponent implements OnInit {
  @ViewChild('stagingHelperTrigger') triggerElement: ElementRef<HTMLElement> | undefined;
  @ViewChild('stagingHelperWidget') widgetElement: ElementRef<HTMLElement> | undefined;

  public collapsed: boolean = true;
  // default state for all sections aka closed
  public showSection: StagingHelperSections = {
    segments: false,
    experiments: false,
    pbsDetails: false,
    rangeDiscoveryPbsDetails: false,
    contentfulEditor: false,
    checkoutShortcut: false,
    exampleSkus: false,
    confirmationShortcut: false,
    stagingSites: false,
    branchSites: false
  };
  public totalExperimentsIn: number = 0;
  public knownExperiments: KnownExperiment[] = [];
  public currentSegments: ContentSegment[] = [];
  public knownSegments: KnownSegment[] = [];
  public form: FormGroup<StagingHelperFormControls>;
  public defaultBackendURL: string;
  public defaultRangeDiscoveryAPIUrl: string;
  public currentAPIUrl: string;
  public currentRangeDiscoveryAPIUrl: string;
  public skuIdSubmitted: boolean = false;
  public loading: boolean = false;

  // by default, all true
  private userConsent: ICookieConsent = {
    marketing: true,
    statistics: true,
    preferences: true,
    necessary: true
  };
  // Add here any other subdomain that is added to the cookiebot dashboard as alias
  // Remove before merge to staging
  private cookiebotDomainAliases: string[] = [
    'localhost',
    'www.bloomdev.org',
    'fr.bloomdev.org',
    'de.bloomdev.org',
    'bloomon-uk.bloomdev.org',
    'bloomon-de.bloomdev.org',
    'bloomon-dk.bloomdev.org',
    'bloomon-nl.bloomdev.org',
    'bloomon-be.bloomdev.org'
  ];
  private products: Product[] = [];

  constructor(
    private experimentsService: ExperimentsService,
    private stateService: StateService,
    private contentService: ContentService,
    private backend: BackendService,
    private windowRef: WindowRefService,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private location: Location,
    private segmentService: SegmentService,
    private productGridService: ProductGridService,
    private configService: ConfigService,
    private countryService: CountryService,
    private checkoutService: CheckoutService,
    private elementRef: ElementRef<HTMLElement>,
    private rangeDiscoveryApiService: RangeDiscoveryApiService
  ) {}

  /**
   * @description Get the staging sites
   * @returns {StagingSite[]}
   */
  get stagingSites(): StagingSite[] {
    return STAGING_SITES;
  }

  /**
   * @description Get the window object
   * @returns {Window}
   */
  private get window(): Window {
    return this.windowRef.nativeWindow as Window;
  }

  @HostListener('document:click', ['$event'])
  checkIfClickOutside(event: MouseEvent): void {
    if (event.target['id'] === 'stagingHelperTrigger') {
      return;
    }

    if (!this.elementRef.nativeElement.contains(event.target as Node)) {
      this.collapsed = true;
    }
  }

  /**
   * @description Initialize the component
   */
  ngOnInit(): void {
    // Fetch the known experiments from the environment
    this.knownExperiments = duringBuildExperiments.map((data): KnownExperiment => new KnownExperiment(data.name, data.variants, 0));
    // Fake the cookie consent for preview branches, except when cypress is running
    // Function live-server causes cypress to get stuck in an infinite loop if ran
    if (typeof this.window?.['Cypress'] === 'undefined') {
      this.fakeCookieConsent();
    }

    this.currentAPIUrl = this.backend.server;
    this.currentRangeDiscoveryAPIUrl = this.rangeDiscoveryApiService.rangeDiscoveryServer;
    const { preview, analyticsDebug } = this.stateService.getCurrent().params;
    this.collapsed = !preview && !analyticsDebug;
    // Experiments
    this.experimentsService.experimentsObj$.subscribe((experimentObj): void => {
      this.totalExperimentsIn = 0;
      Object.values(experimentObj).forEach((exp): void => {
        const known = this.knownExperiments.find((e): boolean => exp.name === e.name);
        if (known) {
          known.variant = exp.variant;
          return;
        }
        const knownExperiment = new KnownExperiment(
          exp.name,
          [0, 1, 2, 3], // We are guessing the number here
          exp.variant
        );
        this.knownExperiments.push(knownExperiment);
      });
      this.totalExperimentsIn = this.knownExperiments.filter((e): boolean => e.variant > 0).length;
    });
    // Content Segments
    this.contentService.contentSegmentsDidChange$.subscribe((segments): void => {
      this.currentSegments = segments;
    });
    // Build the form
    this.buildForm();

    //
  }

  /**
   * @description Toggle the collapsed state
   */
  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;

    // Check if the page is a contentful page when opening the helper
    if (!this.collapsed) {
      Object.keys(this.showSection).forEach((key): boolean => (this.showSection[key] = false));
      this.checkContentfulPage();
    }
  }

  /**
   * @description Toggle the section
   * @param {keyof StagingHelperSections} section
   * @returns {Promise<void>}
   */
  async toggleSection(section: keyof StagingHelperSections): Promise<void> {
    if (this.showSection[section]) {
      Object.keys(this.showSection).forEach((key): boolean => (this.showSection[key] = false));
    } else {
      Object.keys(this.showSection).forEach((key): boolean => (this.showSection[key] = false));
      this.showSection[section] = !this.showSection[section];
    }

    try {
      this.loading = true;
      this.checkContentfulPage();

      // Refresh the segment data if the segments section is opened
      if (section === 'segments' && this.showSection[section]) {
        await this.updateSegmentsList();
      }

      if (section === 'checkoutShortcut' && this.showSection[section]) {
        await this.getAvailableProducts();
      }

      if (section === 'exampleSkus') {
        this.showSection.checkoutShortcut = true;
      }
    } catch (e: unknown) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  /**
   * @description Toggle the experiment
   * @param {KnownSegment} segment
   * @returns {Promise<void>}
   */
  async toggleSegment(segment: KnownSegment): Promise<void> {
    if (segment.isActive) {
      this.contentService.exitContentSegment(segment);
    } else {
      this.contentService.forceContentSegment(segment);
    }

    try {
      this.loading = true;
      await this.contentService.refreshSegments();
      this.stateService.reload();
      return this.refreshSegmentData();
    } catch (e: unknown) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  /**
   * Triggers the CookiebotOnConsentReady in window to be picked up by the cookiebot service
   */
  dispatchCookieConsentEvent(): void {
    const intervalTimer = timer(0, 200);
    intervalTimer.pipe(takeWhile(() => !this.cookieService.intialised.marketing)).subscribe((): void => {
      this.window.dispatchEvent(new Event('CookiebotOnConsentReady'));
    });
  }

  /**
   * @description Refresh the segment data
   * @returns {Promise<void>}
   */
  private async refreshSegmentData(): Promise<void> {
    try {
      this.loading = true;
      const segments = await this.segmentService.getAllSegments();
      this.knownSegments = segments.map(
        (segment): KnownSegment => ({
          ...segment,
          isActive: !!this.contentService.contentSegments().find((s): boolean => s.id === segment.id)
        })
      );
    } catch (e: unknown) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  /**
   * @description Basic toggle of the segments list
   * @returns {Promise<void>}
   */
  async updateSegmentsList(): Promise<void> {
    await this.refreshSegmentData();

    // Before rebuilding form array, clear the current one
    this.buildForm();

    // Rebuild the form array
    (this.knownSegments as KnownSegment[]).forEach((segment): void => {
      (this.form as FormGroup).addControl(`${segment.id}`, new FormControl(segment.isActive));
    });
  }

  /**
   * @description Refresh the page going into the experiment
   * @param {string} name
   * @param {number} variant
   */
  refreshWithExperiment(name: string, variant: number): void {
    const experiment = this.experimentsService.createExperiment({
      name,
      variant,
      allowOverride: false
    });
    this.experimentsService.addExperiment(experiment);
    this.stateService.reload();
  }

  /**
   * @description route to contentful page for editing
   */
  editInContentful(): void {
    const { spaceId, contentfulId } = this.getContentfulPage();

    let url = 'https://app.contentful.com/';

    if (spaceId !== undefined) {
      url += `spaces/${spaceId}`;
    }

    if (contentfulId !== undefined) {
      url += `/entries/${contentfulId}`;
    }

    this.window.open(url, '_blank');
  }

  /**
   * @description Set the API URL
   */
  setApiURL(): void {
    this.currentAPIUrl = this.form.get('newApiURL').value.trim();
    this.localStorageService.set('apiUrl', this.currentAPIUrl);
    this.window.location.replace(`/?apiUrl=${this.currentAPIUrl}`);
  }

  /**
   * @description Clear the API URL
   */
  clearAPIURL(): void {
    this.localStorageService.set('apiUrl');
    this.currentAPIUrl = null;
    this.window.location.replace('/');
  }

  /**
   * @description Set the Range Discovery API URL
   */
  setRangeDiscoveryAPIUrl(): void {
    this.currentRangeDiscoveryAPIUrl = this.form.get('newRangeDiscoveryAPIUrl').value.trim();
    this.localStorageService.set('rangeDiscoveryAPIUrl', this.currentRangeDiscoveryAPIUrl);
    this.window.location.replace(`/?rangeDiscoveryAPIUrl=${this.currentRangeDiscoveryAPIUrl}`);
  }

  /**
   * @description Clear the Range Discovery API URL
   */
  clearRangeDiscoveryAPIUrl(): void {
    this.localStorageService.set('rangeDiscoveryAPIUrl');
    this.currentRangeDiscoveryAPIUrl = null;
    this.window.location.replace('/');
  }

  /**
   * @description Enable the debug mode
   */
  enableDebug(): void {
    const { name, params } = this.stateService.getCurrent();
    const newParams = typeof params === 'object' && params !== null ? params : {};
    const url = this.stateService.href(name, { ...newParams, analyticsDebug: true });

    this.location.go(url);
    this.toggleCollapsed();
    this.window.location.reload();
  }

  /**
   * @description refresh page with the current state
   * @param additionalParams
   */
  refresh(): void {
    const current = this.stateService.getCurrent();

    const params = this.stateService.getCurrent().params;
    this.stateService.go(current, params, { reload: true });
  }

  /**
   * @description Get the feature branch URL
   * @param {string} url
   * @param {string} branch
   * @returns {string}
   */
  getFeatureBranchURL(branch: string, url: string = ''): string | undefined {
    const prefix = branch.split('-')[0];

    if (!['spike', 'feature', 'fix'].some((str): boolean => str.includes(prefix))) {
      return undefined;
    }

    return url.replace('fe-staging', `fe-${branch}`);
  }

  /**
   * @description Check if the branch is a feature branch
   * @param {string} branch
   * @returns {boolean}
   */
  checkIfFeatureBranch(branch: string): boolean {
    const prefix = branch.split('-')[0];

    return ['spike', 'feature', 'fix'].some((str): boolean => str.includes(prefix));
  }

  /**
   * @description Go to the confirmation page
   * @returns {boolean}
   */
  goToConfirmationPage(): void {
    const purchasedId = this.form.get('purchaseId').value;

    if (!purchasedId) {
      return;
    }

    // Reset form values
    this.form.get('purchaseId').reset();

    return this.stateService.go('checkout.confirmation', { purchasedId, purchasedToken: '' });
  }

  /**
   * @description Clear the confirmation form
   */
  clearConfirmationForm(): void {
    this.form.get('purchaseId').setValue('');
  }

  /**
   * @description Check if the confirmation is available
   * @returns {boolean}
   */
  checkIfConfirmationIsAvailable(): boolean {
    return !!this.form.get('purchaseId').value;
  }

  /**
   * @description Check if the SKU ID is valid
   * @returns {boolean}
   */
  checkSkuIdIfValid(): boolean {
    const id = this.form.get('skuId').value;
    return id && this.products.some((product): boolean => product.id === +id);
  }

  /**
   * @description Go to the checkout page
   */
  goToCheckout(): void {
    this.skuIdSubmitted = true;

    if (!this.checkSkuIdIfValid()) {
      return;
    }

    try {
      this.loading = true;

      const product = this.products.find((p): boolean => p.id === +this.form.get('skuId').value);
      const stateName = this.checkoutService.getCheckoutStartingPoint(product) as StateName;
      const params = this.stateService.getCurrent().params;
      this.stateService.go(stateName, {
        data: {
          product,
          params,
          addons: undefined,
          addonSlug: params?.['addon'] ?? undefined,
          giftingOptionsEnabled: stateName === 'checkout.giftOptions'
        },
        date: undefined
      } as never);

      this.toggleCollapsed();
    } catch (e: unknown) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  /**
   * @description Clear the checkout form
   */
  clearCheckoutForm(): void {
    this.form.get('skuId').setValue('');
    this.skuIdSubmitted = false;
  }

  /**
   * @description Get the product based on the tag
   * @param {ProductType} type
   * @returns {Product}
   */
  getProduct(type: ProductType): Product {
    if (type === 'subscription') {
      return this.products.find((product): boolean => product.subscriptionOnly);
    }

    return this.products.find((product): boolean => !!product.tags.find((tag): boolean => tag === type));
  }

  /**
   * @description Get the available products
   * @returns {Promise<void>}
   */
  private async getAvailableProducts(): Promise<void> {
    try {
      this.loading = true;

      const serverTime = this.configService.getConfig().serverTime;
      const { products } = await this.productGridService.getProducts({
        serverTime,
        listType: { type: 'base' },
        site: this.countryService.forSite,
        shippingTo: this.countryService.forShipping,
        orderIndex: 0
      });
      this.products = products;
    } catch (e: unknown) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  /**
   * @description Get the contentful page attributes from dom
   * @returns {{ spaceId: string; contentfulId: string }}
   */
  private getContentfulPage(): { spaceId: string; contentfulId: string } {
    const contentfulId = this.window.document.querySelector('[contentful-id]')?.getAttribute('contentful-id');
    const spaceId = this.window.document.querySelector('[contentful-space-id]')?.getAttribute('contentful-space-id');

    return { spaceId, contentfulId };
  }

  /**
   * @description Builds the fake Cookiebot object in window
   * Sets the fake cookies
   * Triggers the CookiebotOnConsentReady to be picked up by the cookiebot service
   */
  private fakeCookieConsent(): void {
    const isPreviewBranch = this.cookiebotDomainAliases.indexOf(this.window.location.hostname) < 0;

    if (!isPreviewBranch) {
      return;
    }

    // Cookiebot will load in window, but will do nothing if the preview branch is not added to the dashboard
    this.window['Cookiebot'] = {};
    this.window['Cookiebot'].runScripts = (): void => this.runThirdPartyInlineScripts();
    this.window['Cookiebot'].renew = (): void =>
      console.error(
        'Not available for this preview branch',
        'To enable the dialog, add this branch to the cookiebot dashboard and update this.cookiebotDomainAliases'
      );
    this.window['Cookiebot']['consent'] = this.userConsent;

    this.setConsentCookie(this.userConsent);
    this.cookieService.cookieConsent$.next(this.userConsent);
    this.cookieService.initExistingConsent();
  }

  /**
   * @description Sets the consent cookie according to the user preferences
   * Similar to the way cookiebot is building the cookie (except no domain, path ...)
   * Can be used in a later development to toggle a custom consent
   */
  private setConsentCookie(userConsent: ICookieConsent): void {
    const cookieParts = [];
    Object.entries(userConsent).forEach(([key, value]): void => {
      cookieParts.push(`${key}:${encodeURIComponent(value)}`);
    });
    const newConsentCookie = `CookieConsent={${cookieParts.join(encodeURIComponent(','))}}`;
    this.window.document.cookie = newConsentCookie;
  }

  /**
   * @description Replacement for cookiebot's run scripts function
   */
  private runThirdPartyInlineScripts(): void {
    const cookieConsentScripts = this.window.document.querySelectorAll('[type="text/plain"]');
    const body = this.window.document.querySelector('body');
    cookieConsentScripts.forEach((s): void => {
      const consent = s.getAttribute('data-cookieconsent');
      if (this.userConsent[consent]) {
        const script = s.cloneNode(true) as HTMLScriptElement;
        const parent = s.parentElement;
        parent.removeChild(s);
        script.setAttribute('type', 'text/javascript');
        script.defer = false;
        body.appendChild(script);
      }
    });
  }

  /**
   * @description Build form
   */
  private buildForm(): void {
    this.defaultBackendURL = this.backend.defaultBackendUrl();
    this.defaultRangeDiscoveryAPIUrl = this.rangeDiscoveryApiService.defaultRangeDiscoveryAPIUrl();
    this.form = new FormGroup({
      newApiURL: new FormControl(this.currentAPIUrl ?? ''),
      newRangeDiscoveryAPIUrl: new FormControl(this.currentRangeDiscoveryAPIUrl ?? ''),
      purchaseId: new FormControl(''),
      skuId: new FormControl('')
    });
  }

  /**
   * @description Check if the page is a contentful page
   */
  private checkContentfulPage(): void {
    const { spaceId } = this.getContentfulPage();
    this.showSection.contentfulEditor = !!spaceId;
  }
}
