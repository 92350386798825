import { Injectable } from '@angular/core';
import { UserLookupModelService } from 'Shared/models/user-lookup-model.service';
import { UserLookup } from 'Shared/classes/user-lookup';
export { UserLookup } from 'Shared/classes/user-lookup';
@Injectable({
  providedIn: 'root'
})
export class UserLookupService {
  constructor(private userLookupModelService: UserLookupModelService) {}

  /**
   * Get user by email
   * @param email
   * @returns {string}
   */
  checkUserByEmail(email: string): Promise<UserLookup> {
    return this.userLookupModelService.checkUserByEmail(email);
  }
}
