import { Component, OnInit } from '@angular/core';
import { StateService } from 'Shared/services/state.service';
import { LocationService } from 'Shared/services/location.service';
import { WindowRefService } from 'Shared/services/window.service';

@Component({
  selector: 'bw-branch-redirector',
  templateUrl: './branch-redirector.component.html',
  styleUrls: ['./branch-redirector.component.scss']
})
export class BranchRedirectorComponent implements OnInit {
  constructor(
    private stateService: StateService,
    private locationService: LocationService,
    private windowRef: WindowRefService
  ) {}

  /**
   * Redirect to the correct state
   */
  ngOnInit(): void {
    const params = this.locationService.getCurrentParams() as any;
    let path = params.forward;

    const querystring = [];
    Object.keys(params).forEach(key => {
      if (key !== '#') {
        path = path.replace(`:${key}`, params[key]);
        querystring.push(`${key}=${params[key]}`);
      }
    });

    path = path.substr(-1) === '/' ? path.slice(0, -1) : path; // if last character is / remove it

    path = `${path}?${querystring.join('&')}`;

    const compare = this.windowRef.nativeWindow.document.createElement('a');
    compare.setAttribute('href', path);

    window.location.href = compare.href;
  }
}
