import { Injectable } from '@angular/core';
import { ChargesModelService } from 'Shared/models/charges-model.service';
import { Order, SubscriptionPrepaymentCharge, SubscriptionPrepaymentOption } from 'Shared/classes/order';
import { BankRedirectTypes, Charge, ChargeState } from 'Shared/classes/charges';

@Injectable({
  providedIn: 'root'
})
export class ChargesService {
  constructor(private chargesModelService: ChargesModelService) {}

  /**
   * Create a micro payment charge
   * @param {Order} order
   * @param {BankRedirectTypes} type
   * @returns {Charge}
   */
  create(order: Order, type: BankRedirectTypes): Promise<Charge> {
    return this.chargesModelService.create(order, type);
  }

  /**
   * Get a micro payment charge
   * @param {number} id
   * @returns {ChargeState}
   */
  get(id: number): Promise<ChargeState> {
    return this.chargesModelService.get(id);
  }

  /**
   * Preview charge call for a subscrition prepay/charge package
   * @param order
   * @param prepaymentOption
   * @returns
   */
  preview(order: Order, prepaymentOption: SubscriptionPrepaymentOption): Promise<SubscriptionPrepaymentCharge> {
    return this.chargesModelService.preview(order, prepaymentOption);
  }

  /**
   * Prepay a charge for a subscrition prepay/charge package
   * @param charge
   * @returns
   */
  prepay(charge: SubscriptionPrepaymentCharge): Promise<SubscriptionPrepaymentCharge> {
    return this.chargesModelService.prepay(charge);
  }
}
