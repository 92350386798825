import { Injectable } from '@angular/core';
import { LocalStorageService } from 'Shared/services/local-storage.service';
import { ExperimentsService } from 'Shared/services/experiments.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureBannerDisplayService {
  featureBannersStoragePrefix = 'featureBanner';
  featureBanners: { [key: string]: boolean } = {};

  constructor(
    private localStorage: LocalStorageService,
    private experimentService: ExperimentsService
  ) {}

  getStoredItemIfExists(featureName: string): boolean {
    const item = this.localStorage.get(`${this.featureBannersStoragePrefix}.${featureName}`);
    return item === undefined ? true : false;
  }

  visible(featureName: string): boolean {
    return this.featureBanners[featureName] !== undefined
      ? this.featureBanners[featureName]
      : this.getStoredItemIfExists(featureName);
  }

  show(featureName: string): void {
    this.featureBanners[featureName] = true;
    this.localStorage.set(`${this.featureBannersStoragePrefix}.${featureName}`, null);
  }

  hide(featureName: string): void {
    this.featureBanners[featureName] = false;
    this.localStorage.set(`${this.featureBannersStoragePrefix}.${featureName}`, new Date());
  }

  register(featureName: string): void {
    this.featureBanners[featureName] = this.getStoredItemIfExists(featureName);
  }
}
