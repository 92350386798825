
<ui-box class="apps-banner hidden-laptop hidden-desktop" *ngIf="deviceOs === 'ios' || deviceOs === 'android'" box_space_bottom="expanded">
  <ui-heading heading_type="functional-primary" heading_title="Thoughtful gifts at your fingertips" heading_subtitle="Our app makes it even easier to care wildly. And it's got lots of handy features too."></ui-heading>
  <ui-box class="ui-prose" box_space="none" box_space_top="near">
    <ul>
      <li>✔ Personalise your card with a photo</li>
      <li>✔ Save 3 occasions for £5 off</li>
      <li>✔ Track your gift</li>
      <li>✔ Never lose an address</li>
    </ul>
    <ui-stack stack_gap="tight">
      <p>Plus get <span class="text-body--emphasis">20% off</span> your first app order.</p><a href="https://bloomandwild.onelink.me/octp/mnbn5p4k" target="_blank"><img [src]="imageSrc" loading="lazy" [alt]="imageAlt"></a>
    </ui-stack>
  </ui-box>
</ui-box>