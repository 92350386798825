import { Directive, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { ConfigService } from 'Shared/services/config.service';
import { CountryService } from 'Shared/services/country.service';
import { StateService } from 'Shared/services/state.service';

@Directive({
  selector: '[tvDiscountVisibility]'
})
export class tvDiscountDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private configservice: ConfigService,
    private countryService: CountryService,
    private stateService: StateService
  ) {}

  tvBannerVisible(): boolean {
    const config = this.configservice.getConfig();
    const tvDiscountConfiguration = config?.tv_discount_configuration || [];
    const countryId = this.countryService.forShipping.id;
    const urlParams = this.stateService.getInitial().params;

    // The check if the user is new is done in contentful in case this is used on the homepage

    // Check if the country is in the configuration
    const configForCountry = tvDiscountConfiguration.find((c) => c.country_id === countryId);
    if (!configForCountry) {
      return true;
    }

    // Check if the url contains a utm source, if the value of the utm_source is in the configuration the TV banner is not displayed
    if (urlParams.utm_source && configForCountry.excludeIfUtmSource.indexOf(urlParams.utm_source) > -1) {
      return false;
    }

    // Check if the url contains a utm medium, if the value of the utm_medium is in the configuration the TV banner is not displayed
    if (urlParams.utm_medium && configForCountry.excludeIfUtmSource.indexOf(urlParams.utm_medium) > -1) {
      return false;
    }

    // Check if the url contains a gclid, if there is any gclid in the configuration the TV banner is not displayed
    if (urlParams.gclid && configForCountry.excludeIfQueryDefined.indexOf('gclid') > -1) {
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    this.tvBannerVisible() ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
  }
}
