import {
  Component,
  Input,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { ILazyModalsList } from 'Project/modals/interfaces/lazy-modals-list';
import { ToastrService } from 'Project/toastr/services/toastr.service';
import { ModalService } from 'Shared/services/modal.service';
import { t } from 'Shared/utils/translations';
import { ModalRejectData } from 'Project/modals/hc-quality-self-serve/hc-quality-self-serve.component';
import { StateService } from 'Shared/services/state.service';
import { ConfigService } from 'Shared/services/config.service';

/**
 * Without a template, this is basically a diretive and will run for all ui-buttons on the page
 * We can't use directives in the dynamic content viewer
 ************************************************************
 * Directive to be use to open modals from any FE project
 * Example:
 *      ui-button(
 *          button_type="primary"
 *          modal_to_open="HcQualitySelfServeComponent"
 *          modal-tracking-key="HcDeliverySelfServetraking"
 *      )
 *        span(#buttontext) Button To Open Modal with a directive
 *
 */
@Component({
  selector: 'ui-button[modal_to_open]',
  template: '<ng-content></ng-content>'
})
export class ContentfulOpenModalComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Output('modal_clicked_action') clicked: EventEmitter<unknown> = new EventEmitter();

  modalToOpen: ILazyModalsList['name'];
  modalTrackingKey: string;
  modalAttributes: string[];

  attributeWidth: number;
  type: string;
  density: number = this.windowRef.nativeWindow.devicePixelRatio;

  /**
   * Constructor
   * @param element
   * @param windowRef
   * @param modalService
   * @param toastr
   */
  constructor(
    private elem: ElementRef,
    private windowRef: WindowRefService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private configService: ConfigService,
    private stateService: StateService
  ) {
    this.modalAttributes = this.elem.nativeElement.getAttribute('attr')?.split([',']) ?? undefined;
    this.modalToOpen = this.elem.nativeElement.getAttribute('modal_to_open') ?? undefined;
    this.modalTrackingKey = this.elem.nativeElement.getAttribute('modal_tracking_key') ?? undefined;
  }

  click(): void {
    this.launchModal();
  }

  private launchModal() {
    const recipient = this.modalAttributes?.find((x) => x === 'recipient');
    const selectedArticle =
      this.stateService.currentUrl().charAt(0) === '/'
        ? this.stateService.currentUrl().split('?')[0]?.slice(1)
        : this.stateService.currentUrl();
    const articleUrl = selectedArticle.split('/').map((str: string) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    });

    const reason =
      articleUrl.join(' | ') +
      ' | ' +
      this.configService.getConfig().brandName +
      ' | ' +
      this.configService.getConfig().country.toUpperCase();

    if (this.modalToOpen) {
      this.modalService
        .showLazyModal(
          { name: this.modalToOpen },
          {
            trackingKey: this.modalTrackingKey ?? this.modalToOpen.toString(),
            trackingValue: this.modalTrackingKey ?? this.modalToOpen.toString(),
            class: 'modal-sm bw-modal--contact-us',
            useNativeScroll: true,
            keyboard: true,
            initialState: {
              userIsRecipient: !!recipient,
              reason: reason
            }
          }
        )
        .then(() => {
          this.clicked.emit();
        })
        .catch((data: ModalRejectData) => {
          if (this.modalToOpen === 'HcQualitySelfServeComponent') {
            if (!data) {
              return;
            }

            const { showFeedback, ...formData } = data;

            if (showFeedback) {
              this.toastr.feedback(t('js.component.feedback.quality-question'), formData);
            }
          }
        });
    }
  }

  @HostListener('click', ['$event.target']) onClick(): void {
    this.click();
  }

  // Accessibility
  @HostListener('keyup', ['$event'])
  onKeyUp(e): void {
    // 13 = enter key
    if (e.keyCode === 13 && this.windowRef.nativeWindow.document.activeElement === this.elem.nativeElement.nativeElement) {
      this.click();
    }
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.modalToOpen = this.elem.nativeElement.getAttribute('modal_to_open') ?? undefined;
    this.modalTrackingKey = this.elem.nativeElement.getAttribute('modal_tracking_key') ?? undefined;
  }

  /**
   * On changes
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.modalToOpen = this.elem.nativeElement.getAttribute('modal_to_open') ?? undefined;
    this.modalTrackingKey = this.elem.nativeElement.getAttribute('modal_tracking_key') ?? undefined;
  }

  ngOnDestroy(): void {}
}
