/**
 *
 *
 * Documentation:
 * https://quoraadsupport.zendesk.com/hc/en-us/articles/115010466208-How-do-I-install-the-Quora-pixel-
 *
 *
 *
 */
import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { ConfigService } from 'Shared/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class QuoraService {
  private serviceInitialized: boolean = false;
  debug: boolean = false;
  window: any;

  quoraId: string;
  initPromise: Promise<any>;

  constructor(
    private windowRef: WindowRefService,
    private domUtils: DomUtilsService,
    private configService: ConfigService
  ) {
    this.quoraId = this.configService.getConfig().quoraTagId;
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<quora>', ...args);
    }
  }

  /**
   * Wrapper around the qp function
   * @param args
   */
  qp(...args: any): void {
    if (this.serviceInitialized && this.window['qp']) {
      this.log(...args);
      this.window['qp'](...args);
    }
  }

  /**
   * Track a basic purchase
   */
  trackPurchase(): void {
    this.qp('track', 'Purchase');
  }

  /**
   * Track adding to a purchase
   */
  trackAddToPurchase(): void {
    this.qp('track', 'AddToCart');
  }

  /**
   * Track adding to payment
   */
  trackAddedPayment(): void {
    this.qp('track', 'AddPaymentInfo');
  }

  /**
   * Track checkout has started
   */
  trackCheckoutStarted(): void {
    this.qp('track', 'InitiateCheckout');
  }

  /**
   * Track a page View
   */
  trackPageView(): void {
    this.qp('track', 'ViewContent');
  }

  /**
   * Init
   */
  init(): Promise<any> {
    this.initPromise =
      this.initPromise ||
      this.domUtils.loadScript('https://a.quora.com/qevents.js', 'quora').then(() => {
        this.serviceInitialized = true;
        this.qp('init', this.quoraId);
      });

    return this.initPromise;
  }
}
