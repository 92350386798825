
<div class="featured-product-carousel" *ngIf="products">
  <ui-box box_space="none" box_space_bottom="expanded">
    <ui-carousel [carousel_slides_per_view_desktop]="3" carousel_align="start" carousel_gap="default" [carousel_overflow]="true" carousel_pagination="scroll-arrows" [rewind]="true">
      <swiper-slide *ngIf="campaignname">
        <bw-modular-content-product-card [campaignname]="campaignname" [iscontentful]="true" [selectedProductCardType]="productCardType.featuredProduct"></bw-modular-content-product-card>
      </swiper-slide>
      <ng-container *ngFor="let product of products">
        <swiper-slide *ngIf="!isLoading">
          <ui-box box_background="core-primary" box_space="none">
            <ui-stack stack_align="center" stack_gap="none">
              <!-- SKU IMAGE--><a class="ui-card__image" bwtrackas="oasys.product-carousel-card.cta" (click)="productSelected(product)">
                <div class="featured-product-carousel__label-container" *ngIf="product?.labels.length &gt; 0">
                  <bw-product-card-label [productLabels]="product?.labels"></bw-product-card-label>
                </div><img class="ui-card__image" [bwEcommImage]="product.imageUrls[0]" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII="></a>
              <ui-stack stack_gap="tight">
                <ui-box box_space_top="near" box_space="none" box_background="transparent">
                  <ui-stack stack_direction="y" stack_distribute="space-between" stack_gap="none">
                    <!-- SKU NAME--><a class="text-body--emphasis product-name" bwtrackas="oasys.product-carousel-card.cta" itemprop="name" (click)="productSelected(product)">{{product?.name}}</a><span class="text-body--default">{{product?.collectionName}}</span>
                    <!-- REVIEWS-->
                    <ui-stack stack_direction="x" stack_align="center" stack_gap="tight">
                      <bw-review-stars [rating]="product?.rating?.average" [roundingScale]="0.25"></bw-review-stars><span class="content__reviews-count" *ngIf="product?.rating?.count &gt;= 1" itemprop="reviewCount">({{product?.rating?.count}})</span>
                    </ui-stack>
                  </ui-stack>
                  <ui-box box_space="none" box_space_top="default">
                    <ui-stack stack_direction="x" stack_align="center" stack_distribute="space-between" [ngClass]="{'contains-discount': product.price?.discount}">
                      <!-- PRICE-->
                      <ui-stack stack_direction="x" stack_gap="none"><span class="text-body--emphasis product-price--is-discounted" *ngIf="product.price?.discount">{{product?.price | price:'original'}}</span><span class="text-body--emphasis product-price" itemprop="price">{{product?.price | price}}</span></ui-stack>
                      <!-- CTA-->
                      <ui-button button_size="large" button_type="primary" bwtrackas="oasys.product-carousel-card.cta" (click)="productSelected(product)"><span #buttontext>View</span></ui-button>
                    </ui-stack>
                  </ui-box>
                </ui-box>
              </ui-stack>
            </ui-stack>
          </ui-box>
        </swiper-slide>
        <bw-loading-spinner *ngIf="isLoading" [fullscreen]="false"></bw-loading-spinner>
      </ng-container>
    </ui-carousel>
  </ui-box>
</div>