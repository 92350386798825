import { Injectable } from '@angular/core';
import { ConfigService } from 'Shared/services/config.service';
import { WindowRefService } from 'Shared/services/window.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocationService } from 'Shared/services/location.service';
import { Purchase } from 'Shared/classes/purchase';
import { StateService } from 'Shared/services/state.service';
import { lastValueFrom } from 'rxjs';

/**
 *
 *
 * Documentation here - https://www.pivotaltracker.com/file_attachments/96083904/download?inline=true
 * (The award for the worse developer docs is this ^^^^)
 */
@Injectable({
  providedIn: 'root'
})
export class ZyperService {
  debug: boolean = false;
  window: any;
  brandId: number;
  campaignId: number;
  authKey: string;
  apiUrl: string;
  serviceInitialized: boolean = false;

  constructor(
    private configService: ConfigService,
    private windowRefService: WindowRefService,
    private http: HttpClient,
    private locationService: LocationService,
    private stateService: StateService
  ) {
    this.window = this.windowRefService.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
    this.brandId = this.configService.getConfig().zyperBrandId;
    this.campaignId = this.configService.getConfig().zyperCampaignId;
    this.authKey = this.configService.getConfig().zyperAuthkey;
    this.apiUrl = this.configService.getConfig().zyperApiUrl;
  }

  /**
   * Log
   */
  log(...args: unknown[]): void {
    if (this.debug) {
      console.log('<zyper>', ...args);
    }
  }

  /**
   * Track the purchase with Zyper
   * @param purchase
   */
  trackPurchase(purchase: Purchase): Promise<any> {
    const url = this.apiUrl;
    const initial = this.stateService.getInitial().params;

    if ((initial.utm_medium || '').indexOf('zyper') < 0) {
      return Promise.resolve();
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authKey}`,
      'Content-Type': 'application/json'
    });

    const data = {
      brand_id: this.brandId,
      payload: {
        campaign_id: this.campaignId,
        purchase_id: purchase.id,
        amount: (purchase.price.price / 100).toFixed(2),
        amount_discount: (purchase.price.discount / 100).toFixed(2),
        amount_original: (purchase.price.original / 100).toFixed(2),
        amount_revenue: (purchase.price.price / 100).toFixed(2),
        currency: purchase.price.currency.toUpperCase(),
        referrer: window.document.referrer,
        source: window.location.origin,

        // Additional fields
        utm_id: initial.utm_id,
        utm_terms: initial.utm_terms,
        utm_campaign: initial.utm_campaign,
        utm_content: initial.utm_content,
        utm_medium: initial.utm_medium,
        utm_source: initial.utm_source,
        utm_referrer: initial.utm_referrer
      }
    };

    this.log('track', 'purchase', this.serviceInitialized, data);

    return this.serviceInitialized
      ? lastValueFrom(
        this.http.post(url, data, {
          headers
        })
      )
      : Promise.resolve();
  }

  /**
   * Set serviceInitialized, to be used byt the cookie service
   * @param serviceInitialized
   */
  setServiceInitalised(serviceInitialized: boolean): void {
    this.serviceInitialized = serviceInitialized;
  }
}
