import { Component } from '@angular/core';
import { ModalComponent } from 'Shared/services/modal.service';

type ModalResolveData = unknown;
type ModalRejectData = unknown;

export type ConfirmType = 'danger' | 'primary';
/* bw:view-encapsulation */
@Component({
  selector: 'bw-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent extends ModalComponent<ModalResolveData, ModalRejectData> {
  title: string;
  body: string;
  successText: string;
  cancelText: string;
  showClose: boolean = true;
  type?: ConfirmType = 'primary';

  constructor() {
    super();
  }

  /**
   * On success
   */
  onSuccess(): void {
    super.closeAsResolve({});
  }

  /**
   * On cancel
   */
  onCancel(): void {
    super.closeAsReject({});
  }
}
