/**
 * Zendesk Documentation:
 * Guide -> https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/getting_started/
 * Core API -> https://developer.zendesk.com/api-reference/widget-messaging/web/core/
 */
import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { ConfigService } from 'Shared/services/config.service';
import { WindowRefService } from 'Shared/services/window.service';
import { BehaviorSubject } from 'rxjs';

interface ZendeskSettings {
  webWidget: {
    offset?: {
      horizontal?: string;
    };
    chat?: {
      departments: {
        select?: number;
      };
    };
  };
}

@Injectable({
  providedIn: 'root'
})
export class ZendeskWidgetService {
  online$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private zendeskKey: string;
  private department: number;
  private debug: boolean = false;
  private initPromise: Promise<unknown>;
  private window: Window;

  constructor(private domUtils: DomUtilsService, private configService: ConfigService, private windowRefService: WindowRefService) {
    this.zendeskKey = this.configService.getConfig().zendeskKey;
    this.window = this.windowRefService.nativeWindow;
    this.department = this.configService.getConfig().zendeskDefaultDepartment;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Inilialize the Zendesk Widget
   * @returns {Promise<unknown>}
   */
  public init(): Promise<unknown> {
    this.initPromise = this.initPromise || this.initZendesk();

    return this.initPromise;
  }

  /**
   * Open the chat widget
   */
  public openChat(): void {
    if (this.window.zE) {
      this.window.zE('messenger', 'open');
      this.log('opening chat');
    }
  }

  /**
   * Wait for widget to show
   * @returns {Promise<boolean>}
   */
  public waitForLoad(): Promise<boolean> {
    return new Promise((resolve): void => {
      this.window.zE('messenger', 'open', (): void => {
        resolve(true);
      });
    });
  }

  /**
   * Init
   */
  private initZendesk(): Promise<void> {
    const offset = this.configService.getConfig().zendeskChatOffset;

    const obj: ZendeskSettings = {
      webWidget: {}
    };
    if (offset) {
      obj.webWidget.offset = { horizontal: `${offset}px` };
    }

    if (this.department) {
      obj.webWidget.chat = {
        departments: {
          select: this.department
        }
      };
    }

    this.window['zeSettings'] = obj;
    window['zeSettings'] = obj;

    this.log('Init');

    return this.domUtils.loadScript(`https://static.zdassets.com/ekr/snippet.js?key=${this.zendeskKey}`, 'ze-snippet').then((): void => {
      this.window.zE('messenger:set', 'locale', this.configService.getConfig().zendeskLocale);
    });
  }

  /**
   * Log
   */
  private log(...args: unknown[]): void {
    if (this.debug) {
      console.log('<zendesk-chat>', ...args);
    }
  }
}
