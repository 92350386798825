import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { ValidationService } from 'Shared/services/validation.service';
import {
  OasysButtonModule,
  OasysCheckboxModule,
  OasysFormErrorsModule,
  OasysFormGroupModule,
  OasysIconModule,
  OasysLayoutModule,
  OasysRadioModule,
  OasysTextInputModule
} from 'oasys-lib';
import { NgFor, NgIf } from '@angular/common';
import { FeaturesService } from 'Shared/services/features.service';
import { emailPreferences } from 'Account/components/email-preferences/email-preferences.component';
import { EmailPreference } from 'Shared/classes/email';
import { User, UserService } from 'Shared/services/user.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';
import { ContentSegment } from 'Shared/models/segment-model.service';
import { EmailService } from 'Shared/services/email.service';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { ContentService } from 'Shared/services/content.service';
import { Error } from 'Shared/classes/error';
import { AuthOrigin } from 'Shared/components/modals/auth-modal/auth';
import { t } from 'Shared/utils/translations';
import { LoyaltyService } from 'Shared/services/loyalty.service';
import { LoadingSpinnerComponent } from 'Shared/components/loading-spinner/loading-spinner.component';

export interface RadioOption {
  id: string | number;
  value: EmailPreference;
  label: string;
  description?: string;
}

/**
 * PLEASE DO NOT TOUCH THIS COMPONENT
 * Unauthorized changes may lead to unexpected behaviour in the authentication flow
 *
 * The Auth Register Form Component is critical for the authentication flow
 * This component includes stricly the register form logic
 * Any other decorators/logic Like rewards checkout icons, My account notes should be handled in the Feature components like Auth modal outside this component.
 *
 * Bellow is a tutorial how you can use the form inside Authentication modal(bw-auth-modal) and Checkout flow(bw-details-user)
 * Visit Authentication modal(bw-auth-modal) and Checkout flow(bw-details-user) for implementation context
 *
 * Example of struture for implenting inside a component like Authentication modal(bw-auth-modal) or Checkout flow(bw-details-user):
 *
    Feature Component --------------------------------------------
    |
    |   +emailExtras ------------------ Custom Feature-specific logic
    |   bw-auth-email-form ----------- Auth logic DO NOT TOUCH
    |
    |   +passwordExtras -------------- Custom Feature-specific logic
    |   bw-auth-password-form -------- Auth logic DO NOT TOUCH
    |
    |   +registerExtras -------------- Custom Feature-specific logic
    |   bw-register-form ------------- REGISTER Auth logic DO NOT TOUCH
    |
    |   +guestExtras ----------------- Custom Feature-specific logic
    |   bw-auth-guest-form ----------- Auth logic DO NOT TOUCH
    ---------------------------------------------------------------
 */
@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgFor,
    NgIf,
    OasysIconModule,
    OasysLayoutModule,
    OasysButtonModule,
    OasysCheckboxModule,
    OasysFormGroupModule,
    OasysTextInputModule,
    OasysFormErrorsModule,
    OasysRadioModule,
    LoadingSpinnerComponent
  ],
  selector: 'bw-register-form',
  templateUrl: './register-form.component.html'
})
export class RegisterFormComponent implements OnInit, OnDestroy {
  @Input() formSubmitted: boolean = false;
  @Input() origin: AuthOrigin = 'modal';
  @Input() preferredName: string;
  @Input() preferedEmail: string;
  @Input() allowOptInOptions: boolean = false;
  @Input() isJoiningRewards: boolean = false;
  @Input() fullOrigin: string; // Used for Joining Rewards experiment heap events
  @Input() showTandC: boolean = false;

  @Output() didSuccess: EventEmitter<{ authMethod: string; form: FormGroup }> = new EventEmitter<{
    authMethod: string;
    form: FormGroup;
  }>();
  @Output() didFail: EventEmitter<boolean | Error> = new EventEmitter<boolean | Error>();
  @Output() didJoinRewards: EventEmitter<boolean> = new EventEmitter<boolean>();

  form: FormGroup;
  optinFeatureActive: boolean = false;
  options: RadioOption[];
  marketingConsent: RadioOption;
  optionsShown: boolean;
  isSubmitted: boolean = false;
  loading: boolean = false;
  isGuest: boolean = false;
  counter: number = 0;

  constructor(
    private toastr: ToastrService,
    private featuresService: FeaturesService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private optimizelyService: OptimizelyService,
    private emailService: EmailService,
    private contentService: ContentService,
    private loyaltyService: LoyaltyService
  ) {}

  /**
   * On Init
   */
  ngOnInit(): void {
    if (this.formSubmitted) {
      this.form.markAllAsTouched();
      this.form.markAsDirty();
      this.form.updateValueAndValidity();
      this.isSubmitted = this.formSubmitted;
    }

    this.defineOptInMarketingFeature();
    this.buildForm();

    console.log(this.origin, this.fullOrigin);
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    this.form.markAsPristine();
    this.form.reset();
  }

  /**
   * Toggle opt in options
   */
  toggleOptInOptions(): void {
    this.optionsShown = !this.optionsShown;
  }

  /**
   * Choose Marketing Option
   * @param option
   */
  chooseMargetingOption(option?: RadioOption): void {
    this.marketingConsent = option;
  }

  /**
   * Blur field to display validation message
   * @param controlName
   */
  blur(controlName: string): void {
    this.form.get(controlName).markAsDirty();
    this.form.get(controlName).markAsTouched();
  }

  /**
   * Submit and Register
   */
  submit(): Promise<void> {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.isSubmitted = true;

    if (this.form.invalid) {
      return Promise.resolve();
    }

    this.loading = true;
    const user = this.getUserValues();
    const password = this.form.get('password').value;
    return this.userService
      .validateStrongPassword(password)
      .then((res): Promise<void> => {
        if (res) {
          return this.registerUserServiceCall(user);
        }

        this.analyticsService.trackInHeap('authCreateAccountAttempt', { origin: this.origin, attempts: ++this.counter });
        this.form.get('password').setErrors(ValidationService.strongCommonPassword);
        this.form.get('password').markAsTouched();
      })
      .finally((): void => {
        this.loading = false;
      });
  }

  /**
   * Register success
   * @param { authMethod: string } options
   */
  onRegisterSuccess(options?: { authMethod: string }): void {
    this.didSuccess.emit({ authMethod: options?.authMethod, form: this.form });
  }

  /**
   * PRIVATE PROPERTY
   */

  /**
   * Auto-enroll user into Rewards scheme once registered
   * @returns {Promise<void>}
   */
  private joinLoyaltyMembership(user: User): Promise<void> {
    return user.loyaltySchemeMembershipId === undefined && this.isJoiningRewards
      ? this.loyaltyService
          .joinLoyaltyMembership()
          .then((): void => {
            this.didJoinRewards.emit(true);
            this.analyticsService.trackJoiningRewards(this.fullOrigin);
          })
          .catch((): void => {})
      : Promise.resolve();
  }

  /**
   * Register User service call
   * @param user
   * @returns
   */
  private registerUserServiceCall(user: User): Promise<void> {
    return this.userService
      .register(user)
      .then((registered: User): Promise<void> => this.setEmailPreference(registered))
      .then((): Promise<void> => this.joinLoyaltyMembership(user))
      .then((): Promise<ContentSegment[]> => {
        this.analyticsonSuccess(user);
        return this.contentService.refreshSegments();
      })
      .then((): void => this.onRegisterSuccess({ authMethod: 'email' }))
      .catch((e: Error): void => {
        this.track(false, !!user.email.preference);
        this.toastr.error(e.message, e.title);
        if (this.origin === 'occasions') {
          return this.didFail.emit(e);
        }
        return this.didFail.emit(true);
      });
  }

  /**
   * Analytics Register OnSuccess
   * @param user
   */
  private analyticsonSuccess(user: User): void {
    if (this.optinFeatureActive) {
      this.optimizelyService.trackEvent('register');
      this.optimizelyService.trackEvent('set_password');
      this.analyticsService.trackInHeap('authCreateAccount', {
        isSuccessful: true,
        optInValue: this.form.get('marketing').value,
        origin: this.origin
      });
    } else {
      this.track(true, !!user.email.preference);
    }
  }

  /**
   * BwForm required implementation
   */
  private getUserValues(): User {
    const user = new User();
    user.fullName = this.form.get('name').value;
    user.password = this.form.get('password').value;
    user.email.address = this.form.get('email').value;

    const preference = this.featuresService.getFeature('GDPR').emailSubscribedPreference;

    user.email.preference = this.form.get('marketing').value ? preference : 0;
    user.email.consent = {
      method: 'Checkbox - Default Ticked',
      location: 'Web - Checkout - Register',
      copy: this.getDefaultMarketingMessage()
    };

    return user;
  }

  /**
   * Set email preference for user
   * @param user
   */
  private setEmailPreference(user: User): Promise<void> {
    const configEmailPreference = this.featuresService.getFeature('GDPR');

    let marketingLocation = 'Web - Checkout - Register';
    let marketingCopy = this.getDefaultMarketingMessage();

    if (!this.optinFeatureActive) {
      user.email.preference = +this.form.get('marketing').value
        ? configEmailPreference.optedInEmailPreference
        : configEmailPreference.optOutEmailPreference;
    } else {
      user.email.preference = +this.form.get('marketing').value;
      marketingCopy = this.marketingConsent.label + ' ' + this.marketingConsent.description;

      marketingLocation = 'Web - Checkout - Register';
    }

    user.email.consent = {
      copy: marketingCopy,
      method: 'Checkbox - Default Ticked',
      location: marketingLocation
    };
    return this.emailService.update(user.email);
  }

  /**
   * Build Marketing message for tracking
   * @returns
   */
  private getDefaultMarketingMessage(): string {
    return t('js.auth-register.auto_key.string_9_1') + ' ' + t('js.auth-register.auto_key.string_9_2');
  }

  /**
   * Track in heap
   * @param isSuccessful
   * @param optedIn
   */
  private track(isSuccessful: boolean, optedIn: boolean): void {
    if (isSuccessful) {
      this.optimizelyService.trackEvent('register');
      this.optimizelyService.trackEvent('set_password');
    }
    this.analyticsService.trackInHeap('authCreateAccount', { isSuccessful, optedIn, origin: this.origin });
  }

  /**
   * Build form
   */
  private buildForm(): void {
    this.form = new FormGroup({
      name: new FormControl(this.preferredName ?? '', this.nameValidators()),
      email: new FormControl(this.preferedEmail ?? '', [Validators.required, ValidationService.email()]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      marketing: new FormControl(this.optinFeatureActive, [])
    });

    if (this.optinFeatureActive) {
      this.form.get('marketing').setValue(this.options[0].value.toString());
      this.marketingConsent = this.options[0];
    }
  }

  /**
   * Find name Validators
   * @returns {ValidatorFn[]}
   */
  private nameValidators(): ValidatorFn[] {
    const countryConfig = this.featuresService.getFeature('ADDRESS_FIELDS');
    const spaceInName = this.featuresService.getFeature('REQUIRE_SPACE_IN_USER_NAME');

    const nameValidations = [];

    if (spaceInName || countryConfig?.firstAndSecondNameRequired) {
      nameValidations.push(ValidationService.fullNameRequired());
    } else {
      nameValidations.push(Validators.required);
    }

    return [...nameValidations];
  }

  /**
   * Define OptIn Marketing Feature
   */
  private defineOptInMarketingFeature(): void {
    this.optinFeatureActive = this.featuresService.getFeature('OPTIN_LOGIN') && this.allowOptInOptions;

    if (this.optinFeatureActive) {
      const availablePreferences = this.featuresService.getFeature('GDPR').availableEmailPreferences;
      this.options = availablePreferences?.map((id): RadioOption => emailPreferences.find((e): boolean => e.value === id));
    }
  }
}
