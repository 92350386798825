
<div class="feature-banner">
  <div class="feature-banner__close"><a (click)="hide()"><img src="/assets/brand-icons/x.svg"></a></div>
  <div class="feature-banner__heading"><img class="feature-banner__whiskers" src="/assets/brand-icons/whiskers.svg"><img class="feature-banner__title-image" *ngIf="titleImage" src="{{titleImage}}">
    <div class="feature-banner__title-text" *ngIf="titleText">{{titleText}}</div>
    <div class="feature-banner__arrow"><img src="/assets/brand-icons/credit-arrow.svg"></div>
  </div>
  <div class="feature-banner__content u-font-medium" *ngIf="contentText" [innerHtml]="contentText"></div>
  <div class="feature-banner__cta" *ngIf="ctaText">
    <bw-button buttonStyle="primary" uiSref="checkout.base" bwTrackAs="header.credit.banner" (click)="didClick()">{{ctaText}}</bw-button>
  </div>
</div>