import { OnInit, Component, Input } from '@angular/core';
import { environment } from 'Environments/environment';
import { t } from 'Shared/utils/translations';
import { Delivery, DeliveryHistory } from 'Shared/classes/delivery';
import * as dayjs from 'dayjs';
import { OrderService } from 'Shared/services/order.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { AppboyService } from 'Shared/services/third-parties/appboy.service';
import { DeviceOs, ViewportDetectionService } from 'Shared/services/viewport-detection.service';
import { UserService } from 'Shared/services/user.service';
import { ModalService } from 'Shared/services/modal.service';
import { Order } from 'Shared/classes/order';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-delivery-tracking-content',
  templateUrl: './delivery-tracking-content.component.html'
})
export class DeliveryTrackingContentComponent implements OnInit {
  @Input() delivery: Delivery;
  @Input() order: Order;
  @Input() orderToken: string;
  @Input() hideToggle: boolean = false;
  @Input() isOpen: boolean = false;

  progressTracking: number;
  maxTracking: number = 4;
  environment: any;
  appStoreAttributedLink: string;
  historyContent: any[];
  historyContentKeys: any[];
  creditApplied: boolean = false;
  isUserRegistered: boolean;
  deviceType: DeviceOs;
  isDeliveryLate: boolean = false;

  constructor(
    private orderService: OrderService,
    private analyticsService: AnalyticsService,
    private appboyService: AppboyService,
    private viewportDetectionService: ViewportDetectionService,
    private userService: UserService,
    private modalService: ModalService
  ) {}

  /**
   * On init
   */
  ngOnInit(): void {
    this.deviceType = this.viewportDetectionService.checkDeviceOs();

    const user = this.userService.getUser();
    if (!this.orderToken && user.isLoggedIn()) {
      this.orderService.getAll().then((orders) => {
        const order = orders.find((o) => o.id === this.delivery.orderId);
        this.orderToken = order ? order.token : null;
      });
    }
    if (this.delivery) {
      this.analyticsService.trackInHeap('deliveryTrackingModalView', {
        trackingState: this.delivery.trackingDetails ? this.delivery.trackingDetails.status : '',
        deliveryId: this.delivery.id,
        order: this.order
      });
    }
    this.progressTracking = this.getProcessDelivery();
    this.environment = environment;
    this.appStoreAttributedLink = t('js.generic.site.app_download.iOS', 'delivery-tracking-mobile');
    this.historyContent = this.getHistory(this.delivery);
    this.isDeliveryLate =
      dayjs().diff(this.delivery?.trackingDetails?.deliveryOn, 'day') > 1 ?? false;
    this.appboyService.logEvent('web:page:deliverytracking');
  }

  /**
   * Get progress of delivery for progress bar
   */
  public getProcessDelivery(): number {
    if (!this.delivery) {
      return undefined;
    }
    if (this.delivery.state === 'billed') {
      return 1;
    }
    if (this.delivery.state === 'locked' || this.delivery.state === 'printed') {
      return 2;
    }
    if (this.delivery.state === 'shipped') {
      return 3;
    }
    if (this.delivery.state === 'delivered') {
      return 4;
    }
  }

  /**
   * Capitalise first letter within string
   * @param date
   */
  capitalizeFirstLetter(date: string): string {
    if (date === 'today' || date === 'tomorrow') {
      return date.charAt(0).toUpperCase() + date.slice(1);
    }

    return date;
  }

  /**
   * Get delivery history
   * @param delivery
   */
  public getHistory(delivery: Delivery): any {
    const deliveryHistory: DeliveryHistory[] = [];

    if (!delivery) {
      return [];
    }

    this.addHistoryItem(delivery.trackingDetails.deliveredAt, 'delivered', deliveryHistory);
    this.addHistoryItem(delivery.trackingDetails.shippedAt, 'shipped', deliveryHistory);
    this.addHistoryItem(delivery.trackingDetails.lockedAt, 'locked', deliveryHistory);
    this.addHistoryItem(delivery.billedAt, 'billed', deliveryHistory);

    return deliveryHistory;
  }

  /**
   * Create and add delivery history item
   * @param dateItem
   * @param stateItem
   * @param deliveryHistory
   */
  private addHistoryItem(
    dateItem: dayjs.Dayjs,
    stateItem: string,
    deliveryHistory: DeliveryHistory[]
  ): void {
    if (dateItem.isValid()) {
      const item = deliveryHistory.find(
        (a) => a.day.format('YYYY-MM-DD') === dateItem.format('YYYY-MM-DD')
      );
      if (item) {
        item.values.push({ state: stateItem, time: dateItem });
      } else {
        deliveryHistory.push({
          day: dateItem,
          values: [{ state: stateItem, time: dateItem }]
        });
      }
    }
  }

  sendHeapEvent(eventName: string, delivery: Delivery, extraDetails?: string): void {
    if (extraDetails) {
      this.analyticsService.trackInHeap(eventName, {
        deliveryId: delivery.id,
        trackingState: delivery.trackingDetails ? delivery.trackingDetails.status : '',
        details: extraDetails
      });
    } else {
      this.analyticsService.trackInHeap(eventName, {
        deliveryId: delivery.id,
        trackingState: delivery.trackingDetails ? delivery.trackingDetails.status : ''
      });
    }
  }

  /**
   * Tracking event based on view more location
   */
  sendViewMoreDetailsLocation(): void {
    this.sendHeapEvent('trackingModalViewMore', this.delivery, 'Where Has My Gift Been?');
  }

  /**
   * Tracking event based on view more details
   */
  sendViewMoreDetails(): void {
    this.sendHeapEvent('trackingModalViewMore', this.delivery, 'Order Details');
  }

  /**
   * Tracking event based on view more faq
   */
  sendViewMoreDetailsFAQ(): void {
    this.sendHeapEvent('trackingModalViewMore', this.delivery, 'FAQs');
  }

  /**
   * Show delivery self serve modal
   * @param {Delivery}delivery
   */
  showDeliverySelfServeModal(delivery: Delivery): Promise<void> {
    const user = this.userService.getUser();
    const initialState = user.isLoggedIn()
      ? { preferredDelivery: delivery, origin: 'orderDeliveryResolution' }
      : {
        preferredOrderId: delivery.orderId,
        preferredEmail: user.email,
        origin: 'orderDeliveryResolution'
      };

    return this.modalService
      .showLazyModal(
        { name: 'HcDeliverySelfServeComponent' },
        { initialState, class: 'modal-sm help-center-modal' }
      )
      .catch(() => {});
  }
}
