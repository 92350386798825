
<!-- TODO: Depending on requirements (and if we want a button to launch this modal again-->
<!-- this might be easiest seperated into two components, one for desktop and one for mobile-->
<ng-container *featureEnabled="'DELIVERY_TRACKING_MODAL'">
  <div class="bw-delivery-tracking-container" *ngIf="delivery &amp;&amp; (viewportSizeIs$ | async)?.mobile">
    <div class="bw-delivery-tracking">
      <bw-delivery-tracking-content [delivery]="delivery" [orderToken]="orderToken" [hideToggle]="false" [isOpen]="isOpen"></bw-delivery-tracking-content>
    </div>
  </div>
  <bw-launch-modal *ngIf="delivery &amp;&amp; !(viewportSizeIs$ | async)?.mobile" modalToLaunch="deliveryTracking" [delivery]="delivery"></bw-launch-modal>
</ng-container>