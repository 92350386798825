import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Address } from 'Shared/classes/address';
import { Highlight } from 'Shared/pipes/highlight.pipe';
import { AddressSearchService } from 'Shared/services/address-search.service';
import { Country } from 'Shared/classes/country';
import { AddressSearchResult } from 'Shared/models/address-search-model.service';
@Component({
  selector: 'bw-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss']
})
export class AddressSearchComponent implements OnInit, OnDestroy {
  @Input()
  parentForm: FormGroup;
  @Input()
  hint: string;
  @Input()
  required: boolean = true;
  @Input()
  country: Country;
  @Input()
  label: string;
  @Input()
  newStyle: boolean; // TODO: Remove after address picker experiment wins (Experiment Name is ADDRESS_PICKER)
  @Output()
  addressSelected: EventEmitter<any> = new EventEmitter();
  @Output()
  newAddressSelected: EventEmitter<any> = new EventEmitter();
  @Input() formSubmitted: boolean;

  // Properties
  results: {
    postcodes: AddressSearchResult[];
    addresses: AddressSearchResult[];
  } = {
    postcodes: [],
    addresses: []
  };
  selectedAddress: Address;
  control: FormControl;
  isLoading: boolean = false;

  /**
   * Constructor
   * @param addressSearchService
   * @param addressPipe
   * @param highlight
   */
  constructor(private addressSearchService: AddressSearchService, private highlight: Highlight) {}

  /**
   * Setup the form
   */
  setupForm(): void {
    this.control = new FormControl('');
    if (this.required) {
      this.control.setValidators([Validators.required, hasSearchedAddressValidator(this)]);
    }
    this.parentForm = this.parentForm || new FormGroup({});

    this.parentForm.addControl('addressSearch', this.control);
  }

  /**
   * On results change
   * @param searchResults
   */
  resultsChanged(searchResults: AddressSearchResult[]): void {
    if (!searchResults.length || searchResults[0]['Error']) {
      return null;
    }
    const resultsClone = searchResults.slice();
    this.results.postcodes = resultsClone
      .filter((i) => i.Type !== 'Address')
      .map((pc: any) => {
        pc.Text = this.highlight.transform(pc.Text, pc.Highlight);
        return pc;
      });
    this.results.addresses = resultsClone.filter((i) => i.Type === 'Address');
  }

  /**
   * Search fragment
   * @param result
   */
  searchFragment(result): Promise<any> {
    this.isLoading = true;
    return this.addressSearchService.getResults(result.Text, this.country, result.Id).then((r) => {
      this.isLoading = false;
      return this.resultsChanged(r);
    });
  }

  /**
   * Select an address
   * @param result
   */
  selectAddress(result): Promise<any> {
    this.isLoading = true;
    return this.addressSearchService.getAddress(result.Id).then((address: Address) => {
      this.isLoading = false;
      this.selectAndEmit(address);
    });
  }

  /**
   * Select the address and emit
   */
  selectAndEmit(address: Address): void {
    this.selectedAddress = address;
    this.control.updateValueAndValidity();
    this.addressSelected.emit(address);
  }

  /**
   * New Address
   */
  newAddress(): void {
    if (this.newStyle) {
      this.control.reset();
    }

    const newAddress = new Address(this.country);
    this.selectedAddress = newAddress;
    this.control.updateValueAndValidity();
    this.newAddressSelected.emit(newAddress);
  }

  resetAndHide(dropdown: any): void {
    this.results = {
      postcodes: [],
      addresses: []
    };
    this.control.reset();
    dropdown.hide();
  }

  /**
   * Close if control input empty or valid address exists
   * @param dropdown
   */
  closeOnBlurIfValid(dropdown: any): void {
    if (!this.control.value) {
      this.results = {
        postcodes: [],
        addresses: []
      };
    }

    if (this.control.valid && this.required) {
      dropdown.hide();
    }

    // Reset field if user typed something but it didn't select from dropdown or added address manually
    if (this.control.value && !this.required) {
      this.control.reset();
    }
  }

  /**
   * Prevent Close
   * @param event
   */
  preventClose(event: Event): void {
    event.stopImmediatePropagation();
  }

  /**
   * On init, set the address search
   */
  ngOnInit(): void {
    this.setupForm();
    const formDebounce = this.control.valueChanges.pipe(debounceTime(250), distinctUntilChanged());
    // When the value changes, we should reset the current search address
    formDebounce.subscribe((s) => {
      this.selectAndEmit(undefined);
      if (s && s.length > 1) {
        this.searchFragment({ Text: s });
      }
    });
  }

  ngOnDestroy(): void {
    this.parentForm.removeControl('addressSearch');
  }
}

/**
 * Has searched validator
 */
export function hasSearchedAddressValidator(componentInstance: AddressSearchComponent): any {
  return (): { [key: string]: any } | null => {
    const addr = componentInstance.selectedAddress;
    return addr ? null : { hasSearchedAddress: false };
  };
}
