
<div class="reviews-testimonials-carousel" *ngIf="reviews">
  <ui-container>
    <ui-box box_space="none" box_space_top="expanded" box_space_bottom="expanded">
      <ui-stack>
        <ui-stack stack_gap="tight" stack_align="center" [stack_direction]="(viewportSizeIs$ | async)?.mobile ? 'y' : 'x'" stack_distribute="space-between">
          <ui-heading heading_type="functional-primary" [heading_title]="headingTitle" [heading_subtitle]="reviewType === 'trustedshop' ? headingSubtitle : '' "></ui-heading>
          <ui-box box_space="none" [box_fill_mode]="(viewportSizeIs$ | async)?.mobile ? 'fill' : 'fit'">
            <ui-stack stack_gap="tight" stack_align="center" stack_direction="x" *ngIf="reviewType === 'trustpilot'"><span><span class="text-body--default">Rated</span><span class="text-body--default text-body--emphasis"> Excellent</span><span class="text-body--default"> on</span></span><a href="https://uk.trustpilot.com/review/www.bloomandwild.com" target="_blank" rel="noopener noreferrer"><img class="reviews-testimonials-carousel__trustpilot-logo" src="/assets/images/Trustpilot_brandmark_gr-blk-RGB.svg" alt="Reviews from Trustpilot.com"></a></ui-stack>
            <ui-stack stack_gap="tight" *ngIf="reviewType === 'trustedshops'">
              <ui-stack stack_gap="tight" stack_align="center" stack_direction="x" [stack_distribute]="(viewportSizeIs$ | async)?.mobile ? 'start': 'end'"><img class="reviews-testimonials-carousel__trustedshops-stars" src="/assets/images/trustedshops_starrow.svg" alt="Reviews from Trustpilot.com"><span class="text-body--her text-body--emphasis">Excellent</span><a href="https://uk.trustpilot.com/review/www.bloomandwild.com" target="_blank" rel="noopener noreferrer"><img class="reviews-testimonials-carousel__trustedmark" src="/assets/images/trustedshops_trustmark.svg" alt="Reviews from Trustpilot.com"></a></ui-stack>
              <ui-box [ngClass]="{'text-center': !(viewportSizeIs$ | async)?.mobile}" box_space="none"><span class="text-body--supporting text-body--emphasis text-color--supporting">{{ stats.average_rating }}</span><span class="text-body--supporting text-color--supporting"> average</span><span class="text-color--supporting text-color--supporting"> |</span><span class="text-body--supporting text-body--emphasis text-color--supporting"> {{stats.count}}</span><span class="text-body--supporting text-color--supporting"> reviews</span></ui-box>
            </ui-stack>
          </ui-box>
        </ui-stack>
        <ui-box box_space="none">
          <ui-carousel carousel_align="start" carousel_gap="default" carousel_pagination="none" [carousel_overflow]="true">
            <ng-container *ngFor="let review of reviews">
              <swiper-slide><a href="/reviews">
                  <bw-review-testimonial-card [reviewData]="review" [maxReviewTitleLength]="(viewportSizeIs$ | async)?.mobile ? 25 : 30" [maxReviewLength]="(viewportSizeIs$ | async)?.mobile ? 90 : 115" [reviewType]="reviewType"></bw-review-testimonial-card></a></swiper-slide>
            </ng-container>
          </ui-carousel>
        </ui-box>
        <ui-box class="text-center" box_space="none"><span class="text-body--supporting text-color--supporting" *ngIf="reviewType === 'trustpilot'">*Based on all UK online florists rated on Trustpilot or Reviews.co.uk</span><a class="text-link--standalone" href="/reviews" *ngIf="reviewType === 'trustedshops'" bwTrackAs="component.product-detail-new.more-reviews">Read more reviews</a></ui-box>
      </ui-stack>
    </ui-box>
  </ui-container>
</div>