import { Component, OnInit, Input, ViewEncapsulation, ElementRef, Output, EventEmitter } from '@angular/core';
import { BwFormControl } from 'Shared/classes/bw-form';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-form-checkbox-input',
  templateUrl: './form-checkbox-input.component.html'
})
export class FormCheckboxInputComponent implements OnInit {
  @Input() bwFormControl: BwFormControl<boolean>;
  @Input() align: 'left' | 'right' = 'left';
  @Input() size: 'small' | 'medium' | 'large' | 'xlarge' = 'medium';
  @Input() disabled: boolean = false;

  @Output() didChange: EventEmitter<boolean> = new EventEmitter();

  id: string;
  hasSubmitted: boolean = false;
  labelText: string = '';
  hasDescription: boolean = false;

  constructor(private elementRef: ElementRef) {}

  /**
   * Mark as submmitted
   */
  markAsSubmitted(): void {
    this.hasSubmitted = true;
  }

  /**
   * On value change
   * When we need to liksten to the user input only
   */
  onChange() {
    this.didChange.emit(this.bwFormControl.value);
  }

  /**
   * After view, set local properties
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      const labelElem = this.elementRef.nativeElement.querySelector("[role='label']");
      if (labelElem) {
        this.labelText = labelElem.innerText;
      }

      this.hasDescription = !!this.elementRef.nativeElement.querySelector("[role='description']");
    }, 1);
  }

  ngOnInit(): void {
    const formGroup = this.bwFormControl?.parent?.controls;
    if (formGroup) {
      const name = Object.keys(formGroup).find((key) => this.bwFormControl === formGroup[key]);
      this.id = name;

      // Add an additional function, which marks this form as submitted
      this.bwFormControl['markAsSubmitted'] = () => {
        this.markAsSubmitted();
      };
    }
  }
}
