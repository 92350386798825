import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from 'Shared/classes/user';
import { environment } from 'Environments/environment';
import { Jsona } from 'jsona/lib';
import { Error } from 'Shared/classes/error';
import { GaService } from 'Shared/services/third-parties/ga.service';
import { LocationService } from 'Shared/services/location.service';
import { t } from 'Shared/utils/translations';
import { Experiment } from 'Shared/classes/experiment';
import { WindowRefService } from 'Shared/services/window.service';
import { lastValueFrom, Subject } from 'rxjs';
import { StateService } from 'Shared/services/state.service';
import { siteConfig } from 'Environments/site-config';

import {
  APIEndpoint,
  APIHeaders,
  APIJSONRequest,
  APIPaginationOptions,
  APIPaginationResponse,
  APIRequestQueryParams,
  APISerialisedJSONResponse,
  BasicParams
} from 'Shared/types/backend-api/api';
import { CustomQueryEncoderService } from './custom-query-encoder.service';
import { LocalStorageService } from 'Shared/services/local-storage.service';
import { Params } from '@angular/router';
import { TJsonApiBody } from 'jsona/lib/JsonaTypes';

export type { APISerialisedJSONResponse, APIJSONRequest, APIHeaders };

/**
 * Params Partial Type - only required if the URL used matches that of a known/typed API endpoint
 * Once all API endpoints are typed, this likely can be tidied
 */
type BackendOptionsType<T> = {
  headers?: APIHeaders<T>;
  responseIsJsonApi?: boolean; // TODO combine into a 'isJSONApi'
  requestIsJsonApi?: boolean;
  useUrlAsCache?: boolean;
  clearCache?: boolean;
  params?: APIRequestQueryParams<T>; // TODO: only enforce 'params' if T extends APIEndPoint
  sendUtmParams?: boolean;
  sendExperiments?: boolean | string[];
  responseIsPaginate?: APIPaginationOptions;
  useFullUrlFromInput?: boolean;
};

type BWParams = Params & {
  utm_medium?: string | unknown;
  utm_campaign?: string | unknown;
  utm_source?: string | unknown;
};

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  onErrorRaised$ = new Subject<Error>();

  server: string;

  private serverParams: Params = {};
  private locale: string;
  private deviceFingerprint: string;
  private deviceFingerprintOld: string;
  private siteConfig = siteConfig;
  private experimentsRunning: Experiment[] = [];

  private getPromiseCache = {};
  private initialParams: Params;

  /**
   * Constructor
   * @param http
   * @param gaService
   * @param locationService
   * @param windowRefService
   * @param stateService
   * @param localStorageService
   */
  constructor(
    private http: HttpClient,
    private gaService: GaService,
    private locationService: LocationService,
    private windowRefService: WindowRefService,
    private stateService: StateService,
    private localStorageService: LocalStorageService
  ) {
    this.server = this.getBackendUrl();
    this.initialParams = this.locationService.getUrlParams();

    if (environment.environment !== 'production') {
      const localAPIURL: string = this.localStorageService?.get('apiUrl') ?? undefined;

      const urlParams = this.locationService.getUrlParams();
      this.server = urlParams.apiUrl || localAPIURL || this.getBackendUrl();
      if (urlParams.apiUrl) {
        // store in local storage
        this.localStorageService.set('apiUrl', urlParams.apiUrl);
      }

      if (urlParams.testCase) {
        this.serverParams.testCase = urlParams.testCase;
      }
      if (urlParams.recordTestCase) {
        this.serverParams.recordTestCase = urlParams.recordTestCase;
      }
    }

    // To avoid circle dependancies, and to enforce that it doesn't change during the users session
    // we get the locale directly from the file
    this.locale = this.siteConfig[`${environment.defaultShippingCountryId}`]['locale.backend'];

    this.deviceFingerprint = JSON.parse(this.windowRefService.nativeWindow['bwFingerprint'] || '""').replace(/\"/gim, ''); // eslint-disable-line
    this.deviceFingerprintOld = JSON.parse(this.windowRefService.nativeWindow['bwFingerprintOld'] || '""').replace(/\"/gim, ''); // eslint-disable-line
  }

  /**
   * Set which experiments running
   * - we can't access the experiment service directly due to a circle dependancy
   * @param experiments
   */
  setExperimentsRunning(experiments: Experiment[]): void {
    this.experimentsRunning = experiments;
  }

  /**
   * Deserialise the data
   * @param jsonAPIResponse
   */
  deserialise(jsonAPIResponse: string | TJsonApiBody): APISerialisedJSONResponse<APIEndpoint | string> {
    const dataFormatter = new Jsona();
    return dataFormatter.deserialize(jsonAPIResponse);
  }

  defaultBackendUrl(): string {
    return this.getBackendUrl();
  }

  /**
   * Get Request
   * @param user
   * @param url
   * @param options
   */
  public async get<UserType extends User, UrlType extends APIEndpoint | string>(
    user: UserType,
    url: UrlType,
    options?: BackendOptionsType<UrlType>
  ): Promise<APISerialisedJSONResponse<UrlType>> {
    // We need to cast {} if it hasn't been passed in
    const backendOptions = options ?? ({} as BackendOptionsType<UrlType>);

    let headers = this.getAuthHeaders(user, backendOptions ? backendOptions.headers : {});

    // If environment is development or staging then add x-bloom-environment: development
    // header for Cloudflare security when running on localhost
    if (environment.environment === 'development' || environment.environment === 'staging') {
      headers = headers.append('x-bloom-environment', 'development');
    }

    let fullUrl = `${this.server}${url}`;

    // This is needed because so far we fetched the date from the BE monolith,
    // which has the endpoints in the format api/...
    // This is not going to be the case anymore with the microservices architecture,
    // So in that case we need to call the BE with the full URL
    if (backendOptions.useFullUrlFromInput) {
      fullUrl = url;
    }

    const params = this.createParams(backendOptions);
    const cacheUrl = this.urlAsCacheKey(fullUrl, params);
    const httpParams = new HttpParams({
      fromObject: params,
      encoder: new CustomQueryEncoderService()
    });

    if (backendOptions.clearCache) {
      this.getPromiseCache[cacheUrl] = undefined;
      this.windowRefService.clearCache(cacheUrl);
    }

    const httpCall = (): Promise<APISerialisedJSONResponse<UrlType>> =>
      lastValueFrom(
        this.http.get(fullUrl, {
          headers,
          params: httpParams
        })
      )
        .then((res: APIPaginationResponse): APISerialisedJSONResponse<APIEndpoint | string> => {
          const r = backendOptions.responseIsJsonApi ? this.deserialise(res) : res;
          const paginationOptions = backendOptions.responseIsPaginate ? this.formatPaginationOptions(res) : undefined;
          return paginationOptions ? { data: r, paginationOptions } : r;
        })
        .catch((errRes: HttpErrorResponse): Promise<Error> => this.handleError(errRes));

    if (!backendOptions.useUrlAsCache) {
      return await httpCall();
    }

    const windowCache = this.windowRefService.getCache(cacheUrl);
    const windowPromiseCache = this.windowRefService.getPromiseCache(cacheUrl);

    if (this.getPromiseCache[cacheUrl]) {
      return this.getPromiseCache[cacheUrl];
    }

    let promise;
    if (windowCache) {
      promise = Promise.resolve(windowCache);
    } else if (windowPromiseCache) {
      promise = windowPromiseCache;
    } else {
      promise = httpCall();
    }

    this.getPromiseCache[cacheUrl] = promise;

    return this.getPromiseCache[cacheUrl];
  }

  /**
   * Delete Request
   * @param user
   * @param url
   * @param options
   */
  public delete(user: User, url: string, options?: BackendOptionsType<unknown>): Promise<APISerialisedJSONResponse<APIEndpoint | string>> {
    const fullUrl = `${this.server}${url}`;
    const headers = this.getAuthHeaders(user, options ? options.headers : {});

    const params = this.createParams(options);
    const httpParams = new HttpParams({
      fromObject: params,
      encoder: new CustomQueryEncoderService()
    });

    return lastValueFrom(
      this.http.delete(fullUrl, {
        headers,
        params: httpParams
      })
    )
      .then(
        (res: string | TJsonApiBody): APISerialisedJSONResponse<APIEndpoint | string> =>
          options && options.responseIsJsonApi ? this.deserialise(res) : res
      )
      .catch((errRes: HttpErrorResponse): Promise<Error> => this.handleError(errRes));
  }

  /**
   * Put
   * @param user User
   * @param url  Url
   * @param data Data
   * @param options
   */
  public put<UserType extends User, UrlType extends APIEndpoint | string>(
    user: UserType,
    url: UrlType,
    data?: APIJSONRequest<UrlType>,
    options?: BackendOptionsType<UrlType>
  ): Promise<APISerialisedJSONResponse<UrlType>> {
    const fullUrl = `${this.server}${url}`;
    let headers = this.getAuthHeaders(user, options ? options.headers : {});

    if (options && options.requestIsJsonApi) {
      headers = headers.append('Content-Type', 'application/vnd.api+json');
    }

    // If environment is development or staging then add x-bloom-environment: development
    // header for Cloudflare security when running on localhost
    if (environment.environment === 'development' || environment.environment === 'staging') {
      headers = headers.append('x-bloom-environment', 'development');
    }

    const params = this.createParams(options);
    const httpParams = new HttpParams({
      fromObject: params,
      encoder: new CustomQueryEncoderService()
    });

    return lastValueFrom(
      this.http.put(fullUrl, this.tidyPayload(data), {
        headers,
        observe: 'response',
        params: httpParams
      })
    )
      .then((res: APISerialisedJSONResponse<APIEndpoint | string>): APISerialisedJSONResponse<APIEndpoint | string> => {
        const body = options && options.responseIsJsonApi ? this.deserialise(res.body) : res.body;
        // Not all responses have a body.....
        if (body) {
          body.token = res.headers.get('http_x_purchase_token');
        }
        return body;
      })
      .catch((errRes: HttpErrorResponse): Promise<Error> => this.handleError(errRes));
  }

  /**
   * Post
   * @param user
   * @param url
   * @param data
   * @param options
   */
  public post<UserType extends User, UrlType extends APIEndpoint | string>( // eslint-disable-line
    user: User,
    url: string,
    data?: APIJSONRequest<UrlType>,
    options?: BackendOptionsType<unknown>
  ): Promise<APISerialisedJSONResponse<UrlType>> {
    const fullUrl = `${this.server}${url}`;
    let headers: HttpHeaders = this.getAuthHeaders(user, options ? options.headers : {});

    if (options && options.requestIsJsonApi) {
      headers = headers.append('Content-Type', 'application/vnd.api+json');
    }

    // If environment is development or staging then add x-bloom-environment: development
    // header for Cloudflare security when running on localhost
    if (environment.environment === 'development' || environment.environment === 'staging') {
      headers = headers.append('x-bloom-environment', 'development');
    }

    const params = this.createParams(options);
    const httpParams = new HttpParams({
      fromObject: params,
      encoder: new CustomQueryEncoderService()
    });

    headers = headers.set('Accept', 'application/json, text/plain');

    return lastValueFrom(
      this.http.post(fullUrl, this.tidyPayload(data), {
        headers,
        observe: 'response',
        params: httpParams
      })
    )
      .then((res: APISerialisedJSONResponse<APIEndpoint | string>): APISerialisedJSONResponse<APIEndpoint | string> => {
        const body = options && options.responseIsJsonApi ? this.deserialise(res.body) : res.body;
        // Not all responses have a body.....
        if (body) {
          body.token = res.headers.get('http_x_purchase_token');
        }
        return body;
      })
      .catch((errRes: HttpErrorResponse): Promise<Error> => this.handleError(errRes));
  }

  /**
   * Get the auth headers
   * - TODISCUSS: Should this be an interceptor
   * @param user User
   * @param additional
   */
  private getAuthHeaders(user: User, additional?: APIHeaders<APIEndpoint> | string): HttpHeaders {
    const headers = this.removeEmpty(Object.assign({}, additional));

    this.buildUserHeaders(user, headers);

    // Added to test the Platform Migration.
    if (this.initialParams && this.initialParams['new_aws_account'] === 'true') {
      headers['x-bw-new-aws-account'] = 'true';
    }

    headers['x-angular-version'] = '12';
    headers['x-fingerprint'] = this.deviceFingerprint;
    headers['x-fingerprint-old'] = this.deviceFingerprintOld;

    return Object.keys(headers).length ? new HttpHeaders(headers) : new HttpHeaders();
  }

  /**
   * Build user related headers
   * @param user
   * @param headers
   */
  private buildUserHeaders(user: User, headers: APIHeaders<APIEndpoint> | string): void {
    if (user && user.email && user.email.address && user.email.address.length) {
      headers['x-user-email'] = user.email.address;
    }

    if (user && user.token && user.token.length) {
      headers['x-user-token'] = user.token;
    }
  }

  /**
   * Handle the error message
   * @param errRes
   */
  private handleError(errRes: HttpErrorResponse): Promise<Error> {
    let error;

    // This error type matches the v2 endpoint
    if (errRes && errRes.error && errRes.error.errors && errRes.error.errors.length) {
      const firstError = errRes.error.errors[0];

      // ! EU peak override to remove the discount code from error message
      // TODO: Remove after experiment is over, Have expressed that this should be on the BE
      const discountErrorExperiment = this.experimentsRunning.find(
        (exp: Experiment): boolean => exp.name === 'DISCOUNT_ERROR_CHANGE' && exp.variant === 1
      );

      if (discountErrorExperiment && firstError.new_discount_code === 'FMFFJ') {
        firstError.title = t('js.payment.discount-invalid.discountNotFound4.message');
        firstError.detail = undefined;
      }

      error = new Error(firstError);
      error.statusCode = errRes.status;
      if (firstError.new_discount_code) {
        error.meta.alternateDiscountCode = firstError.new_discount_code;
      }

      this.onErrorRaised$.next(error);
      this.gaService.trackError(error);
      return Promise.reject(error);
    }

    // HTTP Error
    if ('status' in errRes && errRes.statusText) {
      error = new Error({
        title: t('js.service.backend.network'),
        message: '',
        code: 'http'
      });
      error.statusCode = errRes.status;
      this.onErrorRaised$.next(error);
      this.gaService.trackError(error);
      return Promise.reject(error);
    }

    error = new Error({
      title: errRes.toString(),
      code: 'unknown'
    });
    error.statusCode = errRes.status;
    this.onErrorRaised$.next(error);
    this.gaService.trackError(error);
    return Promise.reject(error);
  }

  /**
   * Remove null or undefined from objects
   * https://stackoverflow.com/questions/23774231/how-do-i-remove-all-null-and-empty-string-values-from-a-json-object
   */
  private removeEmpty(obj: unknown): Headers {
    // This will manipulate the original object
    Object.entries(obj).forEach(
      ([key, val]): unknown => (val && typeof val === 'object' && this.removeEmpty(val)) || (val === undefined && delete obj[key])
    );

    // Order alphabetically
    const paramArr = Object.keys(obj)
      .sort()
      .map((key: string): Record<string, unknown> => ({ [key]: obj[key] }));

    return Object.assign({}, ...paramArr);
  }

  /**
   * Converts a url and params to a string, this is the same as the "quickloader"
   * @param url
   * @param params
   */
  private urlAsCacheKey(url: string, params: Params): string {
    const queryString = Object.keys(params)
      .filter((k: string): boolean => !!params[k] || params[k] === false)
      .map((k: string): string => `${k}=${params[k]}`)
      .sort()
      .join('&');

    return queryString ? [url, queryString].join('?') : url;
  }

  /**
   * Converts a list of experiments into a suitable params object for API calls
   * @param experiments
   */
  private experimentsAsParam(
    exps: Experiment[],
    experimentNames?: boolean | string[]
  ): {
    experiments?: string;
    variants?: string;
  } {
    const names = [];
    const variants = [];

    // Sort alphabetically by name (Clone first)
    let expmts = exps.slice().sort((a, b): 1 | -1 | 0 => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

    if (experimentNames && Array.isArray(experimentNames)) {
      expmts = expmts.filter((exp: Experiment): string => experimentNames.find((n: string): boolean => exp.name.indexOf(n) > -1));
    }

    expmts.forEach((experiment: Experiment): void => {
      if (experiment.variant > 0) {
        names.push(experiment.name.toUpperCase());
        variants.push(experiment.variant);
      }
    });

    return names.length ? { experiments: names.join(','), variants: variants.join(',') } : {};
  }

  /**
   * Format Pagination options from api response
   * @param jsonAPIResponse
   * @returns APIPaginationOptions
   */
  private formatPaginationOptions(jsonAPIResponse: APIPaginationResponse): APIPaginationOptions {
    const { page, link_params } = jsonAPIResponse.meta.pagination; // eslint-disable-line
    const { next, prev, href } = link_params;

    const options = {
      size: page.size,
      sort: href.page.sort,
      total: page.total
    };

    options['after'] = options.sort === 'desc' ? next?.page.after : prev?.page.after;
    options['before'] = options.sort === 'desc' ? prev?.page.before : next?.page.before;

    return options;
  }

  /**
   * Get the UTM as params to pass to the backend
   */
  private utmAsParam(): BWParams {
    const initial = this.stateService.getInitial().params;
    return Object.assign(
      {},
      initial.utm_medium ? { utm_medium: initial.utm_medium } : null,
      initial.utm_campaign ? { utm_campaign: initial.utm_campaign } : null,
      initial.utm_source ? { utm_source: initial.utm_source } : null
    );
  }

  /**
   * Create pagination params
   * @param params
   * @param options
   * @returns {BasicParams}
   */
  private paginationAsParams(params: BasicParams, options: APIPaginationOptions): BasicParams {
    const { before, after, sort, next, size } = options;
    const defaultParams = { ...params, 'page[size]': size, 'page[sort]': sort };
    let setPage = {};

    if (next) {
      setPage = options.sort === 'desc' ? { 'page[after]': after } : { 'page[before]': before };
    } else {
      setPage = options.sort === 'desc' ? { 'page[before]': before } : { 'page[after]': after };
    }
    return Object.assign(defaultParams, setPage);
  }

  /**
   * Create the set of URL parameters to send to the backend
   * @param options Create the params
   */
  private createParams(options: BackendOptionsType<unknown> = {}): BasicParams {
    // Incase a null value is sent we use an empty object
    const option = options ? options : {};

    let params: Params = {};
    params.locale = this.locale;

    if (this.serverParams) {
      params = Object.assign(params, this.serverParams);
    }
    if (option.sendUtmParams) {
      params = Object.assign(params, this.utmAsParam());
    }

    if (option.sendExperiments) {
      const expParams = this.experimentsAsParam(this.experimentsRunning, option.sendExperiments);
      params = Object.assign(params, expParams);
    }

    if (option.responseIsPaginate) {
      const paramsWithPagination = this.paginationAsParams(params, option.responseIsPaginate);
      params = Object.assign(params, paramsWithPagination);
    }

    if (option.params) {
      params = Object.assign(params, option.params);
    }

    params = this.removeEmpty(params);

    return params;
  }

  /**
   * Tidy the payload
   * @param payload
   */
  private tidyPayload(payload: unknown): unknown {
    return this.removeEmpty(payload);
  }

  /**
   * Get the backend/API Url
   */
  private getBackendUrl(): string {
    const prefix = environment.backendUrl.indexOf('/') === 0 ? `https://${this.locationService.getHost()}` : '';
    return `${prefix}${environment.backendUrl}`;
  }
}
