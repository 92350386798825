import { Injectable } from '@angular/core';
import { AddressSearchModelService, AddressSearchResult } from 'Shared/models/address-search-model.service';
import { Address } from 'Shared/classes/address';
import { Country } from 'Shared/classes/country';

@Injectable({
  providedIn: 'root'
})
export class AddressSearchService {
  constructor(private addressSearchModal: AddressSearchModelService) {}

  /**
   * Find an address within a given area
   * @param query
   * @param country
   * @param within
   */
  getResults(query: string, country: Country, within: string): Promise<AddressSearchResult[]> {
    return this.addressSearchModal.findAddress(query, country, within);
  }

  /**
   * Get an address given a 'containerID' as query
   * @param query
   */
  getAddress(query: string): Promise<Address> {
    return this.addressSearchModal.retrieveAddress(query);
  }
}
