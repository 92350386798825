import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';

@Directive({
  selector: '[bwStaticClick]'
})
export class StaticClickDirective {
  @Output() staticClick: EventEmitter<any> = new EventEmitter();
  threshhold: number = 40;
  startX: number;
  constructor(private el: ElementRef, private windowRef: WindowRefService) {}

  start(e: MouseEvent): void {
    this.startX = e.clientX;
  }

  end(e: MouseEvent): void {
    const endXDifference =
      e.clientX > this.startX ? e.clientX - this.startX : this.startX - e.clientX;
    if (endXDifference < this.threshhold) {
      this.click();
    }
    this.startX = null;
  }

  click(): void {
    this.staticClick.emit();
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(e): void {
    this.start(e);
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(e): void {
    this.start(e);
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(e): void {
    this.end(e);
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(e): void {
    this.end(e);
  }

  // Accessibility
  @HostListener('keyup', ['$event'])
  onKeyUp(e): void {
    // 13 = enter key
    if (
      e.keyCode === 13 &&
      this.windowRef.nativeWindow.document.activeElement === this.el.nativeElement
    ) {
      this.click();
    }
  }
}
