import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService, ToastrMessage } from 'Base/app/toastr/services/toastr.service';
import { ToastrAnimations } from 'Base/app/toastr/toastr.animations';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { PurchaseService } from 'Checkout/services/purchase.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-toastr',
  templateUrl: './toastr.component.html',
  animations: ToastrAnimations
})
export class ToastrComponent implements OnInit {
  @ViewChild('toastProgress')
  toastProgress: ElementRef;
  toastProgressAnimationClass: string = 'toastr__progress--animation-running';
  message: ToastrMessage;
  messageClosing: boolean = false;

  messageTimeoutActive: boolean = false;
  messageTimeout: any;
  easeTimeout: any;

  component: ToastrComponent = this;

  constructor(
    private toastrService: ToastrService,
    private analyticsService: AnalyticsService,
    private purchaseService: PurchaseService
  ) {}

  /**
   * Close Toastr
   */
  close(): Promise<void> {
    this.stopTimeout();
    this.messageClosing = true;

    // set message to hidden (triggering the animation)
    this.message.visible = false;

    return new Promise((resolve) => {
      clearTimeout(this.easeTimeout);
      // set timeout for length of animation
      this.easeTimeout = setTimeout(() => {
        this.message = undefined;
        this.messageClosing = false;
        resolve();
      }, this.message.config.easeTime);
    });
  }

  /**
   * On close button click
   * @returns
   */
  onCloseButtonClick(): Promise<void> {
    if (this.message.config.trackCloseButtonClick) {
      this.analyticsService.track('component.toaster.close', {
        label: this.message.message
      });

      this.analyticsService.trackInHeap('closeErrorMessage', {
        purchase: this.purchaseService.getPurchase(),
        error: {
          message: this.message.message
        }
      });
    }

    return this.manualClose();
  }

  /**
   * Manual close toastr
   */
  manualClose(): Promise<void> {
    // message close triggered by the user
    return this.close().then(() => {
      this.toastrService.next();
    });
  }

  /**
   * Stop toastr timeout
   */
  stopTimeout(): void {
    clearTimeout(this.messageTimeout);
    this.messageTimeoutActive = false;
  }

  /**
   * Start toastr timeout
   */
  startTimeout(): void {
    if (this.messageClosing) {
      // do nothing if the current message is closing
      return;
    }

    if (this.message.config.autoDismiss) {
      // set timeout for dismissal of message
      this.messageTimeout = setTimeout(() => {
        this.close().then(() => {
          this.toastrService.next();
        });
      }, this.message.config.timeOut);

      this.messageTimeoutActive = true;
    }
  }

  /**
   * Displaying message on toastr
   * @param message
   */
  displayMessage(message: ToastrMessage): void {
    this.message = message;

    if (message && message.config) {
      // only mark new message as visible if it has config
      this.message.visible = true;

      // needs to be wrapped in a timeout otherwise the animations stumble up
      setTimeout(() => {
        this.startTimeout();
      });
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.toastrService.message$.subscribe((message: ToastrMessage) => {
      if (message?.type.indexOf('feedback') > -1) {
        return;
      }

      if (this.message) {
        // if there's already a message, close then display new one
        this.close().then(() => {
          this.displayMessage(message);
        });
        return;
      }

      this.displayMessage(message);
    });
  }
}
