import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { StateService } from './state.service';
import { IModularProductCard } from '../interfaces/modular-product-card-content';
import { ExperimentsService } from './experiments.service';
import { Country, CountryService } from 'Shared/services/country.service';
import { ModularProductCard } from 'Shared/models/config-model.service.typings';
@Injectable({
  providedIn: 'root'
})
export class ModularProductCardService {
  activeExperimentVariant: number;
  originalContent: ModularProductCard[];
  modularProductCards: IModularProductCard[];

  constructor(
    private stateService: StateService,
    private configService: ConfigService,
    private expService: ExperimentsService,
    private countryService: CountryService
  ) {
    this.originalContent = this.configService.getConfig().modular_product_card_content || [];
  }

  /**
   * Filters the content by shipping country id
   * @returns ModularProductCard[]
   */
  filterContentFromShippingCountryId(): ModularProductCard[] {
    const content = this.originalContent.filter(
      (item) => item.shippingCountryId === this.countryService.forShipping.id
    );
    return content;
  }

  /**
   * Gets the under experiment content
   * @param ModularProductCard[]
   * @returns ModularProductCard[]
   */
  getUnderExperimentContent(content: ModularProductCard[]): ModularProductCard[] {
    const experimentalContent = content.filter((item) => {
      if (!item.isRolledOut && item.experiment) {
        return item.experiment.variant === this.expService.getVariantFor(item.experiment?.name);
      }
    });
    return experimentalContent;
  }

  /**
   * Gets the rolled out content
   * @param ModularProductCard[]
   * @returns ModularProductCard[]
   */
  getRolledOutContent(content: ModularProductCard[]): ModularProductCard[] {
    const rolledOutContent = content.filter((item) => item.isRolledOut);
    return rolledOutContent;
  }

  /**
   * Setup the modular product card
   * @returns IModularProductCard[]
   */
  setupModularProductCard(): IModularProductCard[] {
    const contentFilteredByShippingCountry = this.filterContentFromShippingCountryId();
    if (!contentFilteredByShippingCountry) {
      return;
    }

    const rolledOutContent = this.getRolledOutContent(contentFilteredByShippingCountry);
    const underExperimentContent = this.getUnderExperimentContent(contentFilteredByShippingCountry);
    const content = [...rolledOutContent, ...underExperimentContent];
    this.modularProductCards = this.getModularProductCardContentForGridType(content);
    return this.modularProductCards;
  }

  /**
   * Maps the content card data
   * @param ModularProductCard[]
   * @returns IModularProductCard[]
   */
  mapModularContentCardData(content: ModularProductCard[]): IModularProductCard[] {
    const currentState = this.stateService.getCurrent();
    return content.map((item) => {
      return {
        content: item.campaignContent,
        id: item.campaignId,
        name: item.campaignName,
        activePlacement: item.placement.find((p) => p?.gridTag === currentState?.url),
        allPlacements: item?.placement
      };
    });
  }

  /**
   * Gets the modular product card content for grid type
   * @param ModularProductCard[]
   * @returns IModularProductCard[]
   */
  getModularProductCardContentForGridType(content: ModularProductCard[]): IModularProductCard[] {
    const currentState = this.stateService.getCurrent();
    if (content) {
      const filteredContentByUrl = content.filter((campaign) =>
        campaign.placement.find((item) => item.gridTag === currentState.url)
      );
      if (filteredContentByUrl.length > 0) {
        return this.mapModularContentCardData(filteredContentByUrl);
      }
    }
  }
}
