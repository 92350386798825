import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { BwFormControl } from 'Shared/classes/bw-form';

export interface RadioOption {
  id: string | number;
  value: any;
  label: string;
  description?: string;
}

@Component({
  selector: 'bw-form-radio-input',
  templateUrl: './form-radio-input.component.html'
})

/* bw:view-encapsulation */
export class FormRadioInputComponent implements OnInit {
  @Input() bwFormControl: BwFormControl<any>;
  @Input() options: RadioOption[];
  @Input() size: 'small' | 'medium' | 'large' | 'xlarge' = 'medium';
  @Input() hideDescriptions: boolean = false;

  hasSubmitted: boolean = false;
  hasDescription: boolean = false;

  constructor() {}

  /**
   * Mark as submmitted
   */
  markAsSubmitted(): void {
    this.hasSubmitted = true;
  }

  /**
   * Init
   */
  ngOnInit(): void {
    this.hasDescription = !!(this.options || []).find((item) => item.description);
    this.bwFormControl['markAsSubmitted'] = () => {
      this.markAsSubmitted();
    };
  }
}
