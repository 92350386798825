import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { WindowRefService } from 'Shared/services/window.service';
import { ConfigService } from 'Shared/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class BingService {
  tagId: string = '';
  debug: boolean = false;
  window: any;
  initPromise: Promise<any>;
  serviceInitialized: boolean = false;

  constructor(
    private windowRef: WindowRefService,
    private domUtils: DomUtilsService,
    private configService: ConfigService
  ) {
    this.tagId = this.configService.getConfig().bingUetTagId;
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<bingUET>', ...args);
    }
  }

  /**
   * Run the bing Code
   * @param args
   */
  bingUET(): void {
    if (this.window['UET'] && this.serviceInitialized) {
      this.window['uetq'] = this.window['uetq'] || [];
      const obj = {
        ti: this.tagId,
        q: this.window['uetq']
      };
      this.window['uetq'] = new this.window['UET'](obj);
      this.window['uetq'].push('pageLoad');
      this.log('Tracked pageLoad event');
    }
  }

  /**
   * Track a page
   */
  trackPage(): void {
    this.bingUET();
  }

  /**
   * Init
   */
  init(): Promise<any> {
    this.initPromise =
      this.initPromise ||
      this.domUtils.loadScript('https://bat.bing.com/bat.js', 'bingUET').then(() => {
        this.serviceInitialized = true;
        this.bingUET();
      });

    return this.initPromise;
  }
}
