/**
 * Intercepts any content that has "data-consent" attribute
 *
 * Looks for a custom cookie message if the user didn't accept the cookies
 * The custom message must have a `data-cookiemessage` attribute with any value
 *
 * The html structure must be something to below
 *
 * `<div data-consent="marketing">
 *    HTML to be displayed if the user consented
 *
 *    <div data-cookiemessage="marketing">
 *      HTML to be displayed if NO consent
 *    <div>
 *
 *  </div>`
 *
 * If we need to trigger Cookiebot dialog, we can do
 * `<a onclick="Cookiebot.renew()">Click here</a>` in Contentful
 *
 */

export default function bwCookieConsentInterceptor(item: HTMLElement): any {
  const parentNode = item.parentNode;
  const consent = item.getAttribute('data-consent');
  const cookieMessage = item.querySelector('[data-cookiemessage]');
  if (cookieMessage) {
    item.removeChild(cookieMessage);
  }
  parentNode.removeChild(item);

  const element = document.createElement('bw-cookie-content');
  element.setAttribute('html', item.outerHTML);
  element.setAttribute('consent', consent);
  if (cookieMessage) {
    element.setAttribute('cookiemessage', cookieMessage.innerHTML);
  }
  parentNode.appendChild(element);

  return item;
}
