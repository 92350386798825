import {
  Directive,
  OnChanges,
  ChangeDetectorRef,
  ViewContainerRef,
  ComponentFactoryResolver,
  SimpleChange,
  Output,
  ComponentRef,
  EventEmitter,
  Input,
  ElementRef
} from '@angular/core';
import { PopoverInfoComponent } from 'Shared/components/popover-info/popover-info.component';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[popover]',
  exportAs: 'popover'
})
export class PopoverInfoDirective implements OnChanges {
  protected popoverContentComponent = PopoverInfoComponent;
  protected popover: ComponentRef<PopoverInfoComponent>;
  protected visible: boolean = false;
  // tslint:disable-next-line:no-input-rename
  @Input('popover') public content: string | PopoverInfoComponent;

  @Input() display: boolean = false;
  @Output() public shown = new EventEmitter<any>();
  @Output() public hidden = new EventEmitter<any>();

  constructor(
    protected viewContainerRef: ViewContainerRef,
    protected cdr: ChangeDetectorRef,
    protected resolver: ComponentFactoryResolver,
    public elRef: ElementRef
  ) {}
  public ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
    this.toggle(changes['display'].currentValue);
  }

  public toggle(visible: boolean): void {
    if (visible) {
      this.show();
    } else {
      this.hide();
    }
  }

  public show(): void {
    const popover = this.content as PopoverInfoComponent;
    popover.popover = this;
    popover.display = true;
    this.cdr.detectChanges();
    this.shown.emit(this);
  }

  public hide(): void {
    const popover = this.content as PopoverInfoComponent;
    popover.popover = this;
    popover.display = false;
    this.cdr.detectChanges();
    this.hidden.emit(this);
  }
}
