import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Address } from 'Shared/classes/address';
import { CountryService } from 'Shared/services/country.service';
import { Country } from 'Shared/classes/country';
import { ConfigService } from 'Shared/services/config.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { t } from 'Shared/utils/translations';
import { FeaturesService } from 'Shared/services/features.service';
import { ShippingOption } from 'Shared/classes/shipping-option';
import { ValidateRecipientSearch } from 'Checkout/validators/validateRecipientSearch';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-address-form',
  templateUrl: './address-form.component.html'
})
export class AddressFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  address: Address;
  @Input()
  parentForm: FormGroup;
  @Input()
  limitCountry: Country;
  @Input() shippingOption?: ShippingOption;
  @Input() hideNameField: boolean = false;
  @Input() showAddressPhone?: boolean;
  @Output()
  countryChanged: EventEmitter<any> = new EventEmitter();
  @Output()
  addressChanged: EventEmitter<any> = new EventEmitter();

  @Input() formSubmitted: boolean;

  countries: Country[];
  dropdown: any;
  telephonePlaceholderPrefix: string = '';
  form: FormGroup = new FormGroup({});
  showDoorCodeAsLine2Label: boolean = false;
  postcodeLength: number;
  addressPhoneRequired: boolean;
  showAddressPostcodeAltCopy: boolean;

  defaultPhoneValidators = [
    Validators.pattern(/^[- +()]*[0-9][- +()0-9]*$/),
    Validators.minLength(7),
    Validators.maxLength(25)
  ];

  constructor(
    private countryService: CountryService,
    public experimentService: ExperimentsService,
    private analyticsService: AnalyticsService,
    private featureService: FeaturesService
  ) {
    this.countries = this.countryService.getCountries();

    this.postcodeLength = this.featureService.getFeature('ADDRESS_FIELDS').postcodeLength;

    const nameControl = new FormControl('', {
      validators: [Validators.required]
    });

    const companyControl = new FormControl('', {});

    const line1Control = new FormControl('', {
      validators: [Validators.required]
    });

    const line2Control = new FormControl('', {});

    const cityControl = new FormControl('', {
      validators: [Validators.required]
    });

    const postcodeControl = new FormControl('', {
      validators: [Validators.required]
    });

    const countryControl = new FormControl('', {
      validators: [Validators.required]
    });

    const phoneControl = new FormControl('');

    const phoneValidators = [Validators.minLength(7), Validators.maxLength(25)];
    phoneControl.setValidators(phoneValidators);

    this.form.addControl('name', nameControl);
    this.form.addControl('company', companyControl);
    this.form.addControl('line1', line1Control);
    this.form.addControl('line2', line2Control);
    this.form.addControl('city', cityControl);
    this.form.addControl('postcode', postcodeControl);
    this.form.addControl('country', countryControl);
    this.form.addControl('phone', phoneControl);
  }

  phoneBlur(): void {
    if (this.form.get('phone').valid) {
      this.analyticsService.track('checkout.deliveryDetails.phoneWasAdded');
    }
  }

  /**
   * On field blur - create a new address and pass it back up
   */
  onFieldBlur(resetPcaID: boolean = true): void {
    const addr = new Address();
    addr.country = this.address.country;
    addr.name = this.form.get('name').value;
    addr.company = this.form.get('company').value;
    addr.line1 = this.form.get('line1').value;
    addr.line2 = this.form.get('line2').value;
    addr.city = this.form.get('city').value;
    addr.postcode = (this.form.get('postcode').value || '').toUpperCase();
    addr.pcaID = resetPcaID ? undefined : this.address.pcaID;
    addr.phone = this.form.get('phone').value;
    this.address = addr;

    if (this.form.get('postcode').valid) {
      this.addressChanged.emit(this.address);
    }
  }

  /**
   * Dynamic set validators depending on the country
   * @param country
   */
  setValidatorsBasedOnCountry(country: Country): void {
    const countryConfig = this.featureService.getFeature('ADDRESS_FIELDS', country);
    const validations = this.defaultPhoneValidators.slice();

    if (this.showAddressPhone !== false) {
      this.showAddressPhone =
        (this.shippingOption && this.shippingOption.hasPhoneNumber) ||
        (this.shippingOption && this.shippingOption.hasPhoneNumberRequired) ||
        countryConfig.showAddressPhone;
      this.addressPhoneRequired = this.shippingOption && this.shippingOption.hasPhoneNumberRequired;
    }

    if (this.shippingOption && this.shippingOption.hasPhoneNumberRequired) {
      validations.push(ValidateRecipientSearch.requirePhoneForShippingOption(this.shippingOption));
    }

    this.form.get('phone').setValidators(validations);

    this.showDoorCodeAsLine2Label = !!countryConfig.showDoorCodeAsLine2Label;

    this.telephonePlaceholderPrefix = `+${country.phonePrefix}`;

    const nameValidators = [Validators.required];
    if (countryConfig.firstAndSecondNameRequired) {
      nameValidators.push(Validators.pattern(/\S+\s\S+/));
    }
    const formControl = this.form.get('name');
    formControl.setValidators(nameValidators);
    formControl.updateValueAndValidity();

    const postcodeControl = this.form.get('postcode');

    const postcodeValidators = [];

    if (!countryConfig.ignorePostcodeValidation) {
      postcodeValidators.push(Validators.pattern(new RegExp(country.address.postcodeRegex)));
    }

    if (countryConfig.postcodeLength && countryConfig.postcodeLength > -1) {
      postcodeValidators.push(Validators.minLength(countryConfig.postcodeLength));
      postcodeValidators.push(Validators.maxLength(countryConfig.postcodeLength));
    }

    if (!countryConfig.postcodeOptional) {
      postcodeValidators.push(Validators.required);
    }
    postcodeControl.setValidators(postcodeValidators);
    postcodeControl.updateValueAndValidity();

    // used for Ireland label (uses eircode and not postcode)
    this.showAddressPostcodeAltCopy = countryConfig.showAddressPostcodeAltCopy;

    this.form.updateValueAndValidity();
  }

  /**
   * Select a country
   * @param country
   */
  selectCountry(country: Country): void {
    this.address.country = country;
    this.setValidatorsBasedOnCountry(country);

    this.countryChanged.emit(country);
    this.onFieldBlur();
  }

  /**
   * On destroy, remove from parent form
   */
  ngOnDestroy(): void {
    this.parentForm.removeControl('addressForm');
  }

  getTranslationString(key): string {
    const trans = t(key);
    return trans;
  }

  getTranslationStringLength(key): number {
    return this.getTranslationString(key).length;
  }

  /**
   * Ng on init
   */
  ngOnInit(): void {
    this.address = this.address || new Address();

    this.fillForm();

    this.fillPhoneField();

    // Add to the parent form
    this.parentForm.setControl('addressForm', this.form);
    this.form.updateValueAndValidity();

    this.countries = this.limitCountry ? [this.limitCountry] : this.countries;

    this.address.country = this.address.country || this.countries[0];
    this.form.get('country').setValue(this.address.country);
    this.setValidatorsBasedOnCountry(this.address.country);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shippingOption) {
      this.setValidatorsBasedOnCountry(this.address.country);
    }
  }

  /**
   * Fill the form fields with predefined values
   */
  private fillForm() {
    ['name', 'company', 'line1', 'line2', 'city', 'postcode', 'phone'].forEach((key) => {
      if (this.address[key]) {
        this.form.get(key).setValue(this.address[key]);
      }
      this.form.get(key).markAsTouched();
    });
  }

  /**
   * Fill Phone field if form gets a predefined  one
   */
  private fillPhoneField() {
    const phone = this.parentForm.get('phone')?.value ?? undefined;

    if (phone && !this.form.get('phone').value) {
      this.form.get('phone').setValue(phone);
      this.form.get('phone').markAsTouched();
    }
  }
}
