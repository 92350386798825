import { Component, Input, OnInit } from '@angular/core';
import { Order } from 'Shared/classes/order';

@Component({
  selector: 'bw-purchase-basket-discount-message',
  templateUrl: './purchase-basket-discount-message.component.html',
  styleUrls: ['./purchase-basket-discount-message.component.scss']
})
export class PurchaseBasketDiscountMessageComponent implements OnInit {
  @Input() order: Order;

  message: string = '';

  /**
   * On init, set the message
   */
  ngOnInit(): void {
    if (this.order.applicableRulesDescription) {
      this.message = this.order.applicableRulesDescription;
    }
  }
}
