/**
 * Intercepts bw-slideshow and extracts the data from each bw-slide attributes
 * @param item
 * The structure in Contentful should be as below
 * <bw-slideshow>
    <bw-slide
      type="image" // or 'video' - mandatory
      source="https://images.ctfassets.net/template2-mobile2.jpg" // or video id - mandatory
      device="mobile" // mandatory
      alt="Some description" // nice to have
      primaryctatext="Shop now"
      primaryctaurl="/send-flowers"
      secondaryctatext="Secondary CTA"
      secondaryctaurl="/send-flowers"
      template="copy-led" // id needed
      duration="5" // nice to have for videos
      backgroundcolor="#EBE3FB" //HEX to be used with copy-led template
    >
      <p>Copy to be displayed with the image</p>
    </bw-slide>
 * </bw-slideshow>
 */
export default function bwSlideshowInterceptor(slideshow): any {
  const slides = [].slice.call(slideshow.querySelectorAll('bw-slide')).map(slide => {
    return {
      type: slide.getAttribute('type'),
      source: slide.getAttribute('source'),
      firstFrame: slide.getAttribute('firstframe') || undefined,
      device: slide.getAttribute('device'),
      alt: slide.getAttribute('alt'),
      primaryCtaText: slide.getAttribute('primaryctatext') || undefined,
      primaryCtaUrl: slide.getAttribute('primaryctaurl') || undefined,
      secondaryCtaText: slide.getAttribute('secondaryctatext') || undefined,
      secondaryCtaUrl: slide.getAttribute('secondaryctaurl') || undefined,
      duration: parseFloat(slide.getAttribute('duration')) || undefined,
      template: slide.getAttribute('template') || 'default',
      backgroundColor: slide.getAttribute('backgroundcolor'),
      content: slide.innerHTML || ''
    };
  });

  // Then delete all the insides, leaving just the component and the attribute data
  slideshow.innerHTML = '';
  slideshow.setAttribute('data', JSON.stringify(slides));

  return slideshow;
}
