import * as dayjs from 'dayjs';

import { Component, OnInit } from '@angular/core';
import { ConfigModelService } from 'Shared/models/config-model.service';
import { environment } from 'Environments/environment';
import { t } from 'Shared/utils/translations';
import { SettingsService } from 'Shared/services/settings.service';
import { CookieService } from 'Shared/services/third-parties/cookie.service';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';
import { FeaturesService } from 'Shared/services/features.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  footerSections: any[] = [];
  today: dayjs.Dayjs = dayjs(new Date());
  openedSection: number = -1;
  environment: any;
  t: any;
  bCorpLogoImage: string;
  bCorpLogoStyle: string;
  bCorpLogoActive: boolean;

  constructor(
    private optimizelyService: OptimizelyService,
    private config: ConfigModelService,
    private settingsService: SettingsService,
    private cookieService: CookieService,
    private featureService: FeaturesService
  ) {
    // both used in the template
    this.environment = environment;
    this.t = t;
  }

  toggleOpenSection(index: number): void {
    this.openedSection = this.openedSection === index ? -1 : index;
  }

  renewCookieConsent(): void {
    this.cookieService.renewConsent();
  }

  ngOnInit(): Promise<any> {
    this.bCorpLogoImage = this.featureService.getFeature('B_CORP').footerLogoImage ?? '';
    this.bCorpLogoStyle = this.featureService.getFeature('B_CORP').footerLogoStyle ?? '';
    this.bCorpLogoActive = this.featureService.getFeature('B_CORP').footerLogoActive ?? false;

    const attribute = { key: 'funnel_start_point', value: 'footer' };
    this.optimizelyService.decide('hpt42_bw_uk_web_klarna', attribute);
    this.optimizelyService.decide('new_klarna_payment_options', attribute);
    return this.config.hasRemoteConfig().then((config) => {
      this.footerSections = this.settingsService.getSetting('FOOTER_LINKS').data || [];
    });
  }
}
