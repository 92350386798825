import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { User } from 'Shared/classes/user';
import { UserService } from 'Shared/services/user.service';
import { TicketService } from 'Shared/services/ticket.service';
import { FileService } from 'Shared/services/file.service';
import { Order } from 'Shared/classes/order';
import { t } from 'Shared/utils/translations';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-subscription-cancel-form',
  templateUrl: './subscription-cancel-form.component.html'
})
export class SubscriptionCancelFormComponent implements OnInit {
  loading: boolean;
  success: boolean;
  formSubmitted: boolean;
  reasonHelper: any;
  reasons: Reason[] = [];

  form: FormGroup;
  fileUpload: any;
  user: User;
  orderPrefilled: boolean = false;
  files: any[];
  s3Location: string = 'bw-contact-uploads';

  characterLimit: number;
  textCounter: number;
  defaultReason: string;
  kind: string = 'Subscription cancelation request';
  showOtherReason: boolean;

  @Input() defaultOrder: Order;

  constructor(
    private toastr: ToastrService,
    private ticketService: TicketService,
    private fileService: FileService,
    private userService: UserService
  ) {}

  onMessageTextChange(): void {
    this.textCounter = (this.form.get('question').value || '').length;
  }

  onFilesChanged(files: any[]): void {
    this.files = files;
  }

  updateUserFields(): void {
    this.orderPrefilled = false;

    const userDetails = {
      email: '',
      name: ''
    };

    if (this.user && this.user.isLoggedIn()) {
      userDetails.email = this.user.email.address || '';
      userDetails.name = this.user.fullName || '';
    }

    this.form.get('email').setValue(userDetails.email);
    this.form.get('fullName').setValue(userDetails.name);

    if (!this.user || !this.user.isLoggedIn()) {
      this.form.get('order').setValue('');
      this.form.get('postCode').setValue('');
      this.orderPrefilled = false;
    }
  }

  /**
   * Select the reason of cancelation
   * @param reason
   */
  selectReason(reason: Reason): void {
    if (!reason || !reason.title) {
      return;
    }
    this.showOtherReason = reason.showOtherReason;

    this.form.get('description').setValue(reason.title);
  }

  /**
   * Gathers data from the uploaded file
   * @param {Object} data
   */
  fileUploaded(data): void {
    if (!data.target.files.length) {
      return;
    }
    this.fileUpload = data.target.files;
  }

  /**
   * Submit the form
   * @param {Form} form
   * @returns {Promise}
   */
  submitForm(): Promise<any> {
    this.formSubmitted = true;

    if (!this.form.valid) {
      return Promise.resolve('form invalid');
    }
    this.loading = true;

    let p: Promise<any> = Promise.resolve();

    // Upload all the files, if one fails, stop
    if (this.files) {
      const promiseArr = this.files.map((file) =>
        this.fileService.uploadFile(this.s3Location, file)
      );
      p = p.then(() => Promise.all(promiseArr));
    }

    return p
      .then((fileNames) => {
        const payload = this.form.value;
        payload.fileNames = fileNames && fileNames.length ? fileNames : undefined;
        return this.ticketService.createTicket(payload);
      })
      .then(() => {
        this.success = true;
        this.loading = false;
      })
      .catch((err) => {
        this.toastr.error(err.message, err.title);
        this.loading = false;
      });
  }

  /**
   * Ng on init
   */
  ngOnInit(): void {
    this.formSubmitted = false;
    this.loading = false;
    this.characterLimit = 1000;
    this.textCounter = 0;
    this.setupReasons();
    this.form = new FormGroup({
      reason: new FormControl(this.kind || ''),
      description: new FormControl(this.defaultReason || '', { validators: [Validators.required] }),
      question: new FormControl('', {}),
      file: new FormControl('', {}),
      postCode: new FormControl('', {}),
      order: new FormControl('', {}),
      fullName: new FormControl('', {}),
      email: new FormControl('', {
        validators: [Validators.required, Validators.email]
      })
    });

    this.userService.user$.subscribe((user) => {
      if (this.user !== user) {
        this.user = user;
        this.updateUserFields();
      }
    });

    this.updateUserDefaultFields();
  }

  private updateUserDefaultFields(): void {
    if (this.defaultOrder) {
      this.form.get('order').setValue(this.defaultOrder.id);
      this.form.get('postCode').setValue(this.defaultOrder.address.postcode);
    }
  }

  private setupReasons(): void {
    this.reasons = [
      { index: 0, title: t('js.components.subscrition-cancel.reason0'), showOtherReason: true },
      { index: 1, title: t('js.components.subscrition-cancel.reason1'), showOtherReason: true },
      { index: 2, title: t('js.components.subscrition-cancel.reason2'), showOtherReason: false },
      { index: 3, title: t('js.components.subscrition-cancel.reason3'), showOtherReason: false },
      { index: 4, title: t('js.components.subscrition-cancel.reason4'), showOtherReason: false },
      { index: 5, title: t('js.components.subscrition-cancel.reason5'), showOtherReason: false },
      { index: 6, title: t('js.components.subscrition-cancel.reason6'), showOtherReason: false },
      { index: 7, title: t('js.components.subscrition-cancel.reason7'), showOtherReason: false },
      { index: 8, title: t('js.components.subscrition-cancel.reason8'), showOtherReason: false },
      { index: 9, title: t('js.components.subscrition-cancel.reason9'), showOtherReason: true }
    ];
  }
}

interface Reason {
  index: number;
  title: string;
  showOtherReason: boolean;
}
