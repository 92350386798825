
<div class="file" *ngFor="let file of files; let idx = index">
  <div class="file__input">
    <input type="file" name="file" [attr.accept]="acceptedFileTypes" (change)="fileSelected($event, file)">
    <!-- This will trigger the input file picker above-->
    <div class="input__trigger"><a class="bwLink under" bwTrackAs="components.form_file_upload.select">+ Select file to upload<img src="/assets/brand-icons/upload.svg"></a></div>
    <!-- Will be placed ontop-->
    <div class="file__name" *ngIf="file.name?.length">
      <div class="file__progress" [ngClass]="{'has-error': file.uploadError}" [ngStyle]="{'width':(file.uploadProgress * 100) + '%'}"></div>
      <div class="name__name"><a class="action__retry bwLink under" (click)="retryUpload(file)" *ngIf="file.uploadError" bwTrackAs="components.form_file_upload.retry">Retry</a><span *ngIf="file.uploadError">{{file.uploadError.message}} -</span><span>{{file.name}}</span></div>
      <div class="file__success-tick" *ngIf="file.uploadProgress &gt; 0.9"></div>
    </div>
  </div>
  <div class="file__action"><a class="action__remove bwLink under" *ngIf="file.name" (click)="removeFile(idx)" bwTrackAs="components.form_file_upload.remove">Remove</a></div>
</div>