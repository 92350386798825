import { Component, OnInit } from '@angular/core';
import { StateName, StateService } from 'Shared/services/state.service';
import { ModalComponent, ModalService } from 'Shared/services/modal.service';
import { UserService, User } from 'Shared/services/user.service';
import { AuthModalComponent } from 'Shared/components/modals/auth-modal/auth-modal.component';
import { Purchase, PurchaseService } from 'Checkout/services/purchase.service';
import { ConfirmModalComponent, ConfirmType } from 'Shared/components/modals/confirm-modal/confirm-modal.component';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { t } from 'Shared/utils/translations';
import { NavContentsModelService } from 'Shared/models/nav-contents-model.service';
import { CountryService } from 'Shared/services/country.service';
import { NavItem } from 'Shared/classes/nav-item';
import { ContentService } from 'Shared/services/content.service';
import { FavouritesService } from 'Shared/services/favourites.service';
import { FeaturesService } from 'Shared/services/features.service';

type ModalResolveData = unknown;
type ModalRejectData = unknown;

/* bw:view-encapsulation */
@Component({
  selector: 'bw-account-burger-menu',
  templateUrl: './account-burger-menu.component.html'
})
export class AccountBurgerMenuComponent extends ModalComponent<ModalResolveData, ModalRejectData> implements OnInit {
  user: User;
  burgerLinks: NavItem[];
  site: string;
  isUserWithSingleSubscription: boolean;
  userSubscriptions: number[];
  userCancelledSubscriptions: number[];
  showManageSubscription: boolean;
  mySubscriptionParamToRedirect: number;
  hasOnlyActiveSubs: boolean;

  constructor(
    private userService: UserService,
    private modalService: ModalService,
    private stateService: StateService,
    private purchaseService: PurchaseService,
    private toastr: ToastrService,
    private navContents: NavContentsModelService,
    private countryService: CountryService,
    private contentService: ContentService,
    private favouritesService: FavouritesService,
    private featuresService: FeaturesService
  ) {
    super();
  }

  /**
   * If rewards feature is active
   */
  get isRewardsEnabled(): boolean {
    return this.featuresService.getFeature('REWARDS');
  }

  /**
   * No success on this one
   */
  onSuccess(): void {
    super.closeAsResolve({});
  }

  /**
   * On cancel
   */
  onCancel(): void {
    super.closeAsReject({});
  }

  /**
   * Go to a state
   * @param state
   */
  goTo(state: StateName | string): void {
    this.stateService.go(state);
  }

  /**
   * go to URL
   * @param url
   */
  goToUrl(url: string): void {
    this.stateService.goToUrl(url);
  }

  /**
   * Confirm logout
   * @param purchase
   */
  confirmLogout(purchase: Purchase): Promise<unknown> {
    if (!purchase.orders.length) {
      return Promise.resolve();
    }

    const bodyKey =
      purchase.orders.length === 1
        ? 'js.components.burger-menu.confirm-logout.bodySingle'
        : 'js.components.burger-menu.confirm-logout.bodyMultiple';
    const initialState = {
      title: t('js.components.burger-menu.confirm-logout.title'),
      body: t(bodyKey, purchase.orders.length),
      successText: t('js.components.burger-menu.confirm-logout.success'),
      cancelText: t('js.components.burger-menu.confirm-logout.cancel'),
      type: 'danger' as ConfirmType
    };
    return this.modalService.show(ConfirmModalComponent, { initialState });
  }

  /**
   * Redirect if account route, or reload, or do nothing
   * @param orReload
   */
  onAccountRoute(): boolean {
    const stateName = this.stateService.getCurrent().name as string;
    const accountStates = [
      'ordersView',
      'applyCreditCode',
      'subscriptionDetails',
      'subscriptionNextDelivery',
      'subscription.prepay'
    ];

    return stateName.indexOf('account') > -1 || !!accountStates.find((element): boolean => element === stateName);
  }

  /**
   * Logout
   */
  logout(): Promise<void> {
    this.onCancel();
    const purchase = this.purchaseService.getPurchase();

    return this.confirmLogout(purchase)
      .then((): void => {
        this.purchaseService.reset();
        this.userService.logout();
        this.favouritesService.updateCurrentshoppableFavourites([]);
        this.contentService.refreshSegments();
        this.toastr.success(t('js.components.burger-menu.logout.confirm'));
        if (this.onAccountRoute()) {
          this.stateService.go('homepage' as StateName);
        } else {
          this.stateService.reload();
        }
        this.onCancel();
      })
      .catch((): void => {
        if (this.onAccountRoute()) {
          this.stateService.go('homepage' as StateName);
        }
        this.onCancel();
      });
  }

  /**
   * Show the login modal
   */
  login(): Promise<void> {
    this.onCancel();
    return this.modalService
      .show(AuthModalComponent, {
        initialState: { origin: 'modal', fullOrigin: 'webAccountNav' },
        trackingKey: 'auth-modal-burger-menu'
      })
      .then((): void => {
        this.onCancel();
      });
  }

  /**
   * On init
   */
  ngOnInit(): Promise<void> {
    this.userService.user$.subscribe((user): User => (this.user = user));
    this.setupMySubscriptionButton();
    return this.navContents.getBurgerLinks(this.countryService.forShipping).then((burgerLinks: BurgerItem): void => {
      this.burgerLinks = burgerLinks?.links;
    });
  }

  /**
   * Set up My Subscription button
   * If user has an active susbcrition button will redirect to Subscription details page
   * If user has more active Subscriptions button will redirect to My orders page
   * If user has no active Subscriptions and 1(ONE) Cancelled Subscription button will redirect to Subscription details page
   * If user has no active Subscriptions and multiple Cancelled Subscriptions button will redirect to My orders page
   * If user has no active or cancelled Subscriptions button is hidded
   */
  private setupMySubscriptionButton(): void {
    this.userSubscriptions = this.user?.subscriptionOrderIds;
    this.userCancelledSubscriptions = this.user?.subscriptionOrderCancelledIds;
    this.hasOnlyActiveSubs = this.userSubscriptions?.length > 0 && this.userCancelledSubscriptions?.length === 0;
    // Active Subscriptions have priority in logic and display
    this.isUserWithSingleSubscription =
      this.userSubscriptions?.length === 1 || (this.userSubscriptions?.length === 0 && this.userCancelledSubscriptions?.length === 1);

    // To display the button user has to have at least an active or cancelled Subscription
    this.showManageSubscription = !!this.userSubscriptions?.length || !!this.userCancelledSubscriptions?.length;

    if (this.showManageSubscription && this.isUserWithSingleSubscription) {
      // If no active Subscription found choose the Cancelled Subscription
      this.mySubscriptionParamToRedirect = this.userSubscriptions[0] ?? this.userCancelledSubscriptions[0];
    }
  }
}

export interface BurgerItem {
  links: NavItem[];
}
