import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ContentfulFormService } from 'Content/services/contentful-form.service';
import { WindowRefService } from 'Shared/services/window.service';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { FileService } from 'Shared/services/file.service';
import { File } from 'Shared/classes/file';
import { LocationService } from 'Shared/services/location.service';
import { CountryService } from 'Shared/services/country.service';
import { BwForm } from 'Shared/classes/bw-form';
import { BwButtonSize } from 'Project/shared/components/button/button';

type OptOutType = 'mothersDay' | 'fathersDay' | 'valentinesDay' | 'grandparentsDay' | 'grandmothersDay' | 'UnknownType';

@Component({
  selector: 'bw-contentful-form',
  templateUrl: './contentful-form.component.html'
})
export class ContentfulFormComponent extends BwForm<any> implements OnInit, OnDestroy {
  @Input() structure: string;

  contentfulForm: FormGroup;
  shippingCountryId: number;
  success: boolean = false;
  loading: boolean = false;
  successMessage: string;
  buttonText: string;
  buttonSize: BwButtonSize = 'medium';
  key: string;
  awsS3Bucket = 'bw-form-uploads';
  files: {
    [key: string]: File[];
  } = {};

  fieldsStructure: any;

  optOutForm: OptOutType = null;

  constructor(
    private contentfulFormService: ContentfulFormService,
    private windowRefService: WindowRefService,
    private fileService: FileService,
    private toastr: ToastrService,
    private locationService: LocationService,
    private countryService: CountryService
  ) {
    super();
  }

  /**
   * On Init
   */
  ngOnInit(): void {
    this.buttonText = this['button-text'];
    this.successMessage = this['success-message'];
    this.buttonSize = this['button-size'] ? this['button-size'] : this.buttonSize;
    this.fieldsStructure = JSON.parse(this.structure || '[]');

    this.countryService.forShipping$.subscribe((country): void => {
      this.shippingCountryId = country.id;
    });
    this.setOptoutFeature();
    super.ngOnInit();
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * Get Object
   * @returns
   */
  getObject(): unknown {
    return this.formGroup.value;
  }

  /**
   * Set object
   * @param args
   */
  setObject(...args: any): void {}

  /**
   * Build Form
   * @returns
   */
  buildForm(): Partial<any> {
    const form = {};

    this.fieldsStructure.forEach((field): void => {
      const validators = [];
      if (field.required) {
        validators.push(field.type === 'checkbox' ? Validators.requiredTrue : Validators.required);
      }

      if (field.maxLength) {
        validators.push(Validators.maxLength(field.maxLength));
      }

      if (field.type === 'email') {
        validators.push(Validators.email);
      }
      form[field.name] = new FormControl('', validators);
    });
    return form;
  }

  onFilesChanged($event: File[], fieldName: string): void {
    this.files[fieldName] = $event;
  }

  extraData(): any {
    const consentTexts = [].slice.call(
      this.windowRefService.nativeWindow.document.querySelectorAll('bw-form-checkbox-input > label > div.checkbox-input__label > span')
    );

    if (consentTexts.length) {
      return {
        consent_content: consentTexts.map((label: { innerText: any }) => label.innerText).join('|'),
        consent_location: `Web - Form - ${this.windowRefService.nativeWindow.document.location.href}`,
        consent_method: 'Ticked Checkbox'
      };
    }

    return {};
  }
  /* eslint-disable no-useless-escape */
  slugify(text: string): string {
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '_') // Replace spaces with _
      .replace(/&/g, '_and_') // Replace & with 'and'
      .replace(/[^\w\_\-]+/g, '') // Remove all non-word chars
      .replace(/\_\_+/g, '_'); // Replace multiple - with single -
  }

  /**
   * Get the data from the url
   */
  getUrlData(): { [key: string]: string } {
    const params = this.locationService.getCurrentParams() || {};

    const urlFormValues = Object.keys(params)
      .filter((key): boolean => key.indexOf('form_') === 0)
      .map((key): { [x: string]: any } => ({ [key.replace('emailSlug', 'slug').replace('form_', '')]: params[key] }));

    return Object.assign({}, ...urlFormValues);
  }

  /**
   * Submit
   * @returns
   */
  submitForm(): Promise<any> {
    this.markAsSubmitted();

    if (this.formGroup.invalid) {
      return;
    }

    this.loading = true;

    const formData = Object.assign(this.getObject(), this.getUrlData(), this.extraData());
    let p = Promise.resolve();

    // TODO: REMOVE AFTER COVID - Hack
    if (this.key && this.key.indexOf('covid-form') > -1) {
      this.key = `covid-form-${this.shippingCountryId}`;
    }

    // Create a promise chain to upload each of the files, then append to form data if upload
    const hasFiles = Object.keys(this.files).find((k): boolean => this.files[k] && this.files[k].length > 0);
    if (hasFiles) {
      Object.keys(this.files).forEach((key): void => {
        this.files[key].forEach((file): void => {
          p = p
            .then((): Promise<any> => this.fileService.uploadFile(this.awsS3Bucket, file))
            .then((fileName): void => {
              formData[key] = formData[key] || [];
              formData[key].push(`s3://${this.awsS3Bucket}/${fileName}`);
            });
          // If this causes an error, the form will catch
        });
      });
    }

    return p
      .then((): { [key: string]: string } => {
        // Tidy up the data - after the files have been potentially uploaded
        const dataToSend: {
          [key: string]: string;
        } = {};

        Object.keys(formData).forEach((key): void => {
          const k = this.slugify(key);
          dataToSend[k] = formData[key];
        });
        return dataToSend;
      })
      .then((dataToSend): Promise<any> => this.contentfulFormService.submitForm(dataToSend, this.key))
      .then((): void => {
        this.loading = false;
        this.success = true;
      })
      .catch((error): void => {
        this.loading = false;
        this.success = false;
        this.toastr.error(error.message);
      });
  }

  /**
   * Set up OptOut form
   * @returns
   */
  private setOptoutFeature(): void {
    // Opt out form
    if (this.key && this.key.indexOf('opt_out') > -1) {
      this.optOutForm = (this.key.split(':')[1] as OptOutType) ?? 'UnknownType';
      this.loading = false;
      return;
    }
  }
}
