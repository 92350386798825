import { Injectable } from '@angular/core';
import { Address } from 'Shared/classes/address';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'Shared/services/config.service';
import { CountryService } from 'Shared/services/country.service';
import { Country } from 'Shared/classes/country';
import { lastValueFrom } from 'rxjs';
import { FeaturesService } from 'Shared/services/features.service';

export interface AddressSearchResult {
  Id: string;
  Type: string;
  Text: string;
  Highlight: string;
  Description: string;
  roadNameWithNum: string;
  roadName: string;
  company: string;
}

interface LoqateFindAddressApiResponse {
  Items: AddressSearchResult[];
}

interface LoqateRetrieveAddressApiResponse {
  Items: Record<string, string>[];
}

@Injectable({
  providedIn: 'root'
})
export class AddressSearchModelService {
  private serverUrl: string;
  private loqateApiKey: string;
  private loqateAlternativeApiKey: string;
  private baseOptions: { Language: string; Origin: string; Limit: string };

  /**
   * Constructor
   * @param http
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private countriesService: CountryService,
    private featuresService: FeaturesService
  ) {
    const config = this.configService.getConfig();
    this.serverUrl = config.addressSearchUrl;
    this.loqateApiKey = config.loqateApiKey;
    this.loqateAlternativeApiKey = config.loqateAlternativeApiKey;
    this.baseOptions = {
      Language: config.locale,
      Origin: config.country,
      Limit: '7'
    };
  }

  /**
   * Find an address
   * @param search
   * @param containerId - Narrows down search to an area
   */
  findAddress(search: string, country: Country, containerId: string = ''): Promise<AddressSearchResult[]> {
    // ! feature flag used to handle if alternative key is needed to be used as bandw site has different shipping countries on single site
    const apiKey = this.featuresService.getFeature('USE_LOQATE_ALTERNATIVE_KEY') ? this.loqateAlternativeApiKey : this.loqateApiKey;
    const findUrl = `/Capture/Interactive/Find/v1.00/json3ex.ws?Key=${apiKey}`;
    const requestUrl = `${this.serverUrl}${findUrl}`;
    const countryCodes = country.codes;

    return lastValueFrom(
      this.http.get(requestUrl, {
        params: Object.assign(this.baseOptions, {
          Countries: countryCodes.join(','),
          Text: search,
          Container: containerId
        })
      })
    ).then((r: LoqateFindAddressApiResponse): AddressSearchResult[] => {
      const addresses = r?.['Items'] ?? [];
      return !addresses || !addresses[0] || addresses[0]['Error'] ? [] : addresses;
    });
  }

  /**
   * Retrieve an address
   * @param containerId
   */
  retrieveAddress(containerId: string): Promise<Address> {
    // ! feature flag used to handle if alternative key is needed to be used as bandw site has different shipping countries on single site
    const apiKey = this.featuresService.getFeature('USE_LOQATE_ALTERNATIVE_KEY') ? this.loqateAlternativeApiKey : this.loqateApiKey;
    const findUrl = `/Capture/Interactive/Retrieve/v1.00/json3ex.ws?Key=${apiKey}`;
    const requestUrl = `${this.serverUrl}${findUrl}`;
    return lastValueFrom(
      this.http.get(requestUrl, {
        params: {
          Id: containerId
        }
      })
    ).then((r: LoqateRetrieveAddressApiResponse): Address => this.fromPayload(r['Items'][0]));
  }

  /**
   * From Payload
   * @param addressObject
   */
  private fromPayload(res: LoqateRetrieveAddressApiResponse['Items'][number]): Address {
    const address = new Address();

    // grabbing all the address lines as there can be up to 5
    const addressLines = [];
    Object.keys(res).forEach((key): void => {
      if (key.toLowerCase().indexOf('line') === 0 && res[key]) {
        addressLines.push(res[key]);
      }
    });

    // placing the last line as line 2
    if (addressLines.length > 1) {
      address.line2 = addressLines.pop();
    }

    // joining the rest as line 1
    address.line1 = addressLines.join(', ');
    address.company = res['Company'] || '';

    if (!address.line1) {
      address.line1 = res['Company'];
      address.company = '';
    }

    address.city = res['City'];
    address.postcode = (res['PostalCode'] || '').toUpperCase();
    address.country = this.countriesService.getCountryByCode(res['CountryIso2'].toLowerCase());
    address.pcaID = res['Id'];

    return address;
  }
}
