import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { FileService } from 'Shared/services/file.service';
import { File } from 'Shared/classes/file';
import { BwFormControl } from 'Shared/classes/bw-form';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-form-file-upload-input',
  templateUrl: './form-file-upload-input.component.html'
})
export class FormFileUploadInputComponent implements OnInit {
  @Input() label: string;
  @Input() uploadInstantly: boolean = false;
  @Input() s3BucketLocation: string;
  @Input() limit: number = -1;
  @Input() acceptedFileTypes: string;
  @Input() infoMessage?: string;
  @Input() hideValidationErrors: boolean = false;
  @Input() bwFormControl: BwFormControl<any>;

  @Output() didClick = new EventEmitter<boolean>();
  @Output() filesChanged: EventEmitter<any> = new EventEmitter();

  window: any;
  files: File[] = [new File()];
  hasSubmitted: boolean = false;
  isFocused: boolean = false;
  isTouched: boolean = false;

  /**
   * Constructor
   * @param windowRef
   * @param fileService
   */
  constructor(private windowRef: WindowRefService, private fileService: FileService) {
    this.window = this.windowRef.nativeWindow;
  }

  /**
   * Remove the file
   * @param index
   */
  removeFile(index: number): void {
    this.files.splice(index, 1);

    if (this.files.length === 0 || this.activeFiles().length === this.limit - 1) {
      // If there are no files
      // Or we just removed a file when we were at the limit,
      // we need to add back the empty file upload prompt
      this.files.push(new File());
    }

    this.emitChange();
  }

  /**
   * Filter out any that have no name and emit
   */
  emitChange(): void {
    this.filesChanged.emit(this.activeFiles());
  }

  /**
   * File Selected
   * @param $event
   * @param file
   */
  fileSelected($event: Event, file: File): Promise<any> {
    file.name = ($event.target as HTMLInputElement).value.split(/(\\|\/)/g).pop();
    file.file = ($event.target as HTMLInputElement).files[0];

    this.window.focus(); // To stop the input field from appearing that it's focused
    this.emitChange();

    this.addAdditionalFile();

    return this.uploadInstantly
      ? this.fileService.uploadFile(this.s3BucketLocation, file).then(() => this.emitChange())
      : Promise.resolve(true);
  }

  /**
   * On input clicked
   */
  onInputClicked(): void {
    this.didClick.emit(true);
  }

  /**
   * Update file
   * @param file
   */
  retryUpload(file: File): Promise<any> {
    file.uploadPromise = undefined;
    return this.fileService.uploadFile(this.s3BucketLocation, file);
  }

  /**
   * Add an additional file field
   */
  addAdditionalFile(): void {
    if (this.files.length < this.limit || this.limit === -1) {
      this.files.push(new File());
    }
  }

  /**
   * Returns Files that do not have empty names
   */
  activeFiles(): File[] {
    return this.files.filter((f) => f.name && f.name.length);
  }

  /**
   * Mark as submmitted
   */
  markAsSubmitted(): void {
    this.hasSubmitted = true;
  }

  /**
   * On init
   */
  ngOnInit(): void {
    // Add an additional function, which marks this form as submitted
    this.bwFormControl['markAsSubmitted'] = () => {
      this.markAsSubmitted();
    };
  }
}
