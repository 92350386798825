import { Injectable } from '@angular/core';
import { FileModelService } from 'Shared/models/file-model.service';
import { File } from 'Shared/classes/file';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private fileModelService: FileModelService) {}

  /**
   * Upload a file and get the callback for each of the progress
   * @param bucket
   * @param file
   * @param progressCallback
   */
  uploadFile(bucket: string, file: File, progressCallback?: Function): Promise<any> {
    return this.fileModelService.uploadFile(bucket, file, progressCallback);
  }
}
