import { Injectable } from '@angular/core';
import { FavouritesModelService } from 'Shared/models/favourites-model.service';
import { Favourite } from 'Shared/classes/favourite';
import { Country } from 'Shared/classes/country';
import { Product, ProductService } from 'Checkout/services/product.service';
import { BehaviorSubject } from 'rxjs';
import { PurchaseService } from 'Checkout/services/purchase.service';
import { CountryService } from 'Shared/services/country.service';
import { GridProduct } from 'Shared/classes/grid-product';
import { AnalyticsService } from 'Shared/services/analytics.service';

/* exports */
export { Favourite } from 'Shared/classes/favourite';

@Injectable({
  providedIn: 'root'
})
export class FavouritesService {
  public shoppableFavourites$: BehaviorSubject<Favourite[]> = new BehaviorSubject([]);

  constructor(
    private favouritesModel: FavouritesModelService,
    private productsService: ProductService,
    private purchaseService: PurchaseService,
    private countryService: CountryService,
    private analyticsService: AnalyticsService
  ) {}

  /**
   * Update the current favourites
   * -> Bit of a hack to populate the heart in the nav
   * @param favouriteProducts
   */
  updateCurrentshoppableFavourites(favourites: Favourite[]): void {
    this.shoppableFavourites$.next(favourites);
  }

  /**
   * Save Favourite
   * @param favourite
   */
  saveFavourite(favourite: Favourite): Promise<any | Favourite[]> {
    this.analyticsService.trackAddToFavourites(favourite.product);
    return this.favouritesModel.saveFavourite(favourite);
  }

  /**
   * Get all the favourites, including those that we can no longer send to
   * @param country
   * @param onlyAvailable - Don't get older products
   */
  getFavouriteProducts(
    country: Country,
    onlyAvailable: boolean = false,
    clearCache?: boolean
  ): Promise<Favourite[]> {
    const favouriteWithProducts = [];
    const discount = this.purchaseService.getPurchase().discount;
    const orderIndex = (this.purchaseService.getPurchase().orders || []).length;
    return Promise.all([
      this.productsService.getAvailableProducts(country, orderIndex, discount),
      this.getFavourites(country, clearCache)
    ]).then(([products, allFavourites]) => {
      const nonShoppableFavourites = [];

      // // Mark any favourites as valid if we can find them in the products API
      allFavourites.forEach((favourite) => {
        const found = products.find((p) => p.id === favourite.product.id);
        if (found) {
          favourite.product = found;
          favourite.isShoppable = true;
          favourite.isGiftVoucher =
            ['digital_gift_voucher', 'physical_gift_voucher'].indexOf(found.type) > -1;
          favouriteWithProducts.push(favourite);
        } else {
          nonShoppableFavourites.push(favourite);
        }
      });

      // // Good news - we found all of them in the products API, so no need to go off and fetch
      if (!nonShoppableFavourites.length || onlyAvailable) {
        return favouriteWithProducts;
      }

      // Check to see if the product has even been valid, if that errors, we don't care
      const promises = nonShoppableFavourites.map((favourite) => {
        return this.productsService.getOlderProduct(favourite.product).catch(() => {});
      });

      return Promise.all(promises).then((olderProducts) => {
        // Finally, add them to the list
        nonShoppableFavourites.forEach((favourite) => {
          const found = olderProducts.find((p) => p && p.id === favourite.product.id);
          if (found) {
            favourite.product = found;
            favourite.isShoppable = false; // Because it's not shoppable any more
            favouriteWithProducts.push(favourite);
          }
        });

        return favouriteWithProducts;
      });
    });
  }

  /**
   * Get Favourites
   * @param country
   * @param clearCache
   */
  getFavourites(country: Country, clearCache?: boolean): Promise<Favourite[]> {
    return this.favouritesModel.getFavourites(country, clearCache);
  }

  /**
   * Delete favourite
   * @param favourite
   */
  deleteFavourite(favourite: Favourite): Promise<Favourite[]> {
    return this.favouritesModel.deleteFavourite(favourite);
  }

  /**
   * Delete the favourite from list
   * @param product
   */
  deleteFavouriteItem(product: GridProduct | Product): Promise<any> {
    // Clone it
    const favourite = Object.assign(new Favourite(), product['relatedFavourite']);
    product['relatedFavourite'] = undefined;

    let currentFavourites = this.shoppableFavourites$.getValue();
    currentFavourites = currentFavourites.filter((f) => f.product.id !== product.id);
    this.updateCurrentshoppableFavourites(currentFavourites);
    return this.deleteFavourite(favourite).catch(() => {});
  }

  /**
   * Save Favourite from list
   * @param product
   */
  saveFavouriteItem(product: GridProduct | Product): Promise<Favourite[]> {
    const favourite = new Favourite();
    favourite.product = product;
    favourite.country = this.countryService.forShipping;
    product['relatedFavourite'] = favourite; // Presume the request was a success
    const currentFavourites = this.shoppableFavourites$.getValue();
    currentFavourites.push(favourite);
    this.updateCurrentshoppableFavourites(currentFavourites);
    return this.saveFavourite(product['relatedFavourite']).catch(() => {});
  }
}
