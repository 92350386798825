import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyCode, Price } from 'Shared/classes/price';
import { Availability } from 'Shared/classes/availability';
import { Credit } from 'Shared/classes/credit';
import { t } from 'Shared/utils/translations';
import { Country, CountryService } from 'Shared/services/country.service';
import { environment } from 'Environments/environment';

interface SimplePrice {
  price: number;
  currency: CurrencyCode;
}

type PriceTypes = number | Credit | Price | Availability | SimplePrice;

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {
  country: Country;
  euroSymbolPosition: string;

  constructor(config: CountryService) {
    this.country = config.forShipping;
    this.euroSymbolPosition = environment.euroSymbolPosition;
  }

  /**
   * We utilise the built in browser currency formatting, _however_ we want to force a certain
   * format, depending on the site the user is on
   * @param currency
   */
  getBrowserLocaleToUse(currency: CurrencyCode): string {
    return currency.toLowerCase() === 'eur' ? (this.euroSymbolPosition === 'start' ? 'nl-NL' : 'fr-FR') : 'en-GB';
  }

  /**
   * Utilise the brower's built in currency -> string formatting
   * @param value
   * @param currency
   * @returns
   */
  utiliseBrowserString(value: number, currency: CurrencyCode): string {
    const localeToUse = this.getBrowserLocaleToUse(currency);
    const splitNum = value.toString().split('.');
    const minDigits = splitNum.length && splitNum.length > 1 && +splitNum[1] > 0 ? 2 : 0;
    return value.toLocaleString(localeToUse, {
      style: 'currency',
      currency: currency.toUpperCase(),
      currencyDisplay: 'symbol',
      useGrouping: true,
      minimumFractionDigits: minDigits
    });
  }

  /**
   * Format as danish krone
   * @param value
   * @returns
   */
  formatDanishKrone(value: number): string {
    const splitNum = value.toString().split('.');
    const hasDecimal = splitNum[1] && parseInt(splitNum[1], 10) > 0;
    return hasDecimal ? `${splitNum[0]},${`${splitNum[1]}00`.slice(0, 2)} kr.` : `${splitNum[0]} kr.`;
  }

  /**
   * Transform object to string
   * @param priceObj
   * @param type
   * @param displayNegative
   * @param shippingLabel
   * @returns
   */
  transform(priceObj: PriceTypes, type: string = 'price', displayNegative: boolean = false, shippingLabel?: string): string {
    let price: PriceTypes;

    if (typeof priceObj === 'number') {
      price = {
        price: priceObj,
        currency: this.country.currencyCode
      };
    } else {
      price = priceObj;
    }

    if (!price || !(type in price)) {
      return ''; // TODO: What if we have no pricing?
    }

    const priceToUse = price[type];

    if (priceToUse === 0 || (!displayNegative && priceToUse <= 0)) {
      return shippingLabel ? shippingLabel : t('js.filters.freeCurrencyFilter.free');
    }

    const value = priceToUse / 100;

    if (price.currency.toLowerCase() === 'dkk' || price.currency.toLowerCase() === 'kr') {
      return this.formatDanishKrone(value);
    }

    return this.utiliseBrowserString(value, price.currency);
  }
}
