import { Component, OnInit, Input, OnChanges, ViewEncapsulation } from '@angular/core';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-review-stars',
  templateUrl: './review-stars.component.html'
})
export class ReviewStarsComponent implements OnInit, OnChanges {
  @Input()
  rating: number;
  @Input()
  roundingScale: number; // eg 0.5 or 0.25, 0.5 => show the nearest half star, 4.5 stars, 4.0 or 3.5 for example
  @Input()
  large: boolean;
  @Input()
  larger: boolean;
  @Input()
    source: string;

  stars: number[];
  remainderStarWidth: number;

  constructor() {
    this.roundingScale = this.roundingScale || 0.01;
  }

  setupStars(): void {
    if (this.rating > 0) {
      const wholeStars = Math.floor(this.rating);
      this.stars = Array.from(Array(wholeStars).keys());
    }
  }

  setupRemainder(): void {
    if (this.roundingScale) {
      const roundedValue = Math.round(this.rating / +this.roundingScale) * +this.roundingScale;
      const wholeValue = Math.floor(this.rating);

      this.remainderStarWidth =
        wholeValue > roundedValue
          ? (wholeValue - roundedValue) * 100
          : (roundedValue - wholeValue) * 100;
    }
  }

  ngOnInit(): void {
    this.setupStars();
    this.setupRemainder();
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }
}
