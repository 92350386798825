
<div class="bw-delivery-tracking-content" *ngIf="delivery">
  <bw-expansion-panel class="bw-delivery-tracking-content-panel" [isOpen]="isOpen" [hideToggle]="hideToggle">
    <div class="bw-delivery-tracking-content__header" role="header">
      <p *ngIf="delivery.state!=='delivered' &amp;&amp; delivery.shippingOption?.name==='Bloom &amp; Wild'">{{delivery?.address?.name}}’s gift is with one of our Bloom & Wild drivers who'll deliver it {{delivery.trackingDetails?.estimatedDeliveryDate | localeRelativeDate:'dddd D MMMM YYYY':'on'}}</p>
      <p *ngIf="delivery.state==='delivered'">Your order to {{delivery?.address?.name}} has been delivered</p>
      <p *ngIf="delivery.state!=='delivered' &amp;&amp; delivery.shippingOption?.name!=='Bloom &amp; Wild'">Your order to {{delivery?.address?.name}} is on its way, and should be delivered by {{delivery.shippingOption?.name}} {{delivery.trackingDetails?.estimatedDeliveryDate | localeRelativeDate:'dddd D MMMM YYYY':'on'}}</p>
    </div>
    <div class="bw-delivery-tracking-content__container" role="body">
      <div class="bw-delivery-tracking-content__status" *ngIf="delivery.shippingOption"><img class="status-img img-delivered" *ngIf="delivery.state==='delivered'" src="/assets/images/delivery-tracking/delivered-letterbox.svg">
        <p>Where's my order now?</p>
        <p class="underline-title" *ngIf="delivery.state==='delivered'">Delivered</p>
        <p *ngIf="delivery.state==='delivered'">Your gift was delivered {{delivery.trackingDetails?.deliveredAt | localeRelativeDate:'dddd D MMMM YYYY': 'on'}} to {{delivery?.address?.name}}. We hope it makes their day!</p><img class="status-img" *ngIf="delivery.state!=='delivered'" src="/assets/images/delivery-tracking/delivery-van.svg">
        <p class="underline-title" *ngIf="delivery.state!=='delivered' &amp;&amp; delivery.shippingOption.name!=='Bloom &amp; Wild'">It’s with {{delivery.shippingOption.name}}</p>
        <p class="underline-title" *ngIf="delivery.state!=='delivered' &amp;&amp; delivery.shippingOption.name==='Bloom &amp; Wild'">It's with our driver</p>
        <p *ngIf="delivery.state!=='delivered' &amp;&amp; delivery.shippingOption?.name==='Bloom &amp; Wild'">We’ve handed your gift over to one of our Bloom & Wild drivers who’ll deliver it {{delivery.trackingDetails.deliveryOn | localeRelativeDate:'dddd D MMMM YYYY'}}</p>
        <p *ngIf="delivery.state!=='delivered' &amp;&amp; delivery.shippingOption?.name!=='Bloom &amp; Wild'">Your gift has been collected by {{delivery.shippingOption.name}} and is on its way.</p>
        <bw-alert class="status-alert" *ngIf="delivery.state==='delivered'" alertStyle="info" header="Can’t find your delivery?"><span>Don't panic! It may have been left in a safe place or with a neighbour. You might also want to double check that we have the correct address in your Order details below.</span><br><span>If something's not right, get in touch with our Customer Delight team and we'll be happy to help.</span></bw-alert>
        <div class="status-delivery-date" *ngIf="delivery.state==='delivered'"><img class="status-delivery-date__icon" src="/assets/images/delivery-tracking/calendar-outline.svg"><span class="status-delivery-date__label">Delivery date:&nbsp;</span><span class="status-delivery-date__text">{{capitalizeFirstLetter(delivery.trackingDetails.deliveredAt | localeRelativeDate:'dddd D MMMM')}}</span></div>
        <bw-process-bar [currentValue]="progressTracking" [maxValue]="maxTracking"></bw-process-bar>
        <div class="status-text">Updated {{delivery.trackingDetails.updatedAt | localeRelativeDate:'dddd D MMMM YYYY':'on' }} at {{delivery.trackingDetails.updatedAt | localeDate:'h:mma' }}</div>
      </div>
      <ng-container *featureEnabled="'DELIVERY_TRACKING_SS'">
        <div class="bw-delivery-tracking-content__body" *ngIf="isDeliveryLate &amp;&amp; delivery.state !== 'delivered'">
          <div class="delivery-late__header">If you delivery is 2 or more days late you can request a new delivery or compensation instantly.</div>
          <bw-button buttonStyle="primary" size="medium" (clicked)="showDeliverySelfServeModal(delivery)" bwTrackAs="component.delivery-tracking.delivery-late.cta">Report a late delivery</bw-button>
        </div>
      </ng-container>
      <div class="bw-delivery-tracking-content__body">
        <div class="bw-delivery-tracking-content__download">
          <div class="icon-text-label" *ngIf="delivery.trackingDetails?.trackedNumber"><img src="/assets/images/delivery-tracking/location.svg">
            <div class="text-medium"> {{delivery.shippingOption.name}} tracking number
              <p>{{delivery.trackingDetails?.trackedNumber}}</p>
            </div><a class="text-medium link-right" target="_blank" bwTrackAs="deliveryTrackingModal.viewRMTracking" href="{{delivery.trackingDetails.trackingUrl}}"><span>Track</span><img src="/assets/images/delivery-tracking/right.svg"></a>
          </div>
          <div class="icon-text-label" *ngIf="delivery.state!=='delivered'"><img src="/assets/images/delivery-tracking/email.svg">
            <div class="text-medium">What happens next?
              <p>We’ll let you know when it’s been delivered!</p>
            </div>
          </div>
          <div class="bw-delivery-tracking-content__download-app" *ngIf="delivery.state!=='delivered'">
            <div class="content__copy">Download our app for tracking notifications.</div>
            <div class="content__cta"><a *ngIf="deviceType === 'android'; else ios" target="_blank" href="https://play.google.com/store/apps/details?id=com.bloomandwild" bwTrackAs="component.delivery-tracking.download-app"><img src="/assets/brand-icons/download-on-google-play.{{ environment.locale }}.svg"></a>
              <ng-template #ios><a target="_blank" href="{{appStoreAttributedLink}}" bwTrackAs="component.delivery-tracking.download-app"><img src="/assets/brand-icons/download-on-app-store.{{ environment.locale }}.svg"></a></ng-template>
            </div>
          </div>
        </div>
        <div class="bw-delivery-tracking-content__body-info" *ngIf="delivery.trackingDetails">
          <bw-expansion-panel (trackInHeap)="sendViewMoreDetails()">
            <div role="header" bwTrackAs="deliveryTrackingModal.viewMoreDetails"><span>Order details</span></div>
            <div class="spacing-area-accordion" role="body">
              <div class="icon-text-label"><img src="/assets/images/delivery-tracking/calendar-outline.svg">
                <div>Booked delivery date:
                  <div class="text-medium">{{capitalizeFirstLetter(delivery.trackingDetails.deliveryOn | localeRelativeDate:'dddd D MMMM YYYY')}}</div><span class="badge badge-pill badge-light text-small-pill" *ngIf="delivery.shippingOption.successRateMessage">{{delivery.shippingOption.successRateMessage}}</span>
                </div>
              </div>
              <div class="icon-text-label"><img src="/assets/images/delivery-tracking/account-outline.svg">
                <div>Delivery address
                  <div class="text-medium">{{delivery.address | addressInline}}</div>
                </div>
              </div><a class="text-medium view-info-link" href="/my-orders/order/{{delivery.orderId}}" (click)="sendHeapEvent('viewMyOrders', delivery)" bwTrackAs="deliveryTrackingModal.viewMyOrders">View order in account<img src="/assets/images/delivery-tracking/right.svg"></a>
            </div>
          </bw-expansion-panel>
          <bw-expansion-panel (trackInHeap)="sendViewMoreDetailsLocation()">
            <div role="header" bwTrackAs="deliveryTrackingModal.viewMoreDetails"><span>Where has my gift been?</span></div>
            <div class="spacing-area-accordion" role="body">
              <div class="timeline" *ngIf="delivery.trackingDetails">
                <div class="timeline__scroll-area">
                  <div class="timeline__item" *ngFor="let item of historyContent">
                    <div class="timeline__item-header">{{item.day | localeDate:'dddd D MMMM YYYY'}}</div>
                    <div class="timeline__item-content" *ngFor="let itemList of item.values">
                      <div class="timeline__item-content-group" *ngIf="itemList.state==='delivered'"><span class="text-small">{{itemList.time | localeDate:'HH:mm'}}</span><span class="text-medium">Delivered</span>
                        <p>Your gift was delivered {{delivery.trackingDetails?.deliveredAt | localeRelativeDate:'dddd D MMMM YYYY': 'on'}} to {{delivery?.address?.name}}. We hope it makes their day!</p>
                      </div>
                      <div class="timeline__item-content-group" *ngIf="itemList.state==='outDelivery'"><span class="text-small">{{itemList.time | localeDate:'HH:mm'}}</span><span class="text-medium">Out for delivery</span>
                        <p>Your bouquet is on the move and will be delivered today by {{delivery.shippingOption.name}}</p>
                      </div>
                      <div class="timeline__item-content-group" *ngIf="itemList.state==='shipped'"><span class="text-small">{{itemList.time | localeDate:'HH:mm'}}</span><span class="text-medium" *ngIf="delivery.shippingOption.name==='Bloom &amp; Wild'">It's with our driver</span><span class="text-medium" *ngIf="delivery.shippingOption.name!=='Bloom &amp; Wild'">It’s with {{delivery.shippingOption.name}}</span>
                        <p>And they’ll deliver it {{delivery.trackingDetails.deliveryOn | localeDate:'dddd D MMMM YYYY'}}!</p>
                      </div>
                      <div class="timeline__item-content-group" *ngIf="itemList.state==='locked'"><span class="text-small">{{itemList.time | localeDate:'HH:mm'}}</span><span class="text-medium">At the flower studio</span>
                        <p>{{delivery.shippingOption.name}} will collect your order soon.</p>
                      </div>
                      <div class="timeline__item-content-group" *ngIf="itemList.state==='billed'"><span class="text-small">{{itemList.time | localeDate:'HH:mm'}}</span><span class="text-medium">Order confirmed</span>
                        <p>Leave it with us…</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="icon-text-label" *ngIf="delivery.trackingDetails?.trackedNumber"><img src="/assets/images/delivery-tracking/location.svg">
                <div class="text-medium">{{delivery.shippingOption.name}} tracking number
                  <p>{{delivery.trackingDetails?.trackedNumber}}</p>
                </div><a class="text-medium link-right" target="_blank" href="{{delivery.trackingDetails.trackingUrl}}" bwTrackAs="components.delivery-tracking.tracking-btn-text"><span>Track</span><img src="/assets/images/delivery-tracking/right.svg"></a>
              </div>
            </div>
          </bw-expansion-panel>
          <bw-expansion-panel (trackInHeap)="sendViewMoreDetailsFAQ()">
            <div role="header"><span>FAQs</span></div>
            <div role="body">
              <bw-faq [delivered]="delivery.state==='delivered'"></bw-faq>
            </div>
          </bw-expansion-panel>
        </div>
      </div>
    </div>
  </bw-expansion-panel>
</div>