import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Country } from 'Shared/classes/country';
import { Address } from 'Shared/classes/address';
import { BwFormControl } from 'Shared/classes/bw-form';
import { DropDownOption } from 'Shared/components/form-dropdown/form-dropdown.component';
import { AddressSearchService } from 'Shared/services/address-search.service';
import { AddressInlinePipe } from 'Shared/pipes/address-inline.pipe';
import { AddressSearchResult } from 'Shared/models/address-search-model.service';
import { FeaturesService } from 'Shared/services/features.service';
import { t } from 'Shared/utils/translations';
import { ShopType } from 'Checkout/components/shop-checkout/shop-checkout.component';
import { Subscription } from 'rxjs';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-search-address',
  templateUrl: './search-address.component.html'
})
export class SearchAddressComponent implements OnInit, OnDestroy {
  @Input() bwFormControl?: BwFormControl<Address>;
  @Input() country: Country;
  @Input() newStyle: boolean = true;
  @Input() setValueOnSelect: boolean = false;
  @Input() label: string = t('js.component.recipient-search.address.label'); // default to recipient search
  @Input() multiline: boolean = true;
  @Input() forceAddressLookup: boolean = false;
  @Input() shopType: ShopType = 'default';

  @Output() didClick: EventEmitter<boolean> = new EventEmitter(); // Use this event to open a modal to edit the address
  @Output() didSelect: EventEmitter<Address> = new EventEmitter(); // Emits the selected address

  results: DropDownOption[];
  showLoading: boolean = false;
  addressLookupEnabled: boolean;
  postcodeBeforeCityEnabled: boolean;

  controlStatusSubscription: Subscription;

  constructor(
    private addressSearchService: AddressSearchService,
    private featuresService: FeaturesService
  ) {}

  /**
   * On display input clicked
   */
  onClick(): void {
    this.didClick.emit(true);
  }

  /**
   * Function to be used by the callback of the bw-form-text-input
   * @param address
   */
  onDisplay(address: Address): string {
    const addressInlinePipe = new AddressInlinePipe();
    return addressInlinePipe.transform(address, true, false);
  }

  /**
   * Function display postcode before city to be used by the callback of the bw-form-text-input
   * @param address
   */
  displayPostcodeBeforeCity(address: Address): string {
    const addressInlinePipe = new AddressInlinePipe();
    return addressInlinePipe.transform(address, true, true);
  }

  /**
   * On search
   * @param querySearch
   */
  onSearch(querySearch: any | string): Promise<AddressSearchResult[]> {
    return this.searchAddresses({
      Text: querySearch.Text || querySearch,
      Id: querySearch.Id || undefined
    });
  }

  /**
   * Search addresses
   * @param result
   */
  searchAddresses(result: Pick<AddressSearchResult, 'Text' | 'Id'>): Promise<any> {
    this.showLoading = true;
    return this.addressSearchService.getResults(result.Text, this.country, result.Id).then((r) => {
      return this.resultsChanged(r);
    });
  }

  /**
   * On results change
   * @param searchResults
   */
  resultsChanged(searchResults: AddressSearchResult[]): void {
    if (!searchResults.length || searchResults[0]['Error']) {
      this.results = [];
      this.showLoading = false;
      return null;
    }

    this.results = searchResults.map((res) => {
      const value =
        res.Type !== 'Address'
          ? {
              Text: res.Text,
              Id: res.Id
            }
          : res.Id;
      return {
        value,
        label: res.Text,
        isGroupingOption: res.Type !== 'Address',
        description: res.Description
      };
    });

    this.showLoading = false;
  }

  /**
   * On option selected
   * @param option
   */
  onSelect(option: DropDownOption): Promise<any> {
    this.showLoading = true;
    return this.addressSearchService.getAddress(option.value).then((address: Address) => {
      this.showLoading = false;
      this.results = undefined;

      if (this.bwFormControl && this.setValueOnSelect) {
        this.bwFormControl.setValue(address);
        this.bwFormControl.updateValueAndValidity();
        this.bwFormControl.markAsTouched();
      }

      this.didSelect.emit(address);
    });
  }

  /**
   * On component init
   */
  ngOnInit(): void {
    this.addressLookupEnabled = this.featuresService.getFeature('ADDRESS_LOOKUP');
    this.postcodeBeforeCityEnabled =
      this.featuresService.getFeature('ADDRESS_FIELDS').postcodeBeforeCity;

    // New subscription flow - SUBSCRIPTION_SHOP_NEW_UX
    if (this.shopType === 'subscription') {
      this.label = this.addressLookupEnabled
        ? t('js.component.recipient-search.address.label-subs-search')
        : t('js.component.recipient-search.address.label-subs-set');

      if (this.bwFormControl.status === 'VALID') {
        this.label = t('js.component.recipient-search.address.label-subs-set');
        return;
      }

      this.controlStatusSubscription = this.bwFormControl.statusChanges.subscribe((status) => {
        if (status === 'VALID') {
          this.label = t('js.component.recipient-search.address.label-subs-set');

          // destroy subscription
          if (this.controlStatusSubscription) {
            this.controlStatusSubscription.unsubscribe();
          }
        }
      });
    }
  }

  /**
   * On component destroy
   */
  ngOnDestroy(): void {
    if (this.controlStatusSubscription) {
      this.controlStatusSubscription.unsubscribe();
    }
  }
}
