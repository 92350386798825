import { Injectable } from '@angular/core';
import { HrefInterceptorService } from './href-interceptor.service';
import { AnalyticsService } from './analytics.service';

/**
 *
 */
@Injectable({
  providedIn: 'root'
})
export class OasysRouterHandlerService {

  constructor(
    private hrefInterceptor: HrefInterceptorService,
    private analyticsService: AnalyticsService) { }

  /**
   *
   * @param element
   * @param event
   */
  handleRoutingOnClick(element: HTMLAnchorElement, event: any): void {
    this.hrefInterceptor.handleRoutingOnClick(element, event);

    if (event) {
      this.handlTracking(event);
    }
  }

  /**
   *
   * @param element
   */
  handleHrefDomains(element: HTMLAnchorElement): void {
    this.hrefInterceptor.handleHrefDomains(element);
  }

  /**
   *
   * @param event
   */
  handlTracking(event: any) {
    const target = event.target as HTMLElement;

    // Find the closest ancestor (or the target itself) with the 'bwtrackas' attribute
    const elementTobeTracked = target.closest('[bwtrackas]');

    if (elementTobeTracked !== null) {
      // Get the tracking key from the 'bwtrackas' attribute and track it using the analytics service
      const trackingKey = elementTobeTracked.getAttribute('bwtrackas');
      this.analyticsService.track(trackingKey);

      // Get the siblings of the element to be tracked
      const siblings = this.getSiblings(target);

      // If the element has siblings, find the position of the element on the page
      if (siblings) {
        const positionOnPage = siblings.indexOf(target.closest('.static-content')) + 1;

        // Add custom tracking here
        this.analyticsService.trackInHeap('activeContentSection', {
          contentPosition: positionOnPage,
          activeCtaUrl: this.findHref(elementTobeTracked)
        });
      }
    }
  }

  /**
 *
 * @param element
 */
  getSiblings(element: HTMLElement): Element[] {
    const parentNode = element.closest('.static-content')?.parentNode;
    if (parentNode) {
      const siblings = Array.from(parentNode?.children);
      return siblings;
    }

    return [];
  }

  /**
   *
   * @param element
   * @returns
   */
  findHref(element: Element): string | null {
    // Check if the element itself has an 'href' attribute
    if (element.hasAttribute('href')) {
      return element.getAttribute('href');
    }

    // Check if any child of the element has an 'href' attribute
    const childWithHref = element.querySelector('[href]');
    return childWithHref ? childWithHref.getAttribute('href') : null;
  }
}
