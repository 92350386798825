import * as clone from 'clone';
import * as dayjs from 'dayjs';

export class OccasionReminder {
  id: number;
  recipient?: string;
  type: OccasionTypeDisplay;
  origin?: string;

  constructor() {
    this.type = new OccasionTypeDisplay();
  }

  /**
   * Clone the occasion
   */
  clone(): OccasionReminder {
    return clone(this);
  }
}

export class OccasionType {
  id: number;
  associatedDate?: dayjs.Dayjs;
  relation: string;
  kind: string;
  displayName: string;
  displayKind: string;
  displayImageUrl: string;
  colour: string;
  isCustomField: boolean;
  tags: string[] = [];

  constructor(
    id?: number,
    relation?: string,
    kind?: string,
    displayName?: string,
    displayKind?: string,
    isCustomField?: boolean,
    associatedDate?: dayjs.Dayjs,
    displayImageUrl?: string,
    tags?: string[]
  ) {
    this.id = id;
    this.relation = relation;
    this.kind = kind;
    this.displayName = displayName;
    this.displayKind = displayKind;
    this.isCustomField = isCustomField;
    this.associatedDate = associatedDate;
    this.displayImageUrl = displayImageUrl;
    this.tags = tags || [];
  }

  /**
   * Clone the occasion
   */
  clone(): OccasionType {
    return clone(this);
  }
}

export class OccasionTypeDisplay extends OccasionType {
  displayNameVariant?: string;
  hasRecipient?: boolean;
  prefillRecipient?: string;

  constructor(
    id?: number,
    relation?: string,
    kind?: string,
    displayName?: string,
    displayKind?: string,
    isCustomField?: boolean,
    associatedDate?: dayjs.Dayjs,
    displayImageUrl?: string,
    displayNameVariant?: string,
    hasRecipient?: boolean,
    prefillRecipient?: string,
    tags?: string[]
  ) {
    super(
      id,
      relation,
      kind,
      displayName,
      displayKind,
      isCustomField,
      associatedDate,
      displayImageUrl,
      tags
    );

    this.displayNameVariant = displayNameVariant;
    this.hasRecipient = hasRecipient;
    this.prefillRecipient = prefillRecipient;
  }

  /**
   * Clone the occasion
   */
  clone(): OccasionTypeDisplay {
    return clone(this);
  }
}
