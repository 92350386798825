import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ContentModelService } from 'Shared/models/content-model.service';
import { SegmentModelService, ContentSegment } from 'Shared/models/segment-model.service';
import { ConfigService } from 'Shared/services/config.service';
import { LocationService } from 'Shared/services/location.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { Experiment } from 'Shared/classes/experiment';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { WindowRefService } from 'Shared/services/window.service';
import { GtmService } from 'Shared/services/third-parties/gtm.service';
import { StateService } from 'Shared/services/state.service';
import { Country, CountryService } from './country.service';
import { OptimizelyService } from './third-parties/optimizely.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  public fallbackContentUsed$: BehaviorSubject<any> = new BehaviorSubject([]);

  public contentSegmentsDidChange$: Subject<ContentSegment[]> = new Subject();
  currentContentSegments: ContentSegment[] = [];

  refreshContentSegmentsCache: Promise<ContentSegment[]>;
  debug: boolean = false;
  window: any;
  fallbackDomain: string = '';
  fallbackHrefLangs: string[] = [];

  constructor(
    private contentModelService: ContentModelService,
    private segmentModelService: SegmentModelService,
    private configService: ConfigService,
    private locationService: LocationService,
    private analyticsService: AnalyticsService,
    private gtmService: GtmService,
    private experimentService: ExperimentsService,
    private windowRef: WindowRefService,
    private stateService: StateService,
    private countryService: CountryService,
    private optimizelyService: OptimizelyService
  ) {
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
    this.fallbackDomain = this.configService.getConfig().contentFallbackDomain;
    this.fallbackHrefLangs = this.configService.getConfig().contentFallbackHreflangs;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<content-segments>', ...args);
    }
  }

  /**
   * Set the current set of segments
   * @param segments
   */
  setCurrentSegments(segments: ContentSegment[]): void {
    this.currentContentSegments = segments;
  }

  /**
   * Set content segment
   */
  setContentSegments(segments: ContentSegment[]): ContentSegment[] {
    const sortedSegments = [];
    segments.forEach((segment) => {
      if (!sortedSegments.find((s) => s.id === segment.id)) {
        sortedSegments.push(segment);
      }
    });
    sortedSegments.sort((a, b) => a.id - b.id);

    const asString = sortedSegments.map((s) => s.id);
    const segmentString = segments && segments.length ? `|CS${asString.join('|CS')}|` : '';

    const currentSegments = this.contentSegments()
      .map((c) => c.id)
      .join(',');
    const newSegments = sortedSegments.map((c) => c.id).join(',');

    if (currentSegments === newSegments) {
      return sortedSegments;
    }

    this.analyticsService.setDimension('contentSegment', segmentString);
    this.gtmService.addToDataLayer({ contentSegments: segmentString });

    this.setCurrentSegments(sortedSegments);
    this.contentSegmentsDidChange$.next(sortedSegments);
    if (this.debug) {
      this.log('changed to', sortedSegments);
    }
    return sortedSegments;
  }

  /**
   * Get user
   */
  public contentSegments(): ContentSegment[] {
    return this.currentContentSegments;
  }

  /**
   * Force a content Segment
   * @param segment
   */
  forceContentSegment(segment: ContentSegment): void {
    this.segmentModelService.forceContentSegment(segment);
  }

  /**
   * Exit the content Segment
   * @param segment
   */
  exitContentSegment(segment: ContentSegment): void {
    this.segmentModelService.exitContentSegment(segment);
  }

  /**
   * Refresh the segments
   */
  getContentSegments(): Promise<ContentSegment[]> {
    this.refreshContentSegmentsCache =
      this.refreshContentSegmentsCache ||
      this.segmentModelService.getContentSegments().then((segments) => {
        return this.setContentSegments(segments);
      });

    return this.refreshContentSegmentsCache;
  }

  /**
   * Refresh content segments
   */
  refreshSegments(): Promise<ContentSegment[]> {
    this.refreshContentSegmentsCache = this.segmentModelService.getContentSegments(true).then((segments) => {
      return this.setContentSegments(segments);
    });

    return this.refreshContentSegmentsCache;
  }

  /**
   * Append the fallback URLs
   * @param url
   * @param fallbackUrl
   */
  appendToFallbackUrls(path: string): void {
    const current = this.fallbackContentUsed$.getValue().slice();

    if (current.indexOf(path) < 0) {
      current.push(path);
      this.fallbackContentUsed$.next(current);
    }
  }

  /**
   * Get the content for a url
   * @param url
   * Get the required content server
   */
  getContentUrl(path: string, server?: string, isPreview: boolean = false, contentType?: string): string {
    const state = this.stateService.getCurrent();

    if (path.toLowerCase().indexOf('http') > -1) {
      return path;
    }

    let newPath = (path === '/' || path === '' ? 'homepage' : path).toLowerCase();
    if (newPath[0] === '/') {
      newPath = newPath.replace('/', ''); // First instance
    }
    newPath = newPath.substr(-1) === '/' ? newPath.slice(0, -1) : newPath; // if last character is / remove it

    // The server may already have a querystring, if so, we need to add either &preview=true or ?prview=true to the end
    const serverPath = `${server}${newPath}.html`.split('?');
    const queryString = serverPath[1] ? serverPath[1].split('&') : [];
    if (isPreview) {
      queryString.push('preview=true');
    }

    if (contentType) {
      queryString.push(`contentTypeId=${contentType}`);
    }

    if (state.params['environment']) {
      queryString.push(`environment=${state.params['environment']}`);
    }

    return `${serverPath[0]}${queryString.length ? `?${queryString.join('&')}` : ''}`;
  }

  /**
   * Strip the content for experiments, depending on what's running
   */
  contentForExperiments(
    data: HTMLDivElement,
    experiments: {
      [key: string]: Experiment;
    }
  ): HTMLDivElement {
    const experimentSelectors = [`bw-experiment[name]`, `bw-content-experiment[experiment]`, `[experiment-name]`].join(',');

    // First, get all the experiments on this page, and the variant we are in
    const pageExperiments = {};
    [].slice.call(data.querySelectorAll(experimentSelectors)).forEach((el) => {
      const name = el.getAttribute('name') || el.getAttribute('experiment-name') || el.getAttribute('experiment');

      pageExperiments[name] = experiments[name] ? experiments[name].variant : 0;
    });

    // Remove the DOM for the variants we are not in
    Object.entries(pageExperiments).forEach((obj) => {
      const selectors = [
        `bw-experiment[name="${obj[0]}"]:not([variant="${obj[1]}"])`,
        `bw-content-experiment[experiment="${obj[0]}"]:not([variant="${obj[1]}"])`,
        `[experiment-name="${obj[0]}"]:not([experiment-variant="${obj[1]}"])`
      ].join(',');

      [].slice.call(data.querySelectorAll(selectors)).forEach((el) => {
        el.parentNode.removeChild(el);
      });
    });

    return data;
  }

  /**
   *
   * @param data
   */
  extractExperimentVariants(data: HTMLDivElement): {
    name: string;
    variant: string;
  }[] {
    const experimentSelectors = [`bw-experiment[name]`, `bw-content-experiment[experiment]`, `[experiment-name]`].join(',');

    const pageExperimentsVariants = [];
    [].slice.call(data.querySelectorAll(experimentSelectors)).map((el) => {
      const expName = el.getAttribute('name') || el.getAttribute('experiment-name') || el.getAttribute('experiment');
      const expVariant = el.getAttribute('experiment-variant');

      pageExperimentsVariants.push({ name: expName, variant: expVariant });
    });

    return pageExperimentsVariants;
  }

  /**
   * Given all experiments names on the page wait for optimise to send back the correct variant details
   * @param pageExperimentsVariants
   */
  waitForExperimentFromOptimize(pageExperimentsVariants): Promise<any[]> {
    // This would output each experiment and all its variants in an array
    // (i.e { HOMEPAGE_DESIGN: [0, 1, 2], CHECKOUT_DESIGN: [0] })
    const reducedExperimentVariants = pageExperimentsVariants.reduce((acc, item) => {
      acc[item.name] = acc[item.name] || [];
      acc[item.name].push(parseInt(item.variant, 10));
      return acc;
    }, {});

    const promises = [];

    Object.keys(reducedExperimentVariants).forEach((key) => {
      // This is where we put users into correct variants for Optimizely.
      // This will need to be refactored once we remove google optimize.
      this.optimizelyService.decide(key.toLowerCase());

      // Wait for every experiment even if its 0
      let promise = this.experimentService.waitForExperiment(key, 550);
      promises.push(promise);
    });
    return Promise.all(promises);
  }

  /**
   * Strip the content for experiments, depending on what's running
   */
  contentForSegment(data: HTMLDivElement, segments: ContentSegment[]): HTMLDivElement {
    const segmentContentSelectors = ['[bw-segments]', '[segments]'].join(',');

    [].slice.call(data.querySelectorAll(segmentContentSelectors)).forEach((el) => {
      const segs = el.getAttribute('bw-segments') || el.getAttribute('segments');

      // As this comes in as a string of numbers, eg "[1,2,3,5]", we need to ensure it's a number and tidy
      const shownForSegments = (segs || '').split(',').map((s) => parseInt(s, 10));

      // Finally, if the segments we are in don't match, remove the DOM element
      if (!shownForSegments.find((i) => segments.find((s) => s.id === i))) {
        el.parentNode.removeChild(el);
      }
    });

    const notSegmentContentSelectors = ['[bw-not-segments]', '[not-segments]'].join(',');

    [].slice.call(data.querySelectorAll(notSegmentContentSelectors)).forEach((el) => {
      const segs = el.getAttribute('bw-not-segments') || el.getAttribute('not-segments');

      // As this comes in as a string of numbers, eg "1,2,3,5", we need to ensure it's a number and tidy
      const shownForSegments = (segs || '').split(',').map((s) => parseInt(s, 10));
      // Finally, if the segments we are in don't match, remove the DOM element
      if (shownForSegments.find((i) => segments.find((s) => s.id === i))) {
        el.parentNode.removeChild(el);
      }
    });

    return data;
  }

  /**
   * Get the content for a fragment
   * @param data
   * @param fragment
   */
  contentForFragment(data: HTMLDivElement, fragment: string): HTMLDivElement {
    const fragmented = document.createElement('div');
    [].slice.call(data.querySelectorAll(fragment)).forEach((el) => {
      fragmented.appendChild(el.cloneNode(true));
    });

    return fragmented;
  }

  /**
   * Apply the canonical URL to the data
   * @param data
   * @param canonicalUrl
   */
  applyCanonicalURL(data: HTMLDivElement, canonicalUrl: string): HTMLDivElement {
    const seoTag = data.querySelector('bw-seo');
    if (seoTag) {
      seoTag.setAttribute('canonicalUrl', canonicalUrl);
    }
    return data;
  }

  /**
   * Get the content for a given URL
   * @param url
   */
  getContentForUrl(url: string): Promise<any> {
    return this.contentModelService.get(url);
  }

  /**
   * Get the content for a url, with an optional "fragment" to only pull
   * @param url
   * @param fragment
   */
  getServerUrls(path: string): { mainUrl: string; fallbackUrl: string } {
    const isPreview = this.configService.isPreviewMode();
    const contentType = this.configService.hasContentfulContentTypeId();
    const config = this.configService.getConfig();
    const country = this.countryService.forShipping;

    let fallback = isPreview ? config.contentServerFallbackPreview : config.contentServerFallback;
    let server = isPreview ? config.contentServerPreview : config.contentServer;

    // This is mostly used for UK shipping to IE or DE.
    server = this.updateServerUrlsForSubDomains(country, isPreview, server);

    return {
      mainUrl: this.getContentUrl(path, server, isPreview, contentType),
      fallbackUrl: fallback ? this.getContentUrl(path, fallback, isPreview, contentType) : undefined
    };
  }

  /**
   * A pure function that updates the server urls for sub-domains
   * @param country
   * @param isPreview
   * @param server
   */
  updateServerUrlsForSubDomains(country: Country, isPreview: boolean, server: string): string {
    const locale = this.configService.getConfig().locale;
    const subDomains = {
      '2': {
        preview: 'slug=bloomandwild.ie/',
        s3Bucket: '/en-ie/'
      },
      '6': {
        preview: 'slug=bloomandwild.en-de/',
        s3Bucket: '/en-de/'
      }
    };

    if (country && subDomains[country.id] && isPreview && locale === 'en') {
      server = server.replace('slug=bloomandwild.com/', subDomains[country.id].preview);
    }

    if (country && subDomains[country.id] && !isPreview && locale === 'en') {
      server = server.replace('/en-gb/', subDomains[country.id].s3Bucket);
    }

    return server;
  }

  /**
   * Get the content for a url, with an optional "fragment" to only pull
   * @param url
   * @param fragment
   */
  get(path: string, useFallback: boolean = true, fragment?: string): Promise<any> {
    let fallbackWasUsed = false;
    const { mainUrl, fallbackUrl } = this.getServerUrls(path);

    return this.getContentForUrl(mainUrl)
      .catch((e) => {
        if (!fallbackUrl || !useFallback) {
          return Promise.reject(e);
        }
        fallbackWasUsed = true;
        return this.getContentForUrl(fallbackUrl);
      })
      .then((data) => {
        // Clone it
        let contentDOM = document.createElement('div');
        contentDOM.innerHTML = data.innerHTML;

        // Filter just for fragment if set
        contentDOM = fragment ? this.contentForFragment(contentDOM, fragment) : contentDOM;

        if (fallbackWasUsed) {
          this.appendToFallbackUrls(path);
        }

        if (fallbackWasUsed && contentDOM.querySelector('.location-page')) {
          return Promise.reject({
            message: 'doNotFallbackToLocationPage'
          });
        }

        // Given a DOM extracts all experiments and its variants into a nice object
        // expected outcome: [{name: "HOMEPAGE_DESIGN", variant: 0}, {name: "HOMEPAGE_DESIGN", variant: 1}]
        const allPageExperimentVariants = this.extractExperimentVariants(contentDOM);

        // Given a list experiments wait for optimise to tell us which one should the user be in
        // we make decide call
        return this.waitForExperimentFromOptimize(allPageExperimentVariants).then((experiment) => {
          // Getting experiments

          const experimentsObj = this.experimentService.experimentsObj$.getValue();

          // we remove content that does not match experiments & variants
          let dom = this.contentForExperiments(contentDOM, experimentsObj);

          // And for content segments
          // we remove content that does not match segments (hide for returning or new)
          const segments = this.contentSegments();

          dom = this.contentForSegment(dom, segments);

          return dom.innerHTML;
        });
      });
  }
}
