import { Injectable } from '@angular/core';
import { Address } from 'Project/shared/classes/address';
import { GiftCard } from 'Project/shared/classes/gift-card';
import { LocalStorageService } from 'Project/shared/services/local-storage.service';
import { BehaviorSubject } from 'rxjs';
import { CookieService, ICookieConsent } from 'Shared/services/third-parties/cookie.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { FeaturesService } from 'Shared/services/features.service';

@Injectable({
  providedIn: 'root'
})
export class PersistentCheckoutService {
  private cookieConsent$: BehaviorSubject<ICookieConsent> = this.cookieService.cookieConsent$;
  private storageAllowed: boolean = false;

  constructor(
    private cookieService: CookieService,
    private experimentsService: ExperimentsService,
    private localStorageService: LocalStorageService,
    private featuresService: FeaturesService
  ) {
    this.setAccessToLocalStorage();
  }

  /**
   * Clears checkout local storage
   */
  clearLocalStorage(): void {
    this.localStorageService.set('recipientDetails');
    this.localStorageService.set('giftCardMessage');
    this.localStorageService.set('giftCardCover');
  }

  /**
   * Sets current date / time in local storage
   */
  setExpiryInLocalStorage(): void {
    if (this.storageAllowed) {
      this.localStorageService.set('persistentCheckoutExp', JSON.stringify(new Date()));
    }
  }

  /**
   * Sets card in local storage
   * @param card
   */
  setCardInLocalStorage(card: GiftCard): void {
    if (this.storageAllowed) {
      this.localStorageService.set('giftCardMessage', card.message);
      this.localStorageService.set('giftCardCover', card.cover);
    }
  }

  /**
   * Sets address local storage
   * @param address
   */
  setAddressInLocalStorage(address: Address): void {
    if (this.storageAllowed) {
      this.localStorageService.set('recipientDetails', address);
    }
  }

  /**
   * Updates / removes local storage address
   */
  getAddressFromLocalStorage(): Address {
    let address: Address;
    if (this.hasPersistenceExpired()) {
      this.clearLocalStorage();
    } else {
      const localAddress: Address = this.localStorageService?.get('recipientDetails');
      if (localAddress) {
        address = this.mapAddressFromLocalStorage(localAddress);
      }
    }
    this.setExpiryInLocalStorage();
    return address;
  }

  /**
   * Gets local storage card
   */
  getCardFromLocalStorage(): GiftCard {
    const giftCard = new GiftCard();
    giftCard.message = this.localStorageService?.get('giftCardMessage');
    giftCard.cover = this.localStorageService?.get('giftCardCover');

    return giftCard;
  }

  /**
   * Maps the address from local storage to ensure it is an Address object
   */
  mapAddressFromLocalStorage(addressToMap: Address): Address {
    const address = new Address(addressToMap?.country, addressToMap?.id);
    address.pcaID = addressToMap?.pcaID;
    address.name = addressToMap?.name;
    address.company = addressToMap?.company;
    address.line1 = addressToMap?.line1;
    address.line2 = addressToMap?.line2;
    address.city = addressToMap?.city;
    address.postcode = addressToMap?.postcode;
    address.phone = addressToMap?.phone;
    address.note = addressToMap?.note;
    address.doorCode = addressToMap?.doorCode;
    address.floor = addressToMap?.floor;
    address.apartmentNumber = addressToMap?.apartmentNumber;
    address.email = addressToMap?.email;
    return address;
  }

  /**
   * Checks whether persisted data should be expired
   */
  private hasPersistenceExpired(): boolean {
    const expireTime: string = this.localStorageService.get('persistentCheckoutExp');
    if (expireTime) {
      const savedTimestamp = new Date(JSON.parse(expireTime));
      // default expire time is 30 minutes
      let expiredTime = new Date(Date.now() - 30 * 60 * 1000);
      if (
        this.experimentsService.isActive('CHECKOUT_PERSISTENT_STORAGE_TIME', 1) ||
        this.featuresService.getFeature('CHECKOUT_PERSISTENT_STORAGE_TIME')
      ) {
        // set expired time for 24 hours
        expiredTime = new Date(Date.now() - 24 * 60 * 60 * 1000);
      }
      return savedTimestamp && savedTimestamp <= expiredTime;
    }
    return true;
  }

  private setAccessToLocalStorage(): void {
    // subscribe to cookie consent and set storageAllowed appropriately
    // (only if preferences are allowed)
    this.cookieConsent$.subscribe((cookieConsent): void => {
      this.storageAllowed = cookieConsent?.preferences ?? false;
    });
  }
}
