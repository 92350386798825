import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WindowRefService } from 'Shared/services/window.service';
import { IViewportOptions } from 'Shared/interfaces/viewport-detection';
export interface IActiveViewports {
  desktop: boolean;
  largeTablet: boolean;
  mediumTablet: boolean;
  mobile: boolean;
  not: {
    desktop: boolean;
    largeTablet: boolean;
    mediumTablet: boolean;
    mobile: boolean;
  };
}

export type DeviceOs = 'windows-phone' | 'android' | 'ios' | 'mac' | 'windows' | 'unknown';

/**
 * Usage Example:
 *
 * Show only desktop
 * div(*ngIf="(viewPort$ | async)?.desktop")
 *
 * The inverse, this will show the element in both mobile & tablet views
 * div(*ngIf="(viewPort$ | async)?.not.desktop")
 */
@Injectable({
  providedIn: 'root'
})
export class ViewportDetectionService {
  activeViewports: IActiveViewports = {
    desktop: false,
    largeTablet: false,
    mediumTablet: false,
    mobile: false,
    not: {
      desktop: true,
      largeTablet: true,
      mediumTablet: true,
      mobile: true
    }
  };

  public viewportSizeIs$ = new BehaviorSubject<IActiveViewports>(this.activeViewports);

  // Our breakpoints
  viewortBreakpoints: IViewportOptions[] = [
    {
      name: 'desktop',
      media: '(min-width: 1200px)'
    },
    {
      name: 'largeTablet',
      media: '(max-width: 1199px) and (min-width: 992px)'
    },
    {
      name: 'mediumTablet',
      media: '(max-width: 991px) and (min-width: 768px)'
    },
    {
      name: 'mobile',
      media: '(max-width: 767px)'
    }
  ];

  hasUpatedForIE: boolean = false;
  isIE: boolean = false;
  constructor(private windowRef: WindowRefService) {
    this.isIE =
      navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1;
  }

  /**
   *
   * @param event Handles events anytime the breakpoint changes
   */
  handleEvent(event: MediaQueryListEvent): void {
    if (event) {
      (this.viewortBreakpoints || []).forEach((view) => {
        // Find the correct media query option from our list
        if (view.media === event.media) {
          this.activeViewports[view.name] = event.matches;
          this.activeViewports.not[view.name] = !event.matches;
        }
      });

      // Alert of subscribers about the view port change
      this.viewportSizeIs$.next(this.activeViewports);
    }
  }

  /**
   * Initialise the view port service and set event listeners
   */
  initViewportCheck(): void {
    const viewportBreakpoints = {
      desktop: '(min-width: 1200px)',
      largeTablet: '(max-width: 1199px) and (min-width: 992px)',
      mediumTablet: '(max-width: 991px) and (min-width: 768px)',
      mobile: '(max-width: 767px)'
    };

    Object.entries(viewportBreakpoints).forEach(([size, breakpoint]) => {
      // Setting Media Query Break points to listen for
      const matchMedia = this.windowRef.nativeWindow.matchMedia(breakpoint);

      // Setting event listeners breakpoints
      // Important note: these event listeners only fire if the above set media query values
      // change. They do NOT fire for every pixel. Hence they keep things performant.
      if (matchMedia.addEventListener) {
        matchMedia.addEventListener('change', this);
      }
      // For IE 11 as it does not the support onchanges event listener
      if (!matchMedia.addEventListener && !this.hasUpatedForIE && this.isIE) {
        this.activeViewports.desktop = true;
        this.viewportSizeIs$.next(this.activeViewports);
        this.hasUpatedForIE = true;
      }

      // this is needed to fire on first load
      if (matchMedia.matches) {
        this.handleEvent(matchMedia);
      }
    });

    const deviceOs = this.checkDeviceOs();
    if (deviceOs) {
      this.windowRef.nativeWindow.document.body.setAttribute('bw-device-os', deviceOs);
    }
  }

  /**
   * Check device OS (ios, android etc)
   * @returns
   */
  checkDeviceOs(): DeviceOs {
    const userAgent = this.windowRef.nativeWindow.navigator.userAgent || this.windowRef.nativeWindow.navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/window's phone/i.test(userAgent)) {
      return 'windows-phone';
    }

    if (/android/i.test(userAgent)) {
      return 'android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'ios';
    }

    if (/Mac/.test(userAgent)) {
      return 'mac';
    }

    if (/Windows/.test(userAgent)) {
      return 'windows';
    }

    return 'unknown';
  }
}
