import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

/* bw:view-encapsulation */
@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'bw-loading-spinner',
  templateUrl: './loading-spinner.component.html'
})
export class LoadingSpinnerComponent {
  @Input() message: string;
  @Input() fullscreen: boolean = false;
}
