import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { ToastrComponent } from 'Base/app/toastr/components/toastr/toastr.component';
import { SharedModule } from 'Shared/shared.module';
import { FeedbackToastrComponent } from './components/feedback-toastr/feedback-toastr.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  providers: [ToastrService],
  declarations: [ToastrComponent, FeedbackToastrComponent],
  exports: [ToastrComponent, FeedbackToastrComponent]
})
export class BwToastrModule {}
