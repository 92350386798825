export class Experiment {
  variant: number;
  id: string;
  name: string;
  allowOverride: boolean = true;
  isRolledOut: boolean = false;

  constructor(obj: ExperimentOptions = {}) {
    this.variant = typeof obj.variant === 'string' ? parseInt(obj.variant, 10) : obj.variant;
    this.name = obj.name ? obj.name.toUpperCase().trim() : undefined;
    this.id = obj.id ? obj.id.trim() : undefined;
    this.allowOverride = typeof obj.allowOverride === 'undefined' ? true : obj.allowOverride;
  }
}

export interface ExperimentOptions {
  name?: string;
  id?: string;
  variant?: string | number;
  allowOverride?: boolean;
}
