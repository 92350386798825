
<div class="modal-header">
  <div class="modal-title">Account</div>
  <button class="close" aria-label="close" (click)="onCancel()"><span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
  <div class="bw-menu" #bwModal>
    <div class="bw-menu__group">
      <bw-button buttonStyle="secondary" size="medium" (clicked)="goTo('account.orders')" [fullWidth]="true" bwTrackAs="component.burger-modal.auto_key.orders">Orders</bw-button>
      <bw-button *ngIf="isRewardsEnabled" buttonStyle="secondary" size="medium" (clicked)="goTo('account.rewards')" [fullWidth]="true" bwTrackAs="component.burger-modal.auto_key.rewards">Rewards</bw-button>
      <bw-button buttonStyle="secondary" size="medium" (clicked)="goTo('account.occasions')" [fullWidth]="true" bwTrackAs="component.burger-modal.my_occasions">Occasions</bw-button>
      <bw-button buttonStyle="secondary" size="medium" (clicked)="goTo('account.favourites')" [fullWidth]="true" bwTrackAs="component.burger-modal.favourites">Favourites</bw-button>
      <bw-button buttonStyle="secondary" size="medium" (clicked)="goTo('account.credit')" [fullWidth]="true" bwTrackAs="component.burger-modal.auto_key.credit">Credit<span *ngIf="user?.hasCredit()">: {{user | userCredit | price}}</span></bw-button>
    </div>
    <div class="bw-menu__group hidden-md hidden-lg" *ngIf="user?.hasCredit()">
      <bw-i18n-selector></bw-i18n-selector>
    </div>
    <div class="bw-menu__group">
      <bw-button buttonStyle="modal-tertiary" size="medium" (clicked)="goTo('account.addresses')" [fullWidth]="true" bwTrackAs="component.burger-modal.auto_key.addresses">Delivery addresses</bw-button>
      <bw-button buttonStyle="modal-tertiary" size="medium" (clicked)="goTo('account.marketing')" [fullWidth]="true" bwTrackAs="component.burger-modal.auto_key.marketing">Marketing preferences</bw-button>
      <bw-button buttonStyle="modal-tertiary" size="medium" (clicked)="goTo('account.cards')" [fullWidth]="true" bwTrackAs="component.burger-modal.auto_key.payment_options">Payment options</bw-button>
      <bw-button buttonStyle="modal-tertiary" size="medium" (clicked)="goTo('account.details')" [fullWidth]="true" bwTrackAs="component.burger-modal.auto_key.account_details">Account details</bw-button>
    </div>
    <div class="bw-menu__group">
      <bw-button buttonStyle="modal-tertiary" size="medium" [fullWidth]="true" *ngFor="let link of burgerLinks" href="{{link.url}}" (clicked)="onCancel()">{{link.name}}</bw-button>
    </div>
  </div>
</div>
<div class="modal-footer center">
  <bw-button buttonStyle="modal-tertiary" size="medium" (clicked)="logout()" bwTrackAs="component.burger-modal.auto_key.logout">Log out</bw-button>
</div>