
<div class="bw-mega-nav-desktop-child hidden-xs" #childNavItem [ngStyle]="{'background-color': childSectionColour, 'min-height.px': height, 'transform': 'translateX(' + horizontalAdjustment + 'px)'}">
  <div class="bw-mega-nav-desktop-child__container container d-flex">
    <div class="bw-mega-nav-desktop-child__container__sections d-flex" *ngFor="let section of childSectionLinks" [ngClass]="{'is--type-content': section.sectionType === 'content'}">
      <ul class="bw-mega-nav-desktop-child__container__section list-unstyled" [ngStyle]="{'align-items' : section.sectionType === 'content' ? 'flex-end' : '' }">
        <li class="bw-mega-nav-desktop-child__container__link" *ngIf="section.sectionType !== 'content'"><a class="link__child sectionHeader" *ngIf="section.sectionType === 'subnav'" (click)="navDidClicked(section)" (keyup)="handleKeyUp($event)" [attr.href]="section.url" attr.aria-label="{{section.name}} {{section.styles}}." [tabindex]="section.name &amp;&amp; section.url ? 0  : -1"><span class="section-name" [ngStyle]="{'text-decoration-color' : childSectionUnderline }">{{section.name}}</span><span [ngClass]="section.styles"></span></a></li>
        <ng-container *ngIf="section.sectionType !== 'content'">
          <li class="bw-mega-nav-desktop-child__container__link" *ngFor="let subchild of section.links"><a class="link__child" (click)="navDidClicked(subchild)" (keyup)="handleKeyUp($event)" [attr.href]="subchild.url" attr.aria-label="{{subchild.name}} {{subchild.styles}}." [tabindex]="subchild.name &amp;&amp; subchild.url ? 0  : -1"><span [ngStyle]="{'text-decoration-color' : childSectionUnderline }">{{subchild.name}}</span><span [ngClass]="subchild.styles"></span></a></li>
        </ng-container>
        <ng-container *ngIf="section.sectionType === 'content'">
          <bw-content-spot *ngIf="loadImages" contentId="megnav" fragment="#{{section.name}}" (click)="closeAllNavs(); contentSpotClicked(section)" (keyup)="handleKeyUp($event)"></bw-content-spot>
        </ng-container>
      </ul>
    </div>
  </div>
</div>