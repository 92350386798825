import * as dayjs from 'dayjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigModelService } from 'Shared/models/config-model.service';
import { ConfigService } from 'Shared/services/config.service';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { ZendeskWidgetService } from 'Shared/services/third-parties/zendesk-widget.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-live-contact',
  templateUrl: './live-contact.component.html'
})
export class LiveContactComponent implements OnInit {
  serverTime: dayjs.Dayjs;
  chatOnline: boolean;
  phoneEnabled: boolean;
  @ViewChild('dropdown', { static: true } as any)
  dropdown: BsDropdownDirective;

  constructor(
    private remoteConfig: ConfigModelService,
    private configService: ConfigService,
    private zendeskChat: ZendeskWidgetService
  ) {}

  chatOnlineSubscribe(): void {
    this.zendeskChat.online$.subscribe((online) => {
      this.chatOnline = online;
    });
  }

  openChat(): void {
    this.zendeskChat.openChat();
  }

  /**
   * Prevent Close
   * @param event
   */
  preventClose(event: Event): void {
    event.stopImmediatePropagation();
  }

  closeDropdown(): void {
    this.dropdown.hide();
  }

  ngOnInit(): Promise<any> {
    this.chatOnlineSubscribe();
    return this.remoteConfig.hasRemoteConfig().then(() => {
      const config = this.configService.getConfig();
      this.phoneEnabled = config[`${config.site}_contact_us_phone_enabled`];
      this.serverTime = config.serverTime;
    });
  }
}
