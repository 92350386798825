/**
 * Digital Genius Documentation:
 * Guide -> https://docs.digitalgenius.com/docs/integrating-genius-chat
 * Configuration-> https://docs.digitalgenius.com/docs/configuration
 *
 * DEVELOPMENT NOTES:
 * Enable isChatLauncherEnabled in env config to work on localhost:8080
 * DG doesn't work on localhost:4200
 */
import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { ConfigService } from 'Shared/services/config.service';
import { WindowRefService } from 'Shared/services/window.service';
import { ErrorItem } from 'Shared/classes/error';
import { ExperimentsService } from '../experiments.service';
import { FeaturesService } from '../features.service';

interface DigitalGeniusSettings {
  widgetId: string;
  env: string;
  metadata: {
    brand: string;
    lang: string;
  };
  generalSettings: {
    isChatLauncherEnabled?: boolean;
  };
}

interface DigitalGeniusConfig {
  widgetId: string;
  env: string;
  scriptUrl: string;
  suncoUrl: string;
  lang: string;
  isChatLauncherEnabled: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DigitalGeniusWidgetService {
  private digitalGeniusConfig: DigitalGeniusConfig;
  private debug: boolean = false;
  private window: Window;

  constructor(
    private domUtils: DomUtilsService,
    private configService: ConfigService,
    private windowRefService: WindowRefService,
    private experimentService: ExperimentsService,
    private featuresService: FeaturesService
  ) {
    this.digitalGeniusConfig = this.configService.getConfig().digitalGenius;
    this.window = this.windowRefService.nativeWindow;
  }

  /**
   * Inilialize the digitalGenius Widget
   * @returns {Promise<void>}
   */
  public init(): Promise<void> {
    return this.initDG();
  }

  public launchWidget(): void {
    if (this.checkDGInitConfiguration()) {
      this.window['dgchat'].methods?.launchWidget();
    } else {
      this.initDG()
        .finally((): void => {
          if (this.checkDGInitConfiguration()) {
            this.window['dgchat'].methods?.launchWidget();
          }
        })
        .catch((error): void => this.log(error));
    }
  }

  /**
   * Init Digital Genius
   */
  private initDG(): Promise<void> {
    if (this.checkDGInitConfiguration()) {
      return Promise.resolve();
    }

    this.configureDG();

    this.log('Init');

    return this.domUtils
      .loadScript(this.digitalGeniusConfig?.scriptUrl, 'dg-script')
      .then((): Promise<void> => this.domUtils.loadScript(this.digitalGeniusConfig?.suncoUrl, 'dg-sunco'))
      .then((): void => {
        this.log('init');
        if (this.checkDGInitConfiguration()) {
          this.window['dgchat'].init(); // digital genius init function
        }
      })
      .catch((e: ErrorItem): void => {
        this.log(e.message);
      });
  }

  /**
   * Set Config variable
   */
  private configureDG(): void {
    const obj: DigitalGeniusSettings = {
      widgetId: this.digitalGeniusConfig?.widgetId,
      env: this.digitalGeniusConfig?.env,
      metadata: {
        brand: this.configService.getConfig().brandId,
        lang: this.digitalGeniusConfig?.lang
      },
      generalSettings: {
        isChatLauncherEnabled: this.digitalGeniusConfig?.isChatLauncherEnabled
      }
    };

    this.window['DG_CHAT_WIDGET_CONFIG'] = obj;
  }

  /**
   * Log
   */
  private log(...args: unknown[]): void {
    if (this.debug) {
      console.log('<digitalGenius-chat>', ...args);
    }
  }

  /**
   * Check DG been init
   */
  private checkDGInitConfiguration(): boolean {
    return this.window['DG_CHAT_WIDGET_CONFIG']?.generalSettings?.isChatLauncherEnabled && this.window['dgchat'] !== undefined;
  }
}
