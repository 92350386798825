import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy, EmbeddedViewRef } from '@angular/core';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { Experiment } from 'Shared/classes/experiment';
import { ConfigService } from 'Shared/services/config.service';

/**
 * To use:
 * div(*inExperiment="{name: 'HELLO_WORLD', variants: [1,2]}") Hello World Variant 1 or 2
 *
 * div(*inExperiment="{name: 'HELLO_WORLD', variants: [0]}") Hello World Variant 0
 *
 */
@Directive({
  selector: '[inExperiment]'
})
export class InExperimentDirective implements OnInit, OnDestroy {
  experimentSubscriber: any;
  experimentName: string;
  experimentVariants: number[];
  classNames: string[] = [];
  orSiteIs: string[] = [];
  isShown: boolean = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private experimentsService: ExperimentsService,
    private configService: ConfigService
  ) {}

  @Input()
  set inExperiment(val) {
    this.experimentName = val.name;
    this.experimentVariants = val.variants.map((n) => parseInt(n, 10));
    this.classNames = val.classNames || [];
    this.orSiteIs = val.orSiteIs || [];
  }

  /**
   * Show the component
   */
  showComponent(): EmbeddedViewRef<any> | void {
    if (!this.isShown) {
      this.isShown = true;
      return this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  /**
   * Hide the component
   */
  hideComponent(): void {
    if (this.isShown) {
      this.viewContainer.clear();
      this.isShown = false;
    }
  }

  /**
   * Destroy to stop memory leaks
   */
  ngOnDestroy(): void {
    if (this.experimentSubscriber) {
      this.experimentSubscriber.unsubscribe();
    }
  }

  /**
   * If we are in the active variant
   * @param experiment
   */
  isActiveVariant(experiment?: Experiment): boolean {
    const variant = experiment ? experiment.variant : 0;
    return this.experimentVariants.indexOf(variant) > -1;
  }

  /**
   * Are we the active site?
   * @param sites
   */
  isActiveSite(sites: string[]): boolean {
    return sites.indexOf(this.configService.getConfig().site) > -1;
  }

  /**
   * Toggle Component showing/hiding
   */
  toggleComponentBasedOnExperiment(isActive: boolean): void {
    isActive ? this.showComponent() : this.hideComponent();
  }

  /**
   * Toggle classes if the experiment is active
   */
  toggeClassesIfExperimentActive(classNames: string[], isActive: boolean): void {
    const viewContainer = this.showComponent();

    if (!viewContainer || !viewContainer.rootNodes || !viewContainer.rootNodes.length) {
      return; // Fail fast - something didn't create, we can't get the DOM
    }

    const elem = viewContainer['rootNodes'][0];
    if (!elem || !elem.classList) {
      return;
    }

    if (isActive) {
      classNames.forEach((className) => {
        elem.classList.add(className);
      });
    } else {
      classNames.forEach((className) => {
        elem.classList.remove(className);
      });
    }
  }

  /**
   * On experiment change
   * @param experiments
   */
  onExperimentChange(experiments: { [key: string]: Experiment }): void {
    const isActiveVariant =
      (this.orSiteIs && this.orSiteIs.length && this.isActiveSite(this.orSiteIs)) || this.isActiveVariant(experiments[this.experimentName]);

    if (this.classNames && this.classNames.length) {
      this.toggeClassesIfExperimentActive(this.classNames, isActiveVariant);
    } else {
      this.toggleComponentBasedOnExperiment(isActiveVariant);
    }
  }

  /**
   * Subscribe to any experiment changes, and check if we are in the experiment
   */
  ngOnInit(): void {
    this.experimentSubscriber = this.experimentsService.experimentsObj$.subscribe((experiments) => {
      this.onExperimentChange(experiments);
    });
  }
}
