/**
 *
 * This is a component to allow tooltip from contentful. It's currently only used in the above carousel SEO header:
 * <div class="above-carousel-seo">
 *   <h1>Pretty Peonies</h1>
 *  <p class="hidden-xs hidden-sm">More stuff here <a tooltip-content="nametogohere">Click me</a></p>
 * </div>
 *
 *
 * The attribute `tooltip-content` must match the HTML element called `tooltip-content`
 * <tooltip-content id="nametogohere" has-background="true">
 *  <div style="width:400px">
 *     <h3>This should be inside </h3>
 *  </div>
 *  </tooltip-content>
 *
 */

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { NgxPopperjsContentComponent } from 'ngx-popperjs';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';

@Component({
  selector: '[tooltip-content]',
  templateUrl: './tooltip-content.component.html',
  styleUrls: ['./tooltip-content.component.scss']
})
export class TooltipContentComponent implements OnInit {
  @Input('tooltipcontent')
  tooltipcontent: string; // Associated id of the content
  dropdownShown: boolean = false;
  popperClasses: string = 'popper--no-padding,popper--background-white';
  tooltipHTMLContent: string;
  document: any;
  popperTrigger: NgxPopperjsTriggers = NgxPopperjsTriggers.click;
  popperPlacement: NgxPopperjsPlacements = NgxPopperjsPlacements.BOTTOMEND;

  @ViewChild('contentfulTooltipContent', { static: true } as any)
  tooltip: NgxPopperjsContentComponent;

  contentfulTooltipContent: any;

  constructor(private windowRef: WindowRefService, private analyticsService: AnalyticsService) {
    this.document = this.windowRef.nativeWindow.document;
  }

  hide(): void {
    this.tooltip.hide();
  }

  onPopperShown(): void {
    this.dropdownShown = true;
    this.analyticsService.track('component.tooltip.show', {
      id: this.tooltipcontent
    });
  }
  onPopperHide(): void {
    this.dropdownShown = false;
  }

  ngOnInit(): void {
    const associatedElement = this.document.querySelector(`tooltip-content#${this.tooltipcontent}`);
    if (!associatedElement) {
      return;
    }

    this.tooltipHTMLContent = associatedElement.innerHTML;
  }
}
