import { CarouselExperiment } from 'Shared/classes/carousel-experiment';
import * as clone from 'clone';

export class Carousel {
  type: string;
  typeValue: string;
  userSegmentId: number;
  productIds: number[] = [];
  experiments: CarouselExperiment[] = [];

  /**
   * Clone
   */
  clone(): Carousel {
    return clone(this);
  }
}
