
<fieldset class="bw-form-dropdown" *ngIf="bwFormControl" [ngClass]="{
    'is-invalid': bwFormControl.invalid,
    'is-valid': bwFormControl.valid,
    'is-dirty': bwFormControl.dirty,
    'is-pristine': bwFormControl.pristine,
    'is-touched': bwFormControl.touched,
    'is-untouched': bwFormControl.untouched,
    'is-submitted': hasSubmitted,
    'is-focused': isFocused || isDropdownOpen,
    'is-display': inputType === 'display' || isDisplay,
    'is-search' : inputType === 'search',
    'is-open': isDropdownOpen,
    'has-expanded-container-when-open': expandContainerWhenOpen,
    'has-max-height': hasMaxHeight,
    'has-data': data?.length,
    'has-instructions': hasInstructions,
    'has-placeholder': placeholder,
    'has-value': !!bwFormControl.value
    }">
  <div class="dropdown__container" [bwClickOutside]="isDropdownOpen" (clickOutside)="onOusideClick()">
    <div class="dropdown__control" aria-haspopup="listbox" [attr.aria-controls]="id" [attr.aria-expanded]="isDropdownOpen">
      <bw-form-text-input *ngIf="inputType === 'display'" [bwFormControl]="bwFormControl" [label]="label" inputType="display" [displayFn]="displayFn" [placeholder]="placeholder" [forceShowPlaceholder]="forceShowPlaceholder" [multiline]="multiline" (didClick)="onInputClicked()" (didFocus)="onFocus()" (didBlur)="onBlur()"></bw-form-text-input>
      <bw-form-text-input *ngIf="inputType === 'search'" [bwFormControl]="isDisplay ? bwFormControl : formGroup.controls.search" [label]="label" [inputType]="isDisplay ? 'display' : 'text'" [displayFn]="displayFn" [allowClear]="allowClear &amp;&amp; !isDisplay" [placeholder]="placeholder" [forceShowPlaceholder]="forceShowPlaceholder" [multiline]="isDisplay ? multiline : false" [autoComplete]="autoComplete" (didClick)="onInputClicked()" (didFocus)="onFocus()" (didBlur)="onBlur()"></bw-form-text-input>
      <div class="control__icon-holder holder--status" *ngIf="!isFocused &amp;&amp; (inputType !== 'display' &amp;&amp; !isDisplay) &amp;&amp; (hasSubmitted || bwFormControl.touched)">
        <bw-icon class="action--invalid" *ngIf="bwFormControl.invalid" [iconType]="'red-alert'"></bw-icon>
        <bw-icon class="action--valid" *ngIf="bwFormControl.valid" [iconType]="'tick__success'"></bw-icon>
      </div>
    </div>
    <div class="dropdown__content__wrapper" tabindex="-1">
      <ul class="dropdown__content" [attr.id]="id" tabindex="-1" role="listbox" [ngClass]="{ 'is-loading': showLoading }">
        <li class="dropdown__option" *ngFor="let option of data; index as optIdx; trackBy: trackBy" [attr.id]="'option-' + optIdx" (click)="onOptionSelected(option, optIdx)" (keyup.enter)="onOptionSelected(option, optIdx)" tabindex="0" role="option" [ngClass]="{
              'has-description': option?.description,
              'is-selected': selectedIndex === optIdx,
              'has-prefix-icon': option?.prefixIcon
              }">
          <div class="option__label">
            <bw-icon *ngIf="option?.prefixIcon" [iconType]="option?.prefixIcon"></bw-icon>
            <div class="option__text">{{ option.label }}</div>
            <div class="option__subtext" *ngIf="option?.subtext || optionSubtext">{{ option.subtext || optionSubtext }}</div>
          </div>
          <div class="option__description" *ngIf="option?.description">{{ option.description }}</div>
        </li>
        <li class="dropdown__instructions" #instructions tabindex="-1">
          <ng-content select="[role=instructions]"></ng-content>
        </li>
        <bw-loading-spinner *ngIf="showLoading"></bw-loading-spinner>
      </ul>
    </div>
  </div>
  <!-- Error messages-->
  <bw-formcontrol-validation-messages [errors]="bwFormControl.errors" [touched]="bwFormControl.touched" [formSubmitted]="hasSubmitted">
    <ng-content select="[role=errors]"></ng-content>
  </bw-formcontrol-validation-messages>
</fieldset>