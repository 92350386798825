import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ViewportDetectionService } from 'Shared/services/viewport-detection.service';
import { TabItemContent } from 'Shared/classes/help-center';
import { BehaviorSubject } from 'rxjs';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-vertical-tabs',
  templateUrl: './vertical-tabs.component.html'
})
export class VerticalTabsComponent implements OnInit, OnChanges {
  @Input() tabs: TabItemContent[];
  @Input() selected: TabItemContent;
  @Output() select: EventEmitter<any> = new EventEmitter();

  selectedTab: TabItemContent;
  viewportSizeIs$: BehaviorSubject<any> = this.viewPortDetection.viewportSizeIs$;

  constructor(private viewPortDetection: ViewportDetectionService) {}

  /**
   * On init
   */
  ngOnInit(): void {
    this.tabs = typeof this.tabs === 'string' ? JSON.parse(this.tabs as any) : this.tabs;

    if (this.selected) {
      this.selectedTab = this.selected; // TO-THINK: Maybe should only set the selected tab if it can be found in this.tabs
    }
    if (!this.selectedTab && this.tabs && this.tabs.length) {
      this.selectedTab = this.tabs[0];
    }
  }

  /**
   * On change
   */
  ngOnChanges(): void {
    if (this.selected) {
      this.selectedTab = this.selected;
    }
  }

  /**
   * On selecting
   * @param content
   */
  selectTab(content: any): void {
    this.selectedTab = content;
    this.select.emit(this.selectedTab);
  }
}
