
<div class="product-card__content">
  <!-- Previously purchased callout-->
  <div class="content__lemon-ribbon" *ngIf="product['previouslyPurchased'] &amp;&amp; ((gridViewToggle$ | async).view !== 'column' || (gridViewToggle$ | async).view === 'column' &amp;&amp; (viewportSizeIs$ | async)?.not.mobile)">You've sent this before</div>
  <ng-container *ngIf="!isGiftVoucher; else giftVoucherTitle">
    <!-- Product Name-->
    <h3 class="content__product-name" *featureDisabled="'GRID_SWITCHER'" (click)="productInfoSelected()" itemprop="name" [ngClass]="{'longSkuName' : product?.name.length &gt; 15}">{{product.name}}</h3>
    <!-- Product Name Grid Switcher-->
    <h3 class="content__product-name" *featureEnabled="'GRID_SWITCHER'" (click)="productInfoSelected()" itemprop="name">{{product.name}}</h3>
    <!-- Product Collection-->
    <h4 class="content__product-collection">{{product?.collectionName}}</h4>
  </ng-container>
  <!-- Gift Voucher Title-->
  <ng-template #giftVoucherTitle>
    <h3 class="content__product-name" *ngIf="isGiftVoucher">Gift Cards</h3>
    <h4 class="content__product-collection" *ngIf="isGiftVoucher">Digital Gift Cards</h4>
  </ng-template>
  <!-- ********* REVIEWS START ***********-->
  <ng-container *ngIf="product.rating.count &amp;&amp; showRating"><a class="content__reviews" *featureEnabled="'RATINGS'" (click)="productReviewsSelected()" bwtrackas="component.product-carousel.review" attr.aria-label="{{product.rating.count}} customers have reviewed {{product.name}} {{product.rating.average}} out of 5">
      <bw-review-stars [rating]="product.rating.average" [roundingScale]="0.25"></bw-review-stars><span class="content__reviews-count" *ngIf="product.rating.count === 1" itemprop="reviewCount">({{product.rating.count}})</span><span class="content__reviews-count" *ngIf="product.rating.count !== 1" itemprop="reviewCount">({{product.rating.count}})</span></a></ng-container>
  <!-- ********* REVIEWS END ***********-->
  <div class="content__product-price-cta">
    <!-- ********* PRICING START *********-->
    <div class="content__product-price" [ngClass]="{'contains-discount': product.price?.discount}"><span class="price price--is-from" *ngIf="isGiftVoucher">From&nbsp;</span>
      <!-- One-off Price-->
      <ng-container *ngIf="!showSubscriptionPricing &amp;&amp; !showBundlePricing"><span class="product-price--is-discounted" *ngIf="product.price?.discount">{{product.price | price:'original'}}</span><span class="product-price" itemprop="price">{{product.price | price}}</span></ng-container>
      <!-- Subscription Price-->
      <ng-container *ngIf="showSubscriptionPricing">
        <!-- Subscription price 'From' or 'First delivery from'  etc-->
        <div class="product-card-price__from" *ngIf="!productDiscount || productDiscount.toDeliveryNumber === -1">From</div>
        <div class="product-card-price__from" *ngIf="productDiscount?.toDeliveryNumber &gt; 1">First {{ productDiscount.toDeliveryNumber }} deliveries from</div>
        <div class="product-card-price__from" *ngIf="productDiscount?.toDeliveryNumber === 1">First delivery from</div><span class="product-price--is-discounted" *ngIf="product['subscriptionPrice']?.discount">{{product['subscriptionPrice'] | price:'original'}}</span><span class="product-price" itemprop="price">{{product['subscriptionPrice'] | price}}</span>
      </ng-container>
      <!-- Bundle Price-->
      <ng-container *ngIf="showBundlePricing &amp;&amp; product['threeMonthPrice']">
        <div class="product-card-price__from">From</div><span class="product-price--is-discounted" *ngIf="product['threeMonthPrice']?.discount">{{product['threeMonthPrice'] | price:'original'}}</span><span class="product-price" itemprop="price">{{product['threeMonthPrice'] | price}}</span>
      </ng-container>
    </div>
    <!-- ********* PRICING END *********-->
    <!-- ********* CTA START ***********-->
    <!-- ********* POT CTA *************-->
    <div class="content__product-cta">
      <bw-button *ngIf="isGiftVoucher" buttonStyle="secondary" uiSref="giftvouchers" bwTrackAs="checkout.carousel.carouselProduct.carouselSend">See Options</bw-button>
      <ng-container *ngIf="selectedProductCardType === productCardType.standard &amp;&amp; !isGiftVoucher">
        <bw-button *featureDisabled="'GRID_SWITCHER'" buttonStyle="primary" size="large" (clicked)="productSelected()" bwTrackAs="checkout.carousel.carouselProduct.carouselSend">Send</bw-button>
        <!-- GRID_SWITCHER - Stack View-->
        <ng-container *ngIf="(gridViewToggle$ | async).view === 'stack' || ((gridViewToggle$ | async).view === 'column' &amp;&amp; (viewportSizeIs$ | async)?.not.mobile)">
          <bw-button *featureEnabled="'GRID_SWITCHER'" buttonStyle="primary" size="large" (clicked)="productSelected()" bwTrackAs="checkout.carousel.carouselProduct.carouselSend"><span>Send</span></bw-button>
        </ng-container>
        <!-- GRID_SWITCHER - Column View-->
        <ng-container *ngIf="(gridViewToggle$ | async).view === 'column' &amp;&amp; (viewportSizeIs$ | async)?.mobile">
          <bw-button *featureEnabled="'GRID_SWITCHER'" buttonStyle="primary" size="small" (clicked)="productSelected()" bwTrackAs="checkout.carousel.carouselProduct.carouselSend"><span>Send</span></bw-button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="selectedProductCardType === productCardType.recentlyViewed &amp;&amp; !isGiftVoucher">
        <bw-button buttonStyle="primary" size="small" (clicked)="productSelected()" bwTrackAs="component.browse-suggestion.view"><span>View</span></bw-button>
      </ng-container>
    </div>
    <!-- ********* CTA END ***********-->
  </div>
</div>