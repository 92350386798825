import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Product } from 'Shared/classes/product';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-product-info-section',
  templateUrl: './product-info-section.component.html'
})
export class ProductInfoSectionComponent implements OnChanges {
  @Input() product: Product;

  /**
   * On changes - force digest so image is destroyed and reloaded
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.product = new Product();
    setTimeout(() => {
      this.product = changes.product.currentValue;
    }, 0);
  }
}
