import { Pipe, PipeTransform } from '@angular/core';
import { siteConfig } from 'Environments/site-config';
import { CountryService, Country } from 'Shared/services/country.service';
import { FeaturesService, FeatureKey, AvailableFeatures } from 'Shared/services/features.service';

type SiteConfig = typeof siteConfig[keyof typeof siteConfig];
type SiteConfigKey = keyof SiteConfig;
type SiteConfigValueType<T> = T extends SiteConfigKey
  ? SiteConfig[T]
  : T extends FeatureKey
  ? AvailableFeatures[T]
  : never;

@Pipe({
  name: 'siteConfig'
})
export class SiteConfigPipe implements PipeTransform {
  country: Country;
  siteConfig = siteConfig;

  constructor(countryService: CountryService, private featuresService: FeaturesService) {
    this.country = countryService.forShipping;
  }

  /**
   * Get the value from the features, using a key system eg GDPR.
   * @param features
   * @param key
   */
  fromFeatures<T, U extends FeatureKey>(
    features: AvailableFeatures,
    key: U
  ): string | number | boolean {
    try {
      return (key as string).split('.').reduce((o, i) => o[i], features);
    } catch (e) {
      return '';
    }
  }

  /**
   * Either find it from features, or try from site config
   * TODO: getting values/keys from features file is not used, so we'll tidy this up in #178697418
   * @param key
   * @param args
   */
  transform<T extends SiteConfigKey | FeatureKey>(key: T): SiteConfigValueType<T> {
    const features = this.featuresService.getAllFeatures();

    const asFeature = this.fromFeatures(features, key as FeatureKey);
    if (asFeature) {
      return asFeature as SiteConfigValueType<T>;
    }
    if (this.siteConfig && this.siteConfig[`${this.country.id}`]) {
      return this.siteConfig[`${this.country.id}`][key];
    }
    return '' as SiteConfigValueType<T>;
  }
}
