import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { WindowRefService } from 'Shared/services/window.service';
import { ConfigService } from 'Shared/services/config.service';
import { ModalService } from 'Shared/services/modal.service';
import { UserService } from 'Shared/services/user.service';
import { Error } from 'Shared/classes/error';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { CredentialResponse } from 'google-one-tap';
import { Subject } from 'rxjs';

const DEFAULT_WIDTH = 400;
@Injectable({
  providedIn: 'root'
})
export class GoogleService {
  private initPromise: Promise<unknown>;
  private serviceInitialization: boolean = false;
  private window: Window;
  public googleCredential$: Subject<string> = new Subject();

  constructor(
    private domUtils: DomUtilsService,
    private windowRefService: WindowRefService,
    private configService: ConfigService,
    private modalService: ModalService,
    private userService: UserService,
    private toastrService: ToastrService
  ) {
    this.window = this.windowRefService.nativeWindow;
  }

  /**
   * Handle google callback response
   * @param creditentailsResponse
   */
  handleCredentialResponse(creditentailsResponse: CredentialResponse): Promise<void> {
    return this.userService
      .googleLogin(creditentailsResponse.credential)
      .then(() => {
        this.modalService.hideAllModals();
        this.googleCredential$.next(creditentailsResponse.credential);
      })
      .catch((e: Error) => {
        this.toastrService.error(e.message, e.title);
      });
  }

  /**
   * Handle triggering of google load event
   */
  loadLibrary(): void {
    if (this.serviceInitialization) {
      this.window.onGoogleLibaryLoad();
    }
  }

  /**
   * Define and calculate button width
   */
  private calculateButtonWidth(isModal = false): number {
    const query = isModal ? 'bw-modal-base' : '';
    const width = document
      .querySelector(`${query} .bw-auth-third-party`)
      ?.getBoundingClientRect()?.width;
    return width && width < DEFAULT_WIDTH ? width : DEFAULT_WIDTH;
  }

  /**
   * Define google on load even including button
   * @param elementId
   */
  initLibrary(isModal?: boolean, elementId = 'googleButton'): void {
    const config = this.configService.getConfig();

    this.window.onGoogleLibaryLoad = () => {
      this.window.google.accounts.id.initialize({
        client_id: config.googleClientId,
        callback: (res) => this.handleCredentialResponse(res),
        auto_select: false,
        cancel_on_tap_outside: true,
        itp_support: false
      });

      const element = document.querySelector('bw-modal-base') ?? document;
      const button = element.querySelector(`#${elementId}`) as HTMLElement;
      const shape = config.brandId === 'bloomon' ? 'circle' : 'rectangular';
      const width = this.calculateButtonWidth(isModal);
      this.window.google.accounts.id.renderButton(button, {
        type: 'standard',
        theme: 'outline',
        size: 'medium',
        shape,
        width,
        logo_alignment: 'center',
        text: 'continue_with',
        locale: config.languageCountryLocale
      });

      // To display one tap dialog box
      // this.window.google.accounts.id.prompt((_) => {});
    };
  }

  /**
   * Init Google Client Library
   */
  initClient(): Promise<unknown> {
    this.initPromise =
      this.initPromise ??
      this.domUtils
        .loadScript('https://accounts.google.com/gsi/client', 'google-client')
        .then(() => {
          this.serviceInitialization = true;
          this.initLibrary(false);
        });

    return this.initPromise;
  }
}
