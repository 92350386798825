import { Component, Input, OnInit } from '@angular/core';
import { BlogItemsService } from 'Project/content/services/BlogItemsService.service';
import { BlogCardItem } from 'Shared/interfaces/blog-card-item';

@Component({
  selector: 'bw-blog-carousel',
  templateUrl: './blog-carousel.component.html',
  styleUrls: ['./blog-carousel.component.scss']
})
export class BlogCarouselComponent implements OnInit {
  blogs: BlogCardItem[];
  constructor(private blogItemsService: BlogItemsService) {}

  ngOnInit(): Promise<BlogCardItem[] | void> {
    // getting blog items with undefiend tags gets us all the blogs
    return this.blogItemsService.getBlogItems(undefined).then((items): void => {
      this.blogs = items;
    });
  }
}
