export default function ngTemplateInterceptor(item): any {
  const element = document.createElement('div');
  element.setAttribute('id', item.id.replace(/\//g, '_'));
  element.setAttribute('style', 'display: none !important;');
  element.innerHTML = item.innerHTML;

  item.parentNode.removeChild(item);

  document.body.appendChild(element);

  return item;
}
