import * as dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import { BackendService } from 'Shared/services/backend.service';
import { WindowRefService } from 'Shared/services/window.service';
import { Purchase } from 'Shared/classes/purchase';
import { RemoteConfig, StripeAccount } from 'Shared/models/config-model.service.typings';
import { Country } from 'Shared/classes/country';

@Injectable({
  providedIn: 'root'
})
export class ConfigModelService {
  private window;
  private promiseCache;
  private remoteConfig: RemoteConfig;

  /**
   * Constructor
   * @param backend
   * @param windowRefService
   */
  constructor(private backend: BackendService, private windowRefService: WindowRefService) {
    this.window = this.windowRefService.nativeWindow;
  }

  /**
   * Get the paypal token
   */
  getPaypalToken(shippingCountry: Country): Promise<string> {
    return this.backend
      .get(null, '/v1/payment_tokens/braintree_client_token', {
        params: {
          shipping_country_id: shippingCountry?.id
        }
      })
      .then((r) => r.braintree_client_token || null);
  }

  /**
   * Get the remote backend config
   * This is part of the route guard, and is therefore available in every component
   */
  hasRemoteConfig(): Promise<RemoteConfig> {
    if (this.promiseCache) {
      return this.promiseCache;
    }

    const fallbackPromise = () => this.backend.get(null, '/v1/config');
    const promise = this.windowRefService.getCacheAsPromise('config', fallbackPromise);

    this.promiseCache = promise.then((c) => {
      c.serverTime = dayjs(c.current_time);
      this.remoteConfig = c;
      return this.remoteConfig;
    });
    return this.promiseCache;
  }

  /**
   * Gets the config
   */
  getConfig(): RemoteConfig {
    return this.remoteConfig;
  }

  /**
   * Get the klarna token for a given purchase
   * @param purchase
   */
  getKlarnaToken(purchase: Purchase): Promise<string> {
    return this.backend
      .get(null, '/v1/payment_tokens/klarna_client_token', {
        params: {
          purchase_id: purchase.id
        },
        sendExperiments: true
      })
      .then((r) => (r ? r.klarna_client_token : null) || Promise.reject('No token'));
  }

  /**
   * Get stripe account config
   * @param shippingCountry
   */
  getStripeAccount(shippingCountry: Country): Promise<StripeAccount> {
    return this.backend
      .get(null, '/v1/payment_tokens/stripe_account', {
        params: {
          shipping_country_id: shippingCountry?.id
        }
      })
      .then(
        (res) =>
          (res
            ? {
                stripeClientKey: res.stripe_client_id,
                stripeAccountId: res.stripe_account
              }
            : null) || Promise.reject('No token')
      );
  }
}
