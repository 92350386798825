import { Injectable } from '@angular/core';
import { Addon } from 'Shared/classes/addon';

@Injectable({
  providedIn: 'root'
})
export class ArrayUtils {
  /**
   * Find in the array, or return the first one
   * @param items
   * @param toFind
   * @returns
   */
  static findOrReturnFirst<Type>(items: Type[], toFind?: Type): Type | undefined {
    if (!items.length) {
      return undefined;
    }
    return (toFind && items.find((s) => s['id'] === toFind['id'])) || items[0];
  }

  /**
   * Move selected to front of array
   * @param selectedAddon
   * @param addons
   */
  static moveToFront(addonsToFront: Addon[], addons: Addon[]): Addon[] {
    const addonsCopy = addons.slice();

    for (let i = (addonsToFront || []).length - 1; i >= 0; i--) {
      const addon = addonsToFront[i];
      const index = (addonsCopy || []).findIndex((a) => a.slug === addon.slug);
      if (index > -1) {
        addonsCopy.unshift(addonsCopy.splice(index, 1)[0]);
      }
    }

    return addonsCopy;
  }
}
