import { Injectable } from '@angular/core';
import { BackendService, APISerialisedJSONResponse, APIJSONRequest } from 'Shared/services/backend.service';
import { Email } from 'Shared/classes/email';
import { Error } from 'Shared/classes/error';
import { t } from 'Shared/utils/translations';

@Injectable({
  providedIn: 'root'
})
export class EmailModelService {
  name: string;

  constructor(private backend: BackendService) {}

  /**
   * To payload
   * @param email
   */
  static toPayload(email: Email): APIJSONRequest<'/v1/email_addresses'> {
    const occasionPreference = email.occasionPreferences || {};

    const data = {
      email: email.address.trim(),
      email_preference: email.preference as any, // Due to use of ENUM for preferenes, rather than a number
      method_of_consent: email.consent.method,
      location_of_consent: email.consent.location,
      consent_copy: email.consent.copy,
      content_preferences: {
        mothers_day: typeof occasionPreference.mothersDay === 'number' ? occasionPreference.mothersDay : undefined,
        fathers_day: typeof occasionPreference.fathersDay === 'number' ? occasionPreference.fathersDay : undefined,
        valentines_day: typeof occasionPreference.valentinesDay === 'number' ? occasionPreference.valentinesDay : undefined,
        grandparents_day: typeof occasionPreference.grandparentsDay === 'number' ? occasionPreference.grandparentsDay : undefined,
        grandmothers_day: typeof occasionPreference.grandmothersDay === 'number' ? occasionPreference.grandmothersDay : undefined,
        discount_promotions: typeof occasionPreference.discountPromotions === 'number' ? occasionPreference.discountPromotions : undefined,
        holiday_season: typeof occasionPreference.holidaySeason === 'number' ? occasionPreference.holidaySeason : undefined,
        survey: typeof email.surveyPreference === 'number' ? email.surveyPreference : undefined
      }
    };

    return data;
  }

  /**
   * From payload
   * @param data
   */
  static fromPayload(data: APISerialisedJSONResponse<'/v1/email_addresses'>): Email {
    const e = new Email(data.email);
    const pref = data.email_preference === null ? -1 : data.email_preference;
    e.preference = pref;
    e.hasOrdered = data.has_ordered;
    e.hasRegistered = data.registered;
    e.slug = data.id || data.external_id;
    e.sha256 = data.email_sha256;
    e.occasionPreferences = EmailModelService.setPreferencesFromPayload(data.content_preferences);
    e.surveyPreference = (data.content_preferences || {}).survey;
    return e;
  }

  /**
   * @description Set the occasion preferences from the payload
   * @param {APISerialisedJSONResponse<'/v1/email_addresses'>['content_preferences']} data
   * @returns {Email['occasionPreferences']}
   */
  static setPreferencesFromPayload(
    data: APISerialisedJSONResponse<'/v1/email_addresses'>['content_preferences']
  ): Email['occasionPreferences'] {
    return {
      mothersDay: (data ?? {}).mothers_day,
      fathersDay: (data ?? {}).fathers_day,
      valentinesDay: (data ?? {}).valentines_day,
      grandparentsDay: (data ?? {}).grandparents_day,
      grandmothersDay: (data ?? {}).grandmothers_day,
      discountPromotions: (data ?? {}).discount_promotions,
      holidaySeason: (data ?? {}).holiday_season
    };
  }

  /**
   * Check an email in backend
   * @param email
   */
  public check(email: string): Promise<Email> {
    return this.backend
      .get(null, '/v1/users/check_email_registered', {
        params: {
          email
        }
      })
      .then((res): Email => EmailModelService.fromPayload(res));
  }

  /**
   * Get email preference by email address
   * @param email
   */
  getPreferenceByAddress(email: Email): Promise<Email> {
    return this.backend
      .post(null, '/v1/users/check_email_registered', {
        email: email.address
      })
      .then((res): Email => EmailModelService.fromPayload(res));
  }

  /**
   * Get the email preference for a given email slug
   * @param emailSlug
   */
  public getPreferenceBySlug(emailSlug: string): Promise<Email> {
    return this.backend.get(null, `/v1/email_addresses/${emailSlug}` as '/v1/email_addresses/*').then((res) => {
      if (res.id !== null) {
        return EmailModelService.fromPayload(res);
      }

      const err = new Error({
        title: t('js.service.backend.network'),
        message: '',
        code: 'emailBySlug'
      });
      return Promise.reject(err);
    });
  }

  /**
   * Set the preference for a slug
   * @param emailSlug
   */
  public setPreferenceForSlug(email: Email): Promise<Email> {
    return this.backend
      .put(null, `/v1/email_addresses/${email.slug}` as '/v1/email_addresses/*', EmailModelService.toPayload(email), {
        sendUtmParams: true
      })
      .then((res): Email => EmailModelService.fromPayload(res));
  }

  /**
   * Save or update an email preference
   * @param data
   */
  public saveOrUpdate(email: Email): Promise<Email> {
    return this.backend
      .post(null, '/v1/email_addresses', EmailModelService.toPayload(email), {
        sendExperiments: true,
        sendUtmParams: true
      })
      .then((res): Email => EmailModelService.fromPayload(res));
  }
}
