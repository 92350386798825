
<ui-box class="blogs-landing-page" box_space="none" box_space_bottom="near" [box_space_top]="{mobile:'default', tablet:'expanded'}" box_background="transparent" [box_content_fill_width]="true">
  <ui-stack stack_align="center" stack_gap="none">
    <ui-box box_background="transparent" [box_space]="{mobile:'none', tablet:'default'}" box_space_bottom="none" [box_space_top]="{mobile:'none', tablet:'near'}">
      <ui-stack stack_align="center" stack_gap="none">
        <nav>
          <ui-stack class="nav-items" stack_direction="x"><span *ngFor="let navItem of blogListNavItems"><a class="text-label--primary text-link--standalone" href="{{navItem.url}}">{{navItem.name}}</a></span></ui-stack>
        </nav>
        <ui-box class="blogs-landing-page__title" box_background="transparent" box_space="none" box_space_top="tight">
          <bw-content-spot [useUrl]="true" [queryStringKey]="'contentSpot'"></bw-content-spot>
        </ui-box>
      </ui-stack>
    </ui-box>
    <ui-box *ngIf="items &amp;&amp; items.length &gt; 0" box_space="none" [box_space_bottom]="{mobile:'none', tablet:'expanded'}" box_background="transparent" [box_content_fill_width]="true">
      <ui-section section_type="inform" [image_on_left]="false" brand_background_colour="core-secondary" [image]="items[0]?.imageUrl|| 'https://images.ctfassets.net/1d9ajmvjpuhw/4AArfUkSnsawCCjL3nDBaR/a2b1a06115f75c10c80164eb6ea7f04c/blog-image-placeholder__logomark-resized.png'" [alt_text]="items[0]?.imageUrl ? items[0]?.imageAltText : placeholderAltText" primary_cta_text="Read more" href_primary_cta="/{{items[0].slug}}" [heading_title]="items[0]?.title || ''" [heading_subtitle]="items[0]?.description || ''"></ui-section>
    </ui-box>
    <ui-box box_background="transparent">
      <ui-grid grid_collapse_below="none">
        <ui-column class="hidden-mobile" *ngFor="let item of items | slice:0:showMaximum; let i = index" [columns]="3"><a href="/{{item.slug}}">
            <ui-card [ngStyle]="{'margin-top': i % 2 === 0 ? '0px' : '36%'}" style="display:flex;" [image]="item?.imageUrl || 'https://images.ctfassets.net/1d9ajmvjpuhw/4AArfUkSnsawCCjL3nDBaR/a2b1a06115f75c10c80164eb6ea7f04c/blog-image-placeholder__logomark-resized.png'" [image_format]="i % 2 === 0 ? 'portrait' : 'square'" [small_title_left]="((item.tags ? item.tags.join(', ') : '') | blogTags | titlecase)" [small_sub_title_right]="(item?.publishedDate | date:'dd.MM.yyyy')" [bold_content]="item?.title || ''" [alt_text]="item?.imageUrl? item?.imageAltText : placeholderAltText"></ui-card></a></ui-column>
        <ui-column class="hidden-tablet" *ngFor="let item of items | slice:0:showMaximum; let i = index" [columns]="i % 5 === 4 ? 12 : 6"><a href="/{{item.slug}}">
            <ui-card [image]="item?.imageUrl || 'https://images.ctfassets.net/1d9ajmvjpuhw/4AArfUkSnsawCCjL3nDBaR/a2b1a06115f75c10c80164eb6ea7f04c/blog-image-placeholder__logomark-resized.png'" [image_format]="i % 5 === 1 || i % 5 === 3 || i % 5 === 4 ? 'square' : 'portrait'" [small_title_left]="((item.tags ? item.tags.join(', ') : '') | blogTags | titlecase)" [small_sub_title_right]="(item?.publishedDate | date:'dd.MM.yyyy')" [bold_content]="item?.title" [alt_text]="item?.imageUrl ? item?.imageAltText : placeholderAltText"></ui-card></a></ui-column>
      </ui-grid>
    </ui-box>
    <ui-box class="show-more" box_background="transparent" box_space="none" box_space_top="default">
      <ui-button *ngIf="items &amp;&amp; showMaximum &lt; items.length" button_type="secondary" (clicked)="showMore()"><span #buttontext>Show more articles</span></ui-button>
    </ui-box>
    <ui-box *ngIf="items &amp;&amp; items.length &gt; 0" box_background="transparent" box_space="none" [box_content_fill_width]="true">
      <bw-content-spot [useUrl]="true" [useFallback]="false" contentId="footer"></bw-content-spot>
    </ui-box>
  </ui-stack>
</ui-box>