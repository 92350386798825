/**
 *
 * Note: This doesn't actually do much here
 * The work to show/hide content based on experiments is done within the `content.service.ts` file
 *
 */
import { Component, OnInit } from '@angular/core';

import { ExperimentsService } from 'Shared/services/experiments.service';
@Component({
  selector: 'bw-experiment',
  templateUrl: './experiment.component.html',
  styleUrls: ['./experiment.component.scss']
})
export class ExperimentComponent implements OnInit {
  constructor(public experimentService: ExperimentsService) {}
  ngOnInit(): void {}
}
