import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TabItem } from 'Shared/classes/help-center';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-horizontal-tabs',
  templateUrl: './horizontal-tabs.component.html'
})
export class HorizontalTabsComponent implements OnInit {
  @Input()
  tabs: any;
  @Input()
  selected?: TabItem;
  @Output() select: EventEmitter<any> = new EventEmitter();
  selectedTab: TabItem;

  constructor() {}

  /**
   * On init
   */
  ngOnInit(): void {
    if (this.selected) {
      this.selectedTab = this.selected;
    }
  }

  /**
   * Select tab
   * @param content
   */
  selectTab(content: any): void {
    this.selectedTab = content;
    this.select.emit(this.selectedTab);
  }
}
