import { translations } from 'Environments/js-translations';

export const addOccasionUrl = translations['js.routing.add_occasion'];
export const helpDeliveryUrl = translations['js.routing.help-with-delivery'];
export const helpQualityUrl = translations['js.routing.help-with-quality'];
export const contactUsUrl = translations['js.routing.content.help'];

export const ACCOUNT_ROUTES_CONFIG = {
  // login: 'login',
  // ordersView: 'my-orders/order/:orderId',
  // subscriptionNextDelivery: 'my-orders/order/:orderId/next-delivery',
  // orderDeliveryView: 'my-orders/order/:orderId/delivery/:deliveryId',
  // orderDeliveriesManagement: 'my-orders/order/:orderId/all-my-deliveries',
  // 'account.orders.delivered': 'my-account/orders/delivered',
  // subscriptionDetails: 'my-orders/order/:orderId/subscription-details',
  // register: 'register',
  // 'create-account': 'create-account/:resetToken',
  // 'create-account-param': 'create-account',
  // 'reset-password': 'reset-password',
  // 'reset-password-with-token': 'reset-password/:resetToken',
  // 'activate-account': 'activate-account',
  // 'quick-add-occasion': addOccasionUrl,
  // 'help-with-delivery': helpDeliveryUrl,
  // 'help-with-quality': helpQualityUrl,
  // subscribe: 'subscribe/:emailSlug',
  // unsubscribe: 'unsubscribe/:emailSlug',
  // 'payment-result': 'payment-result',
  // giftCertificate: 'gift-certificate/:orderId/:orderToken',
  // applyCreditCode: 'my-credit/:code',
  // logout: 'logout',
  // 'ordersView.share': 'my-orders/order/:orderId/share',
  // subscriptionReactivate: 'my-orders/order/:orderId/reactivate',
  // 'account.orders': 'my-account/orders',
  // 'account.invoices': 'my-account/invoices/:purchaseId',
  // 'account.credit': 'my-account/credit',
  // 'account.details': 'my-account/details',
  // 'account.cards': 'my-account/cards',
  // 'account.marketing': 'my-account/marketing-preferences',
  // 'account.addresses': 'my-account/addresses',
  // 'account.occasions': 'my-account/occasions',
  // 'account.favourites': 'my-account/favourites',
  // validateUnusualSignIn: 'validate-unusual-sign-in',
  // 'account.rewards.join': 'my-account/rewards/join',
  // 'account.rewards': 'my-account/rewards',
  // redirectCredit: 'my-credit',
  // redirectMyOrders: 'my-orders',
  // redirectMyAccount: 'my-account',
  // redirectMyOccasions: 'my-occasions',
  // redirectMyOccasionsPage: 'my-occasions/occasions/:page',
  // orderspage: 'orders/:page',
  // myaccountorderspage: 'my-account/orders/:page',
  // 'account.postal': 'my-account/postal-preferences',
  // 'account.email': 'my-account/email-preferences'
};
