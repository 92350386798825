import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ConfigModelService } from 'Shared/models/config-model.service';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { ProductGridService } from 'Checkout/services/product-grid.service';
import { Product, ProductService } from 'Checkout/services/product.service';
import { StateService } from 'Shared/services/state.service';
import { CountryService } from 'Shared/services/country.service';
import { Discount } from 'Shared/classes/discount';
import { t } from 'Shared/utils/translations';
import { PurchaseService } from 'Checkout/services/purchase.service';

@Injectable()
export class HasProductDetailsResolver implements Resolve<any> {
  constructor(
    private productService: ProductService,
    private countryService: CountryService,
    private configModelService: ConfigModelService,
    private stateService: StateService,
    private toastr: ToastrService,
    private productGridService: ProductGridService,
    private purchaseService: PurchaseService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    const initialParams = this.stateService.getInitial();
    const id = parseInt(route.params.skuId, 10);

    const purchase = this.purchaseService.getPurchase();
    // Multi Order Discount
    const orderIndex = purchase && purchase.orders ? purchase.orders.length : 0;

    // if the user landed directly on the page with a discount then we use route params but
    // if they landed somewhere else on the site wih the discount code we check initial params
    const discount =
      route.queryParams && route.queryParams.discountCode
        ? new Discount(route.queryParams.discountCode)
        : initialParams.params && initialParams.params.discountCode
        ? new Discount(initialParams.params.discountCode)
        : null;

    return this.configModelService
      .hasRemoteConfig()
      .then(() => {
        return Promise.all([
          this.productService
            .getAvailableProducts(this.countryService.forShipping, orderIndex, discount)
            .then((prods) => prods.find((p) => p.id === id)),
          this.productService.getProductDetails(
            this.countryService.forShipping,
            new Product(route.params.skuId)
          )
        ]);
      })
      .then(([product, details]) => {
        if (!product) {
          return Promise.reject();
        }

        // Exclude gift vouchers from PDPs - for now
        if (product.isGiftVoucher()) {
          return Promise.reject();
        }

        // Convert the prooduct into a more usefull 'carouselProduct'
        const result = this.productGridService.toGridProduct([product], [], null);
        const carouselProduct = result[0];

        return Promise.resolve({ carouselProduct, details });
      })
      .catch((e) => {
        // Show error message
        this.toastr.error(
          t('js.components.order-form.messages.no-products.message'),
          t('js.components.order-form.messages.no-products.title')
        );
        return this.stateService.go('checkout.base');
      });
  }
}
