
<div class="bw-faq">
  <div class="bw-faq-list" *ngIf="!delivered">
    <div class="bw-faq-list__item"><a href="https://www.bloomandwild.com/help/deliveries/where-are-flowers" bwTrackAs="deliveryTrackingModal.viewFAQs">My gift hasn't arrived, what do I do?</a></div>
    <div class="bw-faq-list__item"><a href="https://www.bloomandwild.com/help/deliveries/do-i-need-to-be-home" bwTrackAs="deliveryTrackingModal.viewFAQs">Does someone need to be in to receive the gift?</a></div>
    <div class="bw-faq-list__item"><a href="https://www.bloomandwild.com/help/deliveries/tracking" bwTrackAs="deliveryTrackingModal.viewFAQs">Get help fast!</a></div>
  </div>
  <div class="bw-faq-list" *ngIf="delivered">
    <div class="bw-faq-list__item"><a href="https://www.bloomandwild.com/help/deliveries/not-delivered" bwTrackAs="deliveryTrackingModal.viewFAQs">Tracking says delivered but can't find it?</a></div>
    <div class="bw-faq-list__item"><a href="https://www.bloomandwild.com/help/delivered-gift/not-as-expected" bwTrackAs="deliveryTrackingModal.viewFAQs">What do I do if my flowers look droopy on arrival?</a></div>
    <div class="bw-faq-list__item"><a href="https://www.bloomandwild.com/caring-for-your-blooms" bwTrackAs="deliveryTrackingModal.viewFAQs">How long do the flowers last?</a></div>
    <div class="bw-faq-list__item"><a href="https://www.bloomandwild.com/help/deliveries/tracking" bwTrackAs="deliveryTrackingModal.viewFAQs">Get help fast!</a></div>
  </div>
</div>