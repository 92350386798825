import {
  Component,
  ContentChildren,
  QueryList,
  Input,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ContentfulFormService } from 'Content/services/contentful-form.service';
import { WindowRefService } from 'Shared/services/window.service';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { t } from 'Shared/utils/translations';
/**
 *
 * TODO: This file should really be split into seperate files
 */
@Component({
  selector: 'contentful-form-control',
  templateUrl: './contentful-form-control.component.html'
})
export class ContentfulFormControlComponent implements OnInit {
  placeHolderText: string;

  @Input()
  type: string;
  @Input()
  name: string;
  @Input()
  label: string;
  @Input()
  required: string;
  @Input()
  maxLength: string;
  @Input()
  key: string;
  @Input()
  contentfulForm: FormGroup;
  @Input()
  formSubmitted: boolean;

  @Output()
  fileschanged: EventEmitter<any> = new EventEmitter(); // Lowercase due to contentful interceptor

  control: FormControl;

  constructor() {}

  ngOnInit(): void {
    // HACK: to be removed after covid
    this.placeHolderText =
      this.label === 'covid_email'
        ? t('js.directives.newsletterSignupDirective.enter_your_email')
        : '';
    this.control = new FormControl(
      this.type === 'checkbox' ? false : '',
      this.required || this.required === 'true' ? { validators: [Validators.required] } : {}
    );

    // checkbox .value isn't working so have to manually do it
    if (this.type === 'checkbox') {
      let value = false;
      this.control.valueChanges.subscribe(val => {
        if (typeof val === 'string') {
          value = !value;
          this.control.setValue(value);
        }
      });
    }
    this.contentfulForm.addControl(this.name, this.control);
  }
}
