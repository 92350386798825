import { Pipe, PipeTransform } from '@angular/core';
import { t } from 'Shared/utils/translations';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(value: any, args?: any): any {

    if (value === -1) {
      return t('js.pipe.ongoing');
    }
    if (value === 1) {
      return t('js.pipe.oneoff');
    }
    if (value === 3) {
      return t('js.pipe.3months');
    }
    if (value === 6) {
      return t('js.pipe.6months');
    }
    if (value === 12) {
      return t('js.pipe.12months');
    }
  }
}
