import { Component, Input, AfterViewInit, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import Player from '@vimeo/player';
import { ViewportDetectionService } from 'Project/shared/services/viewport-detection.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StateService } from 'Shared/services/state.service';
import { ImageRole } from 'Content/components/contentful-image/contentful-image';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-video-player',
  templateUrl: './video-player.component.html'
})
export class VideoPlayerComponent implements AfterViewInit, OnInit, OnDestroy {
  vimeo;

  videoPlayer;
  videoReady: boolean = false;
  videoError: boolean = false;

  aspectRatioActive: boolean = false;
  videoHeight: string;

  isHomepage: boolean = false;
  viewportSizeIs$: BehaviorSubject<any> = this.viewPortDetectionService.viewportSizeIs$;
  viewportDetectionSubscription: Subscription;
  imageRole: ImageRole; // to have to the correct ratio

  // vimeo id
  @Input() videoid: string;

  // video wrapper URL
  @Input() videourl: string;

  // image displayed before video loads and if video fails
  @Input() firstframe: string;

  // aspect ratio for the surrounding container to adhere to
  @Input() aspectratio: string = 'auto';

  @Input() autoplay: boolean = true;

  @Input() videowidth: string;
  @Input() videoheight: string;

  @Output()
  duration: EventEmitter<any> = new EventEmitter();

  constructor(private stateService: StateService, private viewPortDetectionService: ViewportDetectionService) {
    this.vimeo = Player;
  }

  logMessage(type: 'warn' | 'error', message: string): void {
    console[type]('[Vimeo]', message, `id: ${this.videoid}.`);

    if (type === 'error') {
      this.videoError = true;
    }
  }

  getVimeoOptions(): any {
    return {
      id: this.videoid,

      // these options are only for display videos so could be wrapped in an if in the future
      background: true,
      muted: true,
      autoplay: this.autoplay,
      loop: true,
      dnt: true, // This parameter loads the video cookieless https://developer.vimeo.com/api/oembed/videos

      // need to set these as empty strings otherwise the video is rendered tiny
      width: this.videowidth ? this.videowidth : '', // by deafult it's null
      height: this.videoheight ? this.videoheight : '' // by deafult it's null
    };
  }

  calculateVideoHeight(aspectRatio): string {
    try {
      const aspectRatioParts = aspectRatio.split(':');

      if (aspectRatioParts.length !== 2) {
        // invalid aspect ratio
        this.logMessage('warn', 'Invalid aspect ratio');
        this.aspectRatioActive = false;
      }

      this.aspectRatioActive = true;

      // (height / width) x 100 for percentage
      return `${(+aspectRatioParts[1] / +aspectRatioParts[0]) * 100}%`;
    } catch (e) {
      this.logMessage('warn', 'Invalid aspect ratio');
      this.aspectRatioActive = false;
    }
  }

  /**
   * Get the duration from vimeo player and emit it
   */
  getDuration(): Promise<number> {
    if (this.videoPlayer) {
      return this.videoPlayer
        .getDuration()
        .then((duration) => this.duration.emit(duration))
        .catch(() => {});
    }
  }

  ngOnInit(): void {
    const currentState = this.stateService.getCurrent();
    this.isHomepage = !!currentState && currentState.name === 'homepage';

    this.viewportDetectionSubscription = this.viewPortDetectionService.viewportSizeIs$.subscribe((ports) => {
      this.imageRole = ports.not.mobile ? 'hero-banner' : 'hero-banner-mobile';
    });

    if (!this.aspectratio || this.aspectratio === 'auto') {
      // do nothing with aspect ratio
      return;
    }

    this.videoHeight = this.calculateVideoHeight(this.aspectratio);
  }

  ngOnDestroy(): void {
    if (this.viewportDetectionSubscription) {
      this.viewportDetectionSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): Promise<void> {
    if (!this.videoid || !this.firstframe) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          this.videoPlayer = new this.vimeo(`video-player--${this.videoid}`, this.getVimeoOptions());

          this.videoPlayer.on('error', (error) => {
            this.logMessage('error', error.message);
          });

          this.videoPlayer.on('play', () => {
            console.log('play');
            this.videoReady = true;
          });

          this.videoPlayer.on('loaded', () => this.getDuration());
        } catch (error) {
          this.logMessage('error', error.message);
          resolve();
        }
      }, 0);
    });
  }
}
