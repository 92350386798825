import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { AnalyticsService } from 'Shared/services/analytics.service';

@Directive({
  selector: '[bwTrackAs]'
})
export class TrackAsDirective {
  @Input('bwTrackAs') bwTrackAs: string;

  constructor(private analytics: AnalyticsService, private el: ElementRef) {}

  /**
   * Camel case and remove spaces etc
   * @param str
   */
  normalised(str: string): string {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
        index === 0 ? letter.toLowerCase() : letter.toUpperCase()
      )
      .replace(/\s+/g, '');
  }

  /**
   * On click, track against the analytics mappning
   */
  @HostListener('click')
  onClick(): void {
    this.analytics.track(this.bwTrackAs, {
      text: this.normalised(this.el.nativeElement.innerText)
    });
  }
}
