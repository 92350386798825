import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { StateService } from 'Shared/services/state.service';
import { PurchaseService, Purchase } from 'Checkout/services/purchase.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PopulatedPurchase implements CanActivate {
  purchase$: BehaviorSubject<Purchase>;
  constructor(private state: StateService, private purchase: PurchaseService) {}

  /**
   * Can activate
   */
  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const purchase = this.purchase.getPurchase();
      if (purchase.orders.length) {
        resolve(true);
      } else {
        this.state.go('checkout.base');
        reject(false);
      }
    });
  }
}
