
<div class="module-tabs">
  <div class="module-tabs__list">
    <ng-container *ngFor="let tab of moduleTabs; let i = index">
      <bw-button class="module-tabs__item" ngClass="module-tabs__{{mobuleTabsStyle}}" [buttonStyle]="tab.active ? activeStyle : defaultStyle" size="small" (click)="tab.event" (clicked)="onClickEvent(i)">
        <ng-container *ngIf="tab.shortName">
          <div class="module-tabs__item-main hidden-xs" *ngIf="!tab.subCopy; else multiLine">{{ tab.name }}</div>
          <div class="module-tabs__item-main hidden-sm hidden-md hidden-lg hidden-xl" *ngIf="!tab.subCopy || tab.active; else multiLine">{{ tab.shortName }}</div>
          <ng-template #multiLine>
            <div>
              <div class="module-tabs__item-main hidden-xs">{{ tab.name }}</div>
              <div class="module-tabs__item-main hidden-sm hidden-md hidden-lg hidden-xl">{{ tab.shortName }}</div>
              <div class="module-tabs__item-copy">{{ tab.subCopy }}</div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="!tab.shortName">
          <div class="module-tabs__item-main" *ngIf="!tab.subCopy; else multiLine">{{ tab.name }}</div>
          <ng-template #multiLine>
            <div>
              <div class="module-tabs__item-main">{{ tab.name }}</div>
              <div class="module-tabs__item-copy">{{ tab.subCopy }}</div>
            </div>
          </ng-template>
        </ng-container>
      </bw-button>
    </ng-container>
  </div>
  <ng-content></ng-content>
</div>