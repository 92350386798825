import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ConfigService } from 'Shared/services/config.service';
import { CountryService, Country } from 'Shared/services/country.service';
import { ProductService } from 'Checkout/services/product.service';
import { Product } from 'Shared/classes/product';
import { Discount } from 'Shared/classes/discount';
import { StateService } from 'Shared/services/state.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { LocationService } from 'Project/shared/services/location.service';
import { IntersectionService, IntersectionOptions } from 'Shared/services/intersection.service';
import { WindowRefService } from 'Project/shared/services/window.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-quick-shop-v-two',
  templateUrl: './quick-shop-v-two.component.html'
})
export class QuickShopVTwoComponent implements OnInit, OnChanges {
  products: Product[];
  showImage: boolean;
  discount: Discount;

  @Input() orderIndex: number = 0;
  @Input() discountcode: string; // Lowercase for location pages
  @Input() configkey: string; // Lowercase for contentful
  @Input() applydiscountonredirect: boolean = false; // Lowercase for location pages
  @Input() source: string; // Lowercase for location pages, source defines where this component is being used (i.e homepage, location-page)
  @Input() rebrand: boolean = false;

  constructor(
    private configService: ConfigService,
    private countryService: CountryService,
    private productService: ProductService,
    private stateService: StateService,
    private analyticsService: AnalyticsService,
    private locationService: LocationService,
    private intersectionService: IntersectionService,
    private windowRef: WindowRefService
  ) {}

  productSelected(product: Product, index?: number): void {
    this.analyticsService.track('component.bestSellers.action.select', {
      index: index + 1
    });
    this.stateService.go('checkout.productpage', {
      slug: product.slug,
      skuId: product.id,
      discountCode: this.applydiscountonredirect ? this.discountcode : null
    });
  }

  /**
   * Given a config key, return back the list of Skus for the current shipping country
   * @param key
   */
  skuIdsFromConfigForKey(key: string, country: Country): number[] {
    const config = this.configService.getConfig();

    if (!config[key]) {
      return [];
    }

    const fallback = config[key].find(
      (skusByCountry) => country.id === skusByCountry.shipping_country_id
    );
    return fallback && fallback.skus && fallback.skus.length ? fallback.skus : [];
  }

  /**
   * On init
   *
   * @returns {Promise}
   */
  ngOnInit(): Promise<any> {
    // convert string discount code to an discount object
    this.discount = this.discountcode ? new Discount(this.discountcode) : undefined;
    const shippingCountry = this.countryService.forShipping;

    let configToUse = this.configkey;

    if (!configToUse) {
      configToUse =
        this.stateService.getCurrent().name === 'checkout.payment'
          ? 'payment_bestsellers_sku_id'
          : 'homepage_sku_ids';
    }

    let skuIds = this.skuIdsFromConfigForKey(configToUse, shippingCountry);
    if (!skuIds || !skuIds.length) {
      skuIds = this.skuIdsFromConfigForKey('homepage_sku_ids', shippingCountry);
    }

    return this.productService
      .getQuickShopProducts(shippingCountry, skuIds, this.orderIndex, this.discount)
      .then((p) => {
        this.products = p;
      });
  }

  /**
   * Track Quick Shop Product Impressions
   * @param response
   */
  trackProductImpression(response: IntersectionObserverEntry): void {
    const productIndex: number = parseInt(response.target.getAttribute('index'), 10);
    const listType = this.locationService.getListType();

    this.analyticsService.trackImpressions(
      this.products,
      productIndex,
      1,
      'bestsellers',
      listType.value,
      this.configService.getConfig().serverTime,
      productIndex
    );
  }

  ngOnChanges(obj: any): Promise<any> {
    if (obj.discountcode) {
      return this.ngOnInit();
    }
    return Promise.resolve(true);
  }
}
