
<form class="bwForm address-search" [formGroup]="parentForm">
  <div class="form-group" dropdown #dropdown="bs-dropdown" [ngClass]="{'ng-focus' :  dropdown.isOpen}">
    <input class="form-control" name="addressSearch" id="addressSearch" formControlName="addressSearch" type="text" dropdownToggle autocomplete="off" [ngClass]="{ 'has-hint': hint }" (focus)="dropdown.show()" (click)="dropdown.show()" (blur)="closeOnBlurIfValid(dropdown)" tabindex="0">
    <label class="wide" for="select">{{label}}</label>
    <!-- clear form 'x' button, only shows when field focused--><a class="form-control__clear" [hidden]="!dropdown.isOpen" (click)="resetAndHide(dropdown)"><img src="assets/brand-icons/x.svg"></a>
    <bw-form-validation-icon></bw-form-validation-icon>
    <bw-formcontrol-validation-messages [errors]="parentForm.get('addressSearch').errors" [touched]="parentForm.get('addressSearch').touched" [formSubmitted]="formSubmitted">
      <div for="invalid">Search and select a recipient address</div>
    </bw-formcontrol-validation-messages><small class="form-text text-muted" *ngIf="hint">{{hint}}</small>
    <div class="form-items-select dropdown-menu height-limited" *dropdownMenu role="menu">
      <div class="address-search-results"><a class="dropdown-item ellipsis" href="javascript:void(0)" onclick="return false" *ngFor="let result of results.postcodes" (click)="preventClose($event); searchFragment(result); dropdown.show()">
          <div class="dropdown-item__result-title" [innerHtml]="result.Text"></div>
          <div class="dropdown-item__result-subtitle text-muted">{{result.Description}}</div></a><a class="dropdown-item ellipsis" href="javascript:void(0)" onclick="return false" *ngFor="let result of results.addresses" (click)="selectAddress(result)">
          <div class="dropdown-item__result-title" [innerHtml]="result.Text"></div>
          <div class="dropdown-item__result-subtitle text-muted">{{result.Description}}</div></a></div>
      <div class="dropdown-instructions">
        <div [ngClass]="{'new-style' : newStyle}" (click)="newAddress()"><span *ngIf="newStyle">Search for an address or <a class="bwLink" bwTrackAs="component.address-search.auto_key.string_2">Enter address manually</a></span><span *ngIf="!newStyle">Enter address manually</span></div>
      </div>
      <bw-loading-spinner *ngIf="newStyle &amp;&amp; isLoading"></bw-loading-spinner>
    </div>
  </div>
</form>