import { Injectable } from '@angular/core';
import { CampaignCodesModelService } from 'Shared/models/campaign-codes-model.service';
import { CampaignCode } from 'Shared/classes/code';
import { Country } from 'Shared/classes/country';

@Injectable({
  providedIn: 'root'
})
export class CampaignCodesService {
  constructor(private campaignCodesModel: CampaignCodesModelService) {}

  /**
   * Get a code
   * @param campaignExperiments
   * @param campaignVariant
   */
  getCode(
    shippingCountry?: Country
  ): Promise<CampaignCode> {
    return this.campaignCodesModel.getCode(shippingCountry);
  }
}
