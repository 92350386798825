import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalService } from 'Shared/services/modal.service';
import { ContactUsModalComponent } from 'Shared/components/modals/contact-us-modal/contact-us-modal.component';
import { TabItem, TabItemContent } from 'Shared/classes/help-center';
import { StateService } from 'Shared/services/state.service';
import { t } from 'Shared/utils/translations';
import { ConfigService } from 'Shared/services/config.service';
import { WindowRefService } from 'Shared/services/window.service';
import { ViewportDetectionService } from 'Shared/services/viewport-detection.service';
import { Subscription } from 'rxjs';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-help-center-homepage',
  templateUrl: './help-center-homepage.component.html'
})
export class HelpCenterHomepageComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  tabs: TabItem[] | any;

  displayTabs: TabItem[] = null;
  selectedTab: TabItem = null;
  selectedArticle: TabItemContent;
  currentRoute: string;
  stateServiceSubject$: Subscription;
  device: any;
  constructor(
    private modalService: ModalService,
    private stateService: StateService,
    private configService: ConfigService,
    private windowService: WindowRefService,
    private viewPortDetection: ViewportDetectionService
  ) {}

  ngOnDestroy(): void {
    this.stateServiceSubject$?.unsubscribe();
  }
  /**
   * On init
   */
  ngOnInit(): void {
    // The page doesn't refresh and this force page refresh.
    // This is necesary for the back button and scroll to element
    this.device = this.viewPortDetection.viewportSizeIs$
      ? this.viewPortDetection.viewportSizeIs$.getValue()
      : null;
    if (this.device && this.device.mobile) {
      this.stateServiceSubject$ = this.stateService.onSuccess$.subscribe((event) => {
        if (event.to) {
          if ('/' + event.to.url === this.selectedArticle.url) {
            window.location.reload();
          }
        }
      });
    }
    this.currentRoute = this.stateService.getCurrent().url;
    this.displayTabs = typeof this.tabs === 'string' ? JSON.parse(this.tabs as any) : this.tabs;
    this.initArticle(this.currentRoute);
  }

  /*
   * After View Init
   * Scroll to selected/open tab(article) if is mobile view
   */
  ngAfterViewInit(): void {
    if (this.device && this.device.mobile) {
      if ('/' + this.currentRoute === this.selectedArticle.url) {
        this.windowService.scrollToElem('.bw-help-center-homepage__selected');
      }
    }
  }
  /**
   * Select tab item
   * @param item
   */
  selectContentGroup(item: any) {
    this.selectedTab = item;
    this.selectedArticle = this.initFirstArticleSelected();
  }

  /**
   * Select article
   * @param item
   */
  selectContentArticle(item: any) {
    this.selectedArticle = item;
  }

  /**
   * Find and select tab and article
   */
  initArticle(url: string) {
    // To match contentful format
    if (url.charAt(0) !== '/') {
      url = '/' + url;
    }

    if (this.displayTabs && url) {
      this.selectedTab = undefined;
      this.selectedArticle = undefined;

      // Select on load correct tab and article based on url
      this.displayTabs.forEach((t: TabItem) => {
        if (t.url === url) {
          this.selectedTab = t;
          this.selectedArticle = this.initFirstArticleSelected();
        } else {
          let article = t.content?.find((a) => a.url === url);

          if (article) {
            this.selectedTab = t;
            this.selectedArticle = article;
          }
        }
      });

      // Select default not route match
      if (!this.selectedTab && !this.selectedArticle) {
        this.selectedTab = this.displayTabs[0];
        this.selectedArticle = this.initFirstArticleSelected();
      }
    }
  }

  /**
   * Select first article
   */
  private initFirstArticleSelected(): TabItemContent {
    return this.selectedTab?.content[0] || null;
  }

  /**
   * Open contact us modal
   */
  openContactUpModal(): Promise<void> {
    const problems = `${t('js.components.contact_us.form.problem-1')}, ${t(
      'js.components.contact_us.form.problem-2'
    )}, ${t('js.components.contact_us.form.problem-3')}`;

    const articleUrl = this.selectedArticle.url.split('/').slice(1);
    const reason =
      articleUrl.join(' | ') +
      ' | ' +
      this.configService.getConfig().brandId +
      ' ' +
      this.configService.getConfig().country.toUpperCase();

    return this.modalService.show(ContactUsModalComponent, {
      initialState: {
        reason: reason,
        problems: problems,
        formtype: 'email_general'
      },
      trackingKey: 'contactus',
      trackingValue: 'contactus',
      class: 'modal-sm bw-modal--contact-us',
      useNativeScroll: true,
      keyboard: true
    });
  }
}
