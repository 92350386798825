import { Delivery } from 'Shared/classes/delivery';
import { ModalScreen, Resolution } from 'Shared/components/help-center/hc-modal';
import { ToastrConfig } from '../toastr.config';

export type ToastrMessageTypes = 'error' | 'success' | 'info';

export type ToastrFeedbackTypes =
  | 'quality_feedback'
  | 'delivery_feedback'
  | 'delivery_tracking_feedback';

export class ToastrMessage {
  type: ToastrMessageTypes | ToastrFeedbackTypes;
  message: string;
  title: string;
  config: ToastrConfig;
  visible: boolean;

  constructor(data: {
    type: ToastrMessageTypes | ToastrFeedbackTypes;
    message: string;
    title?: string;
    config?: ToastrConfig;
  }) {
    this.type = data.type;
    this.message = data.message;
    this.title = data.title ?? '';
    this.config = Object.assign(new ToastrConfig(), data.config || {});
    this.visible = false;
  }
}

export class FeedbackToastrMessage extends ToastrMessage {
  delivery: Delivery;
  modalScreen: string;
  issue: string;
  stems: string[];
  resolution: Resolution;
  issueOptionId: string;
  userType: string;

  constructor(data: {
    type: ToastrFeedbackTypes;
    message: string;
    title?: string;
    config?: ToastrConfig;
    delivery?: Delivery;
    modalScreen?: ModalScreen;
    issue?: string;
    stems?: string[];
    resolution?: Resolution;
    issueOptionId?: string;
    userType?: string;
  }) {
    super({
      type: data.type,
      message: data.message,
      title: data.title,
      config: data.config
    });

    this.delivery = data.delivery;
    this.modalScreen = data.modalScreen;
    this.issue = data.issue;
    this.stems = data.stems;
    this.resolution = data.resolution;
    this.issueOptionId = data.issueOptionId;
    this.userType = data.userType;
  }
}
