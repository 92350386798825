import { Injectable } from '@angular/core';
import { Country } from 'Shared/classes/country';
import { Product } from 'Shared/classes/product';
import * as dayjs from 'dayjs';
import { APISerialisedJSONResponse, BackendService } from 'Shared/services/backend.service';

import { Review } from 'Shared/classes/review';
import { ProductDetails, DescriptionComponent } from 'Shared/interfaces/product-details';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailModelService {
  constructor(private backend: BackendService) {}

  static fromPayload(res: APISerialisedJSONResponse<'/v2/skus/:productId/details'>): ProductDetails {
    const attr = res.attributes || res;
    const productDetails = new ProductDetails();

    productDetails.seoTitle = attr.seo_title;
    productDetails.descriptionComponents = this.getDescription(attr.description_components);
    productDetails.contentSpecification = attr.content_specification || '';
    productDetails.reviews = ProductDetailModelService.getReviews(attr.reviews);

    if (attr.about_sku != null) {
      productDetails.aboutSku = attr.about_sku || '';
    }
    if (attr.sustainability_information != null) {
      productDetails.sustainabilityInfo = attr.sustainability_information;
    }
    if (attr.delivery_information != null) {
      productDetails.deliveryInfo = attr.delivery_information;
    }

    return productDetails;
  }

  static getDescription(data: DescriptionResponse): DescriptionComponent[] {
    return (data || []).map((item): DescriptionComponent => {
      const component = new DescriptionComponent();
      component.backgroundColour = item.background_colour || '#fbf1f1';
      component.description = item.description;
      component.iconName = `description-${item.icon}`;
      component.kind = item.kind;
      return component;
    });
  }

  static getReviews(data: ReviewResponse): Review[] {
    return (data || []).map((r): Review => {
      const review = new Review();
      review.date = dayjs(r.date);
      review.title = r.title;
      review.reviewer = r.reviewer ? r.reviewer.split(' ')[0] : '';
      review.review = r.review ? r.review : '';
      review.rating = r.rating ? r.rating : 0;
      return review;
    });
  }

  /**
   * Given a product ID return description components and reviews
   * @param country
   * @param product
   */
  getProductDetails(country: Country, product: Product): Promise<ProductDetails> {
    return this.backend
      .get(null, `/v2/skus/${product.id}/details`, {
        useUrlAsCache: true,
        responseIsJsonApi: true,
        params: {
          shipping_country_id: country.id,
          reviews_limit: 12
        }
      })
      .then((res): ProductDetails => ProductDetailModelService.fromPayload(res));
  }
}

interface ReviewResponse extends APISerialisedJSONResponse<'/v2/skus/:productId'>, Array<Review> {}
interface DescriptionResponse extends APISerialisedJSONResponse<'/v2/skus/:productId'>, Array<DescriptionComponent> {}
