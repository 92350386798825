import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BwForm, BwFormControl, BwFormDefintion } from 'Shared/classes/bw-form';
import { FeaturesService } from 'Shared/services/features.service';
import { EmailService, Email } from 'Shared/services/email.service';
import { t } from 'Shared/utils/translations'; // do not remove, required for labels
import { RadioOption } from 'Shared/components/form-radio-input/form-radio-input.component';
import { EmailPreference } from 'Shared/classes/email';
import { WindowRefService } from 'Shared/services/window.service';
import { emailPreferences, EmailPreferenceItem } from 'Account/components/email-preferences/email-preferences.component';

type SimpleUserConsentControls = {
  simpleConsent?: BwFormControl<boolean>;
};

/* bw:view-encapsulation */
@Component({
  selector: 'bw-details-user-consent',
  templateUrl: './details-user-consent.component.html'
})
export class DetailsUserConsentComponent extends BwForm<SimpleUserConsentControls> implements OnInit, OnDestroy, OnChanges {
  @Input({ required: true }) bwFormControl: BwFormControl<number>;
  @Input({ required: true }) email: Email;
  @Input({ required: false }) loggedIn: boolean = false;
  @Input({ required: false }) showOptionsOnly: boolean = false;
  @Input({ required: false }) AutoOptInToRewards: boolean = false;

  availablePreferences: number[];
  options: RadioOption[] = [];

  optionsShown: boolean = false;
  defaultOptInPreference: number;
  defaultOptOutPreference: number;
  softOptedInEmailPreference: number;
  consentSubscribe: Subscription;
  simpleConsentSubscribe: Subscription;
  showSofterGDPRMessage: boolean = false;
  private consentPromise: Promise<any>;

  constructor(private featuresService: FeaturesService, private emailService: EmailService, private windowRef: WindowRefService) {
    super();

    this.availablePreferences = this.featuresService.getFeature('GDPR').availableEmailPreferences;
  }

  /**
   * Build the form
   */
  buildForm(): BwFormDefintion<SimpleUserConsentControls> {
    return {
      simpleConsent: new FormControl(false)
    };
  }

  /**
   * BwForm required implementation
   */
  getObject(): void {}

  /**
   * BwForm required implementation
   */
  setObject(): void {}

  /**
   *
   */
  toggleOptInOptions(): void {
    this.optionsShown = !this.optionsShown;
  }

  /**
   * Updates the 'consent' control when the value of simpleConsent changes
   * @param value
   * @returns
   */
  onSimpleConsentChange(value: boolean): void {
    // convert to defaultOptIn if true
    if (value) {
      this.emailService.emailPreference = +this.defaultOptInPreference;
      this.bwFormControl.setValue(+this.defaultOptInPreference);
      return;
    }

    // convert to defaultOptOut if false
    this.emailService.emailPreference = +this.defaultOptOutPreference;
    this.bwFormControl.setValue(+this.defaultOptOutPreference);
  }

  /**
   * Resets the consent promise
   * Sends the consent if value is 0
   * @param value
   */
  onConsentChange(value: EmailPreference): Promise<Email | void> {
    // reset the promise
    this.consentPromise = undefined;

    // send consent
    if (value === 0 && this.bwFormControl.enabled) {
      return this.submitConsent();
    }

    return Promise.resolve();
  }

  /**
   * Submits the consent
   */
  submitConsent(): Promise<Email> {
    if (this.bwFormControl?.disabled || !this.email?.address?.length) {
      return Promise.resolve(this.email);
    }

    const email = this.email.clone();
    const preference = this.bwFormControl?.value;
    const copy = (
      ((this.windowRef.nativeWindow.document.querySelector('.user-details-consent') as HTMLElement) ?? {})?.innerText ?? ''
    ).trim();

    return (this.consentPromise =
      this.consentPromise || this.emailService.sendConsent(email, preference, copy, 'Web - Checkout - User Details'));
  }

  /**
   * Set consent preference using email
   * @param email
   * @returns
   */
  onEmailChange(email: Email): void {
    this.consentPromise = undefined;
    this.bwFormControl.reset();
    this.bwFormControl.disable();

    // do not initialise the consent form control when the user is already opted in
    if (email.preference > 0) {
      return;
    }

    this.bwFormControl.enable();
    const setPreference = !email.hasRegistered && !email?.hasOrdered ? this.softOptedInEmailPreference : this.defaultOptInPreference;
    this.bwFormControl.setValue(email.preference === -1 ? +setPreference : email.preference);
  }

  /**
   * On changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.email && this.bwFormControl) {
      this.onEmailChange(changes.email.currentValue);
    }
  }

  /**
   * On Init
   */
  ngOnInit(): void {
    super.ngOnInit();

    this.options = this.availablePreferences.map((id): EmailPreferenceItem => emailPreferences.find((e): boolean => e.value === id));

    // attach sendConsent method to the control so can be accesed this.bwFormControl.sendConsent()
    this.bwFormControl['submitConsent'] = async (): Promise<void> => {
      await this.submitConsent();
    };

    // Default opt-in / opt-out
    const configEmailPreference = this.featuresService.getFeature('GDPR');
    this.showSofterGDPRMessage = this.featuresService.getFeature('GDPR').softerGDPR;
    this.defaultOptInPreference = configEmailPreference.optedInEmailPreference;
    this.defaultOptOutPreference = configEmailPreference.optOutEmailPreference;
    this.softOptedInEmailPreference = configEmailPreference.softOptedInEmailPreference;

    this.showSofterGDPRMessage = configEmailPreference.softerGDPR;

    this.onEmailChange(this.email);

    this.consentSubscribe = this.bwFormControl?.valueChanges.subscribe(async (value): Promise<void> => {
      if (value !== null) {
        await this.onConsentChange(value);
      }

      return Promise.resolve();
    });

    this.simpleConsentSubscribe = this.get('simpleConsent').valueChanges.subscribe((value): void => {
      this.onSimpleConsentChange(value);
    });
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.consentSubscribe) {
      this.consentSubscribe.unsubscribe();
    }

    if (this.simpleConsentSubscribe) {
      this.simpleConsentSubscribe.unsubscribe();
    }
  }
}
