import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ReviewsModelService, ReviewsResponse } from 'Shared/models/reviews-model.service';
import { CountryService } from 'Shared/services/country.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-review-summary',
  templateUrl: './review-summary.component.html'
})
export class ReviewSummaryComponent implements OnInit {
  rating: ReviewsResponse;
  reviews: any = [];

  constructor(
    private reviewsModel: ReviewsModelService,
    private changeDetection: ChangeDetectorRef,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {
    const shippingCountry = this.countryService.forShipping;
    this.reviewsModel.getReviewStats(shippingCountry).then(reviewStats => {
      const stats = new ReviewsResponse();
      stats.averageRating = +reviewStats.average_rating;
      stats.count = +reviewStats.count;
      this.rating = stats;
      this.reviews = reviewStats.last_two;
    });
  }
}
