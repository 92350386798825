import { Component, Input } from '@angular/core';
import { ExperimentsService } from 'Shared/services/experiments.service';

@Component({
  selector: 'newsletter-signup',
  templateUrl: './newsletter-signup.component.html'
})
export class NewsletterSignupComponent {
  @Input()
  disclaimerastooltip: boolean = false; // Lowercase due to to contentful attributes being lowercase

  constructor(public experimentsService: ExperimentsService) {}
}
