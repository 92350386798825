
<div class="bw-register-form" *ngIf="form" id="bw-register-form">
  <form [formGroup]="form" [class.ng-submitted]="isSubmitted">
    <ui-form-group group_title="Passwords Form" [group_title_hidden]="true">
      <ui-text-input formControlName="name" id="name" name="name" [autofocus]="true" [autocorrect]="false" autocomplete="off" label="Your full name" [optional]="false" type="text">
        <ui-form-errors [errors]="form.get('name')?.errors" [error_messages]="{&quot;required&quot;:&quot;Name is required&quot;,&quot;fullNameRequired&quot;:&quot;First name and last name are required&quot;}"></ui-form-errors>
      </ui-text-input>
      <ui-text-input formControlName="email" name="text" id="email" label="Your email" type="email" [optional]="false">
        <ui-form-errors [errors]="form.get('email')?.errors" [error_messages]="{&quot;emailPattern&quot;:&quot;Invalid email&quot;,&quot;required&quot;:&quot;Email is required&quot;}"></ui-form-errors>
      </ui-text-input>
      <ui-text-input formControlName="password" show_password_string="Show" hide_password_string="Hide" name="password" autocomplete="new-password" label="Password" hint="8 characters or more. Create a strong password by including things like numbers, upper and lower case letters, or special characters, e.g. !?#&amp;#64;£" type="password" [optional]="false" [minlength]="8">
        <ui-form-errors [errors]="form.get('password')?.errors" [error_messages]="{&quot;minlength&quot;:&quot;Your password must be 8 characters or more.&quot;,&quot;required&quot;:&quot;Oops! Please enter a password of 8 characters or more.&quot;,&quot;passwordStrength&quot;:&quot;Please choose a stronger password. Try adding numbers, upper case and lower case letters, or special characters, e.g. !?#&amp;#64;£&quot;,&quot;strongCommonPassword&quot;:&quot;Please choose a stronger password. Try adding numbers, upper case and lower case letters, or special characters, e.g. !?#&amp;#64;£&quot;}"></ui-form-errors>
      </ui-text-input>
    </ui-form-group>
    <ui-form-group group_title="Marketing Form" [group_title_hidden]="true">
      <ng-container *ngIf="optinFeatureActive &amp;&amp; allowOptInOptions">
        <ui-box class="text-body--supporting" box_space="none" box_space_bottom="near" box_fill_mode="fill">
          <ui-stack stack_gap="near">
            <p *ngIf="!isJoiningRewards">Stay up to date with special offers, exciting products and news from Bloom and Wild. You can <a class="bwLink under" href="/privacy-statement" target="_blank">view our privacy statement</a> or unsubscribe at any time. <a class="bwLink under" (click)="toggleOptInOptions()"><span *ngIf="!optionsShown">See options</span> <span *ngIf="optionsShown">Close</span></a></p>
            <ui-stack class="text-body--supporting" *ngIf="isJoiningRewards" stack_gap="tight">
              <p class="text-body--emphasis">Stay up to date via email with Bloom & Wild Rewards, my birthday treat and VIP perks, as well as special offers and news about our products.</p>
              <p class="text-color--supporting">You can view our <a class="text-link--paragraph" href="/privacy-statement" target="_blank">privacy statement here</a> (to learn how we handle your personal data) and unsubscribe at any time. <a class="text-link--paragraph under" (click)="toggleOptInOptions()"><span *ngIf="!optionsShown">See options</span> <span *ngIf="optionsShown">Close</span></a></p>
            </ui-stack>
            <ui-form-group *ngIf="optionsShown" group_title="OptIn options" [group_title_hidden]="true" group_type="secondary">
              <ui-stack stack_gap="near" role="radiogroup">
                <ui-radio *ngFor="let option of options" formControlName="marketing" [label]="option.label" [value]="option.value + ''" radio_type="secondary" (didChange)="chooseMargetingOption(option)"></ui-radio>
              </ui-stack>
            </ui-form-group>
          </ui-stack>
        </ui-box>
      </ng-container>
      <ng-container *ngIf="!allowOptInOptions">
        <ui-checkbox *ngIf="!isJoiningRewards" formControlName="marketing" checkbox_type="secondary" id="marketing" label="Keep me updated with special offers, exciting products and news from Bloom &amp; Wild."><span>You can view our <a href="/privacy-statement" target="_blank">privacy statement here</a> and unsubscribe at any time.</span></ui-checkbox>
        <ui-checkbox *ngIf="isJoiningRewards" formControlName="marketing" checkbox_type="secondary" id="marketing" label="Keep me updated via email with Bloom &amp; Wild Rewards, my birthday treat and VIP perks, as well as special offers and news about our products."><span>You can view our <a class="text-link--paragraph" href="/privacy-statement" target="_blank">privacy statement here</a> (to learn how we handle your personal data) and unsubscribe at any time.</span></ui-checkbox>
      </ng-container>
      <ui-box *ngIf="isJoiningRewards &amp;&amp; origin === 'checkout' &amp;&amp; showTandC" box_space="none" box_space_bottom="default">
        <p class="text-body--supporting text-color--supporting">You can <a class="text-link--paragraph" href="/bw-rewards-terms" target="_blank">read our full T&Cs here</a>.</p>
      </ui-box>
    </ui-form-group>
  </form>
  <ui-button button_type="primary" button_full_width="true" (clicked)="submit()" bwtrackas="checkout.userDetails.createAccount">Create account</ui-button>
</div>
<bw-loading-spinner *ngIf="loading"></bw-loading-spinner>