/**
 * Interceptor to convert bw-carousel coming from contentful into a useable component
 * by camelCasing the properties
 * @param bwButton
 * @returns
 */
export default function bwCarouselInterceptor(bwCarousel: HTMLElement): any {
  const attributes = [
    'verticalAlignment',
    'itemsPerPageDesktop',
    'itemsPerPageTablet',
    'itemsPerPageMobile',
    'spaceBetweenItems',
    'paginationButtons',
    'paginationDots',
    'fullPage',
    'automaticItemWidth',
    'scrollSnap'
  ];
  // this regex extracts the text from the []
  const regex = /\[(.*?)\]/;
  attributes.forEach((attr) => {
    if (bwCarousel.getAttribute(attr.toLowerCase())) {
      bwCarousel.setAttributeNS(
        null,
        regex.exec(attr) ? regex.exec(attr)[1] : attr,
        bwCarousel.getAttribute(attr.toLowerCase())
      );
      bwCarousel.removeAttribute(attr.toLowerCase());
    }
  });

  return bwCarousel;
}
