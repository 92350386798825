import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Project/shared/utils/dom-utils.service';
import { ConfigService } from '../config.service';
import { WindowRefService } from '../window.service';
import { Purchase } from 'Shared/classes/purchase';
import { User } from 'Shared/classes/user';
interface IPartnerizeParams {
  campaign: string;
  conversionref: number;
  country: string;
  currency: string;
  customertype: string;
  custref: string;
  voucher: string;
}
interface IPartnerizeProductParams {
  category: string;
  sku: number;
  value: string;
  quantity: number;
}
@Injectable({
  providedIn: 'root'
})
export class PartnerizeService {
  private serviceInitialized: boolean = false;
  partnerizeBrandId: string;
  partnerizeTagId: string;
  partnerizeCampaignMatrix: string;
  debug: boolean = false;
  window: any;
  initPromise: Promise<any>;

  constructor(
    private windowRef: WindowRefService,
    private domUtils: DomUtilsService,
    private configService: ConfigService
  ) {
    this.partnerizeBrandId = this.configService.getConfig().partnerizeBrandId;
    this.partnerizeTagId = this.configService.getConfig().partnerizeTagId;
    this.partnerizeCampaignMatrix = this.configService.getConfig().partnerizeCampaignMatrix;
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<partnerize-marketing>', ...args);
    }
  }

  /**
   * Track purchase
   *
   * @param purchase - purchase object
   * @param user - user object
   */
  trackPurchase(purchase: Purchase, user: User): void {
    this.partnerize(purchase, user);
  }

  /**
   * Check we are ready for tracking, make sure a clickref is present and then set up tracking
   *
   * @param purchase - purchase object
   * @param user - user object
   */
  partnerize(purchase: Purchase, user: User): void {
    try {
      if (this.serviceInitialized && this.window.localStorage['_pz_clickref']) {
        this.trackPurchaseUsingPixel(purchase, user);
      }
    } catch (e) {}
  }

  /**
   * Append div pixel to body
   *
   * @param purchase - purchase object
   * @param user - user object
   */
  trackPurchaseUsingPixel(purchase: Purchase, user: User): Promise<any> {
    // Partnerize data object used to build the first part of the pixel url path
    const partnerizeData: IPartnerizeParams = {
      campaign: this.partnerizeCampaignMatrix,
      conversionref: purchase.id,
      country: this.configService.getConfig().country.toUpperCase(),
      currency: purchase.price.currency,
      customertype: user.orderCount > 0 ? 'existing' : 'new',
      custref: user.slug,
      voucher: purchase.discount ? purchase.discount.code : ''
    };

    // Create a product data array based on individual orders
    const productDataArray: IPartnerizeProductParams[] = purchase.orders.map((order) => {
      const productData: IPartnerizeProductParams = {
        category: order.product.collectionName,
        sku: order.id,
        value: (order.price.price / 100).toFixed(2),
        quantity: order.price.quantity
      };
      return productData;
    });

    return this.domUtils
      .loadPixelElement(
        this.constructPixelUrl(partnerizeData, productDataArray),
        'partnerizepixel',
        'div',
        'data-partnerize'
      )
      .then(() => {
        this.domUtils.loadScript(
          `https://pzapi-kg.com/b/${this.partnerizeBrandId}/${this.partnerizeTagId}.js`,
          'partnerizetag'
        );
        return this.log('Partnerize Pixel', { ...partnerizeData, productDataArray });
      });
  }

  /**
   * Use the two objects to build the partnerize pixel url
   *
   * @param partnerizeData - The main data that partnerize need to track a purchase
   * @param productDataArray - The individual order data for each purchased item
   */
  private constructPixelUrl(
    partnerizeData: IPartnerizeParams,
    productDataArray: IPartnerizeProductParams[]
  ): string {
    // Create first url fragment based on the partnerizeData object
    const urlFragmentOne = Object.entries(partnerizeData).reduce(
      (acc, [key, value]) => `${acc}/${key}:${value}`,
      'https://prf.hn/conversion'
    );

    // Second url fragment to start with a forward slash
    let urlFragmentTwo: string = '/';

    // Add the productData objects, inside square brackets, to the second url fragment
    productDataArray.forEach((data) => {
      urlFragmentTwo =
        urlFragmentTwo +
        `[${Object.keys(data)
          .map((key) => key + ':' + data[key])
          .join('/')}]`;
    });

    return urlFragmentOne + urlFragmentTwo;
  }

  /**
   * Initiliase the partnerize service and add the script
   */
  init(): Promise<any> {
    this.initPromise =
      this.initPromise ||
      this.domUtils
        .loadScript(
          `https://pzapi-kg.com/b/${this.partnerizeBrandId}/${this.partnerizeTagId}.js`,
          'partnerizetag'
        )
        .then(() => {
          this.serviceInitialized = true;
        });

    return this.initPromise;
  }
}
