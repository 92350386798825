import { Component, OnInit } from '@angular/core';
import { MegaNavService } from 'Shared/services/mega-nav.service';
import { MegaNav } from 'Shared/classes/mega-nav-item';
import { NavBreadcrumbs } from 'Shared/classes/nav-breadcrumbs';
import { StateService } from 'Shared/services/state.service';
import { ActivatedState } from 'Shared/classes/activated-state';
import { LocationService } from 'Shared/services/location.service';
import { translations } from 'Environments/js-translations';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { CountryService } from 'Shared/services/country.service';

export const checkoutBaseUrl = translations['js.routing.checkout_base'];
export const baseCarouselBreadCrumb = translations['js.meganav.base.breadcrumb'];
@Component({
  selector: 'bw-nav-breadcrumbs',
  templateUrl: './nav-breadcrumbs.component.html',
  styleUrls: ['./nav-breadcrumbs.component.scss']
})
export class NavBreadcrumbsComponent implements OnInit {
  megaNavContent: MegaNav;
  activeBreadcrumbs: any[] = [];
  currentStateUrl: string;
  currentState: ActivatedState;
  listType: any;

  constructor(
    private megaNavService: MegaNavService,
    private stateService: StateService,
    private locationService: LocationService,
    private analyticsService: AnalyticsService,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {
    this.currentState = this.stateService.getCurrent();
    this.listType = this.locationService.getListType();
    this.currentStateUrl = `/${checkoutBaseUrl}/${this.listType.type}/${
      this.listType.value
    }`.toLowerCase();

    if (this.listType.type === 'base') {
      this.activeBreadcrumbs.push({ name: `${baseCarouselBreadCrumb}`, url: `${checkoutBaseUrl}` });
    }

    if (this.listType.type === 'tagOnly') {
      // get mega nav for breadcrumbs reasons
      this.initMegaNavContents();
    }
  }

  breadcrumbInteractionTracking(clickedBreadcrumb): void {
    this.analyticsService.trackInHeap('breadcrumbsClicked', {
      breadcrumbState: this.activeBreadcrumbs.map(crumb => crumb.name).join(),
      breadcrumb: clickedBreadcrumb.name
    });
  }

  /**
   * Get the Mega Nav Content
   */
  initMegaNavContents(): Promise<any> {
    return this.megaNavService.getMegaNav(this.countryService.forShipping).then(nav => {
      this.megaNavContent = nav;
      this.activeBreadcrumbs = this.stateService.activeChildNavPath
        ? this.removeDuplicateCrumbs(this.stateService.activeChildNavPath)
        : [];

      if (!this.stateService.activeChildNavPath) {
        this.getBreadcrumbs(this.megaNavContent);
      }
      this.stateService.activeChildNavPath = null;

      if (this.activeBreadcrumbs.length === 1) {
        this.activeBreadcrumbs.unshift({ name: 'All', url: `/${checkoutBaseUrl}` });
      }
    });
  }

  /**
   *
   * @param breadcrumbs
   */
  removeDuplicateCrumbs(breadcrumbs): any {
    const parentCrumb = (breadcrumbs || []).slice().splice(0, 1);

    const filteredBreadcrumbs = (breadcrumbs || []).filter(crumb =>
      parentCrumb[0].url !== crumb.url ? crumb : ''
    );

    return parentCrumb.concat(filteredBreadcrumbs);
  }

  /**
   * Get Breadcrumbas on every state change
   */
  getBreadcrumbs(nav: MegaNav): void {
    // First check parents
    const foundInParent = this.checkParents(nav);

    this.activeBreadcrumbs = foundInParent && foundInParent.length > 0 ? foundInParent : [];

    // If not found in parents recursivly find in children
    if (!foundInParent.length) {
      const breadcrumbs = this.megaNavService.findPathToNavItem(nav, this.currentStateUrl);

      this.activeBreadcrumbs = this.removeDuplicateCrumbs(breadcrumbs);
    }
  }

  private checkParents(nav): NavBreadcrumbs[] {
    const navParentItems = ((nav && nav.items) || [])
      .map(navItem => {
        return {
          name: navItem.name,
          url: navItem.url,
          underlineColour: navItem.underlineColour ? navItem.underlineColour : ''
        };
      })
      .filter(navItems => navItems.url === this.currentStateUrl);

    return navParentItems;
  }
}
