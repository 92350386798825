import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'Shared/services/config.service';
import { CountryService } from 'Shared/services/country.service';
import { ProductService } from 'Checkout/services/product.service';
import { ContentfulImageComponent } from 'Content/components/contentful-image/contentful-image.component';
import { Product } from 'Shared/classes/product';
import { Discount } from 'Shared/classes/discount';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-quick-shop',
  templateUrl: './quick-shop.component.html'
})
export class QuickShopComponent implements OnInit {
  products: Product[];

  constructor(
    private configService: ConfigService,
    private countryService: CountryService,
    private productService: ProductService
  ) {}

  /**
   * On init
   *
   * @returns {Promise}
   */
  ngOnInit(): Promise<any> {
    const country = this.countryService.forShipping;
    const homepageSkuIds = this.configService
      .getConfig()
      .homepage_sku_ids.find(countrySkus => country.id === countrySkus.shipping_country_id).skus;

    return this.productService.getQuickShopProducts(country, homepageSkuIds).then(p => {
      this.products = p;
    });
  }
}
