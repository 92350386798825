import { trigger, state, style, transition, animate } from '@angular/animations';

export const ModalAnimations = [
  trigger('backdrop', [
    state(
      'visible',
      style({
        opacity: '1'
      })
    ),
    state(
      'hidden',
      style({
        opacity: '0'
      })
    ),
    transition('* => *', [animate('.250s 0.01s ease-in-out')])
  ]),
  trigger('modal', [
    state(
      'out-bottom',
      style({
        transform: 'translateY(100%)'
      })
    ),
    state(
      'out-left',
      style({
        transform: 'translateX(-100%)'
      })
    ),
    state(
      'out-right',
      style({
        transform: 'translateX(100%)'
      })
    ),
    state(
      'out-center',
      style({
        transform: 'scale(0.5)',
        opacity: '0'
      })
    ),
    state(
      'in',
      style({
        transform: '*'
      })
    ),
    transition('* => in', [animate('.250s 0.01s cubic-bezier(.53,.01,.35,1)')]),
    transition('in => *', [animate('.250s 0.01s cubic-bezier(.82,.17,.35,1)')])
  ]),
  trigger('modal', [
    state(
      'none',
      style({
        transform: '*'
      })
    ),
    transition('* => in', []),
    transition('in => *', [])
  ])
];
