import { Injectable } from '@angular/core';
// import { defineIdleProperty } from 'idlize/defineIdleProperty.mjs';
// import { defineIdleProperties } from 'idlize/defineIdleProperties.mjs';
import { IdleQueue } from 'Shared/services/Idlize/IdleQueue';

@Injectable({
  providedIn: 'root'
})
export class UntilIdleService {
  idleQueue: IdleQueue;

  constructor() {
    this.idleQueue = new IdleQueue();
  }

  /**
   * Queue up
   * @param func
   * @param delaySeconds
   */
  queue(func: any, delaySeconds: number = 0): void {
    const fn = () => {
      try {
        func();
      } catch (e) {
        console.error(e);
      }
    };

    if (!delaySeconds) {
      this.idleQueue.pushTask(fn);
      return;
    }
    setTimeout(() => {
      this.idleQueue.pushTask(fn);
    }, delaySeconds * 1000);
  }

  runAll(): void {
    this.idleQueue.runTasksImmediately();
  }
}
