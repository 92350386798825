import { Injectable } from '@angular/core';
import { BackendService, APISerialisedJSONResponse } from 'Shared/services/backend.service';
import { UserLookup } from 'Shared/classes/user-lookup';

@Injectable({
  providedIn: 'root'
})
export class UserLookupModelService {
  constructor(private backend: BackendService) {}

  /**
   * Get user by email
   * successfully returning means email is known
   * @param {string} email
   * @returns {string}
   */
  checkUserByEmail(email: string): Promise<UserLookup> {
    return this.backend
      .get(null, '/v1/user_lookup', {
        params: {
          email: email.toLowerCase()
        }
      })
      .then((res): UserLookup => this.fromPayload(res));
  }

  /**
   * Format payload response in to expected format
   * @param {APISerialisedJSONResponse<'/v1/user_lookup'>} res
   * @returns {string}
   */
  private fromPayload(res: APISerialisedJSONResponse<'/v1/user_lookup'>): UserLookup {
    return {
      identifier: res.data.identifier,
      guest: res.data.guest,
      loyaltySchemeMembership: res.data.loyalty_scheme_membership
    };
  }
}
