import { Component, OnInit } from '@angular/core';
import { ModalResponse, ModalResponseSubject } from 'Shared/services/modal.service';
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { UserService, User } from 'Shared/services/user.service';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { t } from 'Shared/utils/translations';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from 'Shared/components/loading-spinner/loading-spinner.component';
import {
  OasysAlertModule,
  OasysButtonModule,
  OasysFormErrorsModule,
  OasysFormGroupModule,
  OasysIconModule,
  OasysLayoutModule,
  OasysTextInputModule
} from 'oasys-lib';
import { ErrorItem } from 'Shared/classes/error';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { ExperimentsService } from 'Shared/services/experiments.service';

/* bw:view-encapsulation */
@Component({
  standalone: true,
  imports: [
    NgIf,
    LoadingSpinnerComponent,
    OasysButtonModule,
    OasysIconModule,
    OasysLayoutModule,
    OasysFormGroupModule,
    OasysTextInputModule,
    OasysFormErrorsModule,
    ReactiveFormsModule,
    OasysAlertModule
  ],
  selector: 'bw-reset-password-modal',
  templateUrl: './reset-password-modal.component.html'
})
export class ResetPasswordModalComponent implements OnInit, ModalResponse {
  modalResponse: Subject<ModalResponseSubject> = new Subject<ModalResponseSubject>();

  form: FormGroup;
  user: User;
  email: string = null;

  loading: boolean;

  identifiedUser: string;
  isSubmitted: boolean;

  modalTitle: string; // From modal launch
  token: string; // From modal launch

  experiment: number;

  /**
   * Constructor
   * @param userService
   */
  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private optimizelyService: OptimizelyService,
    private analyticsService: AnalyticsService,
    private experimentsService: ExperimentsService
  ) {}

  ngOnInit(): void {
    this.analyticsService.trackInHeap('modalView', {
      modalType: 'reset-password-modal'
    });
    this.optimizelyService.decide('hpt93_bw_uk_web_password_complexity');
    this.experiment = this.experimentsService.getVariantFor('HPT93_BW_UK_WEB_PASSWORD_COMPLEXITY');

    this.identifiedUser = this.email ?? undefined;
    this.buildForm();
  }

  /**
   * On cancel
   */
  onCancel(): void {
    this.loading = false;
    this.modalResponse.next({ success: false });
  }

  /**
   * Submit the form
   * @param form
   */
  submit(): Promise<void> {
    const password = this.form.get('password').value;
    const repeatPassword = this.form.get('repeatPassword').value;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.markAsDirty();
      this.isSubmitted = true;
      return Promise.resolve();
    }

    if (password !== repeatPassword) {
      this.addMatchPasswordError();
      return Promise.resolve();
    }

    this.loading = true;
    if (this.experiment === 0) {
      return this.resetPassword(password);
    }

    return this.userService.validateStrongPassword(password).then((res): Promise<void> => {
      if (res) {
        return this.resetPassword(password);
      }
      this.form.get('password').setErrors({ strongCommonPassword: true });
      this.loading = false;
    });
  }

  /**
   * Reset Password
   * @param password
   * @returns
   */
  private resetPassword(password: string): Promise<void> {
    return this.userService
      .resetPassword(this.token, password)
      .then((user: User): void => {
        this.toastr.success(t('js.components.reset-password-modal.message.success'));
        this.userService.setAsLoggedIn(user);
        this.loading = false;
        this.optimizelyService.trackEvent('set_password');
        this.optimizelyService.trackEvent('reset_password');
        return this.modalResponse.next({ success: true, data: user });
      })
      .catch((error: ErrorItem): void => {
        this.isSubmitted = true;
        this.loading = false;
        this.toastr.error(error.message, error.title);
      });
  }

  /**
   * Build Form
   */
  private buildForm(): void {
    this.form = new FormGroup({
      password: new FormControl('', {
        validators: [Validators.required, Validators.minLength(8)]
      }),
      repeatPassword: new FormControl('', {
        validators: [Validators.required, Validators.minLength(8)]
      })
    });
  }

  /**
   * Add match error to repeat field
   */
  private addMatchPasswordError(): void {
    this.form.get('repeatPassword').setErrors({
      notMatch: true
    });
    this.form.get('repeatPassword').markAsDirty();
    this.form.get('repeatPassword').markAsTouched();
    this.form.markAllAsTouched();
    this.isSubmitted = true;
  }
}
