import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService, Config } from 'Shared/services/config.service';
import { LocalConfig } from 'Shared/services/config.service.typings';
import { StateService } from 'Project/shared/services/state.service';
import { lastValueFrom } from 'rxjs';
import { BlogCardItem, ContentfulFields, ContentfulResponse, ContentfulResponseItem } from 'Shared/interfaces/blog-card-item';
import { CountryService } from 'Shared/services/country.service';

@Injectable({
  providedIn: 'root'
})
export class BlogItemsModel {
  config: Config;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private stateService: StateService,
    private countryService: CountryService
  ) {
    this.config = this.configService.getConfig();
  }

  /**
   * Get the major landing page items
   *
   * @param tag - The tag of the major landing pages to be shown
   * @returns
   */
  public async getMajorLandingPageAsBlog(tag: string): Promise<BlogCardItem[]> {
    const contentfulParameters: LocalConfig['contentfulParameters'] = this.config.contentfulParameters;
    const contentSlugPrefix: string = contentfulParameters.contentLocale ? `${contentfulParameters.contentLocale}` : '';

    // Get the blog list nav items config for the given locale
    const config = this.config.blog_list_nav_items;
    const shippingCountry = this.countryService.forShipping;
    let blogListNavItemsConfig = config.filter((entry): boolean => entry.shippingCountryId === shippingCountry?.id)[0]?.items;

    if (!blogListNavItemsConfig && this.config.locale) {
      const filteredEntries = config.filter((entry): boolean => entry.locale === this.config.locale);

      blogListNavItemsConfig = filteredEntries.length > 0 && filteredEntries[0].items ? filteredEntries[0].items : [];
    }

    // If the blog list nav items config is not present, or the major landing pages are not enabled, return an empty array
    if (!blogListNavItemsConfig || !contentfulParameters.enableMajorLandingPages) {
      return [];
    }

    // This builds our tag list to be used in the query
    // If a tag is passed, use that tag only,
    // otherwise the user is on the 'All' page, and we need to use the tags from the blog list nav items config
    const tagPrefix: string = 'blog-item-';
    const tagList: string = tag
      ? `${tagPrefix}${tag}`
      : blogListNavItemsConfig
          ?.map((item): string => {
            const tags: string[] = item.url.split('tagged/');
            return tags[1] ? `${tagPrefix}${tags[1]}` : '';
          })
          .toString();

    // Build the url for the get request
    const url: string = this.buildUrl(tagList, contentSlugPrefix, 'majorLandingPage');

    // Fetch the data from contentful
    const majorLandingPages: ContentfulResponse = await this.fetchContentfulData(url);

    const assets: ContentfulResponseItem[] = majorLandingPages?.includes?.Asset;

    return majorLandingPages?.items?.map((item: ContentfulResponseItem): BlogCardItem => {
      const imageAsset: ContentfulResponseItem = assets.find((asset): boolean => asset?.sys.id === item.fields?.sharePreviewImage?.sys?.id);
      const imageUrl: string = imageAsset ? imageAsset?.fields?.file?.url : '';
      const tags: string[] = item?.metadata?.tags?.map((res): string => res?.sys.id.replace(`${tagPrefix}`, ''));
      const createdAt: string = item?.sys?.createdAt;

      return this.mapMajorLandingPagesToBlogCardItem(item.fields, tags, createdAt, imageUrl);
    });
  }

  /**
   * Get the blog items
   *
   * @param tag - The tag of the blogs to be shown
   * @returns
   */
  public async getBlogItems(tag: string): Promise<BlogCardItem[]> {
    // Build the url for the get request
    const contentfulParameters: LocalConfig['contentfulParameters'] = this.config.contentfulParameters;
    const contentSlugPrefix = contentfulParameters.contentLocale ? `${contentfulParameters.contentLocale}` : '';
    const parameters: LocalConfig['contentfulParameters'] = this.config.contentfulParameters;
    const url = this.buildUrl(tag, contentSlugPrefix, parameters.contentType);
    // Fetch the data from contentful
    const blogs = await this.fetchContentfulData(url);

    // Map the image assets to each item and convert to BlogCardItems
    const assets = blogs?.includes?.Asset;
    return blogs?.items?.map((item: ContentfulResponseItem): BlogCardItem => {
      const foundImage = this.findMatchingImage(assets, item);

      const imageUrl: string = foundImage ? foundImage?.fields.file?.url : '';

      if (contentSlugPrefix) {
        item.fields = this.removeSlugPrefix(item.fields, contentSlugPrefix) as ContentfulFields;
      }

      return this.mapBlogsToBlogCardItem(item?.fields, imageUrl);
    });
  }

  /**
   * Fetch and process the contentful data
   *
   * @param url - The url to fetch the data from
   * @returns - The returned data
   */
  private fetchContentfulData(url: string): Promise<ContentfulResponse> {
    try {
      return lastValueFrom(this.http.get(url));
    } catch (error) {
      throw new Error(error);
    }
  }

  /**
   * Remove the slug prefix from the field
   *
   * @param field - The field to remove the prefix from
   * @param prefixToRemove - The prefix to remove
   * @returns - the passed field object with the prefix removed
   */
  private removeSlugPrefix(field: unknown, prefixToRemove: string): unknown {
    if (typeof field === 'string') {
      field = field.indexOf(prefixToRemove) === 0 ? field.replace(prefixToRemove, '') : field;
    } else if (Array.isArray(field)) {
      field = field.map((v): unknown => this.removeSlugPrefix(v, prefixToRemove));
    } else if (typeof field === 'object' && field !== null) {
      Object.entries(field).forEach(([key]): void => {
        field[key] = this.removeSlugPrefix(field[key], prefixToRemove);
      });
    }

    return field;
  }

  /**
   * Build the url based on the passed values
   *
   * @param tag - The tag of the blogs to be shown
   * @param contentSlugPrefix - The prefix to add to the slug
   * @returns - the url to fetch the data from
   */
  private buildUrl(tag: string, contentSlugPrefix: string, contentType: string): string {
    const isPreviewMode = this.configService.isPreviewMode();
    const spaceId: string = this.config.contentfulSpaceId;
    const previewKey: string = this.config.contentfulPreviewKey;
    const queryKey: string = this.config.contentfulQueryKey;

    let url = isPreviewMode ? 'https://preview.contentful.com' : 'https://cdn.contentful.com';
    url += `/spaces/${spaceId}/entries?access_token=`;
    url += isPreviewMode ? previewKey : queryKey;
    url += `&content_type=${contentType}`;

    if (tag) {
      url += contentType !== 'majorLandingPage' ? `&fields.tags[in]=${tag}` : `&metadata.tags.sys.id[in]=${tag}`;
    }

    if (contentSlugPrefix) {
      url += `&fields.slug[match]="${contentSlugPrefix}"`;
    }

    return url;
  }

  /**
   * Find the matching image for the given item
   *
   * @param assets - The assets to search through
   * @param item - The item to find the image for
   * @returns - The matching image
   */
  private findMatchingImage(assets: ContentfulResponseItem[], item: ContentfulResponseItem): ContentfulResponseItem {
    return assets.find(
      (asset: ContentfulResponseItem): boolean =>
        asset.sys.id ===
        (item.fields?.image || item.fields?.blogHeaderImage || item.fields?.blogHeaderImageDesktop || item.fields?.blogHeaderImageMobile)
          ?.sys?.id
    );
  }

  /**
   * Map the blogs to the blog card item
   *
   * @param item - The item to map
   * @returns
   */
  private mapBlogsToBlogCardItem(item: ContentfulFields, imageUrl: string): BlogCardItem {
    return {
      title: item?.pageMainHeader ?? item?.title,
      description: item?.metaDescription ?? item?.summary,
      publishedDate: item?.publishedDate ?? item?.date,
      imageUrl: imageUrl ?? '',
      imageAltText: item?.blogHeaderImageAltText,
      slug: item.slug ?? item?.link,
      tags: item.tags
    };
  }

  /**
   * Map the major landing pages to the blog card item
   *
   * @param item - The item to map
   * @returns
   */
  private mapMajorLandingPagesToBlogCardItem(item: ContentfulFields, tags: string[], createdAt: string, imageUrl: string): BlogCardItem {
    return {
      title: item?.pageTitle ?? '',
      publishedDate: createdAt ?? '',
      description: item?.metaDescription ?? '',
      imageUrl: imageUrl ?? '',
      imageAltText: '',
      slug: (this.removeSlugPrefix(item.slug, this.config.contentfulParameters.contentLocale) as string) ?? '',
      tags: tags ?? []
    };
  }
}
