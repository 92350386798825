import {
  Component,
  Input,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { LazyLoaderService } from 'Shared/services/lazy-loader.service';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { ImageSizePipe } from 'Shared/pipes/image-size.pipe';
import { ImageRole } from 'Content/components/contentful-image/contentful-image';

/**
 * Without a template, this is basically a diretive and will run for all images on the page
 * We can't use directives in the dynamic content viewer
 */
@Component({
  selector: 'img, div[bw-contentful-image],  li[bw-contentful-image]', // img[bw-contentful-image]
  template: '<ng-content></ng-content>'
})
export class ContentfulImageComponent implements AfterViewInit, OnDestroy, OnChanges {
  id: string;

  @Input('bwContentfulImage')
  bwContentfulImage;

  @Input('bwEcommImage')
  bwEcommImage;
  @Input('width')
  width: string;
  @Input('height')
  height?: string;
  @Input('lazyLoaded')
  lazyLoaded: boolean = true;
  @Input() imageRole: ImageRole = 'default';
  @Input() imageNoFill: string = 'false';

  elem;
  originalSrc: string;
  attributeWidth: number;
  type: string;
  density: number = this.windowRef.nativeWindow.devicePixelRatio;

  QUALITY_FOR_WEBP = 75;
  QUALITY_DEFAULT = 100;

  // These breakpoints are our most popular based on GA data
  breakpoints: number[] = [
    320, 360, 375, 414, 640, 750, 768, 1080, 1280, 1366, 1440, 1536, 1600, 1680, 1920, 2560, 3440,
    3840
  ];

  /**
   * Constructor
   * @param el
   * @param windowRef
   */
  constructor(
    el: ElementRef,
    private windowRef: WindowRefService,
    private lazyloadService: LazyLoaderService,
    private domUtilsService: DomUtilsService,
    private imageSizePipe: ImageSizePipe
  ) {
    this.elem = el.nativeElement;

    this.originalSrc =
      this.elem.getAttribute('bw-contentful-image') || this.bwEcommImage || this.bwContentfulImage;

    // little hack to make sure contentful image roles are properlly working.
    const contentfulImageRole = this.elem.getAttribute('imagerole');
    if (contentfulImageRole) {
      this.imageRole = contentfulImageRole;
    }

    const imageNoFill = this.elem.getAttribute('imageNoFill');
    if (imageNoFill) {
      this.imageNoFill = imageNoFill;
    }

    this.type = this.elem.getAttribute('bw-contentful-image') ? 'contentful' : 'ecomm';

    this.attributeWidth = parseInt(this.elem.getAttribute('width'), 10) || 0;
  }

  /**
   * Get the first maximum breakpoint, or if larger, get the device width
   * This means we can pre-cache images using the contentful API
   */
  getMaximumWidthBasedOnBreakPoint(width: number): number {
    return (
      this.breakpoints.find((b) => width <= b) || this.breakpoints[this.breakpoints.length - 1]
    );
  }

  /**
   * Get the width based on the device
   */
  getRequiredWidth(requiredWidth: number): number {
    const density = this.getDenisity(this.domUtilsService.supportsWebP);
    const deviceWidth = this.getMaximumWidthBasedOnBreakPoint(this.elem.offsetWidth * density);
    if (requiredWidth) {
      requiredWidth = this.getMaximumWidthBasedOnBreakPoint(requiredWidth * density);
    }
    if (!requiredWidth || deviceWidth < requiredWidth) {
      return parseInt(deviceWidth.toFixed(0), 10); // No half pixels.
    }
    return parseInt(requiredWidth.toFixed(0), 10); // No half pixels
  }

  /**
   * Set the width based on the element width
   */
  setSrcWidthBasedOnElement(src, attrWidth: number): void {
    const width = this.getRequiredWidth(attrWidth);
    const fullSrc =
      this.type === 'contentful'
        ? this.getImageUrlContentful(src, width)
        : this.getImageUrlEcomm(src, width);

    this.elem.setAttribute('src', fullSrc);
  }

  getQuality(isWebP: boolean): number {
    return isWebP ? this.QUALITY_FOR_WEBP : this.QUALITY_DEFAULT;
  }

  getDenisity(isWebP: boolean): number {
    return isWebP ? (this.density > 2 ? this.density : 2) : 1;
  }

  /**
   * Get the url for contentful images
   * @param originalSrc
   * @param width
   */
  getImageUrlContentful(originalSrc: string, width: number): string {
    const height = this.imageSizePipe.getRequiredHeight(width, this.imageRole);
    const format = this.domUtilsService.supportsWebP ? 'webp' : 'jpg&fl=progressive';
    const quality = this.getQuality(this.domUtilsService.supportsWebP);

    return this.imageRole !== 'default' && this.imageNoFill === 'false'
      ? `${originalSrc}?w=${width}&h=${height}&fm=${format}&fit=fill&q=${quality}`
      : `${originalSrc}?w=${width}&h=${height}&fm=${format}&q=${quality}`;
  }

  /**
   * Get the image Url for ecomm based on
   * @param originalSrc
   * @param width
   */
  getImageUrlEcomm(originalSrc: string, width: number): string {
    // https://media.bloomdev.org/v1/600x600/filters:format(webp)/
    // https://media.bloomdev.org/v1/800x800/filters:format(jpeg)/
    // Notes are also on the wiki - https://sites.google.com/a/bloomandwild.com/bloom-wiki/tech-team/tech-stack/image-resizing-service
    // HeightAuto variable used to define when image is not wanted in square ratio
    return this.imageSizePipe.transform(
      originalSrc,
      width,
      this.height,
      this.imageRole,
      this.getQuality(this.domUtilsService.supportsWebP)
    );
  }

  /**
   * Set the style="background-image: url('x')" based on element width
   */
  setBackgroundStyleBasedOnElement(src, attrWidth): void {
    const deviceWidth = this.getMaximumWidthBasedOnBreakPoint(this.elem.offsetWidth);
    let requiredWidth = attrWidth || deviceWidth;
    // If the element has a set width, use it
    if (this.elem.style.width && this.elem.style.width.indexOf('px')) {
      requiredWidth = parseInt(this.elem.style.width.replace('px', ''), 10);
    }
    const width = this.getRequiredWidth(requiredWidth);

    const fullSrc =
      this.type === 'contentful'
        ? this.getImageUrlContentful(src, width)
        : this.getImageUrlEcomm(src, width);

    this.elem.style.backgroundImage = `url('${fullSrc}')`;
  }

  /**
   * Load the image
   */
  load(): void {
    return this.elem.tagName.toLowerCase() === 'img'
      ? this.setSrcWidthBasedOnElement(this.originalSrc, this.attributeWidth)
      : this.setBackgroundStyleBasedOnElement(this.originalSrc, this.attributeWidth);
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.originalSrc = this.bwEcommImage || this.originalSrc || this.bwContentfulImage;

    if ((this.originalSrc || '').indexOf('images.ctfassets.net') > -1) {
      this.type = 'contentful';
    }

    if (!this.originalSrc) {
      return;
    }

    if (this.lazyLoaded) {
      this.lazyloadService.addToQueue(this);
    } else {
      this.load();
    }
  }

  /**
   * On changes
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    const prevImageValue = changes.bwEcommImage?.previousValue;
    if (prevImageValue && changes.bwEcommImage?.currentValue !== prevImageValue) {
      this.ngAfterViewInit(); // set the image
    }
  }

  ngOnDestroy(): void {
    this.lazyloadService.unqueue(this);
  }
}
