
<div class="contact-us">
  <div class="card-text" *ngIf="success">
    <div class="contact-us__success">
      <div class="success__container"><img class="success__icon" src="/assets/brand-icons/envelope.svg">
        <h4 class="success__heading">Thank you, your query has been received!</h4>
      </div>
    </div>
  </div>
  <div class="card-text" *ngIf="!success">
    <form [ngClass]="{'ng-submitted': hasSubmitted}" [formGroup]="contactUsForm" sectionName="qualityOrderSearchSection">
      <fieldset class="quality-modal__form-set">
        <div class="quality-modal__form-group">
          <bw-form-dropdown *ngIf="reasons?.length &amp;&amp; formtype == 'general'" [bwFormControl]="formGroup.controls.reason" label="Nature of your question" inputType="display" [data]="reasonsDLL" placeholder="Choose among the following..." [displayFn]="displayReason">
            <ng-container role="errors">
              <div for="required">This field is required</div>
            </ng-container>
          </bw-form-dropdown>
          <bw-form-text-input [bwFormControl]="formGroup.controls.fullName" label="Full Name" autocomplete="fullName" inputType="text" (didFocus)="onFocus('fullName')">
            <ng-container role="errors">
              <div for="required">Full name is required</div>
            </ng-container>
          </bw-form-text-input>
          <bw-form-text-input [bwFormControl]="formGroup.controls.email" label="Email" autocomplete="email" inputType="text" (didFocus)="onFocus('email')">
            <ng-container role="errors">
              <div for="pattern">Invalid email</div>
              <div for="required">Email is required</div>
              <div for="email">The provided email address is invalid</div>
            </ng-container>
          </bw-form-text-input>
          <bw-form-text-input [bwFormControl]="formGroup.controls.order" label="Order Number (RECOMMENDED)" autocomplete="order" inputType="text" (didFocus)="onFocus('orderNumber')">
            <ng-container role="errors">
              <div for="required">This field is required</div>
            </ng-container>
          </bw-form-text-input>
          <bw-form-text-input *ngIf="formtype == 'general'" [bwFormControl]="formGroup.controls.postCode" label="Delivery Postcode (RECOMMENDED)" autocomplete="postCode" inputType="text" maxlength="10" (didFocus)="onFocus('postCode')">
            <ng-container role="errors">
              <div for="required">This field is required</div>
            </ng-container>
          </bw-form-text-input>
          <bw-form-text-input *ngIf="formtype == 'quality' || formtype == 'delivery'" [bwFormControl]="formGroup.controls.deliveryAddress" label="Full Delivery Address" autocomplete="deliveryAddress" inputType="text" [multiline]="true" [expandOnTypeToMaxCSSHeight]="true" (didFocus)="onFocus('deliveryAddress')">
            <ng-container role="errors">
              <div for="required">This field is required</div>
            </ng-container>
          </bw-form-text-input>
          <bw-form-dropdown *ngIf="issues?.length &amp;&amp; formtype == 'quality'" [bwFormControl]="formGroup.controls.issue" label="What’s the problem?" inputType="display" [data]="issuesDDL" placeholder="Choose among the following..." [displayFn]="displayIssues">
            <ng-container role="errors">
              <div for="required">This field is required</div>
            </ng-container>
          </bw-form-dropdown>
          <!-- Select your stem - Quality-->
          <bw-form-dropdown *ngIf="stems?.length &amp;&amp; formtype == 'quality'" [bwFormControl]="formGroup.controls.stem" label="What's been most affected? Your care card can help!" inputType="display" [data]="stemsDDL" placeholder="Choose among the following..." [displayFn]="displayStems">
            <ng-container role="errors">
              <div for="required">This field is required</div>
            </ng-container>
          </bw-form-dropdown>
          <bw-form-text-input *ngIf="formtype == 'quality'" [multiline]="true" [expandOnTypeToMaxCSSHeight]="true" [maxLength]="characterLimit" [bwFormControl]="formGroup.controls.description" subtext="({{formGroup.controls.description.value.length}}/{{characterLimit}})" label="Describe your issue" placeholder="Give details on the issue and stems affected" autocomplete="description" inputType="text" (didFocus)="onFocus('quality')">
            <ng-container role="errors">
              <div for="required">This field is required</div>
            </ng-container>
          </bw-form-text-input>
          <bw-form-text-input *ngIf="formtype == 'delivery'" [multiline]="true" [expandOnTypeToMaxCSSHeight]="true" [maxLength]="characterLimit" [bwFormControl]="formGroup.controls.question" subtext="({{formGroup.controls.question.value.length}}/{{characterLimit}})" placeholder="eg My order hasn't arrived (it's been 2 working days since delivery date)" label="Your Question" autocomplete="question" inputType="text" (didFocus)="onFocus('delivery')">
            <ng-container role="errors">
              <div for="required">Your question is required</div>
            </ng-container>
          </bw-form-text-input>
          <bw-form-text-input *ngIf="formtype == 'general'" [multiline]="true" [expandOnTypeToMaxCSSHeight]="true" [bwFormControl]="formGroup.controls.question" [maxLength]="characterLimit" subtext="({{formGroup.controls.question.value.length}}/{{characterLimit}})" label="Your Question" autocomplete="question" inputType="text" (didFocus)="onFocus('your question')">
            <ng-container role="errors">
              <div for="required">A question is required</div>
            </ng-container>
          </bw-form-text-input>
          <bw-form-dropdown *ngIf="resolutions?.length &amp;&amp; (formtype == 'quality' || formtype == 'delivery') " [bwFormControl]="formGroup.controls.resolution" label="How can we help you resolve this?" inputType="display" [data]="resolutionsDDL" [displayFn]="displayResolution" placeholder="Choose among the following...">
            <ng-container role="errors">
              <div for="required">This field is required</div>
            </ng-container>
          </bw-form-dropdown>
          <!-- For general or quality-->
          <bw-form-file-upload-input *ngIf="formtype == 'quality' || formtype == 'general'" [bwFormControl]="formGroup.controls.fileNames" label="Upload photos (OPTIONAL)" [s3BucketLocation]="s3Location" [limit]="5" [uploadInstantly]="true" acceptedFileTypes="video/*,image/*,.pdf" (didClick)="onFileInputTouched()" (filesChanged)="onFilesChanged($event)"></bw-form-file-upload-input>
        </div>
      </fieldset>
      <div class="form-text" *ngIf="formtype === 'quality' || orderPrefilled"><strong *ngIf="formtype === 'quality'">A member of our customer delight team will take a look at your request and we will be in touch shortly with an update.</strong>
        <div *ngIf="orderPrefilled"> <p>We’ve filled out your last order details below for you.</p> <p>If you want to talk about another order, feel free to change these details.</p></div>
      </div>
      <div class="form-action">
        <bw-button buttonStyle="modal-primary" size="medium" [state]="'default'" (clicked)="submitForm()" bwTrackAs="page.contact_us.form.submit">Send question</bw-button>
      </div>
    </form>
  </div>
</div>
<bw-loading-spinner *ngIf="loading"></bw-loading-spinner>