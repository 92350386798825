import { Delivery } from 'Shared/classes/delivery';
import { Discount } from 'Shared/classes/discount';
import { GiftVoucher, GiftVoucherRedemption } from 'Shared/classes/gift-voucher';
import { CurrencyCode } from 'Shared/classes/price';
import * as dayjs from 'dayjs';

type SubscriptionPaymentOptionType = SubscriptionDiscount | SubscriptionGiftVoucher | SubscriptionPrepay;

export enum PaymentOptionType {
  prepayment = 'prepayment',
  discount = 'discount',
  voucher = 'voucher'
}

/**
 * @description Subscription payment option class - possible options are discounts and gift vouchers
 */
export class SubscriptionPaymentOption {
  subscriptionId: number | undefined;
  nextDelivery: Delivery | undefined;
  options: SubscriptionPaymentOptionType[] | undefined;

  constructor(subscriptionId?: number) {
    this.subscriptionId = subscriptionId;
  }

  /**
   * @description Check if the payment option has options
   * @returns {boolean}
   */
  hasOptions(): boolean {
    return this.options !== undefined && this.options?.length > 0;
  }

  /**
   * Get Payment Options filtered by type
   * @param type: PaymentOptionType
   * @returns SubscriptionPaymentOptionType[]
   */
  getOptionsByType(type: PaymentOptionType): SubscriptionPaymentOptionType[] {
    return this.options?.filter((o): boolean => o?.type === type);
  }
}

/**
 * Prepay Payment option
 * TODO: Update once the BE finish implementation
 */
export class SubscriptionPrepay {
  type: PaymentOptionType.prepayment;
  id: number;
  expiresOn: dayjs.Dayjs;
}

export class SubscriptionDiscount extends Discount {
  type: PaymentOptionType.discount;

  constructor(code: string, percentage?: string, amount?: number, deliveryRange?: number[], description?: string) {
    super(code, percentage, amount, deliveryRange, description);
  }
}

/**
 * @description Subscription gift voucher class - extension of GiftVoucher class
 */
export class SubscriptionGiftVoucher extends GiftVoucher {
  type: PaymentOptionType.voucher;

  constructor(
    code: string,
    redemptions?: GiftVoucherRedemption[],
    id?: number,
    type?: string,
    initialValue?: number,
    balance?: number,
    currency?: CurrencyCode,
    expiryDate?: dayjs.Dayjs,
    isLoyalty?: boolean
  ) {
    super(code, redemptions, id, type, initialValue, balance, currency, expiryDate, isLoyalty);
  }

  /**
   * @description Get the last 4 digits of the gift voucher code
   * @returns {string}
   */
  get last4(): string {
    return this.code.slice(-4);
  }
}
