import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Country } from 'Project/shared/classes/country';
import { IntegrationType, MentionMeParameters } from 'Project/shared/classes/mention-me';
import { ModalService } from 'Project/shared/services/modal.service';
import { StateParams, StateService } from 'Project/shared/services/state.service';
import { UserService } from 'Project/shared/services/user.service';
import { MentionMeService } from 'Project/shared/services/third-parties/mention-me.service';
import { NextDeliverySubscriptionMessages, NextDeliverySubscriptionMessagesbBloomon } from './mention-me-banner.component.model';
import { ConfigService } from 'Project/shared/services/config.service';
import { OasysButtonModule, OasysLayoutModule } from 'oasys-lib';
import { NgIf } from '@angular/common';

@Component({
  standalone: true,
  imports: [OasysLayoutModule, NgIf, OasysButtonModule],
  selector: 'bw-mention-me-banner',
  templateUrl: './mention-me-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MentionMeBannerComponent implements OnInit {
  @Input() segment: string;
  @Input() situation: string;
  @Input() type: IntegrationType = 'referreroffer';
  @Input({ required: false }) buttonText: string;
  @Input({ required: false }) modalToOpen: string = 'MentionMeModalComponent';
  @Input({ required: false }) message: string;
  @Input({ required: false }) campaign?: 'NoCampaign' | 'NextDeliverySubscription' = 'NoCampaign';
  @Input({ required: false }) shippingCountry?: Country = undefined;

  mmParameters: MentionMeParameters;
  campaignMessage: string;

  constructor(
    private configService: ConfigService,
    private stateService: StateService,
    private userService: UserService,
    private modalService: ModalService,
    private mentionMeService: MentionMeService
  ) {}

  /**
   * On init
   */
  ngOnInit(): void {
    this.initNextDeliverySubscriptionCampaign();
  }

  /**
   * Open mention me iframe via content spot modal
   */
  openMentionMeModal(): Promise<void> {
    this.mmParameters = this.setMentionMeParameters();
    return this.modalService
      .showLazyModal(
        { name: this.forceCast(this.modalToOpen) },
        {
          initialState: {
            situation: this.situation,
            integrationType: this.type,
            parameters: this.mmParameters,
            segment: this.segment ?? undefined,
            shippingCountryId: this.shippingCountry ? this.shippingCountry.id : 0
          },
          class: 'modal-lg bw-modal--mention-me-payment',
          keyboard: true
        }
      )
      .catch((): void => {});
  }

  /**
   * Set mention me passed parameters to populate inital field
   * Use state params if present otherwise default with user
   */
  private setMentionMeParameters(): MentionMeParameters {
    const { fullname, email } = this.stateService.getCurrent().params as StateParams;

    if (fullname || email) {
      return {
        fullname,
        email
      };
    }

    const user = this.userService.getUser();
    return {
      fullname: user.fullName,
      email: user.email.address
    };
  }

  /**
   * Init Next Delivery Subscription Campaign
   */
  private initNextDeliverySubscriptionCampaign(): void {
    if (this.campaign === 'NextDeliverySubscription' && this.shippingCountry) {
      const shippingLocale = this.mentionMeService.getCampaignLocaleByShippingCountry(this.shippingCountry.id);
      const brandId = this.configService.getConfig().brandIdNumber;
      this.campaignMessage =
        brandId === 1 ? NextDeliverySubscriptionMessages[shippingLocale] : NextDeliverySubscriptionMessagesbBloomon[shippingLocale];
    }
  }

  /**
   * Cast string to Modal name
   * @param name
   * @returns
   */
  private forceCast<ILazyModalsList>(name: string): ILazyModalsList {
    return name as ILazyModalsList;
  }
}
