
<div class="bw-form-file-upload-input" *ngIf="bwFormControl" [ngClass]="{
    'is-invalid': bwFormControl.invalid,
    'is-valid': bwFormControl.valid,
    'is-dirty': bwFormControl.dirty,
    'is-pristine': bwFormControl.pristine,
    'is-touched': bwFormControl.touched,
    'is-untouched': !bwFormControl.touched,
    'is-submitted': hasSubmitted
    }">
  <div class="file-input__container">
    <div class="file-input__control">
      <label>{{label}}</label>
      <ng-container *ngFor="let file of files; let idx = index">
        <input type="file" name="file" [attr.accept]="acceptedFileTypes" [attr.ariaLabel]="label" (click)="onInputClicked()" (change)="fileSelected($event, file)">
        <div class="file-input__item" *ngIf="file.name?.length">
          <div class="file-input__name">
            <div class="file-input__progress" [ngClass]="{'has-error': file.uploadError}" [ngStyle]="{'width':(file.uploadProgress * 100) + '%'}"></div>
            <div class="file-input__label">
              <bw-button buttonStyle="text-link" (click)="retryUpload(file)" *ngIf="file.uploadError" bwTrackAs="components.form_file_upload.retry">Retry</bw-button><span *ngIf="file.uploadError">{{file.uploadError.message}} -</span><span>{{file.name}}</span>
            </div>
            <bw-icon class="file-input__success-tick" *ngIf="file.uploadProgress &gt; 0.9" iconType="tick--green"></bw-icon>
          </div>
          <div class="file-input__action">
            <bw-button buttonStyle="text-link" (click)="removeFile(idx)" bwTrackAs="components.form_file_upload.remove">Remove</bw-button>
          </div>
        </div>
      </ng-container>
      <div class="file-input__trigger">
        <div>+ Select file to upload</div>
        <bw-icon class="file-input__trigger-icon" iconType="upload"></bw-icon>
      </div>
    </div>
  </div>
  <bw-alert *featureEnabled="'UPLOAD_FILE_INFO'" alertStyle="warning"><span *ngIf="infoMessage; else elseBlock">{{infoMessage}}</span>
    <ng-template #elseBlock><span>Please wait for all photos must finish uploading before you continue.  Larger images (over 10Mb) should instead be attached to the email you’ll receive from us.</span></ng-template>
  </bw-alert>
  <!-- Error messages-->
  <bw-formcontrol-validation-messages *ngIf="!hideValidationErrors" [errors]="bwFormControl.errors" [touched]="bwFormControl.touched" [formSubmitted]="hasSubmitted">
    <ng-content select="[role=errors]"></ng-content>
  </bw-formcontrol-validation-messages>
</div>