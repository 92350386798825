import { Injectable } from '@angular/core';
import { CountryModelService } from 'Shared/models/country-model.service';
import { Country } from 'Shared/classes/country';
import { ConfigService } from 'Shared/services/config.service';
import { BehaviorSubject } from 'rxjs';
import { WindowRefService } from 'Shared/services/window.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { siteConfig } from 'Environments/site-config';
export { Country } from 'Shared/classes/country';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private countries: Country[];
  forSite: Country;
  forShipping: Country;
  forShipping$: BehaviorSubject<Country> = new BehaviorSubject(new Country());
  siteConfig = siteConfig;
  window;

  /**
   * Constructor
   * @param countryModel
   * @param configService
   */
  constructor(private countryModel: CountryModelService, private configService: ConfigService, private windowService: WindowRefService) {
    this.window = this.windowService.nativeWindow;

    this.init(); // To ensure it's super fast, but the function can be unit tested
  }

  /**
   * init the service
   */
  init(): void {
    const id = this.configService.getConfig().defaultShippingCountryId;

    const allCountries = this.countryModel.getAll();

    // By default, we want to exclude any countries that are not enabled
    this.countries = allCountries.filter((c) => c['availableForUser']);

    // If the site has a default id, we need to ensure it's in the list
    const defaultCountry = allCountries.find((c) => c.id === id);

    if (defaultCountry && !defaultCountry['availableForUser']) {
      this.countries.push(defaultCountry);
    }

    this.forSite = defaultCountry;
    this.forShipping = defaultCountry;
    this.forShipping$.next(this.forShipping);
  }

  /**
   * Get the config for this site, taking into account shipping country
   * TODO: This will be improved
   */
  siteConfigValue<T = any>(key: string): T {
    return this.siteConfig[`${this.forShipping.id}`][key];
  }

  /**
   * Set the country we want to ship to
   * @param country
   */
  setCountryForShipping(country: Country): void {
    if (country && country.codes.length) {
      const currentShippingCode = (this.forShipping.codes[0] || '').toUpperCase();
      this.forShipping = this.getCountryByCode(country.codes[0]);
      this.window.document.body.setAttribute('bw-shipping-country', country.id);

      this.forShipping$.next(this.forShipping);
    }
  }

  /**
   * Get the countries
   */
  getCountries(): Country[] {
    this.countries = this.countries || this.countryModel.getAll();
    return this.countries;
  }

  /**
   * Get a country given a key and value
   * @param key
   * @param value
   */
  getCountryBy(key: string, value: any): Country {
    return this.getCountries().find((c) => c[key] === value);
  }

  /**
   * Get the country by the code
   * @param code
   */
  getCountryByCode(code: string): Country {
    return this.getCountries().find((c) => c.codes.indexOf((code || '').toLowerCase()) > -1);
  }

  /**
   * Returns if countryId param is valid
   * @param params: any
   * @param selectedLocale: string
   */
  supportsShippingToCountryId(countryId: string, selectedLocale?: string): boolean {
    const currentSite = this.configService.getConfig().domain;
    const countryIdToSite = this.configService.getConfig().countryIdToSite;
    const locale = selectedLocale || this.configService.getConfig().locale;

    return !!countryIdToSite.find((c) => c.site === currentSite && c.locale === locale && c.countryIds.indexOf(+countryId) > -1);
  }
}
