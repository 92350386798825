
<form class="user-details-guest" *ngIf="!loading" [ngClass]="{'ng-submitted': hasSubmitted}" [formGroup]="formGroup" sectionName="userDetailsSection">
  <div class="user-details-guest__content" *ngIf="toRegister">
    <ng-container *ngIf="isJoiningRewards">
      <ng-template #rewardsPerks let-alignment="alignment" let-stackDirection="stackDirection">
        <ui-box box_space="near" box_space_left="none" box_space_right="none">
          <ui-stack stack_gap="near" [stack_direction]="stackDirection" [stack_distribute]="alignment" stack_collapse_below="tablet" [stack_wrap]="true">
            <ui-box *ngFor="let item of [].constructor(4); let i = index" box_space="none" box_fill_mode="fit">
              <ui-stack stack_gap="tight" stack_direction="x" stack_align="center">
                <ui-box box_background="rewards-tertiary" box_space="tiny" box_fill_mode="fit" box_border_radius="rewards-icon">
                  <ui-icon icon_name="tick"></ui-icon>
                </ui-box>
                <p class="text-body--supporting" *ngIf="i === 0">£5 off every 500 points</p>
                <p class="text-body--supporting" *ngIf="i === 1">Birthday treat</p>
                <p class="text-body--supporting" *ngIf="i === 2">Save your addresses</p>
                <p class="text-body--supporting" *ngIf="i === 3">Easy order tracking</p>
              </ui-stack>
            </ui-box>
          </ui-stack>
        </ui-box>
      </ng-template>
      <ui-box *ngIf="!checkoutOrigin" box_space="none" box_space_bottom="near">
        <p class="text-body--default">Please verify your email to join Bloom & Wild Rewards.</p>
        <ui-stack stack_gap="near">
          <ng-template [ngTemplateOutlet]="rewardsPerks" [ngTemplateOutletContext]="{alignment: 'start', stackDirection: 'y'}"></ng-template>
          <p class="text-body--supporting text-color--supporting">You can <a class="text-link--paragraph" href="/bw-rewards-terms" target="_blank">read our full T&Cs here</a>.</p>
        </ui-stack>
      </ui-box>
      <ui-box class="hidden-mobile" *ngIf="checkoutOrigin" box_space="near" box_space_top="near" box_space_bottom="near">
        <ng-container *ngIf="loyaltyOrderPoints !== 0">
          <p class="text-body--default text-center">Please verify your email to create an account, and earn <span class="text-body--emphasis">{{loyaltyOrderPoints}}</span> points with Bloom & Wild Rewards.</p>
        </ng-container>
        <ng-container *ngIf="loyaltyOrderPoints === 0">
          <p class="text-body--default text-center">Please verify your email to create an account, and earn points with Bloom & Wild Rewards.</p>
        </ng-container>
        <ng-template [ngTemplateOutlet]="rewardsPerks" [ngTemplateOutletContext]="{alignment: 'center', stackDirection: 'x'}"></ng-template>
      </ui-box>
      <ui-box class="hidden-tablet hidden-laptop hidden-desktop" *ngIf="checkoutOrigin" box_space="near" box_space_left="none" box_space_right="none">
        <ng-container *ngIf="loyaltyOrderPoints !== 0">
          <p class="text-body--default text-center">Please verify your email to create an account, and earn <span class="text-body--emphasis">{{loyaltyOrderPoints}}</span> points with Bloom & Wild Rewards.</p>
        </ng-container>
        <ng-container *ngIf="loyaltyOrderPoints === 0">
          <p class="text-body--default text-center">Please verify your email to create an account, and earn points with Bloom & Wild Rewards.</p>
        </ng-container>
        <ng-template [ngTemplateOutlet]="rewardsPerks" [ngTemplateOutletContext]="{alignment: 'start', stackDirection: 'y'}"></ng-template>
      </ui-box>
      <ui-box *ngIf="checkoutOrigin" box_space="none" box_space_bottom="default" box_align_x="center">
        <ui-stack stack_gap="tight" stack_direction="x" stack_distribute="center" stack_align="center">
          <ui-box box_border_radius="round" box_border_width="primary" box_space="none" box_fill_mode="fit">
            <ui-icon icon_name="info" icon_size="small"></ui-icon>
          </ui-box><a class="text-link--paragraph text-body--supporting" (click)="openRewardsInfoModal()" bwTrackAs="component.detail-user.login-info.rewards-link">What’s Bloom & Wild Rewards?</a>
        </ui-stack>
      </ui-box>
    </ng-container>
    <ng-container *ngIf="!isEmailSent &amp;&amp; !isJoiningRewards">
      <p> You have previously shopped with us as a guest. Verify your email to create an account and unlock these perks:</p>
      <div class="user-details-guest__intro-items">
        <ng-container *ngFor="let item of accountOfferings">
          <div class="user-details-guest__intro-item" *ngIf="item.show">
            <bw-icon *ngIf="item.iconName" [iconType]="$any(item.iconName)"></bw-icon>
            <p *ngIf="item.name">{{item.name}}</p>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ui-box *ngIf="!isEmailSent" box_space="none" box_space_bottom="near">
      <ui-stack stack_gap="none" stack_direction="x">
        <bw-icon class="user-details__icon" iconType="user"></bw-icon>
        <div class="user-details__welcome">Welcome back {{identifiedUser}}</div>
      </ui-stack>
    </ui-box>
    <bw-form-text-input #emailField [bwFormControl]="formGroup.controls.email" label="Email address" autocomplete="email" inputType="display"></bw-form-text-input>
    <ng-container *ngIf="!isJoiningRewards">
      <bw-alert *ngIf="!isEmailSent" header="We need to verify your email" alertStyle="success">You will receive an email to create a password</bw-alert>
      <ui-box *ngIf="isEmailSent" box_space="none" box_space_bottom="near">
        <bw-alert header="Check your inbox" alertStyle="success">We have sent over a link to create a password.</bw-alert>
      </ui-box>
    </ng-container>
    <ng-container *ngIf="isJoiningRewards">
      <ui-alert *ngIf="!isEmailSent" direction="dynamic" tone="positive">
        <ui-alert-title>We need to verify your email</ui-alert-title>
        <ui-alert-content>You will receive an email to create a password</ui-alert-content>
      </ui-alert>
      <ui-box *ngIf="isEmailSent" box_space="none" box_space_bottom="default">
        <ui-alert direction="dynamic" tone="positive">
          <ui-alert-title>Check your inbox</ui-alert-title>
          <ui-alert-content>We have sent over a link to create a password</ui-alert-content>
        </ui-alert>
      </ui-box>
    </ng-container>
    <ng-container *ngIf="!isEmailSent">
      <!-- Newsleter consent input-->
      <ui-box *ngIf="!showSofterGDPRMessage" box_space="none" [box_space_top]="isJoiningRewards ? 'none' : 'default'">
        <bw-details-user-consent [bwFormControl]="formGroup.controls.consent" [email]="user?.email" [showOptionsOnly]="false" [AutoOptInToRewards]="isJoiningRewards"></bw-details-user-consent>
      </ui-box>
      <!-- 'Softer' GDPR message-->
      <!-- Shows details about opt-in/opt-out but doesn't have any checkbox-->
      <div class="user-details-guest__consent" *ngIf="showSofterGDPRMessage">
        <p>Nach dem Kauf senden wir Ihnen regelmäßig ausgesuchte Angebote zu ähnlichen Produkten aus unserer Kollektion. Sie können dem Erhalt von Werbe-Emails jederzeit widersprechen.</p>
      </div>
    </ng-container>
    <ui-box *ngIf="isJoiningRewards &amp;&amp; checkoutOrigin &amp;&amp; showTandC" box_space="none" box_space_top="near" box_space_bottom="near">
      <p class="text-body--supporting text-color--supporting">You can <a class="text-link--paragraph" href="/bw-rewards-terms" target="_blank">read our full T&Cs here</a>.</p>
    </ui-box>
    <ui-box class="user-details-guest__submit" box_space="none">
      <ui-stack stack_gap="default" stack_distribute="fill">
        <ui-button *ngIf="!isEmailSent" button_type="primary" button_full_width="true" bwTrackAs="checkout.userDetails.verifyPassword" (click)="activateAccount()"> Verify my email</ui-button>
        <ui-button *ngIf="checkoutOrigin &amp;&amp; order?.type !== 'subscription'" button_type="secondary" button_full_width="true" bwTrackAs="checkout.userDetails.continueToOrderDetails" (click)="onSubmit()">Continue as guest</ui-button>
      </ui-stack>
    </ui-box>
    <div class="user-details-guest__go-back-login" *ngIf="checkoutOrigin"><span>Wrong email? &nbsp;</span>
      <bw-button buttonStyle="text-link" (clicked)="login()" bwTrackAs="component.aith-register.footer-btn-go-back"> Go back</bw-button>
    </div>
  </div>
  <div class="user-details-guest__content" *ngIf="!toRegister">
    <ui-box box_space="none" box_space_top="near" [box_space_bottom]="showJoiningRewardsForGuest ? 'default' : 'none'">
      <ui-stack *ngIf="showJoiningRewardsForGuest">
        <p class="text-body--default text-center">Are you sure? You'll miss out on points and perks. <a class="text-link--paragraph" (click)="login()">Log in or create an account</a> to join Bloom & Wild Rewards. Or continue as a guest below.</p>
        <ui-stack stack_gap="tight" stack_direction="x" stack_distribute="center" stack_align="center">
          <ui-box box_border_radius="round" box_border_width="primary" box_space="none" box_fill_mode="fit">
            <ui-icon icon_name="info" icon_size="small"></ui-icon>
          </ui-box><a class="text-link--paragraph text-body--supporting" (click)="openRewardsInfoModal()" bwTrackAs="component.detail-user.login-info.rewards-link">What’s Bloom & Wild Rewards?</a>
        </ui-stack>
      </ui-stack>
    </ui-box>
    <fieldset class="user-details-guest__filedset">
      <div class="user-details-guest__form-group">
        <bw-form-text-input [bwFormControl]="formGroup.controls.fullName" label="Your name" autocomplete="fullName" inputType="text" (didBlur)="onNameBlur()">
          <ng-container role="errors">
            <div for="fullNameRequired">First name and last name are required</div>
            <div for="invalid">Name is required</div>
          </ng-container>
        </bw-form-text-input>
        <bw-form-text-input [bwFormControl]="formGroup.controls.email" label="Email address" autocomplete="email" inputType="email" (didBlur)="onNameBlur()">
          <ng-container role="errors">
            <div for="required">Invalid email</div>
            <div for="invalid">Please enter a valid email address (e.g johndoe&#64;domain.com)</div>
          </ng-container>
        </bw-form-text-input>
      </div>
      <bw-details-user-consent [bwFormControl]="formGroup.controls.consent" [email]="user?.email" [showOptionsOnly]="false"></bw-details-user-consent>
      <!-- Action button-->
      <div class="user-details-guest__submit">
        <bw-button buttonStyle="primary" bwTrackAs="checkout.userDetails.continueToOrderDetails" (click)="onSubmit(true)" [fullWidth]="true">Continue as guest</bw-button>
        <ui-box box_space="near">
          <ui-stack stack_align="center">
            <bw-button buttonStyle="text-link" bwTrackAs="checkout.userDetails.continueToLogin" (click)="login()" [fullWidth]="true">Log in to your account</bw-button>
          </ui-stack>
        </ui-box>
      </div>
    </fieldset>
  </div>
</form>
<bw-loading-spinner *ngIf="loading"></bw-loading-spinner>