import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReviewsService } from 'Shared/services/reviews.service';
import { ConfigService } from 'Shared/services/config.service';
import { WindowRefService } from 'Shared/services/window.service';
import { LocationService } from 'Shared/services/location.service';

@Component({
  selector: 'bw-meta-base',
  templateUrl: './meta-base.component.html',
  styleUrls: ['./meta-base.component.scss']
})
export class MetaBaseComponent implements OnInit, OnDestroy {
  scriptContent: any;
  id: string;

  constructor(
    protected reviewService: ReviewsService,
    protected configService: ConfigService,
    private windowRef: WindowRefService,
    protected locationService: LocationService
  ) {
    this.id = `m-${Date.now()}${Math.floor(Math.random() * 1000)}`;
  }

  // Being overwritten in child components
  generateScript(): any {
    return '';
  }

  loadScript(content: any, id: string): any {
    const script = this.windowRef.nativeWindow.document.createElement('script');
    script.id = id;
    script.type = 'application/ld+json';
    script.text = content;
    this.windowRef.nativeWindow.document.getElementsByTagName('head')[0].appendChild(script);
  }

  ngOnInit(): void {
    const content = this.generateScript();
    this.loadScript(JSON.stringify(content), this.id);
  }

  ngOnDestroy(): void {
    const elem = this.windowRef.nativeWindow.document.querySelector(`#${this.id}`);
    if (elem) {
      elem.parentNode.removeChild(elem);
    }
  }
}
