import { Price } from 'Shared/classes/price';

export class DiscountInfo {
  id: string;
  name: string;
  slug: string;
  imageUrls: string[];
  price: Price;
  type: DiscountType;
}

export enum DiscountType {
  Addon = 'addon-campaign'
}
