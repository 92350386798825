import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { MegaNavItem } from 'Shared/classes/mega-nav-item';

@Directive({
  selector: '[bwResponsiveMegaNavItem]'
})
export class ResponsiveMegaNavItemDirective implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('bwResponsiveMegaNavItem') bwResponsiveMegaNavItem: MegaNavItem;
  element: ElementRef;
  constructor(el: ElementRef) {
    this.element = el;
  }

  ngOnInit(): void {
    if (this.element && this.element.nativeElement) {
      this.element.nativeElement.classList.add(
        this.bwResponsiveMegaNavItem.displayOn.indexOf('web_xl') > -1 ? null : 'hidden-lg'
      );
      this.element.nativeElement.classList.add(
        this.bwResponsiveMegaNavItem.displayOn.indexOf('web_desktop') > -1 ? null : 'hidden-lg'
      );
      this.element.nativeElement.classList.add(
        this.bwResponsiveMegaNavItem.displayOn.indexOf('web_tablet') > -1 ? null : 'hidden-md'
      );
      this.element.nativeElement.classList.add(
        this.bwResponsiveMegaNavItem.displayOn.indexOf('web_tablet') > -1 ? null : 'hidden-sm'
      );
      this.element.nativeElement.classList.add(
        this.bwResponsiveMegaNavItem.displayOn.indexOf('web_mobile') > -1 ? null : 'hidden-xs'
      );
    }
  }
}
