import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { User } from 'Shared/classes/user';
import { ConfigService } from 'Shared/services/config.service';
import { Product } from 'Shared/classes/product';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { Subject } from 'rxjs';
import { InAppMessage } from 'Shared/classes/in-app-message';
import { StateService } from 'Shared/services/state.service';
import { ICookieConsent } from 'Shared/services/third-parties/cookie.service';

/**
 * AppboyService() helps with appboy events
 *
 *  Documentation here: https://www.appboy.com/documentation/Web/
 *  https://github.com/Appboy/appboy-web-sdk#getting-started
 *  https://js.appboycdn.com/web-sdk/latest/doc/module-appboy.html
 */

const APPBOY_CUSTOM_EVENTS = {
  purchaseAdd: 'web:ecomm:purchase:add',
  skuView: 'web:ecomm:sku:view',
  skuSelect: 'web:ecomm:sku:select',
  recipientName: 'web:ecomm:recipient:name',
  checkoutGiftCardCover: 'web:checkout:giftcardcover',
  clickToReveal: 'web:click-to-reveal',
  deliveryTracking: 'web:page:deliverytracking',
  subsModal: 'web:page:subsmodalongoing'
} as const;

const trackSubs = 'web:page:subsLandingPage';

// TODO: update to use improved site config
const APPBOY_TRACKED_PAGEVIEWS = {
  '/flower-subscriptions-for-me': trackSubs,
  '/blumen-abo': trackSubs,
  '/abonnements-bergamotte': trackSubs,
  '/flower-subscription/order': trackSubs,
  '/blomsterabonnement/order': trackSubs,
  '/blumenabo/order': trackSubs,
  '/bloemenabonnement/order': trackSubs
} as const;

type AppBoyEvent = typeof APPBOY_CUSTOM_EVENTS & typeof APPBOY_TRACKED_PAGEVIEWS;

type ValueOf<T> = T[keyof T];

@Injectable({
  providedIn: 'root'
})
export class AppboyService {
  clickToRevealMessages$: Subject<InAppMessage> = new Subject<InAppMessage>();
  enabled: boolean;
  initPromise: Promise<void>;
  serviceInitialized: boolean = false;

  private window: Window;
  private debug: boolean = false;

  constructor(
    private windowRef: WindowRefService,
    private configService: ConfigService,
    private domUtils: DomUtilsService,
    private stateService: StateService
  ) {
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
    this.enabled = this.configService.getConfig().braze?.enabled ?? false;
  }

  /**
   * Init Script
   */
  init(cookieConsent: ICookieConsent): Promise<void> {
    this.initPromise =
      this.initPromise ||
      this.domUtils.loadScript('https://js.appboycdn.com/web-sdk/3.5/appboy.min.js', 'appboy').then((): void => {
        this.serviceInitialized = true;

        const { apiKey, sdkEndpoint } = this.configService.getConfig().braze;
        this.appboy('initialize', apiKey, {
          baseUrl: sdkEndpoint,
          doNotLoadFontAwesome: true
        });
        this.appboy('openSession');

        if (this.debug) {
          this.appboy('toggleLogging');
        }

        // Set custom consent attributes on load
        this.toggleGoogleConsent(cookieConsent);

        this.subscribeToMessages();
      });
    return this.initPromise;
  }

  /**
   * Disable appboy from within the app
   */
  disable(): void {
    this.enabled = false;
  }

  /**
   * Log an event
   * @param name
   * @param properties
   */
  logEvent(name: ValueOf<AppBoyEvent>, properties?: unknown): void {
    this.appboy('logCustomEvent', name, properties);
  }

  /**
   * Track page view
   * @param url
   */
  trackPageView(url: string): void {
    setTimeout((): void => {
      const u = url.split('?')[0];
      const key = Object.keys(APPBOY_TRACKED_PAGEVIEWS).find((k): boolean => u.indexOf(k) > -1);

      if (key && APPBOY_TRACKED_PAGEVIEWS[key]) {
        this.logEvent(APPBOY_TRACKED_PAGEVIEWS[key]);
      }
    }, 1000);
  }

  /**
   * Emit custom event and custom prefixed attributes to braze
   * eventName should follow similar stucture to example: web:ecomm:sku:select
   * Last word will become prefix for properties emitted to braze
   * @param eventName
   * @param userAttrPrefix
   * @param product
   * @param recipientName
   */
  trackCheckoutProgress(eventName: ValueOf<AppBoyEvent>, userAttrPrefix: string, product: Product, recipientName?: string): void {
    const eventArray = eventName.split(':');
    const prefix = eventArray[eventArray.length - 1];
    const serverTime = this.configService.getConfig().serverTime;

    try {
      const eventData = {};
      eventData[`sku_${prefix}_id`] = product.id;
      eventData[`sku_${prefix}_name`] = product.name;
      eventData[`sku_${prefix}_image_url`] = product.imageUrls[0];
      eventData[`sku_${prefix}_slug`] = product.slug;
      eventData[`sku_${prefix}_recipient`] = recipientName;
      eventData[`sku_${prefix}_added_date`] = serverTime.format('YYYY-MM-DD');

      this.logEvent(eventName, eventData);

      const userAttributes = {};
      userAttributes[`${userAttrPrefix}_at`] = serverTime.format('YYYY-MM-DD HH:mm:ss');
      userAttributes[`${userAttrPrefix}_name`] = product.name;
      userAttributes[`${userAttrPrefix}_image_url`] = product.imageUrls[0];
      userAttributes[`${userAttrPrefix}_slug`] = product.slug;
      userAttributes[`${userAttrPrefix}_id`] = product.id;

      this.setUserAttributes(userAttributes);
    } catch (e) {}
  }

  /**
   * Identify the user
   * @param user
   */
  identify(user: User): void {
    const userSlug = this.stateService.getInitial().params?.['slug'];
    const preferredUser = userSlug ? new User(null, null, userSlug) : user;

    if (preferredUser.slug && preferredUser.slug.length) {
      this.appboy('changeUser', preferredUser.slug);
    }
  }

  /**
   * Update custom user attributes
   * @param {ICookieConsent} cookieconsent
   * @returns {void}
   */
  toggleGoogleConsent({ marketing }: ICookieConsent): void {
    const consent = {
      $google_ad_user_data: marketing,
      $google_ad_personalization: marketing
    };
    this.setUserAttributes(consent);
  }

  /**
   * The main appboy library call
   * @param fnName
   * @param args
   */
  private appboy(fnName?: string, ...args: unknown[]): unknown {
    if (!this.enabled || !this.serviceInitialized || !this.window['appboy']) {
      return undefined;
    }
    if (!fnName) {
      return this.window['appboy']; // just an object
    }

    // Attempt to execute function
    if (fnName && this.window['appboy'][fnName]) {
      try {
        this.log(fnName, ...args);
        return this.window['appboy'][fnName](...args);
      } catch (e) {}
    }

    return undefined;
  }

  /**
   * Log
   */
  private log(...args: unknown[]): void {
    if (this.debug) {
      console.log('<appboy>', ...args);
    }
  }

  /**
   * Set user attributes
   * @param {Record<string, unknown>} properties
   */
  private setUserAttributes(properties: Record<string, unknown>): void {
    Object.keys(properties).forEach((propKey): void => {
      const user = this.appboy('getUser');
      if (user) {
        user['setCustomUserAttribute'](propKey, properties[propKey]);
      }
    });
  }

  /**
   * Handle click to reveal messages
   * @param message
   */
  private handleClickToRevealMessage(message: any): void {
    const iam = new InAppMessage(message);
    this.clickToRevealMessages$.next(iam);
    this.appboy('logInAppMessageImpression', message);
  }

  /**
   * Handle the push primer
   * @param message
   */
  private handlePushPrimerMessage(message: any): void {
    if (message.buttons[0] != null) {
      // Prompt the user when the first button is clicked
      message.buttons[0].subscribeToClickedEvent(() => {
        this.appboy('registerAppboyPushMessages');
      });
    }

    // Display the message
    const appboy = this.appboy();
    if (appboy && appboy['display']) {
      appboy['display'].showInAppMessage(message);
    }
  }

  /**
   * Soft promp for push notification
   * See https://www.braze.com/documentation/Web/#push-notifications
   * This will wait for 'appboy.logCustomEvent("prime-for-push");' before loading
   */
  private subscribeToMessages(): void {
    this.appboy('subscribeToNewInAppMessages', (inAppMessages) => {
      const message = inAppMessages[0];
      if (message != null) {
        this.log('Did Received IAM');
        if (message instanceof this.windowRef.nativeWindow['appboy'].ab.InAppMessage) {
          // Read the key-value pair for msg-id
          const msgId = message.extras['msg-id'];

          // If this is our push primer message
          if (msgId === 'push-primer') {
            // We don't want to display the soft push prompt to users on browsers that don't support push, or if the user
            // has already granted/blocked permission
            if (!this.appboy('isPushSupported') || this.appboy('isPushPermissionGranted') || this.appboy('isPushBlocked')) {
              return;
            }
            this.handlePushPrimerMessage(message);
            return inAppMessages.slice(1);
          }

          // If this is our click to reveal campaign
          if (msgId === 'ctr') {
            this.handleClickToRevealMessage(message);
            return inAppMessages.slice(1);
          }
        }
      }
      // Remove this message from the array of IAMs and return whatever's left
    });
  }
}
