import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinkValidationService {
  private allowedURLs = new Set([
    'bloomandwild.com',
    'bloomandwild.de',
    'bloomandwild.com/de-at',
    'bloomandwild.com/en-at',
    'bloomon.com',
    'bloomon.co.uk',
    'bloomon.de',
    'bloomon.nl',
    'bloomon.dk',
    'bloomon.be',
    'bloomdev.org',
    'bloomdev.org/de-at',
    'bloomdev.org/en-at',
    'bloomon-uk.bloomdev.org',
    'bloomon-nl.bloomdev.org',
    'bloomon-be.bloomdev.org',
    'bloomon-de.bloomdev.org',
    'bloomon-dk.bloomdev.org',
    'link.orders.bloomon.com',
    'link.orders.bloomon.co.uk',
    'link.orders.bloomon.de',
    'link.orders.bloomon.nl',
    'link.orders.bloomon.dk',
    'link.orders.bloomon.be'
  ]);

  constructor() {}

  validateLink(link: string): boolean {
    // https://bloomon.atlassian.net/wiki/spaces/PTD/pages/3368124419/CO-946+FE+Spike+Investigate+fix+for+security+vulnerabilities
    // If the link is not a valid URL (one of the B&W group domains as above), return false
    // An example of an invalid URL:https://www.bloomandwild.com/branch?forward=https://evil.com/
    // This covers ?forward= links only

    const urlToTest: URL = new URL(link);
    const urlString: string = urlToTest.host.replace(/^www./, '').toString();
    return this.allowedURLs.has(urlString);
  }
}
