/* tslint:disable */
export const alternateMappings = [
  {
    "en-gb": "https://www.bloomandwild.com/",
    "de-de": "https://www.bloomandwild.de/"
  },
  {
    "en-gb": "https://www.bloomandwild.com/send-flowers",
    "de-de": "https://www.bloomandwild.de/blumen-versenden"
  }
];