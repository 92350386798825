/**
 *
 * Documentation is here: https://developers.pinterest.com/docs/ad-tools/conversion-tag/?
 * Pixel Helper - https://developers.pinterest.com/docs/ad-tools/pinterest-tag-helper/?
 *
 */
import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { WindowRefService } from 'Shared/services/window.service';
import { User } from 'Shared/classes/user';
import { ConfigService } from 'Shared/services/config.service';
import { Purchase } from 'Shared/classes/purchase';
import { Order } from 'Shared/classes/order';

@Injectable({
  providedIn: 'root'
})
export class PinterestService {
  tagId: string = '';
  debug: boolean = false;
  window: any;
  initPromise: Promise<any>;
  serviceInitialized: boolean = false;

  constructor(
    private windowRef: WindowRefService,
    private domUtils: DomUtilsService,
    private configService: ConfigService
  ) {
    this.tagId = this.configService.getConfig().pinterestTagId;
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<pinterest>', ...args);
    }
  }

  /**
   * Run the pinterest Code
   * @param args
   */
  pinterest(...args): void {
    // Check to make sure service was intialized and in the window object before tracking
    if (this.window['pintrk'] && this.serviceInitialized) {
      this.window['pintrk'](...args);
      this.log(...args);
    }
  }

  /**
   * Track a page
   */
  trackPage(): void {
    this.pinterest('page');
  }

  /**
   * Add to a purchase
   * @param order
   */
  addToPurchase(order: Order): void {
    this.pinterest('track', 'AddToCart', {
      product_name: order.product.name,
      product_id: order.product.slug,
      product_variant: order.getTrackedDurationName(),
      value: (order.product.getPrice().price / 100).toFixed(2),
      order_quantity: 1,
      currency: order.product.getPrice().currency
    });
  }

  /**
   * Track a purchase
   * @param purchase
   */
  trackPurchase(purchase: Purchase): void {
    this.pinterest('track', 'Checkout', {
      value: (purchase.price.price / 100).toFixed(2),
      order_quantity: 1,
      currency: purchase.price.currency,
      order_id: purchase.id,
      promo_code: purchase.discount && purchase.discount.code ? purchase.discount.code : '',
      line_items: purchase.orders.map((order) => ({
        product_name: order.product.name,
        product_variant: order.getTrackedDurationName(),
        product_id: order.product.slug,
        product_price: (order.price.price / 100).toFixed(2),
        product_quantity: 1
      }))
    });
  }

  /**
   * Identify a user
   * @param user
   */
  identify(user: User): void {
    this.pinterest('load', this.tagId, {
      em: user.email.address
    });
  }

  /**
   * Init
   */
  init(): Promise<any> {
    this.initPromise =
      this.initPromise ||
      this.domUtils.loadScript('https://s.pinimg.com/ct/core.js', 'pinterest').then(() => {
        // on first load set the service as initialized.
        this.serviceInitialized = true;
        this.pinterest('load', this.tagId);
      });

    return this.initPromise;
  }
}
