import { ElementRef, Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { StateParams } from 'Shared/services/state.service';

interface IframeOptions {
  id: string;
  parentElement?: HTMLElement;
}

@Injectable({
  providedIn: 'root'
})
export class DomUtilsService {
  window: any;

  // We can assume WebP support more confidently now
  // starting as true fixes a timing issue that meant safari sometimes showed jpeg
  supportsWebP: boolean = true;
  constructor(private windowRef: WindowRefService) {
    this.window = this.windowRef.nativeWindow;
    this.supportsWebP = this.windowRef.nativeWindow.bwHasWebPSupport || false;
  }

  /**
   * Matches selector
   */
  matches(el: any, selector: string): any {
    return (
      el.matches ||
      el.matchesSelector ||
      el.msMatchesSelector ||
      el.mozMatchesSelector ||
      el.webkitMatchesSelector ||
      el.oMatchesSelector
    ).call(el, selector);
  }

  /**
   * Find the nearest ancestor that matches a css selector
   */
  findAncestor(el: any, sel: string): any {
    /* tslint:disable */
    while ((el = el.parentElement) && !this.matches(el, sel)) {}
    return el;
    /* tslint:enable */
  }

  /**
   * Remove an element with id
   * @param id
   */
  removeElemWithId(id: string): void {
    const elem = document.querySelector(`#${id}`);
    if (elem) {
      elem.parentNode.removeChild(elem);
    }
  }

  /**
   * Load a script async
   * @param url
   * @param id
   */
  loadScript(url: string, id: string): Promise<void> {
    if (id) {
      this.removeElemWithId(id);
    }

    return new Promise((resolve, reject) => {
      const script = this.window.document.createElement('script');
      script.setAttribute('src', url);
      script.id = id;
      script.async = true;
      script.onreadystatechange = () => resolve();
      script.onload = () => resolve();
      this.window.document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  /**
   * Load a pixel element to be used with tracking
   *
   * @param url - pixel url
   * @param id - script id
   * @param tagName - pixel element type
   * @param attribute - attribute to be added to element
   */
  loadPixelElement(
    url: string,
    id: string = '',
    tagName: 'div' | 'img' = 'img',
    attribute: string = 'src'
  ): Promise<void> {
    if (id) {
      this.removeElemWithId(id);
    }

    return new Promise((resolve) => {
      const pixel = this.window.document.createElement(tagName);
      pixel.setAttribute(attribute, url);
      pixel.setAttribute('width', '0');
      pixel.setAttribute('height', '0');
      pixel.setAttribute('border', '0');
      pixel.id = id;
      pixel.style.display = 'none';
      this.window.document.body.appendChild(pixel);
      resolve();
    });
  }

  /**
   * Load an iframe
   */
  loadIframe(url: string, options: IframeOptions): Promise<void> {
    if (options.id) {
      this.removeElemWithId(options.id);
    }

    return new Promise((resolve, reject) => {
      const iframe = this.window.document.createElement('iframe');
      iframe.setAttribute('src', url);
      iframe.id = options.id;
      iframe.async = true;
      if (options.parentElement) {
        options.parentElement.appendChild(iframe);
      } else {
        this.window.document.body.appendChild(iframe);
      }
      resolve();
    });
  }
}
