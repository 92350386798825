import { Price, CurrencyCode } from 'Shared/classes/price';

export class Credit extends Price {
  use: boolean;

  // TODO - Yet Another thing extending price - lets discuss a potential better approach
  constructor(use: boolean, currency?: CurrencyCode, quantity?: number, original?: number) {
    super(currency, quantity, original);
    this.use = use;
  }
}
