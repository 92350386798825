
<div class="toastr__wrapper" *ngIf="message &amp;&amp; message.config">
  <ng-container *ngIf="message.config.position === 'top'">
    <div class="toastr toastr--{{ message.type }} {{message.config.cssClass}}" [@showToast]="{
        value: message.visible,
        params: {
          duration: message.config.easeTime
        }
      }" (click)="!message.config.onTitleClick &amp;&amp; !message.config.onMessageClick &amp;&amp; onCloseButtonClick()" (mouseenter)="stopTimeout()" (mouseleave)="startTimeout()">
      <div class="toastr__inner">
        <div class="toastr__close-button" *ngIf="message.config.closeButton" aria-label="Close" (click)="(message.config.onTitleClick || message.config.onMessageClick) &amp;&amp; onCloseButtonClick()">
          <bw-icon [iconType]="'small-x'"></bw-icon>
        </div>
        <div class="toastr__title" aria-label="title" (click)="message.config.onTitleClick &amp;&amp; message.config.onTitleClick(message, component)" [innerHTML]="message.title"></div>
        <div class="toastr__message" [innerHTML]="message.message" (click)="message.config.onMessageClick &amp;&amp; message.config.onMessageClick(message, component)"></div>
        <div class="toastr__progress-container" [hidden]="!message.config.autoDismiss">
          <div class="toastr__progress" [@toastProgress]="{
            value: messageTimeoutActive,
            params: {
              duration: message.config.timeOut
            }
          }"></div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="message.config.position === 'bottomLeft'">
    <ui-box class="toastr-bl toastr--{{ message.type }} {{message.config.cssClass}}" box_space="none" box_fill_mode="fit" [@showToast]="{
        value: message.visible,
        params: {
          duration: message.config.easeTime
        }
      }" (click)="!message.config.onTitleClick &amp;&amp; !message.config.onMessageClick &amp;&amp; onCloseButtonClick()" (mouseenter)="stopTimeout()" (mouseleave)="startTimeout()">
      <ui-box class="toastr-bl__inner" box_space="near" box_fill_mode="fit">
        <ui-stack stack_direction="x" stack_align="center" stack_distribute="start" stack_gap="default">
          <ui-box box_space="none" box_fill_mode="fit" box_space_top="tiny" box_space_bottom="tiny">
            <ui-stack stack_direction="x" stack_align="center" stack_distribute="start" stack_gap="near">
              <ui-box box_space="none" box_fill_mode="fit">
                <ui-image class="toastr-bl__main-icon" image_alt_text="Success toastr image" image_src="/assets/brand-icons/discountIcon.svg"></ui-image>
              </ui-box>
              <ui-stack stack_direction="y" stack_align="start" stack_distribute="start" stack_gap="none"><span class="text-body--default text-body--emphasis" aria-label="title" (click)="message.config.onTitleClick &amp;&amp; message.config.onTitleClick(message, component)" [innerHTML]="message.title"></span><span class="text-body--supporting" [innerHTML]="message.message" (click)="message.config.onMessageClick &amp;&amp; message.config.onMessageClick(message, component)"></span></ui-stack>
            </ui-stack>
          </ui-box>
          <ui-box box_space="none" box_fill_mode="fit" *ngIf="message.config.closeButton" aria-label="Close" (click)="(message.config.onTitleClick || message.config.onMessageClick) &amp;&amp; onCloseButtonClick()">
            <bw-icon class="toastr-bl__close-icon" [iconType]="'small-x'"></bw-icon>
          </ui-box>
        </ui-stack>
      </ui-box>
    </ui-box>
  </ng-container>
</div>