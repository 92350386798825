import { Pipe, PipeTransform } from '@angular/core';
import { Addon } from 'Shared/classes/addon';
import { Product } from 'Shared/classes/product';

@Pipe({
  name: 'addonCombinationImage'
})
export class AddonCombinationImagePipe implements PipeTransform {
  transform(product: Product, addons: Addon[]): string {

    const addonWithCompbinationImage = (addons || []).find(
      addon => addon.productCombinationImageUrls.length
    );
    if (addonWithCompbinationImage) {
      return addonWithCompbinationImage.productCombinationImageUrls[0];
    }

    if (product?.addonPrimaryImage) {
      return product.addonPrimaryImage;
    }

    return product.imageUrls[0];
  }
}
