import { Injectable } from '@angular/core';
import { ExperimentsService } from 'Project/shared/services/experiments.service';
import { FeaturesService } from 'Project/shared/services/features.service';
import { HeapService } from 'Project/shared/services/third-parties/heap.service';
import { BehaviorSubject } from 'rxjs';

export interface IGridView {
  view: 'stack' | 'column';
}

@Injectable({
  providedIn: 'root'
})
export class ProductGridViewToggleService {
  public gridViewToggle$: BehaviorSubject<IGridView> = new BehaviorSubject({ view: 'stack' });

  constructor(private heapService: HeapService, private featuresService: FeaturesService, private experimentsService: ExperimentsService) {
    this.setInitialValue();
  }

  /**
   * Sets initial value based on exp variant
   */
  setInitialValue(): void {
    const defaultValue = this.featuresService.getFeature('GRID_SWITCHER') ? 'column' : 'stack';

    this.gridViewToggle$.next({
      view: defaultValue
    });

    // Set it Globally so all future events can also use it.
    this.heapService.setGlobalEventProperty({
      gridViewType: defaultValue === 'stack' ? 'oneColumn' : 'twoColumns'
    });
  }

  /**
   *
   * @param toggle Stack or Column
   */
  toggleView(toggle: 'stack' | 'column'): void {
    this.gridViewToggle$.next({
      view: toggle
    });
  }
}
