/* eslint-disable */
export const heapAnalyticsMappings = [
  {
    "name": "deliveryDetails",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "checkoutType",
      "loggedInUser",
      "registeredUser",
      "promoCode",
      "promoCodeApplication",
      "promoCodeStatus",
      "promoCodeType",
      "promoCodeDetail",
      "promoCodeValue",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews",
      "orderType",
      "orderBundleDuration",
      "orderBundleFrequency",
      "gridViewType",
      "greetingCardSku",
      "greetingCardPrice",
      "greetingCardName",
      "totalAddonAdded",
      "totalFreeAddon",
      "totalPaidAddon",
      "totalAddonPrice",
      "totalAddonList",
      "totalAddonNameList",
      "productCardLabel"
    ]
  },
  {
    "name": "HomepageHeroImpression",
    "properties": [
      "content",
      "ranking",
      "media"
    ]
  },
  {
    "name": "homepageHeroClick",
    "properties": [
      "type",
      "clicktext",
      "content",
      "ranking",
      "media"
    ]
  },
  {
    "name": "modalView",
    "properties": [
      "modalType",
      "loggedInUser",
      "registeredUser"
    ]
  },
  {
    "name": "exitModal",
    "properties": [
      "modalType"
    ]
  },
  {
    "name": "componentViewed",
    "properties": [
      "componentName",
      "loggedInUser",
      "registeredUser",
      "placement",
      "productSKU",
      "productName",
      "productPrice",
      "productNumReviews",
      "productAvgRating",
      "productCardLabel",
      "productIsBoosted",
      "productIsFavourite",
      "productIsPreorder",
      "productIsPreviouslyPurchased",
      "productListUniqueIndentifier",
      "productPosition",
      "productUndeliverableTomorrow"
    ]
  },
  {
    "name": "activeContentSection",
    "properties": [
      "activeCtaUrl",
      "contentPosition"
    ]
  },
  {
    "name": "categoryBlockClick",
    "properties": [
      "categoryListName",
      "cardName",
      "cardUrl"
    ]
  },
  {
    "name": "deliveryTrackingModalView",
    "properties": [
      "trackingState",
      "deliveryId",
      "orderId",
      "orderType"
    ]
  },
  {
    "name": "compensationRequestModal",
    "properties": [
      "orderId",
      "compensationType"
    ]
  },
  {
    "name": "deliveryresend",
    "properties": [
      "deliveryId"
    ]
  },
  {
    "name": "deliveryPartialCredit",
    "properties": [
      "deliveryId"
    ]
  },
  {
    "name": "deliveryfullcredit",
    "properties": [
      "deliveryId"
    ]
  },
  {
    "name": "compensationRequestSubmitted",
    "properties": [
      "orderId",
      "compensationType"
    ]
  },
  {
    "name": "trackingModalViewMore",
    "properties": [
      "deliveryId",
      "trackingState",
      "details"
    ]
  },
  {
    "name": "productSelected",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency"
    ]
  },
  {
    "name": "subsCardEngagement",
    "properties": [
      "carouselLength",
      "carouselTag",
      "carouselType",
      "loggedInUser",
      "registeredUser",
      "engagementCta"
    ]
  },
  {
    "name": "ModularContentBlockClick",
    "properties": [
      "modularProductCardIndex",
      "modularProductCardHasCta",
      "primaryCta",
      "secondaryCta",
      "modularProductCardCUrclickon",
      "modularProductCardMessage",
      "modularProductCardId",
      "modularProductCardName",
      "modularProductCardSource",
      "modularProductCardCtaCopy"
    ]
  },
  {
    "name": "ModularContentBlockImpression",
    "properties": [
      "modularProductCardIndex",
      "modularProductCardName"
    ]
  },
  {
    "name": "breadcrumbsClicked",
    "properties": [
      "breadcrumbState",
      "breadcrumb"
    ]
  },
  {
    "name": "navInteraction",
    "properties": [
      "navStyle",
      "productFindingMethod",
      "carouselLength",
      "carouselSegments",
      "carouselTag",
      "carouselType",
      "mainNavItem",
      "primarySubNavItem",
      "secondarySubNavItem",
      "contentSpot",
      "mainNavItemNew",
      "primarySubNavItemNew",
      "secondarySubNavItemNew",
      "subNavLength",
      "subNavPosition"
    ]
  },
  {
    "name": "updateEmailPreferences",
    "properties": [
      "old_preference",
      "new_preference",
      "detail",
      "location"
    ]
  },
  {
    "name": "visualNavInteraction",
    "properties": [
      "carouselLength",
      "carouselTag",
      "carouselType",
      "visualNavItem",
      "visualNavSubNavItem",
      "loggedInUser",
      "registeredUser",
      "cardUrl",
      "location"
    ]
  },
  {
    "name": "viewSplashCategories",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "location"
    ]
  },
  {
    "name": "carousel",
    "properties": [
      "carouselTag",
      "productFindingMethod",
      "carouselType",
      "carouselLength",
      "loggedInUser",
      "registeredUser",
      "carouselSegments",
      "carouselSegmentId",
      "carouselSorted"
    ]
  },
  {
    "name": "carouselSorted",
    "properties": [
      "carouselTag",
      "productFindingMethod",
      "carouselType",
      "carouselLength",
      "loggedInUser",
      "registeredUser",
      "carouselSegments",
      "carouselSegmentId",
      "carouselSortType"
    ]
  },
  {
    "name": "productImpression",
    "properties": [
      "productUndeliverableTomorrow",
      "carouselTag",
      "carouselType",
      "carouselSegments",
      "carouselSegmentId",
      "carouselLength",
      "loggedInUser",
      "registeredUser",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews",
      "daysUntilDeliverable",
      "productIsFavourite",
      "productIsBoosted",
      "productIsPreorder",
      "productIsPreviouslyPurchased",
      "productListUniqueIndentifier",
      "productCardLabel"
    ]
  },
  {
    "name": "playVideo",
    "properties": [
      "productSKU",
      "productName",
      "productPrice",
      "productNumReviews",
      "productAvgRating",
      "productIsBoosted",
      "productIsFavourite",
      "productIsPreorder",
      "productIsPreviouslyPurchased",
      "productListUniqueIndentifier",
      "productCardLabel",
      "productPosition",
      "productUndeliverableTomorrow",
      "playVideoBy",
      "placement",
      "assetType",
      "carouselTag",
      "carouselType",
      "carouselLength"
    ]
  },
  {
    "name": "monthlyFlowersModalProductImpression",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "modalLocation"
    ]
  },
  {
    "name": "addonImpression",
    "properties": [
      "addonName",
      "addonId",
      "addonSKU",
      "addOnPrice",
      "addOnCurrency",
      "addonKind",
      "placement",
      "productName",
      "loggedInUser",
      "registeredUser",
      "addonPosition"
    ]
  },
  {
    "name": "carouselFiltersAdded",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "addedFiltersAlphabetical",
      "addedFilters",
      "activeFiltersAlphabetical",
      "activeFilters",
      "loggedInUser",
      "registeredUser",
      "productListUniqueIndentifier"
    ]
  },
  {
    "name": "carouselFiltersRemoved",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "removedFiltersAlphabetical",
      "removedFilters",
      "activeFiltersAlphabetical",
      "activeFilters",
      "loggedInUser",
      "registeredUser",
      "productListUniqueIndentifier"
    ]
  },
  {
    "name": "productList",
    "properties": [
      "productListIds",
      "productListUniqueIndentifier"
    ]
  },
  {
    "name": "productView",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "loggedInUser",
      "registeredUser",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews",
      "productCardLabel"
    ]
  },
  {
    "name": "productReviews",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "loggedInUser",
      "registeredUser",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews"
    ]
  },
  {
    "name": "subsProductView",
    "properties": [
      "carouselLength",
      "carouselTag",
      "carouselType",
      "loggedInUser",
      "productAvgRating",
      "productCurrency",
      "productFindingMethod",
      "productName",
      "productNumReviews",
      "productPosition",
      "productPrice",
      "productSKU",
      "registeredUser",
      "subsDuration",
      "subsFrequency",
      "fromSubFrequency",
      "subsType"
    ]
  },
  {
    "name": "subsModalEngagement",
    "properties": [
      "carouselLength",
      "carouselTag",
      "carouselType",
      "loggedInUser",
      "productAvgRating",
      "productCurrency",
      "productFindingMethod",
      "productName",
      "productNumReviews",
      "productPosition",
      "productPrice",
      "productSKU",
      "subsDuration",
      "subsFrequency",
      "fromSubFrequency",
      "subsType"
    ]
  },
  {
    "name": "startCheckout",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "checkoutType",
      "shippingDate",
      "shippingFee",
      "shippingOption",
      "shippingPostcode",
      "shippingCountry",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "discountCodeType",
      "discountCodeDetail",
      "discountCode",
      "discountCodeApplication",
      "rewardsMember",
      "rewardsVoucherCode",
      "rewardsVoucherValue",
      "rewardsVoucherValuePoints",
      "rewardsVoucherApplication",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews",
      "orderType",
      "orderBundleDuration",
      "orderBundleFrequency",
      "productListUniqueIndentifier",
      "gridViewType",
      "productCardLabel",
      "greetingCardSku",
      "greetingCardPrice",
      "greetingCardName",
      "totalAddonAdded",
      "totalFreeAddon",
      "totalPaidAddon",
      "totalAddonPrice",
      "totalAddonList",
      "totalAddonNameList"
    ]
  },
  {
    "name": "switchGridView",
    "properties": [
      "gridViewType"
    ]
  },
  {
    "name": "addressChanged",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "checkoutType",
      "shippingDate",
      "shippingOption",
      "shippingPostcode",
      "shippingCountry",
      "paymentMethod",
      "purchaseID",
      "purchaseCurrency",
      "purchaseAmount",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "promoCode",
      "promoCodeApplication",
      "promoCodeStatus",
      "promoCodeType",
      "promoCodeDetail",
      "promoCodeValue",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews",
      "orderType",
      "orderID",
      "orderAmount",
      "orderCurrency",
      "orderBundleDuration",
      "orderBundleFrequency"
    ]
  },
  {
    "name": "addressAdded",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "checkoutType",
      "shippingDate",
      "shippingFee",
      "shippingOption",
      "shippingPostcode",
      "shippingCountry",
      "paymentMethod",
      "purchaseID",
      "purchaseCurrency",
      "purchaseAmount",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "promoCode",
      "promoCodeApplication",
      "promoCodeStatus",
      "promoCodeType",
      "promoCodeDetail",
      "promoCodeValue",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews",
      "orderType",
      "orderID",
      "orderAmount",
      "orderCurrency",
      "orderBundleDuration",
      "orderBundleFrequency",
      "gridViewType",
      "greetingCardSku",
      "greetingCardPrice",
      "greetingCardName",
      "totalAddonAdded",
      "totalFreeAddon",
      "totalPaidAddon",
      "totalAddonPrice",
      "totalAddonList",
      "totalAddonNameList"
    ]
  },
  {
    "name": "shippingDateChanged",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "checkoutType",
      "shippingDate",
      "shippingFee",
      "shippingOption",
      "shippingPostcode",
      "shippingCountry",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "promoCode",
      "promoCodeApplication",
      "promoCodeStatus",
      "promoCodeType",
      "promoCodeDetail",
      "promoCodeValue",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews",
      "orderType",
      "orderBundleDuration",
      "orderBundleFrequency",
      "greetingCardSku",
      "greetingCardPrice",
      "greetingCardName",
      "totalAddonAdded",
      "totalFreeAddon",
      "totalPaidAddon",
      "totalAddonPrice",
      "totalAddonList",
      "totalAddonNameList"
    ]
  },
  {
    "name": "viewShippingOptions",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "checkoutType",
      "shippingDate",
      "shippingOption",
      "shippingPostcode",
      "shippingCountry",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "promoCode",
      "promoCodeApplication",
      "promoCodeStatus",
      "promoCodeType",
      "promoCodeDetail",
      "promoCodeValue",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews",
      "orderType",
      "orderBundleDuration",
      "orderBundleFrequency",
      "greetingCardSku",
      "greetingCardPrice",
      "greetingCardName",
      "totalAddonAdded",
      "totalFreeAddon",
      "totalPaidAddon",
      "totalAddonPrice",
      "totalAddonList",
      "totalAddonNameList"
    ]
  },
  {
    "name": "shippingOptionChanged",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "checkoutType",
      "shippingDate",
      "shippingFee",
      "shippingOption",
      "shippingPostcode",
      "shippingCountry",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "promoCode",
      "promoCodeApplication",
      "promoCodeStatus",
      "promoCodeType",
      "promoCodeDetail",
      "promoCodeValue",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews",
      "orderType",
      "orderBundleDuration",
      "orderBundleFrequency",
      "greetingCardSku",
      "greetingCardPrice",
      "greetingCardName"
    ]
  },
  {
    "name": "shippingDateClicked",
    "properties": [
      "shippingDateStatus",
      "shippingDate",
      "shippingPostcode",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews"
    ]
  },
  {
    "name": "alternativeOptionButtonShown",
    "properties": [
      "shippingDate",
      "shippingPostcode",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews"
    ]
  },
  {
    "name": "orderDetails",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "checkoutType",
      "shippingDate",
      "shippingFee",
      "shippingOption",
      "shippingPostcode",
      "shippingCountry",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "promoCode",
      "promoCodeApplication",
      "promoCodeStatus",
      "promoCodeType",
      "promoCodeDetail",
      "promoCodeValue",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews",
      "orderType",
      "orderBundleDuration",
      "orderBundleFrequency",
      "greetingCardSku",
      "greetingCardPrice",
      "greetingCardName"
    ]
  },
  {
    "name": "addOnAdded",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "checkoutType",
      "shippingDate",
      "shippingOption",
      "shippingPostcode",
      "shippingCountry",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "placement",
      "promoCode",
      "promoCodeApplication",
      "promoCodeStatus",
      "promoCodeType",
      "promoCodeDetail",
      "promoCodeValue",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews",
      "addOnPrice",
      "addOnCurrency",
      "addonSKU",
      "addonName",
      "addonKind",
      "orderType",
      "orderBundleDuration",
      "orderBundleFrequency"
    ]
  },
  {
    "name": "addOnRemoved",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "checkoutType",
      "shippingDate",
      "shippingOption",
      "shippingPostcode",
      "shippingCountry",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "promoCode",
      "promoCodeApplication",
      "promoCodeStatus",
      "promoCodeType",
      "promoCodeDetail",
      "promoCodeValue",
      "placement",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews",
      "addOnPrice",
      "addOnCurrency",
      "addonSKU",
      "addonName",
      "orderType",
      "orderBundleDuration",
      "orderBundleFrequency"
    ]
  },
  {
    "name": "upgradeToBundle",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "checkoutType",
      "shippingDate",
      "shippingOption",
      "shippingPostcode",
      "shippingCountry",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "promoCode",
      "promoCodeApplication",
      "promoCodeStatus",
      "promoCodeType",
      "promoCodeDetail",
      "promoCodeValue",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews",
      "orderType",
      "orderBundleDuration",
      "orderBundleFrequency"
    ]
  },
  {
    "name": "payment",
    "properties": [
      "purchaseID",
      "purchaseCurrency",
      "purchaseAmount",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "promoCode",
      "promoCodeApplication",
      "promoCodeStatus",
      "promoCodeType",
      "promoCodeDetail",
      "promoCodeValue",
      "skuList",
      "skuTotal"
    ]
  },
  {
    "name": "orderPayment",
    "properties": [
      "shippingDate",
      "shippingFee",
      "shippingOption",
      "shippingPostcode",
      "shippingCountry",
      "purchaseID",
      "purchaseCurrency",
      "purchaseAmount",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "discountCodeType",
      "discountCodeDetail",
      "discountCode",
      "discountCodeApplication",
      "rewardsMember",
      "rewardsVoucherCode",
      "rewardsVoucherValue",
      "rewardsVoucherValuePoints",
      "rewardsVoucherApplication",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productAvgRating",
      "productNumReviews",
      "orderType",
      "orderID",
      "orderAmount",
      "orderCurrency",
      "orderBundleDuration",
      "orderBundleFrequency",
      "gridViewType",
      "productCardLabel",
      "greetingCardSku",
      "greetingCardName",
      "totalAddonAdded",
      "totalAddonList",
      "totalAddonNameList"
    ]
  },
  {
    "name": "codeAppliedSuccess",
    "properties": [
      "purchaseID",
      "purchaseCurrency",
      "purchaseAmount",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "discountCodeType",
      "discountCodeDetail",
      "discountCode",
      "discountCodeApplication",
      "rewardsMember",
      "rewardsVoucherCode",
      "rewardsVoucherValue",
      "rewardsVoucherValuePoints",
      "rewardsVoucherApplication"
    ]
  },
  {
    "name": "codeAppliedAttempt",
    "properties": [
      "purchaseID",
      "purchaseCurrency",
      "purchaseAmount",
      "loggedInUser",
      "registeredUser",
      "rewardsMember",
      "codeEntered",
      "codeApplication"
    ]
  },
  {
    "name": "paymentButton",
    "properties": [
      "paymentMethod",
      "paymentMethodDetails",
      "purchaseID",
      "purchaseCurrency",
      "purchaseAmount",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "promoCode",
      "promoCodeApplication",
      "promoCodeStatus",
      "promoCodeType",
      "promoCodeDetail",
      "promoCodeValue"
    ]
  },
  {
    "name": "productdetailsimagescrolled",
    "properties": [
      "productSKU",
      "productName",
      "productPrice",
      "productNumReviews",
      "productAvgRating",
      "assetType",
      "assetPosition"
    ]
  },
  {
    "name": "orderConfirmation",
    "properties": [
      "shippingDate",
      "shippingFee",
      "shippingOption",
      "shippingPostcode",
      "shippingCountry",
      "purchaseID",
      "purchaseCurrency",
      "purchaseAmount",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "discountCodeType",
      "discountCodeDetail",
      "discountCode",
      "discountCodeApplication",
      "rewardsMember",
      "rewardsVoucherCode",
      "rewardsVoucherValue",
      "rewardsVoucherValuePoints",
      "rewardsVoucherApplication",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productAvgRating",
      "productNumReviews",
      "orderType",
      "orderID",
      "orderAmount",
      "orderCurrency",
      "orderBundleDuration",
      "orderBundleFrequency",
      "gridViewType",
      "skuList",
      "skuTotal",
      "productCardLabel",
      "greetingCardSku",
      "greetingCardName",
      "totalAddonAdded",
      "totalAddonList",
      "totalAddonNameList"
    ]
  },
  {
    "name": "subscriptionPrepayment",
    "properties": [
      "shippingDate",
      "shippingFee",
      "shippingOption",
      "shippingPostcode",
      "shippingCountry",
      "purchaseID",
      "purchaseCurrency",
      "purchaseAmount",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "discountCodeType",
      "discountCodeDetail",
      "discountCode",
      "discountCodeApplication",
      "rewardsMember",
      "rewardsVoucherCode",
      "rewardsVoucherValue",
      "rewardsVoucherValuePoints",
      "rewardsVoucherApplication",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productAvgRating",
      "productNumReviews",
      "orderType",
      "orderID",
      "orderAmount",
      "orderCurrency",
      "orderBundleDuration",
      "orderBundleFrequency",
      "gridViewType",
      "skuList",
      "skuTotal",
      "productCardLabel",
      "greetingCardSku",
      "greetingCardName",
      "totalAddonAdded",
      "totalAddonList",
      "totalAddonNameList",
      "paymentMethod",
      "paymentMethodDetails"
    ]
  },
  {
    "name": "purchaseConfirmation",
    "properties": [
      "paymentMethod",
      "paymentMethodDetails",
      "purchaseID",
      "purchaseCurrency",
      "purchaseAmount",
      "loggedInUser",
      "registeredUser",
      "addressType",
      "promoCode",
      "promoCodeApplication",
      "promoCodeStatus",
      "promoCodeType",
      "promoCodeDetail",
      "promoCodeValue"
    ]
  },
  {
    "name": "errorMessageThrown",
    "properties": [
      "errorMessage",
      "paymentProvider",
      "paymentErrorCode",
      "codeEntered",
      "errorBody",
      "errorCode",
      "errorKind"
    ]
  },
  {
    "name": "closeErrorMessage",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "errorMessage",
      "purchaseID"
    ]
  },
  {
    "name": "saveOccasion",
    "properties": [
      "savedOccasionOrigin"
    ]
  },
  {
    "name": "occasionSaved",
    "properties": [
      "savedOccasionID",
      "savedOccasionName",
      "savedOccasionOtherName",
      "savedOccasionDate",
      "savedOccasionOrigin"
    ]
  },
  {
    "name": "occasionRecipientAdded",
    "properties": [
      "savedOccasionName",
      "savedOccasionOtherName",
      "savedOccasionDate",
      "savedOccasionOrigin"
    ]
  },
  {
    "name": "occasionSelected",
    "properties": [
      "savedOccasionName",
      "savedOccasionOtherName",
      "savedOccasionDate",
      "savedOccasionOrigin"
    ]
  },
  {
    "name": "occasionOtherInputted",
    "properties": [
      "savedOccasionName",
      "savedOccasionOtherName",
      "savedOccasionDate",
      "savedOccasionOrigin"
    ]
  },
  {
    "name": "occasionDateSelected",
    "properties": [
      "savedOccasionName",
      "savedOccasionOtherName",
      "savedOccasionDate",
      "savedOccasionOrigin"
    ]
  },
  {
    "name": "contentfulPageViewed",
    "properties": [
      "contentfulPageUrl",
      "contentfulPageTitle",
      "referrerUrl"
    ]
  },
  {
    "name": "experimentActive",
    "properties": [
      "experimentID",
      "experimentName",
      "experimentVariant"
    ]
  },
  {
    "name": "contentSegmentApplied",
    "properties": [
      "contentSegments",
      "carouselSegments"
    ]
  },
  {
    "name": "carouselSegmentApplied",
    "properties": [
      "carouselTag",
      "carouselType",
      "carouselLength",
      "filterName",
      "filterValue",
      "filterType",
      "checkoutType",
      "shippingDate",
      "shippingOption",
      "shippingPostcode",
      "shippingCountry",
      "paymentMethod",
      "purchaseID",
      "purchaseCurrency",
      "purchaseAmount",
      "loggedInUser",
      "registeredUser",
      "contentSegments",
      "carouselSegments",
      "addressType",
      "promoCode",
      "promoCodeApplication",
      "promoCodeStatus",
      "promoCodeType",
      "promoCodeDetail",
      "promoCodeValue",
      "errorMessage",
      "savedOccasionName",
      "savedOccasionDate",
      "experimentID",
      "experimentName",
      "experimentVariant",
      "contentfulPageID",
      "contentfulPageTitle",
      "productSKU",
      "productName",
      "productPrice",
      "productCurrency",
      "productPosition",
      "productFindingMethod",
      "productAvgRating",
      "productNumReviews",
      "orderType",
      "orderID",
      "orderAmount",
      "orderCurrency",
      "orderBundleDuration",
      "orderBundleFrequency",
      "contentfulPageUrl",
      "contentfulPageTitle"
    ]
  },
  {
    "name": "imageScrolled",
    "properties": [
      "productSKU",
      "productName",
      "loggedInUser",
      "registeredUser",
      "carouselTag",
      "carouselType"
    ]
  },
  {
    "name": "editOrder",
    "properties": [
      "loggedInUser",
      "registeredUser"
    ]
  },
  {
    "name": "mentionMe",
    "properties": [
      "loggedInUser",
      "registeredUser"
    ]
  },
  {
    "name": "shopAgainAndSave",
    "properties": [
      "loggedInUser",
      "registeredUser"
    ]
  },
  {
    "name": "appBanner",
    "properties": [
      "loggedInUser",
      "registeredUser"
    ]
  },
  {
    "name": "helpCentreDeliveryResolution",
    "properties": [
      "modalType",
      "loggedInUser",
      "registeredUser",
      "modalScreen",
      "deliveryTrackingState",
      "orderId"
    ]
  },
  {
    "name": "helpCentreQualityResolution",
    "properties": [
      "modalType",
      "loggedInUser",
      "registeredUser",
      "userType",
      "modalScreen",
      "orderId",
      "issue",
      "stems",
      "issueOptionId",
      "errorMessage"
    ]
  },
  {
    "name": "menuOpened",
    "properties": [
      "brandId",
      "locale",
      "loggedInUser",
      "modalType",
      "registeredUser",
      "shippingCountryId",
      "site"
    ]
  },
  {
    "name": "menuClosed",
    "properties": [
      "brandId",
      "locale",
      "loggedInUser",
      "modalType",
      "registeredUser",
      "shippingCountryId",
      "site"
    ]
  },
  {
    "name": "orderViewTab",
    "properties": [
      "tabSelected",
      "orderID",
      "orderType"
    ]
  },
  {
    "name": "viewPastDelivery",
    "properties": [
      "orderID",
      "orderType",
      "deliveryDate",
      "deliveryId",
      "deliveryMessage"
    ]
  },
  {
    "name": "view_all_my_deliveries_tab",
    "properties": [
      "orderID",
      "orderType",
      "deliveryTypeDisplayed",
      "totalDeliveriesDisplayed",
      "skippedDeliveriesDisplayed",
      "deliveryDatesDisplayed",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries"
    ]
  },
  {
    "name": "viewUpcomingDelivery",
    "properties": [
      "orderID",
      "orderType",
      "deliveryState",
      "deliveryDate",
      "deliveryId",
      "deliveryMessage"
    ]
  },
  {
    "name": "orderViewEditDeliveryDate",
    "properties": [
      "orderID",
      "orderType",
      "deliveryState"
    ]
  },
  {
    "name": "click_to_start_churn_flow",
    "properties": [
      "orderID",
      "orderType",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries"
    ]
  },
  {
    "name": "click_get_in_touch_churn_flow",
    "properties": [
      "orderID",
      "orderType",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries",
      "modalType"
    ]
  },
  {
    "name": "submit_contact_form_churn_flow",
    "properties": [
      "orderID",
      "orderType",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries",
      "modalType"
    ]
  },
  {
    "name": "click_keep_my_subscription_churn_flow",
    "properties": [
      "orderID",
      "orderType",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries",
      "modalType"
    ]
  },
  {
    "name": "subscription_churn",
    "properties": [
      "orderID",
      "orderType",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries",
      "modalType",
      "churnReason",
      "churnType",
      "winbackType",
      "winbackDetail"
    ]
  },
  {
    "name": "select_reason_churn_flow",
    "properties": [
      "orderID",
      "orderType",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries",
      "modalType",
      "churnReason"
    ]
  },
  {
    "name": "click_continue_to_pause_or_cancel_churn_flow",
    "properties": [
      "orderID",
      "orderType",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries",
      "modalType",
      "churnReason",
      "winbackType",
      "winbackDetail",
      "existingBouquet"
    ]
  },
  {
    "name": "view_winback_modal_churn_flow",
    "properties": [
      "orderID",
      "orderType",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries",
      "modalType",
      "churnReason",
      "winbackType",
      "winbackDetail",
      "existingBouquet"
    ]
  },
  {
    "name": "winback_success_churn_flow",
    "properties": [
      "orderID",
      "orderType",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries",
      "modalType",
      "churnReason",
      "winbackType",
      "delayDeliveriesUntil",
      "winbackDetail",
      "existingBouquet",
      "newBouquet"
    ]
  },
  {
    "name": "delay_deliveries_offered_churn_flow",
    "properties": [
      "orderID",
      "orderType",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries",
      "modalType",
      "churnReason",
      "winbackType",
      "winbackDetail"
    ]
  },
  {
    "name": "ClickToViewPastDeliveriesList",
    "properties": [
      "orderID",
      "orderType"
    ]
  },
  {
    "name": "ClickToViewUpcomingDeliveriesList",
    "properties": [
      "orderID",
      "orderType"
    ]
  },
  {
    "name": "orderSkipSubsDelivery",
    "properties": [
      "orderType",
      "orderID",
      "deliveryState",
      "deliveryId",
      "skipLocation",
      "skippedDeliveryDate",
      "numberOfSkips",
      "subsFrequency"
    ]
  },
  {
    "name": "orderSkipSubsDeliveryAttempt",
    "properties": [
      "orderType",
      "orderID",
      "deliveryState",
      "skipLocation",
      "skippedDeliveryDate",
      "numberOfSkips",
      "subsFrequency"
    ]
  },
  {
    "name": "orderViewEditBouquet",
    "properties": [
      "orderID",
      "orderType",
      "deliveryState",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries",
      "existingBouquet"
    ]
  },
  {
    "name": "save_bouquet_selection",
    "properties": [
      "orderID",
      "orderType",
      "deliveryState",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries",
      "existingBouquet",
      "newBouquet"
    ]
  },
  {
    "name": "orderViewEditAddress",
    "properties": [
      "orderID",
      "orderType",
      "deliveryState",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries"
    ]
  },
  {
    "name": "orderViewSaveAndCreateNewAddress",
    "properties": [
      "orderID",
      "orderType",
      "deliveryState",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries",
      "existingBouquet",
      "updateUpcomingDeliveriesChecked"
    ]
  },
  {
    "name": "orderViewChangeAddress",
    "properties": [
      "orderID",
      "orderType",
      "deliveryState",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries",
      "existingBouquet",
      "updateUpcomingDeliveriesChecked"
    ]
  },
  {
    "name": "orderViewDetailChangeAddress",
    "properties": [
      "orderID",
      "orderType",
      "deliveryState",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries",
      "existingBouquet"
    ]
  },
  {
    "name": "orderViewDetailSaveAndCreateNewAddress",
    "properties": [
      "orderID",
      "orderType",
      "deliveryState",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries",
      "existingBouquet"
    ]
  },
  {
    "name": "orderViewEditGreetingCard",
    "properties": [
      "orderID",
      "orderType",
      "deliveryState"
    ]
  },
  {
    "name": "orderViewDownloadInvoice",
    "properties": [
      "orderID",
      "orderType"
    ]
  },
  {
    "name": "orderViewTrackDelivery",
    "properties": [
      "orderID",
      "orderType",
      "deliveryState"
    ]
  },
  {
    "name": "orderViewCancel",
    "properties": [
      "orderID",
      "orderType"
    ]
  },
  {
    "name": "orderViewDeliverySelfServe",
    "properties": [
      "orderID",
      "orderType"
    ]
  },
  {
    "name": "orderViewDetailsReschedule",
    "properties": [
      "orderID",
      "orderType"
    ]
  },
  {
    "name": "orderViewUpdateReschedule",
    "properties": [
      "orderID",
      "orderType",
      "deliveryState",
      "fromSubFrequency",
      "subsFrequency",
      "modalLocation"
    ]
  },
  {
    "name": "orderViewResumeSubscriptionModal",
    "properties": [
      "orderID",
      "orderType",
      "fromSubFrequency",
      "subsFrequency",
      "modalLocation"
    ]
  },
  {
    "name": "orderViewDetailsEditAddress",
    "properties": [
      "orderID",
      "orderType",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries"
    ]
  },
  {
    "name": "click_to_add_subs_delivery",
    "properties": [
      "orderID",
      "orderType",
      "totalDeliveriesDisplayed",
      "skippedDeliveriesDisplayed",
      "deliveryDatesDisplayed"
    ]
  },
  {
    "name": "select_date_add_subs_delivery",
    "properties": [
      "orderID",
      "orderType",
      "infoMessageDisplayed"
    ]
  },
  {
    "name": "add_subs_delivery",
    "properties": [
      "orderID",
      "orderType",
      "totalDeliveriesDisplayed",
      "skippedDeliveriesDisplayed",
      "deliveryDatesDisplayed",
      "infoMessageDisplayed",
      "addedDeliveryDatePosition"
    ]
  },
  {
    "name": "authContinueWithEmail",
    "properties": [
      "accountStatus",
      "verificaitonDisplayed",
      "origin"
    ]
  },
  {
    "name": "authViaFacebook",
    "properties": [
      "hasAccount"
    ]
  },
  {
    "name": "authLogin",
    "properties": []
  },
  {
    "name": "authLoginSuccess",
    "properties": [
      "isSuccessful"
    ]
  },
  {
    "name": "authCreateAccount",
    "properties": [
      "isSuccessful",
      "optedIn",
      "origin"
    ]
  },
  {
    "name": "authCreateAccountAttempt",
    "properties": [
      "origin",
      "attempts"
    ]
  },
  {
    "name": "clickContinueAsGuest",
    "properties": [
      "hasAccount",
      "verificaitonDisplayed",
      "verificaitonRequested"
    ]
  },
  {
    "name": "submitContinueAsGuest",
    "properties": [
      "hasAccount",
      "guestOptInSelection",
      "accountStatus"
    ]
  },
  {
    "name": "loginAsGuest",
    "properties": [
      "hasAccount"
    ]
  },
  {
    "name": "orderSubscriptionCancel",
    "properties": [
      "orderID",
      "subsFrequency",
      "reason"
    ]
  },
  {
    "name": "orderSubscriptionPause",
    "properties": [
      "orderID",
      "subsFrequency",
      "reason"
    ]
  },
  {
    "name": "rescheduleDelivery",
    "properties": [
      "orderID",
      "orderType",
      "deliveryState",
      "deliveryMovedFrom",
      "deliveryMovedTo",
      "updateUpcomingDeliveriesChecked",
      "subsFrequency"
    ]
  },
  {
    "name": "viewOrderManagementPage",
    "properties": [
      "orderID",
      "orderType",
      "subsFrequency",
      "activeSubsDeliveries",
      "skippedSubsDeliveries"
    ]
  },
  {
    "name": "orderViewDetailsEditPaymentMethod",
    "properties": [
      "orderID",
      "orderType",
      "paymentMethod"
    ]
  },
  {
    "name": "pausedSubMessageDisplayed",
    "properties": [
      "scenario",
      "message",
      "orderType",
      "orderID",
      "subsFrequency"
    ]
  },
  {
    "name": "viewRewardsHome",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "rewardsMember",
      "rewardsWidgetLocation",
      "claimedRewardsVouchers",
      "claimedRewardsValue",
      "rewardsHomeCTAMessage"
    ]
  },
  {
    "name": "rewardWidgetImpression",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "rewardsMember",
      "rewardsAvailable",
      "rewardsWidgetLocation",
      "rewardsWidgetVoucherValue",
      "rewardsWidgetAvailablePointsValue",
      "rewardsWidgetPendingPointsValue",
      "rewardsWidgetPointsToNextRewardValue"
    ]
  },
  {
    "name": "aboutRewards",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "rewardsMember"
    ]
  },
  {
    "name": "rewardsShopNow",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "rewardsMember",
      "rewardsShopLocation",
      "rewardsVoucherCode",
      "rewardsVoucherValue",
      "rewardsVoucherExpiry"
    ]
  },
  {
    "name": "addYourBirthdayRewards",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "rewardsMember"
    ]
  },
  {
    "name": "saveBirthdayRewards",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "rewardsMember"
    ]
  },
  {
    "name": "emailOptInRewards",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "rewardsMember"
    ]
  },
  {
    "name": "viewOptInModalRewards",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "rewardsMember"
    ]
  },
  {
    "name": "birthdayErrorMessageThrown",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "rewardsMember",
      "dayInput",
      "monthInput",
      "yearInput",
      "errorLocation",
      "errorMessage"
    ]
  },
  {
    "name": "joinRewardsSignUp",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "rewardsMember",
      "rewardsShopLocation",
      "utm_medium",
      "utm_campaign",
      "utm_content",
      "utm_source",
      "rewardsSignUpSource",
      "rewardsSignUpLocation"
    ]
  },
  {
    "name": "clickRewardsHomeJoinRewards",
    "properties": [
      "rewardsMemberType",
      "loggedInUser",
      "registeredUser",
      "rewardsMember",
      "rewardsShopLocation",
      "rewardsSignUpSource",
      "rewardsSignUpLocation"
    ]
  },
  {
    "name": "clickShopNowJoinRewards",
    "properties": [
      "rewardsMemberType",
      "loggedInUser",
      "registeredUser",
      "rewardsMember",
      "rewardsShopLocation",
      "rewardsSignUpSource",
      "rewardsSignUpLocation"
    ]
  },
  {
    "name": "successfulRewardsJoinModal",
    "properties": [
      "rewardsMemberType",
      "loggedInUser",
      "loggedInUser",
      "registeredUser",
      "rewardsMember",
      "rewardsSignUpSource",
      "rewardsSignUpLocation"
    ]
  },
  {
    "name": "clickCloseJoinRewards",
    "properties": [
      "loggedInUser",
      "loggedInUser",
      "registeredUser",
      "rewardsMember",
      "rewardsShopLocation",
      "rewardsSignUpSource",
      "rewardsSignUpLocation"
    ]
  },
  {
    "name": "rewardsCopyCode",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "rewardsMember",
      "rewardsVoucherCode",
      "rewardsVoucherValue",
      "rewardsVoucherExpiry"
    ]
  },
  {
    "name": "clickToClaimReward",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "rewardsMember",
      "rewardsWidgetLocation",
      "rewardsWidgetVoucherValue",
      "rewardsWidgetAvailablePointsValue",
      "rewardsWidgetPendingPointsValue",
      "rewardsWidgetPointsToNextRewardValue"
    ]
  },
  {
    "name": "rewardClaimed",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "rewardsMember",
      "rewardsWidgetLocation",
      "rewardsWidgetVoucherValue",
      "rewardsWidgetAvailablePointsValue",
      "rewardsWidgetPendingPointsValue",
      "rewardsWidgetPointsToNextRewardValue"
    ]
  },
  {
    "name": "greetingCardAdded",
    "properties": [
      "greetingCardSku",
      "greetingCardPrice",
      "greetingCardName"
    ]
  },
  {
    "name": "greetingCardImpression",
    "properties": [
      "greetingCardSku",
      "greetingCardPrice",
      "greetingCardName"
    ]
  },
  {
    "name": "successfullyJoinedRewards",
    "properties": [
      "loggedInUser",
      "registeredUser",
      "rewardsMember",
      "rewardsJoinOrigin"
    ]
  },
  {
    "name": "noUpcomingDeliveryBanner",
    "properties": [
      "orderID",
      "orderType",
      "subsFrequency",
      "existingBouquet"
    ]
  },
  {
    "name": "viewedVideoMessageModal",
    "properties": []
  },
  {
    "name": "gaveVideoMessageModalFeedback",
    "properties": [
      "sentimentFeedback"
    ]
  },
  {
    "name": "thankyoupage",
    "properties": [
      "orderId",
      "thankyouState"
    ]
  },
  {
    "name": "thankyoupage_reaction_picker",
    "properties": [
      "orderId"
    ]
  }
];