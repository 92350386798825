export const reviews = [
  {
    date: '2021-11-24T09:51:06Z',
    title: 'Tolle Blumen',
    review: 'Tolle Blumen, Ihr seid super!',
    rating: 5,
    reviewer: 'Dr Dorian Hartmuth'
  },
  {
    date: '2021-11-23T17:39:45Z',
    title: 'Ich bin mit der Qualität der Blumen und…',
    review:
      'Ich bin mit der Qualität der Blumen und den jeweiligen Kompositionen sehr zufrieden. Leider erreicht mich nicht jedes Paket unversehrt, die liegt aber am Paketboten...',
    rating: 5,
    reviewer: 'Mum Stamm'
  },
  {
    date: '2021-11-17T09:23:45Z',
    title: 'Blumen toll, Federn/Stängel Bitte weglassen',
    review:
      'Ich finde die Blumen toll aber die Federn und Stängel in Braun und Beige finde ich furchtbar hässlich.\nIch habe ein Abo für BLUMEN!!',
    rating: 4,
    reviewer: 'Judith'
  },
  {
    date: '2021-11-16T08:49:20Z',
    title: 'Ein Geschenk für sich selber :-)',
    review:
      'Ein Geschenk für sich selber :-)\nDas Abo bringt mir wirklich große Freude - immer abwechslungsreich und sehr geschmackvoll zusammengestellt !',
    rating: 5,
    reviewer: 'Beate-Ulrike'
  },
  {
    date: '2021-11-15T19:00:23Z',
    title: 'Jede zweite Woche eine tolle…',
    review: 'Jede zweite Woche eine tolle Blumenauswahl.',
    rating: 5,
    reviewer: 'Andreas Neumann'
  },
  {
    date: '2021-11-10T15:11:40Z',
    title: 'Der Beschenkte war superglücklich mit…',
    review: 'Der Beschenkte war superglücklich mit den Blumen',
    rating: 4,
    reviewer: 'Ulrike Köhrer'
  },
  {
    date: '2021-11-09T13:40:55Z',
    title: 'Wir sind sehr angetan von den Sträußen',
    review:
      'Wir sind sehr angetan von den Sträußen, die einmal/Monat pünktlich eintreffen. Die Auswahl der Bouquets ist immer gut und die Blumen halten i.d.R. mehrere Tage. Alles in allem ein gutes Konzept und sehr zu empfehlen ;-)',
    rating: 5,
    reviewer: 'Armando Koziner'
  },
  {
    date: '2021-11-08T19:34:30Z',
    title: 'Ich freue mich immer auf meine Blumen',
    review:
      'Ich freue mich immer auf meine Blumen. Toll zusammengestellt, halten lange, schöne Farben, außergewöhnliche Blumen dabei.',
    rating: 5,
    reviewer: 'Michael Bach'
  },
  {
    date: '2021-11-08T18:07:47Z',
    title: 'Ein sehr schöner frischer Strauss',
    review:
      'dieses Mal war es wieder ein sehr schöner Strauss (schwankt ja doch immer etwas und ist natürlich auch Geschmacksache). Auch die Frische also Qualität ist einwandfrei. \n\nMit DPD gab es wie fast jedes Mal etwas Verwirrung. Erst sollte an meinem Wunschliefertag nicht geliefert werden, dann hat es doch geklappt. Ende gut, alles gut!',
    rating: 5,
    reviewer: 'manuela'
  },
  {
    date: '2021-11-03T14:20:50Z',
    title: 'Wunderschöne Blumen',
    review:
      'Jeder Strauß ist wirklich wunderschön und lässt mich fast immer 14 Tage Freude daran haben. Die Qualität der Blumen ist meist ganz hervorragend!!! Klar zu empfehlen!!!',
    rating: 5,
    reviewer: 'Sarah'
  },
  {
    date: '2021-11-03T13:37:57Z',
    title: 'Immer wieder gerne',
    review:
      'Ich bestelle nun bestimmt schon über zwei Jahre regelmäßig bei bloomon. Immer bin ich sehr zufrieden.\nSelbst wenn durch den Versand etwas beschädigt war, hat mir das super freundliche Team im Kundenservice sofort geholfen. \nVasen würden ersetzt oder als durch den extremen Frost und das Abstellen vor dem Haus, die Blumen leider ruiniert waren (Fehler vom Kurierdienst und nicht von bloomon) hat man mir direkt einen neuen Strauß erstellt und die Kosten für den frostgeschädigten erstattet.\nAlso. Ich bin immer super happy mit meinem Strauß und kann nur jedem empfehlen es Mal zu probieren. Die Blumen sind gut gemischt. Auf diese Zusammenstellung käme man meist nicht selber. Allein dadurch habe ich immer etwas besonderes als Deko in meinem Wohnzimmer stehen. \n\nVielen Dank dafür',
    rating: 5,
    reviewer: 'Inga'
  },
  {
    date: '2021-11-01T18:19:14Z',
    title: 'Am Donnerstag kam',
    review:
      'Am Donnerstag kam, wie erwartet, mein 2. Blumenstrauss.\nLeider bin ich diesesmal nicht sehr zufrieden. Der Phlox war am nächsten Tag kaputt und die Gerbera musste ich heute, am Montag, also nach 3 Tagen,  stark einkürzen und in ein kleines Glas stellen.',
    rating: 4,
    reviewer: 'I.Kominek'
  },
  {
    date: '2021-10-10T09:56:09Z',
    title: 'Der Empfänger war von den herrlichen…',
    review:
      'Der Empfänger war von den herrlichen Blumenstrauß. Ein paar Euro mehr als bei der Konkurrenz aber doppelte Begeisterung',
    rating: 5,
    reviewer: 'Günter Münzloher'
  }
];
