
<div class="bw-carousel" [ngClass]="{'itemsSpaceBetween': spaceBetweenItems, 'container': fullPage, 'fullPage': fullPage, 'scrollSnap': scrollSnap}">
  <div class="bw-carousel__pagination-buttons" *ngIf="paginationButtons &amp;&amp; paginationButtons !== 'false' &amp;&amp; (viewportSizeIs$ | async)?.not.mobile &amp;&amp; carouselItemLenght &gt; itemsPerPage">
    <bw-button class="bw-carousel__pagination-previous" [iconOnly]="true" buttonStyle="secondary" size="large" (click)="scrollCarousel('left')" *ngIf="currentSlideIndex !== 0"><img src="/assets/brand-icons/arrow-right-black.svg"></bw-button>
    <bw-button class="bw-carousel__pagination-next" [iconOnly]="true" buttonStyle="secondary" size="large" (click)="scrollCarousel('right')" *ngIf="currentSlideIndex !== carouselItemLenght-itemsPerPage"><img src="/assets/brand-icons/arrow-right-black.svg"></bw-button>
  </div>
  <div class="bw-carousel__pagination-dots" *ngIf="(paginationDots &amp;&amp; paginationDots !== 'false') &amp;&amp; (viewportSizeIs$ | async)?.mobile">
    <bw-dot-pagination *ngIf="carouselItemLenght &gt; 1" [pages]="carouselItemLenght" [activePageIndex]="currentSlideIndex" theme="light"></bw-dot-pagination>
  </div>
  <div class="bw-carousel__items" [ngStyle]="getItemsStyles()" [ngClass]="{'row': spaceBetweenItems}">
    <ng-content></ng-content>
  </div>
</div>