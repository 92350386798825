
<form class="bwForm address-form" [formGroup]="form" bwExperimentEvent="addressEntryShow">
  <div>
    <div class="tight-form">
      <fieldset *ngIf="!hideNameField">
        <div class="form-group">
          <input class="form-control" (blur)="onFieldBlur(false)" type="text" autocomplete="off" name="name" id="recipient" formControlName="name" tabindex="0">
          <label class="wide" for="recipient">Recipient name</label>
          <bw-form-validation-icon></bw-form-validation-icon>
          <bw-formcontrol-validation-messages [errors]="form.get('name').errors" [touched]="form.get('name').touched" [formSubmitted]="formSubmitted">
            <div for="pattern">Please enter a full name</div>
            <div for="invalid">Name is required</div>
          </bw-formcontrol-validation-messages>
        </div>
      </fieldset>
      <fieldset>
        <div class="form-group">
          <input class="form-control" (blur)="onFieldBlur(true)" type="text" autocomplete="organization" name="company" id="company" formControlName="company" tabindex="0">
          <label class="wide" for="company">Company</label>
          <bw-form-validation-icon></bw-form-validation-icon>
        </div>
        <div class="form-group">
          <input class="form-control" (blur)="onFieldBlur(true)" type="text" autocomplete="address-line1" name="line1" id="line1" formControlName="line1" tabindex="0">
          <label class="wide" for="line1">Line 1</label>
          <bw-form-validation-icon></bw-form-validation-icon>
          <bw-formcontrol-validation-messages [errors]="form.get('line1').errors" [touched]="form.get('line1').touched" [formSubmitted]="formSubmitted">
            <div for="invalid">Required</div>
          </bw-formcontrol-validation-messages>
        </div>
        <div class="form-group">
          <input class="form-control" (blur)="onFieldBlur(true)" type="text" autocomplete="address-line2" name="line2" id="line2" formControlName="line2" tabindex="0">
          <label class="wide" for="line2" *ngIf="!showDoorCodeAsLine2Label">Line 2</label>
          <label class="wide" for="line2" *ngIf="showDoorCodeAsLine2Label">Door code / intercom (recommended)</label>
          <bw-form-validation-icon></bw-form-validation-icon>
        </div>
        <div class="form-group">
          <input class="form-control" (blur)="onFieldBlur(true)" type="text" autocomplete="locality" name="city" id="city" formControlName="city" tabindex="0">
          <label class="wide" for="city">City</label>
          <bw-form-validation-icon></bw-form-validation-icon>
          <bw-formcontrol-validation-messages [errors]="form.get('city').errors" [touched]="form.get('city').touched" [formSubmitted]="formSubmitted">
            <div for="invalid">Required</div>
          </bw-formcontrol-validation-messages>
        </div>
        <div class="form-group">
          <input class="form-control text-uppercase" (blur)="onFieldBlur(true)" type="text" autocomplete="postal-code" name="postcode" id="postcode" oninput="this.value = this.value.toUpperCase()" formControlName="postcode" tabindex="0">
          <label class="wide" *ngIf="!showAddressPostcodeAltCopy" for="postcode">Postcode</label>
          <label class="wide" *ngIf="showAddressPostcodeAltCopy" for="postcode">Eircode</label>
          <bw-form-validation-icon></bw-form-validation-icon>
          <bw-formcontrol-validation-messages *ngIf="!showAddressPostcodeAltCopy" [errors]="form.get('postcode').errors" [touched]="form.get('postcode').touched" [formSubmitted]="formSubmitted">
            <div for="pattern">Postcode appears to be invalid</div>
            <div for="invalid">Postcode is required</div>
          </bw-formcontrol-validation-messages>
          <!-- Alternative postcode copy for Ireland-->
          <bw-formcontrol-validation-messages *ngIf="showAddressPostcodeAltCopy" [errors]="form.get('postcode').errors" [touched]="form.get('postcode').touched" [formSubmitted]="formSubmitted">
            <div for="pattern">Eircode appears to be invalid</div>
            <div for="invalid">Eircode is required</div>
          </bw-formcontrol-validation-messages>
        </div>
        <div class="form-group" *ngIf="showAddressPhone">
          <input class="form-control has-hint form-control--prefix-dropdown" [ngClass]="{'has-two-line-hint' : getTranslationStringLength('js.components.address-form.phone.helper_new') &gt; 75}" (blur)="onFieldBlur(true); phoneBlur()" type="tel" autocomplete="phone" [placeholder]="telephonePlaceholderPrefix" name="phone" id="phone" formControlName="phone" tabindex="0">
          <label class="wide" *ngIf="addressPhoneRequired" for="phone">Phone number (mandatory)</label>
          <label class="wide" *ngIf="!addressPhoneRequired" for="phone">Phone number (recommended)</label><small class="form-text text-muted">{{getTranslationString('js.components.address-form.phone.helper_new')}}</small>
          <div class="prefix">
            <div class="bwFlag" ngClass="bwFlag--{{ address.country.codes[0] }}"></div>
          </div>
          <bw-form-validation-icon></bw-form-validation-icon>
          <bw-formcontrol-validation-messages [errors]="form.get('phone').errors" [touched]="form.get('phone').touched" [formSubmitted]="formSubmitted">
            <div for="invalid">Phone number appears to be invalid</div>
          </bw-formcontrol-validation-messages>
        </div>
        <div class="form-group" *ngIf="countries.length == 1"><span class="form-control">
            <label class="wide" for="country">Country</label>
            <div>{{address.country.name}}</div></span></div>
        <div class="form-group" *ngIf="countries.length &gt; 1" dropdown #dropdown="bs-dropdown" [ngClass]="{'ng-focus' :  dropdown.isOpen}">
          <button class="form-control" dropdownToggle type="button" bwTrackAs="component.address-form.auto_key.string_13">
            <label class="wide" for="select">Country</label>
            <div>{{address.country.name}}</div>
          </button>
          <div class="form-items-select dropdown-menu" *dropdownMenu><a class="dropdown-item" href="javascript:void(0)" onclick="return false" *ngFor="let country of countries" (click)="selectCountry(country)">{{country.name}}</a></div>
          <div class="form-items-icon"><img src="/assets/brand-icons/chevron-down.svg"></div>
        </div>
      </fieldset>
    </div>
  </div>
</form>