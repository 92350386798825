import { Component, OnInit } from '@angular/core';
import { ModalComponent } from 'Shared/services/modal.service';

import { NgIf } from '@angular/common';
import { OasysButtonGroupModule, OasysButtonModule, OasysHeadingModule, OasysImageModule, OasysLayoutModule } from 'oasys-lib';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { StateName, StateService } from 'Shared/services/state.service';
import { UserService } from 'Shared/services/user.service';
import { LoyaltyService } from 'Shared/services/loyalty.service';

type ModalResolveData = RewardsConfirmationModalComponent;
type ModalRejectData = Error;
/* bw:view-encapsulation */
@Component({
  standalone: true,
  imports: [NgIf, OasysButtonModule, OasysLayoutModule, OasysButtonGroupModule, OasysHeadingModule, OasysImageModule],
  selector: 'bw-rewards-confirmation-modal',
  templateUrl: './rewards-confirmation-modal.component.html'
})
export class RewardsConfirmationModalComponent extends ModalComponent<ModalResolveData, ModalRejectData> implements OnInit {
  existingMember: boolean;
  heapData: {
    rewardsSignUpLocation: string;
    rewardsSignUpSource: string;
  };
  welcomePoints: number;

  constructor(
    private analyticsService: AnalyticsService,
    private stateService: StateService,
    private userService: UserService,
    private loyaltyService: LoyaltyService
  ) {
    super();
  }

  /**
   * On Init
   */
  ngOnInit(): void {
    this.trackHeapEvent('successfulRewardsJoinModal');
    this.loyaltyService.getLoyaltyWelcomePoints().then((points: number): void => {
      this.welcomePoints = points;
    });
  }
  /**
   * On cancel
   */
  onCancel(): void {
    this.trackHeapEvent('clickCloseJoinRewards');
    super.closeAsResolve(null);
  }

  /**
   * Track about click event
   */
  gotToRewards(): void {
    this.trackHeapEvent('clickRewardsHomeJoinRewards');
    this.stateService.go('account.rewards' as StateName);
    super.closeAsResolve(null);
  }

  /**
   * Go to homepage
   */
  goToShopNow(): void {
    this.trackHeapEvent('clickShopNowJoinRewards');
    this.stateService.go('checkout.base' as StateName);
    super.closeAsResolve(null);
  }
  /**
   * Track heap events
   * @param {string} eventName
   * @param {unknown[]} args
   */
  private trackHeapEvent(eventName: string, data?: object): void {
    const dataLocation = {
      rewardsShopLocation: 'signUpConfirmationModalRewards',
      rewardsMemberType: this.existingMember ? 'Existing' : 'New'
    };
    const user = this.userService.getUser();

    data = { user, ...data, ...this.heapData, ...dataLocation };
    this.analyticsService.trackInHeap(eventName, data);
  }
}
