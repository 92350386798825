import { Pipe, PipeTransform } from '@angular/core';
import { Address } from 'Shared/classes/address';
import { t } from 'Shared/utils/translations';
import { escapeHTML } from 'Shared/utils/escape-html';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {
  transform(
    address: Address,
    oneLine: boolean = false,
    postcodeBeforeCity: boolean = false,
    hideName: boolean = false,
    additionalClasses: {
      [key: string]: string[];
    } = {}
  ): string {

    const postcode = {value: address.postcode, additionalClasses: additionalClasses.postcode || []};
    const city =  { value: address.city, additionalClasses: additionalClasses.city || [] };

    const addressArr = [
      { value: hideName ? '' : address.name, additionalClasses: additionalClasses.name || [] },
      { value: address.company, additionalClasses: additionalClasses.company || [] },
      { value: address.line1, additionalClasses: additionalClasses.line1 || [] },
      { value: address.line2, additionalClasses: additionalClasses.line2 || [] },
      postcodeBeforeCity? postcode : city,
      postcodeBeforeCity? city : postcode,
      {
        additionalClasses: ['small', 'text-muted'],
        value: address.phone ? `(${address.phone})` : ''
      }
    ].filter((line) => {
      return (line.value || '').length;
    });

    if (!oneLine) {
      return addressArr
        .map((l, index) => {
          const classes = ['address-line']
            .concat(`address-${index}`, l.additionalClasses || [])
            .join(' ');
          const a = `<span class="${classes}">${escapeHTML(l.value)}</span>`;
          return a;
        })
        .join(',\n ');
    }

    return `<span class="address">${addressArr.map((l) => escapeHTML(l.value)).join(', ')}</span>`;
  }
}
