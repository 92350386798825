
<div class="purchase-basket" [ngClass]="{'spaceLimited' : spaceLimited}">
  <ui-stack stack_gap="default">
    <bw-order-summary *ngFor="let order of orders; let idx = index" [order]="order" [user]="user">
      <div class="content__actions" role="content-actions">
        <bw-button buttonStyle="text-link" bwTrackAs="checkout.payment.review.remove" (click)="removeOrder(order, idx)">Remove</bw-button>
        <bw-button *ngIf="!order.timeslot &amp;&amp; order?.product?.type !== 'digital_gift_voucher'" buttonStyle="text-link" bwTrackAs="checkout.payment.review.edit" (click)="editOrder(order)">Edit</bw-button>
      </div>
    </bw-order-summary>
  </ui-stack>
  <!-- Empty Basket-->
  <div *ngIf="!loading &amp;&amp; !orders.length">
    <div class="empty-basket">
      <h6>You have no items in your basket</h6>
      <div class="empty-basket__header">
        <bw-button buttonStyle="text-link" (click)="cancel.emit()" bwTrackAs="component.purchase-basket.auto_key.string_4">Continue shopping</bw-button>
      </div>
    </div>
  </div>
  <bw-loading-spinner *ngIf="loading"></bw-loading-spinner>
</div>