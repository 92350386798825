import { ContentfulImageComponent } from 'Content/components/contentful-image/contentful-image.component';
import { ContentfulPipeComponent } from 'Content/components/contentful-pipe/contentful-pipe.component';
import { ContentfulEventComponent } from 'Content/components/contentful-event/contentful-event.component';
import { HrefInterceptorComponent } from 'Shared/components/href-interceptor/href-interceptor.component';
import { ContactUsComponent } from 'Content/components/contact-us/contact-us.component';
import { SeoComponent } from 'Shared/components/seo/seo.component';
import { NewsletterSignupComponent } from 'Content/components/newsletter-signup/newsletter-signup.component';
import { OpenContentModalComponent } from 'Content/components/open-content-modal/open-content-modal.component';
import { QuickShopComponent } from 'Content/components/quick-shop/quick-shop.component';
import { SMSSignupComponent } from 'Content/components/sms-signup/sms-signup.component';
import { ReviewSummaryComponent } from 'Shared/components/review-summary/review-summary.component';
import { VideoComponent } from 'Shared/components/video/video.component';
import { VideoPlayerComponent } from 'Shared/components/video-player/video-player.component';
import { FormValidationIconComponent } from 'Shared/components/form-validation-icon/form-validation-icon.component';
import { FormValidationMessagesComponent } from 'Shared/components/form-validation-messages/form-validation-messages.component';
import { FormValidationMessageComponent } from 'Shared/components/form-validation-message/form-validation-message.component';
import { ContentfulFormComponent } from 'Content/components/contentful-form/contentful-form.component';
import { ContentfulFormControlComponent } from 'Content/components/contentful-form-control/contentful-form-control.component';
import { FlowerJournalBlogItemsComponent } from 'Content/components/flower-journal-blog-items/flower-journal-blog-items.component';
import { RedirectComponent } from 'Shared/components/redirect/redirect.component';
import { SeparatorBarComponent } from 'Shared/components/separator-bar/separator-bar.component';
import { reviewsOverviewComponent } from 'Content/components/reviews-overview/reviews-overview.component';
import { WhoWeAreComponent } from 'Content/components/who-we-are/who-we-are.component';
import { BwCollapsePanelComponent } from 'Content/components/bw-collapse-panel/bw-collapse-panel.component';
import { ExperimentComponent } from 'Shared/components/experiment/experiment.component';
import { SocialNetworkBarComponent } from 'Content/components/social-network-bar/social-network-bar.component';
import { OccasionBannerComponent } from 'Content/components/occasion-banner/occasion-banner.component';
import { CountdownComponent } from 'Shared/components/countdown/countdown.component';
import { ReadMoreTriggerComponent } from 'Shared/components/read-more-trigger/read-more-trigger.component';
import { ReadLessTriggerComponent } from 'Shared/components/read-less-trigger/read-less-trigger.component';
import { ReviewsStatsComponent } from 'Shared/components/reviews-stats/reviews-stats.component';
import { ReviewsSlimBannerComponent } from 'Shared/components/reviews-slim-banner/reviews-slim-banner.component';
import { QuickShopVTwoComponent } from 'Content/components/quick-shop-v-two/quick-shop-v-two.component';
import { ReviewStarsComponent } from 'Shared/components/review-stars/review-stars.component';
import { ReviewCardComponent } from 'Shared/components/review-card/review-card.component';
import { ReviewsTestimonialsCarouselComponent } from 'Shared/components/reviews-testimonials-carousel/reviews-testimonials-carousel.component';
import { ReviewTestimonialCardComponent } from 'Shared/components/review-testimonial-card/review-testimonial-card.component';
import { TooltipContentComponent } from 'Content/components/tooltip-content/tooltip-content.component';
import { MetaBaseComponent } from 'Shared/components/meta-base/meta-base.component';
import { MetaLocalBusinessComponent } from 'Shared/components/meta-local-business/meta-local-business.component';
import { LocationReviewsComponent } from 'Shared/components/location-reviews/location-reviews.component';
import { InstagramFeedComponent } from 'Shared/components/instagram-feed/instagram-feed.component';
import { ReviewsDisplayComponent } from 'Shared/components/reviews-display/reviews-display.component';
import { NewsletterSignupClickRevealComponent } from 'Content/components/newsletter-signup-click-reveal/newsletter-signup-click-reveal.component';
import { GiftVoucherPickerComponent } from 'Content/components/gift-voucher-picker/gift-voucher-picker.component';
import { MentionMeLaunchRefereeComponent } from 'Shared/components/third-parties/mention-me-launch-referee/mention-me-launch-referee.component';
import { ChatWindowComponent } from 'Shared/components/chat-window/chat-window.component';
import { HelpCenterComponent } from 'Content/components/help-center/help-center.component';
import { OptOutRedirectComponent } from 'Content/components/opt-out-redirect/opt-out-redirect.component';
import { FooterBreadcrumbComponent } from 'Shared/components/footer-breadcrumb/footer-breadcrumb.component';
import { CookieContentComponent } from 'Shared/components/cookie-content/cookie-content.component';
import { ButtonComponent } from 'Shared/components/button/button.component';
import { HorizontalTabsComponent } from 'Project/shared/components/horizontal-tabs/horizontal-tabs.component';
import { VerticalTabsComponent } from 'Shared/components/vertical-tab/vertical-tabs.component';
import { HelpCenterHomepageComponent } from 'Account/components/help-center/help-center-homepage/help-center-homepage.component';
import { AlertComponent } from 'Shared/components/alert/alert.component';
import { ModularContentProductCardComponent } from 'Browse/components/modular-content-product-card/modular-content-product-card.component';
import { FeaturedProductsCarouselComponent } from 'Browse/components/featured-products-carousel/featured-products-carousel.component';
import { BlogsLandingPageComponent } from 'Content/components/blogs-landing-page/blogs-landing-page.component';
import { SplashCategoriesComponent } from 'Shared/components/splash-categories/splash-categories.component';
import { VideoWrapperV2Component } from 'Shared/components/video-wrapper-v2/video-wrapper-v2.component';

import {
  ImageComponent,
  OasysHeadingComponent,
  LayoutContainerComponent,
  LayoutGridComponent,
  LayoutGridColumnComponent,
  IconComponent,
  LayoutBoxComponent,
  LayoutStackComponent,
  OasysButtonComponent,
  HeroComponent,
  BreadcrumbsComponent,
  DividerComponent,
  SeoBlockComponent,
  SectionComponent,
  OasysAlertComponent,
  CardComponent,
  CarouselComponent,
  BannerComponent,
  OasysButtonGroupComponent,
  ChipComponent
} from 'oasys-lib';
import { ContentfulOpenModalComponent } from 'Content/components/contentful-open-modal/contentful-open-modal.component';
import { AppsBannerComponent } from 'Content/components/apps-banner/apps-banner.component';
import { MetaFaqComponent } from 'Shared/components/meta-faq/meta-faq.component';

/**
 * Components available via content HTML, managed in contentful
 */
export const availableComponents: any[] = [
  ContentfulImageComponent,
  ContentfulPipeComponent,
  ContentfulEventComponent,
  HrefInterceptorComponent,
  ContactUsComponent,
  HelpCenterComponent,
  SeoComponent,
  NewsletterSignupComponent,
  NewsletterSignupClickRevealComponent,
  OpenContentModalComponent,
  QuickShopComponent,
  QuickShopVTwoComponent,
  SMSSignupComponent,
  ReviewSummaryComponent,
  VideoComponent,
  VideoPlayerComponent,
  VideoWrapperV2Component,
  FormValidationIconComponent,
  FormValidationMessagesComponent,
  FormValidationMessageComponent,
  ContentfulFormComponent,
  ContentfulFormControlComponent,
  FlowerJournalBlogItemsComponent,
  RedirectComponent,
  SeparatorBarComponent,
  reviewsOverviewComponent,
  WhoWeAreComponent,
  BwCollapsePanelComponent,
  ExperimentComponent,
  SocialNetworkBarComponent,
  OccasionBannerComponent,
  CountdownComponent,
  ReadMoreTriggerComponent,
  ReadLessTriggerComponent,
  ReviewsStatsComponent,
  ReviewsSlimBannerComponent,
  ReviewStarsComponent,
  ReviewCardComponent,
  ReviewsTestimonialsCarouselComponent,
  ReviewTestimonialCardComponent,
  TooltipContentComponent,
  MetaBaseComponent,
  MetaLocalBusinessComponent,
  LocationReviewsComponent,
  InstagramFeedComponent,
  ReviewsDisplayComponent,
  MentionMeLaunchRefereeComponent,
  ChatWindowComponent,
  OptOutRedirectComponent,
  FooterBreadcrumbComponent,
  CookieContentComponent,
  ButtonComponent,
  HorizontalTabsComponent,
  VerticalTabsComponent,
  HelpCenterHomepageComponent,
  AlertComponent,
  ModularContentProductCardComponent,
  ContentfulOpenModalComponent,
  ImageComponent,
  LayoutContainerComponent,
  LayoutGridComponent,
  LayoutGridColumnComponent,
  LayoutBoxComponent,
  LayoutStackComponent,
  OasysButtonComponent,
  OasysHeadingComponent,
  IconComponent,
  HeroComponent,
  BreadcrumbsComponent,
  DividerComponent,
  SeoBlockComponent,
  SectionComponent,
  OasysAlertComponent,
  CardComponent,
  CarouselComponent,
  FeaturedProductsCarouselComponent,
  BannerComponent,
  AppsBannerComponent,
  OasysButtonGroupComponent,
  ChipComponent,
  BlogsLandingPageComponent,
  GiftVoucherPickerComponent,
  MetaFaqComponent,
  SplashCategoriesComponent
];
