import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { StateService } from 'Shared/services/state.service';
import { ActivatedState } from 'Shared/classes/activated-state';

@Injectable()
export class HasGreetingCardsGuard implements CanActivate {
  constructor(private stateService: StateService) {}

  /**
   * Checks if greeting cards passed
   * @returns {boolean}
   */
  canActivate(): Promise<boolean> {
    const toState = this.stateService.getTo<'checkout.greetingCardsGrid'>();
    const stateData = ((toState.data && toState.data.data) || {}) as ActivatedState<'checkout.greetingCardsGrid'>['data']['data'];

    if (!stateData?.addons?.length) {
      this.stateService.go('checkout.base');
    }

    return Promise.resolve(true);
  }
}
