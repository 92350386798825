import { Component, OnInit, Input, AfterViewInit, HostListener, ElementRef } from '@angular/core';
import { ViewportDetectionService } from 'Shared/services/viewport-detection.service';
import { WindowRefService } from 'Shared/services/window.service';
import { BehaviorSubject } from 'rxjs';

type scrollDirection = 'left' | 'right';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-carousel',
  templateUrl: './carousel.component.html'
})
export class CarouselComponent implements OnInit, AfterViewInit {
  @Input() verticalAlignment: 'flex-start' | 'center' | 'flex-end';
  @Input() itemsPerPageDesktop: number;
  @Input() itemsPerPageTablet: number;
  @Input() itemsPerPageMobile: number;
  @Input() spaceBetweenItems: boolean | string;
  @Input() paginationButtons: boolean | string;
  @Input() paginationDots: boolean | string;
  @Input() fullPage: boolean = true;
  @Input() automaticItemWidth: boolean = false;
  @Input() scrollSnap: boolean = true;

  document: any;
  mainCarousel: any;
  genericCarouselItem: any;
  firstItemCarousel: any;
  totalItemsNumber: number;
  itemsContainer: any;
  currentSlideIndex: number = 0;
  itemWidth: number;
  carouselItemLenght: number;
  deviceType: any;
  itemsPerPage: number;
  itemSpacing: number;
  scrollDelayBeforeAdjustment: number = 150;
  viewportSizeIs$: BehaviorSubject<any> = this.viewPortDetectionService.viewportSizeIs$;

  constructor(
    private windowRef: WindowRefService,
    private viewPortDetectionService: ViewportDetectionService,
    private elementRef: ElementRef
  ) {
    this.document = this.windowRef.nativeWindow.document;
  }

  scrollCarousel(scrollDirection: scrollDirection): void {
    if (scrollDirection === 'left' && this.currentSlideIndex > 0) {
      this.currentSlideIndex--;
    }
    if (
      scrollDirection === 'right' &&
      this.currentSlideIndex < this.getCarouselItemsLenght() - this.itemsPerPage
    ) {
      this.currentSlideIndex++;
    }
    this.scrollToCorrectItem();
  }

  scrollToCorrectItem(): void {
    this.itemsContainer.scrollTo({
      left: this.genericCarouselItem.offsetWidth * this.currentSlideIndex,
      behavior: 'smooth'
    });
  }

  setupSwipeDetect(): void {
    // This logic doesn't work as expected. Causing a bug in recently-viewed thus adding the
    // !scrollSnap logic
    // I have flagged that we need to iterate here to get it up to standard

    if (!this.itemsContainer || !this.scrollSnap) {
      return;
    }

    let initialPositionFirstItem = this.firstItemCarousel.getBoundingClientRect().x;
    let maxScrollableAmount =
      this.genericCarouselItem.getBoundingClientRect().width *
      (this.totalItemsNumber - this.itemsPerPage);

    let timer = null;
    this.itemsContainer.addEventListener(
      'scroll',
      () => {
        if (timer !== null) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          this.onScrollOver(maxScrollableAmount, initialPositionFirstItem);
        }, this.scrollDelayBeforeAdjustment);
      },
      false
    );
  }

  onScrollOver(maxScrollableAmount, initialPositionFirstItem): void {
    let finalPositionFirstItem = this.firstItemCarousel.getBoundingClientRect().x;
    let amountScrolled = Math.abs(finalPositionFirstItem - initialPositionFirstItem);
    let amountScrolledPercentage = (amountScrolled / maxScrollableAmount) * 100;
    this.currentSlideIndex = Math.round(
      ((this.totalItemsNumber - this.itemsPerPage) / 100) * amountScrolledPercentage
    );
    this.scrollToCorrectItem();
  }

  setupCarouselStyle(): void {
    if (this.automaticItemWidth) {
      return;
    }

    // Setup items per page per device on resize
    this.setupStyleByDevice();

    const parentElement = this.elementRef.nativeElement;
    let carouselWidth = parentElement.getBoundingClientRect().width;
    const rootElement = this.windowRef.nativeWindow.document.documentElement;

    const w = this.windowRef.nativeWindow
      .getComputedStyle(rootElement)
      .getPropertyValue('--ui__gutter-width');

    this.itemSpacing = parseInt(w) * 2;
    carouselWidth = carouselWidth - this.itemSpacing;

    this.elementRef.nativeElement.style.setProperty(
      '--carousel-width',
      `${carouselWidth + this.itemSpacing}px`
    );

    this.itemWidth =
      (carouselWidth - this.itemSpacing * (this.itemsPerPage - 1)) / this.itemsPerPage;

    this.itemWidth = this.itemWidth + this.itemSpacing / this.itemsPerPage;

    parentElement.style.setProperty('--item-width', `min(100%, ${this.itemWidth}px)`);
  }

  getCarouselItemsLenght(): number {
    return this.getCarouselItems().length;
  }

  getCarouselItems(): any {
    return this.elementRef.nativeElement.querySelectorAll(`.bw-carousel__item`);
  }

  setupGlobalProperties(): void {
    this.mainCarousel = this.elementRef.nativeElement.querySelector('.bw-carousel');
    this.itemsContainer = this.mainCarousel.querySelector('.bw-carousel__items');

    this.firstItemCarousel = this.getCarouselItems()[0];
    // we get the second element(when possible) because the first item has a lot of padding-left if the carousel is overflowing the page
    this.genericCarouselItem = this.getCarouselItems()[1] || this.firstItemCarousel;
    this.totalItemsNumber = this.getCarouselItemsLenght();
  }

  setupStyleByDevice(): void {
    this.deviceType = this.viewPortDetectionService.viewportSizeIs$.getValue();

    if (this.deviceType.desktop) {
      this.itemsPerPage = this.itemsPerPageDesktop;
    }
    if (this.deviceType.largeTablet || this.deviceType.mediumTablet) {
      this.itemsPerPage = this.itemsPerPageTablet;
    }
    if (this.deviceType.mobile) {
      this.itemsPerPage = this.itemsPerPageMobile;
    }
  }

  getItemsStyles(): any {
    return {
      'align-items': this.verticalAlignment
    };
  }

  @HostListener('window:resize')
  onResize(): void {
    this.setupCarouselStyle();
  }

  ngOnInit(): any {
    this.setupStyleByDevice();
  }

  ngAfterViewInit(): any {
    this.setupGlobalProperties();
    this.setupCarouselStyle();
    this.setupSwipeDetect();

    // this is needed for pagination
    this.carouselItemLenght = this.getCarouselItemsLenght();
  }
}
