import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { t } from 'Shared/utils/translations';

@Pipe({
  name: 'fromNow'
})
export class FromNowPipe implements PipeTransform {
  dayjs: any;
  constructor() {
    dayjs.extend(relativeTime);
    this.dayjs = dayjs;
  }

  transform(date: dayjs.Dayjs): any {
    if (!dayjs.isDayjs(date)) {
      date = dayjs(date);
    }
    if (date.format('YYYY-MM-DD') === this.dayjs(new Date()).format('YYYY-MM-DD')) {
      return t('js.pipe.moments-ago');
    }

    return this.dayjs(date).fromNow();
  }
}
