
<div class="product-addon-list">
  <!-- Modal Header-->
  <div class="modal-header">
    <div class="modal-title">
      <h4>Pairs perfectly with…</h4>
      <p class="hidden-xs" *ngIf="shopType === 'default'">Make it feel even more special.</p>
      <p class="hidden-xs" *ngIf="shopType === 'subscription'">Sized perfectly for your subscription</p>
    </div>
    <button class="close" aria-label="close" (click)="onCancel()"><span aria-hidden="true">&times;</span></button>
  </div>
  <!-- Modal Body-->
  <div class="modal-body" #bwModal>
    <!-- Default template-->
    <div class="product-addon-list__content row" *ngIf="shopType === 'default'">
      <div class="product-addon-list__section product-addon-list__section--product col-md-4 col-xs-12">
        <div class="product-addon-list__product"><img class="product__img" [bwEcommImage]="product | addonCombinationImage:selectedAddons" width="150" height="auto">
          <div class="product__contents">
            <p class="product__label">Your gift</p>
            <!-- Product name-->
            <p class="product__name">{{ product.name }}</p>
            <!-- Addon with label-->
            <div class="product__addon" *ngIf="addonRequirement.min">
              <ng-container *ngFor="let addon of (selectedAddons | addonType:[addonRequirement.kind]); let addonIndex = index;"><span *ngIf="!addonIndex">with {{ addon.name }}&nbsp;</span><span *ngIf="addonIndex">and {{ addon.name }}&nbsp;</span></ng-container>
            </div>
            <!-- Price-->
            <div class="product__price" *ngIf="!addonRequirement.min">
              <p class="product__price--original" *ngIf="product.price?.discount">{{product.price | price:'original'}}</p>
              <p class="product__price--price">{{ product.price | price}}</p>
            </div>
            <div class="product__price" *ngIf="addonRequirement.min">
              <p class="product__price--original" *ngIf="product.price?.discount">{{product.price | price:'original'}}</p>
              <p class="product__price--price">{{ product.price | price}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="product-addon-list__section product-addon-list__section--addon col-md-8 col-xs-12">
        <p class="product-addon-list__addon-label hidden-sm hidden-md hidden-lg">Make it feel even more special.</p>
        <div class="product-addon-list__addon">
          <div class="product-addon-list__addon-item" *ngFor="let addon of (addons | addonType:[addonRequirement.kind]); let addonIndex = index; trackBy:addonTrackByFn">
            <bw-addon-card [addon]="addon" [addonRequirement]="addonRequirement" (addonSelected)="selectAddon($event)" (addonRemoved)="deselectAddon($event)" intersectionObserverEvents observerRootMargin="0px 0px 0px 0px" [observerThreshold]="0.5" (hasEntered)="onAddonImpression(addon, addonIndex + 1)"></bw-addon-card>
          </div>
        </div>
      </div>
    </div>
    <!-- Subs template-->
    <div class="product-addon-list__content row" *ngIf="shopType === 'subscription'">
      <div class="product-addon-list__section product-addon-list__section--product col-md-4 col-xs-12">
        <div class="product-addon-list__product"><img class="product__img" [bwEcommImage]="product | addonCombinationImage:selectedAddons" width="150" height="auto">
          <div class="product__contents">
            <p class="product__label">Your subscription</p>
            <p class="product__name">{{ product.name }}</p>
          </div>
          <div class="product__price" *ngIf="!addonRequirement.min">
            <p class="product__price--original" *ngIf="product.price?.discount">{{product.price | price:'original'}}</p>
            <p class="product__price-price">{{ product.price | price}}</p>
          </div>
          <div class="product__price" *ngIf="addonRequirement.min">
            <p class="product__price--original" *ngIf="product.price?.discount">{{product.price | price:'original'}}</p>
            <p class="product__price--price">{{ product.price | price}}</p>
          </div>
        </div>
      </div>
      <div class="product-addon-list__section product-addon-list__section--addon col-md-8 col-xs-12">
        <p class="product-addon-list__addon-label hidden-sm hidden-md hidden-lg">Sized perfectly for your subscription</p>
        <div class="product-addon-list__addon">
          <div class="product-addon-list__addon-item" *ngFor="let addon of (addons | addonType:[addonRequirement.kind]); let addonIndex = index; trackBy:addonTrackByFn">
            <bw-addon-card [addon]="addon" [addonRequirement]="addonRequirement" [shopType]="'subscription'" (addonSelected)="selectAddon($event)" (addonRemoved)="deselectAddon($event)" intersectionObserverEvents observerRootMargin="0px 0px 0px 0px" [observerThreshold]="0.5" (hasEntered)="onAddonImpression(addon, addonIndex + 1)"></bw-addon-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Footer-->
  <div class="modal-footer product-addon-list__footer">
    <p class="product-addon-list__price hidden-xs">Order total: <strong>{{ price | price }}</strong></p>
    <p class="product-addon-list__price hidden-sm hidden-md hidden-lg">Order: <strong>{{ price | price }}</strong></p>
    <bw-button *ngIf="(selectedAddons | addonType:[addonRequirement.kind]).length" buttonStyle="primary" size="medium" [fullWidth]="true" (click)="onSubmit()" bwTrackAs="component.product-addon-list-modal.cta-addon">Continue to checkout</bw-button>
    <bw-button *ngIf="!(selectedAddons | addonType:[addonRequirement.kind]).length" buttonStyle="secondary" size="medium" [fullWidth]="true" (click)="onSubmit()" bwTrackAs="component.product-addon-list-modal.cta-no-addon">Continue without adding</bw-button>
  </div>
</div>
<bw-loading-spinner *ngIf="isLoading"></bw-loading-spinner>