
<div class="bw-auth-third-party">
  <ng-container *featureEnabled="'FACEBOOK_ACCESS'">
    <ng-container *featureEnabled="'GOOGLE_ACCESS'">
      <div class="third-party__separator" *ngIf="showSeparator">or</div>
    </ng-container>
  </ng-container>
  <ui-stack stack_gap="tight" stack_distribute="fill">
    <ui-box *featureEnabled="'FACEBOOK_ACCESS'" box_space="none">
      <bw-button buttonStyle="facebook" size="medium" (clicked)="onFbAction(selectedTab)" [fullWidth]="true" [state]="(cookieConsent$ | async)?.preferences ? 'default' : 'disabled'" bwTrackAs="component.auth-modal.login-fb">
        <ng-container *ngIf="selectedTab === 'login'"><span>Continue with facebook</span></ng-container><span *ngIf="selectedTab === 'register'">Register with Facebook</span>
      </bw-button>
    </ui-box>
  </ui-stack>
  <div class="third-party__no-cookies" *ngIf="showCookieConsent" [hidden]="(cookieConsent$ | async)?.preferences">
    <ng-container *ngIf="selectedTab === 'login'">To login via Facebook please enable Preference cookies <a class="bwLink" (click)="renewCookieConsent()">here</a></ng-container>
    <ng-container *ngIf="selectedTab === 'register'">To signup via Facebook please enable Preference cookies <a class="bwLink" (click)="renewCookieConsent()">here</a></ng-container>
  </div>
</div>