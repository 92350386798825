import { Component, AfterViewChecked, ViewEncapsulation, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { StateService } from 'Shared/services/state.service';
import { ConfigService } from 'Shared/services/config.service';
import { CountryService, Country } from 'Shared/services/country.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-keep-shopping',
  templateUrl: './keep-shopping.component.html',
  animations: [
    trigger('shopAgain', [
      transition('* => in', [
        style({ opacity: 0, height: '0px' }),
        animate(`1.0s 3s ease-out`, style({ opacity: 1, height: '*' }))
      ]),
      transition('* => out', [style({ height: '0px' })])
    ])
  ]
})
export class KeepShoppingComponent implements AfterViewChecked, OnInit {
  state: string = 'out';
  discountCode: string;
  country: Country;
  constructor(
    private stateService: StateService,
    private configService: ConfigService,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {
    const config = this.configService.getConfig();
    this.discountCode = config.discountCode;
    this.country = this.countryService.forShipping;
  }

  keepShoppingAndSaveFundz(): void {
    this.stateService.go('checkout.base', { discountCode: this.discountCode });
  }

  ngAfterViewChecked(): void {
    this.stateService.onSuccess$.subscribe(routerTransition => {
      const toState = routerTransition.to;
      if (toState.name === 'checkout.confirmation') {
        this.state = 'in';
      } else {
        this.state = 'out';
      }
    });
  }
}
