import * as clone from 'clone';
import * as dayjs from 'dayjs';
import { Address } from 'Shared/classes/address';
import { Delivery } from 'Shared/classes/delivery';
import { Product } from 'Shared/classes/product';
import { Price } from 'Shared/classes/price';
import { Timeslot } from 'Shared/classes/timeslot';
import { ShippingOption } from 'Shared/classes/shipping-option';
import { Discount } from 'Shared/classes/discount';
import { UpsoldFrom } from 'Shared/classes/upsell-option';
import { Addon } from 'Shared/classes/addon';
import { GiftCard } from 'Shared/classes/gift-card';
import { GridProduct } from 'Shared/classes/grid-product';
import { Card } from 'Shared/classes/card';
import { PaymentOptionType, SubscriptionPaymentOption } from './subscription-payment-option';

export class Order {
  public id: number;
  public purchaseId: number;
  public indexInPurchase: number;
  public createdAt: dayjs.Dayjs;
  public address: Address;
  public firstDeliveryDate: dayjs.Dayjs;
  public nextDelivery: Delivery;
  // TODO: remove nextDeliveryDate, nextDeliveryPrice and fallback to nextDelivery
  public nextDeliveryDate: dayjs.Dayjs;
  public nextDeliveryPrice: Price;
  public deliveries: Delivery[];
  public imageUrls: string[] = [];
  public product: Product | GridProduct;
  public upsoldFrom: UpsoldFrom;
  public addons: Addon[] | undefined = [];
  public discount: Discount;
  public discounts: Discount[]; // Holds active discounts -> exhausted discounts won't be included
  public type: OrderType | string;
  public isLilyFree: boolean;
  public note: string;
  public giftCard: GiftCard;
  public timeslot: Timeslot;
  public isSelfPurchase: boolean;
  public isSensitive: boolean;
  public token: string;

  public frequency: number;
  public duration: number;
  public quantity: number;

  public state: string;
  public backendState: string;
  public deleted: boolean;
  public shippingOption: ShippingOption;

  public price: Price; // Not set during checkout - set for completed orders
  public subscription?: Subscription;
  public applicableRulesDescription?: string;

  public skippedDeliveriesCount?: number = 0;
  public activeDeliveriesCount?: number = 0;

  public paymentOptions?: SubscriptionPaymentOption;

  public loyaltyPoints: number | undefined;
  public loyaltyBonusPointsCampaignId: number | undefined;

  /**
   * Flag to check if subscrition has prepaid deliveries with a prepaid payment option
   * User can have multiple prepay options
   * @returns
   */
  hasPrepaidDeliveries(): boolean {
    return this.paymentOptions && this.paymentOptions.getOptionsByType(PaymentOptionType.prepayment).length > 0;
  }

  /**
   * Calculate the price
   * @param options
   */
  static calculateOrderTotal(options: {
    product?: Product;
    addons?: Addon[];
    shippingOption?: ShippingOption;
    numberOfDeliveries?: number; // Or Frequency and Duration
    frequency?: number;
    duration?: number;
  }): Price {
    let numberOfDeliveries = options.numberOfDeliveries ?? 1;
    if (!options.numberOfDeliveries && options.duration && options.frequency) {
      const foundPrice = options.product.getPriceFor(options.duration, options.frequency);
      numberOfDeliveries = foundPrice ? foundPrice.quantity : 1;
    }

    const prices: Price[] = [];

    // First product
    if (options.product && (options.product.pricing || []).length) {
      const productPrice = options.product.getPrice(numberOfDeliveries);
      if (productPrice) {
        prices.push(productPrice);
      }
    }

    // Then any addons
    (options.addons ?? []).forEach((addon): void => {
      const addonPrice = addon?.getPrice() ?? undefined;
      if (addonPrice) {
        prices.push(addonPrice);
      }
    });

    // Finally, the shipping options
    const shippingOption = options.shippingOption;
    if (shippingOption) {
      const shipPrice = options.shippingOption.getPriceForNumberOfDeliveries(numberOfDeliveries);
      prices.push(shipPrice);
    }
    const price = Price.add(prices);
    price.quantity = numberOfDeliveries;
    return price;
  }

  /**
   * Has the order  been "completed"
   */
  isComplete(serverTime: dayjs.Dayjs): boolean {
    // If we can find a delivery that is NOT Complete, then the whole order ISN'T complete
    const incompleteDeliveries = (this.deliveries || []).filter((d): boolean => !d.isComplete(serverTime));
    if (this.type === 'subscription') {
      return this.state === 'cancelled' && incompleteDeliveries.length === 0;
    }
    return incompleteDeliveries.length === 0;
  }

  /**
   * Get the greeting card addon
   * @returns
   */
  getGreetingCardAddon(): Addon | undefined {
    if (this.giftCard && this.giftCard.cover) {
      return this.giftCard.cover;
    }

    return this.addons?.find((a): boolean => a.isGreetingCardType());
  }

  /**
   * Set type
   * @param type
   */
  setType(type: string): void {
    this.type = type.toLowerCase();
  }

  /**
   * Set order type using frequency and duration
   * if frequency is 0, we have 'oneoff'
   * if duration is -1 we have ongoing subscription
   * @param frequency number
   * @param duration number
   */
  setOrderType(frequency: number, duration: number): void {
    let orderType = OrderType.OneOff;

    if (frequency !== 0) {
      orderType = duration === -1 ? OrderType.Subscription : OrderType.Bundle;
    }

    this.type = orderType;
  }

  /**
   * Set the Product
   * @param product
   * @param upsoldFrom
   */
  setProduct(product?: Product, upsoldFrom?: UpsoldFrom): void {
    this.product = product;

    this.upsoldFrom = upsoldFrom;
  }

  /**
   * Set state
   * @param state
   */
  setState(state: string): void {
    this.state = (state || '').toLowerCase();
  }

  /**
   * Get the deliveries for the past or future
   * @param type
   */
  getDeliveries(type: 'past' | 'future'): Delivery[] {
    const today = dayjs();
    if (type === 'past') {
      return this.deliveries.filter((d): boolean => d.isComplete(today)).sort((a, b): number => b.date.unix() - a.date.unix());
    }
    return this.deliveries.filter((d): boolean => !d.isComplete(today)).sort((a, b): number => a.date.unix() - b.date.unix());
  }

  getDeliveriesWithoutState(state: string): Delivery[] {
    return this.deliveries.filter((d): boolean => d.backendState !== state);
  }

  /**
   * Set the date
   * @param date DayJs compatible object
   */
  setFirstDelivery(date: dayjs.Dayjs | string): void {
    const d = dayjs(date);
    this.firstDeliveryDate = d.isValid() ? d : undefined;
  }

  /**
   * Set the next delivery date
   * @param date
   */
  setNextDelivery(date: dayjs.Dayjs | string): void {
    const d = dayjs(date);
    this.nextDeliveryDate = d.isValid() ? d : undefined;
  }

  /**
   * Set the timeslot
   * @param time
   */
  setTimeslot(time: string, endTime?: string): void {
    this.timeslot = new Timeslot();
    this.timeslot.setStart(time);
    this.timeslot.setEnd(endTime);
  }

  /**
   * An order is editable, if one of it's deliveries is editable and it's not express
   */
  isEditable(): boolean {
    return !!this.deliveries.find((d): boolean => d.isEditable());
  }

  /**
   * An order is cancellable if one of it's deliveries is editable and it's not express
   */
  isCancellable(): boolean {
    if (this.type !== 'oneoff') {
      return false;
    }

    const hasCancellableDelivery = !!this.deliveries.find((d): boolean => d.isCancellable());

    return !this.stateIs('cancelled') && hasCancellableDelivery;
  }

  /**
   * State is
   * @param type
   */
  stateIs(type: string): boolean {
    return this.state === type;
  }

  /**
   * Set created at
   * @param date
   */
  setCreatedAt(date: dayjs.Dayjs | string): void {
    this.createdAt = dayjs(date);
  }

  /**
   * Get the price for the order, with or without shipping
   * @param withShipping
   */
  getPrice(withShipping: boolean = true, withAddon: boolean = true): Price {
    return Order.calculateOrderTotal({
      numberOfDeliveries: this.quantity,
      frequency: this.frequency,
      duration: this.duration,
      product: this.product,
      addons: withAddon ? this.addons : [],
      shippingOption: withShipping ? this.shippingOption : undefined
    });
  }

  /**
   * Clone the order
   */
  clone(): Order {
    return clone(this);
  }

  getTrackedDurationName(): string {
    return this.product ? this.product.getTrackedDurationName(this.duration) : '';
  }

  /**
   * If it's an ongoing-subscription
   */
  isSubscription(): boolean {
    return this.product.subscriptionOnly || this.duration < 1;
  }

  getTotalDeliveries(): number {
    return this.product.getTotalDeliveries(this.duration, this.frequency);
  }

  /**
   * Handle fetch index discount
   * Defaults to first discount
   * @param index
   * @returns {Discount}
   */
  getDiscount(index = 0): Discount {
    return this.discounts ? this.discounts[index] : undefined;
  }

  /**
   * Get last upcoming delivery
   * @returns {Delivery}
   */
  getLastUpcomingDelivery(): Delivery {
    return this.deliveries?.[this.deliveries?.length - 1] ?? undefined;
  }

  /**
   * @description Check if the product has rewards bonus points
   * @returns {boolean}
   */
  hasRewardsBonusPoints(): boolean {
    return !!this.product?.getLabel('rewards-bonus-points');
  }
}

export interface Subscription {
  id?: string;
  deliveryDayOfWeek?: number;
  isActive?: boolean;
  shippingOption?: ShippingOption;
  duration?: number;
  frequency?: number;
  useCredit?: boolean;
  price?: Price;
  card?: Card;
  failedPaymentsData?: FailedPaymentsData;
  nextDeliveryDate?: dayjs.Dayjs;
}

export enum OrderType {
  OneOff = 'oneoff',
  Bundle = 'bundle',
  Subscription = 'subscription'
}

export interface FailedPaymentsData {
  pausedDueToFailedPayment: boolean;
  activeWithFailedPayment: boolean;
  noValidPaymentMethod: boolean;
}

export interface SubscriptionPrepaymentOption {
  id: number;
  numberBouquets: number;
  description: string;
  name: string;
  expiryMonths: number;
}

export interface SubscriptionPrepaymentCharge {
  id: number;
  state: string;
  kind: string;
  price: Price;
  redirect: string;
  startDate: dayjs.Dayjs;
}

export interface PreviewDate {
  date: dayjs.Dayjs;
  scheduledDate: dayjs.Dayjs;
}
