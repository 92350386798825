import { Component, Input, OnChanges } from '@angular/core';
type Theme = 'dark' | 'light' | 'brand-tint';
@Component({
  selector: 'bw-dot-pagination',
  templateUrl: './dot-pagination.component.html',
  styleUrls: ['./dot-pagination.component.scss']
})
export class DotPaginationComponent implements OnChanges {
  @Input()
  pages: number;
  @Input()
  activePageIndex: number;
  @Input()
  theme: Theme = 'dark';

  pageItems: number[];

  constructor() {}

  update(): void {
    this.pageItems = Array.from(Array(this.pages).keys());
  }

  ngOnChanges(): void {
    this.update();
  }
}
