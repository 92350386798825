import { Price, CurrencyCode } from 'Shared/classes/price';

export class Availability extends Price {
  frequency: number;
  duration: number;
  perDelivery: Price;

  constructor(
    currency: CurrencyCode,
    quantity: number,
    original: number,
    options?: { discounted?: number; rewardPoints?: number; rewardPointsWithBonus?: number }
  ) {
    super(currency, quantity, original, options);
  }
}
