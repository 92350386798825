import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Dayjs } from 'dayjs';
import { Review } from 'Shared/classes/review';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-review-card',
  templateUrl: './review-card.component.html'
})
export class ReviewCardComponent implements OnInit {
  reviewData: Review;

  @Input()
  reviews: Review;
  @Input()
  maxlength: number;
  @Input()
  enableBreakPoints: boolean;
  @Input()
  review: string;
  @Input()
  date: Dayjs;
  @Input()
  reviewer: string;
  @Input()
  rating: number;
  @Input()
  source: string = 'reviews';
  @Input() linkToReview: string = '';
  @Input() reviewsrebrand: boolean = false;

  constructor() {}

  ngOnInit(): void {
    // for hardcoding reviews
    if (this.review && this.reviewer && this.rating) {
      const review = new Review(this.review, this.rating, this.reviewer);
      this.reviewData = this.setBreakPoints(review);
    } else {
      this.reviewData = this.setBreakPoints(this.reviews);
    }
  }

  private setBreakPoints(review: Review): Review {
    return Object.assign(review, {
      review: this.enableBreakPoints
        ? review.review.replace(/(?:\\[rn]|[\r\n]+)+/g, '<br /> <br />')
        : review.review.replace(/(?:\\[rn]|[\r\n]+)+/g, ' ')
    });
  }
}
