import * as dayjs from 'dayjs';

import { Injectable } from '@angular/core';
import { GridProduct } from 'Shared/classes/grid-product';
import { Subject } from 'rxjs';

export enum SortType {
  Delivery = 'delivery-date',
  PriceLowHigh = 'price-low-high',
  PriceHighLow = 'price-high-love'
}

@Injectable({
  providedIn: 'root'
})
export class SortService {
  shouldSortForKey$ = new Subject<string>();

  constructor() {}

  setSortType(sortType: string): void {
    this.shouldSortForKey$.next(sortType);
  }

  sortDate(products): GridProduct[] {
    const productsCopy = products.slice();
    const tomorrow = dayjs().startOf('day').add(1, 'day');

    const firstDateInFuture = productsCopy
      .slice()
      .sort((a, b) => (a.deliverableFrom || dayjs()).unix() - (b.deliverableFrom || dayjs()).unix())
      .find((p) => (p.deliverableFrom || dayjs()).isAfter(tomorrow));

    // if no dates in the future, just sort by delivery dates in past
    if (!firstDateInFuture) {
      return productsCopy.sort((a, b) => a.deliverableFrom.unix() - b.deliverableFrom.unix());
    }

    // If it's before today, use the default date instead
    return productsCopy.sort((a, b) => {
      const aToCompare = a.deliverableFrom.unix() <= tomorrow.unix() ? tomorrow.unix() : a.deliverableFrom.unix();
      const bToCompare = b.deliverableFrom.unix() <= tomorrow.unix() ? tomorrow.unix() : b.deliverableFrom.unix();
      return aToCompare - bToCompare;
    });
  }

  sortProducts(sortType: string, products: GridProduct[] | any): GridProduct[] {
    if (sortType === 'recommended') {
      return products.slice();
    }

    if (sortType === 'delivery-date') {
      return this.sortDate(products);
    }

    if (sortType === 'price-low-high') {
      const productsCopy = products.slice();
      const sortedProducts = productsCopy.sort((a, b): number => a.getPrice().price - b.getPrice().price);
      const oneOffAndBundles = sortedProducts.filter((product) => !product.subscriptionOnly);
      const subscriptions = sortedProducts.filter((product) => product.subscriptionOnly);
      return oneOffAndBundles.concat(...subscriptions);
    }

    if (sortType === 'price-high-low') {
      const productsCopy = products.slice();
      return productsCopy.sort((a, b): number => b.getPrice().price - a.getPrice().price);
    }

    return products;
  }

  mapSortingValues(value: string): string {
    const mapping = {
      recommended: undefined, // Passing nothing, the BE will fallback to sku ordering
      'delivery-date': 'delivery',
      'price-low-high': '-price',
      'price-high-low': 'price'
    };

    return mapping[value] ?? undefined;
  }
}
