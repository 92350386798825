
<label class="bw-form-checkbox-input" *ngIf="bwFormControl" [attr.for]="id" [ngClass]="{
  'input--size-small': size === 'small',
  'input--size-medium': size === 'medium',
  'input--size-large': size === 'large',
  'input--size-xlarge': size === 'xlarge',
  'input--align-left': align === 'left',
  'input--align-right': align === 'right',
  'is-checked': bwFormControl.value,
  'is-invalid': bwFormControl.invalid,
  'is-valid': bwFormControl.valid,
  'is-dirty': bwFormControl.dirty,
  'is-pristine': bwFormControl.pristine,
  'is-touched': bwFormControl.touched,
  'is-untouched': bwFormControl.untouched,
  'is-submitted': hasSubmitted,
  'has-label': labelText.length,
  'has-description': hasDescription,
  'is-disabled': disabled
  }">
  <div class="checkbox-input__container">
    <div class="checkbox-input__control">
      <bw-icon [iconType]="'tick__selected'"></bw-icon>
      <input class="bwForm-no-checkbox-styles" [formControl]="bwFormControl" type="checkbox" [attr.ariaLabel]="labelText" [attr.id]="id" [attr.name]="id" [attr.disabled]="disabled ? true : null" (change)="onChange()">
    </div>
  </div>
  <div class="checkbox-input__label">
    <ng-content select="[role=label]"></ng-content>
    <ng-content select="[role=text-label]"></ng-content>
    <ng-content select="[role=description]"></ng-content>
  </div>
</label>