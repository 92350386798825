import { Component, HostListener, ElementRef } from '@angular/core';
import { AnalyticsService } from 'Shared/services/analytics.service';

/**
 * Like the contentful-image component, without a template, this is basically a diretive
 * We can't use directives in the dynamic content viewer
 */
@Component({
  selector: '[bw-contentful-event]',
  template: '<ng-content></ng-content>'
})
export class ContentfulEventComponent {
  elem: HTMLElement;

  constructor(el: ElementRef, private analyticsService: AnalyticsService) {
    this.elem = el.nativeElement;
  }

  /**
   * On click
   */
  @HostListener('click')
  onClick(): void {
    const params = this.elem.getAttribute('bw-contentful-event').split('|');
    const key = params[0];

    if (key) {
      this.analyticsService.track(key, {
        category: params[1] || '',
        label: params[2] || ''
      });
    }
  }
}
