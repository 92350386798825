import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BwButtonStyle } from 'Shared/components/button/button';
import { ModuleTabsStyle, ModuleTab } from 'Shared/components/module-tabs/module-tabs';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-module-tabs',
  templateUrl: './module-tabs.component.html'
})
export class ModuleTabsComponent implements OnInit {
  @Input() mobuleTabsStyle: ModuleTabsStyle = 'primary';
  @Input() moduleTabs: ModuleTab[];

  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  activeStyle: BwButtonStyle;
  defaultStyle: BwButtonStyle;

  constructor() {}

  /**
   * Set active button after clicked
   * @param tabIndex
   */
  onClickEvent(tabIndex: number): void {
    this.moduleTabs.forEach((tabs) => (tabs.active = false));
    this.moduleTabs[tabIndex].active = true;
    this.moduleTabs[tabIndex].event();
    this.clicked.emit(true);
  }

  /**
   * Set style
   */
  setTabStyle(): void {
    // Set button styles for secondary style
    if (this.mobuleTabsStyle === 'secondary') {
      this.activeStyle = 'modal-quaternary';
      this.defaultStyle = 'modal-tertiary';
      return;
    }

    // Default values
    this.activeStyle = 'modal-primary';
    this.defaultStyle = 'modal-secondary';
  }

  /**
   * Set active tab
   */
  setActiveTab(): void {
    // Find if any tab is wanted to be set as default
    const moduletab = this.moduleTabs.find((tab) => tab.active);
    if (moduletab) {
      moduletab.event();
      return;
    }

    // Default if no active tab is set
    this.moduleTabs[0].active = true;
    this.moduleTabs[0].event();
    this.clicked.emit(true);
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.setTabStyle();
    this.setActiveTab();
  }
}
