import { Injectable } from '@angular/core';
import { Country } from 'Shared/classes/country';
import { Product } from 'Shared/classes/product';
import * as dayjs from 'dayjs';
import { ContentSegment } from 'Shared/models/segment-model.service';
import { Discount } from 'Shared/classes/discount';
import { ContentService } from 'Shared/services/content.service';
import { AddonModelService } from 'Checkout/models/addon-model.service';
import { Addon } from 'Shared/classes/addon';
import { Address } from 'Shared/classes/address';
import { ConfigService } from 'Shared/services/config.service';
import { DeliveryDateService } from 'Shared/services/delivery-date.service';
import { DeliveryDate } from 'Shared/classes/delivery-date';
import { Order, Purchase, PurchaseService } from 'Checkout/services/purchase.service';
import { ToastrService } from 'Project/toastr/services/toastr.service';
import { t } from 'Shared/utils/translations';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { LocationService } from 'Shared/services/location.service';
import { Error } from 'Shared/classes/error';
import { ProductAddonListModalComponent } from 'Checkout/components/product-addon-list-modal/product-addon-list-modal.component';
import { ModalService } from 'Shared/services/modal.service';
import { ArrayUtils } from 'Shared/utils/array-utils';

@Injectable({
  providedIn: 'root'
})
export class AddonService {
  // CO-2278 Cache information required for optimizely update of addon prices
  // TODO create a general store of local state for checkout process and store this info there
  selectedAddons$: BehaviorSubject<Addon[]> = new BehaviorSubject<Addon[]>([]);

  addons$: BehaviorSubject<Addon[] | null> = new BehaviorSubject<Addon[] | null>(null);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private addonModelService: AddonModelService,
    private contentSegmentService: ContentService,
    private configService: ConfigService,
    private deliveryDateService: DeliveryDateService,
    private toastr: ToastrService,
    private analyticsService: AnalyticsService,
    private purchaseService: PurchaseService,
    private locationService: LocationService,
    private modalService: ModalService
  ) {}

  /**
   * Get the addons for next available date, based on first shipping option available
   * @param country
   * @param product
   * @param alreadySelectedAddons
   * @param orderIndex
   * @param enableVaseAsKind
   * @param preferredDate
   * @param discount
   */
  getAddonsForDefaultDeliveryDate(
    country: Country,
    product: Product,
    alreadySelectedAddons: Addon[],
    orderIndex: number,
    enableVaseAsKind: boolean = false,
    preferredDate?: dayjs.Dayjs,
    discount?: Discount
  ): Promise<{
    deliveryDate: DeliveryDate;
    addons: Addon[];
  }> {
    const address = new Address();
    address.country = country;
    let deliveryDate: DeliveryDate;

    return this.deliveryDateService
      .getDefaultDeliveryDate(product, address, preferredDate)
      .then((d) => {
        deliveryDate = d;
        return this.getAddons(country, product, alreadySelectedAddons, deliveryDate.date, orderIndex, enableVaseAsKind, discount);
      })
      .then((addons) => {
        return Promise.resolve({
          addons,
          deliveryDate
        });
      })
      .catch(() => {
        return Promise.resolve({
          deliveryDate,
          addons: []
        });
      });
  }

  /**
   * Get the addons
   * @param country
   * @param product  - TODO: We could probably change all these params to just an 'order'
   * @param alreadySelectedAddons
   * @param deliveryDate
   * @param orderIndex
   * @param enableVaseAsKind
   * @param discount
   */
  getAddons(
    country: Country,
    product: Product,
    alreadySelectedAddons: Addon[],
    deliveryDate: dayjs.Dayjs,
    orderIndex: number,
    enableVaseAsKind: boolean = false,
    discount?: Discount
  ): Promise<Addon[]> {
    return this.addonModelService
      .getAddons(country, product, alreadySelectedAddons, deliveryDate, orderIndex, enableVaseAsKind, discount)
      .then((addons) => {
        const segments = this.contentSegmentService.contentSegments();

        // If right segment/s, will remove addon/s
        return this.excludeAddonsSegment(segments, addons);
      });
  }

  /**
   * Get and set the addons as observable
   * to be used by addons and greeting card components
   */
  getAndSetAddons(preferredAddons: Addon[] = [], purchase: Purchase, order: Order, shouldMoveToFront: boolean = false): Promise<Addon[]> {
    if (!order?.firstDeliveryDate || !order?.product) {
      return Promise.resolve([]);
    }

    this.isLoading$.next(true);

    let chosenAddons = preferredAddons.slice();

    return this.getAddons(
      order.address.country,
      order.product,
      chosenAddons,
      order.firstDeliveryDate,
      order.indexInPurchase,
      true,
      purchase.discount
    )
      .then((productAddons) => {
        const addonsList: Addon[] = [].concat(productAddons);

        // Re-arrange if needed, ensuring that the first is always kept first
        let addons = chosenAddons.length && shouldMoveToFront ? ArrayUtils.moveToFront(chosenAddons, addonsList) : addonsList;

        // If no addons are selecteable or selected, treat as if we have no addons
        addons = addons.find((a) => a.isSelectable || a.isSelected) ? addons : [];

        const selectedAddons = addons.filter((a) => a.isSelected);

        // if we had 3 selected before, but now only have 2 show the message
        // No need to show if we have 0 before, but now we have 1 selected
        return chosenAddons.length > selectedAddons.length
          ? this.selectedAddonsBecomeUnavailable(chosenAddons, addons, order)
          : Promise.resolve(addons);
      })
      .then((addons) => {
        const purchase = this.purchaseService.getPurchase();
        const listType = this.locationService.getListType();
        const selectedAddons = addons.filter((a) => a.isSelected);

        this.analyticsService.trackAddonsSelected(order.addons || [], selectedAddons, purchase, order, listType, undefined, 'addon-picker');

        // this last so we don't send to analytics the updated reference of the order
        setTimeout(() => {
          this.addons$.next(addons);
          this.isLoading$.next(false);
        }, 1);

        return addons;
      })
      .catch((e: Error) => {
        this.addons$.next([]);
        this.isLoading$.next(false);
        return Promise.resolve([]);
      });
  }

  /**
   * Clear Addons on component destroy
   */
  clearAddons() {
    this.addons$.next(null);
    this.isLoading$.next(false);
  }

  /**
   * When an addon becomes unvailable
   */
  private selectedAddonsBecomeUnavailable(chosenAddons: Addon[], filteredAddons: Addon[], order: Order): Promise<Addon[]> {
    const selectedAddons = filteredAddons.filter((a) => a.isSelected);

    if (chosenAddons?.find((a) => a.type?.indexOf('gift_card') > -1) && !selectedAddons?.find((a) => a.type?.indexOf('gift_card') > -1)) {
      this.toastr.error(
        t('js.component.product-addons.toaster.invalid-greeting-card.detail'),
        t('js.component.product-addons.toaster.invalid-greeting-card')
      );
    }

    const filteredChosenAddons = this.excludeSelectedAddons(chosenAddons, 'gift_card');
    const filteredSelectedAddons = this.excludeSelectedAddons(selectedAddons, 'gift_card');

    if (filteredChosenAddons.length <= filteredSelectedAddons.length) {
      return Promise.resolve(filteredAddons);
    }

    this.toastr.error(t('js.component.product-addons.toaster.invalid.detail'), t('js.component.product-addons.toaster.invalid'));

    const addonRequirement = (order?.product?.addonRequirements || []).find((r) => r.min);
    if (!addonRequirement) {
      return Promise.resolve(filteredAddons);
    }

    const selectedMatchesKind = selectedAddons.filter((a) => a.type === addonRequirement.kind);
    if (selectedMatchesKind.length >= addonRequirement.min) {
      return Promise.resolve(filteredAddons); // All is good, the requirement has what we are set
    }

    return this.modalService
      .show(ProductAddonListModalComponent, {
        trackingKey: 'addonListModal',
        initialState: {
          selectedAddons,
          addonRequirement,
          forceUserToSelect: true,
          product: order?.product,
          preferredDate: order?.firstDeliveryDate
        },
        class: 'modal-lg product-addon-list-modal--height'
      })
      .then(({ date, addons }) => {
        const allAddons = []
          .concat(filteredAddons)
          .concat(addons || [])
          .filter((value, index, self) => self.indexOf(value) === index); // unique only

        return Promise.resolve(allAddons);
      })
      .catch(() => Promise.resolve(selectedAddons));
  }

  /**
   * Excludes addons from user if in segment
   */
  excludeAddonsSegment(segments: ContentSegment[], addons: Addon[]): Addon[] {
    const toExclude = segments.reduce((acc, segment) => {
      acc.push(...segment.excluded_tags);
      return acc;
    }, []);

    return addons.filter((addon) => !toExclude.find((t) => (addon.tags || []).indexOf(t) > -1));
  }

  /**
   * Filters out covers from addons
   * @param addons
   */
  filterGiftCardCoversFromAddons(addons: Addon[]): Addon[] {
    return addons.filter((addon) => ['gift_card', 'standard_gift_card', 'premium_gift_card'].includes(addon.type));
  }

  /**
   * Exclude addon kind
   */
  private excludeSelectedAddons(addons: Addon[], addonKind: string): Addon[] {
    return (addons || []).filter((a: Addon) => (a?.type || '').indexOf(addonKind) < 0);
  }
}
