import { Injectable } from '@angular/core';
import { DeliveryModelService } from 'Shared/models/delivery-model.service';
import { Delivery, QualityIssue, QualityItem, QualityResolution, ReportedQualityIssue } from 'Shared/classes/delivery';
import { Product } from 'Shared/classes/product';
import { DeliveryDateService } from 'Shared/services/delivery-date.service';
import * as dayjs from 'dayjs';
import { Error } from 'Shared/classes/error';
import { t } from 'Shared/utils/translations';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { Addon } from 'Shared/classes/addon';
import { UserService } from 'Shared/services/user.service';
import { Order } from 'Shared/classes/order';
import { Price } from 'Shared/classes/price';
import { DeliveryDate } from 'Shared/classes/delivery-date';

/**
 * TODO: Split this service into multiple services https://bloomon.atlassian.net/browse/SR-1978
 */
@Injectable({
  providedIn: 'root'
})
export class DeliveryService {
  constructor(
    private deliveryModel: DeliveryModelService,
    private deliveryDatesService: DeliveryDateService,
    private analyticsService: AnalyticsService,
    private userService: UserService
  ) {}

  /**
   * Check that a delivery is valid
   */
  check(delivery: Delivery, product: Product, serverTime: dayjs.Dayjs): Promise<DeliveryDate[] | DeliveryDate> {
    return this.deliveryDatesService
      .getDates(delivery.address, product, serverTime, delivery.date)
      .then((dates): Promise<DeliveryDate[] | DeliveryDate> => {
        const deliveryDate = dates.find(
          (dd): boolean =>
            !!(
              dd.date.format('YYYY-MM-DD') === delivery.date.format('YYYY-MM-DD') &&
              dd.shippingOptions.find((sO): boolean => sO.id === delivery?.shippingOption?.id)
            )
        );

        const error = new Error({
          message: t('js.service.delivery.check.error'),
          code: 'deliveryInvalid'
        });

        this.analyticsService.trackError(error);

        return deliveryDate ? Promise.resolve(deliveryDate) : Promise.reject(error);
      });
  }

  /**
   * Update
   * @param delivery
   */
  update(delivery: Delivery, all?: boolean): Promise<void | Delivery> {
    return this.deliveryModel.update(delivery, all);
  }

  /**
   * Get information by delivery id
   * @param delivery
   */
  get(delivery: Delivery, token?: string): Promise<void | Delivery> {
    return this.deliveryModel.getByDelivery(delivery, token);
  }

  /**
   * Get deliveries by user
   * @param token
   * @param recent - if true, returns back deliveries only with +- 2 week period
   * @param resent - if true, only resents, if false, no resents
   * @returns Delivery[]
   */
  getDeliveriesByUser(token: string, recent?: boolean, resent?: boolean): Promise<Delivery[]> {
    return this.deliveryModel.getDeliveriesByUser(token, recent, resent);
  }

  /**
   * Get deliveries by order
   * @param order
   * @param token
   * @param recent - if true, returns back deliveries only with +- 2 week period
   * @returns Delivery[]
   */
  getDeliveriesByOrder(order: Order, token: string, recent?: boolean): Promise<Delivery[]> {
    return this.deliveryModel.getDeliveriesByOrder(order, token, recent);
  }

  /**
   * Get information by delivery id
   * @param delivery
   */
  getAvailableProducts(delivery: Delivery): Promise<void | Product[]> {
    return this.deliveryModel.getAvailableProductsByDelivery(delivery);
  }

  /**
   * Get all infomations of user deliveries
   * @param recent - if true, returns back deliveries only with +- 2 week period,
   * @param resent - if true, only resents, if false, no resents
   */
  getAll(recent?: boolean, resent?: boolean): Promise<Delivery[]> {
    return this.deliveryModel.getAll(recent, resent);
  }

  /**
   * Get gift card covers
   * @param product
   */
  getGiftCardCovers(delivery: Delivery): Promise<Addon[]> {
    return this.deliveryModel.getGiftCardCovers(delivery);
  }

  /**
   * Apply the credit automatically
   * @param delivery
   * @param deliveryToken
   * @param qualityIssueId
   */
  applyCredit(delivery: Delivery, deliveryToken: string): Promise<void> {
    return this.deliveryModel.applyCredit(delivery, deliveryToken);
  }

  /**
   * Apply full resend credit automatically
   * @param delivery
   * @param deliveryToken
   * @returns
   */
  applyResend(delivery: Delivery, deliveryToken: string, notify: boolean, changed: boolean): Promise<Delivery> {
    return this.deliveryModel.applyResend(delivery, deliveryToken, notify, changed);
  }

  /**
   * Apply the credit automatically with token
   * @param delivery
   */
  applyCreditByToken(delivery: Delivery): Promise<void> {
    const user = this.userService.getUser();
    const order = new Order();
    order.id = delivery.orderId;
    return this.userService.requestOrderTrackingToken(order, user.email).then((token): Promise<void> => this.applyCredit(delivery, token));
  }

  /**
   * Apply full refund credit automatically with token
   * @param delivery
   */
  applyRefundByToken(delivery: Delivery): Promise<Price> {
    const user = this.userService.getUser();
    const order = new Order();
    order.id = delivery.orderId;
    return this.userService.requestOrderTrackingToken(order, user.email).then((token): Promise<Price> => this.applyRefund(delivery, token));
  }

  /**
   * Apply refund
   * @param delivery
   * @param token
   */
  applyRefund(delivery: Delivery, token: string): Promise<Price> {
    return this.deliveryModel.applyRefund(delivery, token);
  }

  /**
   * Get Quality Issues with token
   * @param delivery
   * @param token
   */
  getQualityIssues(delivery: Delivery, token: string): Promise<QualityItem[]> {
    return this.deliveryModel.getQualityIssues(delivery, token);
  }

  /**
   * Get Quality Stems with token
   * @param delivery
   * @param token
   */
  getQualityStems(delivery: Delivery, token: string): Promise<QualityItem[]> {
    return this.deliveryModel.getQualityStems(delivery, token);
  }

  /**
   * Get quality resolution with token
   * @param delivery
   * @param token
   * @param issue
   * @param stems
   */
  getQualityResolution(delivery: Delivery, token: string, issue: QualityItem, stems: QualityItem[]): Promise<QualityResolution> {
    return this.deliveryModel.getQualityResolution(delivery, token, issue, stems);
  }

  /**
   * Report quality issue
   * @param delivery
   * @param token
   * @param issueId
   * @param stemIds
   * @param comment
   * @param fileNames
   */
  reportQualityIssue(
    delivery: Delivery,
    token: string,
    resolution: QualityResolution,
    stems: QualityItem[],
    comment: string,
    fileNames: string[]
  ): Promise<QualityIssue> {
    return this.deliveryModel.reportQualityIssue(delivery, token, resolution, stems, comment, fileNames);
  }

  /**
   * Apply credit relation to quality issue option
   * @param delivery
   * @param token
   * @param issueOptionId
   * @returns Promise<void>
   */
  applyQualityCompensate(delivery: Delivery, token: string, issueOptionId: number): Promise<void> {
    return this.deliveryModel.applyQualityCredit(delivery, token, issueOptionId);
  }

  /**
   * Apply Refund related to quality issue option
   * @param delivery
   * @param token
   * @param issueOptionId
   * @param issueId
   * @returns Promise<Price>
   */
  applyQualityRefund(delivery: Delivery, token: string, issueOptionId: number, issueId: number): Promise<Price> {
    return this.deliveryModel.applyQualityRefund(delivery, token, issueOptionId, issueId);
  }

  /**
   * Apply quality issue resend
   * @param delivery
   * @param token
   * @param issueId
   * @param deliveryChanged
   * @param notify
   * @returns Delivery
   */
  applyQualityResend(delivery: Delivery, token: string, issueId: number, deliveryChanged: boolean, notify = false): Promise<Delivery> {
    return this.deliveryModel.applyQualityResend(delivery, token, issueId, deliveryChanged, notify);
  }

  /**
   * Check for exist quality issue
   * @param delivery
   * @param token
   */
  checkQualityIssue(delivery: Delivery, token: string): Promise<ReportedQualityIssue> {
    return this.deliveryModel.checkQualityIssue(delivery, token);
  }

  /**
   * Update and validate quality resolution
   * @param delivery
   * @param token
   */
  validateResolutionForQualityIssue(delivery: Delivery, token: string): Promise<ReportedQualityIssue> {
    return this.deliveryModel.validateResolutionForQualityIssue(delivery, token);
  }

  /**
   * Return upcoming deliveries for an order
   * @param order
   * @param past
   * @returns
   */
  getUpcomingDeliveries(order: Order): Promise<void | Delivery[]> {
    return this.deliveryModel.getUpcomingDeliveries(order);
  }

  /**
   * Return past deliveries for an order
   * @param order
   * @param past
   * @returns
   */
  getPastDeliveries(order: Order): Promise<void | Delivery[]> {
    return this.deliveryModel.getPastDeliveries(order);
  }
}
