export const reviews = [
  {
    date: '2021-11-17T09:23:53Z',
    title: 'Fantastisk indpakning',
    review:
      'Fantastisk indpakning, de smukkeste blomster virkelig stilrene - kunne ikke være bedre. Jeg kan kun sige flot arbejde. Jeg er meget tilfreds. Fik en vase gratis med skønt. Tak!',
    rating: 5,
    reviewer: 'Christian'
  },
  {
    date: '2021-11-09T07:44:17Z',
    title: 'Leverancerne var fine og kom som…',
    review:
      'Leverancerne var fine og kom som aftalt. Men det var svært at få noget ændret f.eks leveringstider og type af buket.',
    rating: 4,
    reviewer: 'Kurt Adolfsen'
  },
  {
    date: '2021-11-09T05:34:29Z',
    title: 'Altid friske blomster som holder længe',
    review: 'Altid friske blomster som holder længe',
    rating: 5,
    reviewer: 'Marianne'
  },
  {
    date: '2021-11-08T20:24:30Z',
    title: 'Altid flotte og friske blomster',
    review:
      'Altid flotte og friske blomster, som holder længe. Vi får en buket hver 3. uge, og der står ofte en rest fra den sidste buket. \nDerudover er jeg vild med, at de leveres med cykelbud inde i byen.',
    rating: 5,
    reviewer: 'DK'
  },
  {
    date: '2021-11-08T18:59:36Z',
    title: 'Smukke, struttende stjerner',
    review: 'Smukke, struttende stjerner ✨🤩✨🤩✨🤩✨',
    rating: 5,
    reviewer: 'Anette W L'
  },
  {
    date: '2021-11-07T17:42:26Z',
    title: 'Som altid fantastisk',
    review: 'Som altid fantastisk, smukke blomster levering til tiden, så tak for det.',
    rating: 5,
    reviewer: 'Kirsten Rasmussen'
  },
  {
    date: '2021-11-01T18:12:13Z',
    title: 'Rigtig fin buket',
    review: 'Rigtig fin buket med gode fine blomster. Levering til tiden 👍',
    rating: 5,
    reviewer: 'linda pedersen'
  },
  {
    date: '2021-09-04T17:36:03Z',
    title: 'Meget smuk og friske blomster i en skøn…',
    review: 'Meget smuk og friske blomster i en skøn buket',
    rating: 5,
    reviewer: 'Annette'
  },
  {
    date: '2021-08-29T17:47:50Z',
    title: 'The flowers was a gift and brought much…',
    review:
      'The flowers was a gift and brought much happiness. Very beautiful and perfect in the room.',
    rating: 5,
    reviewer: 'Jeanett Sand  Reimer'
  },
  {
    date: '2021-08-23T06:16:46Z',
    title: 'Modtager blev meget glad for en smuk og…',
    review: 'Modtager blev meget glad for en smuk og særlig buket!',
    rating: 5,
    reviewer: 'Rie'
  },
  {
    date: '2021-07-31T20:20:18Z',
    title: 'Blomsterabonnement hver 2. uge',
    review:
      'Min mand har givet mig dette blomsterabonnement hvor jeg får en buket hver 14. dag. \nJeg er simpelthen så imponeret hver evig eneste gang der kommer en buket. \nHelt vildt imponerende flotte blomster!\nOg de er pakket super godt, så selv hvis vi ikke lige er hjemme på leveringsdagen holder de sig super friske. Vi har sågar glemt at framelde i forbindelse med ferie og selv efter 1 uge i vores carport var de fine. \nBuketten står fint de 2 uger indtil næste buket kommer, med en enkelt trimning. Og nogle gange kan jeg endda genbruge nogle af blomsterne. \nJeg er simpelthen så glad og I får de varmeste anbefalinger herfra!!',
    rating: 5,
    reviewer: 'Ane'
  },
  {
    date: '2021-06-02T17:00:22Z',
    title: 'Super flotte buketter',
    review:
      'Super flotte buketter, der holder minimum 1,5 uge. Friske, unikke og flot sammensat. Appen, hvor man kan melde til og fra fungerer rigtig godt. Jeg har enkelte gange fået leveret på andre dage end booket / men har altid fået mails herom i god tid - så jeg har haft mulighed for at afvise buketten.',
    rating: 5,
    reviewer: 'Anne Dorte'
  },
  {
    date: '2021-05-08T20:14:58Z',
    title: 'Super oplevelse',
    review:
      'Dejlige buketter, hvor der stort set er en forskellig blomst/grønt for hver stilk. De er smukke og holder min. en uge - og nogle af stilkene holder lige til min næste buket kommer efter 14 dage. Jeg synes jeg får meget for pengene og selv pixie buketterne fylder virkelig godt. Jeg har prøvet den mellemste størrelse også, som jeg vil betegne som meget stor (grene op til 60cm) Kan kun anbefales.',
    rating: 5,
    reviewer: 'Betina'
  }
];
