import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { environment } from 'Environments/environment';
import { ConfigService } from 'Shared/services/config.service';
import { WindowRefService } from 'Shared/services/window.service';

interface StatusResponse {
  status: string;
  authResponse: {
    accessToken: string;
    data_access_expiration_time: number;
    expiresIn: number;
    signedRequest: string;
    userID: string;
    grantedScopes?: string | undefined;
    reauthorize_required_in?: number | undefined;
  };
}

@Injectable({
  providedIn: 'root'
})
export class FacebookService {
  private environment: typeof environment;
  private scriptPromise: Promise<void>;
  private window: Window;
  private requestedPermissions = ['email', 'public_profile'];
  private loginState: Promise<StatusResponse['authResponse']>;

  constructor(private domUtils: DomUtilsService, private windowRef: WindowRefService, private configService: ConfigService) {
    this.environment = environment;
    this.window = this.windowRef.nativeWindow;
  }

  /**
   * Init Facebook
   * @return {Promise<void>}
   */
  public init(): Promise<void> {
    if (this.scriptPromise) {
      return this.scriptPromise;
    }

    const fbAppId = this.configService.getConfig().facebookAppId;

    this.scriptPromise = this.domUtils
      .loadScript(`//connect.facebook.net/${this.environment.languageCountryLocale}/sdk.js`, 'facebook')
      .then((): Promise<never> | void => {
        if (!this.window.FB) {
          return Promise.reject('Facebook Error');
        }

        this.window.FB.init({
          appId: fbAppId,
          cookie: true,
          xfbml: true,
          version: 'v17.0'
        });

        if (this.window.FB?.AppEvents) {
          this.window.FB.AppEvents.logPageView();
          this.checkLoginState().catch((): void => {
            /* no-op */
          });
        }
      });

    return this.scriptPromise;
  }

  /**
   * Login with facebook
   * @return {Promise<StatusResponse['authResponse']>}
   */
  public login(): Promise<StatusResponse['authResponse']> {
    return new Promise((resolve, reject): void => {
      const loginData = {
        scope: this.requestedPermissions.join(',')
      };

      this.window.FB.login((response: StatusResponse): Promise<void> => {
        if (response.authResponse && response.status === 'connected') {
          return this.checkPermissions(response.authResponse.userID)
            .then((): void => {
              resolve(response.authResponse);
            })
            .catch((): void => reject('permissions'));
        }

        reject(response);
      }, loginData);
    });
  }

  /**
   * Check if user is logged in
   * @return {Promise<StatusResponse['authResponse']>}
   */
  private checkLoginState(): Promise<StatusResponse['authResponse']> {
    if (this.loginState) {
      return this.loginState;
    }

    this.loginState = new Promise((resolve, reject): void => {
      this.window.FB.getLoginStatus((response: StatusResponse): void => {
        if (response && response.status === 'connected') {
          resolve(response.authResponse);
        } else {
          reject();
        }
      });
    });

    return this.loginState;
  }

  /**
   * Normalizes permissions response
   * @param {Array} data
   * @return {Record<string, boolean>}
   */
  private grantedPermissions(data: { permission: string; status: string }[]): Record<string, boolean> {
    const permissions = {} as Record<string, boolean>;

    data.forEach((item): void => {
      permissions[item.permission] = item.status === 'granted';
    });

    return permissions;
  }

  /**
   * Checks permissions
   * @param {string} userId
   * @return {Promise<void>}
   */
  private checkPermissions(userId: string): Promise<void> {
    return new Promise((resolve, reject): void => {
      this.window.FB.api(
        `/${userId}/permissions`,
        (fbResponse: { data: { permission: string; status: string }[] }): Promise<void> | void => {
          const permissions = this.grantedPermissions(fbResponse.data);
          let valid = true;

          if (fbResponse && fbResponse.data && fbResponse.data.length) {
            this.requestedPermissions.forEach((permission): void => {
              if (!permissions[permission]) {
                valid = false;
              }
            });

            if (valid) {
              return resolve();
            }
          }

          return this.disconnect(userId)
            .then((): void => {
              reject();
            })
            .catch((): void => {
              reject();
            });
        }
      );
    });
  }

  /**
   * Disconnect from FB
   * @param {string} userId
   * @return {Promise}
   */
  private disconnect(userId: string): Promise<void> {
    return new Promise((resolve): void => {
      this.window.FB.api(`/${userId}/permissions`, 'delete', (): void => {
        resolve();
      });
    });
  }
}
