import { Component, Input, OnDestroy, Output, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl, ControlContainer, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

/**
 * Given the controls, the key:value pairs become the key:type
 */
type BwFormValue<TControls> = {
  [Property in keyof TControls]: TControls[Property] extends BwForm<any>
    ? TControls[Property]['value']
    : any; // TODO: The 'any' type here is due to AbstractControls
};

/**
 * Enforce any components that extend this class have the correct type interface
 */
type BwFormInterface = {
  [key: string]: AbstractControl | BwForm<any> | undefined;
};

/**
 * BwFormDefintion
 */
export type BwFormDefintion<T> = Partial<T>;

/**
 *
 * Abstract BwForm class to maintain consistency of sub-forms throughout the app
 * Usage examples in the storybook
 *
 * Note: we need to decorate the abstract class
 * https://angular.io/guide/migration-undecorated-classes
 */

@Component({
  template: ''
})
export abstract class BwForm<TControls extends BwFormInterface>
  extends FormGroup
  implements OnInit, OnDestroy
{
  // Optional parent forms
  @Input() bwParentForm?: FormGroup | BwForm<any>;

  // Ensure the controlName has been defined by the parent
  @Input() bwFormControlName: keyof this['bwParentForm']['controls'];

  // Event once the form has ran ngInit, used for `bw-form-container`
  @Output() didInitForm: EventEmitter<BwForm<TControls>> = new EventEmitter();

  // If this form has been submitted
  hasSubmitted: boolean = false;

  // Override the default FormGroup 'any'
  value: BwFormValue<TControls>;
  valueChanges: Observable<BwFormValue<TControls>>;
  statusChanges: Observable<'VALID' | 'INVALID' | 'PENDING' | 'DISABLED'>;

  // So templates can access 'formGroup' by default
  formGroup: BwForm<TControls> = this;

  // Typing of controls, accessed in templates by controls.x based on what is passed in as BwForm<TControls>
  // @ts-ignore - Ignore that `controls` have [key:string] and we want defined key for each control
  controls: TControls = this.formGroup.controls as TControls;

  // Using Control Container to pass a reference for the parent
  controlContainer: ControlContainer;

  /**
   * Constructor
   */
  constructor() {
    super({});
  }

  /**
   * Get object, overwritten by the extended class
   */
  abstract getObject(): unknown; // Unknown type, as overwritten by the extended class

  /**
   * Set object, also overwritten by the extended class
   */
  abstract setObject(...args: any): void;

  /**
   * Build the form, returning the FormControls as needed
   */
  abstract buildForm(): BwFormDefintion<TControls>;

  /**
   * Add custom validators to this form
   * @returns
   */
  buildFormGroupValidators(): ValidatorFn[] {
    return [];
  }

  /**
   * Get the form control, overriding the type
   */
  // @ts-ignore - as the `get` method doesn't return back abstractControl, which typescript initially expects
  get<TKey extends keyof TControls>(path: TKey): TControls[TKey] {
    return super.get(path as string) as any;
  }

  /**
   * Validate this form, and child components
   */
  runValidators(): void {
    Object.keys(this.controls).forEach((key: string) => {
      const abstractControl = this.controls[key];

      if (abstractControl['runValidators']) {
        abstractControl['runValidators']();
      } else {
        abstractControl.updateValueAndValidity();
      }
    });
  }

  /**
   * Mark this form, and child forms as submitted
   */
  markAsSubmitted(): void {
    this.hasSubmitted = true;
    Object.entries(this.controls).forEach(([key, control]) => {
      // @ts-ignore - During runtime, don't know if the class has inherited which class, so we just chek
      if (typeof control.markAsSubmitted === 'function') {
        // @ts-ignore
        control.markAsSubmitted();
      }
    });
  }

  /**
   * Init
   */
  ngOnInit(): void {
    // Add controls to this form
    const controls = this.buildForm();
    // Skip over anything that is 'undefined'
    for (const i in controls) {
      if (controls[i]) {
        this.setControl(i, controls[i] as any);
      }
    }

    // If we build child components using the ControlContainer class
    // we don't need to pass bwParentForm as Input
    if (this.controlContainer?.control && !this.bwParentForm) {
      this.bwParentForm = this.controlContainer?.control as BwForm<TControls>;
    }

    // Add this form to the parent form, if defined
    if (this.bwParentForm && this.bwFormControlName) {
      this.bwParentForm.setControl(this.bwFormControlName as string, this as any);
    }

    // Create any custom validators - if any
    const validators = this.buildFormGroupValidators();
    if (validators.length) {
      this.setValidators(validators);
      this.updateValueAndValidity();
    }

    // Finally, output that the form initialised
    this.didInitForm.emit(this);
  }

  /**
   * On destory, remove the control
   */
  ngOnDestroy(): void {
    if (
      this.bwParentForm &&
      this.bwFormControlName &&
      this.bwParentForm.controls[this.bwFormControlName as string]
    ) {
      this.bwParentForm.removeControl(this.bwFormControlName as string);
    }
  }
}
