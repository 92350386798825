import { BehaviorSubject, Observable } from 'rxjs';

export class IntersectionService {
  private DEFAULT_OPTIONS: IntersectionOptions = {
    respondWhenIntersecting: true,
    removeAfterObserved: true,
    rootElement: null,
    rootMargin: '0px 0px 0px 0px'
  };

  private observer: IntersectionObserver;

  private options: IntersectionOptions;

  private intersection$: BehaviorSubject<IntersectionObserverEntry> = new BehaviorSubject(null);

  private onObserved(entry: IntersectionObserverEntry): void {
    if (!entry) {
      return;
    }

    if (this.options.respondWhenIntersecting && entry.isIntersecting) {
      this.intersection$.next(entry);
    } else if (!this.options.respondWhenIntersecting) {
      this.intersection$.next(entry);
    }

    if (entry.isIntersecting && this.options.removeAfterObserved) {
      this.unqueue(entry);
    }
  }

  private addToQueue(element: Element): void {
    // Then observe the element
    this.observer.observe(element);
  }

  private unqueue(element: IntersectionObserverEntry): void {
    this.observer.unobserve(element.target);
  }

  setup(options?: IntersectionOptions): this {
    this.options = Object.assign(this.DEFAULT_OPTIONS, options || {});

    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          this.onObserved(entry);
        });
      },
      {
        rootMargin: this.options.rootMargin,
        root: this.options.rootElement,
        threshold: this.options.threshhold
      }
    );

    return this;
  }

  observeElements(elements: Element[]): Observable<IntersectionObserverEntry> {
    if (!this.observer) {
      console.warn('Call intersectionService.setup() first');
      return;
    }
    elements.forEach((element) => {
      this.addToQueue(element);
    });
    return this.intersection$;
  }

  onDestroy(): void {
    this.intersection$.unsubscribe();
  }
}

export interface IntersectionOptions {
  respondWhenIntersecting?: boolean;
  removeAfterObserved?: boolean;
  rootElement?: Element;
  rootMargin?: string;
  threshhold?: number;
}
