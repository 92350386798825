import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmailService, Email } from 'Shared/services/email.service';
import { ConfigService } from 'Shared/services/config.service';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { t } from 'Shared/utils/translations';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { NgxPopperjsContentComponent } from 'ngx-popperjs';
import { NewsletterStatusService } from 'Shared/services/newsletter-status.service';
import { WindowRefService } from 'Shared/services/window.service';
import { CountryService } from 'Shared/services/country.service';
import { FeaturesService } from 'Shared/services/features.service';
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-newsletter-signup-standard',
  templateUrl: './newsletter-signup-standard.component.html'
})
export class NewsletterSignupStandardComponent {
  success: boolean = false;
  promoCode: string;
  form: FormGroup;
  formNew: FormGroup;
  placeholderText: string;
  hasSubmitted: boolean;
  popperTrigger: NgxPopperjsTriggers = NgxPopperjsTriggers.click;
  popperPlacement: NgxPopperjsPlacements = NgxPopperjsPlacements.BOTTOMEND;

  site: string; // fr/de/uk - used for experiment removed after use

  @Input()
  disclaimerastooltip: boolean = false; // Lowercase due to to contentful attributes being lowercase

  @ViewChild('newsletter_tooltip', { static: true } as any)
  popperContent: NgxPopperjsContentComponent;

  @ViewChild('newsletter_error_tooltip', { static: true } as any)
  errorTooltip: NgxPopperjsContentComponent;

  constructor(
    public emailService: EmailService,
    private configService: ConfigService,
    private toastrService: ToastrService,
    private analyticsService: AnalyticsService,
    private newsletterStatusService: NewsletterStatusService,
    private windowRefService: WindowRefService,
    private countryService: CountryService,
    private featuresService: FeaturesService
  ) {
    this.site = this.configService.getConfig().site;
    this.form = new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email]
      })
    });
  }

  signup(): Promise<any> {
    this.analyticsService.track('component.newsletter.tv.actioned');

    this.hasSubmitted = true;
    if (!this.form.valid) {
      setTimeout(() => {
        this.errorTooltip.show();
      }, 500);
      return Promise.resolve(t('js.components.newsletter.invalid-email'));
    }

    const updatedEmail = new Email(this.form.get('email').value);

    updatedEmail.preference = this.featuresService.getFeature('GDPR').emailSubscribedPreference;
    updatedEmail.consent = {
      method: 'Email Entry',
      location: 'Web - Newsletter - Signup',
      copy: (
        this.windowRefService.nativeWindow.document.querySelector(
          '.newsletter-signup__disclaimer-text'
        ) as any
      ).innerText.trim()
    };

    return this.emailService
      .update(updatedEmail)
      .then((res) => {
        this.success = true;

        this.promoCode =
          this.countryService.siteConfigValue('newsletter.signup.code') || res.code || 'welcometen';

        this.analyticsService.track('component.newsletter-signup.success');

        this.newsletterStatusService.recordSignup();
      })
      .catch((error) => {
        this.success = false;
        this.toastrService.error(error.message);
      });
  }
}
