import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { WindowRefService } from 'Shared/services/window.service';
import { CheckoutParams } from 'Project/checkout/checkout.states';
import { StateService } from 'Shared/services/state.service';
import { ActivatedState } from 'Shared/classes/activated-state';
import { environment } from 'Environments/environment'; // can't use configService here due to circle dependancies

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  bloomAndWildOwnedDomains = ['localhost', 'bloomandwild.com', 'bloomandwild.fr', 'bloomandwild.de', 'bloomdev.org'];

  window: any;
  appUrl: string;
  appUrlSubfolder: string;
  currentLocale: string;
  countryIdToSite: any[];
  countrySite: string;
  brandId: string;

  mainDomain: string;
  domainsToReplace: string[] = [];

  constructor(private location: Location, private windowRef: WindowRefService, private stateService: StateService) {
    this.window = windowRef.nativeWindow;

    if (!this.window.document) {
      return;
    }
    // Utilising the browser to get the full URL works across localhost (which has a port in the domain), subdomains and domains :)
    const baseTag = this.window.document.querySelector('base');
    this.appUrl = baseTag ? baseTag.href : '';

    const appUrlA = this.window.document.createElement('a');
    appUrlA.href = this.appUrl;
    this.appUrlSubfolder = (appUrlA.pathname || '').length > 1 ? appUrlA.pathname : undefined;

    const mainDomain = this.window.document.createElement('a');
    mainDomain.href = '/';
    this.mainDomain = mainDomain.toString();

    this.domainsToReplace = [
      (this.appUrl || '').replace(/\/$/, '') // without an ending slash
    ];

    const linksToRewrite = environment['rewriteLinksThatIncludeDomain'];
    if (linksToRewrite && linksToRewrite.length) {
      this.domainsToReplace = this.domainsToReplace.concat(linksToRewrite);
    }

    this.currentLocale = environment.locale;
    this.countryIdToSite = environment.countryIdToSite;
    this.countrySite = environment.countrySite;
    this.brandId = environment.brandId;
  }

  /**
   * Convert a URL to the correct subfolder or domain
   * @param url
   * @param selectedLocale
   */
  convertCountryToSubfolder(url: string, selectedLocale?: string): string {
    const a = this.window.document.createElement('a');
    a.setAttribute('href', url);

    const queryParts = this.getParamsAsObject(a.search);
    if (!queryParts.countryId) {
      return url;
    }

    const countryId = parseInt(queryParts.countryId, 10);
    const locale = selectedLocale || this.currentLocale;

    // Find the best one to use, either by locale & countryId matching, or just by countryId
    // prettier-ignore
    const bestMatch =
        this.countryIdToSite.find(c => c.locale === locale && c.countryIds.indexOf(countryId) > -1) ||
        this.countryIdToSite.find(c => c.countryIds.indexOf(countryId) > -1);

    if (!bestMatch) {
      // if there is no match, return url with no countryId query param, to avoid the app go in a loop
      const urlWithoutQueryAndHash = this.getUrlWithoutQueryAndHash(url);
      delete queryParts.countryId;
      const params = Object.keys(queryParts).length > 0 ? this.getQuerystringForParams(queryParts) : '';

      return `${urlWithoutQueryAndHash}${params}`;
    }

    const path = a.pathname.charAt(0) !== '/' ? `/${a.pathname}` : a.pathname; // ensure start with /
    const uri = `${`${path}`.replace(/^\/\w{2}-\w{2}(\/+|$)/, '/')}${a.search}${a.hash}`; // remove subfolder, replace with /

    // For preview branch
    if (this.window.location.hostname && this.window.location.hostname.indexOf('preview-') === 0) {
      const hostname = this.window.location.hostname;

      const previewSubdomains = {
        default: 'www',
        'fr-5': 'fr',
        'de-6': 'de'
      };
      const subfolder = bestMatch.site
        .replace('https://www.bloomdev.org', '')
        .replace('https://de.bloomdev.org', '')
        .replace('https://fr.bloomdev.org', '');

      const toReplaceWith = previewSubdomains[`${bestMatch.locale}-${countryId}`] || previewSubdomains.default;
      const hostnameTo = hostname.replace(hostname.substring(0, 11), `preview-${toReplaceWith}-`).replace('--', '-');
      return `https://${hostnameTo}${subfolder}${uri}`;
    }
    return `${bestMatch.site}${uri}`;
  }

  /**
   * Normalise the full URL
   * @param url
   */
  urlDetails(url: string): { isExternal: boolean; isOwnedDomain: boolean } {
    const a = this.window.document.createElement('a');
    a.href = url;

    // as defaults
    const toReturn = {
      isExternal: true,
      isOwnedDomain: false
    };

    // TODO: I think this should be "endsWith" rather than just checking the hostname
    // We therefore must own the domain
    const hostname = a.hostname || this.window.location.hostname; // IE11 can return hostame as blank for relative URLs
    toReturn.isOwnedDomain = !!this.bloomAndWildOwnedDomains.find((d) => hostname.indexOf(d) > -1);

    // If we are not on the same domain, it *must* be another site, so just continue
    if (!toReturn.isOwnedDomain || a.toString().indexOf(this.mainDomain) === -1) {
      return toReturn;
    }

    // If the link is a subfolder, but the app URL isn't part of the URL, it must mean it's an external url
    // This should match:
    // /en-gb/
    // /en-gb
    // /en-gb/hello-world/
    const subfolderRegex = new RegExp(/^\/\w{2}-\w{2}(\/+|$)/);
    if (a.pathname.match(subfolderRegex) && a.toString().indexOf(this.appUrl) < 0) {
      return toReturn;
    }

    // if we are on a subfolder, but the link is NOT a subfolder, it must be another domain
    if ((this.appUrlSubfolder || '').match(subfolderRegex) && !a.pathname.match(subfolderRegex)) {
      return toReturn;
    }

    // Set subfolder sites (eg /en-gb/) as external, UNLESS it's the same subdomain site we are on now
    if (a.pathname.match(subfolderRegex) && a.pathname.indexOf(this.appUrlSubfolder) !== 0) {
      return toReturn;
    }

    toReturn.isExternal = false;
    return toReturn;
  }

  /**
   * Given any URL, change it so that it takes the current site and domain structure into account
   * @param url
   */
  normaliseUrlForSite(url: string): string {
    // Create a temporary 'a' tag - these are pretty cool as know the domain the user is on etc
    const tempATag = this.window.document.createElement('a');
    tempATag.href = url;
    // TODO: Should we replace when in preview mode?
    this.domainsToReplace.forEach((domain) => {
      // by setting the href each time (rather then just replacing the string over and over)
      // the href will use the url the user is on to correctly set the relative URL
      tempATag.href = tempATag.toString().replace(domain, '');
    });

    // Finally, we need to tidy by re-writing
    return tempATag.href.replace(this.mainDomain, this.appUrl);
  }

  getQuerystringForParams(params: {}, dontEncode: boolean = false): string {
    const keyArr = Object.keys(params);
    keyArr.sort();

    if (dontEncode) {
      return `?${keyArr.map((key) => `${key}=${params[key]}`).join('')}`;
    }

    return `?${keyArr.map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&')}`;
  }

  getListType(activatedState?: ActivatedState): { type: string; value?: any } {
    // If a state is given then provide its list type, if not given then use getCurrent
    const state = activatedState ? activatedState : this.stateService.getCurrent();
    // Check if we have from params and use the params in there, other wise use the state params
    const params =
      activatedState && (activatedState.name === 'checkout.start' || activatedState.name === 'checkout.giftOptions') && activatedState.from
        ? state.from.params
        : state.params;
    const data = state.data;
    const name = state.name;

    if (name && name === 'checkout.productpage') {
      return {
        type: 'productPage',
        value: params.tagOnly
      };
    }

    if (params.tagOnly) {
      return {
        type: 'tagOnly',
        value: params.tagOnly
      };
    }
    if (params.tag) {
      return {
        type: 'tag',
        value: params.tag
      };
    }
    if (params.expressOnly) {
      return {
        type: 'expressOnly',
        value: true
      };
    }

    return {
      value: undefined,
      type: 'base'
    };
  }

  /**
   * Remove null or undefined from objects
   * https://stackoverflow.com/questions/23774231/how-do-i-remove-all-null-and-empty-string-values-from-a-json-object
   */
  private removeEmpty(obj): any {
    const dangerKeys = ['upsells'];

    // As these may have circlular dependancies, we dont need them to be tidied
    Object.entries(obj).forEach(([key, val]) => {
      if (dangerKeys.indexOf(key) > -1) {
        return;
      }

      if (val && typeof val === 'object') {
        this.removeEmpty(val);
      } else if (val === undefined) {
        delete obj[key];
      }
    });

    return obj;
  }

  getParamsAsObject(str: string): any {
    return str
      .substring(1)
      .split('&')
      .reduce((result, value) => {
        const parts = value.split('=');
        if (parts[0]) {
          result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
        }
        return result;
      }, {});
  }

  /**
   * Just get the url params
   */
  getUrlParams(): any {
    return this.getParamsAsObject(this.window.location.search);
  }

  /**
   * Get the path
   */
  path(): string {
    const path = this.location.path().split('?')[0];
    return path.length ? path : '/';
  }

  /**
   * Get the state params
   */
  getCurrentParams(): CheckoutParams {
    const current = this.stateService.getCurrent();
    const stateParams = current ? current.params : {};
    const urlParams = this.removeEmpty(this.getUrlParams());
    return Object.assign(urlParams, stateParams);
  }

  /**
   * Check that a parameter array is in the params
   * - Useful for checking if a set of URL params contains a combination of params
   * @param params
   * @param paramArrayToCheck
   */
  paramsInclude(params: CheckoutParams, paramArrayToCheck: object[]): boolean {
    // Double !! converts to boolean
    return !!paramArrayToCheck.find((paramArray) => {
      const paramsToCheck = Object.entries(paramArray);
      const paramsThatMatch = paramsToCheck.filter(([key, val]) => params[key] === val);
      return paramsThatMatch.length === paramsToCheck.length; // They all match
    });
  }

  /**
   * Get the URL
   */
  fullUrl(): string {
    return this.window.location.href;
  }

  /**
   * Get the host
   */
  getHost(): string {
    return this.window.location.host;
  }

  /**
   * Get the host with subfolder
   */
  getHostWithSubfolder(): string {
    let host = this.window.location.host;
    if (this.brandId === 'bloom_and_wild' && (this.countrySite === 'de-at' || this.countrySite === 'en-at')) {
      host += `/${this.countrySite}`;
    }
    return host;
  }

  /**
   * URL without querystring or without the hash
   */
  getUrlWithoutQueryAndHash(url?: string): string {
    return (url || this.window.location.href).split('#')[0].split('?')[0];
  }
}
