import * as dayjs from 'dayjs';

import { Injectable } from '@angular/core';
import { BackendService } from 'Shared/services/backend.service';
import { ProductFilterAttributes, FilterMethodType, FilterItem, FilterGroup } from 'Shared/classes/filter';
import { ContentsModelService } from 'Shared/models/contents-model.service';
import { Country } from 'Shared/classes/country';
import { t } from 'Shared/utils/translations';
import { ConfigService } from 'Shared/services/config.service';
import { PeakShippingService } from 'Shared/services/peak-shipping.service';

@Injectable({
  providedIn: 'root'
})
export class FilterModelService {
  priceAttributesPromise: Record<string, Promise<FilterGroup>> = {};
  skuAttributesPromise: Promise<FilterGroup[]>;
  site: string;
  filterIsMutuallyExclusive: string[] = ['packaging', 'type'];

  constructor(
    private backend: BackendService,
    private contentService: ContentsModelService,
    private peakShippingService: PeakShippingService,
    private configService: ConfigService
  ) {
    this.site = this.configService.getConfig().site;
  }

  filterTypeForConfig(key: string): FilterMethodType {
    if (key === 'price') {
      return 'priceRange';
    }

    if (key === 'newIn') {
      return 'newIn';
    }

    if (key === 'delivery') {
      return 'date';
    }

    if (key === 'specificDate') {
      return 'date';
    }

    return 'keyValue';
  }

  fromAttributesPayload(res: any): FilterGroup {
    const filterGroup = new FilterGroup();
    filterGroup.key = res.key;
    filterGroup.isMutuallyExclusive = this.filterIsMutuallyExclusive.indexOf(filterGroup.key) !== -1;

    filterGroup.filterItems = res.values.map((v) => {
      const filterItem = new FilterItem();
      filterItem.groupKey = filterGroup.key;
      filterItem.key = v.key;
      filterItem.name = v.value;
      filterItem.filterType = this.filterTypeForConfig(res.key);
      filterItem.data = {
        key: v.key,
        value: v.value
      };
      filterItem.visible = true;

      if (filterItem.groupKey === 'packaging') {
        // Only for UK since Admin sends us the translations for FR
        if (this.site === 'en') {
          filterItem.name =
            filterItem.name === 'Letterbox'
              ? t('js.filters_refresh.packaging.letterbox-alt')
              : t('js.filters_refresh.packaging.hand-tied-alt');
        }
      }
      if (filterItem.groupKey === 'petFriendly') {
        filterItem.name = t('js.filters_refresh.pet-friendly.alt');
      }

      if (filterItem.groupKey === 'newIn') {
        filterItem.name = t('js.filters_refresh.new-in.alt');
      }

      return filterItem;
    });

    if (filterGroup.key === 'colours') {
      filterGroup.filterItems = filterGroup.filterItems;
    }

    return filterGroup;
  }

  getDateFilterItems(daysLimit: number, group: FilterGroup): FilterItem[] {
    const filterItems: FilterItem[] = [];
    for (let i = 1; i < daysLimit + 1; i++) {
      const filterItem = new FilterItem();
      filterItem.groupKey = group.key;
      filterItem.key = 'specificDateItem'; // intentionally non-unique, will be
      filterItem.filterType = this.filterTypeForConfig(group.key);
      filterItem.data = {
        key: dayjs().add(i, 'day').format('YYYY-MM-DD'),
        value: dayjs().add(i, 'day')
      };
      filterItem.name = filterItem.data.value.format('D MMMM');
      filterItem.visible = true;
      filterItems.push(filterItem);
    }
    return filterItems;
  }

  getDeliveryFilters(): Promise<FilterGroup[]> {
    const group = new FilterGroup();
    group.key = 'delivery';

    const nextDayFilter = new FilterItem();
    nextDayFilter.groupKey = group.key;
    nextDayFilter.key = 'nextDayDelivery';
    nextDayFilter.name = t('js.filters_refresh.delivery.next-day');
    nextDayFilter.filterType = this.filterTypeForConfig(group.key);
    nextDayFilter.data = {
      key: 'next-day',
      value: 'nextDay'
    };
    nextDayFilter.visible = true;

    group.filterItems = [nextDayFilter];

    if (this.peakShippingService.isFreePeakDeliveryMessagingEnabled()) {
      const freePeakDelivery = new FilterItem();
      freePeakDelivery.groupKey = group.key;
      freePeakDelivery.key = 'delivery';
      freePeakDelivery.name = t('js.component.product-card-label.free-delivery');
      freePeakDelivery.filterType = 'tag';
      freePeakDelivery.data = {
        key: 'delivery',
        value: 'free-delivery'
      };
      freePeakDelivery.splodgeColour = '#fdf888';
      freePeakDelivery.visible = true;

      group.filterItems.push(freePeakDelivery);
    }

    const dateGroup = new FilterGroup();
    dateGroup.key = 'specificDate';
    dateGroup.filterItems = this.getDateFilterItems(180, dateGroup);

    return Promise.resolve([group, dateGroup]);
  }

  fromPriceAttributesPayload(priceFilters: any): FilterGroup {
    const filterGroup = new FilterGroup();
    filterGroup.key = 'price';

    filterGroup.filterItems = priceFilters.map((priceFilter, index) => {
      const filterItem = new FilterItem();
      filterItem.groupKey = filterGroup.key;
      filterItem.key = `price-${index}`;
      filterItem.name = priceFilter.name;
      filterItem.filterType = 'priceRange';
      filterItem.data = { from: priceFilter.from, to: priceFilter.to };
      filterItem.visible = priceFilter.visible === undefined ? true : priceFilter.visible;

      return filterItem;
    });
    return filterGroup;
  }

  getFilterAttributes(country: Country): Promise<FilterGroup[]> {
    return Promise.all([this.getSkuAttributes(country), this.getPriceAttributes(country), this.getDeliveryFilters()]).then((res) => {
      return res && res.length ? (res[0] || []).concat(res[1] || []).concat(res[2] || []) : [];
    });
  }

  getSkuAttributes(country: Country): Promise<FilterGroup[]> {
    return (this.skuAttributesPromise = this.skuAttributesPromise
      ? this.skuAttributesPromise
      : this.backend
          .get(null, `/v2/skus/attributes`, {
            responseIsJsonApi: true,
            params: {
              'filter[shipping_country_id]': country.id
            }
          })
          .then((res) => {
            return res ? res.map((a: ProductFilterAttributes) => this.fromAttributesPayload(a)) : null;
          })
          .catch((e) => {}));
  }

  getPriceAttributes(country: Country): Promise<any> {
    this.priceAttributesPromise[country.id] =
      this.priceAttributesPromise[country.id] ||
      this.contentService
        .getAll()
        .then((contents) => {
          if (!contents) {
            return;
          }
          const priceAttributes = contents.filter((content) => content.attributes.name === 'price_filters');

          let priceFilters;
          priceAttributes.find((pa) => {
            priceFilters = pa.attributes.content.find((c) => c.shipping_country_id === country.id);
          });

          return this.fromPriceAttributesPayload(priceFilters['price_configs']);
        })
        .catch(() => {
          // do nothing
          return null;
        });

    return this.priceAttributesPromise[country.id];
  }
}
