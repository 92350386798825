import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ModalComponent } from 'Shared/services/modal.service';
import { FormGroup } from '@angular/forms';
import { Address } from 'Shared/classes/address';
import { AddressService } from 'Shared/services/address.service';
import { CountryService, Country } from 'Shared/services/country.service';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { AddressFormNewComponent } from 'Shared/components/address-form-new/address-form-new.component';
import { ShippingOption } from 'Shared/classes/shipping-option';

// Modal Typings
type ModalResolveData = Address;
type ModalRejectData = Record<string, never>;

/* bw:view-encapsulation */
@Component({
  selector: 'bw-address-modal',
  templateUrl: './address-modal.component.html'
})
export class AddressModalComponent extends ModalComponent<ModalResolveData, ModalRejectData> implements OnInit, AfterViewInit {
  address: Address;
  shippingOption: ShippingOption;
  userIsGuest: boolean = false;
  hideNameField: boolean = false;
  showVatField: boolean;
  showPhoneField: boolean;
  doNotCreate: boolean = false;
  limitCountry: Country;
  isUpdating: boolean = false;
  modalTitleOverride: string = '';
  loading: boolean;
  markFormAsTouched?: boolean = false;
  showChangeCountryOnInvalidPostcode: boolean = false;
  handleIosScroll: boolean = true; // Set this to true to handle iOS body scroll on modal base component

  modalForm: FormGroup = new FormGroup({});

  /**
   * Constructor
   */
  constructor(private addressService: AddressService, private countryService: CountryService, private toastr: ToastrService) {
    super();
  }

  /**
   * Submit the form
   * @param form
   */
  onSubmit(): Promise<void | Address> {
    const addressForm = this.modalForm.get('address') as AddressFormNewComponent;

    addressForm.markAsSubmitted();

    if (!addressForm.valid) {
      return Promise.resolve();
    }

    const address = addressForm.getObject();

    // If we are creating an address  OR the user is logged out, just return
    if ((this.doNotCreate && !this.address.id) || this.userIsGuest) {
      super.closeAsResolve(address);
      return Promise.resolve();
    }

    address.id = this.address.id || undefined;
    const promise = this.address.id ? this.addressService.update(address) : this.addressService.create(address);

    this.loading = true;

    return promise
      .then((addr): void => {
        super.closeAsResolve(addr);
      })
      .catch((error): void => {
        this.loading = false;
        this.toastr.error(error.message, error.title);
      });
  }

  /**
   * On cancel
   */
  onCancel(): void {
    super.closeAsReject({});
  }

  /**
   * On backdrop click (called from modal.base)
   */
  onBackdropClick(): Promise<void | Address> {
    return this.onSubmit();
  }

  /**
   * After the address form is part of the modal form, mark as touched if needed
   */
  ngAfterViewInit(): void {
    if (this.isUpdating) {
      this.modalForm.get('address').markAsTouched();
    }
    if (this.markFormAsTouched) {
      const addressForm = this.modalForm.get('address') as AddressFormNewComponent;
      addressForm.markAsSubmitted();
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.address = (this.address || new Address()).clone();
    this.address.country = this.address.country || this.countryService.forSite;
    this.isUpdating = !!(this.address.id || this.address.name);
  }
}
