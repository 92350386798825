import { Injectable } from '@angular/core';
import { APIJSONRequest, APISerialisedJSONResponse, BackendService } from 'Shared/services/backend.service';
import { Order, SubscriptionPrepaymentCharge, SubscriptionPrepaymentOption } from 'Shared/classes/order';
import { UserService } from 'Shared/services/user.service';
import { Charge, ChargeState, BankRedirectTypes } from 'Shared/classes/charges';
import { Price } from 'Shared/classes/price';
import * as dayjs from 'dayjs';

const API_VERSION = '2023-05-26';

@Injectable({
  providedIn: 'root'
})
export class ChargesModelService {
  constructor(
    private backendService: BackendService,
    private userService: UserService
  ) {}

  /**
   * To payload
   * @param {Order} order
   * @param {PaymentMethod} paymentMethod
   * @returns {APIJSONRequest<'/:version/charges'>}
   */
  private toPayload(order: Order, type: BankRedirectTypes): APIJSONRequest<'/:version/charges'> {
    return {
      order_id: order.id,
      kind: 'micro',
      payment_method: type
    };
  }

  /**
   * From create payload
   * @param {APISerialisedJSONResponse<'/:version/charges'>} res
   * @returns {Charge}
   */
  private fromCreatePayload(res: APISerialisedJSONResponse<'/:version/charges'>): Charge {
    return {
      id: res.id,
      clientSerect: res.client_secret
    };
  }

  /**
   * Create a micro payment charge
   * @param {Order} order
   * @param {BankRedirectTypes} type
   * @returns {Promise<Charge}
   */
  create(order: Order, type: BankRedirectTypes): Promise<Charge> {
    const user = this.userService.getUser();
    return this.backendService
      .post(user, `/${API_VERSION}/charges` as '/:version/charges/:id', this.toPayload(order, type))
      .then((res): Charge => this.fromCreatePayload(res));
  }

  /**
   * Preview charge call for a subscrition prepay/charge package
   * @param order
   * @param prepaymentOption
   * @returns
   */
  preview(order: Order, prepaymentOption: SubscriptionPrepaymentOption): Promise<SubscriptionPrepaymentCharge> {
    const user = this.userService.getUser();
    return this.backendService
      .post(user, '/2024-10-22/charges/prepayment' as '/:version/charges/?subscription_id', {
        subscription_id: Number(order.subscription?.id),
        prepayment_package_id: Number(prepaymentOption?.id)
      })
      .then((res): SubscriptionPrepaymentCharge => this.fromPayloadSubscriptionPrepaymentCharge(res));
  }

  /**
   * Prepay a charge for a subscrition prepay/charge package
   * @param order
   * @param prepaymentOption
   * @returns
   */
  prepay(charge: SubscriptionPrepaymentCharge): Promise<SubscriptionPrepaymentCharge> {
    const user = this.userService.getUser();
    if (!charge) {
      return Promise.reject();
    }
    return this.backendService
      .post(user, `/2024-11-18/charges/${charge.id}/prepayment/pay`, {})
      .then((res): SubscriptionPrepaymentCharge => this.fromPayloadSubscriptionPrepaymentCharge(res));
  }

  /**
   * From get payload
   * @param {APISerialisedJSONResponse<'/version/charges/:id'>} res
   * @returns {SubscriptionPrepaymentCharge}
   */
  fromPayloadSubscriptionPrepaymentCharge(res: APISerialisedJSONResponse<'/:version/charges/:id'>): SubscriptionPrepaymentCharge {
    return {
      id: res.id,
      state: res.state,
      kind: res.kind,
      price: new Price(res.currency, null, res.total_cost_pre_discount_pennies, { discounted: res.total_cost_pennies }),
      redirect: res.confirmation_url ?? undefined,
      startDate: res.first_prepaid_delivery_date ? dayjs(res.first_prepaid_delivery_date) : undefined
    };
  }

  /**
   * From get payload
   * @param {APISerialisedJSONResponse<'/version/charges/:id'>} res
   * @returns {ChargeState}
   */
  fromPayload(res: APISerialisedJSONResponse<'/:version/charges/:id'>): ChargeState {
    return {
      id: res.id,
      state: res.state
    };
  }

  /**
   * Get a micro payment charge
   * @param id
   * @returns {ChargeState}
   */
  get(id: number): Promise<ChargeState> {
    const user = this.userService.getUser();
    return this.backendService
      .get(user, `/${API_VERSION}/charges/${id}` as '/:version/charges/:id')
      .then((res): ChargeState => this.fromPayload(res));
  }
}
