import { Component, Input, OnChanges } from '@angular/core';
import { MetaBaseComponent } from 'Shared/components/meta-base/meta-base.component';
import { t } from 'Shared/utils/translations';

@Component({
  selector: 'bw-meta-breadcrumb',
  templateUrl: './meta-breadcrumb.component.html',
  styleUrls: ['./meta-breadcrumb.component.scss']
})
export class MetaBreadcrumbComponent extends MetaBaseComponent implements OnChanges {
  @Input() activeBreadcrumbs: any[] = [];

  /**
   * On changes, re-generate the script
   */
  ngOnChanges(changes: any): any {
    if (changes && changes.activeBreadcrumbs && !changes.activeBreadcrumbs.firstChange) {
      this.ngOnDestroy();
      this.ngOnInit();
    }
  }

  /**
   * Generate the script tag
   */
  generateScript(): any {
    const baseUrl = this.locationService.appUrl;

    const homePageListItem = {
      '@type': 'ListItem',
      position: 0,
      item: {
        '@id': `${baseUrl}`,
        name: t('js.components.meta-breadcrumb.home')
      }
    };

    const itemListElementArray = this.activeBreadcrumbs.map((breadcrumbItem, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': this.locationService.normaliseUrlForSite(breadcrumbItem.url),
          name: breadcrumbItem.name
        }
      };
    });

    itemListElementArray.unshift(homePageListItem);

    const content = {
      '@context': 'https://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement: itemListElementArray
    };

    return content;
  }
}
