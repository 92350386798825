
<div class="bw-modal">
  <div class="bw-modal__backdrop" [@backdrop]="backdropVisibility">
    <div class="bw-modal__backdrop-content" *ngIf="ignoreBackdropClick"></div>
    <div class="bw-modal__backdrop-content" *ngIf="!ignoreBackdropClick" bwStaticClick (staticClick)="onBackdropClick(false)"></div>
  </div>
  <div class="bw-modal__content-container {{class}}" [@modal]="modalVisibility" (@modal.done)="dismissAnimationCompleted($event)">
    <div class="bw-modal__content bw-modal__scrollview">
      <ng-content></ng-content>
    </div>
  </div>
</div>