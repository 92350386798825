/* eslint-disable */
export const siteConfig = {
  "1": {
    "locale.backend": "en",
    "locale.mentionme": "en_GB",
    "locale.htmlang": "en",
    "locale.hreflang": "en-gb",
    "tv.signup.discount.pennies": 1000,
    "multiorder.discount.pennies": 500,
    "multiorder.discount.string": "£5",
    "newsletter.discount.promo.pennies": 1000,
    "newsletter.discount.promo2.pennies": 500,
    "occasions.credit.pennies.pennies": 500,
    "confirmation.banners.mobile.openAppToSaveLink": "https://bloomandwild.onelink.me/octp/92428c2a",
    "confirmation.banners.save5.discountCode": "bw92h30v",
    "confirmation.banners.save5.image": "https://images.ctfassets.net/ucgi79tscdcj/2WCUWEVYaeYaPYf5VTR6iT/7ddf954b4ae7e7a611c79daca4bea4d2/send-more-flower-image.png",
    "footer.telephone.number.text": "020 7352 9499",
    "footer.telephone.number.link": "tel:02073529499",
    "nav.flowerjournal.link": "/the-blog",
    "nav.flowerjournal.logo": "https://images.ctfassets.net/ucgi79tscdcj/E0lbjzvMzIkComEIsQa2O/6b6d676804dd7c5c6b5a8207e581817b/B_W_Flower-Journal-logo.png",
    "newsletter.signup.code": "",
    "productcard.subscription.tag": "flower-subscriptions",
    "productcard.ourSubscription.tag": "our-subscriptions",
    "sitemap.file": "/en-gb-pages.json",
    "product.carousel.subscription.tag": "flower-subscriptions",
    "contentful.replacements.text": [],
    "social.facebook.url": "https://www.facebook.com/bloomandwild"
  },
  "2": {
    "locale.backend": "en",
    "locale.mentionme": "en_IE",
    "locale.htmlang": "en",
    "locale.hreflang": "en-gb",
    "tv.signup.discount.pennies": 1000,
    "multiorder.discount.pennies": 500,
    "multiorder.discount.string": "5€",
    "newsletter.discount.promo.pennies": 1000,
    "newsletter.discount.promo2.pennies": 500,
    "occasions.credit.pennies.pennies": 500,
    "confirmation.banners.mobile.openAppToSaveLink": "https://bloomandwild.onelink.me/octp/92428c2a",
    "confirmation.banners.save5.discountCode": "bw92h30v",
    "confirmation.banners.save5.image": "https://images.ctfassets.net/ucgi79tscdcj/2WCUWEVYaeYaPYf5VTR6iT/7ddf954b4ae7e7a611c79daca4bea4d2/send-more-flower-image.png",
    "footer.telephone.number.text": "020 7352 9499",
    "footer.telephone.number.link": "tel:02073529499",
    "nav.flowerjournal.link": "/the-blog",
    "nav.flowerjournal.logo": "https://images.ctfassets.net/ucgi79tscdcj/E0lbjzvMzIkComEIsQa2O/6b6d676804dd7c5c6b5a8207e581817b/B_W_Flower-Journal-logo.png",
    "productcard.subscription.tag": "flower-subscriptions",
    "newsletter.signup.code": "",
    "sitemap.file": "/en-gb-pages.json",
    "product.carousel.subscription.tag": "flower-subscriptions",
    "contentful.replacements.text": [],
    "social.facebook.url": "https://www.facebook.com/bloomandwild"
  },
  "6": {
    "locale.backend": "en",
    "locale.mentionme": "en_DE",
    "locale.htmlang": "en",
    "locale.hreflang": "en-gb",
    "tv.signup.discount.pennies": 1000,
    "multiorder.discount.pennies": 500,
    "multiorder.discount.string": "5€",
    "newsletter.discount.promo.pennies": 1000,
    "newsletter.discount.promo2.pennies": 500,
    "occasions.credit.pennies.pennies": 500,
    "confirmation.banners.mobile.openAppToSaveLink": "https://bloomandwild.onelink.me/IygH/2fd8b48d",
    "confirmation.banners.save5.discountCode": "bw92h30v",
    "confirmation.banners.save5.image": "https://images.ctfassets.net/ucgi79tscdcj/2WCUWEVYaeYaPYf5VTR6iT/7ddf954b4ae7e7a611c79daca4bea4d2/send-more-flower-image.png",
    "footer.telephone.number.text": "020 7352 9499",
    "footer.telephone.number.link": "tel:02073529499",
    "nav.flowerjournal.link": "/the-blog",
    "nav.flowerjournal.logo": "https://images.ctfassets.net/ucgi79tscdcj/E0lbjzvMzIkComEIsQa2O/6b6d676804dd7c5c6b5a8207e581817b/B_W_Flower-Journal-logo.png",
    "productcard.subscription.tag": "bundle",
    "newsletter.signup.code": "",
    "sitemap.file": "/en-gb-pages.json",
    "product.carousel.subscription.tag": "bundle",
    "contentful.replacements.text": [],
    "social.facebook.url": "https://www.facebook.com/bloomandwild"
  }
};