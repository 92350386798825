import { Component, Input, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, NgIf, NgFor } from '@angular/common';
import { OasysImageModule, OasysLayoutModule, OasysIconModule, OasysLabelModule } from 'oasys-lib';

export interface Label {
  text: string;
  type: OasysLabelType;
  showIcon?: boolean;
}
export const labelTypes = ['bestseller', 'top_pick', 'selling_fast', 'free_delivery', 'rewards_bonus_points', 'limited_edition'] as const;
export type LabelType = (typeof labelTypes)[number];
export type OasysLabelType = 'featured' | 'free-delivery' | 'limited-edition' | 'rewards-bonus-points';

export const backendToOasysLabelTypes: Record<LabelType, OasysLabelType> = {
  bestseller: undefined,
  top_pick: 'featured',
  selling_fast: undefined,
  free_delivery: 'free-delivery',
  rewards_bonus_points: 'rewards-bonus-points',
  limited_edition: 'limited-edition'
};

@Component({
  selector: 'bw-product-card-label',
  templateUrl: './product-card-label.component.html',
  styleUrls: ['./product-card-label.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, NgIf, NgFor, OasysLayoutModule, OasysImageModule, OasysIconModule, OasysLabelModule]
})
export class ProductCardLabelComponent {
  @Input() productLabels: Label[];

  constructor() {}
}
