import { Injectable } from '@angular/core';
import { BlogItemsModel } from 'Content/models/BlogItemsModel.service';
import { BlogCardItem } from 'Shared/interfaces/blog-card-item';
import { ConfigService } from 'Shared/services/config.service';
import { ContentService } from 'Shared/services/content.service';

@Injectable({
  providedIn: 'root'
})
export class BlogItemsService {
  constructor(private blogItemsModel: BlogItemsModel, private configService: ConfigService, private contentService: ContentService) {}

  /**
   * Get the blog items
   * @param tag
   */
  getBlogItems(tag: string): Promise<BlogCardItem[]> {
    return Promise.all([
      this.blogItemsModel.getBlogItems(tag),
      this.blogItemsModel.getMajorLandingPageAsBlog(tag),
      this.contentService.getContentSegments()
    ])
      .then(([blogItems, majorLandingPages, contentSegments]): BlogCardItem[] => {
        const toExclude = contentSegments.reduce<string[]>((acc, segment): string[] => [...acc, ...segment.excluded_tags], []);

        const combinedBlogItems = [...majorLandingPages, ...blogItems].sort(
          (a, b) => new Date(b.publishedDate).getTime() - new Date(a.publishedDate).getTime()
        );
        return combinedBlogItems.filter((item): boolean => !toExclude.find((t): boolean => (item.tags || []).indexOf(t) > -1));
      })
      .catch((error): BlogCardItem[] => {
        console.error('Error getting blog items', error);
        return [];
      });
  }
}
