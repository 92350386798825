
<ng-template #createAccountForm>
  <ui-stack stack_gap="none">
    <form [formGroup]="form" [class.ng-submitted]="isSubmitted">
      <ui-form-group group_title="Reset Password Form" [group_title_hidden]="true">
        <ui-text-input formControlName="password" show_password_string="Show" hide_password_string="Hide" name="password" id="password" label="Password" hint="8 characters or more. Create a strong password by including things like numbers, upper and lower case letters, or special characters, e.g. !?#&amp;#64;£" autocomplete="new-password" type="password" [optional]="false" [minlength]="8">
          <ui-form-errors [errors]="form.get('password')?.errors" [error_messages]="{&quot;minlength&quot;:&quot;Your password must be 8 characters or more.&quot;,&quot;required&quot;:&quot;Oops! Please enter a password of 8 characters or more.&quot;,&quot;passwordStrength&quot;:&quot;Please choose a stronger password. Try adding numbers, upper case and lower case letters, or special characters, e.g. !?#&amp;#64;£&quot;,&quot;strongCommonPassword&quot;:&quot;Please choose a stronger password. Try adding numbers, upper case and lower case letters, or special characters, e.g. !?#&amp;#64;£&quot;}"></ui-form-errors>
        </ui-text-input>
      </ui-form-group>
      <ui-form-group *ngIf="isJoiningRewards &amp;&amp; user.email.preference === 0" group_title="Marketing checkbox" [group_title_hidden]="true">
        <ui-box box_space="none" box_space_bottom="near">
          <ui-checkbox #marketing formControlName="marketing" checkbox_type="secondary" id="marketing" label="Keep me updated via email with Bloom &amp; Wild Rewards, my birthday treat and VIP perks, as well as special offers and news about our products."><span>You can view our <a class="text-link--paragraph" href="/privacy-statement" target="_blank">privacy statement here</a> (to learn how we handle your personal data) and unsubscribe at any time.</span></ui-checkbox>
        </ui-box>
      </ui-form-group>
      <ui-box *ngIf="isJoiningRewards &amp;&amp; showTandC" box_space="none" box_space_top="near" box_space_bottom="near">
        <p class="text-body--supporting text-color--supporting">You can <a class="text-link--paragraph" href="/bw-rewards-terms" target="_blank">read our full T&Cs here</a>.</p>
      </ui-box>
    </form>
    <ui-button button_type="primary" button_full_width="true" button_event_type="submit" (clicked)="onSubmit()">Create account</ui-button>
  </ui-stack>
</ng-template>
<div class="bw-create-account">
  <ng-container *ngIf="!isJoiningRewards">
    <ui-box *ngIf="isGuest" box_background="core-secondary">
      <ui-stack>
        <div class="text-body--hero text-body--emphasis">Join the club!</div>
        <p>Psst… want to make it quicker to order next time?  Create an account</p>
        <div>
          <p>{{ user?.fullName }}</p>
          <p>{{ user?.email?.address }}</p>
        </div>
        <ng-template [ngTemplateOutlet]="createAccountForm"></ng-template>
      </ui-stack>
    </ui-box>
    <ui-box *ngIf="!isGuest" box_background="core-secondary">
      <ui-stack>
        <div class="text-body--hero text-body--emphasis">You’re all set!</div>
        <ui-stack>
          <p>We’ve created an account for you and logged you in.</p>
          <ui-stack stack_direction="x" stack_distribute="space-evenly"><img src="/assets/brand-icons/occasion-cupcake.svg"><img src="/assets/brand-icons/occasion-dark-balloons.svg"><img src="/assets/brand-icons/occasion-heart.svg"></ui-stack>
          <p>You can also save all your special dates with us, so we can remind you about them nearer the time.</p>
        </ui-stack>
        <ui-button button_type="primary" button_full_width="true" (clicked)="openOccasionModal()">ADD OCCASION</ui-button>
      </ui-stack>
    </ui-box>
  </ng-container>
  <bw-confirmation-auth-rewards *ngIf="isJoiningRewards" [loyaltyWelcomePoints]="loyaltyWelcomePoints" [loyaltyOrderPoints]="loyaltyOrderPoints" [showTandC]="!showTandC" [hasRewardsBonusPoints]="hasRewardsBonusPoints">
    <ui-box box_space="none" box_space_top="near">
      <p class="text-body--default">Create an account now, and we’ll add your points – no problem.</p>
    </ui-box>
    <p class="text-body--default">{{ user?.email?.address }}</p>
    <ng-container [ngTemplateOutlet]="createAccountForm"></ng-container>
  </bw-confirmation-auth-rewards>
</div>
<bw-loading-spinner *ngIf="loading" [fullscreen]="true"></bw-loading-spinner>