import { Injectable } from '@angular/core';
import { environment } from 'Environments/environment';
import { LocationService } from 'Shared/services/location.service';
import { LocalStorageService } from 'Shared/services/local-storage.service';
import { FeaturesService } from './features.service';

@Injectable({
  providedIn: 'root'
})
export class RangeDiscoveryApiService {
  rangeDiscoveryServer: string;

  constructor(
    private locationService: LocationService,
    private localStorageService: LocalStorageService,
    private featuresService: FeaturesService
  ) {
    this.rangeDiscoveryServer = this.getRangeDiscoveryUrl();

    if (environment.environment !== 'production') {
      const urlParams = this.locationService.getUrlParams();
      const localRangeDiscoveryURL: string = this.localStorageService?.get('rangeDiscoveryAPIUrl');

      if (urlParams['radishAPI']) {
        this.rangeDiscoveryServer = urlParams['radishAPI'];
        this.localStorageService.set('rangeDiscoveryAPIUrl', urlParams['radishAPI']);
      } else if (urlParams['radishApi']) {
        this.rangeDiscoveryServer = urlParams['radishApi'];
        this.localStorageService.set('rangeDiscoveryAPIUrl', urlParams['radishApi']);
      } else if (localRangeDiscoveryURL) {
        this.rangeDiscoveryServer = localRangeDiscoveryURL;
      }
    }
  }

  defaultRangeDiscoveryAPIUrl(): string {
    return this.getRangeDiscoveryUrl();
  }

  private getRangeDiscoveryUrl(): string {
    const apiDate: string = this.featuresService.getFeature('RANGE_DISCOVERY_API_VERSION');
    return environment.backendRangeUrl.replace(':date', apiDate);
  }
}
