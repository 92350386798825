export type CurrencyCode = 'GBP' | 'gbp' | 'EUR' | 'eur' | 'DKK' | 'dkk';

export class Price {
  currency: CurrencyCode;
  quantity: number; // if -1 is subscription
  original: number; // What it is without discount
  price: number; // What the customer should ultimatley pay
  discount: number;
  rewardPoints: number;
  rewardPointsWithBonus: number;

  constructor(
    currency: CurrencyCode,
    quantity: number,
    original: number,
    options?: { discounted?: number; rewardPoints?: number; rewardPointsWithBonus?: number }
  ) {
    this.currency = (currency || '').toUpperCase() as CurrencyCode;
    this.quantity = quantity; // TODO - Remove from here
    this.original = original;
    this.price = options?.discounted ?? original;
    this.discount = Math.abs(this.original - this.price); // As a positive amount
    this.rewardPoints = options?.rewardPoints ?? 0;
    this.rewardPointsWithBonus = options?.rewardPointsWithBonus ?? 0;
  }

  /**
   * Add prices together
   * @param prices
   * @param currency
   * @param quantity
   */
  static add(prices: Price[]): Price {
    let original = 0;
    let endingPrice = 0; // After discount taken, what the user pays
    let currency: CurrencyCode;
    prices.forEach((price) => {
      original += price.original;
      endingPrice += price.price;
      currency = currency || price.currency;
    });

    return new Price(currency, 1, original, { discounted: endingPrice });
  }

  /**
   * Clear the discount from the price
   */
  clearDiscount(): Price {
    this.price = this.original;
    this.discount = 0;
    return this;
  }
}
