/**
 * Interceptor to convert bw-button coming from contentful into a useable component
 * by camelCasing the properties
 * @param bwButton
 * @returns
 */
export default function bwButtonInterceptor(bwButton: HTMLElement): any {
  const attributes = [
    'uiSref',
    'uiParams',
    'buttonStyle',
    'buttonType',
    '[iconOnly]',
    '[fullWidth]'
  ];
  // this regex extracts the text from the []
  const regex = /\[(.*?)\]/;
  attributes.forEach((attr) => {
    if (bwButton.getAttribute(attr.toLowerCase())) {
      bwButton.setAttributeNS(
        null,
        regex.exec(attr) ? regex.exec(attr)[1] : attr,
        bwButton.getAttribute(attr.toLowerCase())
      );
      bwButton.removeAttribute(attr.toLowerCase());
    }
  });
  return bwButton;
}
