import * as clone from 'clone';
export class Email {
  public slug?: string;
  public sha256?: string;
  public address: string;
  public preference?: EmailPreference;
  public occasionPreferences?: {
    mothersDay?: Preferences;
    fathersDay?: Preferences;
    valentinesDay?: Preferences;
    grandparentsDay?: Preferences;
    grandmothersDay?: Preferences;
    discountPromotions?: Preferences;
    holidaySeason?: Preferences;
  };
  public surveyPreference: Preferences;
  public hasOrdered?: boolean;
  public hasRegistered?: boolean;
  public consent?: {
    method?: string;
    location?: string;
    copy?: string;
  };

  /**
   * Constructor
   * @param address
   */
  constructor(address: string) {
    this.address = (address || '').toLowerCase().trim();
  }

  /**
   * Clone the instance
   */
  clone(): Email {
    return clone(this);
  }
}

// Preference options
export enum Preferences {
  OptedOut = 0,
  OptedIn = 1
}

// https://sites.google.com/a/bloomandwild.com/bloom-wiki/marketing/newslettergdpr-rules
export enum EmailPreference {
  OptedOut = 0,
  OptedInOccasional = 1,
  OptedInMoreOccasional = 2,
  OptedInAllEmails = 3,
  OptedInAwaitingOptIn = 4,
  OptedInGDPRTrail = 5,
  NoOption = -1
}
