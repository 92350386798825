import { Component, OnInit, OnDestroy } from '@angular/core';
import { FavouritesService, Favourite } from 'Shared/services/favourites.service';
import { CountryService } from 'Shared/services/country.service';
import { ConfigModelService } from 'Shared/models/config-model.service';
import { UserService } from 'Shared/services/user.service';
import { StateService } from 'Shared/services/state.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { LocalStorageService } from 'Shared/services/local-storage.service';
import { AnalyticsService } from 'Shared/services/analytics.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-favourites-indicator',
  templateUrl: './favourites-indicator.component.html'
})
export class FavouritesIndicatorComponent implements OnInit, OnDestroy {
  shoppableFavourites: Favourite[];
  showInfoMessage: boolean = false;
  subscription: any;
  isCheckoutPage: boolean = false;

  constructor(
    private analyticsService: AnalyticsService,
    public favouritesService: FavouritesService,
    private countryService: CountryService,
    private remoteConfig: ConfigModelService,
    public userService: UserService,
    public stateService: StateService,
    private localStorage: LocalStorageService,
    public experimentService: ExperimentsService
  ) {
    this.subscription = this.favouritesService.shoppableFavourites$.subscribe(res => {
      this.shoppableFavourites = res;
      this.toggleInfoMessage();
    });
  }

  /**
   * Get Favourites
   */
  getFavourites(): Promise<void> {
    const forShipping = this.countryService.forShipping;
    return this.remoteConfig
      .hasRemoteConfig()
      .then(() => this.userService.getUserOrAuthenticate())
      .catch(() => {}) // Don't worry if this fails
      .then(() => this.favouritesService.getFavouriteProducts(forShipping, true))
      .then(favourites => {
        this.favouritesService.updateCurrentshoppableFavourites(favourites);
      });
  }

  /**
   * on Icon Click
   */
  onIconClick(): void {
    const user = this.userService.getUser();
    if (user && user.loggedIn) {
      this.stateService.go('account.favourites');
    } else {
      this.stateService.go('favourites');
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  /**
   * On init
   */
  ngOnInit(): Promise<void> {
    this.userService.user$.subscribe(user => {
      this.getFavourites();
    });
    this.stateService.onSuccess$.subscribe(() => {
      const stateName = this.stateService.getCurrent().name;
      this.isCheckoutPage =
        stateName.indexOf('checkout') > -1 && stateName !== 'checkout.confirmation';
    });

    return this.getFavourites();
  }

  toggleInfoMessage(): void {
    const localStorage = this.localStorage.get('favsAddEducation');
    this.showInfoMessage = localStorage && !localStorage['lastSeen'];
  }

  closeInfoMessage(): void {
    this.showInfoMessage = false;
    const localStorage = this.localStorage.get('favsAddEducation');
    if (localStorage && !localStorage['lastSeen']) {
      this.localStorage.set('favsAddEducation', { lastSeen: new Date() });
    }
  }
}
