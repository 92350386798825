import { Injectable } from '@angular/core';
import { ConfigService } from 'Shared/services/config.service';
import { CountryService } from 'Shared/services/country.service';

interface Setting {
  locale: string;
  shipping_country_id: number;
  data: any;
  experiments: SettingExperiment[];
}

interface SettingExperiment {
  name: string;
  variants: SettingExperimentVariant[];
  data: any;
}
interface SettingExperimentVariant {
  variant: number;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  locale: string;

  constructor(private configService: ConfigService, private countryService: CountryService) {
    this.locale = this.configService.getConfig().locale;
  }

  /**
   * Get a Setting from the config endpoint in a structured way
   * @param settingName
   */
  getSetting(settingName: string): any {
    const forShipping = this.countryService.forShipping;
    const config = this.configService.getConfig();

    const setting = (config[`WEB_${settingName.toUpperCase()}`] as Setting[]) || [];
    const found = setting.find(
      (s) => s.locale === this.locale && s.shipping_country_id === forShipping.id
    );

    return found ? found : undefined;
  }
}
