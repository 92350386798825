import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { StateService } from 'Shared/services/state.service';

@Injectable({
  providedIn: 'root'
})
export class ContentRedirectGuard implements CanActivate {
  constructor(private state: StateService, private router: Router) {}
  canActivate(): any {
    const path = this.state.getCurrent().url.replace('content', '');
    this.router.navigateByUrl(path);
  }
}
