import { Component, OnInit } from '@angular/core';
import { BlogItemsService } from 'Content/services/BlogItemsService.service';
import { ViewportDetectionService } from 'Shared/services/viewport-detection.service';
import { StateService } from 'Project/shared/services/state.service';
import { ConfigService } from 'Shared/services/config.service';
import { CountryService, Country } from 'Shared/services/country.service';
import { t } from 'Shared/utils/translations';
import { BlogCardItem } from 'Shared/interfaces/blog-card-item';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-blogs-landing-page',
  templateUrl: './blogs-landing-page.component.html'
})
export class BlogsLandingPageComponent implements OnInit {
  showEach: number;
  showMaximum: number;
  items: BlogCardItem[];
  category: string;
  title: string;
  deviceType: any;
  config: any;
  blogListNavItems: any;
  shippingCountry: Country;
  activeCategoryUrl: string;
  baseTagUrl: string;

  placeholderAltText: string;

  constructor(
    private blogItemsService: BlogItemsService,
    private viewPortDetectionService: ViewportDetectionService,
    private stateService: StateService,
    private configService: ConfigService,
    private countryService: CountryService
  ) {
    this.config = this.configService.getConfig();
    this.showEach = this.config.contentfulParameters.blogLimit;
    this.showMaximum = this.showEach;
  }

  setDeviceBasedOnviewport(): void {
    this.deviceType = this.viewPortDetectionService.viewportSizeIs$.getValue();
  }

  setBlogListNavItems(): void {
    this.shippingCountry = this.countryService.forShipping;
    const blogListNavItemsConfig = this.config.blog_list_nav_items || {};

    this.blogListNavItems = blogListNavItemsConfig.filter((entry) => {
      return entry.hasOwnProperty('shippingCountryId') && entry.shippingCountryId === this.shippingCountry?.id;
    })[0]?.items;

    if (!this.blogListNavItems && this.config.locale) {
      // Filter the entries based on locale if the shipping country is not found
      const filteredEntries = blogListNavItemsConfig.filter((entry) => entry.locale === this.config.locale);
      this.blogListNavItems = filteredEntries.length > 0 && filteredEntries[0].items ? filteredEntries[0].items : [];
    }
  }

  /**
   * Show more
   */
  showMore(): void {
    this.showMaximum += this.showEach;
  }

  /**
   * On Init
   */
  ngOnInit(): Promise<BlogCardItem[] | void> {
    this.activeCategoryUrl = `/${this.stateService.getCurrent().url}`;
    this.setDeviceBasedOnviewport();
    this.setBlogListNavItems();
    this.baseTagUrl = t('js.components.blogs-list-page.tag.base_url');
    this.placeholderAltText = t('js.components.blogs-landing-page.placeholder-alt-text');

    if (this.stateService.getCurrent().name === 'blog.tagged') {
      this.category = this.stateService.getCurrent().params?.tagged;
    }

    return this.blogItemsService.getBlogItems(this.category).then((items): void => {
      this.items = items;
    });
  }
}
