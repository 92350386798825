/***
 * Documentation:
 * https://s3.amazonaws.com/prod.tracker2/resource/96666616/tvsquared_tracker_instructions.pdf?response-content-disposition=inline&response-content-type=application%2Fpdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJJBSFJ4TCVKKGAIA%2F20190502%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20190502T092309Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host&X-Amz-Signature=a658508d4bb0e07739da7dcb94e73d640d0efd1ea1f30b7c62d4287840b926ed
 * Piv Card: https://www.pivotaltracker.com/story/show/165746980
 *
 * They have used this as their underlying analytics framework:
 * https://developer.matomo.org/guides/tracking-javascript-guide#manually-trigger-events
 */
import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { ConfigService } from 'Shared/services/config.service';
import { User } from 'Shared/classes/user';
import { Purchase } from 'Shared/classes/purchase';
import { Order } from 'Shared/classes/order';
import { Product } from 'Shared/classes/product';

@Injectable({
  providedIn: 'root'
})
export class TvsquaredService {
  debug: boolean = false;
  window: any;

  url: string;
  initPromise: Promise<any>;
  serviceInitialized: boolean = false;

  constructor(
    private windowRef: WindowRefService,
    private domUtils: DomUtilsService,
    private configService: ConfigService
  ) {
    this.url = this.configService.getConfig().tvSquaredUrl;
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<tv-squared>', ...args);
    }
  }

  init(): Promise<any> {
    const scriptUrl = `${this.url}/tv2track.js`;
    this.initPromise =
      this.initPromise ||
      this.domUtils.loadScript(scriptUrl, 'tvsquared').then(() => {
        this.log('Init', scriptUrl);
        // on first load set the service as initialized.
        this.serviceInitialized = true;
      });

    return this.initPromise;
  }

  trackPage(): void {
    if (this.window._tvq && this.serviceInitialized) {
      this.window._tvq.push(['trackPageView']);
      this.log('Tracked Page View');
    }
  }

  /**
   * Set a custom variable
   * @param action
   * @param type
   * @param obj
   */
  setCustomVariable(action: string, type: string, obj: any): void {
    if (this.window._tvq && this.serviceInitialized) {
      this.log('setCustomVariable', 'type', type, 'action', action, 'obj', obj);
      this.window._tvq.push([
        function (): any {
          this.setCustomVariable(5, action, JSON.stringify(obj), type);
        }
      ]);
      // Force the event to be sent to TVSquared
      this.window._tvq.push(['trackGoal', action]);
    }
  }

  /**
   * Track an event
   * @param category
   * @param action
   * @param label
   */
  trackEvent(category: string, action: string, label: string): void {
    if (this.window._tvq && this.serviceInitialized) {
      this.log('trackEvent', category, action, label);
      this.window._tvq.push(['trackEvent', category, action, label]);
    }
  }

  /**
   * Identify a user
   */
  identify(user: User): void {
    if (user && user.slug) {
      this.setCustomVariable('session', 'visit', {
        user: user.slug
      });
    }
  }

  /**
   * Track a Purchase
   * @param purchase
   */
  trackPurchase(purchase: Purchase): void {
    if (this.window._tvq) {
      const payload = {
        rev: (purchase.price.price / 100).toFixed(2),
        prod: purchase.orders.map((o) => o.product.slug).join(','),
        id: purchase.id,
        promo: purchase.discount && purchase.discount.code ? purchase.discount.code : ''
      };
      this.setCustomVariable('Order', 'page', payload);
    }
  }
}
