import { Error } from 'Shared/classes/error';

export class File {
  name: string;
  file: any;

  uploadedAs: any;
  uploadPromise: any;
  uploadProgress: number;
  uploadError: Error;
}
