
<form class="address-form" bwExperimentEvent="addressEntryShow" [ngClass]="{'ng-submitted': hasSubmitted}" [formGroup]="formGroup">
  <bw-form-text-input *ngIf="!hideNameField" [bwFormControl]="formGroup.controls.name" label="Recipient name" autocomplete="off" inputType="text">
    <ng-container role="errors">
      <div for="pattern">Please enter a full name</div>
      <div for="invalid">Name is required</div>
    </ng-container>
  </bw-form-text-input>
  <bw-form-text-input [bwFormControl]="formGroup.controls.company" label="Company" autocomplete="organization" inputType="text"></bw-form-text-input>
  <bw-form-text-input *ngIf="showVatNumber" [bwFormControl]="formGroup.controls.vat" label="Customer VAT number" inputType="text"></bw-form-text-input>
  <bw-form-text-input [bwFormControl]="formGroup.controls.line1" label="Line 1" autocomplete="address-line1" inputType="text">
    <ng-container role="errors">
      <div for="invalid">Required</div>
    </ng-container>
  </bw-form-text-input>
  <bw-form-text-input [label]="showDoorCodeAsLine2Label ? 'Door code / intercom (recommended)' : 'Line 2'" [bwFormControl]="formGroup.controls.line2" autocomplete="address-line2" inputType="text"></bw-form-text-input>
  <!-- Postcode field before city-->
  <ng-container *ngIf="postcodeBeforeCityEnabled; then postCode"></ng-container>
  <bw-form-text-input [bwFormControl]="formGroup.controls.city" label="City" autocomplete="locality" inputType="text">
    <ng-container role="errors">
      <div for="invalid">Required</div>
    </ng-container>
  </bw-form-text-input>
  <!-- Postcode field after city-->
  <ng-container *ngIf="!postcodeBeforeCityEnabled; then postCode"></ng-container>
  <ng-template #postCode>
    <bw-form-text-input [bwFormControl]="formGroup.controls.postcode" [label]="showAddressPostcodeAltCopy ? 'Eircode' : 'Postcode'" label="Postcode" (didBlur)="onPostcodeBlur()" autocomplete="off" inputType="text">
      <ng-container *ngIf="!showAddressPostcodeAltCopy" role="errors">
        <div class="postcode-error" *ngIf="countryInvalidPostcode" for="pattern">
          <bw-i18n-selector context="checkout"></bw-i18n-selector>
        </div>
        <div *ngIf="!countryInvalidPostcode" for="pattern">Postcode appears to be invalid</div>
        <div for="invalid">Postcode is required</div>
      </ng-container>
      <!-- used for Ireland label (uses 'Eircode' and not 'Postcode')-->
      <ng-container *ngIf="showAddressPostcodeAltCopy" role="errors">
        <div class="postcode-error" *ngIf="countryInvalidPostcode" for="pattern">
          <bw-i18n-selector context="checkout-ie"></bw-i18n-selector>
        </div>
        <div *ngIf="!countryInvalidPostcode" for="pattern">Eircode appears to be invalid</div>
        <div for="invalid">Eircode is required</div>
      </ng-container>
    </bw-form-text-input>
  </ng-template>
  <bw-form-text-input *ngIf="showAddressPhone" [bwFormControl]="formGroup.controls.phone" [label]="addressPhoneRequired ? 'Phone number (mandatory)' : 'Phone number (recommended)'" [placeholder]="telephonePlaceholderPrefix" [placeholderIcon]="$any(formGroup.controls.country.value.codes[0])" (didBlur)="onPhoneBlur()" autocomplete="phone" inputType="tel"><span role="instructions">We use this to make sure we can successfully deliver to the recipient but we won't spoil the surprise.</span>
    <ng-container role="errors">
      <div for="invalid">Phone number appears to be invalid</div>
    </ng-container>
  </bw-form-text-input>
  <bw-form-dropdown *ngIf="countries.length &gt; 1" [bwFormControl]="formGroup.controls.country" inputType="display" [data]="countriesOptions" label="Country" (didSelect)="onCountryChanged()" [expandContainerWhenOpen]="true" [displayFn]="displayAsCountryName"></bw-form-dropdown>
  <bw-form-text-input *ngIf="countries.length == 1" [bwFormControl]="formGroup.controls.country" label="Country" [displayFn]="displayAsCountryName" inputType="display"></bw-form-text-input>
</form>