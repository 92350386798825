import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, AfterViewInit, Input } from '@angular/core';
import { OasysLayoutModule, OasysIconModule } from 'oasys-lib';
import { WindowRefService } from 'Shared/services/window.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-video-wrapper-v2',
  templateUrl: './video-wrapper-v2.component.html',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, OasysLayoutModule, OasysIconModule]
})
export class VideoWrapperV2Component implements AfterViewInit {
  @Input() videourl: string;
  @Input() firstframeimage: string;
  @Input() alttext: string;

  videoIsPlaying = false;
  videoLoaded = false;

  constructor(private windowRef: WindowRefService) {}

  playVideo(event?: MouseEvent | TouchEvent): void {
    if (event) {
      event.stopPropagation();
    }
    const video: HTMLVideoElement = this.windowRef.nativeWindow.document.getElementById('video-wrapper-v2-video') as HTMLVideoElement;
    this.videoIsPlaying = true;

    if (video) {
      video.autoplay = true;
      video.muted = true;
      video
        .play()
        .then((): void => {
          this.videoLoaded = true;
          // Actions to be performed after video is played(e.g. events)
        })
        .catch((): void => {
          // Actions to be performed if video does not play
          this.videoIsPlaying = false;
        });
    }
  }

  pauseVideo(event?: MouseEvent | TouchEvent): void {
    if (event) {
      event.stopPropagation();
    }
    this.videoIsPlaying = false;

    const video: HTMLVideoElement = this.windowRef.nativeWindow.document.getElementById('video-wrapper-v2-video') as HTMLVideoElement;

    if (video) {
      video.pause();
    }
  }

  ngAfterViewInit(): void {
    setTimeout((): void => {
      this.playVideo();
    }, 300);
  }
}
