
<div class="product-info-section">
  <div class="product-info-section__container" *ngIf="product">
    <div class="product-info-section__content">
      <div class="product-info-section__heading">{{ product.name }}</div>
      <div class="product-info-section__description" [innerHTML]="product.shortDescription"></div>
    </div>
    <div class="product-info-section__image">
      <div class="product-info-section__image-items" *ngIf="product?.imageUrls?.length &gt; 0"><img [bwEcommImage]="product.imageUrls[1]" width="auto"><img class="hidden-sm hidden-md hidden-lg" [bwEcommImage]="product.imageUrls[2] || product.imageUrls[0]" width="auto"></div>
    </div>
  </div>
  <div class="product-info-section__separator"></div>
  <div class="product-info-section__review" *ngIf="product?.rating?.count">
    <bw-review-stars [rating]="product.rating.average" [roundingScale]="0.25"></bw-review-stars><span *ngIf="product.rating.count === 1">{{product.rating.count}} Review</span><span *ngIf="product.rating.count !== 1">{{product.rating.count}} Reviews</span>
  </div>
  <div class="product-info-section__promotion" *featureEnabled="'SUBSCRIPTION_GRAPHIC'"><img src="//images.ctfassets.net/ucgi79tscdcj/1lNfhZTHgJBZLpK4uEDlVY/c761e4d73dbfb48bc8ed93c6f0ae5c91/Bloom___Wild_customer_reviews.gif"></div>
</div>