import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { StateService } from 'Shared/services/state.service';

@Injectable({
  providedIn: 'root'
})
export class HasSuccessfulPurchaseGuard implements CanActivate {
  constructor(private stateService: StateService) {}
  canActivate(): Promise<any> {
    if (this.stateService.getCurrent().data['purchase']) {
      return Promise.resolve(true);
    }
    this.stateService.go('checkout.base');
    return Promise.resolve(false);
  }
}
