import { Injectable } from '@angular/core';
import { BackendService } from 'Shared/services/backend.service';

@Injectable({
  providedIn: 'root'
})
export class ContentfulFormModel {
  constructor(private backend: BackendService) {}

  submitForm(formData: any, formKey: string): Promise<any> {
    return this.backend.post(
      null,
      '/v2/form_entries',
      {
        data: {
          attributes: {
            data_attributes: formData,
            email: formData.email,
            form_attributes: {
              slug: formKey
            }
          },
          type: 'form_entries'
        }
      },
      {
        headers: {
          'Content-Type': 'application/vnd.api+json'
        }
      }
    );
  }
}
