
<div class="bw-feedback">
  <div class="bw-feedback__container" *ngIf="!submitted" [formGroup]="formGroup">
    <ng-template #intro>
      <div class="bw-feedback__info">We’d love your feedback!</div>
      <div class="bw-feedback__title" [innerHtml]="question"></div>
    </ng-template>
    <ng-container *ngIf="!showMoreFeedback">
      <ng-container *ngTemplateOutlet="intro"></ng-container>
      <div class="bw-feedback__points">
        <div class="bw-feedback__points-star" (click)="submitBasicFeedback(point)" *ngFor="let point of maxTotalPoints">
          <bw-icon *ngIf="totalPoints &lt; point" [iconType]="'star-empty'"></bw-icon>
          <bw-icon *ngIf="totalPoints &gt;= point" [iconType]="'star-fill'"></bw-icon>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showMoreFeedback">
      <ng-container *ngIf="showMoreStack" [ngTemplateOutlet]="intro"></ng-container>
      <div class="bw-feedback__points small" *ngIf="showMoreStack">
        <div class="bw-feedback__points-star" (click)="setTotalPoint(point)" *ngFor="let point of maxTotalPoints">
          <bw-icon *ngIf="totalPoints &lt; point" [iconType]="'star-empty'"></bw-icon>
          <bw-icon *ngIf="totalPoints &gt;= point" [iconType]="'star-fill'"></bw-icon>
        </div>
      </div>
      <div class="bw-feedback__message-area" *ngIf="!submitted">
        <bw-form-text-input [bwFormControl]="formGroup.controls.message" [multiline]="true" label="What can we do to improve your experience?" inputType="text"></bw-form-text-input>
      </div>
      <div class="bw-feedback__submit-button">
        <bw-button buttonStyle="primary" size="medium" (clicked)="submit()" bwTrackAs="components.delivery-tracking.feedback.btn-share">Share feedback</bw-button>
      </div>
    </ng-container>
  </div>
  <div *ngIf="submitted">
    <div class="bw-feedback__submit-message">Sent! Thanks again, your feedback really does mean a lot to us.</div>
  </div>
  <bw-loading-spinner *ngIf="isLoading"></bw-loading-spinner>
</div>