import { t } from 'Shared/utils/translations';

export const countries = {
  data: [
    {
      id: '1',
      type: 'countries',
      attributes: {
        name: t('js.country.gb'),
        code: 'gb',
        emoji: '🇬🇧',
        weight: 0,
        phone_number_prefix: '44',
        user_country: {
          id: 1,
          show_chat: true,
          terms_required: false,
          newsletter_opt_in: true,
          newsletter_opt_in_preselected: true,
          default_date_to_non_free: true,
          emojify: true,
          phone_number: '+442073529499',
          currency_code: 'GBP',
          payment_methods: {
            apple_pay: false,
            braintree: true,
            bancontact: false,
            card: true,
            sepa_debit: false,
            ideal: false,
            import: false,
            invoice: false,
            manual: false,
            other: false,
            klarna: false,
            android_pay: false,
            sofort: false
          },
          mention_me: {
            user_discount_pennies: 1000,
            guest_discount_pennies: 1000,
            currency: 'GBP',
            id: 'mm0ee1ada4'
          },
          welcome_discount: { ios: 'appwelcome', android: 'playapp' },
          timezone: 'London'
        },
        shipping_country: {
          id: 1,
          city_required: true,
          currency_code: 'GBP',
          line1_required: true,
          line2_required: false,
          phone_number_required: false,
          postcode_regex: '(GIR|[A-Z]\\d[A-Z\\d]??|[A-Z]{2}\\d[A-Z\\d]??)[ ]??(\\d[A-Z]{2})',
          postcode_required: true,
          multi_order_discount_pennies: 500,
          web_enabled: true,
          ios_enabled: true,
          android_enabled: true,
          show_phone_number_field: false
        }
      }
    },
    {
      id: '2',
      type: 'countries',
      attributes: {
        name: t('js.country.ie'),
        code: 'ie',
        emoji: '🇮🇪',
        weight: 1,
        phone_number_prefix: '353',
        user_country: {
          id: 2,
          show_chat: true,
          terms_required: false,
          newsletter_opt_in: true,
          newsletter_opt_in_preselected: true,
          default_date_to_non_free: false,
          emojify: true,
          phone_number: '+353768887312',
          currency_code: 'EUR',
          payment_methods: {
            apple_pay: false,
            braintree: true,
            bancontact: false,
            card: true,
            sepa_debit: false,
            ideal: false,
            import: false,
            invoice: false,
            manual: false,
            other: false,
            klarna: false,
            android_pay: false,
            sofort: false
          },
          mention_me: {
            user_discount_pennies: 1000,
            guest_discount_pennies: 1000,
            currency: 'GBP',
            id: 'mm0ee1ada4'
          },
          welcome_discount: { ios: 'appwelcome', android: 'playapp' },
          timezone: 'Dublin'
        },
        shipping_country: {
          id: 2,
          city_required: true,
          currency_code: 'EUR',
          line1_required: true,
          line2_required: false,
          phone_number_required: false,
          postcode_regex:
            '\\b(?:(A(4[125S]|6[37]|7[5S]|[8B][1-6S]|9[12468B])|C1[5S]|D([0O][1-9SB]|1[0-8OSB]|2[024O]|6W)|E(2[15S]|3[24]|4[15S]|[5S]3|91)|F(12|2[368B]|3[15S]|4[25S]|[5S][26]|9[1-4])|H(1[2468B]|23|[5S][34]|6[25S]|[79]1)|K(3[246]|4[5S]|[5S]6|67|7[8B])|N(3[79]|[49]1)|P(1[247]|2[45S]|3[126]|4[37]|[5S][16]|6[17]|7[25S]|[8B][15S])|R(14|21|3[25S]|4[25S]|[5S][16]|9[35S])|T(12|23|34|4[5S]|[5S]6)|V(1[45S]|23|3[15S]|42|9[2-5S])|W(12|23|34|91)|X(3[5S]|42|91)|Y(14|2[15S]|3[45S]))\\s?[ABCDEFHKNOPRTSVWXY\\d]{4})\\b',
          postcode_required: true,
          multi_order_discount_pennies: 500,
          web_enabled: true,
          ios_enabled: true,
          android_enabled: true,
          show_phone_number_field: false
        }
      }
    },
    {
      id: '3',
      type: 'countries',
      attributes: {
        name: t('js.country.fr'),
        code: 'fr',
        emoji: '🇫🇷',
        weight: 2,
        phone_number_prefix: '33',
        user_country: {
          id: 3,
          show_chat: true,
          terms_required: true,
          newsletter_opt_in: true,
          newsletter_opt_in_preselected: true,
          default_date_to_non_free: true,
          emojify: true,
          phone_number: '+33975186067',
          currency_code: 'EUR',
          payment_methods: {
            apple_pay: false,
            braintree: true,
            bancontact: false,
            card: true,
            sepa_debit: false,
            ideal: false,
            import: false,
            invoice: false,
            manual: false,
            other: false,
            klarna: false,
            android_pay: false,
            sofort: false
          },
          mention_me: {
            user_discount_pennies: 1000,
            guest_discount_pennies: 1000,
            currency: 'EUR',
            id: 'mm0ee1ada4'
          },
          welcome_discount: { ios: 'appbienvenue', android: 'appbien' },
          timezone: 'Paris'
        },
        shipping_country: {
          id: 5,
          city_required: true,
          currency_code: 'EUR',
          line1_required: true,
          line2_required: false,
          phone_number_required: false,
          postcode_regex: '^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$',
          postcode_required: true,
          multi_order_discount_pennies: 500,
          web_enabled: true,
          ios_enabled: true,
          android_enabled: true,
          show_phone_number_field: false
        }
      }
    },
    {
      id: '4',
      type: 'countries',
      attributes: {
        name: t('js.country.de'),
        code: 'de',
        emoji: '🇩🇪',
        weight: 3,
        phone_number_prefix: '49',
        user_country: {
          id: 4,
          show_chat: true,
          terms_required: true,
          newsletter_opt_in: true,
          newsletter_opt_in_preselected: false,
          default_date_to_non_free: true,
          emojify: true,
          phone_number: '+498941209499',
          currency_code: 'EUR',
          payment_methods: {
            apple_pay: false,
            braintree: true,
            bancontact: false,
            card: true,
            sepa_debit: false,
            ideal: false,
            import: false,
            invoice: false,
            manual: false,
            other: false,
            klarna: false,
            android_pay: false,
            sofort: false
          },
          mention_me: {
            user_discount_pennies: 1000,
            guest_discount_pennies: 1000,
            currency: 'EUR',
            id: 'mm0ee1ada4'
          },
          welcome_discount: { ios: 'appblumen', android: 'appblume' },
          timezone: 'Berlin'
        },
        shipping_country: {
          id: 6,
          city_required: true,
          currency_code: 'EUR',
          line1_required: true,
          line2_required: false,
          phone_number_required: false,
          postcode_regex:
            '^\\b((?:0[1-46-9]\\d{3})|(?:[1-357-9]\\d{4})|(?:[4][0-24-9]\\d{3})|(?:[6][013-9]\\d{3}))\\b$',
          postcode_required: true,
          multi_order_discount_pennies: 500,
          web_enabled: true,
          ios_enabled: true,
          android_enabled: true,
          show_phone_number_field: false
        }
      }
    },
    {
      id: '5',
      type: 'countries',
      attributes: {
        name: t('js.country.at'),
        code: 'at',
        emoji: '🇦🇹',
        weight: 4,
        phone_number_prefix: '43',
        user_country: {
          id: 5,
          show_chat: true,
          terms_required: true,
          newsletter_opt_in: true,
          newsletter_opt_in_preselected: false,
          default_date_to_non_free: true,
          emojify: true,
          phone_number: '+498941209499',
          currency_code: 'EUR',
          payment_methods: {
            apple_pay: false,
            braintree: true,
            bancontact: false,
            card: true,
            sepa_debit: false,
            ideal: false,
            import: false,
            invoice: false,
            manual: false,
            other: false,
            klarna: false,
            android_pay: false,
            sofort: false
          },
          mention_me: {
            user_discount_pennies: 1000,
            guest_discount_pennies: 1000,
            currency: 'EUR',
            id: 'mm0ee1ada4'
          },
          welcome_discount: { ios: 'appblumen', android: 'appblume' },
          timezone: 'Vienna'
        },
        shipping_country: {
          id: 7,
          city_required: true,
          currency_code: 'EUR',
          line1_required: true,
          line2_required: false,
          phone_number_required: false,
          postcode_regex:
            '(1[0-8][0-9]{2}|190[01])|(2[0-3][0-9]{2}|240[0-9]|241[0-3])|242[1-5]|(243[1-9]|24[4-6][0-9]|247[0-2])|247[3-5]|(248[1-9]|2490)|2491|(249[2-9]|2[5-7][0-9]{2}|28[0-7][0-9]|288[01])|(300[1-9]|30[1-9][0-9]|3[12][0-9]{2}|33[0-2][0-9]|333[0-3])|333[4-5]|(33[4-8][0-9]|339[0-9]|3[4-8][0-9]{2}|39[0-6][0-9]|397[0-3])|(4[01][0-9]{2}|42[0-8][0-9]|429[0-4])|430[0-3]|(43[1-8][0-9]|439[01])|4392|(44[01][0-9]|442[01])|(443[1-9]|444[01])|4441|(444[2-9]|44[5-7][0-9]|448[01])|4482|(448[3-9]|449[0-9]|4[5-8][0-9]{2}|49[0-7][0-9]|498[0-5])|(50[0-9]{2}|510[0-9]|511[0-4])|(51[23][0-9]|514[0-5])|(515[1-9]|51[6-9][0-9]|520[0-5])|(521[1-9]|52[2-7][0-9]|528[0-3])|530[0-3]|531[01]|(532[1-9]|53[34][0-9]|535[01])|5360|(5[4-6][0-9]{2}|57[0-6][0-9]|577[01])|(6[0-5][0-9]{2}|66[0-8][0-9]|669[01])|(6[78][0-9]{2}|69[0-8][0-9]|699[0-3])|(7[0-3][0-9]{2}|740[0-9]|741[0-3])|7421|(742[2-9]|74[3-9][0-9]|75[0-6][0-9]|757[0-3])|(8[0-2][0-9]{2}|83[0-5][0-9]|836[0-3])|838[0-5]|(840[1-9]|84[1-9][0-9]|8[5-8][0-9]{2}|89[0-8][0-9]|899[0-3])|(9[0-2][0-9]{2}|93[01][0-9]|932[0-2])|9323|(932[4-9]|93[3-9][0-9]|9[4-6][0-9]{2}|97[0-7][0-9]|978[01])|9782|(98[0-6][0-9]|987[0-3])|(99[0-8][0-9]|999[0-2])',
          postcode_required: true,
          multi_order_discount_pennies: 500,
          web_enabled: true,
          ios_enabled: true,
          android_enabled: true,
          show_phone_number_field: false
        }
      }
    }
  ]
};
