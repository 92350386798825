/**
 * This takes attributes from contentful HTML and adds them to attributes that
 * angular can then utilise.
 * It's a hack, and was initially built for when we migrated from the old angular 1.3 site
 */
export default function bwContactUsInterceptor(item): any {
  const attributes = [].slice.call(item.attributes);

  attributes.forEach(attribute => {
    const attrElements = attribute.name.split('_');

    // Reason attributes
    if (attrElements.length && attrElements[1] === 'reason') {
      attrElements[1] = attrElements[0];
      attrElements[0] = 'reason';

      item.setAttribute(attrElements.join('_'), attribute.value);
      item.removeAttribute(attribute.nodeName);
    }

    // Resolution attributes
    if (attrElements.length && attrElements[1] === 'resolution') {
      attrElements[1] = attrElements[0];
      attrElements[0] = 'resolution';

      item.setAttribute(attrElements.join('_'), attribute.value);
      item.removeAttribute(attribute.nodeName);
    }
  });

  return item;
}
