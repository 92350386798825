
<!-- TODO: This file probably needs a small refactor into multiple files-->
<div class="form-group" [formGroup]="contentfulForm">
  <div class="input-wrapper">
    <input class="form-control" *ngIf="type !== 'checkbox' &amp;&amp; type !== 'textarea'" id="{{name}}" name="{{name}}" formControlName="{{name}}" type="{{type}}" maxlength="{{maxLength}}" placeholder="{{placeHolderText}}">
    <input class="checkbox checkbox--old-school" *ngIf="type === 'checkbox'" id="{{name}}" name="{{name}}" formControlName="{{name}}" type="{{type}}">
    <textarea class="form-control" *ngIf="type == 'textarea'" rows="6" id="{{name}}" name="{{name}}" formControlName="{{name}}"></textarea>
    <ng-content></ng-content>
    <bw-form-validation-icon></bw-form-validation-icon>
    <bw-formcontrol-validation-messages [errors]="control.errors" [touched]="control.touched" [formSubmitted]="formSubmitted">
      <div for="required">This field is required</div>
    </bw-formcontrol-validation-messages>
  </div>
</div>