/***
 *
 * Documentation:
 * https://ads.tiktok.com/help/article?aid=10021
 *
 *
 */
import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { ConfigService } from 'Shared/services/config.service';
import { User } from 'Shared/classes/user';
import { Purchase } from 'Shared/classes/purchase';
import { Order } from 'Shared/classes/order';
import { Product } from 'Shared/classes/product';

@Injectable({
  providedIn: 'root'
})
export class TiktokMarketingService {
  private serviceInitialized: boolean = false;
  debug: boolean = false;
  window: any;

  tiktokMarketingId: string;
  initPromise: Promise<any>;

  constructor(
    private windowRef: WindowRefService,
    private domUtils: DomUtilsService,
    private configService: ConfigService
  ) {
    this.tiktokMarketingId = this.configService.getConfig().tiktokMarketingId;
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<tiktok-marketing>', ...args);
    }
  }

  /**
   * the ttq call
   * @param args
   */
  ttq(...args): void {
    if (this.window.ttq && this.serviceInitialized) {
      try {
        this.window.ttq(...args);
        this.log(...args);
      } catch (e) {}
    }
  }

  /**
   * Identify and re-init the tiktok pixel
   * @param user
   */
  identify(user: User): void {
    if (this.serviceInitialized) {
      this.window.ttq?.identify({
        email: user.email.address || ''
      });
    }
  }

  /**
   * Add an order to the purchase(customer reached payment pqge)
   * @param order
   */
  addToCart(order: Order): void {
    const obj = {
      content_type: 'product',
      content_id: `${order.product.id}`,
      content_name: order.product.name,
      currency: order.product.price.currency,
      value: order.product.getPrice().price / 100,
      quantity: order.quantity,
      price: order.product.getPrice().price / 100
    };
    this.window.ttq.track('AddToCart', obj);
  }

  /**
   * Complete a purchase (Confirmation page)
   * @param purchase
   */
  completePayment(purchase: Purchase): void {
    const contents = purchase.orders.map((order) => {
      return {
        content_type: 'product',
        content_name: order.product.name,
        content_id: order.id,
        quantity: 1,
        price: order.price.price / 100
      };
    });

    this.window.ttq.track('CompletePayment', {
      contents: contents,
      value: purchase.price.price / 100,
      currency: purchase.price.currency
    });
  }

  /**
   * Track a standard page view
   */
  trackPage(): void {
    if (this.serviceInitialized) {
      this.window.ttq.page();
    }
  }

  /**
   * General init, if cached, return
   */
  init(): Promise<any> {
    const i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
    const e = this.tiktokMarketingId;
    const n = this.window.ttq.methods.length;

    this.window.ttq._i = this.window.ttq._i || {};
    this.window.ttq._i[e] = [];
    this.window.ttq._i[e]._u = i;
    this.window.ttq._t = this.window.ttq._t || {};
    this.window.ttq._t[e] = +new Date();
    this.window.ttq._o = this.window.ttq._o || {};
    this.window.ttq._o[e] = n || {};

    this.initPromise =
      this.initPromise ||
      this.domUtils
        .loadScript(
          `https://analytics.tiktok.com/i18n/pixel/events.js?sdkid=${this.tiktokMarketingId}&lib=ttq`,
          'ttmarketing'
        )
        .then(() => {
          this.serviceInitialized = true;
          this.window.ttq.page();
        });
    return this.initPromise;
  }
}
