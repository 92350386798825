import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { User } from 'Shared/classes/user';
import { ConfigService } from 'Shared/services/config.service';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';

/***
 * HotJar (Third party tracking tool)
 * Docs here: https://help.hotjar.com/hc/en-us/articles/115011819488-Tagging-Recordings
 *
 * Rules and Restrictions:
 * The tags can be any alphanumeric value you like (including spaces), e.g. 'Checkout Page'.
 * Any one Recording can have no more than 20 tags - any additional tags will be ignored.
 * Manual tagging within the Recording viewer will be truncated to 50 characters,
 * however, Automatic tagging done with JavaScript will fail to be tagged if exceeding 50 character.
 *
 */
@Injectable({
  providedIn: 'root'
})
export class HotjarService {
  debug: boolean = false;
  window: any;
  user: User;
  siteId: string;
  hotjarServer: number;
  initPromise: Promise<any>;
  serviceInitialized: boolean = false;

  constructor(
    private windowRef: WindowRefService,
    private configService: ConfigService,
    private domUtils: DomUtilsService
  ) {
    this.siteId = this.configService.getConfig().hotjarSiteId;
    this.hotjarServer = 6; // this is the "hjsv" from the tracking code they generate
    this.window = this.windowRef.nativeWindow;
    this.window._hjSettings = { hjid: this.siteId, hjsv: this.hotjarServer };
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<hotjar>', ...args);
    }
  }

  /**
   * To camel Case, eg hello world to elloWorld
   * @param string
   */
  toCamelCase(string: string): string {
    const str = string.toLowerCase().split(/[\s\-_]/);
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1).toLowerCase();
    }

    return str.join('');
  }

  /**
   * Set a dimension
   * @param key
   * @param value
   */
  setDimension(key: string, value: string): void {
    const tag = this.toCamelCase(`${key} ${value}`);
    this.tagRecording(`d${tag}`);
  }

  /**
   * Wrapper around the "hj" command
   */
  hj(command: string, ...args): void {
    if (this.serviceInitialized) {
      this.log(command, ...args);
      try {
        if (this.window && this.window.hj) {
          this.window.hj(command, ...args);
        }
      } catch (e) {}
    }
  }

  /**
   * Identify with hotjar
   * @param user
   */
  identify(user: User): void {
    this.user = user;
    const slug = user && user.slug ? user.slug : null;
    this.hj('identify', slug);
  }

  /**
   * In hotjar, we always need to "identify" to update any user properties - how odd.
   * @param obj
   */
  setUserProperty(obj: any): void {
    const slug = this.user && this.user.slug ? this.user.slug : null;
    this.hj('identify', slug, obj);
  }

  /**
   * Trigger an event
   * @param eventName
   */
  trigger(eventName: string): void {
    this.hj('trigger', eventName);
  }

  /**
   *
   * @param eventName
   */
  triggerEvent(eventName: string): void {
    this.hj('event', eventName);
  }

  /**
   * Track an event
   * @param key
   * @param value
   */
  trackEvent(value: string): void {
    const tagValue = value.split(''); // continueToUserDetails
    tagValue[0] = tagValue[0].toUpperCase();

    try {
      this.tagRecording(`e${tagValue.join('')}`); // eventUserDetailsContinueToUserDetails
    } catch (e) {}
  }

  /**
   * Track a page view
   * @param url
   */
  trackPageView(url: string): void {
    this.hj('stateChange', url);
  }

  /**
   * Tag a recording
   * Has to be alphanumeric, no special characters or spaces
   * @param text
   */
  tagRecording(text: string): void {
    const tag = text.replace(/[^a-z0-9]/gi, '');
    this.hj('tagRecording', [tag]);
  }

  /**
   * Init
   */
  init(): Promise<any> {
    this.initPromise =
      this.initPromise ||
      this.domUtils
        .loadScript(
          `//static.hotjar.com/c/hotjar-${this.siteId}.js?sv=${this.hotjarServer}`,
          'hotjar'
        )
        .then(() => {
          this.serviceInitialized = true;
        });

    return this.initPromise;
  }
}
