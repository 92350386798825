import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalResponse, ModalResponseSubject } from 'Shared/services/modal.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ContentService } from 'Shared/services/content.service';

@Component({
  selector: 'bw-content-modal',
  templateUrl: './content-modal.component.html',
  styleUrls: ['./content-modal.component.scss']
})
export class ContentModalComponent implements OnInit, ModalResponse, AfterViewInit {
  modalResponse: Subject<ModalResponseSubject> = new Subject<ModalResponseSubject>();
  loading: boolean;
  title: string;
  contentPath: string;
  asIframe: boolean;

  showCloseCross: boolean = true;
  showModalCta: boolean = false;
  modalCtaText: string;

  contentUrl: string;
  content: string;

  /**
   * Constructor
   */
  constructor(private contentService: ContentService, private elementRef: ElementRef) {}

  /**
   */
  onSuccess(): void {
    this.modalResponse.next({ success: true });
  }

  /**
   * On cancel
   */
  onCancel(): void {
    this.modalResponse.next({ success: false });
  }

  ngOnInit(): Promise<any> {
    this.loading = true;
    if (this.asIframe) {
      return Promise.resolve();
    }

    return this.contentService
      .get(this.contentPath)
      .then(html => {
        this.loading = false;
        this.content = html;
      })
      .catch(() => {});
  }

  ngAfterViewInit(): Promise<any> {
    if (!this.asIframe) {
      return Promise.resolve(undefined);
    }

    const urls = this.contentService.getServerUrls(this.contentPath);
    // We sadly can't detect if an iframe's content doesn't load, due to CORs, so
    // we need to first do a request, then set the IFRAME URL
    // (in theory, this will be cached in the browser (so will do one network call)
    return this.contentService
      .getContentForUrl(urls.mainUrl)
      .then(() => urls.mainUrl)
      .catch(e => {
        if (urls.fallbackUrl) {
          return this.contentService
            .getContentForUrl(urls.fallbackUrl)
            .then(() => urls.fallbackUrl);
        }
        return Promise.reject(e);
      })
      .then(workingUrl => {
        const iframeElem = this.elementRef.nativeElement.querySelector('iframe');
        iframeElem['onload'] = () => {
          this.loading = false;
        };
        iframeElem.setAttribute('src', workingUrl);
      });
  }
}
