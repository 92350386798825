import { Injectable } from '@angular/core';

import * as md5 from 'md5';
import { ConfigService } from 'Shared/services/config.service';
import { UserService } from 'Shared/services/user.service';
import { Error } from 'Shared/classes/error';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { WindowRefService } from 'Shared/services/window.service';

@Injectable({
  providedIn: 'root'
})
export class PusherModelService {
  private channel: any;
  private socket: any;
  pusherPromise: Promise<any>;
  pusherInstance: any;
  window: any;

  /**
   * Constructor
   * @param configService
   * @param userService
   */
  constructor(
    private configService: ConfigService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private domUtilsService: DomUtilsService,
    private windowRef: WindowRefService
  ) {
    this.window = this.windowRef.nativeWindow;
  }

  init(): Promise<any> {
    const scriptUrl = 'https://js.pusher.com/7.0.3/pusher.min.js';
    this.pusherPromise = this.pusherPromise || this.domUtilsService.loadScript(scriptUrl, 'pusher-model');

    if (this.pusherInstance) {
      return Promise.resolve(this.pusherInstance);
    }

    return this.pusherPromise.then(() => {
      this.pusherInstance = new this.window.Pusher(this.configService.getConfig().pusherKey, {
        encrypted: true
      });
      return this.pusherInstance;
    });
  }

  /**
   * Subscribe & Listen for a response from pusher
   */
  listenForResponse(): Promise<any> {
    const user = this.userService.getUser();
    const channelName = `user_${md5(user.email.address)}`;

    return this.init().then((pusherInstance) => {
      this.socket = pusherInstance;
      this.channel = this.socket.subscribe(channelName);

      return new Promise((resolve, reject) => {
        this.channel.bind_global((event, data) => {
          if (event === 'purchase_complete') {
            this.stopListening();
            return resolve(data);
          }
          if (event === 'purchase_validation_failed' || event === 'purchase_payment_failed') {
            this.stopListening();
            const err = data.order_error;
            const error = new Error({
              title: err.customer_title,
              message: err.customer_message,
              code: err.unique_code,
              kind: event
            });

            this.analyticsService.trackError(error);

            return reject(error);
          }
        });
      });
    });
  }

  /**
   * Unbind
   */
  stopListening(): void {
    if (this.channel) {
      this.channel.unbind_global();
    }
  }
}
