import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FullscreenLoadingSpinnerService } from 'Shared/services/fullscreen-loading-spinner.service';

@Component({
  selector: 'bw-fullscreen-loading-spinner-listener',
  templateUrl: './fullscreen-loading-spinner-listener.component.html'
})
export class FullscreenLoadingSpinnerListenerComponent {
  isLoading$: BehaviorSubject<boolean>;

  constructor(private fullscreenLoadingSpinnerService: FullscreenLoadingSpinnerService) {
    this.isLoading$ = this.fullscreenLoadingSpinnerService.isLoading$;
  }
}
