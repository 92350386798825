import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ReviewsService } from 'Shared/services/reviews.service';
import { Product } from 'Shared/classes/product';
import { Review } from 'Shared/classes/review';
import { CountryService } from 'Shared/services/country.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-reviews-display',
  templateUrl: './reviews-display.component.html'
})
export class ReviewsDisplayComponent implements OnInit {
  @Input()
  products: Product[];
  @Input() reviewsrebrand: boolean = false;

  @Input()
  source: 'reviews' | 'trustpilot' | 'trustedshops' = 'reviews';

  reviews: Review[];

  constructor(private reviewService: ReviewsService, private countryService: CountryService) {}

  ngOnInit(): Promise<any> {
    const shippingCountry = this.countryService.forShipping;
    return this.reviewService.getAll(shippingCountry, 3).then(res => {
      this.reviews = res.reviews;
    });
  }
}
