import { Injectable } from '@angular/core';
import { HttpUrlEncodingCodec } from '@angular/common/http';

/**
 * Custom Query Encoder
 * Fix plus sign (+) not encoding, so sent as blank space
 * See: https://github.com/angular/angular/issues/11058#issuecomment-247367318
 * TODO: Remove on angular 14 update
 * Fixed in angular 14: https://github.com/angular/angular/blob/main/CHANGELOG.md#1400-2022-06-02
 */
@Injectable({
  providedIn: 'root'
})
export class CustomQueryEncoderService extends HttpUrlEncodingCodec {
  /**
   * Encode query key
   * @param {string} key
   * @returns {string}
   */
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  /**
   * Encode query value
   * @param {string} value
   * @returns {string}
   */
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  /**
   * Decode query key
   * @param {string} key
   * @returns {string}
   */
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  /**
   * Decode query value
   * @param {string} value
   * @returns {string | number | boolean}
   */
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
