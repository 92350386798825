import { ToastrMessage } from './classes/toastr';
import { ToastrComponent } from './components/toastr/toastr.component';
export class ToastrConfig {
  timeOut?: number = 5000;
  easeTime?: number = 500;
  autoDismiss?: boolean = true;
  closeButton?: boolean = true;
  preventDuplicates?: boolean = false;
  cssClass?: string;
  trackCloseButtonClick?: boolean = false;
  position?: string = 'top';
  onTitleClick?: (message: ToastrMessage, component: ToastrComponent) => void;
  onMessageClick?: (message: ToastrMessage, component: ToastrComponent) => void;
}
