import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { UserService } from 'Shared/services/user.service';
import { CampaignCodesService } from 'Shared/services/campaign-codes.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { StateService } from 'Shared/services/state.service';
import { CountryService } from 'Shared/services/country.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { LayoutBackgrounds } from 'oasys-lib/lib/components/layout/Layout';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-newletter-signup-click-reveal, bw-newsletter-signup-click-reveal',
  templateUrl: './newsletter-signup-click-reveal.component.html'
})
export class NewsletterSignupClickRevealComponent implements OnInit {
  showError: boolean = false;
  loading: boolean = false;
  isVisible: boolean = true;

  @Input() new_banner: boolean = false;
  @Input() new_banner_background: LayoutBackgrounds;
  @Input() new_banner_cta_text: string;
  @Input() new_banner_title: string;
  @Input() new_banner_surtitle: string;
  @Input() new_banner_subtitle: string;

  constructor(
    private userService: UserService,
    private stateService: StateService,
    private campaignCodesService: CampaignCodesService,
    private toastrService: ToastrService,
    private analyticsService: AnalyticsService,
    private countryService: CountryService,
    public experimentService: ExperimentsService,
    public elRef: ElementRef
  ) {}

  /**
   * Get the discount & forward the user to the checkout with it already enabled
   */
  getDiscount(): Promise<any> {
    const user = this.userService.getUser();
    const shippingCountry = this.countryService.forShipping;
    this.analyticsService.track('component.newsletter.tv.actioned');
    if (user && user.hasPreviouslyOrdered()) {
      this.showError = true;
      return Promise.resolve();
    }
    this.loading = true;

    return this.campaignCodesService
      .getCode(shippingCountry)
      .then((code) => {
        this.loading = true;
        this.stateService.go('checkout.base', {
          discountCode: code.code
        });
      })
      .catch((e) => {
        this.loading = false;
        this.toastrService.error(e.message);
      });
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.userService.user$.subscribe((user) => {
      if (user && user.orderCount > 0) {
        this.isVisible = false;
      }
    });
  }
}
