import { Injectable } from '@angular/core';
import { LocalStorageService, StorageType } from 'Shared/services/local-storage.service';
import { WindowRefService } from 'Shared/services/window.service';
import * as dayjs from 'dayjs';
@Injectable({
  providedIn: 'root'
})
export class ReturningCustomerService {
  isReturning: boolean;
  storageKey: string = 'lastVisit';
  lastVisit: dayjs.Dayjs;
  debug: boolean;

  constructor(private localStorageService: LocalStorageService, private window: WindowRefService) {
    const lastVisitLocalStorage = this.localStorageService.get(this.storageKey);
    this.lastVisit = dayjs(lastVisitLocalStorage || new Date());
    this.isReturning = this.isCustomerReturning();
    this.debug = this.window.nativeWindow.location?.search.indexOf('analyticsDebug=true') > -1;
    if (this.debug) {
      console.log('<personalisation>', 'isReturning', this.isReturning ? 'true' : 'false');
    }
  }

  recordFirstVisit(): void {
    if (!this.localStorageService.get(this.storageKey)) {
      this.localStorageService.set(this.storageKey, new Date().toString());
    }
  }

  isCustomerReturning(): boolean {
    // If the user has ordered before via the storage(the user may be logged out),
    // or it has been 24 hours since the device first visited
    if (
      dayjs().diff(this.lastVisit, 'hour') >= 24 ||
      !!this.localStorageService.get('userHasOrdered', 'localStorage' as StorageType.Local)
    ) {
      return true;
    }

    return false;
  }
}
