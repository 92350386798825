
<div class="bw-subscription-cancel-form">
  <div class="card-text" *ngIf="success">
    <div class="subscription-cancel-form__success">
      <div class="success__container"><img class="success__icon" src="/assets/brand-icons/envelope.svg">
        <h4 class="success__heading">Thank you, your query has been received!</h4>
      </div>
    </div>
  </div>
  <div class="card-text" *ngIf="!success">
    <form class="bwForm" id="form" [formGroup]="form" (ngSubmit)="submitForm()" [ngClass]="{'ng-submitted': formSubmitted}" name="form">
      <div class="form-group">
        <div class="input-wrapper">
          <input class="form-control" id="fullName" name="fullName" formControlName="fullName" type="text" required autocomplete="name">
          <label class="wide" for="fullName">Full Name</label>
          <bw-form-validation-icon></bw-form-validation-icon>
          <bw-formcontrol-validation-messages [errors]="form.get('fullName').errors" [touched]="form.get('fullName').touched" [formSubmitted]="formSubmitted">
            <div for="required">Full name is required</div>
          </bw-formcontrol-validation-messages>
        </div>
      </div>
      <div class="form-group">
        <div class="input-wrapper">
          <input class="form-control" id="email" name="email" formControlName="email" type="email" required autocomplete="email">
          <label class="wide" for="email">Email</label>
          <bw-form-validation-icon></bw-form-validation-icon>
          <bw-formcontrol-validation-messages [errors]="form.get('email').errors" [touched]="form.get('email').touched" [formSubmitted]="formSubmitted">
            <div for="pattern">Invalid email</div>
            <div for="required">Email is required</div>
            <div for="email">Invalid email</div>
          </bw-formcontrol-validation-messages>
        </div>
      </div>
      <div class="form-group">
        <div class="input-wrapper">
          <input class="form-control" id="order" name="order" formControlName="order" type="tel" autocomplete="off">
          <label class="wide" for="order">Order Number <i>(RECOMMENDED)</i></label>
          <bw-form-validation-icon></bw-form-validation-icon>
          <bw-formcontrol-validation-messages [errors]="form.get('order').errors" [touched]="form.get('order').touched" [formSubmitted]="formSubmitted">
            <div for="required">This field is required</div>
          </bw-formcontrol-validation-messages>
        </div>
      </div>
      <!-- Postcode - General-->
      <div class="form-group">
        <div class="input-wrapper">
          <input class="form-control" id="postCode" name="postCode" formControlName="postCode" type="text" maxlength="10">
          <label class="wide" for="postCode">Delivery Postcode <i>(RECOMMENDED)</i></label>
          <bw-form-validation-icon></bw-form-validation-icon>
          <bw-formcontrol-validation-messages [errors]="form.get('postCode').errors" [touched]="form.get('fullName').touched" [formSubmitted]="formSubmitted">
            <div for="required">This field is required</div>
          </bw-formcontrol-validation-messages>
        </div>
      </div>
      <!-- Reason-->
      <div class="form-group" dropdown #dropdown="bs-dropdown" [ngClass]="{'ng-focus' :  dropdown.isOpen}" *ngIf="reasons?.length ">
        <button class="form-control" dropdownToggle type="button" [ngClass]="{'ng-invalid ng-touched': form.get('description').invalid &amp;&amp; formSubmitted}" bwTrackAs="components.account.subscription-cancel.form.reasons.label">
          <label class="wide" for="reasons">Reason for cancelling</label>
          <div>{{ form.value.description || 'Select from dropdown' }}</div>
        </button>
        <div class="form-items-select dropdown-menu" *dropdownMenu><a class="dropdown-item wrap" href="javascript:void(0)" onclick="return false" *ngFor="let reason of reasons" (click)="selectReason(reason)">{{ reason.title }}</a></div>
        <div class="form-items-icon"><img src="/assets/brand-icons/chevron-down.svg"></div>
        <div class="invalid-form-text" *ngIf="form.get('description').invalid &amp;&amp; formSubmitted">This field is required</div>
      </div>
      <div class="form-group" *ngIf="showOtherReason">
        <div class="input-wrapper">
          <textarea class="form-control" elastic id="question" name="question" formControlName="question" (input)="onMessageTextChange()"></textarea>
          <label class="wide" for="question">Comments <i>(OPTIONAL)</i></label>
        </div>
        <div class="question__counter u-font-small" [ngClass]="{'text-danger' : textCounter === characterLimit}">{{textCounter}} / {{characterLimit}}</div>
      </div>
      <!-- For general or quality-->
      <div class="form-group" *ngIf="showOtherReason">
        <div class="input-wrapper">
          <bw-form-file-upload class="form-control" [s3BucketLocation]="s3Location" [limit]="5" [uploadInstantly]="true" acceptedFileTypes="video/*,image/*,.pdf" (filesChanged)="onFilesChanged($event)"></bw-form-file-upload>
          <label class="wide" for="file-upload">Upload photos <i>(OPTIONAL)</i></label>
        </div>
      </div>
      <div class="form-group">
        <bw-button [fullWidth]="true" size="medium" buttonType="submit" buttonStyle="primary" bwTrackAs="page.contact_us.form.submit-request">Send request</bw-button>
      </div>
    </form>
  </div>
</div>
<bw-loading-spinner *ngIf="loading"></bw-loading-spinner>