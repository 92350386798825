
<div class="bw-expansion-panel">
  <div class="bw-expansion-panel__header" (click)="togglePanel()">
    <div class="bw-expansion-panel__header-content" [ngClass]="{'has-toggle-hidden': hideToggle}">
      <ng-content select="div[role=header]"></ng-content>
    </div>
    <bw-icon class="bw-expansion-panel__header-arrow" iconType="chevron-right" *ngIf="!hideToggle" [class.open]="expand"></bw-icon>
  </div>
  <div class="bw-expansion-panel__content" *ngIf="expand">
    <ng-content select="div[role=body]"></ng-content>
  </div>
</div>