import { Injectable } from '@angular/core';
import { Country } from 'Shared/classes/country';

@Injectable({
  providedIn: 'root'
})
export class TelephoneNumberUtilsService {
  /**
   * convertToInternationalNumber
   * eg 07746 to 0044
   * @param {String} dialcode
   * @param {String} number
   * @returns {String}
   */
  static convertToInternationalNumber(dialcode: string, number: string): string {
    const dial = dialcode;
    const prefix = `00${dial}`;
    const telephone = number.replace(/[^0-9]/g, '');

    // If we have a prefix
    if (telephone.indexOf(prefix) === 0) {
      return `${prefix}${telephone.replace(prefix, '')}`;
    }

    if (telephone.indexOf(dial) === 0) {
      return `${prefix}${telephone.replace(dial, '')}`;
    }

    if (telephone.indexOf('0') === 0) {
      return `${prefix}${telephone.substring(1)}`;
    }

    return `${prefix}${telephone}`;
  }

  static convertToDisplayNumber(number: string): string {
    if (number.indexOf('+') === 0) {
      return number;
    }

    if (number.indexOf('00') === 0) {
      return number.replace('00', '+');
    }

    return `+${number}`;
  }

  /**
   * Get a country from dial code
   * @param {Integer} dialCode
   * @param {Array} countries
   * @returns {Object}
   */
  static getCountryMatchesDialCode(dialCode: string, countries: Country[]): Country {
    return countries.find(country => parseInt(country.phonePrefix, 10) === parseInt(dialCode, 10));
  }
}
