import { Injectable } from '@angular/core';
import { CardModelService } from 'Shared/models/card-model.service';
import { Card } from 'Shared/classes/card';
import { Purchase } from 'Shared/classes/purchase';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { StripeService } from 'Shared/services/stripe.service';
import { ChargesService } from 'Shared/services/charges.service';
import { BankRedirect } from 'Shared/classes/bank-redirect';
import { Order } from 'Shared/classes/order';
import { BankRedirectTypes, Charge } from 'Shared/classes/charges';
import { PusherModelService } from 'Checkout/models/pusher-model.service';
import { Error } from 'Shared/classes/error';
import { t } from 'Shared/utils/translations';
import { Country } from './country.service';

export { Card } from 'Shared/classes/card';

@Injectable({
  providedIn: 'root'
})
export class CardService {
  cards: Card[];
  private pollIntervalObj: NodeJS.Timeout;

  constructor(
    private cardModelService: CardModelService,
    private analyticsService: AnalyticsService,
    private stripeService: StripeService,
    private chargesService: ChargesService,
    private pusherModelService: PusherModelService
  ) {}

  /**
   * Get all the cards for the user
   * @param {number} defaultCardId - The default card to sort with
   * @param {string[]} cardKinds - The card kinds to filter by
   * @returns {Promise<Card[]>}
   */
  public getAll(defaultCardId?: number, cardKinds?: string[], orderShippingCountry?: Country): Promise<Card[]> {
    return this.cardModelService.getAll(cardKinds, orderShippingCountry).then((cards): Card[] => {
      if (!defaultCardId) {
        return cards;
      }
      // If we have a default card, make it first
      const cardIndex = cards.findIndex((card): boolean => +card.id === +defaultCardId);
      if (cardIndex > 0) {
        const card = cards.splice(cardIndex, 1)[0];
        cards.unshift(card);
      }
      return cards;
    });
  }

  /**
   * Delete
   * @param {Card} card
   * @returns {Promise<any>}
   */
  public delete(card: Card): Promise<unknown> {
    return this.cardModelService.delete(card);
  }

  /**
   * Create the paypal token
   * @param {string} ppToken
   * @param {boolean} isTemporary
   * @param {Purchase} purchase
   * @returns {Promise<any>}
   */
  public createPayPal(ppToken: string, isTemporary: boolean = false, purchase: Purchase): Promise<Card> {
    this.analyticsService.trackAddPaymentInfo('Paypal', purchase);
    return this.cardModelService.createPaypal(ppToken, isTemporary, purchase);
  }

  /**
   * Create card
   * @param {string} stripeToken
   * @param {boolean} isTemporary
   * @param {Purchase} purchase
   * @returns {Promise<any>}
   */
  public create(stripeToken: string, isTemporary: boolean = false, purchase?: Purchase, orderShippingCountry?: Country): Promise<Card> {
    this.analyticsService.trackAddPaymentInfo('Card', purchase);
    return this.cardModelService.createStripeCard(stripeToken, isTemporary, purchase, orderShippingCountry);
  }

  /**
   * Setup a card for SCA use
   * @param {Card} card
   * @param {boolean} isPhoneSource
   * @returns {Promise<string>}
   */
  public getSetupIntentForFutureUse(card: Card, isPhoneSource: boolean = false): Promise<string> {
    return this.cardModelService.getSetupIntentForFutureUse(card, isPhoneSource);
  }

  /**
   * Get and set up intent for future use
   * @param {Card} card
   * @returns {Promise<Card>}
   */
  public getAndSetNewCard(card: Card, handleCardSetup = true, orderShippingCountry?: Country): Promise<Card> {
    return this.cardModelService
      .getSetupIntentForFutureUse(card, false, orderShippingCountry)
      .then((secret: string): Promise<any> => (handleCardSetup ? this.stripeService.handleCardSetup(secret, card) : Promise.resolve()))
      .then((): Card => card)
      .catch((e: Error): any => this.delete(card).then((): Promise<never> => Promise.reject(e))); // Fails to set new card on user account
  }

  /**
   * Klarna Token
   * @param {string} klarnaToken
   * @param  {boolean} isTemporary
   * @param {Purchase} purchase
   * @returns {Promise<Card>}
   */
  public createKlarna(klarnaToken: string, isTemporary: boolean = false, purchase: Purchase): Promise<Card> {
    this.analyticsService.trackAddPaymentInfo('Klarna', purchase);
    return this.cardModelService.createKlarna(klarnaToken, isTemporary, purchase);
  }

  /**
   * Wait for poll to be completed
   * @param {Charge} charge
   * @param {BankRedirectTypes} bankRedirect
   * @returns {Promise<Charge>}
   */
  public waitUntilComplete(charge: Charge, bankRedirect: BankRedirectTypes): Promise<Charge> {
    return Promise.race([this.pusherModelService.listenForResponse(), this.pollUntilComplete(charge, bankRedirect)]).then((): Charge => {
      this.stopPolling();
      this.pusherModelService.stopListening();
      return charge;
    });
  }

  /**
   * Create and confirm bank redirect
   * @param {Order} order
   * @param {BankRedirect} bankRedirect
   * @returns {Promise<void>}
   */
  public createFromBankRedirect(order: Order, bankRedirect: BankRedirect, isPrepayment?: boolean): Promise<void> {
    let currentCharge: Charge;
    return this.chargesService
      .create(order, bankRedirect.kind as BankRedirectTypes)
      .then((charge): Promise<unknown> => {
        currentCharge = charge;
        bankRedirect = this.addChargeToBankRedirectUrl(bankRedirect, charge);
        if (isPrepayment) {
          bankRedirect = this.addPrepaidToBankRedirectUrl(bankRedirect, isPrepayment);
        }
        return this.stripeService.confirmBankRedirectPayment(charge.clientSerect, bankRedirect.kind, bankRedirect.paymentDetails);
      })
      .then((): Promise<void> => void this.waitUntilComplete(currentCharge, bankRedirect.kind as BankRedirectTypes));
  }

  /**
   * Add charge to redirect url
   * @param {BankRedirect} bankRedirect
   * @param {Charge} charge
   * @returns {BankRedirect}
   */
  private addChargeToBankRedirectUrl(bankRedirect: BankRedirect, charge: Charge): BankRedirect {
    bankRedirect.paymentDetails.return_url += `&chargeId=${charge.id}`;
    return bankRedirect;
  }

  /**
   * Add isPrepayment to redirect url
   * @param bankRedirect
   * @param isPrepayment
   * @returns
   */
  private addPrepaidToBankRedirectUrl(bankRedirect: BankRedirect, isPrepayment: boolean): BankRedirect {
    bankRedirect.paymentDetails.return_url += `&isPrepayment=${isPrepayment}`;
    return bankRedirect;
  }

  /**
   * Stop polling
   */
  private stopPolling(): void {
    if (this.pollIntervalObj) {
      clearInterval(this.pollIntervalObj);
      this.pollIntervalObj = null;
    }
  }

  /**
   * Poll charge state until maximum is hit or charge is complete
   * @param {Charge} charge
   * @param {number} intervalNumber
   * @param {number} maximum
   * @returns {Promise<boolean>}
   */
  private pollUntilComplete(
    charge: Charge,
    bankRedirect: BankRedirectTypes,
    intervalNumber: number = 5000,
    maximum: number = 20
  ): Promise<boolean> {
    let counter = 0;

    return new Promise((resolve, reject): void => {
      // Loop through until resolved
      // Charge needs to be refunded before completion
      this.pollIntervalObj = setInterval((): Promise<void> | void => {
        counter += 1;

        // We don't want this to run forever so this is limit
        // Works out to be 100000ms
        if (counter >= maximum) {
          this.stopPolling();
          return reject(
            new Error({
              title: t('js.service.backend.network'),
              code: 'payment',
              kind: 'pollTimeout'
            })
          );
        }

        // Get charge and check state
        return this.chargesService
          .get(charge.id)
          .then((state): void => {
            if ((bankRedirect === 'sofort' && state.state === 'pending_completion') || state.state === 'complete') {
              this.stopPolling();
              resolve(true);
            }
          })
          .catch((e: Error): void => {
            this.stopPolling();
            return reject(e);
          });
      }, intervalNumber);
    });
  }
}
