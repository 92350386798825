import { Component, OnInit, OnDestroy } from '@angular/core';
import { StateService } from 'Shared/services/state.service';
import { LocationService } from 'Shared/services/location.service';
import { ConfigService } from 'Shared/services/config.service';
import { WindowRefService } from 'Shared/services/window.service';
import { alternateMappings } from 'Environments/alternate-pages';
import { ContentService } from 'Shared/services/content.service';
import { merge } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { CountryService } from 'Shared/services/country.service';

@Component({
  selector: 'bw-seo-hreflang-alternate',
  template: ''
})
export class SeoHreflangAlternateComponent implements OnInit, OnDestroy {
  // The alternateMappings file is populated during build time
  mappings = alternateMappings;

  // For each new base "locale", we'll need to add it here
  defaultForLocale = {
    en: 'en-gb',
    fr: 'fr-fr',
    nl: 'nl-nl',
    da: 'da-dk',
    de: 'de-de'
  };
  // TODO: Move this to some kind of shared config on a per envrionment basis
  fallbackSitesContent = [
    {
      hreflang: 'de-at',
      base: 'de',
      replace: 'bloomandwild.de',
      with: 'bloomandwild.com/de-at'
    }
  ];

  fallbackHrefLangs: string[] = [];
  fallbackDomain: string = '';
  contentHrefLangs: string[] = [];
  document: HTMLDocument;
  subscriber: any;

  constructor(
    private contentService: ContentService,
    private stateService: StateService,
    private configService: ConfigService,
    private countryService: CountryService,
    private windowService: WindowRefService,
    private locationService: LocationService
  ) {
    this.document = this.windowService.nativeWindow.document;
    this.fallbackHrefLangs = this.configService.getConfig().contentFallbackHreflangs;
    this.contentHrefLangs = this.configService.getConfig().contentHrefLangs;
    this.fallbackDomain = this.configService.getConfig().contentFallbackDomain;
  }

  /**
   * Create the alternate hreflang mappings for the current url
   */
  createAlternateMappings(): { [key: string]: string } {
    const appUrl = this.locationService.appUrl;
    const fullUrl = this.locationService.getUrlWithoutQueryAndHash();

    const siteHrefLang = this.countryService.siteConfigValue('locale.hreflang');

    const pageMappings = {};

    // Based on the hreflang site we are on, initially set the hreflang
    if (siteHrefLang) {
      pageMappings[siteHrefLang] = fullUrl;
    }

    // Check for fallback to set the page mappings as a base
    const fallbacksUsed = this.contentService.fallbackContentUsed$.getValue();
    const fallbackUrl = fallbacksUsed.find(path => `${appUrl}${path}` === fullUrl);

    let fallbackMappings = {};
    if (fallbackUrl) {
      const fallbackHrefLangs = this.configService.getConfig().contentFallbackHreflangs;
      const fallbackDomain = this.configService.getConfig().contentFallbackDomain;
      const fallbackFullUrl = `${fallbackDomain}/${fallbackUrl}`;

      fallbackHrefLangs.forEach(hreflang => {
        pageMappings[hreflang] = fallbackFullUrl;
      });

      fallbackMappings = this.mappings.find(
        pages => Object.values(pages).indexOf(fallbackFullUrl) > -1
      );
    }

    // Any mappings which are a direct match, override the fallback
    const foundMappings = this.mappings.find(pages => Object.values(pages).indexOf(fullUrl) > -1);
    const mappings = Object.assign({}, fallbackMappings, foundMappings);

    Object.entries(mappings).forEach(([key, value]) => {
      if (value) {
        pageMappings[key] = value;
      }
    });

    // Duplicate mappings if we have the matching locale, eg en-gb for en, if we don't already have them
    Object.entries(this.defaultForLocale).forEach(([key, value]) => {
      if (pageMappings[value] && !pageMappings[key]) {
        pageMappings[key] = pageMappings[value];
      }
    });

    // If we know that a site uses another content as fallback, set the mapping here, but only if it doesn't already exist
    this.fallbackSitesContent.forEach(r => {
      if (pageMappings[r.base] && !pageMappings[r.hreflang]) {
        pageMappings[r.hreflang] = pageMappings[r.base].replace(r.replace, r.with);
      }
    });

    return pageMappings;
  }

  /**
   * Find and set the meta tags
   * @param url
   */
  findAndSetHrefMetaTags(): void {
    const pageMappings = this.createAlternateMappings();

    // First remove existing
    [].slice.call(this.document.querySelectorAll('link[rel="alternate"][hreflang]')).forEach(el => {
      el.parentNode.removeChild(el);
    });

    // Then add to the page
    Object.entries(pageMappings)
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
      .forEach(([key, value]) => {
        if (value) {
          const elem = this.document.createElement('link');
          elem.setAttribute('rel', 'alternate');
          elem.setAttribute('href', `${value}`);
          elem.setAttribute('hreflang', key);
          this.document.querySelector('head').appendChild(elem);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

  // TODO: enable the hreflang here
  ngOnInit(): void {
    this.subscriber = merge(
      this.stateService.onAppLoad$,
      this.stateService.onSuccess$,
      this.contentService.fallbackContentUsed$
    )
      .pipe(throttleTime(500)) // Wait 500ms for each, users won't navigate this quickly, and ensures this code doesn't run more than once
      .subscribe(() => {
        this.findAndSetHrefMetaTags();
      });
  }
}
