import { Component, OnInit } from '@angular/core';
import { ModalService } from 'Shared/services/modal.service';
import { BackendService } from 'Shared/services/backend.service';
import { MaintenanceModalComponent } from 'Shared/components/modals/maintenance-modal/maintenance-modal.component';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'bw-maintenance-listener',
  template: ''
})
export class MaintenanceListenerComponent implements OnInit {
  private statusCodeToListen = 503;

  constructor(private modalService: ModalService, private backendService: BackendService) {}

  /**
   * Launch the modal
   */
  launchMaintenanceModal(): Promise<any> {
    return this.modalService.show(MaintenanceModalComponent, {
      class: 'bw-modal--maintenance-modal',
      ignoreBackdropClick: true,
      trackingKey: 'maintenance',
      trackingValue: 'five-oh-three',
      closeOnStateChange: false
    });
  }

  /**
   * Init
   */
  ngOnInit(): void {
    this.backendService.onErrorRaised$
      .pipe(
        filter((error) => error.statusCode === this.statusCodeToListen),
        first() // As we are using 'first' here - no need to unsubscribe, and will only launch once
      )
      .subscribe((val) => {
        this.launchMaintenanceModal();
      });
  }
}
