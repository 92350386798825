
<div class="bw-auth-login-password">
  <div class="login__section">
    <bw-icon class="login__user" iconType="user"></bw-icon>
    <div class="login__welcome">Welcome back {{identifiedUser}}</div>
  </div>
  <div class="login__form-items-icon">
    <div class="password-eye" *ngIf="password.value" (click)="showPassword = !showPassword"><span class="password-eye__open" [hidden]="showPassword"></span><span class="password-eye__closed" [hidden]="!showPassword"></span></div>
    <bw-form-text-input [bwFormControl]="password" label="Password" [inputType]="showPassword ? 'text' : 'password'" (didFocus)="formFocus()">
      <ng-container role="errors">
        <div for="required"><span *ngIf="showValidationMessage">Please enter your password.</span></div>
        <div for="invalid"><span *ngIf="showValidationMessage">Hmm. That’s not the right password. Please check and try again.</span></div>
      </ng-container>
    </bw-form-text-input>
  </div>
  <bw-alert class="login__forgot-password-alert" *ngIf="showFailedLogin &amp;&amp; !isInvalidEmailOrPassword &amp;&amp; submitError" [header]="submitError.title" alertStyle="warning"> {{submitError.message}}</bw-alert>
  <div class="login__forgot-password" *ngIf="!submitError || submitError?.code!=='tooManyRequests'">
    <bw-button buttonStyle="text-link" (click)="resetPassword()" bwTrackAs="component.auth-login.auto_key.string_5">Forgot password?</bw-button>
  </div>
  <bw-alert class="login__forgot-password-alert" *ngIf="requestResetPasswordSent" alertStyle="successInfo"> We’ve sent you an email with a link to reset your password.</bw-alert>
  <ng-container *ngIf="optinLoginExperiment">
    <div class="login__forgot-password" *ngIf="!isJoiningRewards">
      <bw-form-checkbox-input *ngIf="emailUser?.preference === 0" [bwFormControl]="consent" [align]="'right'">
        <p role="description">Keep me updated with special offers, exciting products and news from Bloom & Wild. You can view our <a href="/privacy-statement" target="_blank">privacy statement here</a> and unsubscribe at any time.</p>
      </bw-form-checkbox-input>
    </div>
  </ng-container>
  <ng-container *ngIf="isJoiningRewards">
    <ui-checkbox class="login__marketing" *ngIf="emailUser?.preference === 0" [checked]="marketingConsent" (didChange)="marketingConsent = $event" checkbox_type="secondary" id="consent" label="Keep me updated via email with Bloom &amp; Wild Rewards, my birthday treat and VIP perks, as well as special offers and news about our products."><span>You can view our <a class="text-link--paragraph" href="/privacy-statement" target="_blank">privacy statement here</a> (to learn how we handle your personal data) and unsubscribe at any time.</span></ui-checkbox>
    <ui-box *ngIf="origin === 'checkout' &amp;&amp; showTandC" box_space="none" box_space_bottom="default">
      <p class="text-body--supporting text-color--supporting">You can <a class="text-link--paragraph" href="/bw-rewards-terms" target="_blank">read our full T&Cs here</a>.</p>
    </ui-box>
  </ng-container>
  <bw-button *ngIf="origin === 'default'" buttonStyle="modal-primary" [fullWidth]="true" size="medium" bwTrackAs="checkout.userDetails.login" (clicked)="submit()"><span>Log in</span></bw-button>
  <bw-button *ngIf="origin !== 'default'" buttonStyle="primary" [fullWidth]="true" size="medium" bwTrackAs="checkout.userDetails.login" (clicked)="submit()"><span>Log in</span></bw-button>
</div>
<bw-loading-spinner *ngIf="loading" [fullscreen]="false" message="Logging in..."></bw-loading-spinner>