import { Injectable } from '@angular/core';
import { UserModelService } from 'Shared/models/user-model.service';
import { BehaviorSubject } from 'rxjs';

import { LocalStorageService, StorageType } from 'Shared/services/local-storage.service';
import { User } from 'Shared/classes/user';
import { Email } from 'Shared/classes/email';
import { Order } from 'Shared/classes/order';
import * as dayjs from 'dayjs';
export { User } from 'Shared/classes/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user$: BehaviorSubject<User> = new BehaviorSubject<User>(new User());

  private authenticatePromise: Promise<User>;

  constructor(
    private userModel: UserModelService,
    private localStorage: LocalStorageService
  ) {}

  /**
   * Get the fingerprint
   * @returns {string}
   */
  public getFingerprint(): string {
    return this.userModel.getFingerprint();
  }

  /**
   * Get the user slug, either from the current user, or from local stroage
   * @returns {string}
   */
  public getUserSlug(): string {
    const user = this.getUser();
    return (user && user.slug) || this.localStorage.get('userSlug');
  }

  /**
   * Set current user
   * @param {User} user
   */
  public setCurrentUser(user: User): void {
    this.localStorage.set('userEmail', user.email.address);
    this.localStorage.set('userFullName', user.fullName);
    this.localStorage.set('userSlug', user.slug);
    this.user$.next(user);
  }

  /**
   * Get user
   */
  public getUser(): User {
    return this.user$.getValue();
  }

  /**
   * Get refreshed instance of User
   * @returns {Promise<User>}
   */
  public refreshUser(): Promise<User> {
    const currentUser = this.getUser();

    if (!currentUser.isLoggedIn()) {
      return Promise.resolve(currentUser);
    }

    return this.getDetails()
      .then((refreshedUser): User => this.setAsLoggedIn(refreshedUser))
      .catch((): Promise<User> => this.logout());
  }

  /**
   * Update a user
   * @param {User} user
   * @returns {Promise<User>}
   */
  public update(user: User): Promise<User> {
    const currentUser = this.getUser();
    return this.userModel.update(currentUser, user);
  }

  /**
   * Update Date Of Birth
   * @param {User} user
   * @returns {Promise<User>}
   */
  public addDoB(dob: dayjs.Dayjs): Promise<User> {
    const currentUser = this.getUser();
    return this.userModel.addDoB(currentUser, dob);
  }

  /**
   * Set the storage token
   * @param {User} user
   */
  public setStorageTokens(user: User): void {
    this.localStorage.set('userEmail', user.email.address);
    this.localStorage.set('userToken', user.token);
    this.localStorage.set('userFullName', user.fullName);
  }

  /**
   * Returns a promise of the user. authenticates of the user isn't set already
   * @returns {Promise<User>}
   */
  public getUserOrAuthenticate(): Promise<User> {
    const currentUser = this.getUser();

    if (currentUser.token) {
      return Promise.resolve(currentUser);
    }

    return this.authenticate();
  }

  /**
   * Login with an email and password
   * @param {string} email
   * @param {string} password
   * @returns {Promise<User>}
   */
  public login(email: string, password: string): Promise<User> {
    return this.userModel.login(email, password).then((u): User => this.setAsLoggedIn(u));
  }

  /**
   * Register and log the user in
   * @param {User} user
   * @returns {Promise<any>}
   */
  public register(user: User): Promise<User> {
    return this.userModel.register(user).then((u): User => this.setAsLoggedIn(u));
  }

  /**
   * Validate Strong Password
   * @param password
   * @returns
   */
  public validateStrongPassword(password: string): Promise<boolean | void> {
    return this.userModel.validateStrongPassword(password);
  }

  /**
   * Facebook login
   * @returns {Promise<any>}
   */
  public facebookLogin(): Promise<User> {
    return this.userModel.facebookLogin().then((u): User => this.setAsLoggedIn(u));
  }

  /**
   * Reset the password
   * @param {string} token
   * @param {string} password
   * @returns {Promise<User>}
   */
  public resetPassword(token: string, password: string): Promise<User> {
    return this.userModel.resetPassword(token, password);
  }

  /**
   * Request password reset
   * @param {Email} email
   * @returns {Promise<User>}
   */
  public requestPasswordReset(email: Email): Promise<User> {
    const user = new User(email.address);
    return this.userModel.requestPasswordReset(user);
  }

  /**
   * Request acctivate account reset
   * @param {Email} email
   * @returns {Promise<User>}
   */
  public requestActivateAccount(email: Email): Promise<User> {
    const user = new User(email.address);
    return this.userModel.requestActivateAccount(user);
  }

  /**
   * Request delete
   * @param {User} user
   * @returns {Promise<User>}
   */
  public requestDelete(user: User): Promise<User> {
    return this.userModel.requestDelete(user);
  }

  /**
   * Request order tracking token
   * @param {Order} order
   * @param {Email} email
   * @param {string} postcode
   * @param {'tracking' | 'quality'} kind
   * @returns {Promise<string>}
   */
  public requestOrderTrackingToken(
    order: Order,
    email?: Email,
    postcode?: string,
    kind: 'tracking' | 'quality' = 'tracking'
  ): Promise<string> {
    return this.userModel.requestOrderTrackingToken(order, email, postcode, kind);
  }

  /**
   * Set as logged in
   * @param {User} user
   * @returns {User}
   */
  public setAsLoggedIn(user: User): User {
    user.loggedIn = true;
    this.setStorageTokens(user);
    this.setCurrentUser(user);

    if (user.orderCount > 0) {
      this.localStorage.set('userHasOrdered', 'true', 'localStorage' as StorageType.Local);
    }
    return user;
  }

  /**
   * Logout
   * @returns {Promise<User>}
   */
  public logout(): Promise<User> {
    const currentUser = this.getUser();

    return this.userModel.logout(currentUser).then((): User => {
      this.localStorage.set('userEmail', '');
      this.localStorage.set('userToken', '');
      this.localStorage.set('userFullName', '');
      this.localStorage.set('purchaseId', '');
      this.localStorage.set('purchaseToken', '');
      this.setCurrentUser(new User());
      this.authenticatePromise = undefined;

      return this.getUser();
    });
  }

  /**
   * Get the details for the user
   * @returns {Promise<User>}
   */
  public getDetails(): Promise<User> {
    const currentUser = this.getUser();
    return this.userModel.get(currentUser);
  }

  /**
   * Set Password
   * @param {string} password
   * @returns {Promise<User>}
   */
  public setPassword(password: string): Promise<User> {
    const currentUser = this.getUser();
    const updatedUser = new User();
    updatedUser.password = password;
    return this.userModel.update(currentUser, updatedUser);
  }

  /**
   * Reauthenticate the user with the backend
   * This clears '_user', 'user', '_authToken', and 'authToken' from local storage
   * as this was used in the old (and 'new' checkout)
   */
  public authenticate(): Promise<User> {
    if (this.authenticatePromise) {
      return this.authenticatePromise;
    }

    const userEmail =
      this.localStorage.getString('userEmail') || (this.localStorage.get('user') || this.localStorage.get('_user') || {}).email; // 'New' Checkout // 'Old' Checkout

    const token =
      this.localStorage.getString('userToken') || // Ng6
      this.localStorage.getString('authToken') || // 'New' Checkout
      this.localStorage.getString('_authToken'); // 'Old' Checkout

    this.clearOldLocalStorageTokens();

    const hasAuthTokens = userEmail && token && token.length;
    if (!hasAuthTokens) {
      const u = new User(userEmail);
      u.fullName = this.localStorage.getString('userFullName') || '';
      this.authenticatePromise = Promise.resolve(u);
    } else {
      const u = new User(userEmail, token);
      this.authenticatePromise = this.userModel
        .get(u, token)
        .then((authedUser): User => {
          authedUser.loggedIn = true;
          this.setStorageTokens(authedUser);
          return authedUser;
        })
        .catch((): Promise<User> => {
          this.localStorage.set('userEmail', '');
          this.localStorage.set('authToken', '');
          return Promise.reject(new User());
        });
    }

    // Set the current user
    this.authenticatePromise = this.authenticatePromise.then((u: User): Promise<User> => {
      this.setCurrentUser(u);
      return u.isLoggedIn() ? Promise.resolve(u) : Promise.reject(u);
    });

    return this.authenticatePromise;
  }

  /**
   * Authenticate User From App to Web
   * @param tokenAppToWeb
   * @returns
   */
  public authenticateAppToWeb(tokenAppToWeb: string): Promise<User> {
    const token = tokenAppToWeb;

    return this.userModel
      .authenticateAppToWeb(token)
      .then((authedUser): User => this.setAsLoggedIn(authedUser))
      .catch((): Promise<User> => {
        this.localStorage.set('userEmail', '');
        this.localStorage.set('authToken', '');
        return Promise.reject(new User());
      });
  }

  /**
   * Google login via creditial
   * @param {string} credential
   * @returns {user}
   */
  public googleLogin(credential: string): Promise<User> {
    return this.userModel.googleLogin(credential).then((u): User => this.setAsLoggedIn(u));
  }

  /**
   * Validate unusal sign in
   * TODO: add endpoint typings
   * @param {string} deviceIdentifier
   * @param {string} token
   * @returns {Promise<any>}
   */
  public validateUnusualSignIn(deviceIdentifier: string, token: string): Promise<void> {
    return this.userModel.validateUnusualSignIn(deviceIdentifier, token);
  }

  /**
   * Clear the local storage tokens from the old or 'new' apps
   */
  private clearOldLocalStorageTokens(): void {
    this.localStorage.set('_user', ''); // 'New' Checkout
    this.localStorage.set('_authToken', ''); // 'New' Checkout
    this.localStorage.set('user', ''); // 'Old' Checkout
    this.localStorage.set('authToken', ''); // 'New' Checkout
  }
}
