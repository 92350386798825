
<button class="staging-helper__custom-trigger" id="stagingHelperTrigger" (click)="toggleCollapsed()" *ngIf="collapsed" [ngClass]="{ 'staging-helper__custom-trigger--active-pbs': currentAPIUrl !== defaultBackendURL }">🔧</button>
<ui-box class="staging-helper__widget" id="stagingHelperWidget" #stagingHelperWidget [ngClass]="!collapsed ? 'widget--open' : 'widget--closed'" box_border_width="primary" box_space="none" box_space_bottom="default">
  <ui-box class="widget__header" box_space="none" box_space_right="default">
    <ui-stack stack_align="end">
      <ui-box box_fill_mode="fit" box_space_right="none">
        <ui-button (clicked)="toggleCollapsed()" button_icon="close" button_icon_placement="iconOnly" button_size="small" button_type="tertiary"><span aria-hidden="true">&times;</span></ui-button>
      </ui-box>
    </ui-stack>
  </ui-box>
  <ui-stack stack_gap="near">
    <ui-box class="widget__main" box_space_top="none">
      <ui-stack stack_gap="tight">
        <ui-stack class="widget__action" stack_align="center" stack_direction="x" stack_gap="tight">
          <ui-button class="widget__action" (clicked)="toggleSection('segments')" [button_icon]="showSection.segments ? 'chevron-down' : 'chevron-right'" button_event_type="button" button_icon_placement="trailing" button_size="small" button_type="tertiary"><span>📊 Segments</span></ui-button>
          <ui-pill-group *ngIf="currentSegments" pill_group_align="start" pill_group_collapse_below="none">
            <ui-pill *ngFor="let item of currentSegments" tone="positive">{{ item.id }}</ui-pill>
          </ui-pill-group>
        </ui-stack>
        <ng-container *ngIf="showSection.segments" [ngTemplateOutlet]="segmentSection"></ng-container>
        <ui-divider></ui-divider>
        <ui-stack class="widget__action" stack_align="center" stack_direction="x" stack_gap="tight">
          <ui-button class="widget__action" (clicked)="toggleSection('experiments')" [button_icon]="showSection.experiments ? 'chevron-down' : 'chevron-right'" button_event_type="button" button_icon_placement="trailing" button_size="small" button_type="tertiary"><span>🔬 Experiments</span></ui-button>
          <ui-pill-group *ngIf="[totalExperimentsIn]" pill_group_align="start" pill_group_collapse_below="none">
            <ui-pill *ngFor="let item of [totalExperimentsIn]" tone="positive">{{ item }}</ui-pill>
          </ui-pill-group>
        </ui-stack>
        <ng-container *ngIf="showSection.experiments" [ngTemplateOutlet]="experimentSection"></ng-container>
        <ui-divider></ui-divider>
        <ui-stack class="widget__action" stack_align="center" stack_direction="x" stack_gap="tight">
          <ui-button class="widget__action" (clicked)="editInContentful()" [button_disabled]="!showSection.contentfulEditor" [button_icon]="'chevron-right'" button_event_type="button" button_icon_placement="trailing" button_size="small" button_type="tertiary"><span>🎨 Contentful Editor</span></ui-button>
        </ui-stack>
        <ui-divider></ui-divider>
        <ui-stack class="widget__action" stack_align="center" stack_direction="x" stack_gap="tight">
          <ui-button class="widget__action" (clicked)="toggleSection('pbsDetails')" [button_icon]="showSection.pbsDetails ? 'chevron-down' : 'chevron-right'" button_event_type="button" button_icon_placement="trailing" button_size="small" button_type="tertiary"><span>🕸️ API URL</span></ui-button>
        </ui-stack>
        <ng-container *ngIf="showSection.pbsDetails" [ngTemplateOutlet]="apiUrlSection"></ng-container>
        <ui-divider></ui-divider>
        <ui-stack class="widget__action" stack_align="center" stack_direction="x" stack_gap="tight">
          <ui-button class="widget__action" (clicked)="toggleSection('rangeDiscoveryPbsDetails')" [button_icon]="showSection.rangeDiscoveryPbsDetails ? 'chevron-down' : 'chevron-right'" button_event_type="button" button_icon_placement="trailing" button_size="small" button_type="tertiary"><span>🥗 Radish API URL</span></ui-button>
        </ui-stack>
        <ng-container *ngIf="showSection.rangeDiscoveryPbsDetails" [ngTemplateOutlet]="rangeDiscoveryAPIUrlSection"></ng-container>
        <ui-divider></ui-divider>
        <ui-stack class="widget__action" stack_align="center" stack_direction="x" stack_gap="tight">
          <ui-button class="widget__action" (clicked)="enableDebug()" [button_icon]="'chevron-right'" button_event_type="button" button_icon_placement="trailing" button_size="small" button_type="tertiary"><span>🐞 Analytics Debug</span></ui-button>
        </ui-stack>
        <ui-divider></ui-divider>
        <ui-stack class="widget__action" stack_align="center" stack_direction="x" stack_gap="tight">
          <ui-button class="widget__action" (clicked)="refresh()" [button_icon]="'chevron-right'" button_event_type="button" button_icon_placement="trailing" button_size="small" button_type="tertiary"><span>♻️ Refresh</span></ui-button>
        </ui-stack>
        <ui-divider></ui-divider>
        <ui-box box_space="none" box_space_top="tight">
          <ui-heading heading_alignment_override="center" heading_title="Website shortcuts" heading_type="functional-secondary"></ui-heading>
        </ui-box>
        <ui-stack class="widget__action" stack_align="center" stack_direction="x" stack_gap="tight">
          <ui-button class="widget__action" (clicked)="toggleSection('checkoutShortcut')" [button_icon]="showSection.checkoutShortcut ? 'chevron-down' : 'chevron-right'" button_event_type="button" button_icon_placement="trailing" button_size="small" button_type="tertiary"><span>🛒 To Checkout</span></ui-button>
        </ui-stack>
        <ng-container *ngIf="showSection.checkoutShortcut" [ngTemplateOutlet]="toCheckoutSection"></ng-container>
        <ui-divider></ui-divider>
        <ui-stack class="widget__action" stack_align="center" stack_direction="x" stack_gap="tight">
          <ui-button class="widget__action" (clicked)="toggleSection('confirmationShortcut')" [button_icon]="showSection.confirmationShortcut ? 'chevron-down' : 'chevron-right'" button_event_type="button" button_icon_placement="trailing" button_size="small" button_type="tertiary"><span>✅ Confirmation Page</span></ui-button>
        </ui-stack>
        <ng-container *ngIf="showSection.confirmationShortcut" [ngTemplateOutlet]="confirmationPageSection"></ng-container>
        <ui-divider></ui-divider>
        <ui-box box_space="none" box_space_top="tight">
          <ui-heading heading_alignment_override="center" heading_title="Website Links" heading_type="functional-secondary"></ui-heading>
        </ui-box>
        <ui-stack class="widget__action" stack_align="center" stack_direction="x" stack_gap="tight">
          <ui-button class="widget__action" (clicked)="toggleSection('stagingSites')" [button_icon]="showSection.stagingSites ? 'chevron-down' : 'chevron-right'" button_event_type="button" button_icon_placement="trailing" button_size="small" button_type="tertiary"><span>🌐 Staging Urls</span></ui-button>
        </ui-stack>
        <ng-container *ngIf="showSection.stagingSites" [ngTemplateOutlet]="stagingUrlSection"></ng-container>
        <ui-divider></ui-divider>
        <ui-stack class="widget__action" stack_align="center" stack_direction="x" stack_gap="tight">
          <ui-button class="widget__action" (clicked)="toggleSection('branchSites')" [button_disabled]="!checkIfFeatureBranch(&quot;performance&quot;)" [button_icon]="showSection.branchSites ? 'chevron-down' : 'chevron-right'" button_event_type="button" button_icon_placement="trailing" button_size="small" button_type="tertiary"><span>🆕 Branch Urls</span></ui-button>
        </ui-stack>
        <ng-container *ngIf="showSection.branchSites" [ngTemplateOutlet]="branchesUrlSection"></ng-container>
        <ui-divider></ui-divider>
      </ui-stack>
    </ui-box>
  </ui-stack>
  <bw-loading-spinner *ngIf="loading" [fullscreen]="false"></bw-loading-spinner>
</ui-box>
<ng-template #segmentSection>
  <ui-box box_space="none" box_space_left="tiny">
    <ui-stack stack_gap="tiny">
      <form class="full-width" [formGroup]="form">
        <ui-form-group [group_title_hidden]="true" group_title="Known segments">
          <ui-stack stack_distribute="fill" stack_gap="tiny">
            <ng-container *ngFor="let segment of knownSegments">
              <ui-chip class="full-width" (didChange)="toggleSegment(segment)" [formControlName]="segment.id" [label]="segment.name + ' - ' + '(' + segment.id + ')'" [value]="segment.isActive" chip_type="checkbox"></ui-chip>
            </ng-container>
          </ui-stack>
        </ui-form-group>
      </form>
    </ui-stack>
  </ui-box>
</ng-template>
<ng-template #experimentSection>
  <ui-box box_space="none" box_space_left="tiny">
    <ui-stack *ngFor="let experiment of knownExperiments" stack_distribute="fill" stack_gap="tiny">
      <ui-box class="widget__experiment" box_fill_mode="fill" box_space="tiny">
        <ui-stack stack_align="center" stack_direction="x" stack_distribute="space-between" stack_gap="tiny">
          <p class="widget__experiment-name text-body--supporting">{{ experiment.name }}</p>
          <ui-button-group button_group_collapse_below="none">
            <ui-button (clicked)="refreshWithExperiment(experiment.name, variant)" *ngFor="let variant of experiment.variants" [button_type]="experiment.variant === variant ? 'primary' : 'secondary'" button_size="small">{{ variant }}</ui-button>
          </ui-button-group>
        </ui-stack>
      </ui-box>
    </ui-stack>
  </ui-box>
</ng-template>
<ng-template #apiUrlSection>
  <ui-box box_space="none" box_space_left="tiny">
    <form [formGroup]="form">
      <ui-form-group [group_title_hidden]="true" group_title="Api Url Form">
        <ui-text-input formControlName="newApiURL" label="Current API URL" type="text"></ui-text-input>
      </ui-form-group>
      <ui-stack stack_gap="tight">
        <ui-button-group button_group_collapse_below="none">
          <ui-button (clicked)="setApiURL()">Set</ui-button>
          <ui-button (clicked)="clearAPIURL()" [title]="this.defaultBackendURL">Reset to Default</ui-button>
        </ui-button-group>
        <ui-stack stack_direction="x" stack_gap="tight">
          <p class="text-body--supporting text-body--emphasis">Default:</p>
          <p class="text-body--supporting">{{ this.defaultBackendURL }}</p>
        </ui-stack>
      </ui-stack>
    </form>
  </ui-box>
</ng-template>
<ng-template #rangeDiscoveryAPIUrlSection>
  <ui-box box_space="none" box_space_left="tiny">
    <form [formGroup]="form">
      <ui-form-group [group_title_hidden]="true" group_title="Range discovery Api Url Form">
        <ui-text-input formControlName="newRangeDiscoveryAPIUrl" label="Current Range Discovery API URL" type="text"></ui-text-input>
      </ui-form-group>
      <ui-stack stack_gap="tight">
        <ui-button-group button_group_collapse_below="none">
          <ui-button (clicked)="setRangeDiscoveryAPIUrl()">Set</ui-button>
          <ui-button (clicked)="clearRangeDiscoveryAPIUrl()" [title]="this.defaultRangeDiscoveryAPIUrl">Reset to Default</ui-button>
        </ui-button-group>
        <ui-stack stack_direction="x" stack_gap="tight">
          <p class="text-body--supporting text-body--emphasis">Default:</p>
          <p class="text-body--supporting">{{ this.defaultRangeDiscoveryAPIUrl }}</p>
        </ui-stack>
      </ui-stack>
    </form>
  </ui-box>
</ng-template>
<ng-template #stagingUrlSection>
  <ui-box box_space="none" box_space_left="tiny">
    <ui-stack stack_gap="tight">
      <ng-container *ngFor="let brand of stagingSites">
        <p class="text-body--default text-body--emphasis">{{ brand.name }} Staging Urls</p>
        <ui-stack *ngFor="let site of brand.sites" stack_direction="x" stack_gap="tight">
          <ui-button [href]="site.stagingUrl" button_size="small" button_type="tertiary">{{ site.flag }} {{ site.stagingUrl }}</ui-button>
        </ui-stack>
      </ng-container>
    </ui-stack>
  </ui-box>
</ng-template>
<ng-template #branchesUrlSection>
  <ui-box box_space="none" box_space_left="tiny">
    <ui-stack stack_gap="tight">
      <ng-container *ngFor="let brand of stagingSites">
        <p class="text-body--default text-body--emphasis">{{ brand.name }} Feature Branch Urls</p>
        <ui-stack *ngFor="let site of brand.sites" stack_direction="x" stack_gap="tight">
          <ui-button button_size="small" button_type="tertiary" href="https://fe-performance-{{brand.name === &quot;Bloomandwild&quot; ? 'bandw' : 'bloomon'}}-com.bloomdev.org/"><span>{{ site.flag }}</span><span>&#32;&#32; https://fe-performance-{{  brand.name === "Bloomandwild" ? 'bandw' : 'bloomon'  }}-com.bloomdev.org/</span></ui-button>
        </ui-stack>
      </ng-container>
    </ui-stack>
  </ui-box>
</ng-template>
<ng-template #toCheckoutSection>
  <ui-box box_space="none" box_space_left="tiny">
    <form [formGroup]="form">
      <ui-box box_space="none" box_space_bottom="tight">
        <ui-stack stack_gap="near">
          <p class="text-body--supporting text-body--emphasis">Please add the sku id to proceed to the checkout page. Click on link below for example sku ids.</p><a class="text-link text-body--supporting text-body--emphasis" (click)="toggleSection('exampleSkus')">
            <ui-stack stack_align="center" stack_direction="x" stack_gap="tight"><span>Example Products</span>
              <ui-icon [icon_name]="showSection.exampleSkus ? 'chevron-down' : 'chevron-right'"></ui-icon>
            </ui-stack></a>
          <ui-box *ngIf="showSection.exampleSkus" box_space="none">
            <ui-stack class="text-body--supporting" stack_direction="x" stack_gap="tight">
              <p class="text-body--emphasis">One off Bouquet:</p>
              <p>{{  getProduct("letterbox")?.id ?? 'n/a'  }}</p>
            </ui-stack>
            <ui-stack class="text-body--supporting" stack_direction="x" stack_gap="tight">
              <p class="text-body--emphasis">One off Plant:</p>
              <p>{{  getProduct("plants")?.id ?? 'n/a'  }}</p>
            </ui-stack>
            <ui-stack class="text-body--supporting" stack_direction="x" stack_gap="tight">
              <p class="text-body--emphasis">Bundle:</p>
              <p>{{  getProduct("bundle")?.id ?? 'n/a'  }}</p>
            </ui-stack>
            <ui-stack class="text-body--supporting" stack_direction="x" stack_gap="tight">
              <p class="text-body--emphasis">Subscription:</p>
              <p>{{  getProduct("subscription")?.id ?? 'n/a'  }}</p>
            </ui-stack>
          </ui-box>
        </ui-stack>
      </ui-box>
      <ui-form-group [group_title_hidden]="true" group_title="Checkout Form">
        <ui-text-input formControlName="skuId" label="Sku Id" type="text"></ui-text-input>
      </ui-form-group>
      <ui-box *ngIf="!checkSkuIdIfValid() &amp;&amp; skuIdSubmitted" box_space="none" box_space_bottom="tight">
        <p class="text-body--supporting text-body--emphasis text-color--error">Product not found! Please check the sku id.</p>
      </ui-box>
      <ui-button-group button_group_collapse_below="none">
        <ui-button (clicked)="goToCheckout()" [button_disabled]="!form.get('skuId').value">Confirm</ui-button>
        <ui-button (clicked)="clearCheckoutForm()">Reset to Default</ui-button>
      </ui-button-group>
    </form>
  </ui-box>
</ng-template>
<ng-template #confirmationPageSection>
  <ui-box box_space="none" box_space_left="tiny">
    <form [formGroup]="form">
      <ui-box box_space="none" box_space_bottom="tight">
        <p class="text-body--supporting text-body--emphasis">Please fill in form. This will redirect you to the confirmation page.</p>
        <p class="text-body--supporting text-color--supporting">Warning: This shortcut only works if you are logged in. If you are not logged in, please log in first.</p>
      </ui-box>
      <ui-form-group [group_title_hidden]="true" group_title="confirmation page Form">
        <ui-text-input formControlName="purchaseId" label="Purchase id" type="text"></ui-text-input>
      </ui-form-group>
      <ui-button-group button_group_collapse_below="none">
        <ui-button (clicked)="goToConfirmationPage()" [button_disabled]="!checkIfConfirmationIsAvailable()">Confirm</ui-button>
        <ui-button (clicked)="clearConfirmationForm()">Reset to Default</ui-button>
      </ui-button-group>
    </form>
  </ui-box>
</ng-template>