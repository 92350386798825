import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {
  private isAlphanumeric(char: string): boolean {
    return /^[a-zA-Z0-9_\-ÀÁÂÃÄÅĄàáâãäåąßÒÓÔÕÕÖØÓòóôõöøóÈÉÊËĘèéêëęðÇĆçćÐÌÍÎÏìíîïÙÚÛÜùúûüÑŃñńŠŚšśŸÿýŽŻŹžżź]/.test(
      char
    );
  }

  transform(value: string, length: number): any {
    if (value.length <= length) {
      return value;
    }

    const slicedString = value.slice(0, length - 3);

    // if the last character of the sliced string AND the character immidiately after the slicedString is non-alphanumeric
    if (
      this.isAlphanumeric(slicedString[slicedString.length - 1]) &&
      !this.isAlphanumeric(value[slicedString.length])
    ) {
      return `${slicedString}...`;
    }

    let count = 0;
    let nonAlphanumericFound = false;

    for (let i = slicedString.length - 1; i > 0; i--) {
      const nonAlphanumericChar = !this.isAlphanumeric(slicedString[i]);

      if (!nonAlphanumericFound) {
        // add to the count every character until a non-alphanumeric character is found
        count += 1;
      }

      if (nonAlphanumericFound) {
        // if an alphanumeric character is found after (before due to reversed string) a non-alphanumeric
        if (!nonAlphanumericChar) {
          break;
        }

        count += 1;
      }

      // if non-alphanumeric found
      if (nonAlphanumericChar) {
        nonAlphanumericFound = true;
      }
    }

    // remove characters from the end of the string back to the end of a word
    return `${slicedString.slice(0, length - 3 - count)}...`;
  }
}
