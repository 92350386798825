/**
 * Due to our contentful implementation, and that pages are static
 * We need to map the blog "tag" to the Flower Journal (basically a blog...) Url and Name
 * If we ever move away from contentful, to some kind of "proper" dynamic CMS, then, hopefully we won't need this file
 * Daisy says the categories are "here to stay and won't change".... let's see :)
 */
export const blogCategories = [
  {
    sites: ['en-gb', 'en-at'],
    baseBreadcrumb: {
      name: 'Flower Journal',
      url: '/the-blog'
    },
    categories: [
      { tag: 'occasions', url: '/flower-occasions', name: 'Occasions' },
      { tag: 'tips', url: '/flower-tips-and-how-to', name: 'Flower Guides' },
      { tag: 'peony', url: '/peonies-flower-journal', name: 'Peonies' },
      { tag: 'roses', url: '/rose-flowers-journal', name: 'Roses' },
      { tag: 'lilies', url: '/lily-flowers-journal', name: 'Lilies' },
      { tag: 'freesias', url: '/freesia-flowers-journal', name: 'Freesias' },
      { tag: 'alstroemeria', url: '/alstroemeria-flowers-journal', name: 'Alstroemeria' },
      { tag: 'diy', url: '/diy', name: 'DIY' },
      { tag: 'interiors', url: '/interior-design', name: 'Decoration' },
      { tag: 'gifting', url: '/gifting', name: 'Gifting' },
      { tag: 'trends', url: '/flower-trends', name: 'Lifestyle' }
    ]
  },
  {
    sites: ['de-de', 'de-at'],
    baseBreadcrumb: {
      name: 'Blumen Journal',
      url: '/neuigkeiten'
    },
    categories: []
  }
];
