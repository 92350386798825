
<!-- Choosing a selection then continue to checkout-->
<!-- My account bouquet selection-->
<div class="product-card-modal" *ngIf="!isLoading">
  <div class="modal-header">
    <div class="product-card-modal__container">
      <div class="product-card-modal__placeholder"></div>
      <div class="product-card-modal__heading">Subscriptions</div>
      <button class="close" aria-label="close" (click)="onCancel()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="product-card-modal__header">How to get monthly flowers</div>
  </div>
  <div class="modal-body" #bwModal>
    <div class="product-card-modal__section">
      <div class="product-card-modal__section-heading">1. Choose how long you'd like your subscription to be</div>
      <div class="product-card-modal__section-content">
        <bw-module-tabs class="product-card-modal--tabs" mobuleTabsStyle="primary" [moduleTabs]="subscriptionType"></bw-module-tabs>
        <bw-module-tabs *ngIf="selectedProduct?.bundleOnly" mobuleTabsStyle="secondary" [moduleTabs]="bundleProducts"></bw-module-tabs>
        <!-- Toggle between frequencies-->
        <ng-container *ngIf="selectedProduct?.subscriptionOnly">
          <div class="product-card-modal__day-selector" *ngIf="selectedFrequency === 7" (click)="changeFrequency(14)">
            <div class="product-card-modal__day-selector-heading">Every {{ selectedFrequency }} days</div>
            <div class="product-card-modal__day-selector-action">want them fortnightly?</div>
          </div>
          <div class="product-card-modal__day-selector" *ngIf="selectedFrequency === 14" (click)="changeFrequency(28)">
            <div class="product-card-modal__day-selector-heading">Every {{ selectedFrequency }} days</div>
            <div class="product-card-modal__day-selector-action">want them monthly?</div>
          </div>
          <div class="product-card-modal__day-selector" *ngIf="selectedFrequency === 28" (click)="changeFrequency(7)">
            <div class="product-card-modal__day-selector-heading">Every {{ selectedFrequency }} days</div>
            <div class="product-card-modal__day-selector-action">want them weekly?</div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="product-card-modal__separator product-card-modal--top-margin"></div>
    <div class="product-card-modal__info-list" *ngIf="selectedProduct?.subscriptionOnly">
      <bw-alert alertStyle="list">Pay before each delivery</bw-alert>
      <bw-alert alertStyle="list">Choose your delivery dates</bw-alert>
      <bw-alert alertStyle="list">Pause whenever you need to</bw-alert>
    </div>
    <div class="product-card-modal__info-list" *ngIf="selectedProduct?.bundleOnly">
      <bw-alert alertStyle="list">Pay for all deliveries today</bw-alert>
      <bw-alert alertStyle="list">Choose your delivery dates</bw-alert>
      <bw-alert alertStyle="list">Add a new gift card to every box</bw-alert>
    </div>
    <div class="product-card-modal__separator product-card-modal--bottom-margin"></div>
    <div class="product-card-modal__section" *ngIf="associatedSubscriptionTypes?.length">
      <div class="product-card-modal__section-heading">2. Choose the type of flowers you'd like</div>
      <bw-product-option-list [products]="associatedSubscriptionTypes" [selectedProduct]="selectedProduct" [hidePaginationMobile]="true" [showPrice]="true" (productSelected)="onUpsellSelected($event.product)"></bw-product-option-list>
    </div>
    <bw-product-info-section *ngIf="selectedProduct" [product]="selectedProduct"></bw-product-info-section>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="selectedProduct?.subscriptionOnly &amp;&amp; footerLoad">
      <ng-container *ngIf="!selectedProduct?.getPrice()?.discount">
        <div class="product-card-modal__price" *ngIf="selectedFrequency === 28"><strong>{{selectedProduct.getPrice(28) | price }}</strong> every 28 days</div>
        <div class="product-card-modal__price" *ngIf="selectedFrequency === 14"><strong>{{ selectedProduct.getPrice(14) | price }}</strong> every 14 days</div>
        <div class="product-card-modal__price" *ngIf="selectedFrequency === 7"><strong>{{ selectedProduct.getPrice(7) | price }}</strong> every 7 days</div>
        <div class="product-card-modal__price-small">Flexible - pause or cancel at any time</div>
      </ng-container>
      <ng-container *ngIf="selectedProduct?.getPrice()?.discount">
        <div class="product-card-modal__price-bold" *ngIf="numberOfDeliveries === 1">First Delivery <span class="product-card-modal__price-discount">{{ selectedProduct.getPrice(selectedFrequency) | price:'original' }}</span> {{ selectedProduct.getPrice(selectedFrequency) | price }}</div>
        <div class="product-card-modal__price-bold" *ngIf="numberOfDeliveries &gt; 1">First {{ numberOfDeliveries }} deliveries <span class="product-card-modal__price-discount">{{ selectedProduct.getPrice(selectedFrequency) | price:'original' }}</span> {{ selectedProduct.getPrice(selectedFrequency) | price }}</div>
        <div class="product-card-modal__price-bold" *ngIf="numberOfDeliveries === -1"><span class="product-card-modal__price-discount">{{ selectedProduct?.getPrice(selectedFrequency) | price:'original' }}</span> {{ selectedProduct.getPrice(selectedFrequency) | price }}</div>
        <div class="product-card-modal__price-small">Following deliveries {{ selectedProduct.getPrice(selectedFrequency) | price:'original' }} every {{ selectedFrequency }} days - change, pause or cancel at any time</div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="selectedProduct?.bundleOnly &amp;&amp; footerLoad">
      <div class="product-card-modal__price"><strong>{{ selectedProduct?.getPrice() | price }}</strong> total</div>
      <div class="product-card-modal__price-small product-card-modal__price-small__bundle-only">{{ selectedProduct.getPrice().quantity }} deliveries pre-paid at {{ selectedProduct.getPrice() | pricePerDelivery:selectedProduct.getPrice().quantity | price }} per delivery</div>
    </ng-container>
    <bw-button (click)="onSubmit()" bwTrackAs="component.product-card-modal.cta">SEND</bw-button>
  </div>
</div>
<bw-loading-spinner *ngIf="isLoading"></bw-loading-spinner>