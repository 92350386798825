import { Component, OnInit, Input, OnDestroy, EventEmitter } from '@angular/core';
import { ModalService, ModalResponse, ModalResponseSubject } from 'Shared/services/modal.service';
import { MegaNavItem } from 'Shared/classes/mega-nav-item';
import { Subject } from 'rxjs';
import { MegaNavService } from 'Shared/services/mega-nav.service';
import { StateService } from 'Shared/services/state.service';
import { ConfigService } from 'Shared/services/config.service';
import { CountryService } from 'Shared/services/country.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { UserService } from 'Shared/services/user.service';
import { FeaturesService } from 'Shared/services/features.service';
import { translations } from 'Environments/js-translations';
import { AnalyticsService } from 'Project/shared/services/analytics.service';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

export const checkoutBaseUrl = translations['js.routing.checkout_base'];

/* bw:view-encapsulation */
@Component({
  selector: 'bw-mega-nav-mobile',
  templateUrl: './mega-nav-mobile.component.html'
})
export class MegaNavMobileComponent implements OnInit, ModalResponse, OnDestroy {
  @Input() navItems: MegaNavItem[];
  modalResponse: Subject<ModalResponseSubject> = new Subject<ModalResponseSubject>();
  megaNav: MegaNavItem[] = [];
  activeSection: any;
  site: string;
  shippingTo: number;
  megaNavMobileShow: boolean = false;
  user$: any;
  siteChanged: any;

  activeBreadcrumb: any[] = [];
  popperPlacement: NgxPopperjsPlacements = NgxPopperjsPlacements.TOPSTART;

  constructor(
    private megaNavService: MegaNavService,
    private stateService: StateService,
    private configService: ConfigService,
    private countryService: CountryService,
    public modalService: ModalService,
    public experimentService: ExperimentsService,
    public userService: UserService,
    private featuresService: FeaturesService,
    private analyticsService: AnalyticsService,
    private optimizelyService: OptimizelyService
  ) {
    this.user$ = this.userService.user$;
    this.site = this.configService.getConfig().site;
    this.shippingTo = this.countryService.forShipping.id;
  }

  onSuccess?(): void {
    this.modalResponse.next({ success: false });
  }

  onCancel?(): void {
    this.modalResponse.next({ success: false });
  }

  /**
   *
   * @param navItem
   */
  navInteractionTracking(navItem: MegaNavItem): void {
    this.optimizelyService.trackEvent('nav_interaction');
    this.analyticsService.trackInHeap('navInteraction', {
      mainNavItem: navItem.name || undefined,
      primarySubNavItem: undefined,
      secondarySubNavItem: undefined,
      navStyle: 'mega-nav',
      contentSpot: false,
      mainNavItemNew: this.setMainNavItemNew(navItem),
      primarySubNavItemNew: undefined,
      secondarySubNavItemNew: undefined,
      subNavLength: undefined,
      subNavPosition: undefined
    });

    this.modalResponse.next({ success: false });
  }

  /**
   *
   * @param navItem
   * @returns
   */
  setMainNavItemNew(navItem: MegaNavItem): boolean {
    return (navItem.styles || []).indexOf('new') > -1;
  }

  /**
   * On Child Nav close set activeSection as null
   */
  onSecondaryNavClose(): void {
    this.activeSection = null;
  }

  closeBurgerMenu(): void {
    this.modalService.hideAllModals();
  }

  setActiveSection(link: any): any {
    if (!link) {
      return;
    }

    if (this.activeSection && this.activeSection.name === link.name) {
      this.activeSection = null;
      return;
    }

    this.activeSection = {
      name: link.name,
      backgroundColour: link.backgroundColour,
      underlineColour: link.underlineColour,
      sections: link.sections,
      parentSectionLink: link.url
    };
  }

  handleKeyUp(keyboardEvent: KeyboardEvent, link: any): void {
    switch (keyboardEvent.code || keyboardEvent.keyCode) {
      case 'Enter':
      case 13:
        this.setActiveSection(link);
        break;

      case 'Escape':
      case 27:
        // alow user to close the child pannel with Esc
        // if the child pannel is already closed (this.activeSection === null) allow the default Esc event; close the menu
        if (this.activeSection) {
          keyboardEvent.stopImmediatePropagation();
          this.activeSection = null;
        }
        break;
    }
  }

  initMegaNav(): Promise<any> {
    return this.megaNavService.getMegaNav(this.countryService.forShipping).then((nav) => {
      this.megaNav = nav.items;

      // TODO - Write unit test for later to cover this functionality
      if (this.activeBreadcrumb.length) {
        const activeLink = this.megaNav.find((element, index) => {
          return element.name === this.activeBreadcrumb[0].name;
        });
        this.setActiveSection(activeLink);
      }
      setTimeout(() => {}, 10); // Force angular digest
    });
  }

  ngOnInit(): void {
    const current = this.stateService.getCurrent();
    this.activeBreadcrumb = (current.data && current.data.navPath) || [];

    // if the shipping country changes we want to re-init the nav
    this.siteChanged = this.countryService.forShipping$.subscribe((data) => {
      return this.initMegaNav();
    });

    this.initMegaNav();
  }

  ngOnDestroy(): void {
    if (this.siteChanged && this.siteChanged.unsubscribe) {
      this.siteChanged.unsubscribe();
    }
  }
}
