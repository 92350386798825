import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ProductAddonListModalComponent } from 'Checkout/components/product-addon-list-modal/product-addon-list-modal.component';
import { GridProduct } from 'Shared/classes/grid-product';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { ModalService } from 'Shared/services/modal.service';
import { StateService } from 'Shared/services/state.service';
import { DateUtilsService } from 'Shared/utils/date-utils.service';
import { Addon } from 'Shared/classes/addon';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { t } from 'Shared/utils/translations';
import { ActivatedState } from 'Shared/classes/activated-state';
import { FeaturesService } from 'Shared/services/features.service';

const SUPPORTED_REQUIREMENTS = ['pot', 'vase'];

@Injectable()
export class HasAddonRequirementModalGuard implements CanActivate {
  constructor(
    private stateService: StateService,
    private modalService: ModalService,
    private experimentService: ExperimentsService,
    private toastr: ToastrService,
    private featuresService: FeaturesService
  ) {}

  /**
   * Can activate
   */
  canActivate(): Promise<boolean> {
    // tslint:disable-next-line:cyclomatic-complexity
    const fromState = this.stateService.getFrom();
    const toState = this.stateService.getTo<'checkout.start'>();
    const currentData = (toState?.data?.data ||
      {}) as ActivatedState<'checkout.start'>['data']['data'];

    // If user has come from the gift options A/B test then don't show the addons modal again
    // Guard rail to ensure if stateService ever changes, this code does not randomly trigger
    if (toState?.name !== 'checkout.start' || fromState?.name === 'checkout.giftOptions') {
      return Promise.resolve(true);
    }
    const product = currentData?.product as GridProduct;
    const shopType =
      this.featuresService.getFeature('SUBSCRIPTION_SHOP_NEW_UX') &&
      (product?.subscriptionOnly || currentData?.params?.duration === -1)
        ? 'subscription'
        : 'default';

    if (!product) {
      return Promise.resolve(true); // This will go to checkout, skipping the modal
    }

    // Check if we have any requirements for this
    const selectedAddons = (currentData?.addons || []) as Addon[];
    const addonRequirement = (product.addonRequirements || [])
      .filter((requirement) => SUPPORTED_REQUIREMENTS.indexOf(requirement.kind) > -1)
      .filter((requirement) => {
        const hasAddonThatMatches = selectedAddons.filter((a) => a.type === requirement.kind);

        return (
          (requirement.min === 0 && !hasAddonThatMatches.length) ||
          hasAddonThatMatches.length < requirement.min
        );
      });

    // We have no requirements, or the requirements have already been matched with addons selected
    if (!addonRequirement.length) {
      return Promise.resolve(true);
    }

    const preferredDate = DateUtilsService.fromString(
      currentData.params?.date || toState.params?.date
    );

    return this.modalService
      .show(ProductAddonListModalComponent, {
        trackingKey: 'addonListModal',
        initialState: {
          product,
          selectedAddons,
          preferredDate,
          shopType,
          addonRequirement: addonRequirement[0] // TODO: multiple modals
        },
        class: 'modal-lg product-addon-list-modal--height'
      })
      .then(({ date, addons }) => {
        const data = toState.data || {}; // note: data.data! (this is due to how data is passed from the grid)
        data.data = (data.data || {}) as ActivatedState<'checkout.start'>['data']['data'];
        data.data.params = data.data.params || {};
        data.data.addons = addons;

        // Only set the date if the date found by the modal is different to what the user originally requested
        if (!preferredDate) {
          data.data.params.date = undefined;
        } else if (date && preferredDate.format('YYYY-MM-DD') !== date.format('YYYY-MM-DD')) {
          data.data.params.date = date.format('YYYY-MM-DD');
        } else {
          data.data.params.date = preferredDate.format('YYYY-MM-DD');
        }

        this.stateService.addDataToToState(data);

        return Promise.resolve(true);
      })
      .catch((res) => {
        // If we've landed directly on the route guard...
        if (!this.stateService.getFrom()) {
          if (!addonRequirement[0].min) {
            return Promise.resolve(true); // ... go to checkout, if we don't have a minimum
          }
          if (res === false && addonRequirement[0].min) {
            this.toastr.error(t('js.guards.has-addon-modal.guard.cannot-meet-requirements'));
          }
          return this.stateService.go('checkout.base'); // ... go to the grid if we need a minimum
        }

        if (res === undefined || JSON.stringify(res) === '{}') {
          return Promise.resolve(false);
        }

        // If closing modal by choice, return to grid (res = undefined)
        // If not required addons not available, continue to checkout (res = true)
        // If required addon not available, return to grid (res = false)
        if (res === false && addonRequirement[0].min) {
          this.toastr.error(t('js.guards.has-addon-modal.guard.cannot-meet-requirements'));
        }

        return Promise.resolve(!!res);
      });
  }
}
