import { Injectable } from '@angular/core';
import { ContentSegment, SegmentModelService } from 'Shared/models/segment-model.service';
import { Country } from 'Shared/classes/country';
import { UserService } from 'Shared/services/user.service';
import { BoostedProductExperiment } from 'Shared/classes/boosted-product-experiment';

export { ContentSegment };

@Injectable({
  providedIn: 'root'
})
export class SegmentService {
  constructor(
    private segmentModelService: SegmentModelService,
    private userService: UserService
  ) {}

  getSegments(country: Country): Promise<any> {
    return this.userService
      .getUserOrAuthenticate()
      .catch((user) => Promise.resolve(user))
      .then((user) => {
        return this.segmentModelService.getSegments(country, user);
      });
  }

  /**
   * Get the promoted products for the country
   * @param country
   */
  getBoostedProductExperiments(country: Country): Promise<BoostedProductExperiment[]> {
    return this.segmentModelService.getBoostedProductExperiments(country);
  }

  /**
   * @description Get all segments
   * @returns {Promise<ContentSegment[]>}
   */
  getAllSegments(): Promise<ContentSegment[]> {
    return this.segmentModelService.getAllSegments();
  }
}
