import { Component, Input, OnInit } from '@angular/core';
import { FeaturesService } from 'Shared/services/features.service';
import { AvailableIcon, IconVariation } from 'Shared/components/icon/icon.component';
import { ExperimentsService } from 'Shared/services/experiments.service';

type PaymentIconGroupDefinitions = {
  [key: string]: AvailableIcon[];
};

const PAYMENT_ICON_GROUPS: PaymentIconGroupDefinitions = {
  'card-icons': ['visa', 'mastercard', 'amex']
};

export type PaymentIconGroup = keyof typeof PAYMENT_ICON_GROUPS | 'footer-icons';

@Component({
  selector: 'bw-payment-icons',
  templateUrl: './payment-icons.component.html',
  styleUrls: ['./payment-icons.component.scss']
})
export class PaymentIconsComponent implements OnInit {
  @Input() group: PaymentIconGroup;
  @Input() iconVariation: IconVariation;
  icons: AvailableIcon[] = [];

  constructor(private experimentService: ExperimentsService, private featuresService: FeaturesService) {}

  ngOnInit(): void {
    let featurePaymentOptions = this.featuresService.getFeature('PAYMENT_OPTIONS').footerIcons as AvailableIcon[];

    if (!this.featuresService.getFeature('KLARNA_ENABLED') && this.experimentService.isActive('HPT42_BW_UK_WEB_KLARNA', 0)) {
      featurePaymentOptions = featurePaymentOptions.filter((icon): boolean => icon !== 'klarna');
    }

    this.icons = this.group === 'footer-icons' ? featurePaymentOptions : PAYMENT_ICON_GROUPS[this.group];
  }
}
