import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { GlobalIntersectionService } from 'Shared/services/global-intersection.service';

/***
 * This directive calls (hasEntered) and (hasExit) when the element has entered or exited
 * the viewport.
 *
 *  div(
 *    intersectionObserverEvents
 *    (hasEntered)="onHasEntered($event)"
 *    (hasExit)="onHasExit($event)"
 *  )
 *
 *  Additional properties can also be passed through to the underlying intersectionObserver
 *
 *  div(
 *    intersectionObserverEvents
 *    observerRootMargin="0px 0px 0px 0px"
 *    observerThreshold="0.5"
 *    (hasEntered)="onHasEntered($event)"
 *    (hasExit)="onHasExit($event)"
 *  )
 *
 *  Or even, pass in a pre-stored IntersectionObserver instance
 *
 *  div(
 *    intersectionObserverEvents
 *    [observer]="alreadyCreatedInstance"
 *    (hasEntered)="onHasEntered($event)"
 *    (hasExit)="onHasExit($event)"
 *  )
 */
@Directive({
  selector: '[intersectionObserverEvents]'
})
export class IntersectionObserverEventsDirective implements OnInit, OnDestroy {
  @Output() hasEntered: EventEmitter<IntersectionObserverEntry> = new EventEmitter();
  @Output() hasExit: EventEmitter<IntersectionObserverEntry> = new EventEmitter();

  @Input() observeNumberOfEntries: number = Infinity;
  @Input() observerRootMargin: string = '0px 0px 0px 0px';
  @Input() observerThreshold: number = 0;
  @Input() observer: IntersectionObserver;

  totalTimesEntryObserved = 0;

  constructor(
    private globalIntersectionService: GlobalIntersectionService,
    private elementRef: ElementRef
  ) {}

  /**
   * Unobserve Element
   */
  unobserveElement(): void {
    this.globalIntersectionService.unobserve(this.elementRef.nativeElement);
  }

  /**
   * On entry observed
   * @param entry
   */
  onElementEntryObserved(entry: IntersectionObserverEntry): void {
    if (this.totalTimesEntryObserved < this.observeNumberOfEntries) {
      this.hasEntered.emit(entry);
    }

    this.totalTimesEntryObserved++;

    if (this.totalTimesEntryObserved >= this.observeNumberOfEntries) {
      this.unobserveElement();
    }
  }

  /**
   * On exit observed, only if we've observed the entry prior
   * @param entry
   */
  onElementExitObserved(entry: IntersectionObserverEntry): void {
    if (this.totalTimesEntryObserved) {
      this.hasExit.emit(entry);
    }
  }

  /**
   * Observe the element
   */
  observeElement(): void {
    const options = this.observer || {
      rootMargin: this.observerRootMargin,
      threshold: this.observerThreshold,
      root: null
    };

    this.observer = this.globalIntersectionService.observe(
      this.elementRef.nativeElement,
      options,
      entry => {
        this.onElementEntryObserved(entry);
      },
      entry => {
        this.onElementExitObserved(entry);
      }
    );
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    if (typeof this.observeNumberOfEntries === 'string') {
      this.observeNumberOfEntries = parseInt(this.observeNumberOfEntries, 10);
    }
    if (typeof this.observerThreshold === 'string') {
      this.observerThreshold = parseFloat(this.observerThreshold);
    }

    this.observeElement();
  }

  /**
   * NgOnDestory
   */
  ngOnDestroy(): void {
    this.unobserveElement();
  }
}
