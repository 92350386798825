export const reviews = [
  {
    date: '2021-11-25T18:06:22Z',
    title: 'Liefevol',
    review:
      'Hoi\nOntzettend blij met de prachtige bloemen die mijn dochter weer als verrassing had besteld.\n          Lida',
    rating: 5,
    reviewer: 'Lida'
  },
  {
    date: '2021-11-24T19:10:37Z',
    title: 'Wat een prachtig boeket iedere keer weer ❤️',
    review:
      'Iedere keer weer verbaast wat een prachtig samengesteld boeket er wordt bezorgd. De kleuren zijn prachtig en de bloemen heel bijzonder.  Heerlijk om deze te ontvangen en er fijn 2 weken van te kunnen genieten.',
    rating: 5,
    reviewer: 'Geachte Riele Te'
  },
  {
    date: '2021-11-24T11:58:06Z',
    title: 'Ik ben elke keer weer verrast',
    review:
      'Ik ben elke keer weer verrast. Het zijn heel mooi samengestelde bossen. Ik word er elke keer heel vrolijk van en ze staan 2 weken en kan er altijd nog wat uihalen om te drogen.',
    rating: 5,
    reviewer: 'jose bieshaar'
  },
  {
    date: '2021-11-23T22:16:57Z',
    title: 'Altijd een kadootje',
    review: 'Altijd een kadootje ! Prachtig',
    rating: 5,
    reviewer: 'customer'
  },
  {
    date: '2021-11-23T21:44:22Z',
    title: 'Prachtige bloemen',
    review: 'Prachtige bloemen! Mooie kleuren en bijzonderen combinatie! Zeker 2 weken genieten💛',
    rating: 5,
    reviewer: ' Martine van der Weerdt'
  },
  {
    date: '2021-11-23T18:07:16Z',
    title: 'Mooie, verschillende bloemen',
    review:
      'Geweldige bloemen. Heb 1x geprobeerd bij een ander, maar nee dat was het toch niet. Ben zo blij met "origineel " boeket.',
    rating: 5,
    reviewer: 'Klant'
  },
  {
    date: '2021-11-23T18:05:49Z',
    title: 'Deze keer een prachtig boeket',
    review:
      'Deze keer een prachtig boeket. De ene keer mooier als de andere, maar dat heeft ook met smaak te maken. Een enkele keer staan de bloemen wat minder lang en dat vind ik jammer.',
    rating: 4,
    reviewer: 'klantEJ Zijnen'
  },
  {
    date: '2021-11-22T18:41:04Z',
    title: 'prachtige bloemen',
    review:
      'De bloemen zijn van zeer goede kwaliteit en blijven lang mooi. De boeketten zijn echte kunststukken. Ik geniet er iedere keer weer van.',
    rating: 5,
    reviewer: 'Mascha Ronckers'
  },
  {
    date: '2021-11-22T18:36:22Z',
    title: 'Mooie bos',
    review: 'Mooie bos bloemen',
    rating: 5,
    reviewer: 'Bertjan'
  },
  {
    date: '2021-11-22T18:28:05Z',
    title: 'De kleurencombinatie en de keuze van de…',
    review: 'De kleurencombinatie en de keuze van de bloemen….. in een woord FANTASTISCH!',
    rating: 5,
    reviewer: 'AVS'
  },
  {
    date: '2021-11-19T20:37:47Z',
    title: 'Prachtige bos',
    review: 'Prachtige bos, mooi samengesteld qua kleur en lengte stelen. Goeie vaas cadeau!',
    rating: 5,
    reviewer: 'Marian van Veen'
  },
  {
    date: '2021-11-19T14:27:59Z',
    title: 'Prachtige bossen die soms wel langer…',
    review:
      'Prachtige bossen die soms wel langer dan twee weken staan! Echt een cadeautje om ze elke keer uit te pakken!',
    rating: 5,
    reviewer: 'Maaike'
  }
];
