import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { WindowRefService } from 'Shared/services/window.service';
import { GaService } from 'Shared/services/third-parties/ga.service';
import { User } from 'Shared/classes/user';
import { environment } from 'Environments/environment';
import { Error } from 'Shared/classes/error';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  private window: any;
  private debug: boolean = false;

  constructor(
    private domUtils: DomUtilsService,
    private windowRef: WindowRefService,
    private gaService: GaService
  ) {
    this.window = this.windowRef.nativeWindow;

    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Track the error in GTM
   * @param error
   */
  trackError(error: Error): void {
    this.addToDataLayer({
      event: 'web:ui:error',
      eventName: 'web:ui:error',
      category: error.code,
      label: error.kind || ''
    });
  }

  /**
   * Set the user Identity
   * @param {String} userSlug
   */
  identify(user: User): void {
    try {
      if (user && user.slug) {
        const registered = user.isLoggedIn() || user.email.hasRegistered || user.email.hasOrdered;
        this.addToDataLayer({
          userSlug: user.slug,
          userRegistered: registered
        });
        if (user.isLoggedIn()) {
          this.gaService.setDimension('loggedIn', 'true');
        }
        if (registered) {
          this.gaService.setDimension('userRegistered', 'true');
        }
      } else {
        this.clearDataLayerKey('userSlug');
      }
    } catch (e) {}
  }

  /**
   * Add data to the data layer
   * @param {Object} obj
   */
  addToDataLayer(obj: any): void {
    try {
      this.window.dataLayer.push(obj);
      if (this.debug) {
        console.log('<gtm>', 'addToDataLayer', obj);
      }
    } catch (e) {}
  }

  /**
   * Clear the data layer key
   * @param {Object} key
   */
  clearDataLayerKey(key: string): void {
    try {
      const obj = {};
      obj[key] = undefined;
      this.addToDataLayer(obj);
    } catch (e) {}
  }

  /**
   * Init GTM
   */
  init(): Promise<any> {
    const key = environment.gtmId; // We can't use gtmId here from configService due to circle dependancies
    return this.domUtils.loadScript(`https://www.googletagmanager.com/gtm.js?id=${key}`, 'gtm');
  }
}
