/***
Example Usage
<p>Bloom & Wild is redefining the flower delivery experience, making the giving and receiving of beautiful fresh flowers the
  joy it is meant to be. We are the original letterbox online flower delivery company and
  <a class="bwLink" read-more="attached-content-one">Read More</a>
  <a class="bwLink" read-less="attached-content-one">Read Less</a> now offer a number of new products
  including the lux letterbox flowers and hand-tied bouquets, in addition
  to our current letterbox flower range. </p>
<read-more-content id="attached-content-one">
<p>We offer free next day online flower delivery across the UK via Royal Mail, guaranteed next day flower delivery via DPD
    courier as an option and 2 day delivery to Republic of Ireland. We also offer Bloom & Wild Now, a two-hour flower delivery
    service in London. Our unique design means our flowers can be delivered straight</p>
</read-more-content>
 */
import { Component, OnInit, Input, HostListener, ViewEncapsulation } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';

@Component({
  selector: '[read-more]',
  templateUrl: './read-more-trigger.component.html',
  styleUrls: ['./read-more-trigger.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReadMoreTriggerComponent implements OnInit {
  @Input('readmore')
  readmore: string;

  window;
  toShow: string;
  toHide: string;

  constructor(windowRef: WindowRefService) {
    this.window = windowRef.nativeWindow;
  }

  /**
   * On click
   */
  @HostListener('click')
  onClick(): void {
    [].slice.call(this.window.document.querySelectorAll(this.toHide)).forEach(el => {
      el.style.display = 'none';
    });

    [].slice.call(this.window.document.querySelectorAll(this.toShow)).forEach(el => {
      el.style.display = '';
    });
  }

  /**
   * On Init
   * Note: Some things may have already been set in the read-less-trigger.component
   */
  ngOnInit(): void {
    this.toShow = `read-more-content#${this.readmore}, [readless="${this.readmore}"]`;
    this.toHide = `read-less-content#${this.readmore}, [readmore="${this.readmore}"]`;

    [].slice.call(this.window.document.querySelectorAll(this.toShow)).forEach(el => {
      el.style.display = 'none';
    });
  }
}
