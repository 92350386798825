import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  OasysAlertModule,
  OasysButtonModule,
  OasysCheckboxModule,
  OasysFormErrorsModule,
  OasysFormGroupModule,
  OasysIconModule,
  OasysLayoutModule,
  OasysTextInputModule
} from 'oasys-lib';
import { NgFor, NgIf } from '@angular/common';

import { LoadingSpinnerComponent } from 'Shared/components/loading-spinner/loading-spinner.component';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { Email, EmailService } from 'Shared/services/email.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { FeaturesService } from 'Shared/services/features.service';
import { User, UserService } from 'Shared/services/user.service';
import { EmailPreference } from 'Shared/classes/email';
import { Error } from 'Shared/classes/error';
import { ContentService } from 'Shared/services/content.service';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';

interface SubmitEvent {
  passwordValid: boolean;
  email: Email;
  preference: EmailPreference;
}

/**
 * PLEASE DO NOT TOUCH THIS COMPONENT
 * Unauthorized changes may lead to unexpected behaviour in the authentication flow
 *
 * The Auth Password Form Component is critical for the authentication flow
 * This component includes stricly the password check logic
 * Any other decorators/logic Like rewards checkout icons, My account notes should be handled in the Feature components like Auth modal outside this component.
 *
 * Bellow is a tutorial how you can use the form inside Authentication modal(bw-auth-modal) and Checkout flow(bw-details-user)
 * Visit Authentication modal(bw-auth-modal) and Checkout flow(bw-details-user) for implementation context
 *
 * Example of struture for implenting inside a component like Authentication modal(bw-auth-modal) or Checkout flow(bw-details-user):
 *
    Feature Component --------------------------------------------
    |
    |   +emailExtras ------------------ Custom Feature-specific logic
    |   bw-auth-email-form ----------- Auth logic DO NOT TOUCH
    |
    |   +passwordExtras -------------- Custom Feature-specific logic
    |   bw-auth-password-form -------- PASSWORD Auth logic DO NOT TOUCH
    |
    |   +registerExtras -------------- Custom Feature-specific logic
    |   bw-register-form ------------- Auth logic DO NOT TOUCH
    |
    |   +guestExtras ----------------- Custom Feature-specific logic
    |   bw-auth-guest-form ----------- Auth logic DO NOT TOUCH
    ---------------------------------------------------------------
 */

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgFor,
    NgIf,
    OasysLayoutModule,
    OasysButtonModule,
    OasysFormGroupModule,
    OasysTextInputModule,
    OasysFormErrorsModule,
    LoadingSpinnerComponent,
    OasysIconModule,
    OasysAlertModule,
    OasysCheckboxModule
  ],
  selector: 'bw-auth-password-form',
  templateUrl: './auth-password-form.component.html'
})
export class AuthPasswordFormComponent implements OnInit, OnDestroy {
  @Input() email: string;
  @Input() identifiedUser: string;
  @Input() origin: 'default' | 'checkout' | 'occasions' = 'default';
  @Input() showTandC: boolean = false;
  @Input() submitError?: Error;

  @Output() loginSuccess: EventEmitter<{ authMethod: string }> = new EventEmitter<{ authMethod: string }>();
  @Output() didFailOnUntrustworthyLogin: EventEmitter<Error> = new EventEmitter<Error>();
  @Output() didFailOnPassword: EventEmitter<boolean> = new EventEmitter<boolean>();

  form: FormGroup;
  isSubmitted: boolean = false;
  loading: boolean = false;
  requestResetPasswordSent: boolean = false;
  emailUser: Email;
  isInvalidEmailOrPassword: boolean = false;

  showValidationMessage: boolean = true;
  showFailedLogin: boolean = false;

  constructor(
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private emailService: EmailService,
    public experimentService: ExperimentsService,
    private featuresService: FeaturesService,
    private optimizelyService: OptimizelyService,
    private contentService: ContentService
  ) {}

  /**
   * Optin Login Experiment
   */
  get optinLoginExperiment(): boolean {
    return this.featuresService.getFeature('OPTIN_LOGIN');
  }

  /**
   * Check if User can join Rewards
   */
  get isJoiningRewards(): boolean {
    return (
      this.featuresService.getFeature('JOINING_REWARDS').active &&
      (this.experimentService.isActive('AUTO_OPT_IN_TO_REWARDS', 1) || this.experimentService.isActive('AUTO_OPT_IN_TO_REWARDS', 2))
    );
  }

  /**
   * boolean wrapper around 'consent' FormControl for use by marketing checkbox component
   */
  get marketingConsent(): boolean {
    return this.form.get('consent').value;
  }

  /**
   * Set Marketing consent value
   */
  set marketingConsent(consent: boolean) {
    this.form.get('consent').setValue(consent);
  }

  /**
   * On Init
   */
  ngOnInit(): Promise<void> {
    this.loading = true;
    this.analyticsService.trackInHeap('authLogin');
    this.buildForm();
    return this.emailService
      .check(new Email(this.email))
      .then((email: Email): void => {
        this.emailUser = email;
        this.marketingConsent = this.emailUser?.preference !== 0;
      })
      .finally((): void => {
        this.loading = false;
      });
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    this.form.markAsPristine();
    this.form.reset();
  }

  /**
   * Reset password request
   */
  resetPassword(): Promise<void> {
    this.showValidationMessage = true;
    this.requestResetPasswordSent = false;
    this.showFailedLogin = false;

    this.form.get('password').clearValidators();
    this.form.get('password').reset();
    this.form.get('password').markAsUntouched();

    if (this.form.get('password').invalid) {
      return Promise.resolve();
    }

    this.loading = true;
    const emailObject = new Email(this.email);
    return this.userService
      .requestPasswordReset(emailObject)
      .catch((): void => {})
      .then((): void => {
        this.requestResetPasswordSent = true;
      })
      .finally((): void => {
        this.loading = false;
      });
  }

  /**
   * Submit with valid password
   */
  submit(): Promise<void> {
    this.requestResetPasswordSent = false;
    this.showValidationMessage = true;
    this.showFailedLogin = false;

    this.checkPasswordValidation();

    const preference = this.getMarketingPrefOption();

    return this.submitPassword({
      passwordValid: true,
      email: this.emailUser,
      preference
    });
  }

  /**
   * Submit the form
   */
  submitPassword(event: SubmitEvent): Promise<void> {
    this.loading = true;

    this.requestResetPasswordSent = false;
    this.showValidationMessage = true;
    this.showFailedLogin = false;

    const email = this.email;
    const password = this.form.get('password').value;

    let emailPreferencePromise = Promise.resolve();

    // Updates preferences if user has opted in and changed preferences value
    if (this.featuresService.getFeature('OPTIN_LOGIN') && event?.preference && event.email.preference !== event.preference) {
      emailPreferencePromise = this.emailService.sendConsent(event.email, event.preference);
    }

    return emailPreferencePromise
      .then((): Promise<User> => this.userService.login(email, password))
      .then((): void => {
        this.contentService.refreshSegments();
      })
      .then((): void => {
        this.trackSuccessfulLogin(true);
        return this.loginSuccess.emit({ authMethod: 'email' });
      })
      .catch((e: Error): void => {
        this.trackSuccessfulLogin(false);
        this.submitError = e;
        this.form.get('password').setErrors({ failedLogin: true });
        this.checkErrorSubmitKind(e);
        this.form.markAllAsTouched();
      })
      .finally((): void => {
        this.loading = false;
      });
  }

  /**
   * Check Error Submit Kind
   * @param e
   */
  private checkErrorSubmitKind(e: Error): void {
    if (e?.kind === 'untrustworthyLoginAttempt') {
      this.showFailedLogin = true;
      this.showValidationMessage = false;
      return this.didFailOnUntrustworthyLogin.emit(e);
    }
    if (e.code === 'tooManyRequests') {
      this.showFailedLogin = true;
      this.showValidationMessage = false;
    }
  }

  /**
   * Get Marketing Preferance value
   * @returns
   */
  private getMarketingPrefOption(): EmailPreference {
    const emailSubscribedPreference = this.featuresService.getFeature('GDPR').emailSubscribedPreference;

    // preference should be default to current set preference
    let preference = this.emailUser?.preference;

    // if preference is not set, default to consent checkout outcome
    if (preference < 1) {
      preference = this.form.get('consent').value ? emailSubscribedPreference : 0;
    }
    return preference;
  }

  /**
   * Check Password is valid and applies validators
   * @returns
   */
  private checkPasswordValidation(): void {
    if (!this.form.get('password').hasValidator(Validators.required)) {
      this.form.get('password').setValidators([Validators.required]);
      this.form.get('password').updateValueAndValidity();
    }

    // For error handling, important to make form as submitted and touched
    this.form.get('password').markAsTouched();
    this.form.get('password').markAsDirty();

    if (this.form.get('password').invalid) {
      return this.onFailedPassword();
    }
  }

  /**
   * On Failed Password
   */
  private onFailedPassword(): void {
    this.showFailedLogin = true;
    this.showValidationMessage = true;
    this.form.get('password').setErrors({ invalid: true });
    // Validate form forces formControls to be updated
    this.form.updateValueAndValidity();
    this.form.get('password').markAsTouched();
    this.didFailOnPassword.emit();
  }

  /**
   * Track in heap
   * @param isSuccessful
   */
  private trackSuccessfulLogin(isSuccessful: boolean): void {
    this.optimizelyService.trackEvent('successful_login');
    this.analyticsService.trackInHeap('authLoginSuccess', {
      isSuccessful
    });
  }

  /**
   * Build form
   */
  private buildForm(): void {
    this.form = new FormGroup({
      password: new FormControl('', [Validators.required]),
      consent: new FormControl('false')
    });
  }
}
