import { Injectable } from '@angular/core';
import { EmailModelService } from 'Shared/models/email-model.service';
import { Email, EmailPreference } from 'Shared/classes/email';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { FeaturesService } from 'Shared/services/features.service';
import { User, UserService } from 'Shared/services/user.service';
import { ToastrService } from 'Project/toastr/services/toastr.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private _emailPreference: EmailPreference;

  constructor(
    private emailModelService: EmailModelService,
    private analyticsService: AnalyticsService,
    private featuresService: FeaturesService,
    private userService: UserService,
    private toastr: ToastrService
  ) {}

  get emailPreference(): EmailPreference {
    return this._emailPreference;
  }

  set emailPreference(value: EmailPreference) {
    this._emailPreference = value;
  }

  /**
   * Get the email details
   * @param email
   */
  get(email: Email): Promise<Email> {
    if (email.slug) {
      return this.emailModelService.getPreferenceBySlug(email.slug);
    }
    return this.emailModelService.getPreferenceByAddress(email);
  }

  /**
   * Check if an email is registered
   * @param email
   */
  check(email: Email): Promise<Email> {
    if (email) {
      return this.emailModelService.check(email.address);
    }
  }

  /**
   * Update
   * @param email
   */
  update(email: Email): Promise<any> {
    if (email.preference !== undefined && email.preference === this.featuresService.getFeature('GDPR').optOutEmailPreference) {
      this.analyticsService.track('checkout.emails.optOut');
    }
    if (email.slug) {
      return this.emailModelService.setPreferenceForSlug(email);
    }

    return this.emailModelService.saveOrUpdate(email).then((): Promise<User> => this.userService.refreshUser());
  }

  /**
   * Sends the consent
   */
  sendConsent(email: Email, preference: EmailPreference, copy: string = '', placement: string = ''): Promise<any> {
    const updatedEmail = email.clone();
    updatedEmail.slug = undefined; // Clear slug so that it's always new
    updatedEmail.preference = preference;

    updatedEmail.consent = {
      method: `Checkbox - Default was ${email?.preference === -1 ? 'Ticked' : 'Unticked'}`,
      location: placement,
      copy
    };

    return this.update(updatedEmail);
  }
}

export { Email } from 'Shared/classes/email';
